import React, { useState } from 'react';
import { ILead } from 'interfaces';
import { Error } from 'components/message/Error';
import { uploadDocumentForUser } from 'services/api';
import { uploadFile } from 'services/api/common';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import { showError } from 'utils/notifications';
import FileUploaderWithPDF from 'components/FileUpload/FileUploaderPdf';
import { readFileAsBase64 } from 'utils';
import { FilePreviewerThumbnail } from 'react-file-previewer-v2';

const DocumentUpload = (props: { documentType: ILead.IDocumentType; userId: string; onDocumentChange: Function}) => {
  const { documentType, userId, onDocumentChange } = props;
  const [file, setFile] = useState<any>();
  const [url, setURL] = useState('');
  const [base64, setBase64] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [showUploadButton, setShowUploadButton] = useState(true);

  const onSelectFile = async (urlObject, file) => {
    if (file) {
      const base64: any = await readFileAsBase64(file);
      setBase64(base64);
    }
    setURL(urlObject);
    setFile(file);
  };

  const onError = (error) => {
    setError(error.toString());
  };

  const uploadDocument = async () => {
    try {
      setLoading(true);
      const image = await uploadFile(base64, file.type);
      await uploadDocumentForUser(userId, documentType.code, image);
      setShowUploadButton(false);
      setLoading(false);
      onDocumentChange && onDocumentChange();
      clearFile();
    } catch (e) {
      setLoading(false);
      setError(e);
      showError(e);
    }
  };

  const clearFile = () => {
    setURL('');
    setFile(null);
  };


  const getSelectedImageSlot = () => {
    return <>
      <div className="uploaded-image">
        {url && <FilePreviewerThumbnail 
          file={url}
          onClick={() => console.log('Heej')}
        />}
      </div>
      {/* <img className="uploaded-image" src={file} alt={documentType.code}/> */}
      { showUploadButton && (
        <div className="mt-2 d-flex justify-content-center upload-button">
          <ActionButton text={<i className="fa fa-upload" aria-hidden="true"></i>} active={true} onClick={uploadDocument} isLoading={loading} customClass="btn btn-primary"/>
          <ActionButton text={<i className="fa fa-times" aria-hidden="true"></i>} active={true} onClick={clearFile} customClass='ml-3' isLoading={loading}/>
        </div>
      ) }
    </>;
  };

  return (
    <>
      { !!file ? getSelectedImageSlot() : <FileUploaderWithPDF onSelectFile={onSelectFile} onError={onError}/> }
      { !!error && <Error message={error} /> }
    </>
  );
};

export { DocumentUpload };
