import React from 'react';

const DecimalInput = props => {
  const { isTableCell, value, onChange, disabled } = props;
  
  return (
    <div>
      {isTableCell ? (
        <input
          type={'number'}
          className={'form-control'}
          defaultValue={value}
          disabled={disabled}
          onChange={e => onChange(parseFloat(e.target.value))}
          step={0.01}
        />
      ) : (
        <input
          type={'number'}
          className={'form-control'}
          value={value}
          disabled={disabled}
          onChange={e => onChange(parseFloat(e.target.value))}
          step={0.01}
        />
      )}
    </div>
  );
};

export default DecimalInput;