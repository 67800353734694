import ActionButton from 'components/ActionButton/ActionButton';
import Dropdown from 'components/Dropdown';
import NameFilterAutosuggest from 'components/NameFilterAutoSuggest';
import _ from 'lodash';
import React, { useState } from 'react';
import { searchUserByName } from 'services';
import { CART_STATUSES } from 'utils';
import styles from './index.module.scss';

const Filter = props => {
  const [status, setStatus] = useState('');
  const [userId, setUserId] = useState<string>('');
  const [userPhone, setUserPhone] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [orderId, setOrderId] = useState<string>('');

  const resetFilter = () => {
    setStatus('');
    setUserEmail('');
    setUserPhone('');
    setUserId('');
    setOrderId('');
    props.onSubmit({});
  };

  const handleSubmit = () => {
    let filterData: any = {
      status,
      userId,
      userEmail,
      userPhone,
      orderId,
    };
    filterData = _.omitBy(filterData, (v) => _.isUndefined(v) || _.isNull(v) || v === '');
    props.onSubmit(filterData);
  };

  return (
    <form className={styles['filter-container']}>
      <div className={styles['input-group']}>
        <div className={styles['label']}>Status</div>
        <Dropdown
          value={status}
          options={CART_STATUSES}
          onChange={(e) => {
            setStatus(e);
          }}
          isFilter
        />
      </div>
      <div className={styles['autosuggest-group']}>
        <div className={styles['label']}>User</div>
        <div className={styles['autosuggest-input']}>
          <NameFilterAutosuggest
            entityId={userId}
            placeholder={'User Name'}
            onSuggestionSelected={setUserId}
            fetchSuggestions={searchUserByName}
          />
        </div>
      </div>
      <div className={styles['input-group']}>
        <div className={styles['label']}>User ID</div>
        <input
          className={'form-control'}
          value={userId}
          onChange={e => setUserId(e.target.value)}
        />
      </div>
      <div className={styles['input-group']}>
        <div className={styles['label']}>User Phone</div>
        <input
          className={'form-control'}
          value={userPhone}
          onChange={e => setUserPhone(e.target.value)}
        />
      </div>
      <div className={styles['input-group']}>
        <div className={styles['label']}>User Email</div>
        <input
          className={'form-control'}
          value={userEmail}
          onChange={e => setUserEmail(e.target.value)}
        />
      </div>
      <div className={styles['input-group']}>
        <div className={styles['label']}>Order Id</div>
        <input
          className={'form-control'}
          value={orderId}
          onChange={e => setOrderId(e.target.value)}
        />
      </div>
      <div className={styles['buttons-container']}>
        <ActionButton
          text={'Submit'}
          active={true}
          isLoading={false}
          onClick={handleSubmit}
        />
        <ActionButton
          text={'Reset'}
          active={true}
          isLoading={false}
          onClick={resetFilter}
        />
      </div>
    </form>
  );
};

export default Filter;