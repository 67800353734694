import React, { useState } from 'react';
import styles from './index.module.scss';
import ActionButton from 'components/ActionButton/ActionButton';
import Tag from 'components/Tag';
import WholeNumberInput from 'components/WholeNumberInput';
import Currency from 'components/Currency';
import _ from 'lodash';
import RestaurantAutoSuggest from 'components/RestaurantAutoSuggest';

const OrderEligibility = props => {
  const { detailsEditable,discount,setDiscount,loading }=props;
  const [restaurantId, setRestaurantId] = useState<string>('');

  const getMinimumPurchaseAmount =()=>{
    const minimumPurchaseAmount = discount?.orderEligibility?.minimumPurchaseAmount;
    if(detailsEditable) {
      return (
        <WholeNumberInput
          className={'form-control '}
          value={minimumPurchaseAmount}
          onChange={e => setDiscount({ ...discount, orderEligibility: { ...discount?.orderEligibility, minimumPurchaseAmount: e }})}
          required={false}
        />
      );
    }else{
      return <div className={styles['detail']}><Currency amount={minimumPurchaseAmount} /></div>;
    }
  };

  const getMinimumItemCount =()=>{
    const minimumItemCount = discount?.orderEligibility?.minimumItemCount;
    if(detailsEditable) {
      return (
        <WholeNumberInput
          className={'form-control'}
          value={minimumItemCount}
          onChange={e => setDiscount({ ...discount, orderEligibility: { ...discount?.orderEligibility, minimumItemCount: e }})}
          required={false}
        />
      );
    }else{
      return <div className={styles['detail']}>{minimumItemCount ?? '-'}</div>;
    }
  };

  const getOrderEligibilityProperties = ()=>{
    return (
      <div>
        <div className='filter-container'>
          <div className='input-container mr-4 mb-2'>
            <div className={styles['label']}>Minimum Purchase Amount</div>
            {getMinimumPurchaseAmount()}
          </div>
          <div className='input-container mr-4 mb-2'>
            <div className={styles['label']}>Minimum Item Count</div>
            {getMinimumItemCount()}
          </div>
        </div>
      </div>
    );
  };

  const restaurants = discount?.orderEligibility?.eligibleRestaurantIds || [];
  const handleRemove = (index: number) => {
    restaurants.splice(index, 1);
    setDiscount({ ...discount, orderEligibility: { ...discount?.orderEligibility, eligibleRestaurantIds: restaurants }});
  };
  
  const handleAddRestaurants = () => {
    if (!_.isEmpty(restaurantId)) {
      restaurants.push(restaurantId);
      setDiscount({ ...discount, orderEligibility: { ...discount?.orderEligibility, eligibleRestaurantIds: _.uniq(restaurants) }});
    }
  };
  
  const getOrderEligibilitySection=()=>{
    if(detailsEditable){
      return (
        <div className={styles['input-container']}>
          <div>
            <div className={`${styles['label']}`}>Restaurant Id</div>
            <input
              className='form-control'
              placeholder={'Restaurant ID'}
              value={restaurantId}
              onChange={e => setRestaurantId(e.target.value)}
            />
          </div>
          <div>
            <div className={`${styles['label']}`}>Restaurant</div>
            <RestaurantAutoSuggest
              placeholder={'Search Restaurant'}
              onSuggestionSelect={suggestion=>setRestaurantId(suggestion.id)}
            />
          </div>
          <div className={styles['action-button']}>
            <ActionButton
              active={true}
              isLoading={loading}
              onClick={handleAddRestaurants}
              text={'Add Restaurant'}
              customClass={'form-control'}
            />
          </div>
        </div>
      );
    }else{
      return null;
    }
  };

  const getRestaurantsTagSection=()=>{
    if(restaurants?.length > 0 ){
      return (
        <div className={styles['tag-section']}>
          <div className={styles['roles-card']}>
            {restaurants.map((item, i) => <Tag key={i} tagName={item} onRemove={() => handleRemove(i)} />)}
          </div>
        </div>
      );
    }else{
      return <div className={`${styles['label']} pl-2 mb-4`}>No Eligible Restaurants</div>;
    }
  };
  
  return (
    <>
      <div className={styles['group-section']}>
        <h4 className=' font-weight-bolder'>Restaurant Eligibility</h4>
        {getOrderEligibilitySection()}
        {getRestaurantsTagSection()}
        {getOrderEligibilityProperties()}
      </div>
    </>
  );
};

export default OrderEligibility;