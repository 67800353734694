import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import './index.scss';
import { ROUTE_ACTIVITY_TYPE } from 'utils';


const StopTooltip = props => {
  const [tooltipData, setTooltipData] = useState<Record<string, any>>({});

  useEffect(() => {
    setTooltipData(props.data);
  }, []);


  const getToolTip = () => (
    <div className={'stop-tooltip'}>
      {!tooltipData ? (
        <div>No Route Found</div>
      ) : (
        <>
          <div className={'column-section'}>
            <div className={'stop-info-group'}>
              <div>Order ID</div>
              <div>{tooltipData.orderId || 'TestID'}</div>
            </div>
            <div className={'stop-info-group'}>
              <div>Type</div>
              <div>
                {
                  tooltipData.type !== ROUTE_ACTIVITY_TYPE.START ?  (
                    tooltipData.type === ROUTE_ACTIVITY_TYPE.PICKUP_SHIPMENT ? (
                      'PICKUP'
                    ): (
                      'DELIVERY'
                    )
                  ): 'START'
                }
              </div>
            </div>
            {tooltipData.type !== ROUTE_ACTIVITY_TYPE.START ? (
              <div className={'stop-info-group'}>
                <div>Address</div>
                <div>{tooltipData.address || '1, Test Address, Test City, State, India'}</div>
              </div>
            ): null}
          </div>
        </>
      )}
    </div>
  );

  return (
    <>
      <ReactTooltip
        id={props.id}
        multiline={true}
        getContent={() => getToolTip()}
        type={'dark'}
        effect={'solid'}
      />
    </>
  );
};

export default StopTooltip;