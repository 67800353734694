import React, { useState } from 'react';
import './index.scss';
import DraggableList from 'components/DraggableList';
import ActionButton from 'components/ActionButton/ActionButton';
import { showError, showSuccess } from 'utils/notifications';
import { deleteCategory, sortRestaurantCategories } from 'services/api/restaurant';
import { Loading } from 'components/loading/Loading';
import AddCategoryModal from 'components/AddCategoryModal';

const RestaurantCategories = props => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    categories,
    restaurantId,
    setCategories,
    categoriesOrderChanged,
    setCategoriesOrderChanged,
    onEditSuccess,
  } = props;

  const deleteCategoryFn = async (category: string) => {
    setLoading(true);
    try {
      await deleteCategory({
        category,
        restaurantId,
      });
      showSuccess('Category deleted successfully');
      setCategories(categories.filter(singleCategory => singleCategory !== category));
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const saveReOrderedCategories = async () => {
    setLoading(true);
    try {
      await sortRestaurantCategories({
        categories,
        restaurantId,
      });
      setCategoriesOrderChanged(false);
      showSuccess('Categories order updated successfully');
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const onDragEnd = (reOrderedCategories: string[]) => {
    reOrderedCategories.map((category, i) => {
      if (category !== categories[i]) {
        setCategoriesOrderChanged(true);
      }
    });
    setCategories(reOrderedCategories);
  };

  return (
    <div>
      {loading && <Loading />}
      {modalOpen && (
        <AddCategoryModal
          restaurantId={restaurantId}
          toggleModal={() => setModalOpen(!modalOpen)}
          onSuccess={setCategories}
        />
      )}
      <div className={'menu-header'}>
        <div className={'menu-heading'}>Restaurant Categories</div>
        <ActionButton
          text={'Category +'}
          active={true}
          isLoading={false}
          onClick={() => setModalOpen(true)}
          customClass={'btn btn-primary'}
        />
        {categoriesOrderChanged ? (
          <ActionButton
            text={'Save Order'}
            active={!loading}
            isLoading={loading}
            onClick={saveReOrderedCategories}
            customClass={'btn-primary'}
          />
        ) : null}
      </div>
      <div>
        <DraggableList
          id={restaurantId}
          list={categories}
          onDragEnd={onDragEnd}
          recordAction={deleteCategoryFn}
          editable={true}
          onEditSuccess={onEditSuccess}
        />
      </div>
    </div>
  );
};

export default RestaurantCategories;