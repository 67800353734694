import RenderInfo from 'components/RenderInfo';
import React, { useState } from 'react';
import { DATE_FORMATS, FLEET_MERCHANT_INVOICE_STATUS, FLEET_MERCHANT_INVOICE_STATUS_OPTIONS, formatDate, metersToMile, secondsToMinute, showError, showSuccess } from 'utils';
import DataTable from 'react-data-table-component';
import { tableStyles } from 'css/tableStyles';
import TableLink from 'TableLink';
import DropDown from 'components/Dropdown';
import ActionButton from 'components/ActionButton/ActionButton';
import { updateFleetMerchantInvoice } from 'services/api/invoices';

const BasicDetails = ({ details, onRefresh }) => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading]  =useState(false);
  const {
    startDate,
    endDate,
    orders,
    scheduledAt,
    user,
    totalDeliveryCharges,
    totalDeliveryTaxes,
    status,
    createdAt,
    updatedAt,
    paidAt,
    totalAmount,
    objectId,
  } = details?.invoice;
  const [invoiceStatus, setInvoiceStatus] = useState(status);

  const orderColumns = [
    {
      name: 'Order ID',
      selector: 'objectId',
      width: '150px',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/orders/${row?.objectId}`}
          text={row?.objectId}
          openInNewTab={false}
        />
      ),
    },
    {
      name: 'Restaurant',
      selector: 'restaurant',
      width: '180px',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/restaurants/${row?.restaurant?.objectId}`}
          text={row.restaurant?.restaurant_name}
          openInNewTab={true}
        />
      ),
    },
    {
      name: 'Date Time',
      selector: 'createdAt',
      sortable: true,
      format: (row) => formatDate(row?.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
      width: '180px',
    },

    {
      name: 'Delivered At',
      selector: 'deliveredAt',
      sortable: true,
      width: '180px',
      format: (row) => formatDate(row?.deliveredAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
    },
    {
      name: 'Distance',
      selector: 'distance',
      sortable: false,
      format: row => row?.distance ? metersToMile(row?.distance) + ' miles' : 'N/A',
    },
    {
      name: 'Duration',
      selector: 'duration',
      sortable: false,
      format: row => row?.duration ? secondsToMinute(row?.duration) + ' minutes' : 'N/A',
    },
    {
      name: 'Delivery Charges',
      selector: 'deliveryChargeData',
      sortable: false,
      format: row => `$${row?.deliveryChargeData?.total}`,
    },
    {
      name: 'Delivery Taxes',
      selector: 'taxData',
      sortable: false,
      format: row => `$${row?.taxData?.deliveryTaxes?.total}`,
    },
    {
      name: 'Status',
      selector: 'status',
      width: '180px',
      sortable: false,
    },
  ];

  const transactionColumns = [
    {
      name: 'Transaction ID',
      selector: 'objectId',
      width: '150px',
      sortable: false,
    },
    {
      name: 'Payment Method',
      selector: '',
      // eslint-disable-next-line react/display-name
      cell: (row: any) => (
        <div className='d-flex flex-column'>
          <span>XXXX XXXX XXXX {row?.card?.last4}</span>
          <span>Exp: {`${row?.card?.exp_month}/${row?.card?.exp_year}`}</span>
        </div>
      ),
    },
    {
      name: 'Reference No',
      selector: 'chargeId',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`https://dashboard.stripe.com/payments/${row?.chargeId}`}
          text={row?.chargeId}
          openInNewTab={true}
        />
      ),
    },
    {
      name: 'Date Time',
      selector: 'createdAt',
      sortable: true,
      format: (row) => formatDate(row?.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
      width: '180px',
    },
    {
      name: 'Status',
      selector: 'success',
      format: (row) => row?.success ? 'Success' : 'Failure',
    },
    {
      name: 'Transaction error',
      selector: 'error',
      format: (row) => row?.error ? row?.error : '-',
    },
  ];

  async function updateInvoiceDetailsFn() {
    setLoading(true);
    try {
      await updateFleetMerchantInvoice(objectId, { status: invoiceStatus });
      showSuccess(`Invoice status updated to ${invoiceStatus} successfully.`);
      onRefresh();
    } catch (e) {
      showError(e);
    } finally {
      setEdit(false);
      setLoading(false);
    }
  }

  const handleEdit = () => {
    if (edit) {
      updateInvoiceDetailsFn();
    } else {
      setEdit(true);
    }
  };

  return (
    <div className="container mt-3">
      <div className='d-flex justify-content-between'>
        <div>
          <strong>Basic Details</strong>
        </div>
        <div className='d-flex'>
          <ActionButton
            text={edit ? 'Save' : 'Edit'}
            active={!loading}
            isLoading={loading}
            onClick={handleEdit}
            customClass={'mb-3'}
          />
          { edit ?
            <ActionButton
              text={'Cancel'}
              active={true}
              isLoading={false}
              onClick={() => setEdit(false)}
              customClass={'mb-3 ml-2'}
            />
            : null}
        </div>
      </div>
      <div className="row mt-3 p-3">
        <div className="col">
          <RenderInfo heading="Invoice ID" value={objectId} />
          <RenderInfo heading="User ID" value={user?.id} link={`/users/${user.id}`}/>
          {edit ? 
            <div className='mb-2'>
              <div className={'label'} style={{ fontSize: '12px' }}>Status</div>
              <DropDown name="Discount Type" value={invoiceStatus} onChange={(e) => setInvoiceStatus(e)} options={FLEET_MERCHANT_INVOICE_STATUS_OPTIONS} />
            </div>
            : <RenderInfo heading="Status" value={status} />}
          <RenderInfo heading="Total Orders" value={orders?.length} />
          <RenderInfo heading="Total Amount" value={totalAmount}  type="currency"/>
          <RenderInfo heading="Total Delivery Taxes" value={totalDeliveryTaxes}  type="currency"/>
          <RenderInfo heading="Total Delivery Charges" value={totalDeliveryCharges} type="currency"/>
        </div>
        <div className="col">
          <RenderInfo heading="Billing Period" value={(formatDate(startDate, 'MMM D')+ '-' + formatDate(endDate, 'MMM D'))}/>
          <RenderInfo heading="Paid At" value={paidAt} type="date" />
          <RenderInfo heading="Scheduled At" value={scheduledAt} type="date" />
          <RenderInfo heading="Created At" value={createdAt} type="date" />
          <RenderInfo heading="Updated At" value={updatedAt} type="date" />
        </div>
      </div>
      <div className="row mt-3 p-3">
        <DataTable
          title="Orders"
          keyField="objectId"
          columns={orderColumns}
          data={details?.orders}
          highlightOnHover={true}
          defaultSortField="createdAt"
          defaultSortAsc={false}
          customStyles={tableStyles}
          pagination={false}
        />
      </div>
      <div className="row mt-3 p-3">
        <DataTable
          title="Transactions"
          keyField="objectId"
          columns={transactionColumns}
          data={details?.transactions}
          highlightOnHover={true}
          defaultSortField="createdAt"
          defaultSortAsc={false}
          customStyles={tableStyles}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default BasicDetails;

