import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import _ from 'lodash';
import MDEditor from '@uiw/react-md-editor';

import ActionIcon from 'components/ActionIcon';
import { Loading } from 'components/loading/Loading';
import EditConfigModal from './EditConfigModal';

import { fetchAllConfigWithDescription } from 'services/api/configs';

import { showError } from 'utils/notifications';
import { ACTION_ICON_TYPES } from 'utils';

import { tableStyles } from 'css/tableStyles';
import './index.scss';
import ActionButton from 'components/ActionButton/ActionButton';

const Config = props => {
  const [configs, setConfigs] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [configToEdit, setConfigToEdit] = useState<Record<string, any>>({});
  const [readOnlyMode, setReadOnlyMode] = useState<boolean>(false);

  const getConfigValue = (value: any) => (!_.isObject(value) || _.isArray(value)) ? value.toString() : '';
  
  const handleViewDescription = (config: Record<string, any>) => {
    setConfigToEdit(config);
    setEditModalOpen(true);
    setReadOnlyMode(true);
  };
  
  const columns = useMemo(() => [
    {
      name: 'Name',
      selector: 'name',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <div className={'config-name'}>{row.name}</div>
      ),
    },
    {
      name: 'Value',
      selector: 'value',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <div className={'config-value'}>{getConfigValue(row.value)}</div>
      ),
    },
    {
      name: 'Description',
      selector: 'description',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <div className={'config-description'}>
          <MDEditor.Markdown
            source={row.description.substr(0, 50)}
          />
          <ActionButton
            text={'View'}
            active={true}
            isLoading={false}
            onClick={() => handleViewDescription(row)}
            customClass={'btn btn-primary'}
          />
        </div>
      ),
    },
    {
      name: 'Action',
      selector: '',
      width: '120px',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <ActionIcon
          actionType={ACTION_ICON_TYPES.EDIT}
          loading={false}
          onClick={() => {
            setConfigToEdit(row);
            setEditModalOpen(true);
          }}
          customClass={'ml-3'}
        />
      ),
    },
  ], []);

  const getConfigFn = async () => {
    try {
      const response = await fetchAllConfigWithDescription();
      setConfigs(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getConfigFn();
  }, []);

  const handleCloseModal = () => {
    setReadOnlyMode(false);
    setEditModalOpen(false);
  };
  
  if (loading) return <Loading />;

  return (
    <div>
      {editModalOpen && (
        <EditConfigModal
          onSuccess={getConfigFn}
          configToEdit={configToEdit}
          readOnlyMode={readOnlyMode}
          closeModal={handleCloseModal}
        />
      )}
      <DataTable
        title={'Config'}
        customStyles={tableStyles}
        data={configs}
        columns={columns}
      />
    </div>
  );

};

export default Config;