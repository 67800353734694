import BackButton from 'components/BackButton';
import JSONViewer from 'components/JSONViewer';
import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from 'react';
import { getCloudRequestDetail } from 'services/api/cloudRequests';
import { showError } from 'utils/notifications';
import './index.scss';

const CloudRequestDetail = props => {
  const { cloudRequestId } = props.match.params;
  const [cloudRequestDetails, setCloudRequestDetails] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);

  const fetchCloudRequestDetails = async () => {
    try {
      const response = (await getCloudRequestDetail(cloudRequestId)).toJSON();
      setCloudRequestDetails(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchCloudRequestDetails();
  }, []);
  
  const getResult = () => {
    let result = JSON.parse(cloudRequestDetails.result);
    if (typeof result === 'string') {
      result = { result };
    }
    return result;
  };
  
  const getDetails = () => (
    <div className={'cloud-request-details-section'}>
      <div className={'details-group'}>
        <div className={'label'}>Cloud Request ID</div>
        <div className={'info'}>{cloudRequestId}</div>
      </div>
      <div className={'details-group'}>
        <div className={'label'}>Entity Class</div>
        <div className={'info'}>{cloudRequestDetails.entityClass || ''}</div>
      </div>
      <div className={'details-group'}>
        <div className={'label'}>Entity Id</div>
        <div className={'info'}>{cloudRequestDetails.entityId || ''}</div>
      </div>
      <div className={'details-group'}>
        <div className={'label'}>Params</div>
        <JSONViewer data={cloudRequestDetails.params} />
      </div>
      <div>
        <div className={'label'}>Result</div>
        <JSONViewer data={getResult()} />
      </div>
    </div>
  );
  
  return (
    <div className={'cloud-request-details-main-container'}>
      <div className={'header'}>
        <BackButton />
        <h4>Cloud Request Details</h4>
      </div>
      {loading ? <Loading /> : getDetails()}
    </div>
  );
};

export default CloudRequestDetail;