import React, { useEffect, useState } from 'react';
import { fetchAsyncJobs } from 'services/api/asyncJobs';
import { getPageLimit } from 'utils';
import DataTableWrapper from 'components/DataTableWrapper';

let sort = {};
const AsyncJobs = props => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const {
    type,
    columns,
    onRowClicked,
  } = props;

  const filter = {
    limit: getPageLimit(),
    page: 0,
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    const { limit, page } = filter;
    const params = { type, limit, page, sort };
    setLoading(true);
    const response = await fetchAsyncJobs(params);
    setLoading(false);
    setData(response.items);
    setTotalRows(response.total);
  };

  const onSort = (column: Record<string, any>, sortDirection: string) => {
    sort = {
      ...sort,
      [column.selector]: sortDirection,
    };
    fetchJobs();
  };

  if(data.length > 0) {
    return (
      <DataTableWrapper
        loading={loading}
        type={type}
        data={data}
        columns={columns}
        fetchData={fetchJobs}
        filter={filter}
        totalRows={totalRows}
        onRowClick={onRowClicked}
        onSort={onSort}
      />
    );
  }

  return <div />;
};

export default AsyncJobs;
