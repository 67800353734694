import * as React from 'react';
import './loading.scss';

class Loading extends React.Component<any, any> {
  render () {
    return (
      <div className="loading">
        <div className={this.props.type !== 'mini' ? 'loader-cover' : 'm-auto'}/>
        <div className={'spinner-border text-secondary ' + (this.props.type !== 'mini' ? 'page-loader' : '')} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}
export { Loading };
