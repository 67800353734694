import ActionButton from 'components/ActionButton/ActionButton';
import BackButton from 'components/BackButton';
import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from 'react';
import { getSubscriptionDetail } from 'services/api/subscriptions';
import { showError } from 'utils';
import BasicDetails from './basicDetails';
import ChangeStatusButton from './changeStatusButton';
import styles from './index.module.scss';
import BasicStripProductDetails from '../../stripe-products/details/basicDetails';

const SubscriptionDetails = (props) => {
  const { subscriptionId } = props.match.params;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({}) as any;

  const getSubscriptionDetailFn = async () => {
    setLoading(true);
    try {
      let response = await getSubscriptionDetail(subscriptionId);
      response = JSON.parse(JSON.stringify(response));
      setData(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubscriptionDetailFn();
  }, []);

  const getHeader = () => (
    <div className={styles['header']}>
      <div className={styles['heading']}>Subscription Details</div>
      <div className={styles['header-buttons']}>
        <BackButton />
        <ActionButton
          text={'Edit'}
          active={true}
          isLoading={false}
          onClick={() => {
            props.history.push(`/subscription/edit/${data?.objectId}`);
          }}
        />
      </div>
    </div>
  );

  return (
    <div id={'main-container'} className={`${styles['main-container']}`}>
      {loading && <Loading />}
      {getHeader()}
      <ChangeStatusButton details={data} setData={setData} />
      <BasicDetails details={data} />
      <h5 className='ml-4 mt-3'>Stripe Product</h5>
      <BasicStripProductDetails details={data?.stripeProduct} />
    </div>
  );
};

export default SubscriptionDetails;