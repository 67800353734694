import ActionButton from 'components/ActionButton/ActionButton';
import React, { useState } from 'react';
import styles from './index.module.scss';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Error } from 'components/message/Error';
import { SUBSCRIPTION_TYPES, dollarToCents, showError, showSuccess } from 'utils';
import { createSubscription } from 'services/api/subscriptions';
import useQuery from 'hooks/useQuery';

const validationSchema = yup.object().shape({
  name: yup.string().required('Please enter name'),
  enName: yup.string(),
  description: yup.string().required('Please enter description'),
  enDescription: yup.string(),
  price: yup.number().typeError('Please enter price').required('Please enter price'),
  type: yup.string().required('Please enter type'),
  trialPeriodDays: yup.number().test(
    'Is positive integer?', 
    'trialPeriodDays must be positive integer', 
    (value) => value >= 0 && value%1 === 0,
  ),
});

type FormData = {
  name: string;
  enName: string;
  description: string;
  enDescription: string;
  price: number;
  type: string;
  trialPeriodDays: number;
};

const CreateSubscription = props => {
  const query = useQuery();
  const stripeProductId = query.get('stripeProductId');
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    errors,
  } = useForm<FormData>({ resolver: yupResolver(validationSchema), defaultValues: {
    trialPeriodDays: 0,
  }});

  const saveSubscriptionFn = handleSubmit(async (values) => {
    setLoading(true);
    try {
      await createSubscription({ ...values, price: dollarToCents(values?.price), productId: stripeProductId });
      showSuccess('Subscription created successfully.');
      props.history.goBack();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  });

  const getHeader = () => {
    return (
      <div className={styles['header']}>
        <div className={styles['heading']}>Create a New Subscription</div>
      </div>
    );
  };

  const getFooter = () => {
    return (
      <div className={styles['footer']}>
        <span className={styles['label']}>Note: Price and subscription can not be edit from admin portal once subscription is created.</span>
        <ActionButton
          text={'Cancel'}
          active={!loading}
          isLoading={false}
          onClick={() => props.history.goBack()}
          customClass={styles['cancel']}
        />
        <ActionButton
          text={'Save'}
          active={!loading}
          isLoading={loading}
          onClick={saveSubscriptionFn}
        />
      </div>
    );
  };

  const getFieldError = (error) => {
    if (!error) return null;
    return (
      <Error message={error} />
    );
  };

  const getName = () => {
    return (
      <div className={`${styles['name']} mr-2`}>
        <div className={styles['label']}>Name*</div>
        <input
          name={'name'}
          ref={register}
          className={'form-control'}
          disabled={loading}
          placeholder="Enter name(Spanish)"
        />
        {getFieldError(errors?.name?.message)}
      </div>
    );
  };

  const getPrice = () => {
    return (
      <div className={`${styles['name']} mr-2`}>
        <div className={styles['label']}>Price*</div>
        <input
          name={'price'}
          type={'number'}
          ref={register}
          className={'form-control'}
          disabled={loading}
          placeholder="Enter price(in dollars)"
        />
        {getFieldError(errors?.price?.message)}
      </div>
    );
  };

  const getTypeOptions = () => {
    const subscriptionTypes = Object.values(SUBSCRIPTION_TYPES);
    return subscriptionTypes.map((i) => (
      <option key={i} value={i}>{i}</option>),
    );
  };

  const getType = () => {
    return (
      <div className={`${styles['name']} ml-2`}>
        <div className={styles['label']}>Subscription Type</div>
        <select
          disabled={loading}
          ref={register}
          name='type'
        >
          {getTypeOptions()}
        </select>
      </div>
    );
  };

  const getDescription = () => {
    return (
      <div className={styles['description']}>
        <div className={styles['label']}>Description*</div>
        <textarea
          name={'description'}
          ref={register}
          className={'form-control'}
          disabled={loading}
          placeholder="Enter Description(Spanish)"
        />
        {getFieldError(errors?.description?.message)}
      </div>
    );
  };

  const getEnName = () => {
    return (
      <div className={styles['name']}>
        <div className={styles['label']}>English Name</div>
        <input
          name={'enName'}
          ref={register}
          className={'form-control'}
          disabled={loading}
          placeholder="Enter Name(English)"
        />
        {getFieldError(errors?.enName?.message)}
      </div>
    );
  };

  const getEnDescription = () => {
    return (
      <div className={styles['description']}>
        <div className={styles['label']}>English Description</div>
        <textarea
          name={'enDescription'}
          ref={register}
          className={'form-control'}
          disabled={loading}
          placeholder="Enter Description(English)"
        />
        {getFieldError(errors?.enDescription?.message)}
      </div>
    );
  };

  const getTrialPeriodDays = () => {
    return (
      <div className={styles['name']}>
        <div className={styles['label']}>Trial Period Days</div>
        <input
          name={'trialPeriodDays'}
          ref={register}
          className={'form-control'}
          disabled={loading}
          type='number'
          min={1}
          step={1}
        />
        {getFieldError(errors?.trialPeriodDays?.message)}
      </div>
    );
  };

  return (
    <div className={styles['main-container']}>
      {getHeader()}
      <div className={styles['item-details']}>
        {getName()}
        <div className='d-flex justify-content-between'>
          {getPrice()}
          {getType()}
        </div>
        {getDescription()}
        {getEnName()}
        {getEnDescription()}
        {getTrialPeriodDays()}
      </div>
      {getFooter()}
    </div>
  );
};

export default CreateSubscription;
