import GoogleMapLocationPicker from './GoogleMapLocationPicker';
import React from 'react';
import { IAddress } from '../../interfaces/address';

export const LocationPickerWrapper: React.FC<IAddress.ILocationPickerWrapper> = ({
  containerStyle,
  locationCoords,
  isVisible,
  draggable,
  onMapLocationChanged,
}) => {
  
  if (!isVisible) return null;
  
  return (
    <div>
      <GoogleMapLocationPicker
        initialCenter={locationCoords}
        styles={{ container: containerStyle }}
        onMapLocationChanged={onMapLocationChanged}
        draggable={draggable}
      />
    </div>
  );
};
