import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { mapDispatchToProps as authActions } from 'actions/auth';
import './login.scss';
import { Loading } from '../../components/loading/Loading';

const mapStateToProps = function(state: any) {
  return {
    isAuthenticated: !!state.auth.loggedInUser,
    loading: state.auth.loading,
  };
};

class Login extends React.Component<any, any> {
    state = {
      username: null,
      password: null,
    };

    login = async (event: any) => {
      event.preventDefault();
      await this.props.login(this.state.username, this.state.password);
    };

    render () {
      const { from } = this.props.location.state || { from: { pathname: '/' }};
      if (this.props.isAuthenticated && from) { return (<Redirect to={from} />) }
      return (
        <div className="login-container">
          <h4>Login</h4>
          <form>
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input type="email" className="form-control" id="email" required={true} onChange = {e => this.setState({ username: e.target.value })}/>
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input type="password" className="form-control" id="password" onChange = {e => this.setState({ password: e.target.value })}/>
            </div>
            <button type="submit" className="btn btn-primary float-right" onClick={this.login}>Login</button>
          </form>
          { this.props.loading && <Loading /> }
        </div>
      );
    }
}
const LoginWrapper = connect(mapStateToProps, authActions)(Login);
export { LoginWrapper as Login };
