import BackButton from 'components/BackButton';
import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from 'react';
import { fetchFleetMerchantInvoicesDetail } from 'services/api/invoices';
import { showError } from 'utils';
import styles from './index.module.scss';
import BasicDetails from './basicDetails';

const ShopifyInvoiceDetails = (props: any) => {
  const { invoiceId } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({}) as any;

  useEffect(() => {
    fetchFleetMerchantInvoicesDetailsFn();
  },[]);

  const fetchFleetMerchantInvoicesDetailsFn = async () => {
    setLoading(true);
    try {
      const response = await fetchFleetMerchantInvoicesDetail(invoiceId);
      setData(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading/>;
  }
  
  const getHeader = () => (
    <div className={styles['header']}>
      <div className={styles['heading']}>Invoice Details</div>
      <div className={styles['header-buttons']}>
        <BackButton />
      </div>
    </div>
  );

  return (
    <div id={'main-container'} className={`${styles['main-container']}`}>
      {loading && <Loading />}
      {getHeader()}
      <BasicDetails details={data} onRefresh={fetchFleetMerchantInvoicesDetailsFn}/>
    </div>
  );
};

export default ShopifyInvoiceDetails;