import _ from 'lodash';
import React from 'react';

import { ILead } from 'interfaces';
import { Document } from './Document';
import { DocumentUpload } from './DocumentUpload';

import './document.scss';

const DocumentSlot = ( props: { documentType: ILead.IDocumentType; documents: ILead.ILeadDocument[]; 
  userId: string; onDocumentChange: Function; }) => {

  const { documentType, documents, userId, onDocumentChange } = props;
  
  const getDocumentSlotFiller = () => {
    const documentByCode = _.keyBy(documents, 'documentCode');
    if (documentByCode[documentType.code]) {
      return <Document doc={documentByCode[documentType.code]} onDocumentChange={onDocumentChange} {...props}/>;
    } else {
      return <DocumentUpload documentType={documentType} userId={userId} onDocumentChange={onDocumentChange}/>;
    }
  };

  return <>
    {getDocumentSlotFiller()}
  </>;
};

export { DocumentSlot };
