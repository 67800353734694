import React, { useEffect, useState } from 'react';
import './index.scss';
import CityDropDown from '../components/city-drop-down';
import VehicleTypeDropDown from '../components/vehicle-type-drop-down';
import { IVehicle } from 'interfaces/vehicle';

const DriverVehicleDetail = props => {
  const { savePersonalDetailsLoading } = props;
  const [vehicleTypeList, setVehicleTypeList] = useState<IVehicle[]>(props.vehicleTypeList);

  useEffect(() => {
    setVehicleTypeList(props.vehicleTypeList);
  }, [props.vehicleTypeList]);

  const getVehicleType = (type: IVehicle | string) => {
    let objectId = type;
    if (type && typeof objectId !== 'string') {
      objectId = type['objectId'];
    }

    if (vehicleTypeList && vehicleTypeList.length > 0 && type) {
      const vehicle = vehicleTypeList.filter(vehicleType => vehicleType['objectId'] === objectId)[0];
      if (vehicle) {
        return `${vehicle.type}, ${vehicle.capacity}`;
      }
    }
    return 'N/A';
  };
  return (
    <div className={`driver-vehicle mt-2 border-top pt-2 ${props.isEditing ? 'editing': ''}`}>
      <div className="row">
        <div className="col-12">
          <span className="text-muted font-weight-bold">Driver Vehicle Detail</span>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-3 border-right">
              <div className="text-muted font-weight-bold">Model</div>
              {!props.isEditing ? <div className="font-weight-bold">{props.vehicleModel || 'N/A'}</div> : <>
                <input disabled={savePersonalDetailsLoading} type="text" className="form-control" value={props.vehicleModel}
                  onChange={event => props.onPersonalDetailChange({ 'vehicleModel': event.target.value })} />
              </>}

            </div>
            <div className="col-12 col-sm-6 col-md-3 border-right">
              <div className="text-muted font-weight-bold">Year</div>
              {!props.isEditing ? <div className="font-weight-bold">{props.vehicleYear || 'N/A'}</div> : <>
                <input disabled={savePersonalDetailsLoading} type="text" className="form-control" value={props.vehicleYear}
                  onChange={event => props.onPersonalDetailChange({ 'vehicleYear': event.target.value })} />
              </>}
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="text-muted font-weight-bold">Color</div>
              {!props.isEditing ? <div className="font-weight-bold">{props.vehicleColor || 'N/A'}</div> : <>
                <input disabled={savePersonalDetailsLoading} type="text" className="form-control" value={props.vehicleColor}
                  onChange={event => props.onPersonalDetailChange({ 'vehicleColor': event.target.value })} />
              </>}
            </div>
            <div className="col-12 col-sm-6 col-md-3 border-right">
              <div className="text-muted font-weight-bold">City of Work</div>
              {!props.isEditing ? <div className="font-weight-bold">{props.cityOfWork || 'N/A'}</div> : <>
                <CityDropDown disabled={savePersonalDetailsLoading} city={props.cityOfWork} loading={props.cityLoading} cities={props.cities}
                  onChange={event => props.onPersonalDetailChange({ 'cityOfWork': event.target.value })}/>
              </>}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-sm-6 col-md-3 border-right">
              <div className="text-muted font-weight-bold">Vehicle Type</div>
              {!props.isEditing ? <div className="font-weight-bold text-capitalize">{getVehicleType(props.vehicleType)}</div> : <>
                <VehicleTypeDropDown disabled={savePersonalDetailsLoading} type={props.vehicleType} vehicleList={vehicleTypeList}
                  onChange={event => props.onPersonalDetailChange({ 'vehicleType': event.target.value })} />
              </>}
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="text-muted font-weight-bold">Vehicle Make</div>
              {!props.isEditing ? <div className="font-weight-bold">{props.vehicleMake || 'N/A'}</div> : <>
                <input disabled={savePersonalDetailsLoading} type="text" className="form-control" value={props.vehicleMake}
                  onChange={event => props.onPersonalDetailChange({ 'vehicleMake': event.target.value })} />
              </>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverVehicleDetail;
