import React, { useState } from 'react';
import './index.scss';
import moment from 'moment';
import { getDailyDriverPaymentSummary } from 'services/api/reports';
import { showError } from 'utils/notifications';
import { Loading } from 'components/loading/Loading';
import { tableStyles } from '../../../../css/tableStyles';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { DATE_FORMATS, formatDate } from 'utils';
import ActionButton from 'components/ActionButton/ActionButton';
import { DateRangePickerWrapper } from 'components/Date/DateRangePicker';
import { DRIVER_EARNING_DATA_TABLE_COLUMNS } from 'utils/driverEarningConstants';

const DriverDailyEarnings = props => {
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null | string>('');
  const [endDate, setEndDate] = useState<Date | null | string>('');
  const [earnings, setEarnings] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const columns: IDataTableColumn<any>[] = DRIVER_EARNING_DATA_TABLE_COLUMNS.concat({
    name: 'Date',
    selector: 'createdAt',
    sortable: false,
    format: row => formatDate(row.createdAt, DATE_FORMATS.MM_DD_YYYY),
  });

  const onDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    startDate && setStartDate(moment(startDate).toISOString());
    endDate && setEndDate(moment(endDate).toISOString());
  };

  const fetchEarnings = async () => {
    setLoading(true);
    try {
      const response = await getDailyDriverPaymentSummary(startDate, endDate, props.objectId);
      setEarnings(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const onChangeRowPerPage = (rowsPerPage: number, currentPage: number) => {
    setPageNumber(currentPage);
    setPageSize(rowsPerPage);
  };

  const onChangePage = (page: number) => {
    setPageNumber(page);
  };

  const getEarnings = () => {
    if (loading) {
      return <Loading type="mini" />;
    } else {
      return (<>
        <div className="col-12 data-table">
          <DataTable
            keyField="objectId"
            columns={columns}
            data={earnings.slice((pageNumber - 1) * pageSize, ((pageNumber - 1) * pageSize + pageSize))}
            highlightOnHover={true}
            customStyles={tableStyles}
            pagination={true}
            paginationServer={true}
            paginationDefaultPage={pageNumber}
            paginationPerPage={pageSize}
            paginationTotalRows={earnings.length}
            onChangeRowsPerPage={onChangeRowPerPage}
            onChangePage={onChangePage}
          />
        </div>
      </>);
    }
  };

  return (
    <div className="driver-earnings">
      <div className="row">
        <div className="col-12 mb-2">
          <div className="row">
            <div className="col-6">
              <div className="text-muted font-weight-bold">Driver Daily Payments</div>
            </div>
            <div className="col-6 text-right">
              <div className="d-inline-block">
                <DateRangePickerWrapper
                  startDate={startDate}
                  endDate={endDate}
                  onDatesChange={onDateSelectionChange}
                />
              </div>
              <ActionButton text="Submit" active={!!(startDate && endDate)} onClick={fetchEarnings} isLoading={loading} customClass="btn-primary ml-2" />
            </div>
          </div>
        </div>
        {getEarnings()}
      </div>
    </div>
  );
};

export default DriverDailyEarnings;
