import React, { useEffect, useState } from 'react';
import './lead-filter.scss';
import moment from 'moment';
import qs from 'query-string';
import { DateRangePickerWrapper } from 'components/Date/DateRangePicker';
import { ILead } from 'interfaces';
import NameFilterAutosuggest from 'components/NameFilterAutoSuggest';
import { fetchLeadEventNames, searchLeadByName } from 'services';
import ActionButton from 'components/ActionButton/ActionButton';
import Dropdown from 'components/Dropdown';

const LeadFilter = props => {
  const [userSignedUp, setUserSignedUp] = useState<string>();
  const [isInterested, setIsInterested] = useState<string>();
  const [isDeclined, setIsDeclined] = useState<string>();
  const [startDate, setStartDate] = useState<string | null>();
  const [endDate, setEndDate] = useState<string | null>();
  const [userId, setUserId] = useState<string>('');
  const [leadId, setLeadId] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [events, setEvents] = useState<any>([]);
  const [lastAction, setLastAction] = useState<string>('');

  useEffect(() => {
    const {
      userSignedUp,
      approved,
      isInterested,
      isDeclined,
      startDate,
      endDate,
      userId,
      leadId,
      email,
      lastAction,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
    fetchLeadEventNamesFn();
    if (userSignedUp) setUserSignedUp(userSignedUp.toString());
    if (isInterested) setIsInterested(isInterested.toString());
    if (isDeclined) setIsDeclined(isDeclined.toString());
    if (startDate) setStartDate(startDate.toString());
    if (endDate) setEndDate(endDate.toString());
    if (userId) setUserId(userId.toString());
    if (leadId) setLeadId(leadId.toString());
    if (email) setEmail(email.toString());
    if (lastAction) setLastAction(lastAction.toString());
  }, []);

  const fetchLeadEventNamesFn=async()=>{
    const response =await fetchLeadEventNames();
    setEvents(response);
  };
  const onDateSelectionChange = ({ startDate, endDate }: {startDate: Date; endDate: Date}) => {
    setStartDate(moment(startDate).toISOString());
    setEndDate(moment(endDate).toISOString());
  };

  const resetFilter = () => {
    setUserSignedUp('');
    setIsInterested('');
    setIsDeclined('');
    setStartDate('');
    setEndDate('');
    setUserId('');
    setLeadId('');
    setEmail('');
    setLastAction('');

    const filterData = {
      userSignedUp: '',
      approved: '',
      isInterested: '',
      isDeclined: '',
      startDate: '',
      endDate: '',
      userId: '',
      leadId: '',
      email: '',
      lastAction:'',
    };

    props.onFilterChange && props.onFilterChange(filterData);
  };

  const emitFilterChange = (e) => {
    e && e.preventDefault();

    const filterData: ILead.ILeadFilter = {
      startDate,
      endDate,
      userId,
      leadId,
      email,
      lastAction,
    };

    if (userSignedUp === 'true') filterData.userSignedUp = true;
    if (userSignedUp === 'false') filterData.userSignedUp = false;

    if (isInterested === 'true') filterData.isInterested = true;
    if (isInterested === 'false') filterData.isInterested = false;

    if (isDeclined === 'true') filterData.isDeclined = true;
    if (isDeclined === 'false') filterData.isDeclined = false;

    props.onFilterChange && props.onFilterChange(filterData);
  };

  return (
    <form className="leadFilter d-flex column align-items-center">
      <div className='select-container'>
        <strong>Signed Up Status</strong>
        <select className="form-control" value={userSignedUp} onChange={e => setUserSignedUp(e.target.value)}>
          <option value="all">All</option>
          <option value={'true'}>Signed up User</option>
          <option value={'false'}>Non Signed up User</option>
        </select>
      </div>
      <div className='select-container'>
        <strong>Is Interested</strong>
        <select className="form-control" value={'' + isInterested} onChange={e => setIsInterested(e.target.value)}>
          <option value=''>All</option>
          <option value={'true'}>Interested</option>
          <option value={'false'}>Not Interested</option>
        </select>
      </div>
      <div className='select-container ml-4 mr-4'>
        <strong>Is Declined</strong>
        <select className="form-control" value={'' + isDeclined} onChange={e => setIsDeclined(e.target.value)}>
          <option value={''}>All</option>
          <option value={'true'}>Declined</option>
          <option value={'false'}>Not Declined</option>
        </select>
      </div>
      <div className="d-flex flex-column ml-4 mr-4">
        <strong>Date Range</strong>
        <DateRangePickerWrapper
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onDateSelectionChange}
        />
      </div>
      <div className="d-flex flex-column mr-4">
        <strong>User Id</strong>
        <input type="text" name="userId" className="border rounded p-1" value={userId} onChange={e => setUserId(e.target.value)} />
      </div>
      <div className="d-flex flex-column mr-4">
        <strong>Email</strong>
        <input type="text" name="email" className="border rounded p-1" value={email} onChange={e => setEmail(e.target.value)} />
      </div>
      <div className='d-flex flex-column mr-4'>
        <strong>Lead Name</strong>
        <NameFilterAutosuggest
          entityId={leadId}
          placeholder={'Lead Name'}
          onSuggestionSelected={setLeadId}
          fetchSuggestions={searchLeadByName}
        />
      </div>
      <div className="d-flex flex-column mr-4">
        <strong>Last Action</strong>
        <Dropdown 
          value={lastAction}
          onChange={setLastAction}
          options={events}
          name={'Last Action'}
          areOptionsStringArray={true}
        />
      </div>
      <div className='button-container'>
        <ActionButton
          text={'Submit'}
          active={true}
          isLoading={false}
          onClick={emitFilterChange}
          customClass={'btn btn-primary float-left'}
        />
        <ActionButton
          text={'Reset'}
          active={true}
          isLoading={false}
          onClick={resetFilter}
          customClass={'btn btn-primary float-left ml-4'}
        />
      </div>
    </form>
  );
};

export { LeadFilter };
