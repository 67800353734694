import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from 'react';
import { getFleetOrdersReport } from 'services/api/common';
import { showError } from 'utils/notifications';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import qs from 'query-string';
import FleetOrdersFilter from './filter';
import { tableStyles } from 'css/tableStyles';
import './index.scss';
import TableLink from 'TableLink';

let filters: { [p: string]: any; page: number } = {
  page: 0,
  endDate: moment().toString(),
  startDate: moment().subtract(1, 'month').toString(),
};

const columns = [
  {
    name: 'Order ID',
    selector: 'orderId',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/orders/${row.orderId}`}
        text={row.orderId}
        openInNewTab={true}
      />
    ),
  },
  {
    name: 'Route ID',
    selector: 'routeId',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/routes/${row.routeId}`}
        text={row.routeId}
        openInNewTab={true}
      />
    ),
  },
  {
    name: 'Merchant ID',
    selector: 'merchantId',
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/restaurants/${row.merchantId}`}
        text={row.merchantId}
        openInNewTab={true}
      />
    ),
    sortable: false,
  },
  {
    name: 'Merchant Name',
    selector: 'merchantName',
    sortable: false,
  },
  {
    name: 'Order Date',
    selector: 'orderDate',
    sortable: false,
  },
  {
    name: 'Delivery Address',
    selector: 'deliveryAddress',
    sortable: false,
  },
  {
    name: 'Pick up address',
    selector: 'pickUpAddress',
    sortable: false,
  },
  {
    name: 'Pickedup At',
    selector: 'pickedUpAt',
    sortable: false,
  },
  {
    name: 'Delivered At',
    selector: 'deliveredAt',
    sortable: false,
  },
  {
    name: 'Pickup Photo',
    selector: 'pickupPhoto',
    sortable: false,
  },
  {
    name: 'Delivery Photo',
    selector: 'deliveryPhoto',
    sortable: false,
  },
  {
    name: 'Delivery Comment',
    selector: 'deliveryComment',
    sortable: false,
  },
  {
    name: 'Pickup Comment',
    selector: 'pickupComment',
    sortable: false,
  },
  {
    name: 'Order Distance (mi)',
    selector: 'miles',
    sortable: false,
  },
  {
    name: 'Order Duration (minutes)',
    selector: 'duration',
    sortable: false,
  },
  {
    name: 'Route Distance (mi)',
    selector: 'milesInRoute',
    sortable: false,
  },
  {
    name: 'Route Duration (minutes)',
    selector: 'durationOfRoute',
    sortable: false,
  },
  {
    name: 'Delivery Charges',
    selector: 'deliveryCharges',
    sortable: true,
  },
  {
    name: 'Delivery Taxes',
    selector: 'deliveryTaxes',
    sortable: false,
  },
];

const FleetOrdersReport = props => {
  const [fleetOrders, setFleetOrders] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchFleetOrders = async () => {
    setLoading(true);
    try {
      const response = await getFleetOrdersReport(filters);
      setFleetOrders(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    };
  };

  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  useEffect(() => {
    const filtersFromQuery: Record<string, any> = queryStringToFilter();
    filters = {
      ...filters,
      ...filtersFromQuery,
    };
    if (filtersFromQuery?.selectedRestaurantId?.length) {
      fetchFleetOrders();
    }
  }, []);

  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onFilterChanged = (udpatedfilters: Record<string, any>) => {
    filters = { ...udpatedfilters, page: 0 };
    updateQueryString();
    if (udpatedfilters?.selectedRestaurantId?.length) {
      fetchFleetOrders();
    } else {
      setFleetOrders([]);
    }
  };

  return (
    <div>
      {loading && <Loading />}
      <FleetOrdersFilter {...props} onFilterSubmit={onFilterChanged} />
      <DataTable
        title="Fleet Orders"
        columns={columns}
        data={fleetOrders}
        highlightOnHover={true}
        defaultSortField="createdAt"
        customStyles={tableStyles}
        pagination={false}
      />
    </div>
  );
};

export default FleetOrdersReport;