import React, { useEffect, useState } from 'react';
import './index.scss';
import ActionButton from 'components/ActionButton/ActionButton';
import { addTollByAdmin, fetchAvailableTolls } from 'services/api/order';
import _ from 'lodash';
import { showError } from 'utils/notifications';

const AddToll = props => {
  const { closeModal, onSuccess, orderId } = props;
  const [tollName, setTollName] = useState<string>('');
  const [tollAmount, setTollAmount] = useState<number>(0);
  const [tollOptions, setTollOptions] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(false);
  
  useEffect(() => {
    (async () => {
      const tolls = await fetchAvailableTolls();
      const tollOptions: Record<string, any>[] = [];
      _.forOwn(tolls, (value, key) => tollOptions.push({
        name: key,
        amount: value,
      }));
      setTollOptions(tollOptions);
    })();
  }, []);

  const addToll = async () => {
    setLoading(true);
    try {
      await addTollByAdmin(orderId, tollName, tollAmount);
      onSuccess();
    } catch(e) {
      showError(e);
      setLoading(false);
    }
  };
  
  const getTollNames = () => {
    if (!tollOptions.length) return;
    return tollOptions.map((option, i) => <option key={i} value={option.name}>{option.name}</option>);
  };
  
  const onChange = (value: string) => {
    const toll = _.find(tollOptions, { name: value });
    setTollName(value);
    setTollAmount(toll.amount);
  };
  
  return (
    <div className='add-toll-container'>
      <div className='add-toll-modal'>
        <div className='modal-header'>
          <div>Add Toll</div>
          <i className='fa fa-close' onClick={closeModal} />
        </div>
        <div className='modal-body'>
          <div className='toll-name'>
            <label>Toll Name</label>
            <select
              className='form-control'
              value={tollName || ''}
              onChange={e => onChange(e.target.value)}
            >
              <option hidden={!!tollName} value={'default'}>Select an option</option>
              {getTollNames()}
            </select>
          </div>
          <div className='toll-amount'>
            <label>Toll Amount</label>
            <input
              className='form-control'
              disabled={true}
              value={tollAmount}
            />
          </div>
        </div>
        <div className='modal-footer'>
          <ActionButton
            text='Cancel'
            active={true}
            isLoading={false}
            onClick={closeModal}
          />
          <ActionButton
            text='Add Toll'
            active={!loading}
            isLoading={false}
            customClass='btn-primary'
            onClick={addToll}
          />
        </div>
      </div>
    </div>
  );
};

export default AddToll;