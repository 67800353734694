import React from 'react';
import { DATE_FORMATS, formatDate } from 'utils';
import './index.scss';

const DriverOrderSummary = props => {
  return (
    <div className="row mt-3 driver-order-summary mb-2">
      <div className="col-12 col-sm-6 col-md-3 mt-2 mt-sm-0">
        <div className="info-block">
          <div className="row">
            <div className="col-7">
              <div>Total Orders</div>
              <div className="earning-amount font-weight-bold">
                { props.totalOrderServed ? props.totalOrderServed.toLocaleString() : '0'}
              </div>
            </div>
            <div className="col d-flex justify-content-center align-items-center pl-0">
              <img src={require('assets/bar-chart.png')} alt="bar-chart" className="bar-chart"/>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-md-3 mt-2 mt-sm-0">
        <div className="info-block">
          <div>Driver Since</div>
          <div className="earning-amount font-weight-bold">
            {formatDate(props.driverApprovedAt?.iso || props.createdAt, DATE_FORMATS.MMM_DD_YYYY)}
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-md-3 mt-2 mt-sm-0">
        <div className="info-block">
          <div>First Order</div>
          <div className="earning-amount font-weight-bolder">
            { props.firstOrderServedAt ? formatDate(props.firstOrderServedAt.iso, DATE_FORMATS.MMM_DD_YYYY) : 'N/A' }
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-md-3 mt-2 mt-sm-0">
        <div className="info-block">
          <div>Latest Order</div>
          <div className="earning-amount font-weight-bolder">
            { props.lastOrderServedAt ? formatDate(props.lastOrderServedAt.iso, DATE_FORMATS.MMM_DD_YYYY) : 'N/A' }
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverOrderSummary;
