import { cloudFunction } from 'services/parse';
import _ from 'lodash';

export const fetchAvailableTolls = async () => {
  const response = await cloudFunction('fetchAvailableTolls', {});
  return response.map(toll=> toll.toJSON());
};

export const fetchTolls = async (data: Record<string, any>) => {
  let response = await cloudFunction('fetchTolls', data);
  response = {
    ...response,
    items: response.items.map(item => ({
      ...item,
      coords: _.get(item, 'coords', { latitude: '', longitude: '' }),
    })),
  };
  return response;
};

export const createToll = (data: Record<string, any>) => {
  return cloudFunction('createToll', data);
};

export const updateToll = (data: Record<string, any>) => {
  return cloudFunction('updateToll', data);
};

export const deleteToll = (id: string) => {
  return cloudFunction('deleteToll', { id });
};