import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { ACTION_ICON_TYPES, getPageLimit, updatePageLimit } from 'utils';
import { Loading } from 'components/loading/Loading';
import { showError, showSuccess } from 'utils/notifications';
import { tableStyles } from 'css/tableStyles';
import { addBlacklistedDriverForMerchant, fetchBlacklistedDriversForMerchant, removeBlacklistedDriverForMerchant } from 'services/api/blacklists';
import ActionButton from 'components/ActionButton/ActionButton';
import ActionIcon from 'components/ActionIcon';
import AddDriver from 'components/AddDriver';
import TableLink from 'TableLink';

const RestaurantBlacklistedDrivers = props => {
  const [drivers, setDrivers] = useState<Record<string, any>>({});
  const [page, setPage] =useState<number>(0);
  const [addDriverModalOpen, setAddDriverModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      name: 'ID',
      selector: 'objectId',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/drivers/${row.objectId}`}
          text={row.objectId}
          openInNewTab={false}
        />
      ),
    },
    {
      name: 'Driver Name',
      selector: 'full_name',
      sortable: false,
    },
    {
      name: 'Remove',
      selector: '',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <div className={'ml-5'}>
          <ActionIcon
            actionType={ACTION_ICON_TYPES.DELETE}
            loading={false}
            onClick={(e)=>removeDriverFromBlacklist(row?.objectId)}
          />
        </div> 
      ),
    },
  ];

  const fetchBlacklistedDrivers = async () => {
    setLoading(true);
    try {
      const response = await fetchBlacklistedDriversForMerchant({
        limit: 1000,
        restaurantId: props?.restaurantId,
      });
      setDrivers(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const addDriverToBlackList = async (driverId) => {
    setLoading(true);
    try {
      setAddDriverModalOpen(!addDriverModalOpen);
      await addBlacklistedDriverForMerchant({ driverId, restaurantId: props?.restaurantId });
      await fetchBlacklistedDrivers();
      showSuccess('Restaurant added to User successfully');
    } catch(e) {
      showError(e);
      setLoading(false);
    }
  };

  const removeDriverFromBlacklist = async (driverId)=>{
    try{
      setLoading(true);
      await removeBlacklistedDriverForMerchant({ driverId, restaurantId: props?.restaurantId });
      await fetchBlacklistedDrivers();
      showSuccess('Restaurant removed from blacklist');
    }catch(e){
      showError(e);
    }finally{
      setLoading(false);
    }
  };

  const onChangePage = (page: number) => {
    setPage(page - 1);
    fetchBlacklistedDrivers();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };
  
  useEffect(() => {
    fetchBlacklistedDrivers();
  }, []);

  return (
    <div>
      {loading && <Loading />}
      {addDriverModalOpen && (
        <AddDriver
          toggleModal={() => setAddDriverModalOpen(!addDriverModalOpen)}
          onSubmit={addDriverToBlackList}
        />
      )}
      <div className={'d-flex justify-content-between mb-2 mt-2 pr-3'}>
        <div className='pl-2 font-weight-bolder text-muted'>Blacklisted Drivers</div>
        <ActionButton
          text='Add Driver'
          active={true}
          isLoading={false}
          customClass={'btn btn-primary'}
          onClick={() => setAddDriverModalOpen(true)}
        />
      </div>
      <DataTable
        noHeader={true}
        keyField="objectId"
        columns={columns}
        data={drivers.items}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationTotalRows={drivers.total}
        paginationDefaultPage={page + 1}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </div>
  );
};

export { RestaurantBlacklistedDrivers };
