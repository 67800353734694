import ActionButton from 'components/ActionButton/ActionButton';
import { DateRangePickerWrapper } from 'components/Date/DateRangePicker';
import NameFilterAutosuggest from 'components/NameFilterAutoSuggest';
import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import moment from 'moment';
import { searchUserByName } from 'services/api/order';
import RestaurantAutoSuggest from 'components/RestaurantAutoSuggest';

const OrderReviewsFilters = props => {
  const [startDate, setStartDate] = useState<string | null>(moment().subtract(1, 'week').toISOString());
  const [endDate, setEndDate] = useState<string | null>(moment().toISOString());
  const [driverId, setDriverId] = useState<string>('');
  const [restaurantId, setRestaurantId] = useState<string>('');
  const [userId, setUserId] = useState<string>('');

  useEffect(() => {
    const {
      startDate,
      endDate,
      driverEmail: driverId,
      restaurantId,
      userId,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });

    if (startDate) setStartDate(startDate.toString());
    if (endDate) setEndDate(endDate.toString());
    if (driverId) setDriverId(driverId.toString());
    if (restaurantId) setRestaurantId(restaurantId.toString());
    if (userId) setUserId(userId.toString());
  }, []);

  const resetFilter = () => {
    setStartDate('');
    setEndDate('');
    setDriverId('');
    setRestaurantId('');
    setUserId('');

    const filterData = {
      startDate: '',
      endDate: '',
      driverId: '',
      restaurantId: '',
      userId: '',
    };

    props.onFilterSubmit(filterData);
  };

  const onFilterSubmit = () => {
    const filterData: Record<string, any> = {
      startDate,
      endDate,
      driverId,
      restaurantId,
      userId,
    };

    props.onFilterSubmit(filterData);
  };

  const onDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    setStartDate(moment(startDate).toISOString());
    setEndDate(moment(endDate).toISOString());
  };

  return (
    <form className='filter-container'>
      <div className='input-container mb-2'>
        <label>User Name</label>
        <NameFilterAutosuggest
          entityId={userId}
          placeholder={'User Name'}
          onSuggestionSelected={setUserId}
          fetchSuggestions={searchUserByName}
        />
      </div>
      <div className='input-container mb-2 ml-2'>
        <label>Driver Id</label>
        <input
          value={driverId}
          onChange={e => setDriverId(e.target.value)}
          className={'form-control'}
        />
      </div>
      <div className='input-container mb-2 ml-2'>
        <label>Driver Name</label>
        <NameFilterAutosuggest
          entityId={driverId}
          placeholder={'Driver Name'}
          onSuggestionSelected={setDriverId}
          onlyDriver={true}
          fetchSuggestions={searchUserByName}
        />
      </div>
      <div className='input-container mb-2 ml-2'>
        <label>Date Range</label>
        <DateRangePickerWrapper
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onDateSelectionChange}
        />
      </div>
      <div className={'input-container ml-2'}>
        <label>Restaurant Id</label>
        <input
          value={restaurantId}
          onChange={e => setRestaurantId(e.target.value)}
          className={'form-control'}
        />
      </div>
      <div className='input-container ml-2'>
        <label>Restaurant</label>
        <RestaurantAutoSuggest
          placeholder={'Search Restaurant'}
          onSuggestionSelect={suggestion => setRestaurantId(suggestion.id)}
        />
      </div>
      <div className={'buttons-container'}>
        <ActionButton
          text={'Submit'}
          isLoading={false}
          active={true}
          customClass={'btn-primary mb-2'}
          onClick={onFilterSubmit}
        />
        <ActionButton
          text={'Reset'}
          isLoading={false}
          active={true}
          customClass={'btn-primary ml-4 mb-2'}
          onClick={resetFilter}
        />
      </div>
    </form>
  );
};

export default OrderReviewsFilters;