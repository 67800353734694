import React from 'react';
import { MultiSelect } from 'react-multi-select-component';
import './index.scss';

const MultiSelectDropdown = (props) => {
  const {
    value,
    options,
    onChange,
    disableSearch,
    disabled,
    labelAccessor = 'label',
    hasSelectAll = true,
  } = props;

  const renderItems = ({
    checked,
    option,
    onClick,
  }) => (
    <div className={`react-multi-select-custom-option ${checked && 'checked'}`} onClick={onClick}>
      <div className={''}>{option[labelAccessor]}</div>
    </div>
  );
  
  return (
    <MultiSelect
      className={`${disabled && 'disabled'} w-100`}
      disabled={disabled}
      disableSearch={disableSearch}
      value={value}
      options={options}
      onChange={onChange}
      labelledBy={'Select'}
      ItemRenderer={renderItems}
      hasSelectAll={hasSelectAll}
    />
  );
};

export default MultiSelectDropdown;