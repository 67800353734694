import React from 'react';
import { DATE_FORMATS, formatDate } from 'utils';
import GetDriverDetailsTemplate from './DriverDetailsTemplate';

const NotAcceptedByDrivers = (props) => {
  const { orderDetails = {}} = props;
  if (!orderDetails?.notAcceptedByDrivers?.length) return null;
  const drivers = orderDetails?.notAcceptedByDrivers?.map((driver) => {
    return (
      <GetDriverDetailsTemplate 
        id={driver?.driverId} 
        details={[
          {
            key: 'Assigned At',
            value: formatDate(driver?.driverAssignedAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
          },
        ]} 
      />);
  });
  return (
    <div className={'not-accepted-by-driver-details-section'}>
      <div className={'section-header'}>
        <div className={'label'}>Not Accepted By Drivers</div>
      </div>
      <div className='drivers'>
        {drivers}
      </div>
    </div>
  );
};

export default NotAcceptedByDrivers;