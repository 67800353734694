import React from 'react';
import AsyncJobs from 'components/AsyncJobs/AsyncJobs';
import { formatDate } from 'utils';

const CopyRestaurantMenuJobs = props => {
  const onRowClicked = row => {
    props.history.push(`/job-detail/${row.id}`);
  };

  const columns = [
    {
      name: 'Job ID',
      selector: 'id',
      sortable: false,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: false,
    },
    {
      name: 'Created At',
      selector: 'createdAt',
      sortable: true,
      format: (row: any) => formatDate(row.createdAt),
    },
    {
      name: 'Created By',
      selector: 'startedBy',
      sortable: false,
    },
  ];

  return (
    <>
      <button
        onClick={()=> props.history.push('/copy-restaurant-menu')}
        className='btn btn-primary float-right'
      >
                Copy Restaurant Menu
      </button>
      <AsyncJobs
        type='COPY_RESTAURANT_MENU'
        columns={columns}
        onRowClicked={onRowClicked}
      />
    </>
  );
};

export { CopyRestaurantMenuJobs };
