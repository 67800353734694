import React, { useState } from 'react';
import AddDishVariantDetailsForm from './detailsForm';
import DishVarientsList from '../DishVariants/List/index';
import AddDishVariantModalFooter from './footer';
import AddDishVariantModalHeader from './header';
import './index.scss';

const detailsFormInitialValues = {
  name: '', price: 0, minCount: 0, maxCount: 0, minSelectedVariants: 0, maxSelectedVariants: 0,
};

const AddDishVariantModal = props => {
  const {
    toggleModal,
    dishDetails,
    setVariantsArray,
    variantsHashMap,
    variantsArray,
    variantsHashMapWithDetails,
  } = props;
  
  const dishId = dishDetails?.objectId;
  const [variantLevels, setVariantLevels] = useState([dishId]);
  const [isAddFormVisible, setIsAddFormVisible] = useState(false);
  const [newVariantDetails, setNewVariantDetails] = useState(detailsFormInitialValues);
  
  const getLastLevelVariants = () => {
    const totalLevels = variantLevels?.length;
    return variantsHashMap[variantLevels[totalLevels - 1]];
  };

  const onClickVariantEdit = (variant)=>{
    setNewVariantDetails(variant);
    setIsAddFormVisible(true);
  };
  
  const getLastLevelVariantId = () => {
    const totalLevels = variantLevels?.length;
    return variantLevels[totalLevels - 1];
  };

  return (
    <div className={'add-variant-modal-main-container'}>
      <div className={'add-variant-modal-container'}>
        <AddDishVariantModalHeader 
          toggleModal={toggleModal} 
          isAddFormVisible={isAddFormVisible} 
          newVariantDetails={newVariantDetails}
          parentVariantDetails={variantsHashMapWithDetails[getLastLevelVariantId()]}
        />
        {isAddFormVisible ? 
          <AddDishVariantDetailsForm 
            setNewVariantDetails={setNewVariantDetails} 
            newVariantDetails={newVariantDetails}
          /> 
          : 
          <DishVarientsList 
            dishDetails={dishDetails} 
            dishVariants={getLastLevelVariants()} 
            setVariantLevels={setVariantLevels} 
            variantLevels={variantLevels}
            setVariantsArray={setVariantsArray}
            variantsArray={variantsArray}
            isNameClickable
            variantsHashMap={variantsHashMap}
            onClickVariantEdit={onClickVariantEdit}
          />
        }
        <AddDishVariantModalFooter 
          setVariantLevels={setVariantLevels} 
          variantLevels={variantLevels} 
          setIsAddFormVisible={setIsAddFormVisible}
          isAddFormVisible={isAddFormVisible}
          newVariantDetails={newVariantDetails}
          setVariantsArray={setVariantsArray}
          dishDetails={dishDetails}
          setNewVariantDetails={setNewVariantDetails}
          detailsFormInitialValues={detailsFormInitialValues}
        />
      </div>
    </div>
  );
};

export default AddDishVariantModal;