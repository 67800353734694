import React from 'react';
import './index.scss';
import { convertJSONtoCSV, downloadFile } from 'utils/fileUtils';

const ExportCSVButton = props => React.useMemo(() => {
  const { columnsForCSV, data, fileName } = props;
  
  const downloadCSV = () => {
    downloadFile(convertJSONtoCSV(columnsForCSV, data), fileName);
  };

  return (
    <button className='export-btn' onClick={downloadCSV}>Export</button>
  );
}, [props.data]);

export default ExportCSVButton;