import React, { useEffect, useState } from 'react';
import { getScrapperServerInstances } from 'services/api/scrappers';

const ScrapperServerInstanceDropDown = props => {
  const { value, onChange } = props;
  const [scrapperServerInstances, setScrapperServerInstances] = useState([]);
  
  const fetchAndSetScrapperServerInstances = async () => {
    const instances = await getScrapperServerInstances() || [];
    const scrapperInstances = instances.concat(NONE);
    setScrapperServerInstances(scrapperInstances || []);
  };
  
  useEffect(() => {
    fetchAndSetScrapperServerInstances();
  }, []);

  const getOptions = () => {
    return scrapperServerInstances.map((instance, i) => (
      <option key={i} value={instance}>{instance}</option>
    ));
  };
  
  return (
    <select
      value={value}
      className={'form-control'}
      onChange={e => onChange(e.target.value)}
    >
      <option value={'undefined'} hidden={!!value}>Choose an instance</option>
      {getOptions()}
    </select>
  );
};

export const NONE = 'None';
export default ScrapperServerInstanceDropDown;