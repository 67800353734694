import React from 'react';
import { Switch } from 'react-router-dom';
import { USER_ROLES } from 'utils';
import { PrivateRoute } from './PrivateRoute';
import { connect } from 'react-redux';

import {
  CategoriseDishes,
  CopyRestaurantMenu,
  CopyRestaurantMenuJobs,
  DishImportsList,
  Drivers,
  DuplicateOrders,
  ImportDish,
  LeadDetail,
  Leads,
  Login,
  NotificationsList,
  Orders,
  PaymentSummary,
  RouteDetail,
  Routes,
  SendNotifications,
  SingleOrderWithRouter as SingleOrder,
  Tags,
  Users,
  UvaCategories,
} from '../pages';

import AsyncJobDetail from 'components/AsyncJobs/AsyncJobDetail';
import ReviewReport from 'pages/reports/ReviewReport';
import BonusReport from 'pages/reports/BonusReport';
import PaymentSummaryReport from 'pages/reports/PaymentSummaryReport';
import DriverPaymentReport from 'pages/reports/DriverPaymentReport';
import FleetOrdersReport from 'pages/reports/fleet-orders/FleetOrders';
import RevenueReport from 'pages/reports/revenue-overview';
import PublicRoute from './PublicRoute';
import { UserDetails } from 'pages/users/user-details';
import DriverDetails from 'pages/drivers/driver-details';
import { DeferredOrders } from 'pages/deferred-orders';
import { DeferredOrderDetails } from 'pages/deferred-orders/OrderDetails';
import { ScrapperDetails, Scrappers } from 'pages/scrappers';
import { CreateScrapper } from 'pages/scrappers/create-scrapper';
import { OrderReviews } from 'pages/order-reviews';
import { Refunds } from 'pages/refund';
import { EditRestaurantInfo, RestaurantDetails, Restaurants } from 'pages/restaurants';
import RestaurantStats from 'pages/restaurant-stats';
import { AddDish, DishDetails, Dishes } from 'pages/dishes';
import DiscountRedemptionReport from 'pages/reports/DiscountRedemptionReport';
import Config from 'pages/config';
import DriversData from 'pages/reports/DriversData';
import CronJobs from 'pages/cron-jobs/list';
import CronJobDetails from 'pages/cron-jobs/details';
import TollCrud from 'pages/toll-crud';
import CloudRequests from 'pages/cloud-request/CloudRequests';
import CloudRequestDetail from 'pages/cloud-request/cloud-request-detail';
import TaxSummaryReport from 'pages/reports/TaxSummaryReport';
import SendTaxSummary from 'pages/tax-summary';
import DriverEvents from 'pages/reports/DriverEvents';
import LeadReports from 'pages/reports/LeadReports';
import DriverNoteReport from 'pages/reports/DriverNoteReport';
import UvaCategoryDetails from 'pages/uva-categories/uva-category-details';
import { EditUvaCategoryInfo } from 'pages/uva-categories/edit-uva-category-details';
import { CreateUvaCategory } from 'pages/uva-categories/create';
import { DishCollection } from 'pages/dish-collection/DishCollection';
import { DishCollectionDetails } from 'pages/dish-collection';
import FleetOrderPaymentSummary from 'pages/reports/FleetOrderPaymentSummary';
import OrderRejectionSummary from 'pages/reports/order-rejection-summary/index';
import { RestaurantCollection, RestaurantCollectionDetails } from 'pages/restaurant-collection';
import { AutomaticMerchantCollectionDetails, AutomaticMerchantCollectionList,CreateAutomaticMerchantCollection } from 'pages/automatic-merchant-collection';
import Discounts from '../pages/discounts/list';
import { CreateDiscount } from 'pages/discounts/create';
import { DiscountDetails } from 'pages/discounts';
import StripeProductsList from 'pages/stripe-products/list';
import { CreateStripeProduct } from 'pages/stripe-products/create';
import StripeProductDetails from 'pages/stripe-products/details';
import CreateSubscription from 'pages/subscriptions/create';
import EditSubscription from 'pages/subscriptions/edit';
import SubscriptionDetails from 'pages/subscriptions/details';
import PrimeSubscriptionDiscountReport from 'pages/reports/PrimeSubscriptionDiscountReport';
import { CreateRestaurant } from 'pages/restaurants/create';
import Disputes from 'pages/disputes/list';
import DisputeDetails from 'pages/disputes/detail';
import UserCarts from 'pages/user-carts/list';
import UserCartDetails from 'pages/user-carts/details';
import { UserSubscriptionDetails, UsersSubscriptions } from 'pages/users-subscriptions';
import { ShopifyOrdersList } from 'pages/shopify/shopify-orders/list';
import { ShopifyMerchantsList } from 'pages/shopify/shopify-merchants/list';
import ShopifyMerchantDetails from 'pages/shopify/shopify-merchants/details';
import ShopifyOrderDetails from 'pages/shopify/shopify-orders/details';
import ShopifyInvoiceList from 'pages/shopify/shopify-invoices/list';
import ShopifyInvoiceDetails from 'pages/shopify/shopify-invoices/details';

const mapStateToProps = (state) => ({
  isOpen: state.menu.isOpen,
  isAuthenticated: !!state.auth.loggedInUser,
});

const Router = props => {
  const { isOpen, isAuthenticated } = props;

  return (
    <div className={`main-content ${isOpen && isAuthenticated ? 'menu-open' : 'menu-close'}`}>
      <Switch>
        <PublicRoute path="/login" component={Login} />
        <PrivateRoute exact={true} path="/" component={Restaurants} roles={[USER_ROLES.SHOPPER]} />
        <PrivateRoute exact={true} path="/leads" component={Leads} roles={[USER_ROLES.AGENT]} />
        <PrivateRoute exact={true} path='/copy-restaurant-menu-jobs' component={CopyRestaurantMenuJobs} roles={[USER_ROLES.SALES]} />
        <PrivateRoute exact={true} path='/copy-restaurant-menu' component={CopyRestaurantMenu} roles={[USER_ROLES.SALES]} />
        <PrivateRoute exact={true} path="/payment-summary" component={PaymentSummary} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path="/send-notifications" component={SendNotifications} roles={[USER_ROLES.DISPATCHER]} />
        <PrivateRoute exact={true} path="/import-dishes" component={ImportDish} roles={[USER_ROLES.SALES]} />
        <PrivateRoute exact={true} path='/dish-imports' component={DishImportsList} roles={[USER_ROLES.SALES]} />
        <PrivateRoute exact={true} path='/notifications-list' component={NotificationsList} roles={[USER_ROLES.DISPATCHER]} />
        <PrivateRoute exact={true} path='/job-detail/:jobId' component={AsyncJobDetail} roles={[USER_ROLES.DISPATCHER, USER_ROLES.FINANCE, USER_ROLES.SALES]} />
        <PrivateRoute exact={true} path="/lead-details/:objectId" component={LeadDetail} roles={[USER_ROLES.AGENT]} />
        <PrivateRoute exact={true} path='/orders' component={Orders} roles={[USER_ROLES.DISPATCHER, USER_ROLES.FINANCE, USER_ROLES.SALES, USER_ROLES.SHOPPER]} />
        <PrivateRoute exact={true} path='/orders/:orderId' component={SingleOrder} roles={[USER_ROLES.DISPATCHER, USER_ROLES.FINANCE, USER_ROLES.SALES, USER_ROLES.SHOPPER]} />
        <PrivateRoute exact={true} path='/duplicate-orders' component={DuplicateOrders} roles={[USER_ROLES.DISPATCHER, USER_ROLES.FINANCE, USER_ROLES.SALES, USER_ROLES.SHOPPER]} />
        <PrivateRoute exact={true} path='/reports/review' component={ReviewReport} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/reports/bonus' component={BonusReport} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/reports/payment' component={PaymentSummaryReport} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/reports/driver-payment' component={DriverPaymentReport} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/reports/fleet-orders' component={FleetOrdersReport} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/reports/fleet-payment-summary' component={FleetOrderPaymentSummary} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/reports/revenue-overview' component={RevenueReport} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/reports/discount-redemption' component={DiscountRedemptionReport} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/reports/drivers-data' component={DriversData} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/reports/tax-summary' component={TaxSummaryReport} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/reports/driver-note-report' component={DriverNoteReport} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/reports/driver-events' component={DriverEvents} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/reports/leads' component={LeadReports} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/reports/prime-subscription-discount-report' component={PrimeSubscriptionDiscountReport} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/reports/order-rejection-summary' component={OrderRejectionSummary} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/users' component={Users} roles={[USER_ROLES.DISPATCHER, USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/users/:userId' component={UserDetails} roles={[USER_ROLES.DISPATCHER, USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/tags' component={Tags} roles={[USER_ROLES.SALES, USER_ROLES.ADMIN]} />
        <PrivateRoute exact={true} path='/drivers' component={Drivers} roles={[USER_ROLES.DISPATCHER, USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/drivers/:driverId' component={DriverDetails} roles={[USER_ROLES.DISPATCHER, USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/deferred-orders' component={DeferredOrders} roles={[USER_ROLES.DISPATCHER, USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/deferred-orders/:orderId' component={DeferredOrderDetails} roles={[USER_ROLES.DISPATCHER, USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/scrappers' component={Scrappers} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/scrappers/create' component={CreateScrapper} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/scrappers/:scrapperId' component={ScrapperDetails} roles={[USER_ROLES.SALES, USER_ROLES.FINANCE]} />
        <PrivateRoute exact={true} path='/order-reviews' component={OrderReviews} roles={[USER_ROLES.DISPATCHER, USER_ROLES.SALES]} />
        <PrivateRoute exact={true} path='/routes' component={Routes} roles={[USER_ROLES.DISPATCHER]} />
        <PrivateRoute exact={true} path='/routes/:routeId' component={RouteDetail} roles={[USER_ROLES.DISPATCHER]} />
        <PrivateRoute exact={true} path='/refunds' component={Refunds} roles={[]} />
        <PrivateRoute exact={true} path='/restaurants' component={Restaurants} roles={[USER_ROLES.SHOPPER]} />
        <PrivateRoute exact={true} path='/restaurants/:restaurantId' component={RestaurantDetails} roles={[]} />
        <PrivateRoute exact={true} path='/restaurants/:restaurantId/edit' component={EditRestaurantInfo} roles={[]} />
        <PrivateRoute exact={true} path='/restaurant-stats' component={RestaurantStats} roles={[]} />
        <PrivateRoute exact={true} path='/uva-categories' component={UvaCategories} roles={[]} />
        <PrivateRoute exact={true} path='/uva-categories/create' component={CreateUvaCategory} roles={[]} />
        <PrivateRoute exact={true} path='/uva-categories/:uvaCategoryId' component={UvaCategoryDetails} roles={[]} />
        <PrivateRoute exact={true} path='/uva-categories/:uvaCategoryId/edit' component={EditUvaCategoryInfo} roles={[]} />
        <PrivateRoute exact={true} path='/dishes' component={Dishes} roles={[]} />
        <PrivateRoute exact={true} path='/dishes/:dishId/add/:restaurantId' component={AddDish} roles={[]} />
        <PrivateRoute exact={true} path='/dishes/:dishId/edit/:restaurantId' component={AddDish} roles={[]} />
        <PrivateRoute exact={true} path='/dishes/:dishId' component={DishDetails} roles={[]} />
        <PrivateRoute exact={true} path='/dish-collection/:collectionId' component={DishCollectionDetails} roles={[]} />
        <PrivateRoute exact={true} path='/dish-collection' component={DishCollection} roles={[]} />
        <PrivateRoute exact={true} path='/restaurant-collection' component={RestaurantCollection} roles={[]} />
        <PrivateRoute exact={true} path='/restaurant-collection/:collectionId' component={RestaurantCollectionDetails} roles={[]} />
        <PrivateRoute exact={true} path='/automatic-merchant-collection' component={AutomaticMerchantCollectionList} roles={[]} />
        <PrivateRoute exact={true} path='/automatic-merchant-collection/:collectionId/:action' component={CreateAutomaticMerchantCollection} roles={[]} />
        <PrivateRoute exact={true} path='/automatic-merchant-collection/:collectionId' component={AutomaticMerchantCollectionDetails} roles={[]} />
        <PrivateRoute exact={true} path='/categorise-dishes' component={CategoriseDishes} roles={[]} />
        <PrivateRoute exact={true} path='/discounts' component={Discounts} roles={[]} />
        <PrivateRoute exact={true} path='/discounts/create' component={CreateDiscount} roles={[]} />
        <PrivateRoute exact={true} path='/discounts/:discountId' component={DiscountDetails} roles={[]} />
        <PrivateRoute exact={true} path='/config' component={Config} roles={[]} />
        <PrivateRoute exact={true} path='/cron-jobs' component={CronJobs} roles={[]} />
        <PrivateRoute exact={true} path='/cron-jobs/:jobId' component={CronJobDetails} roles={[]} />
        <PrivateRoute exact={true} path='/toll-crud' component={TollCrud} roles={[]} />
        <PrivateRoute exact={true} path='/cloud-requests' component={CloudRequests} roles={[]} />
        <PrivateRoute exact={true} path='/cloud-requests/:cloudRequestId' component={CloudRequestDetail} roles={[]} />
        <PrivateRoute exact={true} path='/tax-summary' component={SendTaxSummary} roles={[]} />
        <PrivateRoute exact={true} path='/stripe-products' component={StripeProductsList} roles={[]} />
        <PrivateRoute exact={true} path='/stripe-products/create' component={CreateStripeProduct} roles={[]} />
        <PrivateRoute exact={true} path='/stripe-products/edit/:slug' component={CreateStripeProduct} roles={[]} />
        <PrivateRoute exact={true} path='/stripe-products/:slug' component={StripeProductDetails} roles={[]} />
        <PrivateRoute exact={true} path='/subscription/create' component={CreateSubscription} roles={[]} />
        <PrivateRoute exact={true} path='/subscription/edit/:subscriptionId' component={EditSubscription} roles={[]} />
        <PrivateRoute exact={true} path='/subscription/:subscriptionId' component={SubscriptionDetails} roles={[]} />
        <PrivateRoute exact={true} path='/restaurant/create' component={CreateRestaurant} roles={[]} />
        <PrivateRoute exact={true} path='/disputes' component={Disputes} roles={[]} />
        <PrivateRoute exact={true} path='/disputes/:id' component={DisputeDetails} roles={[]} />
        <PrivateRoute exact={true} path='/user-carts' component={UserCarts} roles={[]} />
        <PrivateRoute exact={true} path='/user-carts/:id' component={UserCartDetails} roles={[]} />
        <PrivateRoute exact={true} path='/user-subscriptions' component={UsersSubscriptions} roles={[USER_ROLES.ADMIN]} />
        <PrivateRoute exact={true} path='/user-subscriptions/:subscriptionId' component={UserSubscriptionDetails} roles={[USER_ROLES.ADMIN]} />
        <PrivateRoute exact={true} path='/shopify/orders' component={ShopifyOrdersList} roles={[USER_ROLES.ADMIN]} />
        <PrivateRoute exact={true} path='/shopify/orders/:orderId' component={ShopifyOrderDetails} roles={[USER_ROLES.ADMIN]} />
        <PrivateRoute exact={true} path='/shopify/merchants' component={ShopifyMerchantsList} roles={[USER_ROLES.ADMIN]} />
        <PrivateRoute exact={true} path='/shopify/merchants/:merchantId' component={ShopifyMerchantDetails} roles={[USER_ROLES.ADMIN]} />
        <PrivateRoute exact={true} path='/shopify/invoices' component={ShopifyInvoiceList} roles={[]} />
        <PrivateRoute exact={true} path='/shopify/invoices/:invoiceId' component={ShopifyInvoiceDetails} roles={[]} />
      </Switch>
    </div>
  );
};

const RouterWrapper = connect(mapStateToProps)(Router);
export { RouterWrapper as Router };
