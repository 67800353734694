import { cloudFunction } from 'services/parse';

export const reverseGeoCode = async (
  latitude: number,
  longitude: number,
): Promise<any> => {
  return await cloudFunction('reverseGeoCode', {
    coords: { latitude, longitude },
  });
};
