import React, { useState } from 'react';
import { IVehicle } from 'interfaces/vehicle';

const VehicleTypeDropDown = ({ type, onChange, vehicleList, disabled = false }: { type: string; onChange: any; vehicleList: any[], disabled?: boolean }) => {
  const [vehicleTypeList, setVehicleTypeList] = useState(vehicleList);

  const getOptions = () => {
    return vehicleTypeList.map(({ type, objectId, capacity }) => (<option value={objectId} key={objectId}>{type}, {capacity}</option>));
  };

  const getVehicleType = (): string => {
    return type && type['objectId'];
  };
  return (
    <>
      <select disabled={disabled} value={getVehicleType()} className="form-control" onChange={onChange}>
        <option>Select Vehicle Type</option>
        {getOptions()}
      </select>
    </>
  );
};

export default VehicleTypeDropDown;
