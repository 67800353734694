import { Loading } from 'components/loading/Loading';
import React from 'react';
import './index.scss';


const IconClass = {
  EDIT: 'fa-pencil',
  DELETE: 'fa-trash',
};

const ActionIcon = props => {
  const {
    actionType,
    loading,
    onClick,
    customClass,
  } = props;
  
  return (
    <div className={`delete-icon-container ${customClass}`}>
      {loading ? (
        <Loading type={'mini'} />
      ) : (
        <i className={`fa ${IconClass[actionType]}`}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default ActionIcon;