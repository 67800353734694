import { Loading } from 'components/loading/Loading';
import ReportsFilter from 'components/ReportsFilter';
import React, { useState } from 'react';
import { showError } from 'utils/notifications';
import { revenueOverview } from 'services';
import moment from 'moment';
import './index.scss';
import Currency from 'components/Currency';
import FleetRevenueSection from './FleetRevenueSection';
import { CANCEL_FLEET_ORDER_REASON_CODES } from 'utils';

const SectionHeader = props => {
  return (
    <div className={`report-section-header`}>{props.heading}</div>
  );
};

const DetailsGroup = props => {
  const {
    label,
    value,
    negative,
    groupClassName,
    infoClassName,
  } = props;

  return (
    <div className={`details-group ${groupClassName || ''}`}>
      <div className={`label`}>{label}</div>
      <div className={`info ${infoClassName || ''}`}>
        <span>{negative ? '- ' : ''}</span>
        <Currency amount={value} />
      </div>
    </div>
  );
};

const RevenueReport = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [revenueData, setRevenueData] = useState<Record<string, any>>({});
  const [fleetRevenueData, setFleetRevenueData] = useState<Record<string, any>>({});
  const [startDate, setStartDate] = useState<Date | null | string>(moment().subtract(30, 'days').toDate());
  const [endDate, setEndDate] = useState<Date | null | string>(moment().toDate());

  const fetchRevenueData = async () => {
    setLoading(true);
    const payload = {
      startTime: startDate,
      endTime: endDate,
    };
    try {
      const [response, responseFleet] = await Promise.all([revenueOverview({ ...payload, isFleetOrder: false }), revenueOverview({ ...payload, isFleetOrder: true })]);
      setRevenueData(response);
      setFleetRevenueData(responseFleet);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const getHeading = (heading: string) => {
    return (
      <div className={'heading'}>{heading}</div>
    );
  };

  const getDetailsSection = () => {
    const getTotalsSection = () => (
      <div className={'revenue-report-totals-section'}>
        <SectionHeader heading={'Total Amount'} />
        <DetailsGroup
          label={'Total Sale'}
          value={revenueData.totalOrderAmount}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Refunds'}
          value={revenueData.totalRefunds}
          groupClassName={''}
          infoClassName={''}
        />
      </div>
    );

    const getOthersReportSection = () => (
      <div className={'revenue-report-others-section'}>
        <SectionHeader heading={'Others'} />
        <DetailsGroup
          label={'Total Delivery Fee'}
          value={revenueData.totalDeliveryFee}
          groupClassName={''}
          infoClassName={''}
        />
      </div>
    );

    const getRefundsReportSection = () => (
      <div className={'revenue-report-refunds-section'}>
        <SectionHeader heading={'Refunds'} />
        <DetailsGroup
          label={'Total Restaurant Refunds'}
          value={revenueData.totalRestaurantRefund}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Uva Refunds'}
          value={revenueData.totalUvaRefund}
          groupClassName={''}
          infoClassName={'horizontal-rule pb-1'}
        />
        <DetailsGroup
          label={'Total Refunds'}
          value={revenueData.totalRefunds}
          groupClassName={''}
          infoClassName={''}
        />
      </div>
    );

    const getTaxesReportSection = () => (
      <div className={'revenue-report-taxes-section'}>
        <SectionHeader heading={'Taxes'} />
        <DetailsGroup
          label={'Total Food Taxes'}
          value={revenueData.totalFoodTaxes}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Delivery Taxes'}
          value={revenueData.totalDeliveryTaxes}
          groupClassName={''}
          infoClassName={'horizontal-rule pb-1'}
        />
        <DetailsGroup
          label={'Total Taxes'}
          value={revenueData.totalTaxes}
          groupClassName={''}
          infoClassName={''}
        />
      </div>
    );

    const getUvaReportSection = () => (
      <div className={'revenue-report-uva-section'}>
        <SectionHeader heading={'Uva'} />
        <DetailsGroup
          label={'Total Uva Revenue'}
          value={revenueData.totalUvaRevenue}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Revenue Share'}
          value={revenueData.totalRevShare}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Markup Amount'}
          value={revenueData.totalMarkupAmount}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Suntotal Markup'}
          value={revenueData.totalSubtotalsMarkupAmount}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Subtotals Markup Amount'}
          value={revenueData.totalSubtotalsMarkupAmount}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Uva Refunds'}
          value={revenueData.totalUvaRefund}
          groupClassName={''}
          infoClassName={'horizontal-rule pb-1'}
          negative={true}
        />
        <DetailsGroup
          label={'Total Uva Income'}
          value={revenueData.totalUvaIncome}
          groupClassName={''}
          infoClassName={''}
        />
      </div>
    );

    const getRefundByReasonSection = () => {
      return (
        <div className={'revenue-report-refund-reason-section'}>
          <SectionHeader heading={'Refund By Reason'} />
          {Object.keys(revenueData.refundByReasonAndType).map(key => {
            const reason = revenueData.refundByReasonAndType[key];
            return (
              <div key={key} className={'refundByReason-main-container'}>
                {Object.values(CANCEL_FLEET_ORDER_REASON_CODES).includes(key) ?
                  null
                  :
                  <div className={'single-reason'}>
                    <div className={'reason'}>{key}</div>
                    {Object.keys(reason).map(key => {
                      const type = reason[key];
                      return (
                        <DetailsGroup
                          key={key}
                          label={key}
                          value={type.refundAmount.toFixed(2)}
                          groupClassName={''}
                          infoClassName={''}
                        />
                      );
                    })}
                  </div>
                }
              </div>
            );
          })}
        </div>
      );
    };

    const getRestaurantsReportSection = () => (
      <div className={'revenue-report-restaurant-section'}>
        <SectionHeader heading={'Restaurant'} />
        <DetailsGroup
          label={'Total Restaurant Subtotal'}
          value={revenueData.totalSubTotals}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Municipal Taxes'}
          value={revenueData.totalTaxDepositedToRestaurant}
          groupClassName={''}
          infoClassName={'horizontal-rule pb-1'}
        />
        <DetailsGroup
          label={'Total Restaurant Revenue'}
          value={revenueData.totalRestaurantRevenue}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Revenue Share'}
          value={revenueData.totalRevShare}
          groupClassName={''}
          infoClassName={''}
          negative={true}
        />
        <DetailsGroup
          label={'Total Restaurant Refund'}
          value={revenueData.totalRestaurantRefund}
          groupClassName={''}
          infoClassName={'horizontal-rule pb-1'}
          negative={true}
        />
        <DetailsGroup
          label={'Total Restaurant Payments'}
          value={revenueData.totalRestaurantPayment}
          groupClassName={''}
          infoClassName={''}
        />
      </div>
    );

    const getDriversReportSection = () => (
      <div className={'revenue-report-drivers-section'}>
        <SectionHeader heading={'Driver'} />
        <DetailsGroup
          label={'Total Uva Bonus'}
          value={revenueData.totalUvaBonus}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Distance Bonus'}
          value={revenueData.totalDistanceBonus}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Rejection Bonus'}
          value={revenueData.totalRejectionBonus}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Driver Bonus'}
          value={revenueData.totalDriverBonus}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Tips'}
          value={revenueData.totalTip}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Toll'}
          value={revenueData.totalToll}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Driver Earning'}
          value={revenueData.totalDriverEarning}
          groupClassName={'horizontal-rule pb-1'}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Driver Payment'}
          value={revenueData.totalDriverPayment}
          groupClassName={''}
          infoClassName={''}
        />
      </div>
    );

    const getTotalOrders = () => (
      <div className={'total-order'}>
        <div>Total Orders</div>
        <div>{revenueData.totalOrders}</div>
      </div>
    );

    const renderDetails = () => {
      if (Object.keys(revenueData).length > 0) {
        return (
          <div className={'revenue-details-section'}>
            {getTotalOrders()}
            <div>
              <div className={''}>
                {getDriversReportSection()}
                {getRestaurantsReportSection()}
                {getRefundByReasonSection()}
              </div>
              <div className={''}>
                {getUvaReportSection()}
                {getTaxesReportSection()}
                {getRefundsReportSection()}
                {getOthersReportSection()}
                {getTotalsSection()}
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    };

    if (loading) {
      return <Loading />;
    }
    if (Object.keys(revenueData).length > 0) {
      return (
        <>
          {getHeading('Revenue Overview')}
          {renderDetails()}
        </>
      );
    } else {
      return null;
    }
  };

  const getDateRangeSelector = () => (
    <ReportsFilter
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      handleSubmit={fetchRevenueData}
      showEmailInput={false}
    />
  );

  const getData = () => {
    if (revenueData.totalAmountFromAllRefunds === 0 && revenueData.totalAmountFromAllOrders === 0) {
      return <div className='d-flex justify-content-center data-container'>No data found.</div>;
    } else {
      return (
        <>
          {revenueData?.totalOrders && getDetailsSection()}
          <FleetRevenueSection revenueData={fleetRevenueData} loading={loading} salesRevenueData={revenueData} />
        </>
      );
    }
  };

  return (
    <div className={'revenue-overview-container'}>
      {getDateRangeSelector()}
      {getData()}
    </div>
  );
};

export default RevenueReport;