import ActionButton from 'components/ActionButton/ActionButton';
import BackButton from 'components/BackButton';
import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from 'react';
import { showError } from 'utils';
import BasicDetails from './basicDetails';
import './index.scss';
import { fetchShopifyOrderDetails, syncShopifyOrder } from 'services/shopifyApi/orders';

const ShopifyOrderDetails = (props) => {
  const { orderId } = props.match.params;
  const [loading, setLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [data, setData] = useState({}) as any;

  const getOrderDetailFn = async () => {
    setLoading(true);
    try {
      const response = await fetchShopifyOrderDetails(orderId);
      setData(response?.data);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const syncOrderFn = async () => {
    setSyncLoading(true);
    try {
      await syncShopifyOrder(orderId);
      getOrderDetailFn();
    } catch (e) {
      showError(e);
    } finally {
      setSyncLoading(false);
    }
  };

  useEffect(() => {
    getOrderDetailFn();
  }, []);

  const getHeader = () => (
    <div className={'header'}>
      <div className={'heading'}>Order Details</div>
      <div className={'header-buttons'}>
        <BackButton />
      </div>
    </div>
  );

  return (
    <div id={'main-container'}>
      {loading && <Loading />}
      {getHeader()}
      <BasicDetails details={data} syncLoading={syncLoading} syncOrderFn={syncOrderFn}/>
    </div>
  );
};

export default ShopifyOrderDetails;