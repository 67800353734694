import { store } from 'react-notifications-component';

type NotificationParams = {
  type: 'success' | 'danger' | 'info' | 'default' | 'warning';
  message: string;
};

const displayNotification = (notificationDetails: NotificationParams) => {
  return store.addNotification({
    message: notificationDetails.message.toString(),
    type: notificationDetails.type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 4000,
      showIcon: true,
    },
    slidingEnter: {
      duration: 200,
      timingFunction: 'ease-in',
      delay: 0,
    },
    slidingExit: {
      duration: 400,
      timingFunction: 'ease-out',
      delay: 0,
    },
  });
};

export const showWarning = (message: string) => {
  displayNotification({ message, type: 'warning' });
};

export const showError = (message: string) => {
  displayNotification({ message, type: 'danger' });
};

export const showSuccess = (message: string) => {
  displayNotification({ message, type: 'success' });
};
