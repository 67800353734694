/* eslint-disable react/display-name */
import React, { useState } from 'react';
import ReportsFilter from 'components/ReportsFilter';
import { getFleetOrderPaymentSummaryReport } from 'services/api/reports';
import moment from 'moment';
import { showError } from 'utils/notifications';
import './index.scss';
import { Loading } from 'components/loading/Loading';
import DataTable from 'react-data-table-component';
import _ from 'lodash';
import ExportCSVButton from 'components/ExportCSVButton';
import { getEmailArr, metersToMile } from 'utils';
import { tableStyles } from 'css/tableStyles';
import TableLink from 'TableLink';
import Currency from '../../components/Currency';

const columns = [
  // {
  //   name: 'Payment ID',
  //   selector: 'objectId',
  //   sortable: false,
  //   width: '150px',
  //   cell: (row) => <TableLink linkTo={`/orders/${row.objectId}`} text={row.objectId}/>,
  // },
  {
    name: 'Merchant ID',
    selector: 'merchantId',
    sortable: false,
    cell: (row) => <TableLink linkTo={`/restaurants/${row.merchantId}`} text={row.merchantId}/>,
  },
  {
    name: 'Total Orders',
    selector: 'totalOrders',
    sortable: false,
  },
  {
    name: 'Subtotal',
    selector: 'totalDeliveryCharges',
    sortable: false,
    cell: (row) => <Currency amount={row.totalDeliveryCharges} />,
  },
  {
    name: 'Delivery Taxes',
    selector: 'totalDeliveryTaxes',
    sortable: false,
    cell: (row) => <Currency amount={row.totalDeliveryTaxes} />,
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: false,
    cell: (row) => <Currency amount={row.total} />,
  },
  {
    name: 'Distance (miles)',
    selector: 'totalDistance',
    sortable: false,
    cell: (row) => metersToMile(row.totalDistance),
  },
  {
    name: 'Duration (mins)',
    selector: 'totalDuration',
    sortable: false,
  },
];

const columnsForCSV = columns.map(col=>({ label: col.name, value: col.selector }));

const FleetOrderPaymentSummary = () =>{
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null | string>(moment().subtract(1, 'days').toDate());
  const [endDate, setEndDate] = useState<Date | null | string>(moment().toDate());
  const [emails, setEmails] = useState<string>('');
  const [restaurantId, setRestaurantId] = useState<string>('');
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [data, setData] = useState<Record<string, any>[]>([]);

  const fetchPaymentSummary = async () => {
    setLoading(true);
    try {
      const response = await getFleetOrderPaymentSummaryReport(startDate, endDate, getEmailArr(emails), restaurantId, sendEmail);
      setData(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };
  const CSVFileName = 'Fleet Order Payment Summary';
  const getDataTable = () => {
    if (loading) return <Loading />;
    if (!_.isEmpty(data)) {
      const exportCSVButton = <ExportCSVButton data={data} columnsForCSV={columnsForCSV} fileName={CSVFileName} />;
      return (
        <DataTable
          title="Reports"
          keyField="objectId"
          columns={columns}
          data={data}
          customStyles={tableStyles}
          pagination={false}
          striped={true}
          actions={[ exportCSVButton]}
        />
      );
    }
    return <div />;
  };

  return (
    <div className='report-container'>
      <ReportsFilter
        btnText={'Send'}
        loading={loading}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        emails={emails}
        setEmails={setEmails}
        restaurantId={restaurantId}
        setRestaurantId={setRestaurantId}
        sendEmail={sendEmail}
        setSendEmail={setSendEmail}
        handleSubmit={fetchPaymentSummary}
        showEmailInput={true}
      />
      {getDataTable()}
    </div>
  );
};

export default FleetOrderPaymentSummary;