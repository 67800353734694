import { cloudFunction } from '../parse';

export const importDishes = (googleDocURL: string, restaurantId: string, overrideExistingImage: boolean) => {
  return cloudFunction('importDishesFromCSV', { restaurantId, googleDocURL, overrideExistingImage });
};

export const getDishDetail = async (id: string) => {
  const response = await cloudFunction('getDishDetailV3', { id, responseAsHierarchy: true });
  return response;
};

export const createDish = (data: Record<string, any>) => {
  return cloudFunction('createDish', data);
};

export const saveDish = (data: Record<string, any>) => {
  return cloudFunction('saveDish', data);
};

export const deleteDish = (data: Record<string, any>) => {
  return cloudFunction('deleteDish', data);
};

export const updateDishImage = (data: Record<string, any>) => {
  return cloudFunction('updateDishImage', data);
};

export const toggleDishAvailability = (data: Record<string, any>) => {
  return cloudFunction('toggleDishAvailability', data);
};

export const toggleDishVariantAvailability = (params: any) => {
  return cloudFunction('toggleDishVariantAvailability', params);
};

export const toggleDishVariantsAvailability = (params: any) => {
  return cloudFunction('toggleDishVariantsAvailability', params);
};

export const deleteDishVariant = (data: Record<string, any>) => {
  return cloudFunction('deleteDishVariant', data);
};

export const createDishVariant = (data: Record<string, any>) => {
  return cloudFunction('createDishVariant', data);
};

export const searchDishes = async (searchTerm: string, filter: Record<string, any>) => {
  const response = await cloudFunction('searchDishes', { searchTerm, filter });
  return response;
};

export const saveDishVariants = async (params: any) => {
  const response = await cloudFunction('saveDishVariants', params);
  return response;
}; 

export const duplicateDish = async (dishId: string) => {
  const response = await cloudFunction('duplicateDish', { dishId });
  return response;
}; 