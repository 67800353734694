import React from 'react';
import styles from './index.module.scss';
import RenderInfo from 'components/RenderInfo';
import VariantsHierarchy from 'components/VariantsHierarchy';
import { getItemsFromCartVariantWise } from 'utils';
import TableLink from 'TableLink';

const ListItems = props => {
  const { items = [], title='Cart Items' } = props;
  
  const newItems = getItemsFromCartVariantWise(items);
  return (
    <div className={styles['section-container']}>
      <div className={styles['section-header']}>
        <div className={styles['heading']}>{title}</div>
      </div>
      <div className={styles['cart-items']}>
        {newItems?.map((item, i) => {
          return (
            <div className={styles['cart-item']} key={i}>
              <div className='d-flex justify-content-between align-items-center'>
                <TableLink linkTo={`/dishes/${item?.dishId}`} text={item?.name} />
                <div className={styles['price']}>{item?.count} x ${item?.price}</div>
              </div>
              <div className='d-flex flex-column'>
                <h6>Variants:</h6>
                <VariantsHierarchy dish={item} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ListItems;