import React, { useState } from 'react';
import ReportsFilter from 'components/ReportsFilter';
import { Loading } from 'components/loading/Loading';
import DataTable from 'react-data-table-component';
import { getBonusReport } from 'services/api/reports';
import moment from 'moment';
import { showError } from 'utils/notifications';
import ExportCSVButton from 'components/ExportCSVButton';
import DataTableFilter from 'components/DataTableFIlter';
import { getEmailArr } from '../../utils';
import { tableStyles } from 'css/tableStyles';
import EmptySectionMessage from 'components/message/EmptySectionMessage';

const columns = [
  {
    name: 'Order ID',
    selector: 'orderId',
    sortable: false,
    width: '150px',
  },
  {
    name: 'Date Time',
    selector: 'createdAt',
    sortable: false,
  },
  {
    name: 'Distance',
    selector: 'distance',
    sortable: false,
    format: row => `${row.distance !== 'N/A' ? `${row.distance} mi` : row.distance}`,
  },
  {
    name: 'Distance Bonus',
    selector: 'distanceBonus',
    sortable: false,
    format: row => `$ ${row.distanceBonus}`,
  },
];

const columnsForCSV = [
  {
    value: 'orderId',
    label: 'Order ID',
  },
  {
    value: 'createdAt',
    label: 'Date Time',
  },
  {
    value: 'distance',
    label: 'Distance',
  },
  {
    value: 'distanceBonus',
    label: 'Distance Bonus',
  },
];

const BonusReport = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null | string>(moment().subtract(1, 'days').toDate());
  const [endDate, setEndDate] = useState<Date | null | string>(moment().toDate());
  const [emails, setEmails] = useState<string>('');
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [data, setData] = useState<Array<Record<string, any>>>([]);
  const [filterText, setFilterText] = React.useState('');
  const [apiCalled, setAPICalled] = useState<boolean>(false);

  const fetchBonusReport = async () => {
    if (!apiCalled) setAPICalled(true);
    setLoading(true);
    try {
      const response = await getBonusReport(startDate, endDate, getEmailArr(emails), sendEmail);
      setData(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const getDataTable = () => {
    if (loading) return <Loading />;

    if (data.length) {
      const exportCSVButton = <ExportCSVButton data={data} columnsForCSV={columnsForCSV} fileName={'Bonus Report'} />;
      const dataFilterInput = <DataTableFilter filterText={filterText} setFilterText={setFilterText} placeholder={'Filter by Order ID'} />;
      const filteredData = data.filter(item => item.orderId && item.orderId.toLowerCase().includes(filterText.toLowerCase()));

      return (
        <DataTable
          title="Reports"
          keyField="objectId"
          columns={columns}
          data={filteredData}
          customStyles={tableStyles}
          pagination={false}
          striped={true}
          actions={[dataFilterInput, exportCSVButton]}
        />
      );
    } else {
      return apiCalled ? <EmptySectionMessage message={'No records found'} /> : null;
    }
  };

  return (
    <div className='report-container'>
      <ReportsFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        emails={emails}
        setEmails={setEmails}
        sendEmail={sendEmail}
        setSendEmail={setSendEmail}
        handleSubmit={fetchBonusReport}
        showEmailInput={true}
      />
      {getDataTable()}
    </div>
  );
};

export default BonusReport;
