import React, { useState } from 'react';
import styles from './index.module.scss';

const InfoModal = props => {
  const [loading, setLoading] = useState<string>('');
  const {
    type,
    title,
    message,
    cancellable,
    toggleModal,
    primaryButtonText,
    primaryButtonOnClick,
    secondaryButtonText,
    secondaryButtonOnClick,
    primaryButtonClass,
    secondaryButtonClass,
  } = props;

  const handleSecondaryButtonClick = async () => {
    setLoading('secondary');
    await secondaryButtonOnClick();
    setLoading('');
  };

  const handlePrimaryButtonClick = async () => {
    setLoading('primary');
    await primaryButtonOnClick();
    setLoading('');
  };
  
  return (
    <div className={styles['main-container']}>
      <div className={styles['modal-container']}>
        <div className={styles['modal-header']}>
          <div>
            <div className={styles['label']}>{type}</div>
            <div className={styles['modal-title']}>{title}</div>
          </div>
          {cancellable ? (
            <i className={'fa fa-times'} onClick={toggleModal} />
          ): null}
        </div>
        <div className={styles['modal-body']}>
          <div className={styles['message']}>{message}</div>
        </div>
        <div className={styles['modal-footer']}>
          <button
            disabled={loading === 'secondary'}
            type={'button'}
            className={`${styles['cancel-button']} ${secondaryButtonClass}`}
            onClick={handleSecondaryButtonClick}
          >
            {secondaryButtonText}
          </button>
          <button
            disabled={loading === 'primary'}
            type={'button'}
            className={`${styles['submit-button']} ${primaryButtonClass}`}
            onClick={handlePrimaryButtonClick}
          >
            {primaryButtonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;