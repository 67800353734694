import { cloudFunction } from 'services/parse';

export const getDishCollections = (data: any) => {
  return cloudFunction('fetchAllDishCollections', { ...data });
};

export const addDishCollection = (name: string) => {
  return cloudFunction('createDishCollection', { name });
};

export const deleteDishCollection = (id: string) => {
  return cloudFunction('deleteDishCollection', { id });
};

export const addItemsToDishCollection = (collectionId: string, dishIds: string[]) => {
  return cloudFunction('addItemsToDishCollection', { collectionId, dishIds });
};

export const getDishCollectionDetail = (id: string) => {
  return cloudFunction('fetchDishCollectionDetail', { id });
};

export const removeItemFromDishCollection = (collectionId: string, dishId: string) => {
  return cloudFunction('removeItemFromDishCollection', { collectionId, dishId });
};

export const updateDishCollection = (data: Record<string, any>) => {
  return cloudFunction('updateDishCollection', { ...data });
};

export const fetchVerticals = () => {
  return cloudFunction('fetchVerticals', {});
};
