import ActionButton from 'components/ActionButton/ActionButton';
import ActionIcon from 'components/ActionIcon';
import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { addRestaurantToUser, fetchUserRestaurants, removeRestaurantFromUser } from 'services/api/users';
import TableLink from 'TableLink';
import { ACTION_ICON_TYPES } from 'utils';
import { showError, showSuccess } from 'utils/notifications';
import AddRestaurant from 'components/AddRestaurant';

const UserRestaurants = props => {
  const [restaurants, setRestaurants] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteLoading, setDeleteLoading] = useState<string>('');
  const [addRestaurantModalOpen, setAddRestaurantModalOpen] = useState<boolean>(false);
  const { userId } = props;

  const fetchUserRestaurantsFn = async () => {
    try {
      const response = await fetchUserRestaurants(userId);
      setRestaurants(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const addRestaurantFn = async (restaurantId) => {
    setLoading(true);
    try {
      setAddRestaurantModalOpen(!addRestaurantModalOpen);
      await addRestaurantToUser(userId, restaurantId);
      await fetchUserRestaurantsFn();
      showSuccess('Restaurant added to User successfully');
    } catch(e) {
      showError(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserRestaurantsFn();
  }, []);

  const removeRestaurantFromUserFn = async (id: string) => {
    setDeleteLoading(id);
    try {
      await removeRestaurantFromUser(userId, id);
      await fetchUserRestaurantsFn();
      showSuccess('Restaurant removed from User successfully');
    } catch (e) {
      showError(e);
    } finally {
      setDeleteLoading('');
    }
  };
  
  const columns = useMemo(() => [
    {
      name: 'Restaurant ID',
      selector: 'objectId',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/restaurants/${row.objectId}`}
          text={row.objectId}
          openInNewTab={false}
        />
      ),
    },
    {
      name: 'Name',
      selector: 'restaurant_name',
      sortable: false,
    },
    {
      name: 'Type',
      selector: 'type',
      sortable: false,
    },
    {
      name: 'Bank Account No',
      selector: 'bankAccountNo',
      sortable: false,
    },
    {
      name: 'Bank Routing No',
      selector: 'routingNo',
      sortable: false,
    },
    {
      name: '',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <div className={'ml-5'}>
          <ActionIcon
            actionType={ACTION_ICON_TYPES.DELETE}
            loading={deleteLoading === row.objectId}
            onClick={() => removeRestaurantFromUserFn(row.objectId)}
          />
        </div>
      ),
    },
  ], [deleteLoading, loading]);
  
  return (
    <div>
      {addRestaurantModalOpen && (
        <AddRestaurant
          toggleModal={() => setAddRestaurantModalOpen(!addRestaurantModalOpen)}
          onSubmit={addRestaurantFn}
        />
      )}
      <div className={'mt-4'}>
        <div className={'d-flex justify-content-between mb-2 pr-3'}>
          <h4 className='pl-2 font-weight-bolder'>User Restaurants</h4>
          <ActionButton
            text='Add Restaurant'
            active={true}
            isLoading={false}
            customClass={'btn btn-primary'}
            onClick={() => setAddRestaurantModalOpen(true)}
          />
        </div>
        {loading ? (
          <Loading type={'mini'} />
        ): (
          <DataTable
            noHeader
            keyField="objectId"
            columns={columns}
            data={restaurants}
            highlightOnHover={true}
            customStyles={tableStyles}
            pagination={false}
          />
        )}
      </div>
    </div>
  );
};

export default UserRestaurants;