import React from 'react';
import styles from './index.module.scss';
import { STRIPE_SUPPORTED_CARD_BRANDS } from 'utils';
import MultiSelectDropdown from 'components/MultiSelectDropdown';

const CardEligibility = props => {
  const { detailsEditable, cardBrands, setCardBrands, updateLoading }=props;
  
  const getCardBrands = () => {
    const cardBrandNames = cardBrands?.map((card)=>card?.label);
    const cardBrandNamesString = cardBrandNames?.join(', ');
    return(
      <div className={styles['card-brands']}>
        <div className={styles['label']}>Card Brands</div>
        {detailsEditable ? (
          <MultiSelectDropdown
            value={cardBrands}
            options={STRIPE_SUPPORTED_CARD_BRANDS}
            onChange={setCardBrands}
            disableSearch={true}
            hasSelectAll={false}
            disabled={updateLoading}
          />
        ) : (
          <div className={styles['detail']}>{cardBrandNamesString ? cardBrandNamesString : '-'}</div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={styles['user-section']}>
        <h4 className='pb-2 font-weight-bolder'>Card Eligibility</h4>
        {getCardBrands()}
      </div>
    </>
  );
};

export default CardEligibility;