import React from 'react';
import { DATE_FORMATS, ENTITY_CLASS_MAPPINGS, formatDate } from 'utils';
import './index.scss';
import { IEntityMiniCardProps } from 'interfaces/entityMiniCard';

const EntityMiniCard = (props: IEntityMiniCardProps) => {
  const {
    entityInfo: {
      createdAt,
      name,
      objectId,
      type,
      icon,
    },
  } = props;

  const getEntityLink = () => {
    const [entityMappingInfo] = ENTITY_CLASS_MAPPINGS.filter(mapping => mapping.dropdownValue === type);
    if (!!entityMappingInfo) {
      return (
        <a
          href={`/${entityMappingInfo.routeValue}/${objectId}`}
          className={'_id'}
        >
          {objectId}
        </a>
      );
    } else {
      return null;
    }
  };
  
  return (
    <div className={'entity_mini_card_container'}>
      <div className={'image_section'}>
        {!!icon && (
          <i className={icon} />
        )}
      </div>
      <div className={'info_section'}>
        <div className={'_name'}>{name}</div>
        {getEntityLink()}
        <div className={'_created_at'}>
          {`${type} since ${formatDate(createdAt, DATE_FORMATS.YYYY)}`}
        </div>
      </div>
    </div>
  );
};

export default EntityMiniCard;