import ActionButton from 'components/ActionButton/ActionButton';
import React, { useEffect, useState } from 'react';
import { cancelPrimeSubscription, fetchUserPrimeSubscriptionDetail, sendPrimeSubscriptionInvitationToUsers } from 'services/api/subscriptions';
import { capitalize, formatDate, showError, showSuccess, stringifyAndParseObj } from 'utils';
import './index.scss';

const UserPrimeSubscription = (props) => {
  const { onInvitationSentSuccess, user } = props;
  const { hasPrimeSubscriptionInvitation = false, hasPrimeSubscription = false, objectId: userId } = user;
  const [loading, setLoading] = useState(false);
  const [primeSubscriptionDetails, setPrimeSubscriptionDetails] = useState({}) as any;

  const [sendInviteLoading, setSendInviteLoading] = useState(false);
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] = useState(false);

  const sendPrimeSubscriptionInvitationToUsersFn = async () => {
    setSendInviteLoading(true);
    try {
      await sendPrimeSubscriptionInvitationToUsers([userId]);
      if (onInvitationSentSuccess) {
        await onInvitationSentSuccess();
      }
      showSuccess('Prime invitation sent successfully.');
    } catch (e) {
      showError('Error while sending invitation.');
    } finally {
      setSendInviteLoading(false);
    }
  };

  const fetchUserPrimeSubscriptionDetailFn = async () => {
    setLoading(true);
    try {
      let details = await fetchUserPrimeSubscriptionDetail(userId);
      details = stringifyAndParseObj(details);
      setPrimeSubscriptionDetails(details);
    } catch (e) {
      showError('Error while fetching user prime subscription details.');
    } finally {
      setLoading(false);
    }
  };

  const cancelPrimeSubscriptionFn = async() => {
    setCancelSubscriptionLoading(true);
    try {
      await cancelPrimeSubscription({ userId });
      await fetchUserPrimeSubscriptionDetailFn();
    } catch (e) {
      showError(e);
    } finally {
      setCancelSubscriptionLoading(false);
    }
  };

  useEffect(() => {
    if (userId && hasPrimeSubscription) {
      fetchUserPrimeSubscriptionDetailFn();
    }
  }, [userId]);

  const getSubscriptionDetail = ({ heading, value, className = '' }) => {
    if (!value) return null;
    return (
      <div className={`d-flex flex-column detail ${className}`}>
        <span className='heading'>{heading}</span>
        <span className='value'>{value}</span>
      </div>
    );
  };

  const getSubscriptionCancelButton = () => {
    const isSubscriptionCancelled = primeSubscriptionDetails.cancelAtPeriodEnd;
    if (isSubscriptionCancelled) return null;
    return (
      <span 
        onClick={cancelPrimeSubscriptionFn} 
        className={cancelSubscriptionLoading ? 'cancel-inactive' : 'cancel-active'}>
        {cancelSubscriptionLoading ? 'Cancelling...' : 'Cancel subscription'}
      </span>
    );
  };

  const getPrimeSubscriptionDetails = () => {
    if (Object.keys(primeSubscriptionDetails)?.length) {
      const isSubscriptionCancelled = primeSubscriptionDetails.cancelAtPeriodEnd;
      return (
        <>
          <div className='d-flex'>
            {getSubscriptionDetail({ heading: 'Name', value: primeSubscriptionDetails?.subscription?.name })}
            {getSubscriptionDetail({ heading: 'Status', value: capitalize(primeSubscriptionDetails?.status), className: 'ml-5' })}
            {getSubscriptionDetail({ heading: 'Subscribed on', value: formatDate(primeSubscriptionDetails?.startDate?.iso), className: 'ml-5' })}
          
          </div>
          <div className='mt-3'>
            {isSubscriptionCancelled ?
              `Prime subscription will be cancelled on ${formatDate(primeSubscriptionDetails?.currentPeriodEnd?.iso)}`
              :
              null}
          </div>
          {getSubscriptionCancelButton()}
        </>
      );
    }
    return null;
  };

  const getInviteToPrimeButton = () => {
    if (hasPrimeSubscription) {
      return null;
    }
    return (
      <div>
        <div>This user currently does not have any active prime subscription</div>
        <div className='d-flex flex-row justify-content-between align-items-center'>
          {getSubscriptionDetail({ heading: 'Has prime subscription invitaion', value: `${hasPrimeSubscriptionInvitation}` })}
          <ActionButton
            text={'Send Prime Invitation'}
            active={!sendInviteLoading}
            isLoading={sendInviteLoading}
            onClick={sendPrimeSubscriptionInvitationToUsersFn}
          />
        </div>
      </div>
    );
  };

  const getLoading = () => {
    if (!loading) return null;
    return (
      <div>Loading details...</div>
    );
  };
  
  return (
    <>
      <h4 className='pl-2 mt-4 font-weight-bolder'>User Prime Subscription</h4>
      <div className='user-prime-subscription-container'>
        {getLoading()}
        {getPrimeSubscriptionDetails()}
        {getInviteToPrimeButton()}
      </div>
    </>
  );
};

export default UserPrimeSubscription;