import ActionButton from 'components/ActionButton/ActionButton';
import InfoModal from 'components/InfoModal';
import React, { useState } from 'react';
import { markStripeProductAsActive, markStripeProductAsInactive } from 'services/api/stripeProducts';
import { MODAL_TYPES, showError } from 'utils';
import styles from './index.module.scss';

const ChangeStatusButton = (props) => {
  const { details: { objectId = '', active = false } = {}, setData } = props;
  const [isChangeStatusModalVisible, setIsChangeStatusModalVisible] = useState(false);

  const getButtonTitle = () => {
    return active ? 'Make Inactive' : 'Make Active';
  };

  const getModalDescription = () => {
    return active ? 'All the subscriptions associated with this stripe product will also be maked as inactive' : 'You need to make all the subscription associated to this stripe product active seperately';
  };

  const handlePrimaryButtonClick = async () => {
    try {
      let response = {};
      if (active) {
        response = await markStripeProductAsInactive(objectId);
      } else {
        response = await markStripeProductAsActive(objectId);
      }
      setData(response);
      setIsChangeStatusModalVisible(false);
    } catch (e) {
      showError(e);
    }
  };

  const getModal = () => {
    if (isChangeStatusModalVisible) {
      return (
        <InfoModal
          cancellable={true}
          type={MODAL_TYPES.CONFIRM_CHANGES}
          title={'Warning'}
          message={getModalDescription()}
          toggleModal={() => setIsChangeStatusModalVisible(!isChangeStatusModalVisible)}
          primaryButtonText={getButtonTitle()}
          primaryButtonOnClick={handlePrimaryButtonClick}
          primaryButtonClass={styles['change-status-button']}
          secondaryButtonText={'Cancel'}
          secondaryButtonOnClick={() => {
            setIsChangeStatusModalVisible(false);
          }}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div className='d-flex justify-content-end'>
      <ActionButton
        text={getButtonTitle()}
        active={true}
        isLoading={false}
        onClick={() => setIsChangeStatusModalVisible(true)}
        customClass={`${styles['change-status-button']} mr-3 mt-2 align-self-end`}
      />
      {getModal()}
    </div>
  );
};

export default ChangeStatusButton;