import React, { useEffect, useState } from 'react';
import {
  addDriverNotes,
  deleteDriverNotes,
  editDriverNotes,
  fetchAllCities,
  fetchDriverDeactivationReasons,
  fetchDriverDetailForAdminDashboard, fetchVehicleTypes,
  toggleIsDriver,
  toggleOnlineStatusByAdmin,
} from 'services/api/drivers';
import { showError, showSuccess } from 'utils/notifications';
import { Loading } from 'components/loading/Loading';
import BackButton from 'components/BackButton';
import './index.scss';
import OnlineStatus from './online-status';
import DriverPersonalDetail from './driver-personal-detail';
import DriverEarnings from './driver-earnings';
import DriverOrderHistory from './driver-order-history';
import DriverActivities from './driver-activities';
import DriverNotes from './driver-notes';
import DriverDailyEarnings from './driver-daily-earnings';
import DriverWeeklyEarnings from './driver-weekly-earnings';
import ActionButton from 'components/ActionButton/ActionButton';
import { updateUserDetail } from 'services';
import { Documents } from '../../leads/components/lead-detail/Documents';
import { fetchDocumentsForDriver } from 'services/api/drivers';
import IsDriverComponent from './components/is-driver';
import { AVAILABLE_DOCUMENT_LIST, DATE_FORMATS, MESSAGES, formatDate } from 'utils';
import { IVehicle } from 'interfaces';
import DriverRemovedFromOrders from './driver-removed-from-orders';
import BlackListedMerchants from './blacklist-merchant';
import DeactivationReasonDropDown from './components/deactivation-reason-dropdown';

const DriverDetails = props => {
  const { driverId } = props.match.params;
  const [driver, setDriver] = useState<Record<string, any>>({});
  const [isDriver, setIsDriver] = useState<boolean>(false);
  const [isDriverOnline, setIsDriverOnline] = useState<boolean>(false);
  const [driverCopy, setDriverCopy] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [editPersonalDetail, setEditPersonalDetail] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [cityList, setCityList] = useState([]);
  const [cityLoading, setCityLoading] = useState<boolean>(false);
  const [vehicleTypeList, setVehicleTypeList] = useState<IVehicle[]>([]);
  const [driverToggleLoading, setDriverToggleLoading] = useState<boolean>(false);
  const [savePersonalDetailsLoading, setSavePersonalDetailsLoading] = useState(false);

  const fetchDriverDetails = async () => {
    try {
      let driver = await fetchDriverDetailForAdminDashboard(driverId);
      driver = JSON.parse(JSON.stringify(driver));
      setDriver(driver);
      setIsDriver(driver.isDriver);
      setIsDriverOnline(driver.driverOnline);
      setDriverCopy(driver);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchDriverDocuments = async () => {
    const docs = await fetchDocumentsForDriver(driverId);
    setDocuments(docs);
  };

  useEffect(() => {
    fetchDriverDetails();
    fetchDriverDocuments();
    fetchCities();
    fetchVehicleTypeList();
  }, []);

  const onPersonalDetailChange = event => {
    setDriver({ ...driver, ...event });
  };

  const cancelChange = () => {
    setEditPersonalDetail(false);
    setDriver({ ...driverCopy });
  };

  const updatePersonalDetails = async () => {
    setSavePersonalDetailsLoading(true);
    try {
      if (editPersonalDetail) {
        const updatedUser = await updateUserDetail(driverId, driver);
        onPersonalDetailChange(updatedUser);
        showSuccess(MESSAGES.DRIVER_UPDATE_SUCCESS);
      }
      setEditPersonalDetail(!editPersonalDetail);
    } catch (e) {
      console.log(e);
      showError(e);
    } finally {
      setSavePersonalDetailsLoading(false);
    }
  };

  const toggleIsDriverFn = async () => {
    setDriverToggleLoading(true);
    try {
      await toggleIsDriver(driverId);
      if (!isDriver) {
        const updatedUser = await updateUserDetail(driverId, { deactivationReason: null });
        onPersonalDetailChange(updatedUser);
      }
      setIsDriver(!isDriver);
      fetchDriverDetails();
      showSuccess(MESSAGES.DRIVER_UPDATE_SUCCESS);
    } catch (e) {
      showError(e);
      setIsDriver(isDriver);
    } finally {
      setDriverToggleLoading(false);
    }
  };

  const toggleOnlineFn = async () => {
    try {
      await toggleOnlineStatusByAdmin(driverId);
      setIsDriverOnline(!isDriverOnline);
      showSuccess(MESSAGES.DRIVER_UPDATE_SUCCESS);
    } catch (e) {
      showError(e);
      setIsDriverOnline(isDriverOnline);
    }
  };

  const fetchCities = async () => {
    try {
      setCityLoading(true);
      let response = await fetchAllCities();
      response = JSON.parse(JSON.stringify(response));
      setCityList(response);
    } catch (e) {
      showError(e);
    } finally {
      setCityLoading(false);
    }
  };

  const fetchVehicleTypeList = async () => {
    const response = await fetchVehicleTypes();
    setVehicleTypeList(JSON.parse(JSON.stringify(response)));
  };

  const getDeactivationDropdown = () => {
    if (!isDriver) {
      return (
        <DeactivationReasonDropDown
          loading={loading}
          deactivationReason={driver?.deactivationReason}
          onDeactivationReasonChange={e => onPersonalDetailChange({ 'deactivationReason': e })}
          onSuccess={e => onPersonalDetailChange(e)}
          driver={driver}
        />
      );
    } else {
      return null;
    }
  };

  if (loading) return <Loading />;

  return (
    <div className='driver-details-container'>
      <div className='header'>
        <div className="row">
          <div className="col-6">
            <BackButton />
          </div>
          <div className="col-6 text-right">
            {editPersonalDetail && <>
              <ActionButton text="Cancel" active={!savePersonalDetailsLoading} onClick={cancelChange} isLoading={false} customClass="btn-secondary mr-2" />
            </>}
            <ActionButton text={`${editPersonalDetail ? 'Save' : 'Edit'}`} active={!savePersonalDetailsLoading} onClick={updatePersonalDetails} isLoading={savePersonalDetailsLoading} customClass="btn-primary" />
          </div>
        </div>
        <div className="row">
          <div className="d-flex">
            <h3 className="pl-3">Driver Detail</h3>
            <IsDriverComponent isDriver={isDriver} toggleIsDriverFn={toggleIsDriverFn} isLoading={driverToggleLoading} />
            {getDeactivationDropdown()}
            <span className="ml-3 border-left pl-3">
              <OnlineStatus isOnline={isDriverOnline} toggleOnline={toggleOnlineFn} />
            </span>
          </div>
          <div className="col text-right">
            <span className="font-weight-bold pr-2">
              {`since ${formatDate(driver.driverApprovedAt?.iso || driver.createdAt, DATE_FORMATS.MMM_YYYY)}`}
            </span>
          </div>
        </div>
        <div className="mt-3">
          <DriverPersonalDetail {...driver} savePersonalDetailsLoading={savePersonalDetailsLoading} cities={cityList} cityLoading={cityLoading} vehicleTypeList={vehicleTypeList}
            isEditing={editPersonalDetail} onPersonalDetailChange={onPersonalDetailChange} />
        </div>
        <div className="mt-3 border-top pt-2">
          <DriverEarnings {...driver} />
        </div>
        <div className="mt-3 border-top pt-3">
          <Documents documents={documents} userId={driverId} 
            onDocumentChange={fetchDriverDocuments} customClass="pb-5" 
            AVAILABLE_DOCUMENT_LIST={AVAILABLE_DOCUMENT_LIST} isApprovedRequired/>
        </div>
        <div className="mt-3 border-top pt-3">
          <DriverOrderHistory {...driver} {...props} />
        </div>
        <div className="mt-3 border-top pt-2">
          <DriverDailyEarnings {...driver} />
        </div>
        <div className="mt-3 border-top pt-2">
          <DriverWeeklyEarnings {...driver} />
        </div>
        <div className="mt-3 border-top pt-3">
          <DriverNotes notes={driver.notes} id={driverId} addNotesAPI={addDriverNotes} 
            editNotesAPI={editDriverNotes} deleteNoteAPI={deleteDriverNotes}/>
        </div>
        <div className="mt-3 border-top pt-3">
          <DriverActivities {...driver} />
        </div>
        <div className="mt-3 border-top pt-3">
          <DriverRemovedFromOrders driverId={driverId} {...props} />
        </div>
        <div className="mt-3 border-top pt-3">
          <BlackListedMerchants driverId={driverId} />
        </div>
      </div>
    </div>
  );
};

export default DriverDetails;
