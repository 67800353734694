import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { showError } from 'utils';
import { fetchRestaurantOnlineTime } from 'services/api/restaurant';
import ActionButton from 'components/ActionButton/ActionButton';
import { DateRangePickerWrapper } from 'components/Date/DateRangePicker';
import moment from 'moment';
import _ from 'lodash';

const RestaurantOnlineTimeGraph = ({ restaurantId }) => {
  const [onlineTimeValues, setOnlineTimeValues] = useState<any>();
  const [startDate,setStartDate] = useState<any>(moment().subtract(1,'month').toDate());
  const [endDate,setEndDate] = useState<any>(moment().toDate());
  const [loading, setLoading] = useState<boolean>(false);
  const fetchRestaurantOnlineTimeFn = async () => {
    setLoading(true);
    try {
      let response = await fetchRestaurantOnlineTime({ restaurantId,startDate,endDate });
      response = response.map((value: any) => {
        return [new Date(_.get(value, 'day.iso')).getTime(), _.get(value, 'avgOnlineTime')];
      });
      setOnlineTimeValues(response);
    } catch (e) {
      showError(e);
    } finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRestaurantOnlineTimeFn();
  }, []);

  const onDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    setStartDate(moment(startDate).toDate());
    setEndDate(moment(endDate).toDate());
  };
  
  const Filter = () => (
    <div className={'d-flex flex-column flex-md-row align-items-center align-items-md-end mb-3 mt-3 mt-md-0'}>
      <DateRangePickerWrapper
        startDate={startDate}
        endDate={endDate}
        onDatesChange={onDateSelectionChange}
      />
      <ActionButton
        text={'Submit'}
        active={!loading}
        isLoading={loading}
        onClick={fetchRestaurantOnlineTimeFn}
        customClass={'ml-3 mt-3 mb-1'}
      />
    </div>
  );

  const SectionHeader = () => (
    <div className={`d-flex px-0 px-md-4 justify-content-between align-items-center flex-column flex-md-row`}>
      <div style={{ fontSize: `20px`, lineHeight: `26px`, color: '#161616' }} className={'ml-3 mb-3'}>Restaurant Online Time</div>
      <div className={'ml-0 ml-lg-auto'}>
        <Filter />
      </div>
    </div>
  );

  const options = {
    colors: ['#f4511d'],
    yAxis: {
      title: {
        text: 'Online Time (in mins)',
      },
    },
    xAxis: {
      title: {
        text: 'Date',
      },
      type: 'datetime',
      labels: {
        format: '{value:%b-%e}',
      },
      tickInterval: 1000 * 60 * 60 ,
    },
    series: [{
      type: 'bar',
      //   pointWidth: 20,
      name:'Average Online Time',
      data: onlineTimeValues,
    }],
    step:1,
  };
  
  return (
    <div className='p-3'>
      <SectionHeader/>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default RestaurantOnlineTimeGraph;