import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styles from './index.module.scss';
import qs from 'query-string';

const TabView = props => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const {
    tabsList,
    tabsPanelList,
  } = props;
  
  useEffect(() => {
    const {
      selectedTab,
    } = qs.parse(props.location.search, { parseNumbers: true });
    if (selectedTab && typeof selectedTab === 'number') {
      setSelectedTab(selectedTab);
    }
  }, []);

  const handleSelect = (index: number) => {
    setSelectedTab(index);
    const queryParams = qs.stringify({ selectedTab: index });
    props.history.push({
      pathname: props.location.pathname,
      search: queryParams,
    });
  };
  
  const getTabPanels = () => tabsPanelList.map(Panel => (
    <TabPanel
      key={Panel.key}
      className={styles['tab-panel']}
    >
      <Panel.Component
        {...Panel.props}
      />
    </TabPanel>
  ));
  
  const getTabSelectors = () => (
    <TabList className={styles['tabs-selector-container']}>
      {tabsList.map((tab, i) => (
        <Tab
          key={tab.key}
          className={`${styles['tab-selector']} ${(selectedTab === i) && styles['selected']}`}
        >
          <div>{tab.label}</div>
          <div className={styles['tab-border']} />
        </Tab>
      ))}
    </TabList>
  );
  
  return (
    <Tabs 
      className={styles['tabs-container']}
      selectedIndex={selectedTab}
      onSelect={index => handleSelect(index)}
    >
      {getTabSelectors()}
      {getTabPanels()}
    </Tabs>
  );
};

export default TabView;