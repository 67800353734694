import React, { useEffect, useState } from 'react';
import { fetchAdminOrders } from 'services/api/order';
import DataTable from 'react-data-table-component';
import { DATE_FORMATS, formatDate, fromStripeAmount, getPageLimit, metersToMile, updatePageLimit } from 'utils';
import qs from 'query-string';
import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import { showError } from 'utils/notifications';
import moment from 'moment';
import TableLink from 'TableLink';
import { fetchShopifyOrders } from 'services/shopifyApi/orders';
import { OrdersFilter } from './orders-filter';
import Currency from 'components/Currency';

let filters: { [p: string]: any; page: number } = {
  page: 0,
  endDate: moment().toString(),
  startDate: moment().subtract(1, 'month').toString(),
};

const ShopifyOrdersList = props => {
  const [orders, setOrders] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      name: 'Order ID',
      selector: '_id',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/shopify/orders/${row._id}`}
          text={row._id}
          openInNewTab={false}
        />
      ),
    },
    {
      name: 'UVA Order Id',
      selector: 'uvaOrderId',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/orders/${row.uvaOrderId}`}
          text={row.uvaOrderId}
          openInNewTab={false}
        />
      ),
    },
    {
      name: 'Shopify Shop Name',
      selector: 'merchantId',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/shopify/merchants/${row.merchantId._id}`}
          text={row.merchantId.shopName}
          openInNewTab={false}
        />
      ),
    },
    {
      name: 'Delivery Charges',
      selector: 'uvaOrderResponse',
      format: row => <Currency amount={row?.uvaOrderResponse?.deliveryCharges}/>,
    },
    {
      name: 'Delivery Taxes',
      selector: 'uvaOrderResponse',
      format: row => <Currency amount={row?.uvaOrderResponse?.deliveryTaxes} />,
    },
    {
      name: 'Shopify Order Id',
      selector: 'shopifyOrderId',
    },
    {
      name: 'Created At',
      selector: 'createdAt',
      sortable: true,
      width: '180px',
      format: row => formatDate(row.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
    },
  ];

  const fetchAdminOrdersFn = async () => {
    setLoading(true);
    try {
      const response = await fetchShopifyOrders(qs.stringify({
        page: filters.page,
        limit: getPageLimit(),
        q: btoa(JSON.stringify({ filter: filters, selectedProperties: ['orderNumber', 'shopifyOrderId','uvaOrderId','uvaOrderResponse'] })),
      }));
      setOrders(response?.data);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  useEffect(() => {
    filters = {
      ...filters,
      ...queryStringToFilter(),
    };
    fetchAdminOrdersFn();
  }, []);

  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchAdminOrdersFn();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const onFilterChanged = (udpatedfilters: Record<string, any>) => {
    if (udpatedfilters._id) {
      props.history.push(`/shopify/orders/${udpatedfilters._id}`);
      return;
    }
    filters = { ...udpatedfilters, page: 0 };
    updateQueryString();
    fetchAdminOrdersFn();
  };

  return (
    <div>
      {loading && <Loading />}
      <OrdersFilter
        location={props.location}
        onFilterSubmit={onFilterChanged}
      />
      <DataTable
        title="Orders"
        keyField="_id"
        columns={columns}
        data={orders.orders}
        highlightOnHover={true}
        defaultSortField="createdAt"
        defaultSortAsc={false}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationDefaultPage={(filters.page || 0) + 1}
        paginationTotalRows={orders.total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </div>
  );
};

export { ShopifyOrdersList };
