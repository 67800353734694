import React from 'react';
import './index.scss';

const BackButton = () => {
  return (
    <div className='back-button' onClick={() => window.history.back()}>
      <i className='fa fa-arrow-left' />
        Back
    </div>
  );
};

export default BackButton;