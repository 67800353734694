import React from 'react';
import { SCRAPPER_PLATFORM } from 'utils';

const ScrapperPlatformDropdown = props => {
  const { value, onChange } = props;
  
  const getOptions = () => {
    const keys = Object.keys(SCRAPPER_PLATFORM);
    return keys.map((key, i) => (
      <option key={i} value={SCRAPPER_PLATFORM[key]}>{key}</option>
    ));
  };
  
  return (
    <select
      value={value}
      className={'form-control'}
      onChange={e => onChange(e.target.value)}
    >
      <option value={'undefined'} hidden={!!value}>Choose a platform</option>
      {getOptions()}
    </select>
  );
};

export default ScrapperPlatformDropdown;