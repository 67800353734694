import * as React from 'react';
import './message.scss';

class Message extends React.Component<any, any> {

  render () {
    return (
      <p className="message text-secondary">{this.props.message || ''}</p>
    );
  }
}
export { Message };
