import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { getDishesForRestaurantMenuV2 } from 'services/api/restaurant';
import { showError, showSuccess } from 'utils/notifications';
import qs from 'query-string';
import { formatDate, getPageLimit, updatePageLimit } from 'utils';
import TableLink from 'TableLink';
import './index.scss';
import Currency from 'components/Currency';
import DishesFilter from './dishes-filter';
import ToggleSwitch from 'components/ToggleSwitch';
import { toggleDishAvailability } from 'services/api/dishes';

let filters: { [p: string]: any; page: number } = {
  page: 0,
  sort: {},
};

const Dishes = props => {
  const [dishes, setDishes] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const [toggleLoading, setToggleLoading] = useState<string>('');

  const fetchDishesForRestaurant = async (filters: Record<string, any>) => {
    setLoading(true);
    try {
      const { page, ...rest } = filters;
      const response = await getDishesForRestaurantMenuV2({
        page,
        limit: getPageLimit(),
        filter: {
          ...rest,
        },
      });
      setDishes(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  useEffect(() => {
    const filter = {
      ...filters,
      ...queryStringToFilter(),
    };
    fetchDishesForRestaurant(filter);
  }, []);

  const toggleDish = async (dishId: string) => {
    setToggleLoading(dishId);
    const data = {
      restaurantId: filters.restaurantId,
      dishId,
    };
    try {
      await toggleDishAvailability(data);
      await fetchDishesForRestaurant(filters);
      showSuccess('Dish availability toggled successfully');
    } catch (e) {
      showError(e);
    } finally {
      setToggleLoading('');
    }
  };

  const columns = useMemo(() => [
    {
      name: 'Dish ID',
      selector: 'objectId',
      width: '140px',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/dishes/${row.objectId}`}
          text={row.objectId}
          openInNewTab={true}
        />
      ),
    },
    {
      name: 'Created At',
      selector: 'createdAt',
      sortable: true,
      format: (row: any) => formatDate(row.createdAt, 'MM/DD/YYYY HH:mm'),
      width: '180px',
    },
    {
      name: 'Name',
      selector: 'name',
      width: '660px',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <div className={'dish-list-dish-detail'}>
          <div className={'dish-image'}>
            <img src={row.image ? row.image.url : require('assets/placeholder.svg')} />
          </div>
          <div className={'dish-name-and-desc'}>
            <div className={'dish-name-and-id'}>
              <div className={'dish-name'}>{row.name}</div>
            </div>
            <div className={'dish-description'}>{row.description}</div>
          </div>
        </div>
      ),
    },
    {
      name: 'Price',
      selector: 'price',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <div className={'dishes-list-dish-price'}>
          <Currency amount={row.price} />
        </div>
      ),
    },
    {
      name: 'Availability',
      selector: 'availability',
      // eslint-disable-next-line react/display-name
      cell: row => toggleLoading === row.objectId ? (
        <Loading type={'mini'} />
      ) : (
        <ToggleSwitch
          switchLabel={''}
          checked={row.available}
          onChange={() => toggleDish(row.objectId)}
          disabled={false}
        />
      ),
    },
    {
      name: 'Variants',
      selector: 'variantsCount',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <div className={'dishes-list-dish-variant-count'}>
          {row.variantsCount ? row.variantsCount : '0'} Variant{row.variantsCount > 1 ? 's' : ''}
        </div>
      ),
    },
    {
      name: 'Deleted',
      selector: 'deleted',
      width: '120px',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <div className={`flag ${row.deleted ? 'deleted' : 'not-deleted'}`}>
          {row.deleted ? 'DELETED' : 'N/A'}
        </div>
      ),
    },
  ], [toggleLoading]);

  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchDishesForRestaurant(filters);
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const onFilterChanged = (updatedFilters: Record<string, any>) => {
    filters = { ...updatedFilters, page: 0 };
    updateQueryString();
    fetchDishesForRestaurant(filters);
  };

  const onSort = (column: Record<string, any>, sortDirection: string) => {
    const sort = filters.sort || {};
    filters = {
      ...filters,
      sort: {
        ...sort,
        [column.selector]: sortDirection,
      },
    };
    fetchDishesForRestaurant(filters);
  };

  return (
    <div>
      {loading && <Loading />}
      <DishesFilter
        {...props}
        onFilterSubmit={onFilterChanged}
      />
      <DataTable
        title={'Dishes'}
        keyField="objectId"
        columns={columns}
        data={dishes.items}
        highlightOnHover={true}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationDefaultPage={(filters.page || 0) + 1}
        paginationTotalRows={dishes.total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onSort={onSort}
      />
    </div>
  );
};

export { Dishes };