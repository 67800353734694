import React from 'react';
import moment from 'moment';
import './index.scss';
import ActionButton from 'components/ActionButton/ActionButton';
import { IReportsFilterProps } from 'interfaces/reportsFilter';
import DateRangePresetsPicker from 'components/Date/DateRangePresetsPicker';
import RestaurantAutoSuggest from 'components/RestaurantAutoSuggest';

const presets = [
  { start: moment().startOf('month'), end: moment(), text: 'This Month' },
  { start: moment().startOf('month').subtract(1, 'day').startOf('month'), end: moment().startOf('month').subtract(1, 'day'), text: 'Last Month' },
  { start: moment().startOf('week'), end: moment(), text: 'This Week' },
  { start: moment().startOf('week').subtract(1, 'day').startOf('week'), end: moment().startOf('week').subtract(1, 'day'), text: 'Last Week' },
];

const ReportsFilter: React.FC<IReportsFilterProps> = props => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    emails,
    setEmails,
    sendEmail,
    restaurantId,
    setRestaurantId,
    setSendEmail,
    handleSubmit,
    btnText,
    loading,
    showEmailInput,
    onReset,
  } = props;

  const onDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    startDate && setStartDate(moment(startDate).toISOString());
    endDate && setEndDate(moment(endDate).toISOString());
  };

  return (
    <>
      {/* <BackButton /> */}
      <form className='filter-container'>
        <div className="input-container mr-2 mb-2">
          <DateRangePresetsPicker
            presets={presets}
            startDate={startDate}
            endDate={endDate}
            onDatesChange={onDateSelectionChange}
          />
        </div>
        {showEmailInput ? (
          <div className='input-container mr-2 mb-2'>
            <input
              type='email'
              className='form-control'
              placeholder='Emails'
              disabled={loading || !sendEmail}
              value={emails}
              onChange={e => setEmails && setEmails(e.target.value)}
            />
            <div className='hint mb-2'>Hint: separate multiple emails using commas</div>
          </div>
        ) : null}
        <div className='filter-container m-0'>
          {showEmailInput ? (
            <div className={'d-flex'}>
              <div className='label mr-2'>Send Email</div>
              <input
                type='checkbox'
                disabled={loading}
                checked={sendEmail}
                onChange={e => setSendEmail && setSendEmail(e.target.checked)}
                className={'mr-2'}
              />
            </div>
          ) : null}
          <div className='input-container mr-2 mb-2'>
            {setRestaurantId && <RestaurantAutoSuggest
              placeholder={'Search Restaurant'}
              onSuggestionSelect={suggestion => setRestaurantId(suggestion.id)}
            />}
          </div>
          <div className='button-container'>
            <ActionButton
              text={btnText || 'Submit'}
              active={!loading}
              isLoading={false}
              customClass={'btn-primary m-0'}
              onClick={handleSubmit}
            />
            {onReset && (
              <ActionButton
                text={'Reset'}
                active={true}
                isLoading={false}
                customClass={'btn-primary ml-3 mt-0 mr-0 mb-0'}
                onClick={onReset}
              />
            )
            }
          </div>
        </div>
      </form>
    </>
  );
};

export default ReportsFilter;
