import { cloudFunction } from 'services';

export const createUvaCategory = (name: string, esName: string, parentCategoryId: string) => {
  return cloudFunction('createUvaCategory', { name, esName, parentCategoryId });
};

export const getUvaCategoryDetail = (id: string) => {
  return cloudFunction('fetchUvaCategoryById', { id });
};

export const fetchAllUvaCategories = () => {
  return cloudFunction('fetchAllUvaCategories', { });
};

export const updateUvaCategoryDetail = (data: Record<string, any>) => {
  return cloudFunction('updateUvaCategory', data);
};

export const uploadUvaCategoryIcon = (data: Record<string, any>) => {
  return cloudFunction('uploadUvaCategoryIcon', data);
};

export const fetchUvaCategoryTree = async() => {
  const resp = await cloudFunction('fetchUvaCategoryTree', {});
  return resp;
};

export const switchToMainCategory = async(id: string) =>{
  await cloudFunction('toggleAsMainCategory',{ id });
};