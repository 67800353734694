import Header, { CancelButton, RenderAddress } from './components';
import React, { useState } from 'react';
import styles from './index.module.scss';
import { addressComponents } from './index';
import { reverseGeoCode } from '../../../services/api/address';
import ActionButton from '../../ActionButton/ActionButton';
import { IAddress } from '../../../interfaces/address';

const LocationDetails: React.FC<IAddress.ILocationDetails> = (props) => {
  const {
    setComponentToShow,
    localAddress = {},
    setLocalAddress,
    handleClose,
    isVisible = true,
  } = props;
  const [loading, setLoading] = useState(false);

  const onClickContinue = async () => {
    try {
      setLoading(true);
      const addressDetails = await reverseGeoCode(localAddress?.coords?.lat, localAddress?.coords?.lng);
      setLocalAddress({
        ...localAddress,
        town: addressDetails?.town,
        zip: addressDetails?.zip,
        number: addressDetails?.number,
      });
      setComponentToShow(addressComponents.addressDetails);
      
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const getUserCurrentLocation = async () => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(async function (position) {
      const latLng = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      const addressDetails = await reverseGeoCode(latLng?.lat, latLng?.lng);
      setLocalAddress({
        ...localAddress,
        coords: latLng,
        address: addressDetails?.address,
      });
      setLoading(false);
    });
  };

  const getCtaText = () => {
    return loading ? 'Fetching location details' : 'Continue';
  };

  const onClickChangeAddress = () => {
    setComponentToShow(addressComponents.searchLocation);
  };

  if(!isVisible) return null;

  return (
    <div className="col-12">
      <Header title="Select your location" onClose={handleClose} />
      <span className={styles['location-label']}>Delivery Area</span>
      <RenderAddress 
        address={localAddress?.address} 
        onClick={onClickChangeAddress}
        onClickCurrentLocation={getUserCurrentLocation}
      />
      <div className="d-flex align-items-center justify-content-between mt-2">
        <CancelButton onCancel={handleClose} />
        <div>
          <ActionButton
            text={getCtaText()}
            onClick={onClickContinue}
            active={!loading}
            customClass={'orange'}
            isLoading={loading}
          />
        </div>
      </div>
    </div>
  );
};
export default LocationDetails;
