import BackButton from 'components/BackButton';
import ImageUpload from 'components/ImageUpload';
import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from'react';
import { Link } from 'react-router-dom';
import { getUvaCategoryDetail, uploadUvaCategoryIcon } from 'services/api/uvaCategories';
import { DATE_FORMATS, formatDate } from 'utils';
import { showError } from 'utils/notifications';
import '../../dishes/dish-details/index.scss';
import { switchToMainCategory } from '../../../services/api/uvaCategories';
import ToggleSwitch from 'components/ToggleSwitch';

const UvaCategoryDetails =(props) =>{
  const [details, setDetails] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [toggleLoading, setToggleLoading] = useState<boolean>(false);
  const [logo, setLogo] = useState<string>('');

  const { uvaCategoryId } = props.match.params;

  const uploadIconImage = (img) => {
    try {
      uploadUvaCategoryIcon({
        icon: img,
        id: uvaCategoryId,
      });
      // handleChange('icon', img);
    } catch (e) {
      showError(e);
    }
  };

  const fetchDetails = async () => {
    setLoading(true);
    try {
      const res = await getUvaCategoryDetail(uvaCategoryId);
      if (res){
        setDetails(JSON.parse(JSON.stringify(res)));
      }
    } catch (e) {
    //   showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [uvaCategoryId]);

  const toggleAsMainCategory =async()=>{
    try{
      setToggleLoading(true);
      await switchToMainCategory(uvaCategoryId);
      fetchDetails();
    }catch(e){
      showError(e);
    }finally {
      setToggleLoading(false);
    }
  };

  const getHeader = () => (
    <div className={'header'}>
      <div>Uva Category Details</div>
      <BackButton />
    </div>
  );

  const getImageSection = () => (
    <div className={'dish-image-section d-flex flex-column align-items-center'}>
      <img src={logo || details.icon?.url || require('assets/placeholder.svg')} />
      <ImageUpload
        onImageSelect={setLogo}
        pickerText={logo || details.icon?.url ? 'Change Icon' : 'Upload Icon'}
        onImageUpload={uploadIconImage}
      />
    </div>
  );

  const getUvaCategoryDetails = () => (
    <div className={'dish-basic-details-section'}>
      <div className={'image-and-name-container'}>
        {getImageSection()}
        <div className={'dish-name-section justify-content-start'}>
          <div className={'action-button-section'}>
            <div className={'dish-details-group'}>
              <div className={'label'}>Category ID</div>
              <div className={'detail'}>{uvaCategoryId}</div>
            </div>
            <div className={'button-group'}>
              <i
                className={'fa fa-pencil'}
                onClick={() => props.history.push(`/uva-categories/${uvaCategoryId}/edit`)}
              />
            </div>
          </div>
          <div className={'action-button-section'}>
            <div className={'dish-details-group'}>
              <div className={'label'}>Name</div>
              <div className={'detail'}>{details.name}</div>
            </div>
            <div className={'button-group'}>
              <ToggleSwitch
                switchLabel={'Main category'}
                onChange={toggleAsMainCategory}
                checked={details.useAsMainCategory}
                disabled={toggleLoading}
              />
            </div>
          </div>
          <div className={'dish-details-group'}>
            <div className={'label'}>Spanish Name</div>
            <div className={'detail'}>{details.esName}</div>
          </div>
          <div className={'dish-details-group'}>
            <div className={'label'}>Parent Category </div>
            <div className={'detail'}>
              {details?.parentCategory ?
                <Link
                  to={`/uva-categories/${details?.parentCategory?.objectId}`}
                  style={{ color: '#FF3D00' }}
                >
                  <u>{details.parentCategory?.name}</u>
                </Link>: 'N/A'
              }
            </div>
          </div>
          <div className={'dish-details-group'}>
            <div className={'label'}>Created At</div>
            <div className={'detail'}>{formatDate(details.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A)}</div>
          </div>
        </div>
      </div>
    </div>
  );

  if (loading) return <Loading />;

  return(
    <div className={'dish-details-main-container'}>
      {getHeader()}
      <div className={'dish-details-section'}>
        {getUvaCategoryDetails()}
      </div>
    </div>
    
  );
};

export default UvaCategoryDetails;