import { IDataTableColumn, IDataTableProps } from 'react-data-table-component';

export const DRIVER_EARNING_DATA_TABLE_COLUMNS = [
  {
    name: 'Total Orders',
    selector: 'totalOrders',
    sortable: false,
  },
  {
    name: 'Earnings',
    selector: 'earnings',
    sortable: false,
    format: row => `$ ${row.earnings}`,
  },
  {
    name: 'UVA Bonus',
    selector: 'uvaBonus',
    sortable: false,
    format: row => `$ ${row.uvaBonus}`,
  },
  {
    name: 'Distance Bonus',
    selector: 'distanceBonus',
    sortable: false,
    format: row => `$ ${row.distanceBonus}`,
  },
  {
    name: 'Rejection Bonus',
    selector: 'rejectionBonus',
    sortable: false,
    format: row => `$ ${row.rejectionBonus}`,
  },
  {
    name: 'Tip',
    selector: 'tip',
    sortable: false,
    format: row => `$ ${row.tip}`,
  },
  {
    name: 'Toll',
    selector: 'toll',
    sortable: false,
    format: row => `$ ${row.toll}`,
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: false,
    format: row => `$ ${row.total}`,
  },
] as IDataTableColumn<any>[];

export const DRIVER_EARNING_CSV_COLUMNS = [
  {
    label: 'Total Orders',
    value: 'totalOrders',
  },
  {
    label: 'Earnings',
    value: 'earnings',
  },
  {
    label: 'UVA Bonus',
    value: 'uvaBonus',
  },
  {
    label: 'Distance Bonus',
    value: 'distanceBonus',
  },
  {
    label: 'Rejection Bonus',
    value: 'rejectionBonus',
  },
  {
    label: 'Tip',
    value: 'tip',
  },
  {
    label: 'Toll',
    value: 'toll',
  },
  {
    label: 'Total',
    value: 'total',
  },
];