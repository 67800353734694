import React, { useEffect, useState } from 'react';
import { getEntityTypes } from 'services/api/cloudRequests';
import { ENTITY_CLASS_MAPPINGS, sortArray } from 'utils';
import { showError } from 'utils/notifications';

const EntityClassDropdown = props => {
  const [entityClasses, setEntityClasses] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const {
    value,
    onChange,
    disabled,
  } = props;
  
  const fetchEntityClasses = async () => {
    try {
      const response = await getEntityTypes();
      const dropdownOptionsArray = sortArray(
        response.map(entityClass => {
          const [entity] = ENTITY_CLASS_MAPPINGS.filter(entityObj => entityObj.key === entityClass);
          return {
            // providing entityClass as a fallback value will save crashes
            // but the option display value will not be formatted
            value: entity?.key || entityClass,
            label: entity?.dropdownValue || entityClass,
          };
        }), 'label',
      );
      setEntityClasses(dropdownOptionsArray);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEntityClasses();
  }, []);
  
  return (
    <select
      className={'form-control'}
      value={value}
      onChange={e => onChange(e.target.value)}
      disabled={disabled || loading}
    >
      <option hidden={!!value}>Select entity type</option>
      {entityClasses.map((entity, i) => <option key={`${entity.value + i}`} value={entity.value}>{entity.label}</option>)}
    </select>
  );
};

export default EntityClassDropdown;