import React from 'react';
import './index.scss';

const DataTableFilter = props => React.useMemo(() => {
  const { filterText, setFilterText, placeholder } = props;

  const handleClear = () => {
    if (filterText) {
      setFilterText('');
    }
  };

  return (
    <div className='search-container'>
      <input className='search-input' type="text" placeholder={placeholder} value={filterText} onChange={e => setFilterText(e.target.value)} />
      <button className='search-clear' onClick={handleClear}>X</button>
    </div>
  );
}, [props.filterText]);

export default DataTableFilter;