import React from 'react';
import AsyncJobs from 'components/AsyncJobs/AsyncJobs';
import { formatDate } from 'utils';

const DishImportsList = props => {
  const onRowClicked = row => {
    props.history.push(`/job-detail/${row.id}`);
  };

  const columns = [
    {
      name: 'Job ID',
      selector: 'id',
      sortable: false,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: false,
    },
    {
      name: 'Created At',
      selector: 'createdAt',
      sortable: true,
      format: (row: any) => formatDate(row.createdAt),
    },
    {
      name: 'Created By',
      selector: 'startedBy',
      sortable: false,
    },
  ];
  
  return (
    <>
      <button
        onClick={()=> props.history.push('/import-dishes')}
        className='btn btn-primary float-right'
      >
        Start New Import
      </button>
      <AsyncJobs
        type='DISH_IMPORT'
        columns={columns}
        onRowClicked={onRowClicked}
      />
    </>
  );
};

export { DishImportsList };