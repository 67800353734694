import RenderInfo from 'components/RenderInfo';
import React from 'react';
import './index.scss';

const ColorTheme = (props) => {
  const { restaurantDetails: { colorTheme: { primary = '-', secondary = '-' } = {}} = {}} = props;

  return (
    <div className={'color-theme-container'}>
      <div className={'heading'}>Color Theme</div>
      <div className={'color-theme-sub-container'}>
        <RenderInfo 
          heading="Primary" 
          customComponent={() => {
            return (
              <div style={{ color: primary }}>
                {primary}
              </div>
            );
          }} 
        />
        <RenderInfo 
          heading="Secondary" 
          customComponent={() => {
            return (
              <div style={{ color: secondary }}>
                {secondary}
              </div>
            );
          }} 
        />
      </div>
    </div>
  );
};

export default ColorTheme;
