import React from 'react';
import { connect } from 'react-redux';
import './home.scss';

class Home extends React.Component<any, any> {
  render () {
    return (
      <div className="home">
        Home
      </div>
    );
  }
}

const HomeWrapper = connect(null, null)(Home);
export { HomeWrapper as Home };
