import React, { useState } from 'react';
import '../AddRestaurant/index.scss';
import ActionButton from 'components/ActionButton/ActionButton';
import { IAddRestaurant } from 'interfaces/addRestaurant';
import NameFilterAutosuggest from 'components/NameFilterAutoSuggest';
import { searchUserByName } from 'services/api/order';

const AddDriver = (props: IAddRestaurant) => {
  const [driverId, setDriverId] = useState<string>('');
  const { toggleModal, onSubmit } = props;
  
  const addDriverFn = async () => {
    onSubmit(driverId);
  };
  
  return (
    <div className='add-restaurant-container'>
      <div className='add-restaurant-modal'>
        <div className='modal-header'>
          <div>Add Driver</div>
          <i className='fa fa-close' onClick={()=>toggleModal()} />
        </div>
        <div className='modal-body'>
          <div className={''}>
            <label>Drivers</label>
            <NameFilterAutosuggest
              entityId={driverId}
              placeholder={'Driver Name'}
              onSuggestionSelected={setDriverId}
              fetchSuggestions={event => searchUserByName(event, true)}
            />
          </div>
        </div>
        <div className='modal-footer'>
          <ActionButton
            text='Cancel'
            active={true}
            isLoading={false}
            onClick={toggleModal}
          />
          <ActionButton
            text={'Add'}
            active={!!driverId}
            isLoading={false}
            customClass='btn-primary'
            onClick={addDriverFn}
          />
        </div>
      </div>
    </div>
  );
};

export default AddDriver;