import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';
import './index.scss';
import AddCategoryModal from 'components/AddCategoryModal';
import ActionButton from 'components/ActionButton/ActionButton';
import { showError, showSuccess } from 'utils';
import { markCategoryAvailable, markCategoryUnavailable } from 'services/api/restaurant';

const DraggableList = props => {
  const { list, onDragEnd, recordAction, id, editable, onEditSuccess } = props;
  const [categoryCurrentName, setCategoryCurrentName] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<string>('');
  const [edit, setEdit] = useState<boolean>(false);

  const handleDragEnd = (start: Record<string, any>, end: Record<string, any>) => {
    if (!start || !end) {
      return;
    };

    const startIndex = start.index;
    const endIndex = end.index;
    const reOrderedCategories = _.cloneDeep(list);
    const [removed] = reOrderedCategories.splice(startIndex, 1);
    reOrderedCategories.splice(endIndex, 0, removed);
    onDragEnd(reOrderedCategories);
  };

  const handleEdit = (item: string) => {
    setCategoryCurrentName(item);
    setModalOpen(true);
    setEdit(!edit);
  };

  const handleCategoryAction = async (category: string, action: boolean) => {
    setLoading(category);
    try {
      if (action) {
        await markCategoryAvailable(category);
        showSuccess(`Dishes marked enabled for category: ${category}`);
      } else {
        await markCategoryUnavailable(category);
        showSuccess(`Dishes marked disabled for category: ${category}`);
      }
    } catch (e) {
      showError(e);
    } finally {
      setLoading('');
    }
  };

  const renderActionButtons = (item: string) => {
    if (editable) {
      return (
        <>
          <ActionButton
            active={true}
            isLoading={item === loading}
            text={'Enable Dishes'}
            customClass={'ml-2'}
            onClick={() => handleCategoryAction(item, true)}
          />
          <ActionButton
            active={true}
            isLoading={item === loading}
            text={'Disable Dishes'}
            customClass={'ml-2 disable-btn'}
            onClick={() => handleCategoryAction(item, false)}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const getCategoryActions = (item: string) => (
    <div className='row-action'>
      {editable && (
        <i
          className={'fa fa-pencil ml-2 edit-icon'}
          onClick={() => handleEdit(item)}
        />
      )
      }
      {renderActionButtons(item)}
      <i
        className={'fa fa-trash ml-2 delete-icon'}
        onClick={() => recordAction(item)}
      />
    </div>
  );

  return (
    <>
      {modalOpen && (
        <AddCategoryModal
          restaurantId={id}
          categoryCurrentName={categoryCurrentName}
          onEditSuccess={onEditSuccess}
          toggleModal={() => setModalOpen(!modalOpen)}
        />
      )}
      <div className={'draggable-list-container'}>
        <DragDropContext onDragEnd={({ source, destination }) => handleDragEnd(source, destination)}>
          <Droppable droppableId={'droppable'}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`draggable-list ${snapshot.isDraggingOver ? 'not-dragging' : 'dragging'}`}
              >
                {list.map((item, index) => (
                  <Draggable key={item} draggableId={item} index={index}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        className={`draggable-item ${snapshot.isDragging ? 'dragging-item' : 'not-dragging'}`}
                      >
                        <img
                          {...provided.dragHandleProps}
                          className={'drag-handle'}
                          src={require('assets/drag-handle.svg')}
                        />
                        {item}
                        {getCategoryActions(item)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

export default DraggableList;