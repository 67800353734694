import React, { useState } from 'react';
import './notes.scss';
import ActionButton from 'components/ActionButton/ActionButton';
import { addLeadNote } from 'services/api/lead';
import SingleNote from './SingleNote';
import { ILead } from 'interfaces';
import { showError, showSuccess, showWarning } from 'utils/notifications';

const Notes: React.FC<ILead.INotesProps> = props => {
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  const { notes, leadId, refetchLeadDetails } = props;

  const addNote = async () => {
    if (!note) {
      showWarning('Note cannot be empty.');
      return;
    }

    setLoading(true);
    try {
      await addLeadNote(leadId, note);
      showSuccess('Note added successfully.');
      setNote('');
      refetchLeadDetails();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };
  
  const renderNotes = () => {
    if(notes.length > 0) {
      return notes.map((note, i) => {
        return (
          <SingleNote key={i} note={note} />
        );
      });
    }
    return <div className='emptylist-message'>No notes added</div>;
  };
  
  const renderAddNoteSection = () => {
    return (
      <>
        <textarea
          value={note}
          disabled={loading}
          onChange={e => setNote(e.target.value)}
          className='form-control'
        />
        <ActionButton
          text='Add Note'
          active={true}
          isLoading={loading}
          onClick={addNote}
          customClass="btn-primary float-right"
        />
      </>
    );
  };
  
  return (
    <div className='notes-main-container custom-scrollbar mt-4'>
      <div className='heading-container'>
        <h4 className='font-weight-bold'>Notes</h4>
      </div>
      <div className='notes-container'>
        <div className='notes-list'>
          {renderNotes()}
        </div>
        <div className='add-note-section'>
          {renderAddNoteSection()}
        </div>
      </div>
    </div>
  );
};

export default Notes;