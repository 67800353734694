import React from 'react';
import Currency from 'components/Currency';
import { Loading } from 'components/loading/Loading';
import { REFUND_TYPES } from 'utils';
import EntityMiniCard from 'components/EntityMiniCard';

const RefundDetails = props => {
  const { loading, refundDetails } = props;

  const getVariants = (variants: Record<string, any>[]) => {
    return (
      <>
        <div className='variants-label'>Variants</div>
        {variants.map(variant => {
          return (
            <div key={variant.id} className='variant'>
              <div className='variant-name'>{variant.name}</div>
              <div className='variant-price'><Currency amount={variant.price} /></div>
            </div>
          );
        })}
      </>
    );
  };

  const getRefundMetadata = (singleRefund) => {
    return (
      <div className='refund-item'>
        <div className='item-details'>
          <div className='dish-quantity'>1x</div>
          <div className='plate-details'>
            <div className='dish-details'>
              <div className='dish-name'>{singleRefund.metadata.name}</div>
              <div className='dish-price'><Currency amount={singleRefund.metadata.price} /></div>
            </div>
            <div className='variant-details'>
              {!!singleRefund.metadata.dishesDescription.specs.length && getVariants(singleRefund.metadata.dishesDescription.specs)}
            </div>
          </div>
        </div>
        <div className='reason-details'>
          <div className='reason-label'>Reason</div>
          <div className='reason'>{singleRefund.metadata.reasonDisplayValue}</div>
        </div>
      </div>
    );
  };

  const getRefundType = (type: string) => {
    switch (type) {
    case REFUND_TYPES.FULL: return 'Full Refund';
    case REFUND_TYPES.PARTIAL: return 'Partial Refund';
    case REFUND_TYPES.DELIVERY: return 'Delivery';
    default: return '';
    }
  };

  const getSingleRefund = () => refundDetails.map((refundTransaction, i) => {
    const getSingleRefundTransaction = () => refundTransaction.map((singleRefund, i) => {
      if (singleRefund.type === REFUND_TYPES.BY_ITEM) {
        return (
          <div key={`${i + singleRefund.totalAmount}`} className='single-refund'>
            <div className='refund-header'>
              <div className='refund-type'>
                Per Item
              </div>
              <div className='total-amount'><Currency amount={singleRefund.totalAmount} /></div>
            </div>
            {singleRefund.metadata && getRefundMetadata(singleRefund)}
          </div>
        );
      }

      return (
        <div key={singleRefund.objectId} className='full-refund-container'>
          <div className='full-refund-header'>
            <div className='refund-type'>{getRefundType(singleRefund.type)}</div>
            <div className='refund-amount'><Currency amount={singleRefund.totalAmount} /></div>
          </div>
          <div className='reason-label'>Reason</div>
          <div className='reason'>{singleRefund.reason}</div>
        </div>
      );
    });

    return (
      <div key={i} className='single-transaction-container'>
        {getSingleRefundTransaction()}
        <div className='refunded-by-section'>
          <div className='refunded-by-header'>Refunded By</div>
          <EntityMiniCard
            entityInfo={{
              name: refundTransaction[0]?.refundedBy.get('full_name'),
              createdAt: refundTransaction[0]?.refundedBy.createdAt,
              icon: 'fa fa-user-circle',
              type: 'User',
              objectId: refundTransaction[0]?.refundedBy?.id,
            }}
          />
        </div>
      </div>
    );
  });

  if (loading) return <Loading type='mini' />;

  if (refundDetails.length > 0) {
    return (
      <div className='refunds-container'>
        <div className='label'>Refund Details</div>
        <div className='refunds'>
          {getSingleRefund()}
        </div>
      </div>
    );
  }

  return null;
};

export default RefundDetails;
