import React, { useState } from 'react';
import { fetchConsolidatedTaxSummaryForRestaurants } from 'services';
import { showError } from 'utils/notifications';
import moment from 'moment';
import ReportsFilter from 'components/ReportsFilter';
import { Loading } from 'components/loading/Loading';
import DataTable from 'react-data-table-component';
import { tableStyles } from 'css/tableStyles';
import './index.scss';
import ExportCSVButton from 'components/ExportCSVButton';
import EmptySectionMessage from 'components/message/EmptySectionMessage';
import TableLink from 'TableLink';

const columns = [
  {
    name: 'Restaurant ID',
    selector: 'restaurantId',
    // eslint-disable-next-line react/display-name
    cell: row => (
      <div className={'tax-summary-table-cell'}>
        <TableLink
          linkTo={`/restaurants/${row.restaurantId}`}
          text={row.restaurantId}
        />
      </div>
    ),
  },
  {
    name: 'Restaurant Name',
    selector: 'restaurantName',
    // eslint-disable-next-line react/display-name
    cell: row => (
      <div className={'tax-summary-table-cell'}>{row.restaurantName}</div>
    ),
  },
  {
    name: 'Restaurant Email',
    selector: 'restaurantEmail',
    // eslint-disable-next-line react/display-name
    cell: row => (
      <div className={'tax-summary-table-cell'}>{row.restaurantEmail}</div>
    ),
  },
  {
    name: 'Duration',
    selector: 'duration',
    // eslint-disable-next-line react/display-name
    cell: row => (
      <div className={'tax-summary-table-cell'}>{row.duration}</div>
    ),
  },
  {
    name: 'Subtotal 6',
    selector: 'subtotal6',
  },
  {
    name: 'Subtotal 105',
    selector: 'subtotal105',
  },
  {
    name: 'Municipal Taxes',
    selector: 'foodTaxes',
  },
  {
    name: 'State Taxes 6',
    selector: 'stateTaxes6',
  },
  {
    name: 'State Taxes 105',
    selector: 'stateTaxes105',
  },
  {
    name: 'State Taxes',
    selector: 'stateTaxes',
  },
  {
    name: 'Total Municipal Tax Refund',
    selector: 'totalFoodTaxRefund',
  },
  {
    name: 'Total State Tax 6 Refund',
    selector: 'totalStateTax6Refund',
  },
  {
    name: 'Total State Tax 105 Refund',
    selector: 'totalStateTax105Refund',
  },
  {
    name: 'Total State Tax Refund',
    selector: 'totalStateTaxRefund',
  },
  {
    name: 'Total Municipal Tax Deposited',
    selector: 'totalFoodTaxDeposited',
  },
  {
    name: 'Total State Tax Deposited',
    selector: 'totalStateTaxDeposited',
  },
  {
    name: 'Total Tax Deposited',
    selector: 'totalTaxDeposited',
  },
];

const columnsForCSV = [
  {
    label: 'Restaurant ID',
    value: 'restaurantId',
  },
  {
    label: 'Restaurant Name',
    value: 'restaurantName',
  },
  {
    label: 'Restaurant Email',
    value: 'restaurantEmail',
  },
  {
    label: 'Duration',
    value: 'duration',
  },
  {
    label: 'Subtotal 6',
    value: 'subtotal6',
  },
  {
    label: 'Subtotal 105',
    value: 'subtotal105',
  },
  {
    label: 'Municipal Taxes',
    value: 'foodTaxes',
  },
  {
    label: 'State Taxes 6',
    value: 'stateTaxes6',
  },
  {
    label: 'State Taxes 105',
    value: 'stateTaxes105',
  },
  {
    label: 'State Taxes',
    value: 'stateTaxes',
  },
  {
    label: 'Total Municipal Tax Refund',
    value: 'totalFoodTaxRefund',
  },
  {
    label: 'Total State Tax 6 Refund',
    value: 'totalStateTax6Refund',
  },
  {
    label: 'Total State Tax 105 Refund',
    value: 'totalStateTax105Refund',
  },
  {
    label: 'Total State Tax Refund',
    value: 'totalStateTaxRefund',
  },
  {
    label: 'Total Municipal Tax Deposited',
    value: 'totalFoodTaxDeposited',
  },
  {
    label: 'Total State Tax Deposited',
    value: 'totalStateTaxDeposited',
  },
  {
    label: 'Total Tax Deposited',
    value: 'totalTaxDeposited',
  },
];

const TaxSummaryReport = props => {
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null | string>(moment().subtract(1, 'month').toDate());
  const [endDate, setEndDate] = useState<Date | null | string>(moment().toDate());
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [apiCalled, setAPICalled] = useState<boolean>(false);
  
  const fetchTaxSummary = async () => {
    if (!apiCalled) setAPICalled(true);
    setLoading(true);
    try {
      const response = await fetchConsolidatedTaxSummaryForRestaurants({
        startDate,
        endDate,
      });
      setData(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const getDataTable = () => {
    if (loading) return <Loading />;

    if (data.length) {
      const exportCSVButton = <ExportCSVButton key={''} data={data} columnsForCSV={columnsForCSV} fileName={'Tax Summary'} />;

      return (
        <DataTable
          title={'Reports'}
          columns={columns}
          data={data}
          customStyles={tableStyles}
          striped={true}
          actions={[exportCSVButton]}
        />
      );
    } else {
      return apiCalled ? <EmptySectionMessage message={'No records found'} /> : null;
    }
  };
  
  return (
    <div>
      <ReportsFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        showEmailInput={false}
        handleSubmit={fetchTaxSummary}
      />
      {getDataTable()}
    </div>
  );
};

export default TaxSummaryReport;