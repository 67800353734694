import React from 'react';
import DataTable from 'react-data-table-component';
import { Loading } from './loading/Loading';
import { tableStyles } from 'css/tableStyles';

const DataTableWrapper = props => {
  const { columns, data, fetchData, filter, totalRows, onRowClick, loading, onSort } = props;

  const onChangePage = (page: number) => {
    filter.page = page - 1;
    fetchData();
  };

  const onChangeRowsPerPage = (rowsPerPage: number) => {
    filter.limit = rowsPerPage;
    fetchData();
  };

  return (
    <DataTable
      title='Jobs'
      keyField='_id'
      data={data}
      columns={columns}
      highlightOnHover={true}
      customStyles={tableStyles}
      pagination={true}
      progressPending={loading}
      persistTableHead={true}
      progressComponent={<Loading />}
      paginationServer={true}
      expandableRows={false}
      expandOnRowClicked={false}
      paginationTotalRows={totalRows}
      onRowClicked={onRowClick}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSort={onSort}
    />
  );
};

export default DataTableWrapper;
