import React, { useEffect, useState } from 'react';
import { showError, sortArray } from 'utils';
import _ from 'lodash';
import { fetchAllSubscriptionsForAdmin } from 'services/api/subscriptions';

const SubscriptionTypeDropdown = props => {
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<Record<string, any>[]>([]);
  const {
    value,
    onChange,
    disabled,
  } = props;

  const fetchAllSubscriptionsFn = async () => {
    setLoading(true);
    try {
      const response = await fetchAllSubscriptionsForAdmin({});
      setSubscriptions(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllSubscriptionsFn();
  }, []);

  const getOptions = () => sortArray(subscriptions, 'name').map((row, i) => (
    <option key={i} value={row.objectId}>{row.name}</option>
  ));

  return (
    <>
      <select
        value={value}
        disabled={!subscriptions.length || loading || disabled}
        className={'form-control'}
        onChange={e => onChange(e.target.value)}
      >
        <option value={''} hidden={!!value}>Choose Subscription Type</option>
        {subscriptions.length && getOptions()}
      </select>
    </>
  );
};

export default SubscriptionTypeDropdown;