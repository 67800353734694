import React, { useEffect, useState } from 'react';
import ReactMapboxGl, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { assignRoute, explodeRoute, fetchRouteDetail, fetchRouteUpdates, removeDriverFromRoute } from 'services/api/routes';
import { showError, showSuccess } from 'utils/notifications';
import './index.scss';
import { Loading } from 'components/loading/Loading';
import { DATE_FORMATS, ROUTE_ACTIVITY_TYPE, convertSecondsToMinutesHours, formatDate, metersToKm } from 'utils';
import Currency from 'components/Currency';
import NameFilterAutosuggest from 'components/NameFilterAutoSuggest';
import ActionButton from 'components/ActionButton/ActionButton';
import { searchUserByName } from 'services/api/order';
import StopTooltip from './StopTooltip';
import BackButton from 'components/BackButton';
import JSONViewer from 'components/JSONViewer';
import PseudoLink from 'components/PseudoLink';

const RouteDetail = props => {
  const { routeId } = props.match.params;
  const [loading, setLoading] = useState<boolean>(true);
  const [udpatesLoading, setUpdatesLoading] = useState<boolean>(false);
  const [markerCoords, setMarkerCoords] = useState<Record<string, any>[]>([]);
  const [route, setRoute] = useState<Record<string, any>>({});
  const [activities, setActivities] = useState<Record<string, any>[]>([]);
  const [orders, setOrders] = useState<Record<string, any>[]>([]);
  const [driver, setDriver] = useState<Record<string, any> | null>(null);
  const [driverId, setDriverId] = useState<string>('');
  const [assignDriverLoading, setAssignDriverLoading] = useState<boolean>(false);
  const [removeDriverLoading, setRemoveDriverLoading] = useState<boolean>(false);
  const [explodeRouteLoading, setExplodeRouteLoading] = useState<boolean>(false);
  const [routeUpdates, setRouteUpdates] = useState<Record<string, any>[] | null>(null);
  const [viewport, setViewport] = useState<Record<string, any>>({
    latitude: 18.2208,
    longitude: -66.5901,
    zoom: 14,
  });

  const fetchRouteUpdatesFn = async () => {
    setUpdatesLoading(true);
    try {
      const response = await fetchRouteUpdates(routeId);
      setRouteUpdates(response);
    } catch (e) {
      showError(e);
    } finally {
      setUpdatesLoading(false);
    }
  };

  const explodeRouteFn = async () => {
    setExplodeRouteLoading(true);
    try {
      await explodeRoute(routeId);
      fetchRouteDetails();
    } catch (e) {
      showError(e);
    }
    setExplodeRouteLoading(false);
  };
  
  const fetchRouteDetails = async () => {
    try {
      const response = await fetchRouteDetail(routeId);
      setRoute(response.route);
      setOrders(response.orders || []);
      setDriver(response.route?.driver);
      setActivities(response.route?.activities || []);
      const markers = response.route?.points?.filter(point => point.type === 'Point');
      setMarkerCoords(markers || []);
      if (!!markers?.length) {
        setViewport({
          ...viewport,
          longitude: markers[0].coordinates[0],
          latitude: markers[0].coordinates[1],
          zoom: 11,
        });
      }
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchRouteDetails();
  }, []);

  const removeDriverFromRouteFn = async () => {
    setRemoveDriverLoading(true);
    try {
      await removeDriverFromRoute(routeId); 
      await fetchRouteDetails();
      fetchRouteUpdatesFn();
      setDriver(null);
      showSuccess('Driver removed successfully');
    } catch (e) {
      showError(e);
    } finally {
      setRemoveDriverLoading(false);
    } 
  };
  
  const assignRouteToDriverFn = async () => {
    if (driverId) {
      setAssignDriverLoading(true);
      try {
        await assignRoute(routeId, driverId);
        await fetchRouteDetails();
        fetchRouteUpdatesFn();
        showSuccess('Driver assigned successfully');
      } catch (e) {
        showError(e);
      } finally {
        setAssignDriverLoading(false);
      }
    }
  };

  const getUpdatesSection = () => {
    if (udpatesLoading) return <Loading type={'mini'} />;

    if (routeUpdates === null) {
      return (
        <div className={'ml-3'}>
          <PseudoLink text={'Show Route Updates'} onClick={fetchRouteUpdatesFn} />
        </div>
      );
    }
    
    if (!!routeUpdates?.length) {
      return (
        <div className='route-updates-section'>
          <div className='label'>Route Updates</div>
          {routeUpdates.map((update, i) => {
            return (
              <div key={i} className='single-update'>
                <JSONViewer data={update} rootVisible={false} />
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };
  
  const getMapSection = () => {
    const ActivityLog = props => {
      const { activity, index, type } = props;
      const [order] = orders.filter(record => record.objectId === activity.id);
            
      const getStatus = () => {
        switch (type) {
        case 'start':
          return 'START';
        case 'pickupShipment':
          return 'PICKUP SHIPMENT';
        case 'deliverShipment':
          return 'DELIVER SHIPMENT';
        default:
          break;
        }
        return;
      };
      
      const getAddressFromOrder = () => {
        if (type === ROUTE_ACTIVITY_TYPE.PICKUP_SHIPMENT) {
          return order['pickupAddress'];
        } else {
          return order['deliveryAddress'];
        }
      };

      const getActivityDate = () => {
        switch (type) {
        case ROUTE_ACTIVITY_TYPE.PICKUP_SHIPMENT:
          if (order.pickedUpAt) {
            return `Picked up at ${formatDate(order.pickedUpAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A)}`;
          }
        case ROUTE_ACTIVITY_TYPE.DELIVER_SHIPMENT:
          if (order.deliveredAt) {
            return `Delivered at ${formatDate(order.deliveredAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A)}`;
          }
        default:
          if (order.createdAt) {
            return `Created at ${formatDate(order.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A)}`;
          } else {
            return '';
          }
        }
      };
      
      return (
        <div className={'activity-log'}>
          <div className={'activity-indicator'}>
            <div className={'activity-marker'}>
              <img className={'line'} src={require('assets/activity-indicator.svg')} />
              <div className={'circle'}>
                <img className={'circle-image'} src={require('assets/map-marker.svg')} />
                <div className={'activity-marker-number'}>{index}</div>
              </div>
            </div>
          </div>
          <div className={'activity-details'}>
            {type === ROUTE_ACTIVITY_TYPE.START ? (
              <div className={'start-activity'}>START</div>
            ): (
              <>
                <div className={'activity-info'}>
                  <span className={'font-weight-bold mr-3'}>{activity.id}</span>
                  {!!order && (
                    <div className={'d-flex align-items-center'}>
                      <i className={'fa fa-circle'} />
                      <span className={'ml-3'}>{getActivityDate() || ''}</span>
                    </div>
                  )}
                </div>
                <div className={'activity-address'}>
                  {order ? getAddressFromOrder() : ''}
                </div>
              </>
            )}
          </div>
          <div className={'activity-stats'}>
            <div className={`activity-status ${activity.type}`}>
              {getStatus()}
            </div>
            {type !== ROUTE_ACTIVITY_TYPE.START ? (
              <div className={'chevron'} onClick={() => props.history.push(`/orders/${activity.id}`)}>
                <i className={'fa fa-chevron-right'} />
              </div>
            ): null}
          </div>
        </div>
      );
    };

    const renderActivitiesSection = () => (
      <div className={'activites-section'}>
        <div className='label mb-4'>Stop Details</div>
        {activities?.length > 0 && activities?.map((activity, i) => (
          <ActivityLog
            {...props}
            key={i}
            index={i + 1}
            activity={activity}
            type={activity.type}
          />
        ))}
      </div>
    );
    
    const renderMap = () => {
      const getDataForMarker = (orderId: string, type: string) => {
        let markerData: Record<string, any> = {
          type,
          orderId,
        };
        if (type !== ROUTE_ACTIVITY_TYPE.START) {
          const [order] = orders?.filter(record => record.objectId === orderId);
          markerData = {
            ...markerData,
            address: type === ROUTE_ACTIVITY_TYPE.DELIVER_SHIPMENT
              ? order?.deliveryAddress
              : order?.pickupAddress,
          };
        }
        return markerData;
      };
      
      return (
        <ReactMapboxGl
          {...viewport}
          className={'map'}
          width={'100%'}
          height={'420px'}
          mapStyle='mapbox://styles/mapbox/streets-v9'
          mapboxApiAccessToken={process.env.REACT_APP_MAP_BOX_API_KEY}
          onViewportChange={viewport => setViewport(viewport)}
        >
          {activities?.map((activity, i) => {
            if (activity.address) {
              const { lon, lat } = activity.address;
              if (!lon || !lat) return null;
              if (typeof lon !== 'number' || typeof lat !== 'number') return null;

              return (
                <div key={i}>
                  <Marker
                    key={i}
                    className={'marker'}
                    longitude={activity.address.lon}
                    latitude={activity.address.lat}
                  >
                    <div data-for={`stop-${i}`} data-tip={''}>
                      <img src={require('assets/map-marker.svg')} />
                      <div className={'marker-number'}>{i + 1}</div>
                    </div>
                  </Marker>
                  <StopTooltip
                    id={`stop-${i}`}
                    data={getDataForMarker(activity.id || '', activity.type) || {}}
                  />
                </div>
              );
            }
            return null;
          })}
        </ReactMapboxGl>
      );
    };

    // if (!orders?.length) return null;

    return (
      <div className={'map-container'}>
        {renderMap()}
        {renderActivitiesSection()}
      </div>
    );
  };

  const getAssignDriverSection = () => (
    <div className='assign-driver-section'>
      <div className='label'>Driver Details</div>
      <div className='section'>
        <NameFilterAutosuggest
          entityId={driverId}
          placeholder={'Driver Name'}
          onSuggestionSelected={setDriverId}
          onlyDriver={true}
          fetchSuggestions={searchUserByName}
        />
        <ActionButton
          text={'Assign'}
          active={true}
          isLoading={assignDriverLoading}
          customClass={'btn-primary export'}
          onClick={assignRouteToDriverFn}
        />
      </div>
    </div>
  );

  const getDriverDetailsSection = () => (
    <div className='driver-details-section'>
      <div className='single-section'>
        <div className='label'>Driver Details</div>
        <div className='driver-name-container'>
          <div>
            <i className='fa fa-car' />
          </div>
          <div>
            <div className='driver-name'>{driver?.full_name || ''}</div>
            <div className='driver-joined'>Driver since {formatDate(driver?.createdAt, DATE_FORMATS.YYYY)}</div>
          </div>
        </div>
      </div>
      <div className='single-section'>
        <ActionButton
          text={'Remove Driver'}
          active={!removeDriverLoading}
          isLoading={removeDriverLoading}
          onClick={removeDriverFromRouteFn}
          customClass={'btn btn-primary mb-3'}
        />
        <div className='label'>Phone Number</div>
        <div className='driver-phone'>{driver?.phone || ''}</div>
      </div>
    </div>
  );
  
  const getBasicDetailsSection = () => (
    <div className={'basic-route-details-container'}>
      <div className={'info-group'}>
        <div className={'label'}>Route ID</div>
        <div className={'detail font-weight-bold'}>{routeId}</div>
      </div>
      <div className={'info-group'}>
        <div className={'label'}>Stops</div>
        <div className={'detail'}>{markerCoords?.length ? markerCoords.length.toString() : 'N/A'}</div>
      </div>
      <div className={'info-group'}>
        <div className={'label'}>Distance</div>
        <div className={'detail'}>
          {`${metersToKm(route.distance) || 0} ${route.distance > 999 ? 'km' : 'm'}`}
        </div>
      </div>
      <div className={'info-group'}>
        <div className={'label'}>Time Duration</div>
        <div className={'detail theme-orange'}>{convertSecondsToMinutesHours(route.transportTime || 0)}</div>
      </div>
      <div className={'info-group'}>
        <div className={'label'}>Date Time Created</div>
        <div className={'detail'}>{formatDate(route.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A)}</div>
      </div>
      <div className={'info-group'}>
        <div className={'label'}>Price</div>
        <div className={'detail font-weight-bold theme-orange'}>
          <Currency amount={route.totalEarning || 0} />
        </div>
      </div>
      <div className={'info-group'}>
        <div className={`route-status ${route.status}`}>{route.status}</div>
      </div>
    </div>
  );
  
  const getExplodeRouteButton = () => {
    return (
      <ActionButton
        text={'Explode Route'}
        active={true}
        isLoading={explodeRouteLoading}
        customClass={'btn-primary'}
        onClick={explodeRouteFn}
      />
    );
  };

  const getHeader = () => (
    <div className={'route-header'}>
      <div className={'route-heading'}>Detailed Route</div>
      <BackButton />
      <div style={{ textAlign: 'right' }}>
        {getExplodeRouteButton()}
      </div>
    </div>
  );
  
  return (
    <>
      {loading && <Loading />}
      <div>
        {getHeader()}
        {getBasicDetailsSection()}
        {driver ? getDriverDetailsSection() : getAssignDriverSection()}
        {getMapSection()}
        {getUpdatesSection()}
      </div>
    </>
  );
};

export { RouteDetail };