import ActionButton from 'components/ActionButton/ActionButton';
import BackButton from 'components/BackButton';
import { Loading } from 'components/loading/Loading';
import React, { useEffect, useMemo, useState } from 'react';
import { excludeOrderFromAvgCookingTime, excludeOrderFromAvgElapsedTime, whyAmIHidden } from 'services/api/restaurant';
import TableLink from 'TableLink';
import { showError, showSuccess } from 'utils/notifications';
import './index.scss';
import _ from 'lodash';
import { DATE_FORMATS, formatDate } from 'utils';
import RestaurantStatsOrdersTable from './RestaurantStatsOrdersTable';
import qs from 'query-string';
import RestaurantAutoSuggest from 'components/RestaurantAutoSuggest';

const RestaurantStats = props => {
  const [loading, setLoading] = useState<boolean>(false);
  const [restaurantId, setRestaurantId] = useState<string>('');
  const [stats, setStats] = useState<Record<string, any>>({});
  const [statsAvailable, setStatsAvailable] = useState<boolean>(false);
  const [elapsedExcludeLoading, setElapsedExcludeLoading] = useState<string>('');
  const [cookingExcludeLoading, setCookingExcludeLoading] = useState<string>('');
  const valueGetter = (valuePath: string) => _.get(stats, valuePath, '');

  const fetchRestaurantStats = async (restaurantId: string) => {
    setStatsAvailable(false);
    try {
      setLoading(true);
      const response = await whyAmIHidden(restaurantId);
      setStats(response);
      setStatsAvailable(true);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { restaurantId } = qs.parse(props.location.search);
    if (typeof restaurantId === 'string') {
      setRestaurantId(restaurantId);
      fetchRestaurantStats(restaurantId);
    }
  }, []);

  const handleSubmit = () => {
    props.history.replace({
      pathname: props.location.pathname,
      search: qs.stringify({ restaurantId }),
    });
    fetchRestaurantStats(restaurantId);
  };

  const handleSelect = (e: any) => {
    setRestaurantId(e.target.value);
  };

  const onKeyDown = (e: any) => {
    e.preventDefault();
    if (e.keyCode === 13) {
      setRestaurantId(e.target.value);
      handleSubmit();
    }
  };

  const handleExclude = async (type: string, orderId: string) => {
    const payload = {
      restaurantId, orderId,
    };
    try {
      if (type === 'Elapsed') {
        setElapsedExcludeLoading(orderId);
        await excludeOrderFromAvgElapsedTime(payload);
      } else {
        setCookingExcludeLoading(orderId);
        await excludeOrderFromAvgCookingTime(payload);
      }
      await fetchRestaurantStats(restaurantId);
      showSuccess(`Order successfully excluded from Avg. ${type} Time`);
    } catch (e) {
      showError(e);
    } finally {
      setElapsedExcludeLoading('');
      setCookingExcludeLoading('');
    }
  };

  const getDataForAvgCookingTime = () => {
    const orders: Record<string, any> = [];
    const { ordersForAvgCookingTime } = stats;
    const orderIds = Object.keys(ordersForAvgCookingTime || {});
    orderIds.map(orderId => {
      orders.push({
        orderId,
        loadFactor: ordersForAvgCookingTime[orderId].loadFactor.toFixed(2),
        cookingTime: ordersForAvgCookingTime[orderId].cookingTime,
      });
    });
    return orders;
  };

  const columnsForAvgElapsedTime = useMemo(() => [
    {
      name: 'Order ID',
      selector: 'objectId',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/orders/${row.objectId}`}
          text={row.objectId}
          openInNewTab={true}
        />
      ),
    },
    {
      name: 'Elapsed Time',
      selector: 'elapsedTime',
    },
    {
      name: 'Action',
      selector: '',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <ActionButton
          text={'Exclude'}
          active={elapsedExcludeLoading !== row.objectId}
          isLoading={elapsedExcludeLoading === row.objectId}
          onClick={() => handleExclude('Elapsed', row.objectId)}
          customClass={'btn btn-primary restaurant-stats-exclude-button'}
        />
      ),
    },
  ], [restaurantId, elapsedExcludeLoading]);

  const columnsForAvgCookingTime = useMemo(() => [
    {
      name: 'Order ID',
      selector: 'orderId',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/orders/${row.orderId}`}
          text={row.orderId}
          openInNewTab={true}
        />
      ),
    },
    {
      name: 'Load Factor',
      selector: 'loadFactor',
    },
    {
      name: 'Cooking Time',
      selector: 'cookingTime',
    },
    {
      name: 'Action',
      selector: '',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <ActionButton
          text={'Exclude'}
          active={cookingExcludeLoading !== row.orderId}
          isLoading={cookingExcludeLoading === row.orderId}
          onClick={() => handleExclude('Cooking', row.orderId)}
          customClass={'btn btn-primary restaurant-stats-exclude-button'}
        />
      ),
    },
  ], [restaurantId, cookingExcludeLoading]);

  const getBody = () => {
    const getPendingOrders = () => !!stats.ordersForAvgElapsedTime?.pendingOrders?.length && (
      <RestaurantStatsOrdersTable
        data={stats.ordersForAvgElapsedTime?.pendingOrders}
        columns={columnsForAvgElapsedTime}
        header={'Pending Orders'}
      />
    );

    const getLast3Orders = () => !!stats.ordersForAvgElapsedTime?.last3DeliveredOrders?.length && (
      <RestaurantStatsOrdersTable
        data={stats.ordersForAvgElapsedTime?.last3DeliveredOrders}
        columns={columnsForAvgElapsedTime}
        header={'Last 3 Orders'}
      />
    );

    const getRestaurantAvgElapsedTimeSection = () => (
      <div className={'details-group'}>
        <div className={'label'}>Restaurant Avg. Elapsed Time</div>
        <div className={'info'}>
          {valueGetter('avgElapsedTime')}
        </div>
      </div>
    );

    const getOrdersConsideredSection = () => !!Object.keys(stats.ordersForAvgCookingTime || {}).length && (
      <RestaurantStatsOrdersTable
        data={getDataForAvgCookingTime()}
        columns={columnsForAvgCookingTime}
        header={'Orders Considered'}
      />
    );

    const getRestaurantAvgCookingTimeSection = () => (
      <div className={'details-group'}>
        <div className={'label'}>Restaurant Avg. Cooking Time</div>
        <div className={'info'}>{valueGetter('avgCookingTime')}</div>
      </div>
    );

    const getHideReason = () => stats.hideReason ? (
      <div className={'details-group'}>
        <div className={'label'}>Hide Reason</div>
        <div className={'info'}>{valueGetter('hideReason')}</div>
      </div>
    ) : null;

    const getHideOverride = () => stats.hideOverride ? (
      <div className={'details-group'}>
        <div className={'label'}>Hide Override</div>
        <div className={'info'}>{valueGetter('hideOverride')}</div>
      </div>
    ) : null;

    const getDecisionSection = () => (
      <div className={'details-group'}>
        <div className={'label'}>Decision</div>
        <div className={'info'}>{valueGetter('hidden') ? 'Hidden' : 'Shown'}</div>
      </div>
    );

    const getLastOrderMadeAt = () => stats.lastOrderMadeAt ? (
      <div className={'details-group'}>
        <div className={'label'}>Last Order Made At</div>
        <div className={'info'}>{formatDate(valueGetter('lastOrderMadeAt'), DATE_FORMATS.MM_DD_YYYY_HH_MM_A)}</div>
      </div>
    ) : null;

    const getConfigSection = () => (
      <div className={'restaurant-stats-config'}>
        <div className={'label'}>Configuration</div>
        <div className={'config-group'}>
          <div className={'details-group'}>
            <div className={'label'}>Max Average Cooking Time</div>
            <div className={'info'}>
              {valueGetter('maxAverageCookingTime')}
            </div>
          </div>
          <div className={'details-group'}>
            <div className={'label'}>Max Average Elapsed Time</div>
            <div className={'info'}>
              {valueGetter('maxAverageElapsedTime')}</div>
          </div>
        </div>
      </div>
    );

    const getRestaurantFilter = () => (
      <div className={'filter-container'}>
        <div className={'input-container mr-2'}>
          <label>Restaurant Id</label>
          <input
            value={restaurantId}
            onChange={e => setRestaurantId(e.target.value)}
            className={'form-control'}
          />
        </div>
        <div className='input-container mr-2'>
          <label>Restaurant</label>
          <RestaurantAutoSuggest
            placeholder={'Search Restaurant'}
            onSuggestionSelect={suggestion=>setRestaurantId(suggestion.id)}
          />
        </div>
        <ActionButton
          text={'Submit'}
          isLoading={loading}
          active={!!restaurantId}
          onClick={handleSubmit}
          customClass={'btn-primary'}
        />
      </div >
    );

    return (
      <div className={'restaurant-stats-body'}>
        {getRestaurantFilter()}
        {statsAvailable ? (
          <div className={'stats-section'}>
            {getConfigSection()}
            {getLastOrderMadeAt()}
            {getDecisionSection()}
            {getHideOverride()}
            {getHideReason()}
            {getRestaurantAvgCookingTimeSection()}
            {getOrdersConsideredSection()}
            {getRestaurantAvgElapsedTimeSection()}
            {getLast3Orders()}
            {getPendingOrders()}
          </div>
        ) : null}
      </div>
    );
  };

  const getHeader = () => (
    <div className={'restaurant-stats-header'}>
      <div className={'restaurant-heading'}>Restaurant Stats</div>
      <BackButton />
    </div>
  );

  return (
    <div className={'restaurant-stats-container'}>
      {loading && <Loading />}
      {getHeader()}
      {getBody()}
    </div>
  );
};

export default RestaurantStats;