import React, { useEffect, useState } from 'react';
import {
  assignRole,
  fetchRoles,
  fetchUserDetailForAdminDashboard,
  lockUser,
  reactivateUser,
  removeAsFleetUser,
  removeRoleForUser,
  setAsFleetUser,
  toggleOverrideValidPaymentMethodNeededFlag,
  unlockUser,
} from 'services/api/users';
import { showError, showSuccess } from 'utils/notifications';
import { Loading } from 'components/loading/Loading';
import BackButton from 'components/BackButton';
import './index.scss';
import { formatDate, fromStripeAmount, getPageLimit, updatePageLimit } from 'utils';
import Tag from 'components/Tag';
import ActionButton from 'components/ActionButton/ActionButton';
import DataTable from 'react-data-table-component';
import { fetchAdminOrders } from 'services/api/order';
import { tableStyles } from 'css/tableStyles';
import TableLink from 'TableLink';
import { OrdersFilter } from 'pages/orders/orders-filter';
import moment from 'moment';
import UserRestaurants from './UserRestaurants';
import { connect } from 'react-redux';
import ToggleSwitch from 'components/ToggleSwitch';
import CopyToClipBoard from 'components/CopyToClipBoard';
import { allowAPIAccess, generateToken, removeAPIAccess } from 'services/api/restaurant';
import UserFleetLocations from './UserFleetLocations';
import UserPrimeSubscription from './userPrimeSubscription';
import InternalUser from './internalUser';

const columns = [
  {
    name: 'Order ID',
    selector: 'objectId',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/orders/${row.objectId}`}
        text={row.objectId}
        openInNewTab={false}
      />
    ),
  },
  {
    name: 'Order Number',
    selector: 'orderNumber',
    sortable: false,
  },
  {
    name: 'Client',
    selector: 'clientName',
    sortable: false,
  },
  {
    name: 'Driver',
    selector: 'driverName',
    sortable: false,
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: false,
    format: row => `$${row.total}`,
  },
  {
    name: 'Date Time',
    selector: 'createdAt',
    sortable: true,
  },
  {
    name: 'Refunded',
    selector: 'refunded',
    sortable: false,
    format: row => `$${fromStripeAmount(row.refundAmount)}`,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
  },
];

let filter: { [p: string]: any } = {
  page: 0,
  endDate: moment().toISOString(),
  startDate: moment().subtract(1, 'month').toISOString(),
};

let sort = {};

const mapStateToProps = (state: any) => {
  const { roles } = state.auth.loggedInUser.toJSON();
  return { loggedInUserRoles: roles || [] };
};

const UserDetails = props => {
  const { loggedInUserRoles } = props;
  const { userId } = props.match.params;
  const [user, setUser] = useState<Record<string, any>>({});
  const [roles, setRoles] = useState<string[]>([]);
  const [selectedRole, setSelectedRole] = useState<string>('');
  const [orders, setOrders] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [assignLoading, setRoleLoading] = useState<boolean>(false);
  const [ordersLoading, setOrdersLoading] = useState<boolean>(false);
  const [lockLoading, setLockLoading] = useState<boolean>(false);
  const [fleetLoading, setFleetLoading] = useState<boolean>(false);
  const [reActivateLoading, setReActivateLoading] = useState<boolean>(false);
  const [tokenLoading, setTokenLoading] = useState<string>('');
  const [toggleOverrideValidPaymentMethodNeededFlagLoading, setToggleOverrideValidPaymentMethodNeededFlagLoading] = useState<boolean>(false);

  const fetchUserOrders = async () => {
    setOrdersLoading(true);
    try {
      const response = await fetchAdminOrders({
        filter: { ...filter, userId },
        limit: getPageLimit(),
        page: filter.page,
        sort,
      });
      setOrders(response);
    } catch (e) {
      showError(e);
    } finally {
      setOrdersLoading(false);
    }
  };

  const fetchUserDetails = async () => {
    try {
      const user = await fetchUserDetailForAdminDashboard(userId);
      setUser(user);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchRolesFn = async () => {
    try {
      const roles = await fetchRoles();
      setRoles(roles);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
    fetchRolesFn();
    fetchUserOrders();
  }, []);

  const assingRolesToUser = async () => {
    setRoleLoading(true);
    try {
      await assignRole(userId, selectedRole);
      setSelectedRole('');
      fetchUserDetails();
      showSuccess('Role assigned successfully');
    } catch (e) {
      showError(e);
    } finally {
      setRoleLoading(false);
    }
  };

  const removeRole = async (role: string) => {
    setRoleLoading(true);
    try {
      await removeRoleForUser(userId, role);
      fetchUserDetails();
    } catch (e) {
      showError(e);
    } finally {
      setRoleLoading(false);
    }
  };

  const lockUserFn = async () => {
    setLockLoading(true);
    try {
      const response = await lockUser(userId);
      setUser(response.toJSON());
      showSuccess('User locked successfully');
    } catch (e) {
      showError(e);
    } finally {
      setLockLoading(false);
    }
  };

  const unlockUserFn = async () => {
    setLockLoading(true);
    try {
      const response = await unlockUser(userId);
      setUser(response.toJSON());
      showSuccess('User unlocked successfully');
    } catch (e) {
      showError(e);
    } finally {
      setLockLoading(false);
    }
  };

  const setAsFleetUserFn = async () => {
    setFleetLoading(true);
    try {
      const response = await setAsFleetUser(userId);
      // setUser(response.toJSON());
      await fetchUserDetails();
      showSuccess('Set as fleet user successfully');
    } catch (e) {
      showError(e);
    } finally {
      setFleetLoading(false);
    }
  };

  const removeAsFleetUserFn = async () => {
    setFleetLoading(true);
    try {
      const response = await removeAsFleetUser(userId);
      // setUser(response.toJSON());
      window.location.reload();
      showSuccess('Unset as fleet user successfully');
    } catch (e) {
      showError(e);
    } finally {
      setFleetLoading(false);
    }
  };

  const toggleOverrideValidPaymentMethodNeededFlagFn = async () => {
    setToggleOverrideValidPaymentMethodNeededFlagLoading(true);
    try {
      const response = await toggleOverrideValidPaymentMethodNeededFlag(userId);
      // setUser(response.toJSON());
      await fetchUserDetails();
      showSuccess('Override payment method required for fleet order updated successfully');
    } catch (e) {
      showError(e);
    } finally {
      setToggleOverrideValidPaymentMethodNeededFlagLoading(false);
    }
  };

  const reactivateUserFn = async () => {
    setReActivateLoading(true);
    try {
      await reactivateUser(userId);
      await fetchUserDetails();
      showSuccess('User re-activated successfully');
    } catch (e) {
      showError(e);
    } finally {
      setReActivateLoading(false);
    }
  };


  const onChangePage = (page: number) => {
    filter.page = page - 1;
    fetchUserOrders();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const handleFilterSubmit = (updatedFilter: Record<string, any>) => {
    filter = { ...filter, ...updatedFilter, page: 0 };
    fetchUserOrders();
  };

  const onSort = (column: Record<string, any>, sortDirection: string) => {
    // TODO: BUG: sortDirection is always coming as "asc"
    sort = {
      ...sort,
      [column.selector]: sortDirection,
    };
    fetchUserOrders();
  };

  const getUserRestaurantsSection = () => (
    <UserRestaurants userId={userId} />
  );

  const getUserFleetLocationSection = () => (
    <UserFleetLocations userId={userId} />
  );

  const getUserOrders = () => {
    return (
      <div className='user-orders-section'>
        <h4 className='pl-2 font-weight-bolder'>User Orders</h4>
        <div className={'mb-5'}>
          <OrdersFilter
            {...props}
            showOrderNumberFilter
            showDriverNameFilter
            showRestaurantFilter
            onFilterSubmit={handleFilterSubmit}
          />
        </div>
        {ordersLoading ? (
          <Loading />
        ) : (
          <DataTable
            noHeader
            keyField="objectId"
            columns={columns}
            data={orders.items}
            highlightOnHover={true}
            customStyles={tableStyles}
            pagination={true}
            paginationServer={true}
            paginationDefaultPage={(filter.page || 0) + 1}
            paginationTotalRows={orders.total}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onSort={onSort}
          />
        )}
      </div>
    );
  };


  const getUserRolesSection = () => {
    if (!user.roles || !user.roles.length) return;
    return (
      <div className='user-roles-section'>
        <h4 className='pl-2 font-weight-bolder'>User Roles</h4>
        <div className='roles-card'>
          {user.roles.map((role, i) => <Tag key={i} tagName={role} onRemove={() => removeRole(role)} />)}
        </div>
      </div>
    );
  };

  const getLockUnlockUserSection = () => (
    <div className={'d-flex mt-2'}>
      <ActionButton
        text={'Lock User'}
        active={!lockLoading && !user.locked}
        isLoading={false}
        customClass={'btn-primary buttonUserLockSection mr-4'}
        onClick={lockUserFn}
      />
      <ActionButton
        text={'Unlock User'}
        active={!lockLoading && user.locked}
        isLoading={false}
        customClass={'btn-primary buttonUserLockSection'}
        onClick={unlockUserFn}
      />
    </div>
  );

  const getfleetUnFleetUserSection = () => (
    <div className={'d-flex mt-2'}>
      <ActionButton
        text={'Set as fleet user'}
        active={!fleetLoading && user.type !== 'fleet'}
        isLoading={false}
        customClass={'btn-primary mr-4'}
        onClick={setAsFleetUserFn}
      />
      <ActionButton
        text={'Unset as fleet user'}
        active={!fleetLoading && user.type === 'fleet'}
        isLoading={false}
        customClass={'btn-primary'}
        onClick={removeAsFleetUserFn}
      />
    </div>
  );

  const overrideValidPaymentMethodForFleetOrder = () => {
    return (
      <div className={'d-flex'}>
        <ToggleSwitch
          switchLabel={'Override payment method required for fleet order: '}
          disabled={toggleOverrideValidPaymentMethodNeededFlagLoading}
          checked={user?.overrideValidPaymentMethodForFleetOrder}
          onChange={() => {
            toggleOverrideValidPaymentMethodNeededFlagFn();
          }}
        />
      </div>
    );
  };

  const handleTokenAction = async () => {
    setTokenLoading('token-generate');
    try {
      await generateToken(userId);
      await fetchUserDetails();
    } catch (e) {
      showError(e);
    } finally {
      setTokenLoading('');
    }
  };

  const handleAPIAccessAction = async () => {
    try {
      if (user.allowAPIAccess) {
        setTokenLoading('remove');
        await removeAPIAccess(userId);
      } else {
        setTokenLoading('allow');
        await allowAPIAccess(userId);
      }
      await fetchUserDetails();
    } catch (e) {
      showError(e);
    } finally {
      setTokenLoading('');
    }
  };

  const getTokenActionsSection = () => (
    <div>
      <div className={'d-flex mt-2'}>
        <ActionButton
          text={'Allow API access'}
          active={!user.allowAPIAccess}
          isLoading={tokenLoading === 'allow'}
          onClick={handleAPIAccessAction}
          customClass={'btn btn-primary mr-4'}
        />
        <ActionButton
          text={'Remove API access'}
          active={user.allowAPIAccess}
          isLoading={tokenLoading === 'remove'}
          onClick={handleAPIAccessAction}
          customClass={'btn btn-primary'}
        />
      </div>
      <div>
        {user.accessToken ? (
          <div className={'d-flex mt-2'}>
            <div>{user.accessToken}</div>
            <CopyToClipBoard
              copyText={user.accessToken}
            />
          </div>
        ) : null}
        <ActionButton
          text={`${user.accessToken ? 'Re-' : ''}Generate Access Token`}
          active={user.allowAPIAccess}
          isLoading={tokenLoading === 'token-generate'}
          onClick={handleTokenAction}
          customClass={`${user?.accessToken ? 'reGenerateButton ' : 'generateButton '} btn btn-primary mt-2 `}
        />
      </div>
    </div>
  );

  const getUserBasicInfoSection = () => {
    return (
      <div className='user-details-section'>
        <h4 className='pl-2 font-weight-bolder'>User Details{user.type}</h4>
        <div className='d-flex flex-row justify-content-center details-card'>
          <div className='d-flex'>
            {user.profilePicture &&
              <div className='profile-picture-section'>
                <div className='profile-picture'>
                  <img src={user.profilePicture.url} alt="profile-picture" />
                </div>
              </div>
            }
            <div className='info-section'>
              <div className='label'>App Version</div>
              {user.appVersion && <div className='info'>{user.appVersion}</div>}
              {user.driverAppVersion && <div className='info'>{user.driverAppVersion}</div>}
              {user.merchantAppVersion && <div className='info'>{user.merchantAppVersion}</div>}
              {!(user.appVersion || user.driverAppVersion || user.merchantAppVersion) && <div className='info'>{'NA'}</div>}
              <div className='label'>User ID</div>
              <div className='info'>{user.objectId}</div>
              <div className='label'>Signed Up On</div>
              <div className='info'>{formatDate(user.createdAt)}</div>
              <div className='label'>Name</div>
              <div className='info'>{user.full_name}</div>
              {user.email &&
                <>
                  <div className='label'>Email</div>
                  <div className='info'>{user.email}</div>
                </>
              }
              {user.phone &&
                <>
                  <div className='label'>Phone</div>
                  <div className='info'>{user.phone}</div>
                </>
              }
              <div className='label'>IP Address</div>
              <div className='info'>{user.ipAddress || '-'}</div>
              {typeof user.locked !== 'undefined' &&
                <>
                  <div className='label'>Locked</div>
                  <div className='info'>{user.locked?.toString()}</div>
                </>
              }
              {user.address &&
                <>
                  <div className='label'>Address</div>
                  <div className='info'>{user.address}</div>
                </>
              }
              {user.totalOrdersMade &&
                <>
                  <div className='label'>Total Orders</div>
                  <div className='info'>{user.totalOrdersMade}</div>
                </>
              }
              {user.firstOrderMadeAt &&
                <>
                  <div className='label'>First Order</div>
                  <div className='info'>{formatDate(user.firstOrderMadeAt?.iso, 'lll')}</div>
                </>
              }
              {user.lastOrderMadeAt &&
                <>
                  <div className='label'>Last Order</div>
                  <div className='info'>{formatDate(user.lastOrderMadeAt?.iso, 'lll')}</div>
                </>
              }
              <InternalUser userDetails={user} />
            </div>
          </div>
          {loggedInUserRoles.includes('admin') &&
            <div style={{ flex: 1 }} className='d-flex align-items-end flex-column'>
              {overrideValidPaymentMethodForFleetOrder()}
              {getfleetUnFleetUserSection()}
              {getLockUnlockUserSection()}
              {getTokenActionsSection()}
            </div>
          }
        </div>
      </div>
    );
  };

  const getAssignRoleSection = () => {
    const getOptions = () => roles.map((role, i) => {
      return <option key={i} value={role}>{role}</option>;
    });

    return (
      <div className='assign-role-section'>
        <div className={'label'}>Assign Role</div>
        <div className={'d-flex align-items-end'}>
          <select
            className='form-control'
            value={selectedRole}
            disabled={assignLoading}
            onChange={e => setSelectedRole(e.target.value)}
          >
            <option hidden={!!selectedRole} value={'default'}>Select a role</option>
            {roles.length && getOptions()}
          </select>
          <ActionButton
            text='Assign'
            active={!assignLoading}
            isLoading={false}
            customClass={'btn-primary'}
            onClick={assingRolesToUser}
          />
        </div>
      </div>
    );
  };

  const getIsDeletedSection = () => {
    if (user?.deleted) {
      return (
        <div className='d-flex'>
          <h3 style={{ color: 'red', marginRight: '20px' }}>DELETED</h3>
          <ActionButton
            text='Re-Activate User'
            active={!reActivateLoading}
            isLoading={reActivateLoading}
            customClass={'btn-primary ml-2'}
            onClick={reactivateUserFn}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const getHeader = () => (
    <div className='header'>
      <BackButton />
      <div className={'d-flex justify-content-between align-items-end'}>
        {getAssignRoleSection()}
        {getIsDeletedSection()}
        {/* {loggedInUserRoles.includes('admin') && getLockUnlockUserSection()} */}
      </div>
    </div>
  );

  if (loading) return <Loading />;

  return (
    <div className='user-details-container'>
      {getHeader()}
      {getUserBasicInfoSection()}
      <UserPrimeSubscription 
        onInvitationSentSuccess={fetchUserDetails} 
        user={user}
      />
      {getUserRolesSection()}
      {getUserOrders()}
      {getUserRestaurantsSection()}
      {getUserFleetLocationSection()}
    </div>
  );
};

const UserDetailsWrapper = connect(mapStateToProps)(UserDetails);
export { UserDetailsWrapper as UserDetails };
