import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { showError, showSuccess } from 'utils/notifications';
import qs from 'query-string';
import { ACTION_ICON_TYPES, formatDate, getPageLimit, getTextValue, updatePageLimit } from 'utils';
import TableLink from 'TableLink';
import CollectionFilter from './collection-filter';
import { deleteDishCollection, getDishCollections } from 'services/api/dishCollection';
import ActionButton from 'components/ActionButton/ActionButton';
import AddDishCollectionModal from '../../components/AddDishCollectionModal/index';
import ActionIcon from 'components/ActionIcon';

let filters: { [p: string]: any; page: number } = {
  page: 0,
};

const DishCollection = props => {
  const [colletions, setColletions] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const [addModal, showAddModal] = useState(false);

  const fetchDishesColletion = async (filters: Record<string, any>) => {
    setLoading(true);
    try {
      const { page, ...rest } = filters;
      const response = await getDishCollections({
        page,
        limit: getPageLimit(),
        filter: {
          ...rest,
        },
      });
      setColletions(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteDishCollectionFn = async (id: string) => {
    setLoading(true);
    try {
      await deleteDishCollection(id);
      showSuccess('Dish COllection deleted succefully.');
      fetchDishesColletion(filters);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  useEffect(() => {
    const filter = {
      ...filters,
      ...queryStringToFilter(),
    };
    fetchDishesColletion(filter);
  }, []);

  const columns = useMemo(() => [
    {
      name: 'Collection ID',
      selector: 'objectId',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/dish-collection/${row.objectId}`}
          text={row.objectId}
        />
      ),
    },
    {
      name: 'Collection Name',
      selector: 'name',
    },
    {
      name: 'No of dishes',
      selector: '',
      cell: row => (row?.items?.length || 0),
      width: '150px',
    },
    {
      name: 'Vertical Type',
      selector: 'verticalType',
      cell: row => row?.verticalType ?? '-',
    },
    {
      name: 'Featured',
      selector: 'featured',
      cell: (row) => getTextValue(row.featured),
      width: '80px',
    },
    {
      name: 'Active',
      selector: 'active',
      cell: (row) => getTextValue(row.active),
      width: '80px',
    },
    {
      name: 'Created by',
      selector: 'user.full_name',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/users/${row.user.objectId}`}
          text={row.user.full_name}
          openInNewTab={true}
        />
      ),
    },
    {
      name: 'Created at',
      selector: 'createdAt',
      sortable: true,
      format: (row: any) => formatDate(row.createdAt, 'MM/DD/YYYY HH:mm'),
    },
    {
      name: '',
      selector: '',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <ActionIcon
          actionType={ACTION_ICON_TYPES.DELETE}
          onClick={() => deleteDishCollectionFn(row.objectId)}
        />
      ),
    },
  ], []);

  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchDishesColletion(filters);
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const onFilterChanged = (updatedFilters: Record<string, any>) => {
    filters = { ...updatedFilters, page: 0 };
    updateQueryString();
    fetchDishesColletion(filters);
  };

  const onSort = (column: Record<string, any>, sortDirection: string) => {
    const sort = filters.sort || {};
    filters = {
      ...filters,
      sort: {
        ...sort,
        [column.selector]: sortDirection,
      },
    };
    fetchDishesColletion(filters);
  };

  return (
    <div>
      {loading && <Loading />}
      {addModal && (
        <AddDishCollectionModal
          toggleModal={() => { showAddModal(!addModal) }}
          onSuccess={() => fetchDishesColletion(filters)}
        />
      )}
      <CollectionFilter
        {...props}
        onFilterSubmit={onFilterChanged}
      />
      <DataTable
        title={'Dish Collections'}
        actions={(
          <ActionButton
            text={`Create Collection +`}
            active={true}
            isLoading={false}
            customClass={'btn-primary mb-2'}
            onClick={() => showAddModal(true)}
          />
        )}
        keyField="objectId"
        columns={columns}
        data={colletions.items}
        highlightOnHover={true}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationDefaultPage={(filters.page || 0) + 1}
        paginationTotalRows={colletions.total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onSort={onSort}
      />
    </div>
  );
};

export { DishCollection };