import { cloudFunction } from '../parse';

export const fetchAllSubscriptionsForAdmin = async (params) => {
  return cloudFunction('fetchAllSubscriptionsForAdmin', { ...params, isAdmin: true });
};

export const updateSubscription = async (params) => {
  return cloudFunction('updateSubscription', { ...params, isAdmin: true });
};

export const createSubscription = async (params) => {
  return cloudFunction('createSubscription', { ...params, isAdmin: true });
};

export const getSubscriptionDetail = async (id: string) => {
  return cloudFunction('getSubscriptionDetail', { id, isAdmin: true });
};

export const sendPrimeSubscriptionInvitationToUsers = async (userIds: Record<string, any>) => {
  return cloudFunction('sendPrimeSubscriptionInvitationToUsers', { userIds });
};

export const fetchUserPrimeSubscriptionDetail = async (userId: string): Promise<any> => {
  return cloudFunction('fetchUserPrimeSubscriptionDetail', { userId });
};

export const cancelPrimeSubscription = async (data: object): Promise<any> => {
  return cloudFunction('cancelPrimeSubscription', data);
};