import { cloudFunction } from 'services/parse';

export const fetchAllRoutes = (data: Record<string, any>) => {
  return cloudFunction('fetchAllRoutes', data);
};

export const fetchRouteDetail = (id: string) => {
  return cloudFunction('fetchRouteDetail', { id });
};

export const assignRoute = (id: string, driverId: string) => {
  return cloudFunction('assignRoute', { id, driverId });
};

export const removeDriverFromRoute = (id: string) => {
  return cloudFunction('removeDriverFromRoute', { id });
};

export const fetchRouteUpdates = (id: string) => {
  return cloudFunction('fetchRouteUpdates', { id });
};

export const explodeRoute = (id: string) => {
  return cloudFunction('explodeRoute', { id });
};