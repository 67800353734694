import React, { useEffect, useState } from 'react';
import { fetchAdminOrders } from 'services/api/order';
import { fromStripeAmount, getPageLimit, updatePageLimit } from 'utils';
import moment from 'moment';
import { showError } from 'utils/notifications';
import DataTable from 'react-data-table-component';
import TableLink from 'TableLink';
import { tableStyles } from 'css/tableStyles';
import qs from 'query-string';
import { Loading } from 'components/loading/Loading';
import { OrdersFilter } from 'pages/orders/orders-filter';

const columns = [
  {
    name: 'Order ID',
    selector: 'objectId',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/orders/${row.objectId}`}
        text={row.objectId}
        openInNewTab={false}
      />
    ),
  },
  {
    name: 'Order Number',
    selector: 'orderNumber',
    sortable: false,
  },
  {
    name: 'Client',
    selector: 'clientName',
    sortable: false,
  },
  {
    name: 'Driver',
    selector: 'driverName',
    sortable: false,
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: false,
    format: row => `$${row.total}`,
  },
  {
    name: 'Date Time',
    selector: 'createdAt',
    sortable: true,
  },
  {
    name: 'Refunded',
    selector: 'refunded',
    sortable: false,
    format: row => `$${fromStripeAmount(row.refundAmount)}`,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
  },
];

let sort = {};

const RestaurantOrders = props => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<Record<string, any>>({});
  const [selectedTab, setSelectedTab] = useState<number>();
  const { restaurantId } = props;

  let filters: { [p: string]: any; page: number } = {
    page: 0,
    endDate: moment().toISOString(),
    startDate: moment().subtract(1, 'month').toISOString(),
  };
  
  const fetchAdminOrdersFn = async (filters: Record<string, any>) => {
    setLoading(true);
    try {
      const response = await fetchAdminOrders({
        filter: { ...filters, restaurantId },
        limit: getPageLimit(),
        sort,
        page : filters.page,
      });
      setOrders(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  useEffect(() => {
    const { selectedTab, ...rest } = queryStringToFilter();
    if (selectedTab && typeof selectedTab === 'number') setSelectedTab(selectedTab);
    props.history.push({
      pathname: props.location.pathname,
      search: qs.stringify({ ...rest, selectedTab }),
    });
    fetchAdminOrdersFn(rest);
  }, []);

  const getQueryString = () => {
    return qs.stringify({ ...filters, selectedTab });
  };

  const updateQueryString = () => {
    const qs = getQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onFilterChanged = (updatedFilters: Record<string, any>) => {
    filters = {
      ...updatedFilters,
      page: 0,
    };
    updateQueryString();
    fetchAdminOrdersFn(filters);
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchAdminOrdersFn(filters);
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const onSort = (column: Record<string, any>, sortDirection: string) => {
    sort = {
      ...sort,
      [column.selector]: sortDirection,
    };
    fetchAdminOrdersFn(filters);
  };

  return (
    <div className={'pt-3'}>
      <OrdersFilter
        {...props}
        showOrderNumberFilter
        showUserNameFilter
        showUserEmailFilter
        showUserPhoneFilter
        showDriverNameFilter
        onFilterSubmit={onFilterChanged}
      />
      {loading && <Loading />}
      <DataTable
        title="Orders"
        keyField="objectId"
        columns={columns}
        data={orders.items}
        highlightOnHover={true}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationDefaultPage={(filters.page || 0) + 1}
        paginationTotalRows={orders.total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onSort={onSort}
      />
    </div>
  );
};

export default RestaurantOrders;