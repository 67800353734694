import React, { useState } from 'react';
import './lead-detail.scss';
import { Loading } from 'components/loading/Loading';
import { Message } from 'components/message/Message';
import { Documents } from './Documents';
import { convertToDriver, fetchLeadDetail } from 'services/api/lead';
import { useMount } from 'react-use';
import { ILead } from 'interfaces/lead';
import { UserDetail } from './UserDetail';
import { LeadInformation } from './LeadInformation';
import ActivityLog from './ActivityLog';
import Notes from './Notes';
import AgentPicker from 'components/AgentPicker';
import { showError, showSuccess } from 'utils/notifications';
import BackButton from 'components/BackButton';
import { AVAILABLE_DOCUMENT_LIST } from 'utils';


const LeadDetail = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [lead, setLead] = useState<ILead.ILeadDetail | undefined>();
  const { objectId } = props.match.params;

  const fetchLeadDetailFn = async () => {
    const leadDetail = await fetchLeadDetail(objectId);
    setLead(leadDetail);
    setLoading(false);
  };

  useMount(async () => {
    fetchLeadDetailFn();
  });

  const convertUserToDriver = async () => {
    setLoading(true);
    try {
      const user: ILead.IUserDetail = await convertToDriver(objectId);
      showSuccess('Lead converted to Driver');
      if (lead) {
        lead.lead.user = user;
        setLead(lead);
      }
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const getDetailsSection = () => {
    if (lead && lead.lead) {
      const information = <LeadInformation loading={loading} lead={lead.lead} user={lead.lead.user} convertToDriver={convertUserToDriver}/>;

      if (lead.lead.user) {
        return <>
          {information}
          <UserDetail loading={loading} user={lead.lead.user}  />
          <Documents documents={lead.documents} userId={lead.lead.user.objectId} onDocumentChange={fetchLeadDetailFn} AVAILABLE_DOCUMENT_LIST={AVAILABLE_DOCUMENT_LIST}/>
        </>;
      } else {
        return <>
          {information}
          {getMessageBlock()}
        </>;
      }
    } else {
      return <></>;
    }
  };

  const getMessageBlock = () => {
    return (
      <div className="no-user">
        <div className="card mt-4">
          <div className="card-body d-flex justify-content-center align-items-center">
            <Message message="No User Detail" />
          </div>
        </div>
      </div>
    );
  };

  const getActivitiesAndNotes = () => {
    return (
      <>
        <ActivityLog
          leadId={lead?.lead.objectId || ''}
        />
        <Notes
          leadId={lead?.lead.objectId || ''}
          notes={lead?.lead.notes || []}
          refetchLeadDetails={fetchLeadDetailFn}
        />
      </>
    );
  };

  const getCompleteLeadDetail = () => {
    return (
      <div className='row'>
        <div className='details-section'>{getDetailsSection()}</div>
        <div className='activities-notes-section'>{getActivitiesAndNotes()}</div>
      </div>
    );
  };

  return (
    <div className="container">
      <div className='header-container'>
        <div className='row'>
          <BackButton />
          <AgentPicker
            leadId={lead?.lead.objectId || ''}
            currentAgentId={lead?.lead.agent?.objectId}
          />
        </div>
      </div>
      {
        loading ? <Loading /> : getCompleteLeadDetail()
      }
    </div>
  );

};
export { LeadDetail };
