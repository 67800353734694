import React from 'react';
import { convertFlatArrayToFlatObject, convertNestedObjectsToFlatArrayForVariantSpecs } from 'utils';

const VariantsHierarchy = ({ dish }) => {

  const getLeafVariantsWithParents = () => {
    const specsFlatArray = convertNestedObjectsToFlatArrayForVariantSpecs(dish?.dishDescriptionTree);
    const leafVariants = specsFlatArray?.filter((item) => { return !item?.specs?.length });
    const variantsObj = convertFlatArrayToFlatObject(specsFlatArray, 'id');
    const leafVariantsWithParents = [] as any;
  leafVariants?.map((item) => {
    let currentLeafVariantId = item?.id;
    let currentVariant = item;
    const parentVariants = [] as any;
    do {
    parentVariants?.push(currentVariant);
    currentLeafVariantId = variantsObj[currentLeafVariantId]?.parent;
    currentVariant = variantsObj[currentLeafVariantId];
    } while (currentLeafVariantId);
   leafVariantsWithParents?.push(parentVariants?.reverse());
  });
  return leafVariantsWithParents;
  };

  const leafVariantsWithParents = getLeafVariantsWithParents();

  const getSubVariant = ({ item, i }) => {
    let totalPrice = 0;
    return (<div className='d-flex justify-content-between'>
      <div>
        {i + 1}. 
        {item?.map((item2, i2) => {
          totalPrice = totalPrice + item2?.price;
          return (
            <span>{item2?.name}(${item2?.price}) {i2 < item?.length - 1 ? '->' : ''} </span>
          );
        })}
      </div>
      <strong>${totalPrice}</strong>
    </div>);
  };

  const getSubVariants = () => {
    if (!leafVariantsWithParents?.length) {
      return '-';
    }
    return leafVariantsWithParents?.map((item, i) => {
      return getSubVariant({ item, i });
    });
  };

  return (
    <div>
      {getSubVariants()}
    </div>
  );
};

export default VariantsHierarchy;

