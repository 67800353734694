import RenderInfo from 'components/RenderInfo';
import React, { useMemo } from 'react';
import { metersToMile, secondsToMinute } from 'utils';
import { tableStyles } from 'css/tableStyles';
import DataTable from 'react-data-table-component';
import ActionButton from 'components/ActionButton/ActionButton';


const BasicDetails = ({ details, syncLoading, syncOrderFn }) => {
  const {
    _id,
    merchantId,
    weight,
    shopifyOrderId,
    weightUnit,
    customerEmail,
    orderNumber,
    fulfillmentStatus,
    note,
    lineItems,
    address,
    createdAt,
    updatedAt,
    uvaErrorResponse,
    uvaOrderId,
    uvaOrderResponse,
  } = details;

  const columns = useMemo(() => [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Title',
      selector: 'title',
    },
    {
      name: 'Fulfillable Qty',
      selector: 'fulfillableQuantity',
    },
    {
      name: 'Fulfillment Status',
      selector: 'fulfillmentStatus',
    },
    {
      name: 'Grams',
      selector: 'grams',
      format: (row) => `${row.grams} gm`,
    },
    {
      name: 'Price',
      selector: 'price',
      format: (row) => `$ ${row.price}`,
    },
    {
      name: 'Quantity',
      selector: 'quantity',
    },
    {
      name: 'Product Id',
      selector: 'productId',
    },
  ], []);

  return (
    <div className="container mt-3">
      <div className='d-flex justify-content-between'>
        <div>
          <strong>Basic Details</strong>
        </div>
        <div>
          <ActionButton
            active
            isLoading={syncLoading}
            onClick={syncOrderFn}
            text={<div><i className='fa fa-refresh' aria-hidden="false"></i> Sync</div>}
          />
        </div>
      </div>
      <div className="row mt-3 p-3">
        <div className="col">
          <RenderInfo heading="ID" value={_id} />
          <RenderInfo heading="Merchant ID" value={merchantId?.shopName} link={`/shopify/merchants/${merchantId?._id}`}/>
          <RenderInfo heading="Weight" value={`${weight} ${weightUnit}`} />
          <RenderInfo heading="Shopify Order ID" value={shopifyOrderId} />
          <RenderInfo heading="Customer Email" value={customerEmail} />
          <RenderInfo heading="Order Number" value={orderNumber} />
          <RenderInfo heading="Fulfillment Status" value={fulfillmentStatus} />
          <RenderInfo heading="Note" value={note} />
        </div>
        <div className='col'>
          <strong>Address</strong>
          <div className='p-3'>
            <div>{address?.name}</div>
            <div>{address?.address1}</div>
            <div>{address?.city}, {address?.province}, {address?.country}, {address?.zip}</div>
            <div>{address?.phone}</div>
          </div>
          <RenderInfo heading="Created At" value={createdAt} type="date"/>
          <RenderInfo heading="Updated At" value={updatedAt} type="date" />
          <RenderInfo heading="UVA Error Response" value={uvaErrorResponse} />
          <RenderInfo heading="UVA Order ID" value={uvaOrderId} link={`/orders/${uvaOrderId}`}/>
        </div>
      </div>
      <strong>UVA Order Response</strong>
      <div className="row mt-3 p-3">
        <div className="col">
          <RenderInfo heading="Object ID" value={uvaOrderResponse?.objectId} link={`/orders/${uvaOrderResponse?.objectId}`} />
          <RenderInfo heading="Status" value={uvaOrderResponse?.status} />
          <RenderInfo heading="Order Number" value={uvaOrderResponse?.orderNumber} />
          <RenderInfo heading="Delivery Type" value={uvaOrderResponse?.deliveryType} />
        </div>
        <div className="col">
          <RenderInfo heading="Distance" value={uvaOrderResponse?.distance ? metersToMile(uvaOrderResponse?.distance) + ' miles' : 'N/A'} />
          <RenderInfo heading="Duration" value={uvaOrderResponse?.duration ? secondsToMinute(uvaOrderResponse?.duration) + ' minutes' : 'N/A'} />
          <RenderInfo heading="Delivery Charges" value={uvaOrderResponse?.deliveryCharges} type="currency"/>
          <RenderInfo heading="Delivery Taxes" value={uvaOrderResponse?.deliveryTaxes} type="currency" />
        </div>
      </div>
      <strong>Order Items</strong>
      <div className="row mt-3 p-3">
        <DataTable
          keyField="productId"
          columns={columns}
          noHeader={true}
          data={lineItems}
          highlightOnHover={true}
          customStyles={tableStyles}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default BasicDetails;