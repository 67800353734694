import React, { useRef, useState } from 'react';
import './file-upload.scss';

const FileUploaderWithPDF = ({ onSelectFile, onError }: { onSelectFile: any; onError: Function}) => {
  const profilePictureInputRef= useRef() as any;
    
  const onFileChange = event => {
    const fileReader: any = new window.FileReader();
    const file = event.target.files[0];
  
    if (file.type === 'application/pdf' || file.type.startsWith('image/')) {
      fileReader.onload = fileLoad => {
        const { result } = fileLoad.target;
        onSelectFile({ url: result }, file);
      };

      fileReader.onerror = error => {
        onError(error);
      };

      fileReader.readAsDataURL(file);
      onError('');
    } else {
      onError(new Error('Unsupported file type, only pdf and image allowed.'));
    }
  };

  return (
    <>
      <div className=""
        onClick={() => { profilePictureInputRef?.current?.click() }} 
      >
        <input 
          type="file"
          name="myImage"
          onChange={onFileChange}
          style={{ display: 'none' }}
          ref={profilePictureInputRef}
        />
        <div className="image-uploader d-flex justify-content-center align-items-center">
          +
        </div>
      </div>
    </>
  );
};

export default FileUploaderWithPDF;