import React from 'react';
import styles from './index.module.scss';

const Header = (props) => {
  const { title, onClose } = props;
  return (
    <div className="d-flex justify-content-between align-items-center">
      <span className={styles['heading']}>{title}</span>
      <img width="20" height="20" onClick={onClose} className={`${styles['close-icon']} adrs__sbcn__cn__img cls`} src="https://ik.imagekit.io/hnlpilvl5t/icons/cancel_G4ORsrTrv.jpg" />
    </div>
  );
};

const RenderAddress = (props) => {
  const { address, onClick = () => {return}, onClickCurrentLocation } = props;
  return(
    <div className={styles['address-container']}>
      <div
        onClick={onClick}
        style={{ marginRight: onClickCurrentLocation ? 40 : 0 }}
        className={`${styles['locality-name-container']} d-flex align-items-center`}
      >
        <i className="fa fa-location-arrow mr-2"></i>
        <div className={styles['locality-name']}>{address}</div>
        <div className={styles['change-button']}>CHANGE</div>
      </div>
      {onClickCurrentLocation && <div
        style={{ position: 'absolute', right: 0, top: 2 }}
        className="small cursor-pointer font-weight-bold"
        onClick={onClickCurrentLocation}
      >
        <img src='https://ik.imagekit.io/hnlpilvl5t/icons/gps__1__XYXbC3ZvF_uMBB6LJxs.png' className={styles['current-location-image']} />
      </div>}
    </div>
  );
};

const CancelButton = (props) => {
  const { onCancel } = props;
  return(
    <div onClick={onCancel}><span className={`${styles['cancel-button']} mr-4 font-weight-bold`}>Cancel</span></div>
  );
};

export default Header;
export { RenderAddress, CancelButton };