import CopyToClipBoard from 'components/CopyToClipBoard';
import RenderInfo from 'components/RenderInfo';
import React from 'react';
import TableLink from 'TableLink';
import { STRIPE_DASHBOARD_LINK } from 'utils';
import styles from './index.module.scss';

const BasicDetails = (props) => {
  const { details: { name = '', enName = '', description = '', enDescription = '', productType = '', objectId = '', active = false, stripeProductId = '', image: { url: imageUrl = '' } = {}} = {}} = props;

  const stripeDashboardLink = STRIPE_DASHBOARD_LINK + '/products/' + stripeProductId;
  return <div className={styles['details-section']}>
    <img src={imageUrl} className={styles['stripe-product-image']} />
    <div className={styles['info-container']}>
      <div className={styles['info']}>
        <RenderInfo heading="ID" value={objectId} />
        <RenderInfo heading="Name" value={name} />
        <RenderInfo heading="Description" value={description} />
        <RenderInfo heading="Type" value={productType} />
        <div>
          <div className={styles['label']}>Stripe Dashboard</div>
          <div className='d-flex align-items-center'>
            <TableLink
              linkTo={stripeDashboardLink}
              text={stripeDashboardLink}
              customClass={'detail'}
              openInNewTab
            />
            <CopyToClipBoard
              copyText={stripeDashboardLink}
            />
          </div>
        </div>
      </div>
      <div className={styles['info']}>
        <RenderInfo heading="Active" value={active} type='boolean' />
        <RenderInfo heading="English Name" value={enName} />
        <RenderInfo heading="English Description" value={enDescription} />
      </div>
    </div>
  </div>;
};

export default BasicDetails;