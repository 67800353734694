import React, { useState } from 'react';
import { Loading } from 'components/loading/Loading';
import { sendNotification } from 'services/api';
import { Error } from 'components/message/Error';
import { Success } from 'components/message/Success';

const SendNotifications = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const [to, setTo] = useState('all');
  const [message, setMessage] = useState('');
  const [sms, setSms] = useState('sms');
  const [isTestMode, setIsTestMode] = useState(false);

  const send = async event => {
    event.preventDefault();
    try {
      setLoading(true);
      setSuccess(false);
      const jobId = await sendNotification(to, message, sms === 'sms', isTestMode);
      reset();
      setLoading(false);
      props.history.push(`/job-detail/${jobId}`);
    } catch (e) {
      setError(e);
      setLoading(false);
      setSuccess(false);
    }
  };

  const reset = () => {
    setTo('all');
    setMessage('');
    setMessage('sms');
    setIsTestMode(false);
  };

  const isFormValid = () => {
    return to && message;
  };

  return (
    <div className="container">
      <form className="form-horizontal" onSubmit={event => send(event)} noValidate>
        <div className="form-group">
          <label htmlFor="to">To</label>
          <select name="To" id="to" className="form-control" value={to} required onChange = {e => setTo(e.target.value)}>
            <option value="all">All</option>
            <option value="offline">Off-line</option>
            <option value="online">Online</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea className="form-control" name="message" id="message" required value={message} rows={5} onChange = {e => setMessage(e.target.value)}></textarea>
        </div>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" id="sms" value="sms" name="notificationType" checked={sms === 'sms'} onChange = {() => setSms('sms')}/>
          <label className="form-check-label" htmlFor="sms">SMS</label>
        </div>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" id="push" value="push" name="notificationType" checked={sms === 'push'} onChange = {() => setSms('push')}/>
          <label className="form-check-label" htmlFor="push">Push</label>
        </div>
        <div className="form-group form-check">
          <input type="checkbox" id="isTestMode" className="form-check-input" checked={isTestMode} onChange = {e => setIsTestMode(e.target.checked)}/>
          <label htmlFor="isTestMode" className="form-check-label"> Is Test Mode</label>
        </div>
        <div className="row">
          { error && <Error message={error}/> }
          { success && <Success message="Successfully sent the notification!"/> }
        </div>
        <button className="btn btn-primary send-notification" onClick={event => send(event)} disabled={!isFormValid()}>
            Send Notification
        </button>
        { loading && <Loading /> }
      </form>
    </div>
  );
};

export { SendNotifications };
