import { Loading } from 'components/loading/Loading';
import TabView from 'components/TabView';
import React, { useEffect, useState } from 'react';
import { fetchRestaurantDetailsByAdmin } from 'services/api/restaurant';
import { RESTAURANT_DETAILS_TYPE } from 'utils';
import { showError } from 'utils/notifications';
import RestaurantCategories from './RestaurantCategories';
import RestaurantInfo from './RestaurantInfo';
import RestaurantMenu from './RestaurantMenu';
import RestaurantOrders from './RestaurantOrders';
import './index.scss';
import BackButton from 'components/BackButton';
import { RestaurantBlacklistedDrivers } from './RestaurantBlacklistedDrivers';

const tabs = [
  {
    key: RESTAURANT_DETAILS_TYPE.OVERVIEW.key,
    label: RESTAURANT_DETAILS_TYPE.OVERVIEW.label,
  },
  {
    key: RESTAURANT_DETAILS_TYPE.MENU.key,
    label: RESTAURANT_DETAILS_TYPE.MENU.label,
  },
  {
    key: RESTAURANT_DETAILS_TYPE.CATEGORIES.key,
    label: RESTAURANT_DETAILS_TYPE.CATEGORIES.label,
  },
  {
    key: RESTAURANT_DETAILS_TYPE.ORDERS.key,
    label: RESTAURANT_DETAILS_TYPE.ORDERS.label,
  },
  {
    key: RESTAURANT_DETAILS_TYPE.DRIVERS.key,
    label: RESTAURANT_DETAILS_TYPE.DRIVERS.label,
  },
];

const RestaurantDetails = props => {
  const { restaurantId } = props.match.params;
  const [loading, setLoading] = useState<boolean>(true);
  const [restaurantDetails, setRestaurantDetails] = useState<Record<string, any>>({});
  const [categoriesOrderChanged, setCategoriesOrderChanged] = useState<boolean>(false);

  const fetchRestaurantDetails = async () => {
    try {
      const response = await fetchRestaurantDetailsByAdmin(restaurantId);
      setRestaurantDetails(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRestaurantDetails();
  }, []);

  const setCategories = (reOrderedCategories: string[]) => {
    setRestaurantDetails({
      ...restaurantDetails,
      categories: reOrderedCategories,
    });
  };

  const getPanels = () => {
    const panelsArr: any = [
      {
        key: RESTAURANT_DETAILS_TYPE.OVERVIEW.key,
        Component: RestaurantInfo,
        props: {
          ...props,
          restaurantDetails: {
            ...restaurantDetails,
            restaurantId,
          },
          refreshDetails: fetchRestaurantDetails,
        },
      },
      {
        key: RESTAURANT_DETAILS_TYPE.MENU.key,
        Component: RestaurantMenu,
        props: {
          ...props,
          restaurantId,
        },
      },
      {
        key: RESTAURANT_DETAILS_TYPE.CATEGORIES.key,
        Component: RestaurantCategories,
        props: {
          restaurantId,
          setCategories,
          categories: restaurantDetails.categories || [],
          categoriesOrderChanged,
          onEditSuccess: fetchRestaurantDetails,
          setCategoriesOrderChanged,
        },
      },
      {
        key: RESTAURANT_DETAILS_TYPE.ORDERS.key,
        Component: RestaurantOrders,
        props: {
          ...props,
          restaurantId,
        },
      },
      {
        key: RESTAURANT_DETAILS_TYPE.DRIVERS.key,
        Component: RestaurantBlacklistedDrivers,
        props: {
          ...props,
          restaurantId,
        },
      },
    ];

    return panelsArr;
  };

  if (loading) return <Loading />;

  return (
    <div className={'restaurant-details-main-container'}>
      <div className={'header'}>
        <div>{restaurantDetails.restaurant_name}</div>
        <BackButton />
      </div>
      <div className={'restaurant-details-tabs-container'}>
        <TabView
          {...props}
          tabsList={tabs}
          tabsPanelList={getPanels()}
        />
      </div>
    </div>
  );
};

export { RestaurantDetails };