import _ from 'lodash';
import { cloudFunction } from '../parse';
import { stringifyAndParseObj } from '../../utils/util';

export const fetchRestaurantForAutoComplete = () => {
  return cloudFunction('restaurantAutocompleteList', {});
};

export const copyRestaurantMenu = data => {
  return cloudFunction('copyRestaurantMenu', data);
};

export const whyAmIHidden = (restaurantId: string) => {
  return cloudFunction('whyAmIHidden', { restaurantId });
};

export const fetchRestaurantsByAdmin = (data: Record<string, any>) => {
  return cloudFunction('fetchRestaurantsByAdmin', data);
};

export const fetchRestaurantDetailsByAdmin = async (id: string) => {
  let response = await cloudFunction('fetchRestaurantDetailsByAdmin', { id });
  response = stringifyAndParseObj(response);
  if (response.schedule) {
    response = {
      ...response,
      schedule: JSON.parse(response.schedule),
    };
  }
  return response;
};

export const getDishesForRestaurantMenuV2 = async (data: Record<string, any>) => {
  let response: Record<string, any> = await cloudFunction('getDishesForRestaurantMenuV2', data);
  response = {
    ...response,
    items: response.items.dishes.map(dish => dish.toJSON()),
  };
  return response;
};

// export const getDishesV2 = async (data: Record<string, any>) => {
//   let response: Record<string, any> = await cloudFunction('getDishesV2', data);
//   // response = {
//   //   ...response,
//   //   items: response.items.dishes.map(dish => dish.toJSON()),
//   // };
//   // return response;
// };

export const setRestaurantOffline = (id: string) => {
  return cloudFunction('setRestaurantOffline', { id });
};

export const setRestaurantOnline = (id: string) => {
  return cloudFunction('setRestaurantOnline', { id });
};

export const sortRestaurantCategories = (data: Record<string, any>) => {
  return cloudFunction('sortRestaurantCategories', data);
};

export const updateRestaurant = (data: Record<string, any>) => {
  return cloudFunction('updateRestaurant', { restaurantId: data.objectId, data });
};

export const addCategory = (data: Record<string, any>) => {
  return cloudFunction('addCategory', data);
};

export const deleteCategory = (data: Record<string, any>) => {
  return cloudFunction('deleteCategory', data);
};

export const editCategory = (data: Record<string, any>) => {
  return cloudFunction('editCategory', data);
};

export const toggleRestaurantActiveStatus = (id: string) => {
  return cloudFunction('toggleRestaurantActiveStatus', { id });
};

export const toggleAcceptDeferred = (id: string) => {
  return cloudFunction('toggleAcceptDeferred', { id });
};

export const optInAutoLogOff = (id: string) => {
  return cloudFunction('optInAutoLogOff', { id });
};

export const optOutAutoLogOff = (id: string) => {
  return cloudFunction('optOutAutoLogOff', { id });
};

export const optInAutoLogIn = (id: string) => {
  return cloudFunction('optInAutoLogIn', { id });
};

export const optOutAutoLogIn = (id: string) => {
  return cloudFunction('optOutAutoLogIn', { id });
};

export const allowAPIAccess = (userId: string) => {
  return cloudFunction('allowAPIAccess', { userId });
};

export const removeAPIAccess = (userId: string) => {
  return cloudFunction('disallowAPIAccess', { userId });
};

export const generateToken = (userId: string) => {
  return cloudFunction('generateToken', { userId });
};

export const excludeOrderFromAvgElapsedTime = (data: Record<string, any>) => {
  return cloudFunction('excludeOrderFromAvgElapsedTime', data);
};

export const excludeOrderFromAvgCookingTime = (data: Record<string, any>) => {
  return cloudFunction('excludeOrderFromAvgCookingTime', data);
};

export const searchRestaurants = async (searchTerm: string, filter: Record<string, any>) => {
  return cloudFunction('searchRestaurants', { searchTerm, filter });
};

export const markCategoryAvailable = (category: string) => {
  return cloudFunction('markCategoryAvailable', { category });
};

export const markCategoryUnavailable = (category: string) => {
  return cloudFunction('markCategoryUnavailable', { category });
};

export const fetchRestaurantOnlineTime = async (data: Record<string, any>) => {
  let response = await cloudFunction('fetchRestaurantOnlineTime', data);
  response = stringifyAndParseObj(response);
  return response;
};

export const createRestaurant = async (data: object): Promise<any> => {
  const response = await cloudFunction('createRestaurant', data); 
  return response;
};

export const exportRestaurantMenu = async (data: object): Promise<any> => {
  const response = await cloudFunction('exportRestaurantMenu', data); 
  return response;
};

export const deleteRestaurantNote = async ({ noteId, id }: { noteId: string; id: string }) => {
  return await cloudFunction('deleteRestaurantNote', { noteId, restaurantId: id });
};

export const addRestaurantNote = async ({ id, note }) => {
  return await cloudFunction('addRestaurantNote', {  restaurantId: id, note });
};

export const editRestaurantNote = async ({ id, note, noteId }: { id: string; note: string; noteId: string }) => {
  return await cloudFunction('editRestaurantNote', {  restaurantId: id, note, noteId });
};