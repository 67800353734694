import React, { useState } from 'react';
import { showError } from 'utils/notifications';
import './index.scss';

const Tag = props => {
  const { tagName, onRemove } = props;
  const [active, setActive] = useState<boolean>(true);
  
  const handleRemove = async () => {
    setActive(false);
    try {
      await onRemove();
    } catch (e) {
      showError(e);
    } finally {
      setActive(true);
    }
  };
  
  return (
    <div className={`tag ${!active ? 'inactive-tag' : ''}`}>
      <div className='tag-name'>{tagName}</div>
      <div className={`remove-button ${!active ? 'inactive-tag' : ''}`} onClick={handleRemove}>
        <i className='fa fa-times' />
      </div>
    </div>
  );
};

export default Tag;