import React, {  } from 'react';
import './index.scss';

const AddDishVariantModalHeader = props => {
  const {
    toggleModal,
    isAddFormVisible,
    newVariantDetails = {},
    parentVariantDetails: { name: parentName = '', objectId: parentId = '' } = {},
  } = props;

  const getHeading = () => {
    if(isAddFormVisible && newVariantDetails?.objectId){
      return 'Edit Variant';
    }
    if(isAddFormVisible){
      return 'Add Variant';
    }
    return `${parentName}(${parentId})`;
  };
  return (
    <div className={'add-variant-modal-header'}>
      <div className={'heading'}>{getHeading()}</div>
      <div className={'close-modal'}>
        <i className={'fa fa-times'} onClick={toggleModal} />
      </div>
    </div>
     
  );
};

export default AddDishVariantModalHeader;