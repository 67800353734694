import React, { useState } from 'react';
import './index.scss';
import ActionButton from 'components/ActionButton/ActionButton';
import { IAddRestaurant } from 'interfaces/addRestaurant';
import RestaurantAutoSuggest from 'components/RestaurantAutoSuggest';

const AddRestaurant = (props: IAddRestaurant) => {
  const [restaurantId, setRestaurantId] = useState<string>('');
  const { toggleModal, onSubmit } = props;

  const addRestaurantFn = async () => {
    onSubmit(restaurantId);
  };

  return (
    <div className='add-restaurant-container'>
      <div className='add-restaurant-modal'>
        <div className='modal-header'>
          <div>Add Restaurant</div>
          <i className='fa fa-close' onClick={() => toggleModal()} />
        </div>
        <div className='modal-body'>
          <div>
            <label>Restaurant Id</label>
            <input
              value={restaurantId}
              onChange={e => setRestaurantId(e.target.value)}
            />
          </div>
          <div>
            <label>Restaurant</label>
            <RestaurantAutoSuggest
              placeholder={'Search Restaurant'}
              onSuggestionSelect={suggestion=>setRestaurantId(suggestion.id)}
            />
          </div>
        </div>
        <div className='modal-footer'>
          <ActionButton
            text='Cancel'
            active={true}
            isLoading={false}
            onClick={toggleModal}
          />
          <ActionButton
            text={'Add'}
            active={!!restaurantId}
            isLoading={false}
            customClass='btn-primary'
            onClick={addRestaurantFn}
          />
        </div>
      </div>
    </div>
  );
};

export default AddRestaurant;