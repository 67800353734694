import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ActionButton from 'components/ActionButton/ActionButton';
import { addOrderNote, editOrderNote } from 'services/api/order';
import { showError } from 'utils/notifications';

const OrderNoteModal = props => {
  const [modalNote, setModalNote] = useState('');
  const [loading, setLoading] = useState(false);
  const { show, orderId, note, onClose, onSuccess } = props;
  
  useEffect(() => {
    if (props.note && props.note.note) {
      setModalNote(props.note.note);
    }
  }, [note]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let res;
      if (!note) {
        res = await addOrderNote(orderId, modalNote);
      } else {
        res = await editOrderNote(orderId, props.note.id, modalNote);
      }
      onSuccess(res.toJSON().notes);
      onClose();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title>{!note ? 'Add ' : 'Edit '}Note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          name="notes"
          id="notes"
          cols={30}
          rows={10}
          className="w-100"
          defaultValue={modalNote}
          onChange={event => setModalNote(event.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <ActionButton
          text={'Close'}
          active={true}
          isLoading={false}
          onClick={onClose}
        />
        <ActionButton
          text={'Submit'}
          active={modalNote.length > 0}
          onClick={handleSubmit}
          isLoading={loading}
          customClass="btn-primary"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default OrderNoteModal;
