import ActionButton from 'components/ActionButton/ActionButton';
import BackButton from 'components/BackButton';
import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from 'react';
import { fetchStripeProductBySlug } from 'services/api/stripeProducts';
import { showError } from 'utils';
import BasicDetails from './basicDetails';
import ChangeStatusButton from './changeStatusButton';
import styles from './index.module.scss';
import Subscriptions from './subscriptions';

const StripeProductDetails = (props) => {
  const { slug } = props.match.params;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({}) as any;

  const fetchStripeProductBySlugFn = async () => {
    setLoading(true);
    try {
      const response = await fetchStripeProductBySlug(slug);
      setData(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStripeProductBySlugFn();
  }, []);

  const getHeader = () => (
    <div className={styles['header']}>
      <div className={styles['heading']}>Stripe Product Details</div>
      <div className={styles['header-buttons']}>
        <BackButton />
        <ActionButton
          text={'Edit'}
          active={true}
          isLoading={false}
          onClick={() => {
            props.history.push(`/stripe-products/edit/${data?.slug}`);
          }}
        />
      </div>
    </div>
  );

  const getSubscriptions = () => {
    if (data?.objectId) {
      return <Subscriptions stripeProductId={data?.objectId} />;
    }
    return null;
  };

  return (
    <div id={'main-container'} className={`${styles['main-container']}`}>
      {loading && <Loading />}
      {getHeader()}
      <ChangeStatusButton details={data} setData={setData} />
      <BasicDetails details={data} />
      {getSubscriptions()}
    </div>
  );
};

export default StripeProductDetails;