import React, { useEffect, useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import qs from 'query-string';
import moment from 'moment';
import './index.scss';
import DateRangePresetsPicker from 'components/Date/DateRangePresetsPicker';
import { getEmailArr } from 'utils';
import RestaurantAutoSuggest from 'components/RestaurantAutoSuggest';

const presets = [
  { start: moment().startOf('month'), end: moment(), text: 'This Month' },
  { start: moment().startOf('month').subtract(1, 'day').startOf('month'), end: moment().startOf('month').subtract(1, 'day'), text: 'Last Month' },
  { start: moment().startOf('week'), end: moment(), text: 'This Week' },
  { start: moment().startOf('week').subtract(1, 'day').startOf('week'), end: moment().startOf('week').subtract(1, 'day'), text: 'Last Week' },

];

const FleetOrdersFilter = props => {
  const [startDate, setStartDate] = useState<string | null>(moment().subtract(1, 'month').toString());
  const [endDate, setEndDate] = useState<string | null>(moment().toString());
  const [emails, setEmails] = useState<string>('');
  const [selectedRestaurantId, setSelectedRestaurantId] = useState<string>('');
  const [sendEmail, setSendEmail] = useState<boolean>(false);

  useEffect(() => {
    const {
      startDate,
      endDate,
      selectedRestaurantId,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });

    if (startDate) setStartDate(startDate.toString());
    if (endDate) setEndDate(endDate.toString());
    if (selectedRestaurantId) setSelectedRestaurantId(selectedRestaurantId.toString());
  }, []);

  const onFilterSubmit = () => {
    const filterData: Record<string, any> = {
      startDate,
      endDate,
      selectedRestaurantId,
      sendEmail,
      emails: getEmailArr(emails),
    };

    props.onFilterSubmit(filterData);
  };

  // const renderEmails = () => {
  //   const removeEmail = (email: string) => {
  //     let emails: string[] = emailsArr;
  //     emails = emails.filter((el) => email !== el);
  //     setEmailsArr(emails);
  //   };

  //   return emailsArr.map((email, i) => {
  //     return (
  //       <div className={'email'} key={i}>
  //         <div>{email}</div>
  //         <i className={'fa fa-times'} onClick={() => removeEmail(email)} />
  //       </div>
  //     );
  //   });
  // };

  const onDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    setStartDate(moment(startDate).toISOString());
    setEndDate(moment(endDate).toISOString());
  };

  // const addEmail = (keyCode: number) => {
  //   if (keyCode !== 13) return;
  //   if (!userEmail || !userEmail.trim().length) return;
  //   const emails: string[] = emailsArr;
  //   emails.push(userEmail.trim());
  //   setEmailsArr(emails);
  //   setUserEmail('');
  // };

  const submitDisabled = () => !(!!startDate && !!endDate && !!selectedRestaurantId);

  return (
    <form>
      <div className='fleet-filter-container'>
        <div className='input-container mb-2 mr-4'>
          <label>Date Range</label>
          <DateRangePresetsPicker
            presets={presets}
            startDate={startDate}
            endDate={endDate}
            onDatesChange={onDateSelectionChange}
          />
          <div className={'helper'}>*required</div>
        </div>
        <div className='input-container mb-2 mr-4'>
          <label>Restaurant ID</label>
          <input
            disabled={false}
            value={selectedRestaurantId}
            onChange={e => setSelectedRestaurantId(e.target.value)}
            className={'form-control'}
          />
          <div className={'helper'}>*required</div>
        </div>
        <div className='input-container mb-4 mr-4'>
          <label>Restaurant</label>
          <RestaurantAutoSuggest
            placeholder={'Search Restaurant'}
            onSuggestionSelect={suggestion=>setSelectedRestaurantId(suggestion.id)}
          />
        </div>
        <div className='input-container mb-2 mr-4'>
          <label>User Email</label>
          <input
            name={'userEmail'}
            type={'email'}
            className={'form-control'}
            placeholder={'Emails'}
            disabled={!sendEmail}
            value={emails}
            onChange={e => setEmails && setEmails(e.target.value)}
          />
          <div className='helper'>Hint: separate multiple emails using commas</div>
        </div>
        <div className='input-container mr-4'>
          <div className={'send-email'}>
            <div className='label'>Send Email</div>
            <input
              type='checkbox'
              checked={sendEmail}
              onChange={e => setSendEmail(e.target.checked)}
            />
          </div>
        </div>
      </div>
      <div className={'px-2'}>
        <ActionButton
          type={'button'}
          text={'Submit'}
          isLoading={false}
          active={!submitDisabled()}
          customClass={'btn-primary mb-2'}
          onClick={onFilterSubmit}
        />
      </div>
    </form>
  );
};

export default FleetOrdersFilter;
