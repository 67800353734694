import ReportsFilter from 'components/ReportsFilter';
import React, { useState } from 'react';
import { getDriverNotesReport } from 'services';
import { showError } from 'utils/notifications';
import moment from 'moment';
import ExportCSVButton from 'components/ExportCSVButton';
import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import DataTable from 'react-data-table-component';
import TableLink from 'TableLink';
import EmptySectionMessage from 'components/message/EmptySectionMessage';
import { DRIVER_REPORT_CSV_COLUMNS } from 'utils/driverNote';
import { DATE_FORMATS, formatDate } from 'utils';

const columns = [
  {
    name: 'Driver Name',
    selector: 'driverFullname',
    sortable: false,
  },
  {
    name: 'Driver ID',
    selector: 'driverId',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/drivers/${row.driverId}`}
        text={row.driverId}
        openInNewTab={true}
      />
    ),
  },
  {
    name: 'Note',
    selector: 'noteValue',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row=><div style={{ wordBreak:'break-word' }}>{row['noteValue']}</div>,
    width: '400px',
  },
  {
    name: 'Created At',
    selector: 'noteCreatedAt',
    sortable: false,
    format:(row)=> row['noteCreatedAt']?formatDate(row['noteCreatedAt'], DATE_FORMATS.MM_DD_YYYY_HH_MM_A):'-',
  },
  {
    name: 'Created By',
    selector: 'noteCreatedByUserFullname',
    sortable: false,
  },
  {
    name: 'Creator Id',
    selector: 'noteCreatedByUserId',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/users/${row.driverId}`}
        text={row.driverId}
        openInNewTab={true}
      />
    ),
  },
];

const DriverNoteReport = props => {
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null | string>(moment().subtract(1, 'days').toDate());
  const [endDate, setEndDate] = useState<Date | null | string>(moment().toDate());
  const [data, setData] = useState<Array<Record<string, any>>>([]);
  const [apiCalled, setAPICalled] = useState<boolean>(false);

  const fetchDriverNoteReport = async () => {
    if (!apiCalled) setAPICalled(true);
    setLoading(true);
    try {
      const response = await getDriverNotesReport({ startDate, endDate });
      setData(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };
  
  const getDataTable = () => {

    if (loading) return <Loading />;

    if (data.length) {
      const exportCSVButton = <ExportCSVButton data={data} columnsForCSV={DRIVER_REPORT_CSV_COLUMNS} fileName={'Driver Note Report'} />;
      return (
        <DataTable
          title="Driver Notes"
          keyField="objectId"
          columns={columns}
          data={data}
          customStyles={tableStyles}
          pagination={false}
          striped={true}
          actions={[exportCSVButton]}
        />
      );
    } else {
      return apiCalled ? <EmptySectionMessage message={'No records found'} /> : null;
    }
  };

  return (
    <div className='report-container'>
      <ReportsFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        showEmailInput={false}
        handleSubmit={fetchDriverNoteReport}
      />
      {getDataTable()}
    </div>
  );
};

export default DriverNoteReport;