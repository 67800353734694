import DateRangePresetsPicker from 'components/Date/DateRangePresetsPicker';
import React, { useState } from 'react';
import moment from 'moment';
import './index.scss';
import ActionButton from 'components/ActionButton/ActionButton';
import { sendTaxSummaryEmailToRestaurants } from 'services/api/taxSummary';
import { showWarning } from 'utils/notifications';
import { Success } from 'components/message/Success';
import { Error } from 'components/message/Error';
import RestaurantAutoSuggest from 'components/RestaurantAutoSuggest';

const SendTaxSummary = props => {
  const [loading, setLoading] = useState<boolean>(false);
  const [restaurantId, setRestaurantId] = useState<string>('');
  const [allRestaurants, setAllRestaurants] = useState<boolean>(false);
  const [testMode, setTestMode] = useState<boolean>(false);
  const [testEmail, setTestEmail] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null | string>(moment().subtract(1, 'month').toISOString());
  const [endDate, setEndDate] = useState<Date | null | string>(moment().toISOString());
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<string>('');

  const sendSummary = async () => {
    let payload: Record<string, any> = {
      startDate,
      endDate,
    };
    if (!allRestaurants) {
      if (restaurantId) {
        payload = { ...payload, restaurantId };
      } else {
        showWarning('Please select a single Restaurant from the dropdown or mark All Restaurants');
        return;
      }
    }
    if (testMode) {
      if (testEmail) {
        payload = { ...payload, testEmail };
      } else {
        showWarning('Please enter a Test Email or uncheck Test Mode');
        return;
      }
    }
    setLoading(true);
    try {
      const response = await sendTaxSummaryEmailToRestaurants(payload);
      if (response === 'started') setSuccess('Success');
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const onDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    startDate && setStartDate(moment(startDate).toISOString());
    endDate && setEndDate(moment(endDate).toISOString());
  };

  return (
    <div className={'send-tax-summary-main-container'}>
      <div className={'header'}>
        <div className={'heading'}>Send Tax Summary</div>
      </div>
      <div className={'body'}>
        <div className={'d-flex align-items-end flex-wrap'}>
          <div className={'mr-3'}>
            <div className={'label'}>Date Range</div>
            <DateRangePresetsPicker
              disabled={loading}
              startDate={startDate}
              endDate={endDate}
              onDatesChange={onDateSelectionChange}
            />
          </div>
          <div className={'mr-3'}>
            <div className={'label'}>Single Restaurant</div>
            <input
              value={restaurantId}
              onChange={e => setRestaurantId(e.target.value)}
              disabled={allRestaurants || loading}
              className={'form-control'}
            />
          </div>
          <div className={'mr-3'}>
            <label>Restaurant</label>
            <RestaurantAutoSuggest
              placeholder={'Search Restaurant'}
              onSuggestionSelect={suggestion=>setRestaurantId(suggestion.id)}
            />
          </div>
        </div>
        <div className={'mr-3 checkbox-container'}>
          <input
            type={'checkbox'}
            disabled={loading}
            checked={allRestaurants}
            onChange={e => setAllRestaurants(e.target.checked)}
          />
          <span>All Restaurants</span>
        </div>
        <div className={'mr-3'}>
          <div className={'label'}>Test Email</div>
          <input
            className={'form-control'}
            value={testEmail}
            disabled={!testMode || loading}
            onChange={e => setTestEmail(e.target.value)}
          />
        </div>
        <div className={'checkbox-container'}>
          <input
            type={'checkbox'}
            disabled={loading}
            checked={testMode}
            onChange={e => setTestMode(e.target.checked)}
          />
          <span>Test Mode</span>
        </div>
        <ActionButton
          active={!loading}
          isLoading={loading}
          onClick={sendSummary}
          text={'Send'}
          customClass={'btn btn-primary send-tax'}
        />
      </div>
      {!!error || !!success && (
        <div className={'response-container'}>
          {!!success && <Success message={success} />}
          {!!error && <Error message={error} />}
        </div>
      )}
    </div>
  );
};

export default SendTaxSummary;