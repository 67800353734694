import { Loading } from 'components/loading/Loading';
import React from 'react';

const IsDriverComponent = ({ isDriver, toggleIsDriverFn, isLoading }: { isDriver: boolean; toggleIsDriverFn: any; isLoading: boolean }) => {
  return (
    <>
      <span className="pl-4 font-weight-bold">
        <label htmlFor="isDriver" className="text-muted">
          Is Driver? {isLoading ? <Loading type="mini" /> : <input type="checkbox" className="checkbox" onChange={toggleIsDriverFn} id="isDriver" checked={isDriver} />}
        </label>
      </span>
    </>
  );
};

export default IsDriverComponent;
