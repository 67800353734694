import React, { useCallback, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import _ from 'lodash';
import theme from './index.module.scss';

const DishesAutoSuggest = props => {
  const [suggestions, setSuggestions] = useState([]);

  const {
    value,
    fetchDishes,
    placeholder,
    handleChange,
    onSuggestionSelect,
  } = props;
  
  const onChange = (e, { newValue }) => {
    handleChange(newValue);
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    if (value.length >= 2) {
      const result = await fetchDishes(value);
      if(result){
        setSuggestions(result);
      }
    }
  };

  const onSuggestionsFetchRequestedDebounced = useCallback(_.debounce(onSuggestionsFetchRequested, 500), [value]);

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = suggestion => suggestion.name;

  const renderSuggestion = suggestion => {
    return (
      <div className="d-flex flex-col">
        <span>{suggestion.name}</span>
        <label style={{ fontSize:'12px',color: 'grey' }}>{suggestion.restaurantName}</label>
      </div>
    );
  };

  const onSuggestionSelected = (e, { suggestion }) => {
    onSuggestionSelect(suggestion.id);
  };

  const inputProps = {
    placeholder,
    value,
    onChange,
  };

  return (
    <>
      <Autosuggest
        theme={theme}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequestedDebounced}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    </>
  );
};

export default DishesAutoSuggest;
