import { cloudFunction } from 'services/parse';

export const fetchPaginatedDiscounts = (data: Record<string, any>) => {
  return cloudFunction('fetchPaginatedDiscounts', data);
};

export const fetchDiscount = (id: string) => {
  return cloudFunction('fetchDiscount', { id });
};

export const createDiscount = (data: Record<string, any>) => {
  return cloudFunction('createDiscount', data);
};

export const updateDiscount = (data: Record<string, any>) => {
  return cloudFunction('updateDiscount', data);
};

export const removeEligibleProperties = (data: Record<string, any>) => {
  return cloudFunction('removeEligibleProperties', data);
};