import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import Tooltip from 'react-simple-tooltip';
import { IAddress } from '../../interfaces/address';
import { DEFAULT_ADDRESS } from '../../utils/constant';

const ZOOM_ON_INITIAL_CENTER = 18;
const DEFAULT_ZOOM = 8.65;

const GoogleMapLocationPicker: React.FC<IAddress.IGoogleMapLocationPicker> = (props) => {
  const {
    styles,
    onMapLocationChanged,
    initialCenter,
    draggable = true,
  } = props;
  const [center, setCenter] = useState(initialCenter || DEFAULT_ADDRESS.coords);
  const [map, setMap] = useState(null) as any;
  const [showToolTip, setShowToolTip] = useState(true);
  
  useEffect(() => {
    setCenter(initialCenter || DEFAULT_ADDRESS.coords);
  }, [initialCenter]);

  useEffect(() => {
    if (map) {
      map.setOptions({ draggable: draggable });
    }
  }, [draggable, map]);

  const onGoogleMapLoad = useCallback(function callback(mapRef) {
    setMap(mapRef);
    // @ts-ignore
    mapRef.panTo(center);
  }, []);

  // Need to update the center
  const updateSelectedLocation = () => {
    if (map) {
      // @ts-ignore
      const coords = map.getCenter().toJSON();
      onMapLocationChanged && onMapLocationChanged(coords);
      setShowToolTip(true);
    }
  };

  const onDragStart = () => {
    setShowToolTip(false);
  };
  
  return (
    <div className="map">
      <div className="map-container">
        <GoogleMap
          id="location-picker"
          mapContainerStyle={styles.container}
          zoom={initialCenter ? ZOOM_ON_INITIAL_CENTER : DEFAULT_ZOOM}
          center={center}
          onDragEnd={updateSelectedLocation}
          onDragStart={onDragStart}
          onLoad={onGoogleMapLoad}
        >
          <div style={{ top: '50%', fontSize: 10, left: '50%', position: 'absolute' }}>
            <Tooltip style={{ whiteSpace: 'nowrap' }} padding={5} content={`Your location is here`} fixed={showToolTip} >
              <img  src="https://ik.imagekit.io/hnlpilvl5t/icons/icons8-marker-30_VKHxNJ9vU.png" alt="back" />
            </Tooltip>
          </div>
        </GoogleMap>
      </div>
    </div>
  );
};

export default GoogleMapLocationPicker;
