import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { getPageLimit, updatePageLimit } from 'utils';
import { Loading } from 'components/loading/Loading';
import DataTable from 'react-data-table-component';
import { tableStyles } from 'css/tableStyles';
import styles from './index.module.scss';
import ActionButton from 'components/ActionButton/ActionButton';
import { fetchAllUvaCategories } from 'services/api/uvaCategories';
import { showError } from 'utils/notifications';
import TableLink from 'TableLink';

let filters: { [p: string]: any; page: number } = {
  page: 0,
  deleted: false,
};

const UvaCategories = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<Record<string, any>[]>([]);
  const [filterText, setFilterText] = useState<string>('');

  const columns = [
    {
      name: 'Category Id',
      selector: 'objectId',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/uva-categories/${row.objectId}`}
          text={row.objectId}
          openInNewTab={true}
        />
      ),
    },
    {
      name: 'Category Name',
      selector: 'name',
    },
    {
      name: 'Category Spanish Name',
      selector: 'esName',
    },
  ];

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await fetchAllUvaCategories();
      setCategories(response);
    } catch (e) {
      // showError(e);
    } finally {
      setLoading(false);
    }
  };

  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  const getTableData = () => (
    categories.filter(cat => cat && cat.name && cat.name.toLowerCase().includes(filterText.toLowerCase()))
  );

  useEffect(() => {
    filters = {
      ...filters,
      ...queryStringToFilter(),
    };
    // sort = {};
    fetchCategories();
  }, []);

  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchCategories();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    filters.limit = rowsPerPage;
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <div className="d-flex justify-content-between">
        <input
          type={'text'}
          placeholder={'Search Category'}
          onChange={(e) => setFilterText(e.target.value)}
          className={styles['search-box']}
        />
        <ActionButton
          text={`Add Category +`}
          active={true}
          isLoading={false}
          customClass={'btn-primary mb-2'}
          onClick={() => props.history.push('/uva-categories/create')}
        />
      </div>
      <DataTable
        keyField="objectid"
        noHeader={true}
        columns={columns}
        data={getTableData()}
        highlightOnHover={true}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationPerPage={getPageLimit()}
        paginationDefaultPage={(filters.page || 0) + 1}
        paginationTotalRows={categories.length}
        paginationRowsPerPageOptions={[50, 100, 200]}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </>
  );
};

export { UvaCategories };