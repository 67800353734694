import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import BackButton from 'components/BackButton';
import ActionButton from 'components/ActionButton/ActionButton';
import { DATE_FORMATS, formatDate } from 'utils';
import { showError, showSuccess } from 'utils/notifications';
import { deleteAutomaticMerchantCollection, getAutomaticMerchantCollectionDetail } from 'services/api/automaticMerchantCollection';
import { Loading } from 'components/loading/Loading';
import JSONInput from 'react-json-editor-ajrm';

const AutomaticMerchantCollectionDetails = props => {
  const [loading, setLoading] = useState<boolean>(true);
  const [collection, setCollection] = useState<Record<string, any>>({});
  const [deleteLoading,setDeleteLoading]= useState<boolean>(false);
  const { collectionId } = props.match.params;
  const getCollectionInfo= (heading: string,value: any) => {
    return (
      <div>
        <div className={styles['label']}>{heading}</div>
        <div className={styles['info']}>{value}</div>
      </div>
    );
  };
  const fetchCollectionDetails = async () => {
    try {
      const response = (await getAutomaticMerchantCollectionDetail(collectionId)).toJSON();
      setCollection({
        ...response,
        cloudFunctionParams: JSON.stringify(response.cloudFunctionParams),
      });
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchCollectionDetails();
  }, []);
  
  const getDetailsSection = () => (
    <div className={styles['collection-details-container']}>
      {getCollectionInfo('Collection ID',collection.objectId)}
      {getCollectionInfo('Created At',formatDate(collection.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A))}
      {getCollectionInfo('Active',collection.active?.toString())}
      {getCollectionInfo('Title',collection.title)}
      {getCollectionInfo('Cloud Function',collection.cloudFunctionName)}
      <div className={styles['label']}>Params</div>
      <JSONInput
        placeholder={JSON.parse(collection.cloudFunctionParams || '{}')}
        height='500px'
        width='70%'
        viewOnly={true}
      />
    </div>
  );

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      await deleteAutomaticMerchantCollection(collectionId);
      showSuccess('Collection deleted successfully');
      setDeleteLoading(false);
      props.history.goBack();
    } catch (e) {
      showError(e);
    }
  };

  const getHeader = () => (
    <div className={styles['header']}>
      <div>
        <div className={styles['heading']}>Automatic Merchant Collection Details</div>
        <BackButton />
      </div>
      <div className={'d-flex justify-content-between w-19 p-4 mb-4'}>
        <ActionButton
          text={'Edit'}
          active={true}
          isLoading={false}
          onClick={() => props.history.push(`/automatic-merchant-collection/${collectionId}/edit`)}
          customClass={'btn btn-primary mr-3'}
        />
        <ActionButton
          text={'Delete'}
          active={!deleteLoading}
          isLoading={deleteLoading}
          onClick={() => handleDelete()}
          customClass={'btn btn-outline-secondary mr-3'}
        />
      </div>
    </div>
  );
  
  if (loading) return <Loading />;
  
  return (
    <div className={styles['home-page-details-container']}>
      {getHeader()}
      {getDetailsSection()}
    </div>
  );
};
 
export { AutomaticMerchantCollectionDetails };