import React, { useEffect, useState } from 'react';
import './index.scss';
import ActionButton from 'components/ActionButton/ActionButton';
import { showError, showSuccess } from 'utils/notifications';
import MDEditor from '@uiw/react-md-editor';
import { upsertConfigDescription } from 'services/api/configs';

const EditConfigModal = props => {
  const [loading, setLoading] = useState<boolean>(false);
  const [configDescription, setConfigDescription] = useState<string>('');
  const { closeModal, configToEdit, onSuccess, readOnlyMode } = props;
  const { name, description } = configToEdit;
  
  useEffect(() => {
    !!description && setConfigDescription(description);
  }, []);

  const editConfig = async () => {
    setLoading(true);
    try {
      await upsertConfigDescription({
        name,
        description: configDescription,
      });
      await onSuccess();
      showSuccess('Config updated successfully');
      closeModal();
    } catch(e) {
      showError(e);
      setLoading(false);
    }
  };
  
  const getModalBody = () => readOnlyMode ? (
    <div className='modal-body'>
      <div className='field-group'>
        <label>Description</label>
        <MDEditor.Markdown
          source={configDescription}
        />
      </div>
    </div>
  ): (
    <div className = 'modal-body'>
      <div className = 'field-group'>
        <label>Name</label>
        <input
          className='form-control'
          disabled={true}
          value={name}
        />
      </div >
      <div className='field-group'>
        <label>Description</label>
        <MDEditor
          aria-disabled={loading}
          value={configDescription}
          onChange={val => setConfigDescription(val || '')}
        />
      </div>
    </div >
  );
  
  return (
    <div className='edit-config-container'>
      <div className='edit-config-modal'>
        <div className='modal-header'>
          <div>{`${readOnlyMode ? '' : 'Edit Config'}`}</div>
          <i className='fa fa-close' onClick={closeModal} />
        </div>
        {getModalBody()}
        <div className='modal-footer'>
          <ActionButton
            text={readOnlyMode ? 'Close' : 'Cancel'}
            active={!loading}
            isLoading={false}
            onClick={closeModal}
            type={'button'}
          />
          {!readOnlyMode && (
            <ActionButton
              text={'Save'}
              active={!loading}
              isLoading={false}
              customClass='btn-primary'
              onClick={editConfig}
              type={'submit'}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditConfigModal;