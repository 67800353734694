import React from 'react';
import { removeRejectionFromOrder } from 'services';
import { DATE_FORMATS, formatDate, showError } from 'utils';
import GetDriverDetailsTemplate from './DriverDetailsTemplate';

const DriverRejections = (props) => {
  const { orderDetails = {}, fetchAdminOrderDetailFn, setLoading } = props;
  if (!orderDetails?.rejections?.length) return null;

  const removeRejectionFromOrderFn = async (driverId) => {
    try {
      setLoading(true);
      await removeRejectionFromOrder({
        id: orderDetails?.objectId,
        driverId,
      });
      fetchAdminOrderDetailFn();
    } catch (e) {
      showError(e);
    }
  };

  const rejections = orderDetails?.rejections?.map((driver) => {
    return (
      <GetDriverDetailsTemplate 
        id={driver?.driverId} 
        details={[
          {
            key: 'Reason',
            value: driver?.reason,
          },
          {
            key: 'Bonus Assigned',
            value: driver?.bonusAssigned || '-',
          },
          {
            key: 'Rejected At',
            value: formatDate(driver?.rejectedAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
          },
        ]} 
        showRemoveRejection
        removeRejectionFromOrderFn={removeRejectionFromOrderFn}
      />);
  });

  return (
    <div className={'not-accepted-by-driver-details-section'}>
      <div className={'section-header'}>
        <div className={'label'}>Driver Rejections</div>
      </div>
      <div className='drivers'>
        {rejections}
      </div>
    </div>
  );
};

export default DriverRejections;