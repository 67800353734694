import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { fetchStoresForUser } from 'services/api/users';
import TableLink from 'TableLink';
import { showError } from 'utils/notifications';

const UserFleetLocations = props => {
  const { userId } = props;
  const [stores, setStores] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchUserRestaurantsFn = async () => {
    try {
      const response = await fetchStoresForUser(userId);
      setStores(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserRestaurantsFn();
  }, []);

  const columns = useMemo(() => [
    {
      name: 'Restaurant ID',
      selector: 'objectId',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/restaurants/${row.objectId}`}
          text={row.objectId}
          openInNewTab={false}
        />
      ),
    },
    {
      name: 'Name',
      selector: 'restaurant_name',
      sortable: false,
    },
    {
      name: 'Contact Phone',
      selector: 'contact_phone',
      sortable: false,
    },
    {
      name: 'Contact Address',
      selector: 'contact_address',
      sortable: false,
    },
    {
      name: 'Latitude',
      selector: 'restaurant_geo.latitude',
      sortable: false,
    },
    {
      name: 'Longitude',
      selector: 'restaurant_geo.longitude',
      sortable: false,
    },
  ], [loading]);

  return (
    <div>
      <div className={'mt-4'}>
        <div className={'d-flex justify-content-between mb-2 pr-3'}>
          <h4 className='pl-2 font-weight-bolder'>User Fleet Locations</h4>
        </div>
        {loading ? (
          <Loading type={'mini'} />
        ) : (
          <DataTable
            noHeader
            keyField="objectId"
            columns={columns}
            data={stores}
            highlightOnHover={true}
            customStyles={tableStyles}
            pagination={false}
          />
        )}
      </div>
    </div>
  );
};

export default UserFleetLocations;