import { cloudFunction } from 'services/parse';

export const createAutomaticMerchantCollection = (data: Record<string, any>) => {
  return cloudFunction('createAutomaticMerchantCollection', data);
};

export const updateAutomaticMerchantCollection = (data: Record<string, any>) => {
  return cloudFunction('updateAutomaticMerchantCollection', data);
};

export const deleteAutomaticMerchantCollection = (id: string) => {
  return cloudFunction('deleteAutomaticMerchantCollection', { id });
};

export const toggleActiveStatusForAutomaticMerchantCollection = (id: string) => {
  return cloudFunction('toggleActiveStatusForAutomaticMerchantCollection', { id });
};

export const fetchAutomaticMerchantCollection = async () => {
  const response = await cloudFunction('fetchAutomaticMerchantCollection', {});
  return response.map(item => item.toJSON());
};

export const getAutomaticMerchantCollectionDetail = (id: string) => {
  return cloudFunction('getAutomaticMerchantCollectionDetail', { id });
};