import React, {  } from 'react';
import './index.scss';
import DishVarient from './variant';

const DishVarientsList = props => {
  const {
    dishVariants = [],
    setVariantLevels,
    variantLevels,
    dishDetails,
    setVariantsArray,
    isNameClickable = false,
    variantsArray,
    variantsHashMap,
    onClickVariantEdit,
  } = props;

  return(
    <div className={'dish-variants-section'}>
      {!!dishVariants.length ? (
        <div className={'variants-container'}>
          <div className={'variants-header'}>
            <div className={'object-id'}>Object Id</div>
            <div className={'name'}>Name</div>
            <div className={'price'}>Price</div>
            <div className={'header-item'}>Selected variants</div>
            <div className={'header-item'}>Required quantity</div>
            <div className={'available'}>Available</div>
          </div>
          {dishVariants.map((variant, i) => (
            <DishVarient 
              variant={variant}
              setVariantLevels={setVariantLevels}
              variantLevels={variantLevels}
              key={i}
              dishDetails={dishDetails}
              setVariantsArray={setVariantsArray}
              isNameClickable={isNameClickable}
              variantsArray={variantsArray}
              variantsHashMap={variantsHashMap}
              onClickVariantEdit={onClickVariantEdit}
            />
          ))}
        </div>
      ) : (
        <div className={'no-variants-message'}>No variants added</div>
      )}
    </div>
  );
};

export default DishVarientsList;