import Currency from 'components/Currency';
import { Loading } from 'components/loading/Loading';
import ToggleSwitch from 'components/ToggleSwitch';
import React, { useState } from 'react';
import { deleteDishVariant, toggleDishVariantAvailability } from 'services/api/dishes';
import { showError, showSuccess } from 'utils';
import './index.scss';

const DishVarient = props => {
  const {
    variant = {},
    variant: { minSelectedVariants = 0, maxSelectedVariants = 0, minCount = 0, maxCount = 0 } = {},
    setVariantLevels,
    variantLevels,
    dishDetails,
    setVariantsArray,
    isNameClickable,
    variantsHashMap,
    onClickVariantEdit,
  } = props;

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [toggleLoading, setToggleLoading] = useState<boolean>(false);
  const subVariants = variantsHashMap[variant?.objectId] || [];
  
  const onClickAdd = (variant) => {
    if(!isNameClickable) return;
    setVariantLevels([ ...variantLevels, variant?.objectId]);
  };

  const deleteVariant = async () => {
    setDeleteLoading(true);
    const data = {
      restaurantId: dishDetails?.restaurant?.objectId,
      dishVariantId: variant?.objectId,
      'responseAsTree': true,
    };
    try {
      const response = await deleteDishVariant(data);
      setVariantsArray(response);
      showSuccess('Variant deleted successfully');
    } catch (e) {
      showError(e);
    } finally {
      setDeleteLoading(false);
    }
  };

  const toggleVariantAvailability = async () => {
    setToggleLoading(true);
    try {
      const response = await toggleDishVariantAvailability({ dishVariantId: variant?.objectId, responseAsHierarchy: true });
      showSuccess('Variant toggled successfully');
      setVariantsArray(response);
    } catch (e) {
      showError(e);
    } finally {
      setToggleLoading(false);
    }
  };

  const onCLickEdit = () => {
    onClickVariantEdit(variant);
  };

  return(
    <div
      key={variant.objectId}
      className={'dish-variant'}
    >
      <div 
        className={'object-id'}
      >
        {variant.objectId}
      </div>
      <div className='name-container' 
        onClick={()=>{onClickAdd(variant)}}
        style={{ cursor: isNameClickable ? 'pointer' : 'normal' }}>
        <span 
          className={'name'}
        >
          {variant.name}
        </span>
        <br />
        <span className='variants-count'>{subVariants?.length} variants</span>
      </div>
      <div className={'price'}><Currency amount={variant.price} /></div>
      <div className={'selected-count'}>{minSelectedVariants} - {maxSelectedVariants}</div>
      <div className={'selected-count'}>{minCount} - {maxCount}</div>
      <div className={'available'}>
        <ToggleSwitch 
          key={variant.objectId}
          switchLabel={''}
          checked={variant.available}
          onChange={toggleVariantAvailability}
          disabled={toggleLoading || deleteLoading}
        />
      </div>
      <div className={'delete-variant'}>
        {deleteLoading ? (
          <Loading type={'mini'} />
        ) : (
          <i
            className={'fa fa-trash'}
            onClick={deleteVariant}
          />
        )}
      </div>
      {onClickVariantEdit ? <div className={'delete-variant ml-1'} onClick={onCLickEdit}>
        Edit
      </div> : null}
    </div>
  );
};

export default DishVarient;