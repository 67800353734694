import React from 'react';
import AsyncJobs from 'components/AsyncJobs/AsyncJobs';
import { formatDate } from 'utils';

const NotificationsList = props => {
  const columns = [
    {
      name: 'Job ID',
      selector: 'id',
      sortable: false,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: false,
    },
    {
      name: 'Created At',
      selector: 'createdAt',
      sortable: true,
      format: (row: any) => formatDate(row.createdAt),
    },
    {
      name: 'Created By',
      selector: 'startedBy',
      sortable: false,
    },
  ];

  const onRowClick = row => {
    props.history.push(`/job-detail/${row.id}`);
  };
  
  return (
    <>
      <button
        className='btn btn-primary float-right'
        onClick={() => props.history.push('/send-notifications')}
      >Send New Notification</button>
      <AsyncJobs
        type='DRIVER_NOTIFICATION'
        columns={columns}
        onRowClicked={onRowClick}
      />
    </>
  );
};

export { NotificationsList };