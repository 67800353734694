import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { showError, showSuccess } from 'utils/notifications';
import { ACTION_ICON_TYPES } from 'utils';
import TableLink from 'TableLink';
import { deleteAutomaticMerchantCollection,fetchAutomaticMerchantCollection,toggleActiveStatusForAutomaticMerchantCollection } from 'services/api/automaticMerchantCollection';
import ActionIcon from 'components/ActionIcon';
import ToggleSwitch from 'components/ToggleSwitch';
import ActionButton from 'components/ActionButton/ActionButton';

const AutomaticMerchantCollectionList = props => {
  const [collections, setCollections] = useState<[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [switchLoading,setSwitchLoading]= useState<boolean>(false);

  const fetchMerchantCollection = async () => {
    try {
      const response = await fetchAutomaticMerchantCollection();
      setCollections(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };  

  const deleteDishCollectionFn = async (id: string) => {
    setLoading(true);
    try {
      await deleteAutomaticMerchantCollection(id);
      showSuccess('Merchant COllection deleted succefully.');
      await fetchMerchantCollection();
    } catch (e) {
      showError(e);
    } finally{
      setLoading(false);
    }
  };

  const handleToggleActiveStatus = async (id: string)=>{
    setSwitchLoading(true);
    try {
      await toggleActiveStatusForAutomaticMerchantCollection(id);
      fetchMerchantCollection();
    } catch (e) {
      showError(e);
    } finally{
      setSwitchLoading(false);
    }
  };

  useEffect(() => {
    fetchMerchantCollection();
  }, []);

  const columns = useMemo(() => [
    {
      name: 'Collection ID',
      selector: 'objectId',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/automatic-merchant-collection/${row.objectId}`}
          text={row.objectId}
        />
      ),
    },
    {
      name: 'Title',
      selector: 'title',
    },
    {
      name: 'Created At',
      selector: 'createdAt',
    },
    {
      name: 'Cloud Function Name',
      selector: 'cloudFunctionName',
    },
    {
      name: 'Active',
      selector: 'active',
      // eslint-disable-next-line react/display-name
      cell: (row) => (
        <ToggleSwitch
          switchLabel=''
          checked={row.active}
          disabled={switchLoading}
          onChange={()=>handleToggleActiveStatus(row.objectId)}
        />
      ),
      width: '80px',
    },
    {
      name: '',
      selector: '',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <ActionIcon
          actionType={ACTION_ICON_TYPES.DELETE}
          onClick={() => deleteDishCollectionFn(row.objectId)}
        />
      ),
    },
  ], [switchLoading]);

  return (
    <div>
      {loading && <Loading />}
      <DataTable
        title={'Automatic Merchant Collections'}
        actions={(
          <ActionButton
            text={<>Create Collection<span>+</span></>}
            active={!loading}
            isLoading={loading}
            customClass={'btn-primary mb-2'}
            onClick={()=>props.history.push('/automatic-merchant-collection/collection/create')}
          />
        )}
        keyField="objectId"
        columns={columns}
        data={collections}
        highlightOnHover={true}
        customStyles={tableStyles}
      />
    </div>
  );
};

export { AutomaticMerchantCollectionList };