import { Loading } from 'components/loading/Loading';
import React from 'react';
import './index.scss';
import Currency from 'components/Currency';
import { CANCEL_FLEET_ORDER_REASON_CODES } from 'utils';
import _ from 'lodash';

const SectionHeader = props => {
  return (
    <div className={`report-section-header`}>{props.heading}</div>
  );
};

const DetailsGroup = props => {
  const {
    label,
    value,
    negative,
    groupClassName,
    infoClassName,
  } = props;

  return (
    <div className={`details-group ${groupClassName || ''}`}>
      <div className={`label`}>{label}</div>
      <div className={`info ${infoClassName || ''}`}>
        <span>{negative ? '- ' : ''}</span>
        <Currency amount={value} />
      </div>
    </div>
  );
};

const FleetRevenueSection = props => {
  const { revenueData, salesRevenueData, loading } = props;

  const getHeading = (heading: string) => {
    return (
      <div className={'heading'}>{heading}</div>
    );
  };

  const getDetailsSection = () => {
    const getOthersReportSection = () => (
      <div className={'revenue-report-others-section'}>
        <SectionHeader heading={'Others'} />
        <DetailsGroup
          label={'Total Delivery Fee'}
          value={revenueData.totalDeliveryFee}
          groupClassName={''}
          infoClassName={''}
        />
      </div>
    );

    const getRefundsReportSection = () => (
      <div className={'revenue-report-refunds-section'}>
        <SectionHeader heading={'Refunds'} />
        <DetailsGroup
          label={'Total Refunds'}
          value={revenueData.totalFleetRefund}
          groupClassName={''}
          infoClassName={''}
        />
      </div>
    );

    const getTaxesReportSection = () => (
      <div className={'revenue-report-taxes-section'}>
        <SectionHeader heading={'Taxes'} />
        <DetailsGroup
          label={'Total Delivery Taxes'}
          value={revenueData.totalDeliveryTaxes}
          groupClassName={''}
        />
      </div>
    );

    const getDriversReportSection = () => (
      <div className={'revenue-report-drivers-section'}>
        <SectionHeader heading={'Driver'} />
        <DetailsGroup
          label={'Total Uva Bonus'}
          value={revenueData.totalUvaBonus}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Distance Bonus'}
          value={revenueData.totalDistanceBonus}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Rejection Bonus'}
          value={revenueData.totalRejectionBonus}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Driver Bonus'}
          value={revenueData.totalDriverBonus}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Tips'}
          value={revenueData.totalTip}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Toll'}
          value={revenueData.totalToll}
          groupClassName={''}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Driver Earning'}
          value={revenueData.totalDriverEarning}
          groupClassName={'horizontal-rule pb-1'}
          infoClassName={''}
        />
        <DetailsGroup
          label={'Total Driver Payment'}
          value={revenueData.totalDriverPayment}
          groupClassName={''}
          infoClassName={''}
        />
      </div>
    );

    const getRefundByReasonSection = () => {
      return (
        <div className={'revenue-report-refund-reason-section'}>
          <SectionHeader heading={'Refund By Reason'} />
          {Object.keys(revenueData.refundByReasonAndType).map(key => {
            const reason = revenueData.refundByReasonAndType[key];
            return (
              <div key={key} className={'refundByReason-main-container'}>
                {Object.values(CANCEL_FLEET_ORDER_REASON_CODES).includes(key) ?
                  <div className={'single-reason'}>
                    <div className={'reason'}>{key}</div>
                    {Object.keys(reason).map(key => {
                      const type = reason[key];
                      return (
                        <DetailsGroup
                          key={key}
                          label={key}
                          value={type.refundAmount.toFixed(2)}
                          groupClassName={''}
                          infoClassName={''}
                        />
                      );
                    })}
                  </div>
                  :
                  null
                }
              </div>
            );
          })}
        </div>
      );
    };

    const getTotalOrders = () => (
      <div className={'total-order'}>
        <div>Total Orders</div>
        <div>{revenueData.totalOrders}</div>
      </div>
    );

    const renderDetails = () => (
      Object.keys(revenueData).length > 0 ? (
        <div className={'revenue-details-section'}>
          {getTotalOrders()}
          <div>
            <div className={''}>
              {getDriversReportSection()}
              {getRefundByReasonSection()}
            </div>
            <div className={''}>
              {getTaxesReportSection()}
              {getRefundsReportSection()}
              {getOthersReportSection()}
            </div>
          </div>
        </div>
      ) : null
    );

    if (loading) {
      return <Loading />;
    }
    if (Object.keys(revenueData).length > 0) {
      return (
        <>
          {getHeading('Fleet Revenue Overview')}
          {renderDetails()}
        </>
      );
    } else {
      return null;
    }
  };

  const getOverallTotalsSection = () => {
    if (Object.keys(revenueData).length > 0) {
      return (
        <div className='revenue-overview-container'>
          {getHeading('Total Amount')}
          <DetailsGroup
            label={'Total Sales Order'}
            value={salesRevenueData?.totalOrderAmount ?? 0}
            groupClassName={'ml-2 d-flex'}
            infoClassName={'ml-auto mr-4'}
          />
          <DetailsGroup
            label={'Total Fleet Order'}
            value={revenueData?.totalOrderAmount ?? 0}
            groupClassName={'ml-2 d-flex'}
            infoClassName={'ml-auto mr-4 total'}
          />
          <DetailsGroup
            label={'Total Amount'}
            value={_.round(salesRevenueData?.totalOrderAmount ?? 0 + revenueData?.totalOrderAmount ?? 0, 2)}
            groupClassName={'ml-2 d-flex'}
            infoClassName={'ml-auto mr-4'}
          />
          <div className={'heading'}>Total Refund</div>
          <DetailsGroup
            label={'Total Sales Refund'}
            value={salesRevenueData?.totalRefunds ?? 0}
            groupClassName={'ml-2 d-flex'}
            infoClassName={'ml-auto mr-4'}
          />
          <DetailsGroup
            label={'Total Fleet Refund'}
            value={revenueData.totalFleetRefund ?? 0}
            groupClassName={'ml-2 d-flex'}
            infoClassName={'ml-auto mr-4 total'}
          />
          <DetailsGroup
            label={'Total Refund'}
            value={salesRevenueData.totalAmountFromAllRefunds}
            groupClassName={'ml-2 d-flex'}
            infoClassName={'ml-auto mr-4'}
          />
        </div>
      );
    } else return null;
  };

  return (
    <div className={'revenue-overview-container'}>
      {revenueData?.totalOrders && getDetailsSection()}
      {getOverallTotalsSection()}
    </div>
  );
};


export default FleetRevenueSection;