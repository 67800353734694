import ActionButton from 'components/ActionButton/ActionButton';
import React,{ useState } from 'react';
import { createUvaCategory } from '../../../services/api/uvaCategories';
import { showError, showSuccess } from '../../../utils/notifications';
import styles from './index.module.scss';
import BackButton from '../../../components/BackButton/index';
import UvaCategoriesFlattenTree from 'components/UvaCategoryTree/UvaCategoryFlattenTree';

const CreateUvaCategory = props=>{
  const [name, setName] = useState<string>('');
  const [esName, setEsName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [uvaCategoryId, setUvaCategoryId] = useState<string>('');
  const [uvaCategoryName, setUvaCategoryName] = useState<string>('');

  const handleSubmit = async (e: any) => {
    setLoading(true);
    try {
      await createUvaCategory(name, esName, uvaCategoryId);
      showSuccess(`Category created successfully`);
      props.history.push('/uva-categories');
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (key, label) => {
    setUvaCategoryId(key);
    setUvaCategoryName(label);
  };

  return (
    <form onSubmit={handleSubmit}>
      <BackButton />
      <div>
        <div className={styles['title']}>{`Create Uva Category`}</div>
        <div className={styles['body-container']}>
          <div>
            <div className={styles['label']}>Parent Category</div>
            <div className='input-container mr-4 mb-2'>
              <UvaCategoriesFlattenTree
                uvaCategoryId={uvaCategoryId}       // the hardcoded Restaurant ID
                onChange={handleCategoryChange}
              />
            </div>
          </div>
          <div className="mt-2 mr-4">
            <div className={styles['label']}>Category Name</div>
            <input
              className={'form-control'}
              value={name}
              type="text"
              onChange={e => setName(e.target.value)}
              disabled={loading}
            />
          </div>
          <div className="mt-2 mr-4">
            <div className={styles['label']}>Category Name (Spanish)</div>
            <input
              className={'form-control'}
              value={esName}
              type="text"
              onChange={e => setEsName(e.target.value)}
              disabled={loading}
            />
          </div>
        </div>
        
        <div className={styles['footer']}>
          <ActionButton
            text="Submit"
            active={true}
            isLoading={loading}
            onClick={handleSubmit}
            customClass={'btn-primary'}
          />
        </div>
      </div>
    </form>
  );
};

export { CreateUvaCategory };