import React from 'react';
import { DATE_FORMATS, formatDate } from 'utils';
import { IDate } from 'interfaces';

const ViewDate: React.FC<IDate.IViewDateProps> = props => {
  const { customClass, date } = props;

  return <div className={customClass}>{formatDate(date, DATE_FORMATS.MM_DD_YYYY_HH_MM_A)}</div>;
};

export default ViewDate;