import ActionButton from 'components/ActionButton/ActionButton';
import React, { useState } from 'react';
import { DATE_FORMATS, DISCOUNT_TYPES, STRIPE_SUPPORTED_CARD_BRANDS, showError, showSuccess } from 'utils';
import styles from './index.module.scss';
import DropDown from 'components/Dropdown';
import { createDiscount } from 'services/api/discounts';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import DecimalInput from 'components/DecimalInput';
import { Error } from 'components/message/Error';
import WholeNumberInput from 'components/WholeNumberInput';
import DatePicker from 'react-datepicker';
import ActionCheckbox from 'components/ToggleSwitch';
import { Loading } from 'components/loading/Loading';
import MultiSelectDropdown from 'components/MultiSelectDropdown';

const validationSchema = yup.object().shape({
  code: yup.string().required('code is required'),
});

type FormData = {
  code: string;
}

const CreateDiscount = props => {
  const { errors, handleSubmit, register, reset } = useForm<FormData>({ resolver: yupResolver(validationSchema) });
  const [loading, setLoading] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(true);
  const [onlyOnFirstOrder, setOnlyOnFirstOrder] = useState<boolean>(false);
  const [onlyForPickup, setOnlyForPickup] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [cardBrands, setCardBrands] = useState([]);
  const [startDate, setStartDate] = useState<string>(moment().toISOString());
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [discountPercent, setDiscountPercent] = useState<number>(0);
  const [discountAmount, setDiscountAmount] = useState<number>(0);

  const createDiscountFn = handleSubmit(async (values) =>  {
    setLoading(true);
    try {
      if(!type){
        throw 'Discount Type is required';
      }
      const discountDetails = {
        ...values, 
        type,
        startDate,
        endDate,
        active,
        discountAmount,
        discountPercent,
        onlyOnFirstOrder,
        onlyForPickup,
      } as any;
      if (cardBrands?.length > 0) {
        discountDetails.cardEligibility = {};
        discountDetails.cardEligibility.cardBrands = cardBrands?.map((card: any)=>card?.value);
      }
      const res = await createDiscount(discountDetails);
      showSuccess('Discount added successfully.');
      props.history.replace(`/discounts/${res.id}`);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  });

  const getPercentOrAmount = () => {
    if (type.includes('FIXED')) {
      return (
        <div className={styles['info']}>
          <div className={styles['label']}>Discount Amount</div>
          <WholeNumberInput
            value={discountAmount}
            disabled={loading}
            onChange={e => setDiscountAmount(e)}
          />
        </div>
      );
    } else {
      return (
        <div className={styles['info']}>
          <div className={styles['label']}>Discount Percent</div>
          <WholeNumberInput
            value={discountPercent}
            disabled={loading}
            onChange={e => setDiscountPercent(e)}
          />
        </div>);
    }
  };

  const getDates = () => (
    <div className={styles['dates']}>
      <div className={styles['info']}>
        <div className={styles['label']}>Start Date</div>
        <div className={styles['date-picker']}>
          <DatePicker
            wrapperClassName='w-100 '
            className={'form-control'}
            showTimeInput
            dateFormat={DATE_FORMATS.MM_DD_YYYY__EE_HH_MM_A}
            selected={startDate ? moment(startDate).toDate() : null}
            onChange={(e: any) => setStartDate(moment(e).toISOString())}
          />
        </div>
      </div>
      <div className={styles['info']}>
        <div className={styles['label']}>End Date</div>
        <div className={styles['date-picker']}>
          <DatePicker
            wrapperClassName='w-100'
            className={'form-control'}
            dateFormat={DATE_FORMATS.MM_DD_YYYY__EE_HH_MM_A}
            showTimeInput
            selected={endDate ? moment(endDate).toDate() : null}
            onChange={(e: any) => setEndDate(moment(e).toISOString())}
          />
        </div>
      </div>
    </div>
  );

  const getFooter = () => (
    <div className={styles['footer']}>
      <ActionButton
        text={'Cancel'}
        active={!loading}
        isLoading={false}
        onClick={() => props.history.goBack()}
        customClass={styles['cancel']}
      />
      <ActionButton
        text={'Create Discount'}
        active={!loading}
        isLoading={loading}
        onClick={createDiscountFn}
        customClass={styles['submit']}
      />
    </div>
  );

  const getCheckBoxContainer = () => (
    <>
      <div className={styles['info']}>
        <div className={styles['label']}>Active</div>
        <div className={styles['active-input-container']}>
          <ActionCheckbox
            switchLabel=''
            checked={active}
            disabled={loading}
            onChange={() => setActive(!active)}
          />
        </div>
      </div>
      <div className={styles['info']}>
        <div className={styles['label']}>Only on First Order</div>
        <div className={styles['active-input-container']}>
          <ActionCheckbox
            switchLabel=''
            checked={onlyOnFirstOrder}
            disabled={loading}
            onChange={() => setOnlyOnFirstOrder(!onlyOnFirstOrder)}
          />
        </div>
      </div>
      <div className={styles['info']}>
        <div className={styles['label']}>Only For Pickup</div>
        <div className={styles['active-input-container']}>
          <ActionCheckbox
            switchLabel=''
            checked={onlyForPickup}
            disabled={loading}
            onChange={() => setOnlyForPickup(!onlyForPickup)}
          />
        </div>
      </div>
    </>
  );

  const getCardBrandsInput = () => {
    return(
      <div className={styles['card-brand']}>
        <div className={styles['label']}>Card Brands</div>
        <MultiSelectDropdown
          value={cardBrands}
          options={STRIPE_SUPPORTED_CARD_BRANDS}
          onChange={setCardBrands}
          disableSearch={true}
          hasSelectAll={false}
        />
      </div>
    );
  };

  const getBody = () => (
    <div className={styles['account-details']}>
      <div className={styles['info']}>
        <div className={styles['label']}>Discount Code</div>
        <input
          className={'form-control'}
          disabled={loading}
          ref={register}
          name={'code'}
        />
        {errors.code && <Error message={errors.code.message} />}
      </div>
      <div className={styles['discountType']}>
        <div className={styles['info']}>
          <div className={styles['label']}>Discount Type</div>
          <DropDown ref={register} value={type} onChange={(e) => setType(e)} options={DISCOUNT_TYPES} name={'Discount Type'} containerClass={'form-select'} />
        </div>
        {getCardBrandsInput()}
        {getPercentOrAmount()}
      </div>
      {getDates()}
      {getCheckBoxContainer()}
    </div >
  );

  const getHeader = () => (
    <div className={styles['header']}>
      <div className={styles['heading']}>Create a New Discount</div>
    </div>
  );

  return (
    <div className={styles['main-container']}>
      {loading && <Loading/>}
      {getHeader()}
      {getBody()}
      {getFooter()}
    </div>
  );
};

export { CreateDiscount };
