import ActionButton from 'components/ActionButton/ActionButton';
import InfoModal from 'components/InfoModal';
import React, { useState } from 'react';
import { duplicateDish } from 'services/api/dishes';
import { showError, showSuccess, stringifyAndParseObj } from 'utils';

const DuplicateDish = (props) => {
  const { dish } = props;
  const [loading, setLoading] = useState(false);
  const [isDuplicateDishModalOpen, setIsDuplicateDishModalOpen] = useState(false);

  const toggleDuplicateDishModal = () => {
    setIsDuplicateDishModalOpen(!isDuplicateDishModalOpen);
  };

  const duplicateDishFn = async() => {
    setLoading(true);
    try {
      let res = await duplicateDish(dish?.objectId);
      res = stringifyAndParseObj(res);
      toggleDuplicateDishModal();
      showSuccess('Dish duplicated successfully');
      props.history.push(`/dishes/${res?.objectId}`);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ActionButton 
        text="Duplicate" 
        onClick={toggleDuplicateDishModal} 
        isLoading={loading} 
        active={!loading} 
        customClass='ml-2'
      />
      {isDuplicateDishModalOpen && <InfoModal
        type=''
        title="Duplicate Dish"
        message="Are you sure you want to duplicate the dish?"
        cancellable={true}
        toggleModal={toggleDuplicateDishModal}
        primaryButtonText="Yes"
        primaryButtonOnClick={duplicateDishFn}
        secondaryButtonText="Cancel"
        secondaryButtonOnClick={toggleDuplicateDishModal}
      />}
    </div>
  );
};

export default DuplicateDish;