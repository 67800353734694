import React, { useState } from 'react';
import ReportsFilter from 'components/ReportsFilter';
import { Loading } from 'components/loading/Loading';
import DataTable from 'react-data-table-component';
import { primeSubscriptionDiscountReport } from 'services/api/reports';
import moment from 'moment';
import { showError } from 'utils/notifications';
import ExportCSVButton from 'components/ExportCSVButton';
import DataTableFilter from 'components/DataTableFIlter';
import { tableStyles } from 'css/tableStyles';
import EmptySectionMessage from 'components/message/EmptySectionMessage';
import TableLink from 'TableLink';

const columns = [
  {
    name: 'Restaurant Id',
    selector: 'objectId',
    sortable: false,
    width: '150px',
    // eslint-disable-next-line react/display-name
    cell: (row) => <TableLink linkTo={`/restaurants/${row.objectId}`} text={row.objectId} />,
  },
  {
    name: 'Restaurant Name',
    selector: 'restaurantName',
    sortable: false,
  },
  {
    name: 'Delivery Discount',
    selector: 'totalPrimeDeliveryDiscount',
    sortable: false,
  },
  {
    name: 'Items Discount',
    selector: 'primeItemsDiscount',
    sortable: false,
  },
];

const columnsForCSV = [
  {
    value: 'objectId',
    label: 'Restaurant ID',
  },
  {
    value: 'restaurantName',
    label: 'Restaurant Name',
  },
  {
    value: 'totalPrimeDeliveryDiscount',
    label: 'Delivery Discount',
  },
  {
    value: 'primeItemsDiscount',
    label: 'Items Discount',
  },
];

const PrimeSubscriptionDiscountReport = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null | string>(moment().subtract(1, 'days').toDate());
  const [endDate, setEndDate] = useState<Date | null | string>(moment().toDate());
  const [data, setData] = useState<Array<Record<string, any>>>([]);
  const [filterText, setFilterText] = React.useState('');

  const fetchPrimeSubscriptionDiscountReport = async () => {
    setLoading(true);
    try {
      const response = await primeSubscriptionDiscountReport({ startDate, endDate });
      setData(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const getDataTable = () => {
    if (loading) return <Loading />;

    if (data.length > 0) {
      const exportCSVButton = <ExportCSVButton data={data} columnsForCSV={columnsForCSV} fileName={'Prime Discount Report'} />;
      const dataFilterInput = <DataTableFilter filterText={filterText} setFilterText={setFilterText} placeholder={'Filter by Restaurant ID'} />;
      const filteredData = data.filter(item => item.objectId && item.objectId.toLowerCase().includes(filterText.toLowerCase()));

      return (
        <DataTable
          title="Reports"
          keyField="objectId"
          columns={columns}
          data={filteredData}
          customStyles={tableStyles}
          pagination={false}
          striped={true}
          actions={[dataFilterInput, exportCSVButton]}
        />
      );
    } else {
      return <EmptySectionMessage message={'No records found'} />;
    }
  };

  return (
    <div className='report-container'>
      <ReportsFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        handleSubmit={fetchPrimeSubscriptionDiscountReport}
        showEmailInput={false}
      />
      {getDataTable()}
    </div>
  );
};

export default PrimeSubscriptionDiscountReport;
