import ActionButton from 'components/ActionButton/ActionButton';
import BackButton from 'components/BackButton';
import Currency from 'components/Currency';
import { Loading } from 'components/loading/Loading';
import ToggleSwitch from 'components/ToggleSwitch';
import UvaCategoriesFlattenTree from 'components/UvaCategoryTree/UvaCategoryFlattenTree';
import React, { useEffect, useState } from 'react';
import { deleteDish, getDishDetail, toggleDishAvailability } from 'services/api/dishes';
import { formatDate } from 'utils';
import { showError, showSuccess } from 'utils/notifications';
import { assignUvaCategoryToDish } from 'services/api/categoriseDishes';
import './index.scss';
import { Link } from 'react-router-dom';
import TableLink from 'TableLink';
import DynamicLink from 'components/DynamicLink';
import DishVariants from 'components/DishVariants';
import DuplicateDish from './duplicateDish';

const DishDetails = props => {
  const [dishDetails, setDishDetails] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [toggleLoading, setToggleLoading] = useState<string>('');
  const [dishDeleteLoading, setDishDeleteLoading] = useState<boolean>(false);
  const [showUvaCategories, setShowUvaCategories] = useState<boolean>(false);
  const [selectedUvaCategoryId, setSelectedUvaCategoryId] = useState<string>('');
  const { dishId } = props.match.params;
  const [restaurantId, setRestaurantId] = useState<string>('');

  const fetchDishDetails = async () => {
    setLoading(true);
    try {
      const dish = await getDishDetail(dishId) as any;
      setRestaurantId(dish?.restaurant?.objectId);
      setDishDetails(dish);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDishDetails();
  }, [dishId]);

  const toggleDishAvailabilityFn = async () => {
    setToggleLoading(dishId);
    try {
      await toggleDishAvailability({
        dishId,
        restaurantId,
      });
      setDishDetails({
        ...dishDetails,
        available: !dishDetails.available,
      });
      showSuccess('Dish availability toggled successfully');
    } catch (e) {
      showError(e);
    } finally {
      setToggleLoading('');
    }
  };

  const assignDishToCategory = async () => {
    try {
      await assignUvaCategoryToDish(dishDetails?.objectId, selectedUvaCategoryId);
      showSuccess('Uva Category assigned to dish successfully');
    } catch (e) {
      showError(e);
    } finally {

    }
  };

  const deleteDishFn = async () => {
    setDishDeleteLoading(true);
    const data = {
      dishId,
      restaurantId,
    };
    try {
      await deleteDish(data);
      props.history.goBack();
    } catch (e) {
      showError(e);
      setDishDeleteLoading(false);
    }
  };

  const getDeleteLabel = () => {
    if (dishDetails?.deleted) {
      return (
        <div className={`flag mr-3 mb-auto ${dishDetails.deleted ? 'deleted' : 'not-deleted'}`}>
          DELETED
        </div>
      );
    } else return null;
  };

  const getDeleteActions = () => {
    if (!dishDetails?.deleted) {
      if (dishDeleteLoading) {
        return <Loading type={'mini'} />;
      } else {
        return <i
          className={'fa fa-trash mt-1'}
          onClick={deleteDishFn}
        />;
      }
    } else {
      return null;
    }
  };

  const getUvaCategory = () => {
    if (dishDetails?.uvaCategory) {
      return (
        <div className={'dish-details-group'}>
          <div className={'label'}>Uva Category</div>
          <Link
            to={`/uva-categories/${dishDetails.uvaCategory?.objectId}`}
            className={'detail'}
          >
            <u>{dishDetails.uvaCategory?.name}</u>
          </Link>
        </div>
      );
    } else return null;
  };

  const getDishDetails = () => (
    <div className={'dish-basic-details-section'}>
      <div className={'image-and-name-container'}>
        <div className={'dish-image-section'}>
          <img src={dishDetails.image ? dishDetails.image.url : require('assets/placeholder.svg')} />
        </div>
        <div className={'dish-name-section'}>
          <div className={'action-button-section'}>
            <div className={'dish-details-group'}>
              <div className={'label'}>Dish ID</div>
              <div className={'detail'}>{dishId}</div>
            </div>
            <div className={'button-group'}>
              {getDeleteLabel()}
              <i
                className={'fa fa-pencil mt-1'}
                onClick={() => props.history.push(`/dishes/${dishId}/edit/${restaurantId}`)}
              />
              {getDeleteActions()}
              <DuplicateDish dish={dishDetails} {...props} />
            </div>
          </div>
          <div className={'dish-details-group'}>
            <div className={'label'}>Name</div>
            <div className={'detail'}>{dishDetails.name}</div>
          </div>
          <div className={'dish-details-group'}>
            <div className={'label'}>Created At</div>
            <div className={'detail'}>{formatDate(dishDetails.createdAt)}</div>
          </div>
          <div className={'dish-details-group'}>
            <div className={'label'}>Availability</div>
            <div className={'detail'}>
              <div className={`available-tag ${dishDetails.available ? 'available' : 'not-available'}`}>
                {dishDetails.available ? 'Available' : 'Not available'}
              </div>
              <ToggleSwitch
                checked={dishDetails.available || false}
                onChange={toggleDishAvailabilityFn}
                switchLabel={''}
                disabled={toggleLoading === dishId}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={'dish-other-details'}>
        <div className={'dish-details-group'}>
          <div className={'label'}>Restaurant Id</div>
          <TableLink
            linkTo={`/restaurants/${dishDetails?.restaurant?.objectId}`}
            text={dishDetails?.restaurant?.objectId}
            customClass={'info'}
          />
        </div>
        <div className={'dish-details-group'}>
          <div className={'label'}>Restaurant Name</div>
          <div className={'detail'}>{dishDetails?.restaurant?.restaurant_name}</div>
        </div>
        <div className={'dish-details-group'}>
          <div className={'label'}>Description</div>
          <div className={'detail description'}>{dishDetails.description}</div>
        </div>
        <div className={'dish-details-group'}>
          <div className={'label'}>Category</div>
          <div className={'detail'}>{dishDetails.category}</div>
        </div>
        <div className={'dish-details-group'}>
          <div className={'label'}>Available Quantity</div>
          <div className={'detail'}>{dishDetails?.quantity ?? '-'}</div>
        </div>
        {getUvaCategory()}
        <div className={'dish-details-group'}>
          <div className={'label'}>Price</div>
          <div className={'detail'}><Currency amount={dishDetails.price} /></div>
        </div>
        <DynamicLink link={dishDetails?.dynamicLink} />
        <div className={'dish-details-group'}>
          <div className={'label'}>Selected Variants</div>
          <div className={'detail'}>{dishDetails.minSelectedVariants} - {dishDetails.maxSelectedVariants}</div>
        </div>
      </div>
    </div>
  );

  const getHeader = () => (
    <div className={'header'}>
      <div>Dish Details</div>
      <BackButton />
    </div>
  );

  const assignUvaCategory = () => {
    return (
      <>
        <div className="row justify-content-between">
          <div className="col-10">
            <div className={'section-heading'}>Assign Uva Category</div>
          </div>
          <div className="col-2 mb-2">
            <ActionButton
              active={true}
              text={'+ Assign'}
              isLoading={false}
              onClick={assignDishToCategory}
              customClass={'btn-primary w-100'}
            />
          </div>
        </div>
        {
          showUvaCategories ?
            <UvaCategoriesFlattenTree
              uvaCategoryId={selectedUvaCategoryId}
              onChange={(key, label) => { setSelectedUvaCategoryId(key) }}
            />
            :
            <ActionButton
              active={true}
              text={'Show Uva Categories'}
              isLoading={false}
              onClick={() => setShowUvaCategories(!showUvaCategories)}
              customClass={'btn-primary'}
            />
        }
      </>
    );
  };

  if (loading) return <Loading />;

  return (
    <div>
      <div className={'dish-details-main-container'}>
        {getHeader()}
        <div className={'dish-details-section'}>
          {getDishDetails()}
          <DishVariants dishDetails={dishDetails} />
          <hr />
          {assignUvaCategory()}
        </div>
      </div>
    </div>
  );
};

export { DishDetails };