import { stringifyAndParseObj } from 'utils';
import { cloudFunction } from '../parse';

export const fetchAllStripeProductsForAdmin = async () => {
  return cloudFunction('fetchAllStripeProductsForAdmin', { isAdmin: true });
};

export const createStripeProduct = async (params: any) => {
  return cloudFunction('createStripeProduct', { ...params, isAdmin: true });
};

export const fetchStripeProductBySlug = async (slug: string) => {
  const res = await cloudFunction('fetchStripeProductBySlug', { slug, isAdmin: true });
  return stringifyAndParseObj(res);
};

export const updateStripeProduct = async (params: { name?: string; enName?: string; description?: string; enDescription?: string; id: string; productType?: string }) => {
  return cloudFunction('updateStripeProduct', { ...params, isAdmin: true });
};

export const markStripeProductAsInactive = async (id: string) => {
  return cloudFunction('markStripeProductAsInactive', { id, isAdmin: true });
};

export const markStripeProductAsActive = async (id: string) => {
  return cloudFunction('markStripeProductAsActive', { id, isAdmin: true });
};