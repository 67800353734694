import RenderInfo from 'components/RenderInfo';
import RenderPaymentMethod from 'components/RenderPaymentMethod';
import SingleAddressTile from 'components/SingleAddressTile';
import React from 'react';
import DeliveryChargesData from './deliveryChargesData';
import styles from './index.module.scss';
import PerMerchantData from './perMerchantData';
import PrimeDiscountData from './primeDiscount';

const BasicDetails = (props) => {
  const { details } = props;

  const getAddress = () => {
    if (!details?.addressObject) return null;
    return (
      <div className={styles['address-container']}>
        <div className={styles['label']}>Address</div>
        <SingleAddressTile address={details?.addressObject} />
      </div>
    );
  };

  const getPaymentMethod = () => {
    if (!details?.selectedPaymentMethod) return null;
    return (
      <div>
        <div className={styles['label']}>Payment Method</div>
        <RenderPaymentMethod paymentMethod={details?.selectedPaymentMethod} />
      </div>
    );
  };

  const getDeliveryCoordsString = () => {
    if (!details?.deliveryCoords) return null;
    const { deliveryCoords: { latitude = '', longitude = '' } = {}} = details;
    return `Lat: ${latitude}, lng: ${longitude}`;
  };

  return (
    <div className={styles['basic-details-section']}>
      <div className={styles['basic-info']}>
        <div className={styles['info']}>
          <RenderInfo heading="Cart Id" value={details.objectId} />
          <RenderInfo heading="User Id" value={details.user?.objectId} link={`/users/${details.user?.objectId}`} />
          <RenderInfo heading="Status" value={details.status} />
          <RenderInfo heading="Created At" value={details.createdAt} type='date' />
          <RenderInfo heading="Updated At" value={details.updatedAt} type='date' />
          <RenderInfo heading="User has prime subscription" value={details.updatedAt} type='date' />
          <RenderInfo heading="User has prime subscription" value={details.userHasPrimeSubscription} type='boolean' />
          <RenderInfo heading="Is multi merchant cart" value={details.isMultiMerchantCart} type='boolean' />
          <RenderInfo heading="Delivery coords" value={getDeliveryCoordsString()} />
        </div>
        <div className={styles['info']}>
          
          <RenderInfo heading="Delivery Taxes" value={details.deliveryTaxes || 0} type="currency" />
          <RenderInfo heading="Taxes" value={details.itemTaxes || 0} type="currency" />
          <RenderInfo heading="Subtotals Markup" value={details.subtotalsMarkup || 0} type="currency" />
          <RenderInfo heading="Subtotals Markup Taxes" value={details.subtotalsMarkupTaxes || 0} type="currency" />
          {details.order ? <RenderInfo heading="Order" value={details.order?.objectId} link={`/orders/${details?.order?.objectId}`} /> : null}
        </div>
        <div className={styles['info']}>
          <RenderInfo heading="Discount Amount" value={details.discountAmount || 0} type="currency" />
          <RenderInfo heading="Tip" value={details.tip || 0} type="currency" />
          <RenderInfo heading="Sub Total" value={details.subtotals || 0} type="currency" />
          <RenderInfo heading="Total" value={(details.total / 100) || 0} type="currency" />
        </div>
      </div>
      <DeliveryChargesData details={details} />
      <PrimeDiscountData details={details} />
      <PerMerchantData details={details} />
      <div className={styles['section-container']}>
        <div className={styles['basic-info']}>
          {getPaymentMethod()}
          {getAddress()}
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;