import React from 'react';
import './index.scss';
import ToggleSwitch from 'components/ToggleSwitch';

const OnlineStatus = ({ isOnline, toggleOnline }: { isOnline: boolean; toggleOnline: any }) => {
  return (
    <span className="d-inline-block">
      <span className="d-flex justify-content-center align-items-center">
        <ToggleSwitch switchLabel="Driver Online" checked={isOnline} onChange={toggleOnline} customClass="text-muted font-weight-bold"/>
      </span>
    </span>
  );
};

export default OnlineStatus;
