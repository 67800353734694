import { cloudFunction } from 'services/parse';
import { getTimeDiff } from 'utils';

export const fetchCloudRequests = async(data: Record<string, any>) => {
  const response = await cloudFunction('fetchCloudRequests', data);
  response.items =  response.items.map(req=>{
    return {
      ...req,
      timeTaken: getTimeDiff(req.createdAt, req.updatedAt),
    };
  });
  return response;
};

export const getEntityTypes = () => {
  return cloudFunction('getEntityTypes', {});
};

export const getCloudRequestDetail = (id: string) => {
  return cloudFunction('getCloudRequestDetail', { id });
};