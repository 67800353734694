import ReportsFilter from 'components/ReportsFilter';
import React, { useState } from 'react';
import { discountRedemptionReport } from 'services';
import { showError } from 'utils/notifications';
import moment from 'moment';
import ExportCSVButton from 'components/ExportCSVButton';
import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import DataTable from 'react-data-table-component';
import DataTableFilter from 'components/DataTableFIlter';
import TableLink from 'TableLink';
import EmptySectionMessage from 'components/message/EmptySectionMessage';

const columns = [
  {
    name: 'Discount Code',
    selector: 'discountCode',
    sortable: false,
  },
  {
    name: 'Discount Amount',
    selector: 'discountAmount',
    sortable: true,
    cell: row => (
      `$${row.discountAmount}`
    ),
  },
  {
    name: 'Redemption Date',
    selector: 'redemptionDate',
    sortable: false,
  },
  {
    name: 'Order ID',
    selector: 'orderId',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/orders/${row.orderId}`}
        text={row.orderId}
        openInNewTab={true}
      />
    ),
  },
  {
    name: 'Order Number',
    selector: 'orderNumber',
    sortable: false,
  },
  {
    name: 'Client ID',
    selector: 'clientId',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/users/${row.clientId}`}
        text={row.clientId}
        openInNewTab={true}
      />
    ),
  },
  {
    name: 'Client Email',
    selector: 'clientEmail',
    sortable: false,
  },
  {
    name: 'Client Full Name',
    selector: 'clientFullname',
    sortable: false,
  },
  {
    name: 'Client Phone number',
    selector: 'clientPhoneNumber',
    sortable: false,
  },
];

const columnsForCSV = [
  {
    value: 'discountCode',
    label: 'Discount Code',
  },
  {
    value: 'discountAmount',
    label: 'Discount Amount',
  },
  {
    value: 'redemptionDate',
    label: 'Redemption Date',
  },
  {
    value: 'orderNumber',
    label: 'Order Number',
  },
  {
    value: 'orderId',
    label: 'Order Id',
  },
  {
    value: 'clientId',
    label: 'Client Id',
  },
  {
    value: 'clientEmail',
    label: 'Client Email',
  },
  {
    value: 'clientFullname',
    label: 'Client Full Name',
  },
  {
    value: 'clientPhoneNumber',
    label: 'Client Phone Number',
  },
];


const DiscountRedemptionReport = props => {
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null | string>(moment().subtract(1, 'days').toDate());
  const [endDate, setEndDate] = useState<Date | null | string>(moment().toDate());
  const [data, setData] = useState<Array<Record<string, any>>>([]);
  const [filterEmail, setFilterEmail] = useState<string>('');
  const [filterCode, setFilterCode] = useState<string>('');
  const [apiCalled, setAPICalled] = useState<boolean>(false);

  const fetchDiscountRedemptionReport = async () => {
    if (!apiCalled) setAPICalled(true);
    setLoading(true);
    try {
      const response = await discountRedemptionReport({ startDate, endDate });
      setData(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };
  
  const getDataTable = () => {
    const getFilteredData = () =>
      data.filter(record =>
        record.clientEmail && record.clientEmail.toLowerCase().includes(filterEmail.toLowerCase())
        && record.discountCode && record.discountCode.toLowerCase().includes(filterCode.toLowerCase()),
      );

    if (loading) return <Loading />;

    if (data.length) {
      const exportCSVButton = <ExportCSVButton data={data} columnsForCSV={columnsForCSV} fileName={'Discount Redemption Report'} />;
      const emailFilterInput = <DataTableFilter filterText={filterEmail} setFilterText={setFilterEmail} placeholder={'Filter by Client Email'} />;
      const codeFilterInput = <DataTableFilter filterText={filterCode} setFilterText={setFilterCode} placeholder={'Filter by Discount Code'} />;
      const filteredData = getFilteredData();

      return (
        <DataTable
          title="Reports"
          keyField="objectId"
          columns={columns}
          data={filteredData}
          customStyles={tableStyles}
          pagination={false}
          striped={true}
          actions={[emailFilterInput, codeFilterInput, exportCSVButton]}
        />
      );
    } else {
      return apiCalled ? <EmptySectionMessage message={'No records found'} /> : null;
    }
  };

  return (
    <div className='report-container'>
      <ReportsFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        showEmailInput={false}
        handleSubmit={fetchDiscountRedemptionReport}
      />
      {getDataTable()}
    </div>
  );
};

export default DiscountRedemptionReport;