import React from 'react';
import { mapDispatchToProps as leadActions } from 'actions/lead';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import { LeadFilter } from './components/lead-filter/LeadFilter';
import { formatDate, updatePageLimit } from 'utils';
import { ILead } from 'interfaces/lead';
import { Loading } from 'components/loading/Loading';
import qs from 'query-string';
import { tableStyles } from 'css/tableStyles';
import TableLink from 'TableLink';

const mapStateToProps = function (state: any) {
  return {
    isAuthenticated: !!state.auth.loggedInUser,
    leads: state.lead.leads,
    lead: state.lead.lead,
    leadsCount: state.lead.leadsCount,
  };
};

const columns = [
  {
    name: 'Lead ID',
    selector: 'objectId',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/lead-details/${row.objectId}`}
        text={row.objectId}
        openInNewTab={false}
      />
    ),
    width: '135px',
  },
  {
    name: 'User ID',
    selector: 'user',
    sortable: false,
    format: (row: any) => row.user && row.user.objectId,
  },
  {
    name: 'Driver Name',
    selector: 'full_name',
    sortable: false,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: false,
  },
  {
    name: 'Phone',
    selector: 'phone',
    sortable: false,
  },
  {
    name: 'City',
    selector: 'city',
    sortable: false,
  },
  {
    name: 'Total Documents',
    selector: 'totalDocuments',
    sortable: false,
  },
  {
    name: 'Last Action',
    selector: 'lastAction',
    sortable: false,
  },
  {
    name: 'Doc Uploaded At',
    selector: 'lastDocumentUploaded',
    sortable: true,
    width: '180px',
    format: (row: any) => formatDate(row.lastDocumentUploaded && row.lastDocumentUploaded.iso, 'MM/DD/YYYY HH:mm'),
  },
  {
    name: 'Created At',
    selector: 'leadCreatedAt',
    sortable: true,
    width: '180px',
    format: (row: any) => formatDate(row.leadCreatedAt && row.leadCreatedAt.iso, 'MM/DD/YYYY HH:mm'),
  },
];

let sort = { lastDocumentUploaded: 'desc' };

class Leads extends React.Component<any, any> {
  filters: ILead.ILeadFilter = {
    page: 0,
    approved: false,
  };

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      events:[],
    };
  }

  filterToQueryString = () => {
    return qs.stringify({ ...this.filters });
  };

  queryStringToFilter = (): ILead.ILeadFilter => {
    return qs.parse(this.props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  componentDidMount() {
    this.filters = { ...this.filters, ...this.queryStringToFilter() };
    this.fetchLeads();
  }

  fetchLeads = async () => {
    this.setState({ loading: true });
    await this.props.fetchLeads(this.filters, sort);
    this.setState({ loading: false });
  };

  updateQueryString = () => {
    const qs = this.filterToQueryString();
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: qs,
    });
  };

  onChangePage = (page: number) => {
    this.filters.page = page - 1;
    this.updateQueryString();
    this.fetchLeads();
  };

  onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    this.onChangePage(currentPage);
  };

  onFilterChange = (filters: any) => {
    this.filters = { ...this.filters, ...filters, page: 0  };
    this.updateQueryString();
    this.fetchLeads();
  };

  onSort = (column: Record<string, any>, sortDirection: string) => {
    sort = {
      ...sort,
      [column.selector]: sortDirection,
    };
    this.fetchLeads();
  };

  render() {
    return (
      <div>
        <LeadFilter onFilterChange={this.onFilterChange} location={ this.props.location }/>
        <DataTable
          title="Leads"
          keyField="objectId"
          columns={columns}
          data={this.props.leads}
          highlightOnHover={true}
          defaultSortField="leadCreaatedAt"
          customStyles={tableStyles}
          pagination={true}
          paginationServer={true}
          paginationDefaultPage={(this.filters.page || 0) + 1}
          paginationTotalRows={this.props.leadsCount}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onSort={this.onSort}
        />
        {this.state.loading && <Loading />}
      </div>
    );
  }
}


const LeadsWrapper = connect(mapStateToProps, leadActions)(Leads);
export { LeadsWrapper as Leads };
