import React, { useState } from 'react';
import { addressComponents } from './index';
import Header from './components';
import styles from './index.module.scss';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { IAddress } from '../../../interfaces/address';

const SearchLocation: React.FC<IAddress.ISearchLocation> = (props) => {
  const { setComponentToShow, onSelect } = props;


  const getBounds = () => {
    // @ts-ignore
    const google = window.google;
    const bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(17.9268695, -67.271492),
      new google.maps.LatLng(18.5159789, -65.5897525),
    );
    return bounds;
  };

  const onClose = () => {
    setComponentToShow(addressComponents.locationDetails);
  };

  const [address, setAddress] = useState('');

  const handleSelect = address => {
    geocodeByAddress(address)
      .then(async results => {
        const coords = await getLatLng(results[0]);
        onSelect({ coords, address: address });
      })
      .catch(error => console.error('Error!!!!!', error));
  };

  const renderSuggestions = (suggestions, getSuggestionItemProps) => {
    return suggestions.map(suggestion => {
      const className = suggestion.active
        ? `${styles['suggestion-item']} ${styles['suggestion-item-active']}`
        : styles['suggestion-item'];
      return (
        <div key={1}
          {...getSuggestionItemProps(suggestion, {
            className,
          })}
        >
          <span className={styles['suggestion-heading']}>{suggestion?.formattedSuggestion.mainText}</span>
          <span className={styles['suggestion-desc']}>{suggestion?.formattedSuggestion.secondaryText}</span>
        </div>
      );
    });
  };

  const getPlacesAutoComplete = () => {
    return(<PlacesAutocomplete
      value={address}
      onChange={(address)=>{setAddress(address)}}
      onSelect={handleSelect}
      debounce={500}
      highlightFirstSuggestion
      searchOptions={{
        bounds: getBounds(),
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div>
          <div className={`${styles['locality-name-container']} d-flex align-items-center`}>
            <i className="fa fa-location-arrow mr-2"></i>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: styles['location-search-input'],
                  
              })}
              autoFocus
            />
          </div>
          <div className={styles['autocomplete-dropdown-container']}>
            {renderSuggestions(suggestions, getSuggestionItemProps)}
          </div>
        </div>
      )}
    </PlacesAutocomplete>);
  };

  return (
    <div className="col-12">
      <Header title="Search location" onClose={onClose} />
      <span className={styles['location-label']}>Delivery Area</span>
      {getPlacesAutoComplete()}
    </div>
  );
};
export default SearchLocation;
