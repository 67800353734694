import { cloudFunction } from 'services/parse';

export const fetchFleetMerchantInvoices = (data: Record<string, any>) => {
  return cloudFunction('fetchFleetMerchantInvoices', data);
};

export const fetchFleetMerchantInvoicesDetail = (id: string) => {
  return cloudFunction('fetchFleetMerchantInvoiceDetail', { id });
};

export const updateFleetMerchantInvoice = (id: string, data: Record<string, any>) => {
  return cloudFunction('updateFleetMerchantInvoice', { id, data });
};