import ActionButton from 'components/ActionButton/ActionButton';
import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from 'react';
import { updateUserDetail } from 'services';
import { fetchDriverDeactivationReasons } from 'services/api/drivers';
import { MESSAGES, showSuccess } from 'utils';

const DeactivationReasonDropDown = (props) => {
  const { deactivationReason,onDeactivationReasonChange,loading,driver,onSuccess } =  props;
  const [reason, setReason] = useState(deactivationReason);
  const [reasons, setReasons] = useState<any>();
  const [editReason,setEditReason] = useState<boolean>(false);

  const fetchDriverDeactivateReasonsFn = async()=>{
    const response=await fetchDriverDeactivationReasons();
    setReasons(response);
  };

  
  useEffect(()=>{
    fetchDriverDeactivateReasonsFn();
  },[]);

  const updateDeactivationReason = async () => {
    try {
      if (editReason) {
        const updatedUser = await updateUserDetail(driver?.objectId, driver);
        onSuccess(updatedUser);
        showSuccess(MESSAGES.DRIVER_UPDATE_SUCCESS);
      }
      setEditReason(!editReason);
    } catch (e) {
      console.log(e);
    } 
  };

  const getOptions = () => {
    return reasons && reasons.map((reason,i) => (<option value={reason.code} key={i}>{reason.displayValue}</option>));
  };

  const getDeactivationDropdown=()=>(
    <>
      <select defaultValue={reason} value={deactivationReason} className="form-control" onChange={e=>onDeactivationReasonChange(e.target.value)}>
        <option>Select Deactivation Reason</option>
        {getOptions()}
      </select>
    </>
  );


  if (loading) {
    return (
      <div className="form-control">
        <Loading type="mini" />
      </div>
    );
  }
  return (
    <>
      {editReason ? (
        <span className="ml-3 border-left pl-3 pr-2">
          {getDeactivationDropdown()}
        </span>
      ): (
        <div className="col-12 col-sm-6 col-md-3 ml-3 border-left pl-3">
          <div className="text-muted font-weight-bold">Deactivation Reason</div>
          <div className="font-weight-bold">{deactivationReason ?? '-'}</div>
        </div>
      )}
      <span className="d-flex pl-3">
        {editReason && 
              <ActionButton text="Cancel" active={true} onClick={()=>setEditReason(false)} isLoading={false} customClass="btn-secondary mr-2"/>
        }
        <ActionButton text={`${editReason ? 'Update' : 'Edit'}`} active={true} onClick={updateDeactivationReason} isLoading={false}  customClass="btn-primary"/>
      </span>
    </>
  );
};


export default DeactivationReasonDropDown;
