import React, { useEffect, useState } from 'react';
import './index.scss';
import { DATE_FORMATS, formatDate, getPageLimit, updatePageLimit } from 'utils';
import { showError } from 'utils/notifications';
import { Loading } from 'components/loading/Loading';
import DataTable from 'react-data-table-component';
import { tableStyles } from '../../../../css/tableStyles';
import { fetchDriverActivities } from 'services/api/drivers';
import moment from 'moment';
import { DateRangePickerWrapper } from 'components/Date/DateRangePicker';
import ActionButton from 'components/ActionButton/ActionButton';

let filter = {
  endDate: moment().toISOString(),
  startDate: moment().subtract(1, 'month').toISOString(),
};

const DriverActivities = props => {
  const [activities, setActivities ] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [activityPage, setActivityPage] = useState<number>(0);
  const [startDate, setStartDate] = useState<string | null>(filter.startDate);
  const [endDate, setEndDate] = useState<string | null>(filter.endDate);
  const columns = [
    {
      name: 'Event Name',
      selector: 'eventName',
      sortable: false,
    },
    {
      name: 'Date Time',
      selector: 'createdAt',
      sortable: false,
      format: row => formatDate(row.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
    },
    {
      name: 'New Value',
      selector: 'newValue',
      sortable: false,
      format: row => typeof row.newValue === 'undefined' ? '-' :  row.newValue.toString(),
    },
  ];
  useEffect(() => {
    fetchDriverActivitiesFn();
  }, []);

  const fetchDriverActivitiesFn = async () => {
    setLoading(true);
    try {
      const response = await fetchDriverActivities({
        limit: getPageLimit(),
        page: activityPage,
        id: props.objectId,
        filter,
      });
      setActivities(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const onChangePage = (page: number) => {
    setActivityPage(page - 1);
    fetchDriverActivitiesFn();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const onDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    filter = {
      ...filter,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
    };
    setStartDate(moment(startDate).toISOString());
    setEndDate(moment(endDate).toISOString());
  };

  const getDataTable = () => {
    return (
      <div className={'col-12 data-table'}>
        <DataTable
          keyField="objectId"
          columns={columns}
          data={activities.items}
          highlightOnHover={true}
          defaultSortField="createdAt"
          customStyles={tableStyles}
          pagination={true}
          paginationServer={true}
          paginationDefaultPage={(activityPage || 0) + 1}
          paginationTotalRows={activities.total}
          progressPending={loading}
          progressComponent={<Loading type="mini"/>}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </div>
    );
  };

  return (
    <div className="driver-activities">
      <div className="row">
        <div className="col-12 activity-table">
          <div className="row">
            <div className="col-6">
              <div className="text-muted font-weight-bold">Driver Events</div>
            </div>
            <div className="col-6 text-right">
              <div className="d-inline-block">
                <DateRangePickerWrapper
                  startDate={startDate}
                  endDate={endDate}
                  onDatesChange={onDateSelectionChange}
                />
              </div>
              <ActionButton
                text="Submit"
                isLoading={loading}
                active={!!(startDate && endDate)}
                onClick={fetchDriverActivitiesFn}
                customClass="btn-primary ml-2"
              />
            </div>
          </div>
          {getDataTable()}
        </div>
      </div>
    </div>
  );
};

export default DriverActivities;
