import * as React from 'react';
import './message.scss';

class Error extends React.Component<any, any> {

  render () {
    return (
      <div className="alert alert-danger mt-2">{this.props.message || ''}</div>
    );
  }
}
export { Error };
