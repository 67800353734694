import React, { useState } from 'react';
import './index.scss';
import { copyRestaurantMenu } from 'services/api/restaurant';
import { Loading } from 'components/loading/Loading';
import { Error } from 'components/message/Error';
import RestaurantAutoSuggest from 'components/RestaurantAutoSuggest';

const CopyRestaurantMenu = (props) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [toRestaurantId, setToRestaurantId] = useState('');
  const [fromRestaurantId, setFromRestaurantId] = useState('');
  const [overrideExistingImage, setOverrideExistingImage] = useState(false);

  const copyMenu = async (e: any): Promise<void> => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!toRestaurantId || !fromRestaurantId) {
        throw 'Please select a restaurant from both the dropdowns';
      }
      setError('');
      const job = await copyRestaurantMenu({ fromRestaurantId,toRestaurantId, overrideExistingImage });
      setLoading(false);
      props.history.push(`/job-detail/${job.id}`);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  const copyStatus = () => {
    if (loading) return <Loading />;

    if (error) return <Error message={error} />;

    return <div />;
  };

  return (
    <div className='form-container'>
      <h4>Copy Restaurant Menu</h4>
      <form>
        <div className='form-group'>
          <label>From Restaurant ID</label>
          <input
            value={fromRestaurantId}
            onChange={e => setFromRestaurantId(e.target.value)}
            className={'form-control'}
          />
          <label>From Restaurant</label>
          <RestaurantAutoSuggest
            placeholder={'Search Restaurant'}
            onSuggestionSelect={suggestion=>setFromRestaurantId(suggestion.id)}
          />
        </div>
        <div className='form-group'>
          <label>To Restaurant ID</label>
          <input
            value={toRestaurantId}
            onChange={e => setToRestaurantId(e.target.value)}
            className={'form-control'}
          />
        </div>
        <div className='form-group'>
          <label>To Restaurant</label>
          <RestaurantAutoSuggest
            placeholder={'Search Restaurant'}
            onSuggestionSelect={suggestion=>setToRestaurantId(suggestion.id)}
          />
        </div>
        <div className="form-check">
          <label htmlFor="overrideExistingImage" className="form-check-label d-flex align-items-end">
            <input
              className="form-check-input"
              id="overrideExistingImage"
              type="checkbox"
              checked={overrideExistingImage}
              onChange={e => setOverrideExistingImage(e.target.checked)}
            />
            <span className={'ml-2'}>Allow overriding the  existing image?</span>
          </label>
        </div>
        {copyStatus()}
        <button
          type='submit'
          className='btn btn-primary float-right'
          onClick={copyMenu}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export { CopyRestaurantMenu };
