import React from 'react';
import { ICurrencyProps } from 'interfaces/currency';
import _ from 'lodash';

const Currency: React.FC<ICurrencyProps> = props => {
  const value = _.isNil(props.amount) ? '-' : `$${Number(props.amount).toFixed(2)}`;
  return (
    <span>
      <span className="font-weight-bolder">
        {props.negative ? '-' : ''}
        {value}
      </span>
    </span>
  );
};

export default Currency;
