import React, { useState } from 'react';
import moment from 'moment';
import { getEmailArr } from '../../../../utils/util';
import NameFilterAutosuggest from 'components/NameFilterAutoSuggest';
import { searchUserByName } from '../../../../services/api/order';
import ReportsFilter from 'components/ReportsFilter';
import Dropdown from '../../../../components/Dropdown/index';
import { GROUP_BY_OPTIONS } from 'utils';

const initialFilters = {
  startDate: moment().subtract(1, 'days').toDate(),
  endDate: moment().toDate(),
  restaurantId: '',
  driverId: '',
  groupBy: 'driver',
  sendEmail: false,
  emails: '',
};

const OrderRejectionFilters = (props) =>{
  const{ loading, onSubmit } = props;
  const [startDate, setStartDate] = useState<Date | null | string>(initialFilters?.startDate);
  const [endDate, setEndDate] = useState<Date | null | string>(initialFilters?.endDate);
  const [emails, setEmails] = useState<string>(initialFilters?.emails);
  const [restaurantId, setRestaurantId] = useState<string>(initialFilters?.restaurantId);
  const [groupBy, setGroupBy] = useState<string>(initialFilters?.groupBy);
  const [driverId, setDriverId] = useState<string>(initialFilters?.driverId);
  const [sendEmail, setSendEmail] = useState<boolean>(initialFilters?.sendEmail);

  const handleFilterSubmit = () =>{
    onSubmit && onSubmit(
      {
        startDate, 
        endDate, 
        emails: getEmailArr(emails), 
        restaurantId, 
        sendEmail,
        driverId,
        groupBy,
      },
    );
    return {};
  };

  const handleReset = () =>{
    setStartDate(initialFilters?.startDate);
    setEndDate(initialFilters?.endDate);
    setEmails(initialFilters?.emails);
    setRestaurantId(initialFilters?.restaurantId);
    setGroupBy(initialFilters?.groupBy);
    setDriverId(initialFilters?.driverId);
    setSendEmail(initialFilters?.sendEmail);
  };

  return(
    <>
      <div className='row mr-2 ml-2'>
        <div className='input-container mb-2 col pr-2 pl-1'>
          <input
            className='form-control mr-0'
            placeholder={'Driver ID'}
            value={driverId}
            onChange={e => setDriverId(e.target.value)}
            required={true}
          />
        </div>
        <div className='input-container mb-2 col pr-2 pl-1'>
          <NameFilterAutosuggest
            entityId={driverId}
            placeholder={'Driver Name'}
            onSuggestionSelected={setDriverId}
            fetchSuggestions={event => searchUserByName(event, true)}
          />
        </div>
        <div className='input-container mb-2 col pr-2 pl-1'>
          <Dropdown
            options={GROUP_BY_OPTIONS}
            onChange={setGroupBy}
            value={groupBy}
            name={'Group By'}
            disabled={loading}
          />
        </div>
      </div>
      <div className='ml-3'>
        <ReportsFilter
          btnText={'Send'}
          loading={!groupBy}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          emails={emails}
          setEmails={setEmails}
          restaurantId={restaurantId}
          setRestaurantId={setRestaurantId}
          sendEmail={sendEmail}
          setSendEmail={setSendEmail}
          handleSubmit={handleFilterSubmit}
          showEmailInput={true}
          onReset={handleReset}
        />
      </div>
    </>
  );
};

export default OrderRejectionFilters;