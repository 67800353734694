import React, { useEffect, useState } from 'react';
import './index.scss';
import ActionButton from 'components/ActionButton/ActionButton';
import {
  createRefund,
  getFleetRefundReasons,
  getRefundReasons,
} from 'services/api/order';
import { Error } from 'components/message/Error';
import { REFUND_TYPES, isFleetOrder } from 'utils';
import SinglePlateItem from './SinglePlateItem';
import RadioInput from 'components/RadioInput';

type RefundPlateType = {
  id: string;
  dishDescriptionId: string;
};

const Refund = (props) => {
  const { order, closeModal, onRefundSuccess } = props;
  const [selectedRefundType, setSelectedRefundType] = useState('');
  const [selectedRefundReason, setSelectedRefundReason] = useState('');
  const [refundDeliveryChecked, setRefundDeliveryChecked] = useState(false);
  const [comments, setComments] = useState('');
  const [refundPlateList, setRefundPlateList] = useState<
    RefundPlateType[] | []
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [refundReasons, setRefundReasons] = useState<Record<string, any>>({});
  const [partialRefundAmount, setPartialRefundAmount] = useState<string>('');
  const fleetOrder = order.isFleetOrder;

  useEffect(() => {
    (async () => {
      if (fleetOrder) {
        const response = await getFleetRefundReasons();
        setRefundReasons({ full: response });
      } else {
        const response = await getRefundReasons();
        setRefundReasons(response);
      }
    })();
  }, []);

  const createRefundFn = async () => {
    let type;
    switch (selectedRefundType) {
    case REFUND_TYPES.full:
      type = REFUND_TYPES.FULL;
      break;
    case REFUND_TYPES.partial:
      type = REFUND_TYPES.PARTIAL;
      break;
    case REFUND_TYPES.byItem:
      type = REFUND_TYPES.BY_ITEM;
      break;
    }

    let data: Record<string, any> = {
      orderId: order.objectId,
      shouldRefundDelivery: refundDeliveryChecked,
      type,
      description: comments,
    };

    if (selectedRefundType !== REFUND_TYPES.byItem) {
      if (selectedRefundType === REFUND_TYPES.partial) {
        data = { ...data, amount: parseFloat(partialRefundAmount) };
      }
      data = { ...data, reason: selectedRefundReason };
    } else {
      data = { ...data, items: refundPlateList };
    }

    setLoading(true);
    try {
      await createRefund(data);
      onRefundSuccess();
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const getRefundCommentSection = () => {
    return (
      <div className="refund-comment-section">
        <div>Comments</div>
        <textarea
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
      </div>
    );
  };

  const getRefundDeliverySection = () => {
    return (
      <div className="refund-delivery-section">
        <div>Refund Delivery</div>
        <input
          type="checkbox"
          checked={refundDeliveryChecked}
          onChange={(e) => setRefundDeliveryChecked(e.target.checked)}
        />
      </div>
    );
  };

  const getPartialRefundAmountSection = () => {
    return (
      <div className="refund-amount-section">
        <div>Refund Amount</div>
        <input
          className="form-control"
          type="number"
          value={partialRefundAmount}
          onChange={(e) => setPartialRefundAmount(e.target.value)}
        />
      </div>
    );
  };

  const getRefundReasonOptions = () => {
    return refundReasons[selectedRefundType]?.map((reason, i) => {
      return (
        <option key={i} value={reason.key}>
          {reason.value}
        </option>
      );
    });
  };

  const getRefundReasonSection = () => {
    if (!selectedRefundType) return null;

    return (
      <div className="refund-reason-section">
        <label>Reason</label>
        <select
          className="form-control"
          value={selectedRefundReason || ''}
          onChange={(e) => setSelectedRefundReason(e.target.value)}
        >
          <option hidden={!!selectedRefundReason} value={'default'}>
            Select an option
          </option>
          {getRefundReasonOptions()}
        </select>
      </div>
    );
  };

  const getItemChoiceContainer = () => {
    const getDishes = () => {
      return order.dishes?.map((dish) => {
        return dish.dishesDescription?.map((description, i) => {
          return (
            <SinglePlateItem
              key={description.id}
              index={i}
              dish={dish}
              description={description}
              refundPlateList={refundPlateList}
              setRefundPlateList={setRefundPlateList}
              getRefundReasonOptions={getRefundReasonOptions}
            />
          );
        });
      });
    };

    return (
      <div className="item-select-section">
        <div>Plates</div>
        <div className="item-choice-container">
          <div className="choice-header">
            <div>Plates</div>
            <div>Total</div>
            <div>Select</div>
          </div>
          {getDishes()}
        </div>
      </div>
    );
  };

  const getRefundTypeSection = () => {
    return (
      <div className="refund-type-section">
        <div>Refund Type</div>
        <div className="refund-types-container">
          <RadioInput
            value={'full'}
            name={'refund-type'}
            label={'Full Refund'}
            description={`Return the full amount (${order.total / 100})`}
            setSelectedOption={setSelectedRefundType}
          />
          {!fleetOrder && (
            <RadioInput
              value={'partial'}
              name={'refund-type'}
              label={'Partial Refund'}
              description={'Return a partial amount'}
              setSelectedOption={setSelectedRefundType}
            />
          )}
          {!fleetOrder && (
            <RadioInput
              value={'byItem'}
              name={'refund-type'}
              label={'Per Item'}
              description={`Return a specific item`}
              setSelectedOption={setSelectedRefundType}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="refund-main-container">
      <div className="refund-dialog-box custom-scrollbar">
        <div className="box-header">
          <div>Refund Payment</div>
          <i className="fa fa-close" onClick={closeModal} />
        </div>
        <div className="box-body">
          {getRefundTypeSection()}
          {selectedRefundType === REFUND_TYPES.byItem &&
            getItemChoiceContainer()}
          {selectedRefundType !== REFUND_TYPES.byItem &&
            getRefundReasonSection()}
          {selectedRefundType === REFUND_TYPES.partial &&
            getPartialRefundAmountSection()}
          {!fleetOrder && getRefundDeliverySection()}
          {getRefundCommentSection()}
        </div>
        {error && <Error message={error} />}
        <div className="box-footer">
          <ActionButton
            text="Cancel"
            active={true}
            isLoading={false}
            onClick={closeModal}
          />
          <ActionButton
            text="Refund"
            active={true}
            isLoading={loading}
            customClass="btn-primary"
            onClick={createRefundFn}
          />
        </div>
      </div>
    </div>
  );
};

export default Refund;
