import React, { useState } from 'react';
import ReportsFilter from 'components/ReportsFilter';
import { sendRestaurantPaymentSummary } from 'services/api/reports';
import moment from 'moment';
import { showError } from 'utils/notifications';
import './index.scss';
import { Loading } from 'components/loading/Loading';
import DataTable from 'react-data-table-component';
import _ from 'lodash';
import DataTableFilter from 'components/DataTableFIlter';
import ExportCSVButton from 'components/ExportCSVButton';
import { RESTAURANT_PAYMENT_REPORT_TYPE, getEmailArr } from 'utils';
import { tableStyles } from 'css/tableStyles';

const depositSummaryColumns = [
  {
    name: 'Restaurant ID',
    selector: 'objectId',
    sortable: false,
    width: '150px',
  },
  {
    name: 'Restaurant Name',
    selector: 'restaurant_name',
    sortable: false,
  },
  {
    name: 'Subtotal',
    selector: 'subtotal',
    sortable: false,
  },
  {
    name: 'Taxes',
    selector: 'foodTaxes',
    sortable: false,
  },
  {
    name: 'Delivery Charge',
    selector: 'deliveryCharge',
    sortable: false,
  },
  {
    name: 'Total Refund',
    selector: 'totalRefund',
    sortable: false,
  },
  {
    name: 'Rev Share',
    selector: 'revShare',
    sortable: false,
  },
  {
    name: 'Deposit',
    selector: 'deposit',
    sortable: false,
  },
];

const bankStatementColumns = [
  {
    name: 'Restaurant ID',
    selector: 'objectId',
    sortable: false,
    width: '150px',
  },
  {
    name: 'Restaurant Name',
    selector: 'restaurant_name',
    sortable: false,
  },
  {
    name: 'Bank Account Number',
    selector: 'bankAccountNo',
    sortable: false,
  },
  {
    name: 'Routing Number',
    selector: 'routingNo',
    sortable: false,
  },
  {
    name: 'Email',
    selector: 'contact_email',
    sortable: false,
  },
  {
    name: 'Deposit',
    selector: 'deposit',
    sortable: false,
  },
];

const bankStatementColumnsForCSV = [
  {
    label: 'Statement ID',
    value: 'objectId',
  },
  {
    label: 'Restaurant Name',
    value: 'restaurant_name',
  },
  {
    label: 'Bank Account Number',
    value: 'bankAccountNo',
  },
  {
    label: 'Routing Number',
    value: 'routingNo',
  },
  {
    label: 'Email',
    value: 'contact_email',
  },
  {
    label: 'Deposit',
    value: 'deposit',
  },
];

const depositSummaryColumnsForCSV = [
  {
    label: 'Deposit ID',
    value: 'objectId',
  },
  {
    label: 'Restaurant Name',
    value: 'restaurant_name',
  },
  {
    label: 'Deposit',
    value: 'deposit',
  },
  {
    label: 'Rev Share',
    value: 'revShare',
  },
  {
    label: 'Delivery Charge',
    value: 'deliveryCharge',
  },
  {
    label: 'Food Taxes',
    value: 'foodTaxes',
  },
  {
    label: 'Refunds',
    value: 'refunds',
  },
  {
    label: 'Total Refund',
    value: 'totalRefund',
  },
  {
    label: 'Tax',
    value: 'tax',
  },
  {
    label: 'Subtotal',
    value: 'subtotal',
  },
];

const PaymentSummaryReport = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null | string>(moment().subtract(1, 'days').toDate());
  const [endDate, setEndDate] = useState<Date | null | string>(moment().toDate());
  const [emails, setEmails] = useState<string>('');
  const [restaurantId, setRestaurantId] = useState<string>('');
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<string>('depositSummary');
  const [data, setData] = useState<Record<string, any>>({});
  const [filterText, setFilterText] = React.useState('');

  const sendPaymentSummary = async () => {
    setLoading(true);
    try {
      const response = await sendRestaurantPaymentSummary(startDate, endDate, getEmailArr(emails),restaurantId, sendEmail);
      setData(response);
    } catch (e) {
      showError(e);
    } finally{
      setLoading(false);
    }
  };

  const getColumnsForTable = () => selectedReport === RESTAURANT_PAYMENT_REPORT_TYPE.DEPOSIT_SUMMARY ? depositSummaryColumns : bankStatementColumns;
  // eslint-disable-next-line max-len
  const getColumnsForCSV = () => selectedReport === RESTAURANT_PAYMENT_REPORT_TYPE.DEPOSIT_SUMMARY ? depositSummaryColumnsForCSV : bankStatementColumnsForCSV;
  const getCSVFileName = () => selectedReport === RESTAURANT_PAYMENT_REPORT_TYPE.DEPOSIT_SUMMARY ? 'Deposit Summary' : 'Restaurant Payment Summary';

  const getDataTable = () => {
    if (loading) return <Loading />;

    if (!_.isEmpty(data)) {
      const exportCSVButton = <ExportCSVButton data={data[selectedReport]} columnsForCSV={getColumnsForCSV()} fileName={getCSVFileName()} />;
      const dataFilterInput = <DataTableFilter filterText={filterText} setFilterText={setFilterText} placeholder={'Filter by Restaurant Name'} />;
      // eslint-disable-next-line max-len
      const filteredData = data[selectedReport].filter(item => item.restaurant_name && item.restaurant_name.toLowerCase().includes(filterText.toLowerCase()));

      return (
        <DataTable
          title="Reports"
          keyField="objectId"
          columns={getColumnsForTable()}
          data={filteredData}
          customStyles={tableStyles}
          pagination={false}
          striped={true}
          actions={[dataFilterInput, exportCSVButton]}
        />
      );
    }

    return <div />;
  };

  const getTableSelectionTab = () => {
    return (
      <div className='selection-tab'>
        <div
          className={`tab ${selectedReport !== RESTAURANT_PAYMENT_REPORT_TYPE.DEPOSIT_SUMMARY && `not-selected`}`}
          onClick={() => setSelectedReport(RESTAURANT_PAYMENT_REPORT_TYPE.DEPOSIT_SUMMARY)}
        >
          Deposit Summary
        </div>
        <div
          className={`tab ${selectedReport !== RESTAURANT_PAYMENT_REPORT_TYPE.RESTAURANT_BANK_STATEMENT && `not-selected`}`}
          onClick={() => setSelectedReport(RESTAURANT_PAYMENT_REPORT_TYPE.RESTAURANT_BANK_STATEMENT)}
        >
          Restaurant Bank Statement
        </div>
      </div>
    );
  };

  return (
    <div className='report-container'>
      <ReportsFilter
        btnText={'Send'}
        loading={loading}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        emails={emails}
        setEmails={setEmails}
        restaurantId={restaurantId}
        setRestaurantId={setRestaurantId}
        sendEmail={sendEmail}
        setSendEmail={setSendEmail}
        handleSubmit={sendPaymentSummary}
        showEmailInput={true}
      />
      {getTableSelectionTab()}
      {getDataTable()}
    </div>
  );
};

export default PaymentSummaryReport;
