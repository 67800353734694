import React from 'react';
import { DAYS_NUMBER_TO_NAME } from 'utils';
import SingleSlot from './single-slot';
import _ from 'lodash';

const SingleDay = props => {
  const {
    day,
    restaurantHours,
    setRestaurantHours,
  } = props;
  const [dayKey] = Object.keys(day);
  const [daySlots]: Record<string, any>[] = Object.values(day);
  
  const normalizeTimeString = (timeString: string) => {
    let hour = timeString.slice(0, timeString.indexOf(':'));
    const minutes = timeString.slice(timeString.indexOf(':') + 1);
    if (hour.length === 1) hour = `0${hour}`;
    return `${hour}${minutes}`;
  };
  
  const handleTimeChange = (value: string, slotIndex: number,  type: string) => {
    const time = normalizeTimeString(value);
    setRestaurantHours(
      _.mapValues(restaurantHours, (slots, key) => {
        let dayTimings = _.cloneDeep(slots);
        if (dayKey === key) {
          dayTimings = dayTimings.map((singleSlot, i) => {
            if (i === slotIndex) {
              if (type === 'start') {
                singleSlot = { start: time, end: singleSlot.end };
              } else {
                singleSlot = { start: singleSlot.start, end: time };
              }
            }
            return singleSlot;
          });
        }
        return dayTimings;
      }),
    );
  };

  const deleteSlot = (slotToDelete: number) => {
    setRestaurantHours(
      _.mapValues(restaurantHours, (slots, key) => {
        let timings = _.cloneDeep(slots);
        if (dayKey === key) {
          if (timings.length === 1) {
            timings = [{ start: '', end: '' }];
          } else {
            timings = timings.filter((timing, i) => i !== slotToDelete);
          }
        }
        return timings;
      }),
    );
  };
  
  const addSlot = () => {
    setRestaurantHours(
      _.mapValues(restaurantHours, (slots, key) => {
        const dayTimings = _.cloneDeep(slots);
        if (dayKey === key) {
          dayTimings.push({ start: '', end: '' });
        }
        return dayTimings;
      }),
    );
  };
  
  return (
    <div className={'restaurant-timing-single-day'}>
      <div className={'day-info'}>{DAYS_NUMBER_TO_NAME[dayKey]}</div>
      <div className={'slots-container'}>
        {daySlots.map((slot, i) => {
          return (
            <SingleSlot
              key={i}
              slot={slot}
              deleteSlot={index => deleteSlot(index)}
              slotIndex={i}
              handleChange={(val: string, type: string) => handleTimeChange(val, i, type)}
            />
          );
        })}
        <div className={'add-timing-slot'} onClick={addSlot}>
          Add Slot
        </div>
      </div>
    </div>
  );
};

export default SingleDay;