import React from 'react';
import { showSuccess } from 'utils/notifications';
import './index.scss';

const CopyToClipBoard = props => {
  const { copyText, successMessage } = props;
  const copySuccessMessage = successMessage || 'Copied to Clipboard';

  const copyToClipboard = () => {
    try {
      const ta = document.createElement('textarea');
      ta.innerText = copyText;
      document.body.appendChild(ta);
      ta.select();
      document.execCommand('copy');
      ta.remove();
      showSuccess(copySuccessMessage);
    } catch(e) {
      console.log(e);
    }
  };

  return (
    <i
      className={'fa fa-files-o copy-clipboard-icon'}
      onClick={copyToClipboard}
    />
  );
};

export default CopyToClipBoard;