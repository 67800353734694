import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import qs from 'query-string';
import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import { showError } from 'utils/notifications';
import { fetchAllRoutes } from 'services/api/routes';
import { DATE_FORMATS, convertSecondsToMinutesHours, formatDate, getPageLimit, metersToKm, updatePageLimit } from 'utils';
import './Routes.scss';
import TableLink from 'TableLink';
import RoutesFilter from './routes-filter';
import moment from 'moment';

let filters: { [p: string]: any; page: number } = {
  page: 0,
  endDate: moment().toString(),
  startDate: moment().subtract(1, 'month').toString(),
};

let sort = {};

const columns = [
  {
    name: 'Route ID',
    selector: 'objectId',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/routes/${row.objectId}`}
        text={row.objectId}
        openInNewTab={false}
      />
    ),
  },
  {
    name: 'Created at',
    selector: 'createdAt',
    format: row => formatDate(row.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
    sortable: true,
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: false,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
  },
  {
    name: 'Distance',
    selector: 'distance',
    format: row => `${metersToKm(row.distance)} ${row.distance > 999 ? 'km' : 'm'}`,
    sortable: false,
  },
  {
    name: 'Transportation Time',
    selector: 'transportTime',
    format: row => convertSecondsToMinutesHours(row.transportTime),
    sortable: false,
  },
  {
    name: 'Total Earning',
    selector: 'totalEarning',
    sortable: false,
  },
];

const Routes = props => {
  const [routes, setRoutes] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);

  const fetchRoutesFn = async () => {
    setLoading(true);
    const { page, ...rest } = filters;
    try {
      const response = await fetchAllRoutes({
        page,
        limit: getPageLimit(),
        filter: { ...rest },
        sort,
      });
      // setRoutes(response);
      setRoutes(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  useEffect(() => {
    filters = {
      ...filters,
      ...queryStringToFilter(),
    };
    fetchRoutesFn();
  }, []);

  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchRoutesFn();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const onFilterChanged = (udpatedfilters: Record<string, any>) => {
    filters = { ...udpatedfilters, page: 0 };
    updateQueryString();
    fetchRoutesFn();
  };

  const onSort = (column: Record<string, any>, sortDirection: string) => {
    sort = {
      ...sort,
      [column.selector]: sortDirection,
    };
    fetchRoutesFn();
  };

  return (
    <div>
      {loading && <Loading />}
      <RoutesFilter
        {...props}
        onFilterSubmit={onFilterChanged}
      />
      <DataTable
        title={'Route'}
        keyField={'objectId'}
        columns={columns}
        data={routes.items}
        highlightOnHover={true}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationDefaultPage={(filters.page || 0) + 1}
        paginationTotalRows={routes.total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onSort={onSort}
      />
    </div>
  );
};


export { Routes };
