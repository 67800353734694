import React, { useEffect, useState } from 'react';
import {
  assignOrderToDriver,
  fetchAdminOrderDetail,
  fetchRefundsForOrder,
  getOrderUpdates, markOrderAsDelivered, markOrderFinished,
  moveDeferredOrderToOrder,
  searchUserByName,
  unAssignOrder,
  updateBonus,
} from 'services/api/order';
import './single-order.scss';
import { Loading } from 'components/loading/Loading';
import ActionButton from 'components/ActionButton/ActionButton';
import { DATE_FORMATS, ORDER_STATUS, formatDate, fromStripeAmount, getItemsCount, metersToMile, secondsToMinute } from 'utils';
import _ from 'lodash';
import Currency from 'components/Currency';
import JSONViewer from 'components/JSONViewer';
import NameFilterAutosuggest from 'components/NameFilterAutoSuggest';
import RefundDetails from './RefundDetails';
import BackButton from 'components/BackButton';
import AddToll from './AddToll/index';
import { showError, showSuccess, showWarning } from 'utils/notifications';
import { Link, withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { tableStyles } from 'css/tableStyles';
import OrderNoteModal from './AddNote';
import ResendReceiptModal from './ResendReceiptModal';
import AddUserCommunicaitonModal from './Add User Communication';
import RatingComponent from 'components/RatingComponent';
import Refund from 'pages/refund/create-refund';
import TableLink from 'TableLink';
import AssignDriverModal from './AssignDriverModal';
import PseudoLink from 'components/PseudoLink';
import EntityMiniCard from 'components/EntityMiniCard';
import MultiMerchantOrder from './MultiMerchantOrder';
import NoteSection from './NoteSection';
import TransactionInfo from './TransactionInfo';
import ClientDetails from './ClientDetails';
import { isFleetOrder } from '../../../utils/util';
import NotAcceptedByDrivers from './NotAcceptedByDrivers';
import DriverRejections from './DriverRejections';
import ListItems from 'pages/user-carts/details/listItems';

const SingleOrder = props => {
  const [loading, setLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState<Record<string, any>>({});
  const [refundModalOpen, setRefundModalOpen] = useState<boolean>(false);
  const [orderUpdates, setOrderUpdates] = useState<Record<string, any>[] | null>(null);
  const [updatesLoading, setUpdatesLoading] = useState<boolean>(false);
  const [refundDetails, setRefundDetails] = useState<Array<Record<string, any>[]>>([]);
  const [refundDetailsLoading, setRefundDetailsLoading] = useState<boolean>(false);
  const [driverId, setDriverId] = useState<string>('');
  const [assignLoading, setAssignLoading] = useState<boolean>(false);
  const [editBonus, setEditBonus] = useState<boolean>(false);
  const [bonusUpdating, setBonusUpdating] = useState<boolean>(false);
  const [distanceBonus, setDistanceBonus] = useState<string>('');
  const [merchantDistanceBonus, setMerchantDistanceBonus] = useState<string>('');
  const [rejectionBonus, setRejectionBonus] = useState<string>('');
  const [uvaBonus, setUvaBonus] = useState<string>('');
  const [addTollModalOpen, setAddTollModalOpen] = useState<boolean>(false);
  const [tollLoading, setTollLoading] = useState<boolean>(false);
  const [orderActionLoading, setOrderActionLoading] = useState<boolean>(false);
  const [markFinishedLoading, setMarkFinishedLoading] = useState<boolean>(false);
  const [markDeliveredLoading, setMarkDeliveredLoading] = useState<boolean>(false);
  const [orderNoteModalOpen, setOrderNoteModalOpen] = useState<boolean>(false);
  const [note, setNote] = useState<Record<string, any> | null>(null);
  const [resendReceiptModalOpen, setResendReceiptModalOpen] = useState<boolean>(false);
  const [addUserCommunicationModalOpen, setAddUserCommunicaitonModalOpen] = useState<boolean>(false);
  const [assignDriverModalOpen, setAssignDriverModalOpen] = useState<boolean>(false);
  const { orderId } = props.match.params;

  const fetchAdminOrderDetailFn = async () => {
    try {
      const orderDetails = await fetchAdminOrderDetail(orderId);
      setOrderDetails(orderDetails);
      if (orderDetails.bonusData) {
        const { bonus, distanceBonus, rejectionBonus } = orderDetails.bonusData;
        setUvaBonus(bonus || '0');
        setDistanceBonus(distanceBonus || '0');
        setRejectionBonus(rejectionBonus || '0');
      }
      if (orderDetails?.driver) {
        setDriverId(orderDetails?.driver.objectId);
      }
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchOrderUpdates = async () => {
    setUpdatesLoading(true);
    try {
      const orderUpdates = await getOrderUpdates(orderId);
      setOrderUpdates(orderUpdates);
    } catch (e) {
      showError(e);
    } finally {
      setUpdatesLoading(false);
    }
  };

  const fetchRefundDetails = async () => {
    setRefundDetailsLoading(true);
    try {
      const refundDetails = await fetchRefundsForOrder(orderId);
      setRefundDetails(refundDetails);
    } catch (e) {
      showError(e);
    } finally {
      setRefundDetailsLoading(false);
    }
  };

  useEffect(() => {
    if (props.orderDetails) {
      setOrderDetails(props.orderDetails);
      setLoading(false);
    } else {
      fetchRefundDetails();
      fetchAdminOrderDetailFn();
    }
  }, []);

  const refundSuccessful = async() => {
    setRefundModalOpen(false);
    await fetchAdminOrderDetailFn();
    fetchRefundDetails();
  };

  const onTollAdded = () => {
    setAddTollModalOpen(false);
    setTollLoading(true);
    fetchAdminOrderDetailFn();
    setTollLoading(false);
  };

  const removeDriverFromOrder = async () => {
    if (driverId) {
      setAssignLoading(true);
      try {
        await unAssignOrder(orderDetails.objectId, driverId);
        await fetchAdminOrderDetailFn();
        setDriverId('');
        showSuccess('Driver removed successfully');
      } catch (e) {
        showError(e);
      } finally {
        setAssignLoading(false);
      }
    }
  };

  const assignOrderToDriverFn = async () => {
    if (driverId) {
      setAssignLoading(true);
      try {
        await assignOrderToDriver({
          orderId,
          driverId,
        });
        await fetchAdminOrderDetailFn();
        showSuccess('Driver assigned successfully');
      } catch (e) {
        showError(e);
      } finally {
        setAssignLoading(false);
      }
    }
  };

  const updateBonusFn = async () => {
    const bonusDataObj = {
      bonus: parseFloat(uvaBonus),
      distanceBonus: parseFloat(distanceBonus),
      rejectionBonus: parseFloat(rejectionBonus),
      merchantDistanceBonus: parseFloat(merchantDistanceBonus),
    };
    setBonusUpdating(true);
    try {
      await updateBonus(orderDetails.objectId, bonusDataObj);
    } catch (e) {
      showError(e);
    } finally {
      setBonusUpdating(false);
      setEditBonus(false);
    }
  };

  if (loading && !orderDetails?.objectId) return <Loading />;

  const getOrderUpdatesSection = () => {
    if (updatesLoading) return <Loading type='mini' />;

    if (orderUpdates === null) {
      return (
        <div className={'my-3'}>
          <PseudoLink text={'Show Order Updates'} onClick={fetchOrderUpdates} />
        </div>
      );
    }

    if (!!orderUpdates?.length) {
      return (
        <div className={'order-updates-section'}>
          <div className={'label'}>Order Updates</div>
          {orderUpdates.map((update, i) => {
            return (
              <div key={i} className={'single-update'}>
                <JSONViewer data={update} rootVisible={false} />
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const getTollDetailsSection = () => {
    const getTollData = () => {
      if (tollLoading) return <Loading type='mini' />;

      return orderDetails.tollData.map(toll => {
        return (
          <div key={toll.id} className={'toll-detail'}>
            <div>{toll.tollName}</div>
            <div className={'amount'}><Currency amount={toll.approvedAmount} />/<Currency amount={toll.requestedAmount} /></div>
          </div>
        );
      });
    };

    return (
      <div className={'toll-section'}>
        <div className={'header'}>
          <div className={'label'}>Toll Section</div>
          <ActionButton
            text={'Add Toll'}
            active={true}
            isLoading={false}
            customClass={'btn-primary'}
            onClick={() => setAddTollModalOpen(true)}
          />
        </div>
        {orderDetails.tollData
          ? getTollData()
          : <div className={'label'}>No Toll Requested</div>
        }
      </div>
    );
  };

  const getDriverDetailLink = (driver) => {
    const text = _.get(driver, 'full_name', _.get(driver, 'email'));
    return (
      <div>
        <div className={'mr-2'}>{text}</div>
        <Link to={`/drivers/${driver.objectId}`}>{driver.objectId}</Link>
      </div>
    );
  };

  const getDriverDetailsSection = () => {
    return (
      <div className={'driver-details-section'}>
        <div className={'driver-details-section-header'}>
          <div className={'label'}>Driver Details</div>
          <ActionButton
            text={'Remove'}
            active={true}
            isLoading={assignLoading}
            customClass={'btn btn-remove btn-min-width'}
            onClick={removeDriverFromOrder}
          />
        </div>
        <div className={'driver-details-fields-container'}>
          <EntityMiniCard
            entityInfo={{
              name: orderDetails.driver?.full_name,
              createdAt: orderDetails.driver?.createdAt,
              icon: 'fa fa-car',
              type: 'Driver',
              objectId: orderDetails.driver?.objectId,
            }}
          />
          <div className={'driver-phone-and-earnings-container'}>
            <div className={'label'}>Phone Number</div>
            <div className={'driver-phone'}>{orderDetails.driver.phone}</div>
            <div className={'label'}>Driver Earnings</div>
            <div className={'driver-earnings'}><Currency amount={orderDetails.earnings} /></div>
          </div>
        </div>
      </div>
    );
  };

  const getAssignDriverView = () => {
    const handleAssign = () => {
      if (orderDetails.status === ORDER_STATUS.DELIVERED) {
        setAssignDriverModalOpen(true);
      } else {
        assignOrderToDriverFn();
      }
    };

    return (
      <div className={'assign-driver-section'}>
        <div className={'label'}>Driver Details</div>
        <div className={'section'}>
          <NameFilterAutosuggest
            entityId={driverId}
            placeholder={'Driver Name'}
            onSuggestionSelected={setDriverId}
            onlyDriver={true}
            fetchSuggestions={searchUserByName}
          />
          <ActionButton
            text={'Assign'}
            active={!!driverId?.length}
            isLoading={assignLoading}
            customClass={'btn-primary btn-min-width'}
            onClick={handleAssign}
          />
        </div>
      </div>
    );
  };

  const getRouteInformationSection = () => {
    return (
      <div className={'route-information-container'}>
        <div className={'label'}>Route Information</div>
        <div className={'distance-section'}>
          <div>Distance</div>
          <div>{orderDetails.distance ? metersToMile(orderDetails.distance) + ' miles' : 'N/A'}</div>
        </div>
        <div className={'duration-section'}>
          <div>Duration</div>
          <div>{orderDetails.duration ? secondsToMinute(orderDetails.duration) + ' minutes' : 'N/A'}</div>
        </div>
      </div>
    );
  };

  const getDeliveryChargesSection = () => {
    return (
      <div className={'route-information-container'}>
        <div className={'label'}>Delivery Charges</div>
        <div className={'distance-section'}>
          <div>Delivery Charge</div>
          <div className={'amount'}><Currency amount={parseFloat(orderDetails.deliveryCharges || '0')} /></div>
        </div>
      </div>
    );
  };

  const getBonusEarningsSection = () => {
    const handleBonusPrimaryAction = () => {
      if (editBonus) {
        updateBonusFn();
      } else {
        setEditBonus(true);
      }
    };

    return orderDetails.bonusData &&
      <div className={'bonus-earnings-section'}>
        <div className={'d-flex justify-content-between align-items-center mb-3'}>
          <div className={'label'}>Bonus Earnings</div>
          {!props.isDeferredOrder && (
            <div className={'buttons-container'}>
              <ActionButton
                text={editBonus ? 'Save' : 'Edit'}
                active={true}
                isLoading={bonusUpdating}
                customClass={'btn-primary'}
                onClick={handleBonusPrimaryAction}
              />
              {
                editBonus && <ActionButton
                  text={'Cancel'}
                  active={!bonusUpdating}
                  isLoading={false}
                  customClass={'btn-primary ml-3'}
                  onClick={() => setEditBonus(false)}
                />
              }
            </div>
          )}
        </div>
        <div className={'single-bonus-section'}>
          <div>Merchant Distance Bonus</div>
          {
            editBonus
              ? <input
                type={'number'}
                className={'form-control'}
                placeholder={'Merchant Distance Bonus'}
                disabled={bonusUpdating}
                value={merchantDistanceBonus || ''}
                onChange={e => setMerchantDistanceBonus(e.target.value)}
              />
              : <div className={'amount'}><Currency amount={parseFloat(merchantDistanceBonus || '0')} /></div>
          }
        </div>
        <div className={'single-bonus-section'}>
          <div>Distance Bonus</div>
          {
            editBonus
              ? <input
                type={'number'}
                className={'form-control'}
                placeholder={'Distance Bonus'}
                disabled={bonusUpdating}
                value={distanceBonus || ''}
                onChange={e => setDistanceBonus(e.target.value)}
              />
              : <div className={'amount'}><Currency amount={parseFloat(distanceBonus || '0')} /></div>
          }
        </div>
        <div className={'single-bonus-section'}>
          <div>Uva! Bonus</div>
          {
            editBonus
              ? <input
                type={'number'}
                className={'form-control'}
                placeholder={'Uva! Bonus'}
                disabled={bonusUpdating}
                value={uvaBonus || ''}
                onChange={e => setUvaBonus(e.target.value)}
              />
              : <div className={'amount'}><Currency amount={parseFloat(uvaBonus || '0')} /></div>
          }
        </div>
        <div className={'single-bonus-section'}>
          <div>Rejection Bonus</div>
          {
            editBonus
              ? <input
                type={'number'}
                className={'form-control'}
                placeholder={'Rejection Bonus'}
                disabled={bonusUpdating}
                value={rejectionBonus || ''}
                onChange={e => setRejectionBonus(e.target.value)}
              />
              : <div className={'amount'}><Currency amount={parseFloat(rejectionBonus || '0')} /></div>
          }
        </div>
      </div>;
  };

  const getOrderReviewDetailsSection = () => (
    <div className={'review-details-section'}>
      <div className={'label'}>Order Review</div>
      <div className={'single-review'}>
        <div className={'d-flex'}>
          <img src={require('assets/heart.svg')} />
          <div className={'reviewee-details'}>
            <div className={'reviewee-type'}>Uva</div>
            <div className={'review-text'}>
              {orderDetails.review.uva.text}
            </div>
          </div>
        </div>
        <div className={'review-rating'}>
          <RatingComponent rating={orderDetails.review.uva.star} />
        </div>
      </div>
      <div className={'single-review'}>
        <div className={'d-flex'}>
          <img src={require('assets/heart.svg')} />
          <div className={'reviewee-details'}>
            <div className={'reviewee-type'}>Service</div>
            <div className={'review-text'}>
              {orderDetails.review.service.text}
            </div>
          </div>
        </div>
        <div className={'review-rating'}>
          <RatingComponent rating={orderDetails.review.service.star} />
        </div>
      </div>
      <div className={'single-review'}>
        <div className={'d-flex'}>
          <img src={require('assets/car.svg')} />
          <div className={'reviewee-details'}>
            <div className={'reviewee-name'}>{orderDetails.driver?.full_name}</div>
            <div className={'reviewee-type'}>Driver</div>
            <div className={'review-text'}>
              {orderDetails.review.driver.text}
            </div>
          </div>
        </div>
        <div className={'review-rating'}>
          <RatingComponent rating={orderDetails.review.driver.star} />
        </div>
      </div>
      <div className={'single-review'}>
        <div className={'d-flex'}>
          <img src={require('assets/cutlery.svg')} />
          <div className={'reviewee-details'}>
            <div className={'reviewee-name'}>{orderDetails.restaurant?.restaurant_name}</div>
            <div className={'reviewee-type'}>Restaurant</div>
            <div className={'review-text'}>
              {orderDetails.review.restaurant.text}
            </div>
          </div>
        </div>
        <div className={'review-rating'}>
          <RatingComponent rating={orderDetails.review.restaurant.star} />
        </div>
      </div>
    </div>
  );

  const getOrderPlateDetailsSection = () => {
    const getVariantsDescription = (variants: Record<string, any>[]) => {
      return (
        <div className={'variants'}>
          <div className={'variants-label'}>Variants</div>
          {variants.map(variant => (
            <div key={variant.id} className={'variant'}>
              <div className={`name`}>
                {variant.name}
              </div>
              <div className={'amount'}>
                <Currency amount={variant.price} />
              </div>
            </div>
          ))}
        </div>
      );
    };

    const getDishNote = (note: string) => {
      return (
        <div className={'notes'}>
          <div className={'notes-label'}>Notes</div>
          <div className={'note'}>{note}</div>
        </div>
      );
    };

    const getDishDetails = (dishDetails: Record<string, any>, dishCount: number) => {
      const dishArr: Record<string, any>[] = [];
      if (!dishDetails.dishesDescription) {
        return dishArr;
      }
      for (let i = 0; i < dishCount; i++) {
        const plateDescription = dishDetails.dishesDescription[i];
        plateDescription && dishArr.push(
          <div key={dishDetails.id} className={'single-plate'}>
            <div className={'basic-plate-details'}>
              <div className={'dish-count'}>1x</div>
              <div className={`name`}>
                {dishDetails.name}
              </div>
              <div className={'dish-price'}><Currency amount={dishDetails.price} /></div>
            </div>
            {dishDetails.dishesDescription ? (
              <div className={'dish-desc'}>
                {!!plateDescription.specs?.length && getVariantsDescription(plateDescription.specs)}
                {!!plateDescription.notes && getDishNote(plateDescription.notes)}
              </div>
            ) : null}
          </div>,
        );
      }
      return dishArr;
    };

    const getPlateDetails = () => {
      return orderDetails.dishes.map(dish => getDishDetails(dish, dish.count));
    };

    const getTotalBreakdownSection = () => {
      const getTotalBreakdowns = () => {
        const data = [
          {
            label: 'Subtotals',
            value: orderDetails.subtotals,
          },
          {
            label: 'Subtotals Markup',
            value: orderDetails.subtotalsMarkup,
          },
          {
            label: 'Delivery Fee',
            value: orderDetails.deliveryCharges,
          },
          {
            label: 'Delivery Tax',
            value: orderDetails.taxData?.deliveryTaxes?.total,
          },
          {
            label: 'Discount',
            value: orderDetails.discountAmount,
          },
          {
            label: 'Tips',
            value: orderDetails.tip,
          },
          {
            label: 'Food Taxes',
            value: orderDetails.taxData?.foodTaxes?.total,
          },
          {
            label: 'Markup Taxes',
            value: orderDetails.taxData?.markupTaxes?.total,
          },
        ];
        return data.map(charge => (
          <div key={charge.label} className={'delivery-fee-section'}>
            <div>{charge.label}</div>
            <div className={'amount'}>
              {(charge.label === 'Discount' && charge.value > 0) && (<span>{`- `}</span>)}
              <Currency amount={charge.value || 0} />
            </div>
          </div>
        ));
      };

      return (
        <div className={'total-breakdown-section'}>
          {getTotalBreakdowns()}
          <div className={'footnote-and-promo-section'}>
            {orderDetails.deliveryNotes &&
              <>
                <div className={'footnote-label'}>Footnotes</div>
                <div className={'footnote'}>{orderDetails.deliveryNotes}</div>
              </>
            }
            <div className={'promo-code-label'}>Promo Code Added</div>
            <div className={'promo-code'}>{orderDetails.discountCode || 'N/A'}</div>
          </div>
          <div className={'total-section'}>
            <div>Total</div>
            <div className={'amount'}><Currency amount={fromStripeAmount(orderDetails.total)} /></div>
          </div>
        </div>
      );
    };

    return (
      <div className={'order-details-section'}>
        <ListItems items={orderDetails.dishes} title="Dish Details" />
        {getTotalBreakdownSection()}
      </div>
    );
  };


  const getBasicOrderDetailsSection = () => {
    return (
      <div className={'basic-order-details'}>
        <div className={'detail'}>
          <div className={'label'}>Order No</div>
          <div className={'info'}>#{orderDetails.orderNumber}</div>
        </div>
        <div className={'detail'}>
          <div className={'label'}>Order ID</div>
          <div className={'info'}>{orderDetails.objectId}</div>
          {orderDetails?.parentOrder && (
            <div>
              <div className={'label'}>Parent Order ID</div>
              <a href={`/orders/${orderDetails.parentOrder.objectId}`}>{orderDetails.parentOrder.objectId}</a>
            </div>
          )}
        </div>
        <div className={'detail'}>
          <div className={'label'}>Restaurant Details</div>
          <div className={'info'}>
            <TableLink
              linkTo={`/restaurants/${orderDetails.restaurant?.objectId}`}
              text={orderDetails.restaurant?.objectId}
              openInNewTab={true}
              customClass={'info'}
            />
          </div>
          <div className={'info theme-orange'}>{orderDetails.restaurant?.restaurant_name}</div>
        </div>
        <div className={'detail'}>
          <div className={'label'}>Order Date</div>
          <div className={'info'}>{formatDate(orderDetails.createdAt, DATE_FORMATS.MMM_DD_YYYY)}</div>
        </div>
        <div className={'detail'}>
          <div className={'label'}>Time</div>
          <div className={'info'}>{formatDate(orderDetails.createdAt, DATE_FORMATS.H_MM_P)}</div>
        </div>
        <div className={'detail'}>
          <div className={'label'}>Is Fleet Order</div>
          <div className={'info'}>{orderDetails.isFleetOrder ? 'Yes' : 'No'}</div>
        </div>
        <div className={'status-section'}>
          <div className={'status-label delivery'}>{orderDetails.delivery ? 'Delivery' : 'PickUp'}</div>
          <div className={`status-label ${orderDetails.status?.toLowerCase()}`}>{orderDetails.status}</div>
          {!!orderDetails.fleetRoute ? (
            <span className={'fleet-indicator'}>Fleet</span>
          ) : null}
        </div>
      </div>
    );
  };

  const handleOrderAction = async () => {
    if (!props.isDeferredOrder) {
      if (orderDetails.refundStatus === 'REFUNDED') {
        showWarning('Order is already REFUNDED');
        return;
      } else {
        setRefundModalOpen(!refundModalOpen);
      }
    } else {
      setOrderActionLoading(true);
      try {
        const response = await moveDeferredOrderToOrder(orderDetails.objectId);
        props.history.replace(`/orders/${response}`);
      } catch (e) {
        showError(e.toString());
        setOrderActionLoading(false);
      }
    }
  };

  const markOrderAsFinished = async () => {
    setMarkFinishedLoading(true);
    const data = {
      orderId: orderDetails.objectId,
      restaurantId: orderDetails.restaurant?.objectId,
    };
    try {
      await markOrderFinished(data);
    } catch (e) {
      showError(e);
    } finally {
      setMarkFinishedLoading(false);
      fetchAdminOrderDetailFn();
    }
  };

  const markOrderAsDeliveredFn = async () => {
    setMarkDeliveredLoading(true);
    const data = {
      orderId: orderDetails.objectId,
    };
    try {
      await markOrderAsDelivered(data);
    } catch (e) {
      showError(e);
    } finally {
      setMarkDeliveredLoading(false);
      fetchAdminOrderDetailFn();
    }
  };

  const getHeader = () => (
    <div>
      <BackButton />
      <div className={'header'}>
        <h4>Order Detail</h4>
        <div className={'buttons-container'}>
          <ActionButton
            text={props.isDeferredOrder ? 'Move To Order' : 'Refund'}
            active={!orderActionLoading}
            isLoading={orderActionLoading}
            onClick={handleOrderAction}
            customClass='btn-primary ml-auto refund'
          />
          <ActionButton
            text={'Resend Receipt'}
            active={true}
            isLoading={false}
            onClick={() => setResendReceiptModalOpen(true)}
            customClass='btn-primary receipt'
          />
          <ActionButton
            text="Send Communication"
            active={true}
            isLoading={false}
            onClick={() => setAddUserCommunicaitonModalOpen(true)}
            customClass="btn-primary user-comm-button"
          />
          {orderDetails?.status === ORDER_STATUS.FINISHED ?
            null
            :
            <ActionButton
              text="Mark As Finished"
              active={!markFinishedLoading}
              isLoading={markFinishedLoading}
              onClick={markOrderAsFinished}
              customClass="btn-primary finished"
            />}
          {orderDetails?.status === ORDER_STATUS.DELIVERED ?
            null
            :
            <ActionButton
              text="Mark As Delivered"
              active={!markDeliveredLoading}
              isLoading={markDeliveredLoading}
              onClick={markOrderAsDeliveredFn}
              customClass="btn-primary finished"
            />}
          <ActionButton
            text="Cloud Request"
            active={true}
            isLoading={false}
            onClick={() => props.history.push(`/cloud-requests?entityId=${orderDetails.objectId}`)}
            customClass="btn-primary finished"
          />
        </div>
      </div>
    </div>
  );

  if (orderDetails?.isMultiMerchantParentOrder) {
    return <MultiMerchantOrder orderId={orderDetails?.objectId} orderDetails={orderDetails} />;
  }

  return (
    <>
      {(loading && orderDetails?.objectId) ? <Loading /> : null}
      {
        addTollModalOpen
        && <AddToll
          orderId={orderDetails.objectId}
          closeModal={() => setAddTollModalOpen(false)}
          onSuccess={onTollAdded}
        />
      }
      {
        orderNoteModalOpen && (
          <OrderNoteModal
            show={orderNoteModalOpen}
            onClose={() => {
              setNote(null);
              setOrderNoteModalOpen(false);
            }}
            orderId={orderDetails.objectId}
            note={note}
            onSuccess={notes => setOrderDetails({ ...orderDetails, notes })}
          />
        )
      }
      {
        resendReceiptModalOpen && (
          <ResendReceiptModal
            modalOpen={resendReceiptModalOpen}
            onCloseModal={() => setResendReceiptModalOpen(false)}
            orderId={orderDetails.objectId}
          />
        )
      }
      {
        refundModalOpen
        && <Refund
          order={orderDetails}
          onRefundSuccess={refundSuccessful}
          closeModal={() => setRefundModalOpen(false)}
        />
      }
      {
        addUserCommunicationModalOpen && (
          <AddUserCommunicaitonModal
            orderId={orderDetails.objectId}
            show={addUserCommunicationModalOpen}
            onClose={() => setAddUserCommunicaitonModalOpen(false)}
          />
        )
      }
      {
        assignDriverModalOpen && (
          <AssignDriverModal
            show={assignDriverModalOpen}
            orderId={orderId}
            driverId={driverId}
            onClose={() => setAssignDriverModalOpen(false)}
            onSuccess={fetchAdminOrderDetailFn}
          />
        )
      }
      <div className={`single-order-container ${refundModalOpen || addTollModalOpen ? 'fixed' : ''}`}>
        {getHeader()}
        <div className={'order-details-container'}>
          {getBasicOrderDetailsSection()}
          <ClientDetails
            user={orderDetails?.user}
            deliveryAddress={orderDetails?.deliveryAddress}
            itemCount={(orderDetails.dishes || orderDetails.dishes?.length) ? getItemsCount(orderDetails.dishes) + ' item' : 'No details available'}
            contactPhone={orderDetails?.contactPhone}
            contactName={orderDetails?.contactName}
          />
          {getOrderPlateDetailsSection()}
          {orderDetails.review && getOrderReviewDetailsSection()}
          {getBonusEarningsSection()}
          {getRouteInformationSection()}
          {getDeliveryChargesSection()}
          {!props.isDeferredOrder && (orderDetails.driver ? getDriverDetailsSection() : getAssignDriverView())}
          <NotAcceptedByDrivers orderDetails={orderDetails} />
          <DriverRejections orderDetails={orderDetails} setLoading={setLoading} fetchAdminOrderDetailFn={fetchAdminOrderDetailFn} />
          {!props.isDeferredOrder && getTollDetailsSection()}
          <TransactionInfo
            orderId={orderDetails?.objectId}
            isDeferredOrder={props.isDeferredOrder}
            parentOrderId={orderDetails?.parentOrder?.objectId}
            orderStatus={orderDetails?.status}
            isParentOrder={orderDetails?.isMultiMerchantParentOrder}
          />
          <NoteSection
            orderDetails={orderDetails}
            setOrderDetails={setOrderDetails}
            note={note}
            setNote={setNote}
            setOrderNoteModalOpen={setOrderNoteModalOpen}
            orderNoteModalOpen={orderNoteModalOpen}
          />
          {!props.isDeferredOrder && (
            <RefundDetails loading={refundDetailsLoading} refundDetails={refundDetails} />
          )}
          {!props.isDeferredOrder && getOrderUpdatesSection()}
        </div>
      </div>
    </>
  );
};

const SingleOrderWithRouter = withRouter(SingleOrder);
export { SingleOrderWithRouter };
