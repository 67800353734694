import * as React from 'react';
import { useState } from 'react';
// import { sendRestaurantPaymentSummary } from 'services/api/payment';
import { Error } from 'components/message/Error';
import { Success } from 'components/message/Success';
import { DateRangePickerWrapper } from 'components/Date/DateRangePicker';
import moment from 'moment';

const PaymentSummary = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState<Date | null | string>( moment().subtract(7, 'days').toDate());
  const [endDate, setEndDate] = useState<Date | null | string>(moment().toDate());
  const [success, setSuccess] = useState(false);

  const sendPaymentSummary = async  () => {
    try {
      setLoading(true);
      setSuccess(false);
      // await sendRestaurantPaymentSummary(startDate, endDate);
      setLoading(false);
      setSuccess(true);
    } catch (e) {
      setError(e);
      setLoading(false);
      setSuccess(false);
    }
  };

  const onDateSelectionChange = ({ startDate, endDate }: {startDate: Date; endDate: Date}) => {
    startDate && setStartDate(moment(startDate).toDate());
    endDate && setEndDate(moment(endDate).toDate());
    setLoading(false);
  };

  return (
    <div className='ml-2'>
      <strong>Select the date range</strong>
      <div className="row m-2">
        <DateRangePickerWrapper
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onDateSelectionChange} // PropTypes.func.isRequired,
        />
      </div>
      <div className="row m-2">
        <button type="button" className="btn btn-primary float-right" onClick={sendPaymentSummary}>
          { loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : '' }
          { loading ? 'Sending...' : 'Send' }
        </button>
      </div>
      <div className="row">
        { error && <Error message={error}/> }
      </div>
      <div className="row">
        { success && <Success message="successfully sent the payment summary report"/> }
      </div>
    </div>
  );
};

export { PaymentSummary };
