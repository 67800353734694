import RatingComponent from 'components/RatingComponent/index';
import React from 'react';
import './index.scss';

const ReviewCard = props => {
  const {
    objectId,
    user,
    driver: driverInfo,
    restaurant: restaurantInfo,
    review: {
      uva,
      restaurant,
      driver,
      service,
    },
  } = props.details;

  return (
    <div className={'main-container'}>
      <div className={'d-flex mb-4'}>
        <div className={'info-group'}>
          <div className={'label'}>Driver</div>
          <a
            target={'blank'}
            href={`/drivers/${driverInfo?.objectId}`}
            className={'id'}
          >
            {driverInfo?.objectId}
          </a>
          <div className={'name'}>{driverInfo?.full_name || 'N/A'}</div>
        </div>
        <div className={'info-group'}>
          <div className={'label'}>User</div>
          <a
            target={'blank'}
            href={`/users/${user?.objectId}`}
            className={'id'}
          >
            {user.objectId}
          </a>
          <div className={'name'}>{user?.full_name || 'N/A'}</div>
        </div>
        <div className={'info-group'}>
          <div className={'label'}>Restaurant</div>
          <div className={'theme-orange'}>{restaurantInfo?.objectId}</div>
          <div className={'name'}>{restaurantInfo?.restaurant_name || 'N/A'}</div>
        </div>
        <div className={'info-group'}>
          <div className={'label'}>Order</div>
          <a
            target={'blank'}
            href={`/orders/${objectId}`}
            className={'id'}
          >
            {objectId || 'N/A'}
          </a>
        </div>
      </div>
      <div className={'rating-container'}>
        <div className={'rating-group'}>
          <div className={'label'}>Uva Review</div>
          <RatingComponent
            rating={uva?.star || 0}
          />
          <div className={'rating-text'}>{uva?.text}</div>
        </div>
        <div className={'rating-group'}>
          <div className={'label'}>Service Review</div>
          <RatingComponent
            rating={service?.star || 0}
          />
          <div className={'rating-text'}>{service?.text}</div>
        </div>
        <div className={'rating-group'}>
          <div className={'label'}>Restaurant Review</div>
          <RatingComponent
            rating={restaurant?.star || 0}
          />
          <div className={'rating-text'}>{restaurant?.text}</div>
        </div>
        <div className={'rating-group'}>
          <div className={'label'}>Driver Review</div>
          <RatingComponent
            rating={driver?.star || 0}
          />
          <div className={'rating-text'}>{driver?.text}</div>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;