import React, { useEffect, useState } from 'react';
import './activity-log.scss';
import { fetchActivityLogs } from 'services';
import { ILead } from 'interfaces';
import { AVAILABLE_DOCUMENT_LIST, LEAD_ACTIVITIES_LIST, OTHER_DOCUMENT_TYPE } from 'utils';
import { Loading } from 'components/loading/Loading';
import ViewDate from 'components/Date/ViewDate';
import _ from 'lodash';

const ActivityLog: React.FC<ILead.IActivityLogProps> = props => {
  const [loading, setLoading] = useState(true);
  const [activityLogs, setActivityLogs] = useState<ILead.IActivityLogType[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchActivityLogs(props.leadId);
        setActivityLogs(response);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const getMessageForDocument = (leadDocType: string) => {
    const docType =  AVAILABLE_DOCUMENT_LIST.filter(document => document.code === leadDocType);
    return _.get(docType, '0.displayName', OTHER_DOCUMENT_TYPE.displayName);
  };

  const getLogContent = (log: Record<string, any>) => {
    switch (log.eventName) {
    case LEAD_ACTIVITIES_LIST.CREATED:
      return <span>Lead Created.</span>;
    case LEAD_ACTIVITIES_LIST.WELCOME_EMAIL_SENT:
      return <span>Interested email sent to lead.</span>;
    case LEAD_ACTIVITIES_LIST.ASSIGNED_TO_AGENT:
      return <>Lead assigned to <span>{log.assignedTo?.full_name}</span> by <span>{log.performedBy?.full_name}</span>.</>;
    case LEAD_ACTIVITIES_LIST.CONTACTED:
      return <>Lead contacted by <span>{log.performedBy?.full_name}</span>.</>;
    case LEAD_ACTIVITIES_LIST.SIGNED_UP_AS_USER:
      return <span>Lead signed up on driver app.</span>;
    case LEAD_ACTIVITIES_LIST.DOCUMENT_UPLOADED:
      return <>
        <span>{getMessageForDocument(log.documentCode)}</span> was uploaded by <span>{log.performedBy?.full_name}</span>.
      </>;
    case LEAD_ACTIVITIES_LIST.DOCUMENT_APPROVED:
      return <>
        <span>{getMessageForDocument(log.documentCode)}</span> was approved by <span>{log.performedBy?.full_name}</span>.
      </>;
    case LEAD_ACTIVITIES_LIST.DOCUMENT_UN_APPROVED:
      return <>
        <span>{getMessageForDocument(log.documentCode)}</span> was unapproved by <span>{log.performedBy?.full_name}</span>.
      </>;
    case LEAD_ACTIVITIES_LIST.PROMOTED_TO_DRIVER  :
      return <>Lead was promoted to driver by <span>{log.performedBy?.full_name}</span>.</>;
    case LEAD_ACTIVITIES_LIST.MARKED_AS_INTERESTED  :
      return <>Lead was marked as interested by <span>{log.performedBy?.full_name}</span>.</>;
    case LEAD_ACTIVITIES_LIST.MARKED_AS_NOT_INTERESTED  :
      return <>Lead was marked as not interested by <span>{log.performedBy?.full_name}</span>.</>;
    case LEAD_ACTIVITIES_LIST.MARKED_AS_DECLINED  :
      return <>Lead was marked as declined by <span>{log.performedBy?.full_name}</span>.</>;
    case LEAD_ACTIVITIES_LIST.MARKED_AS_NOT_DECLINED  :
      return <>Lead was marked as not declined by <span>{log.performedBy?.full_name}</span>.</>;
    default: return '';
    }
  };

  const renderLogs = () => {
    if (loading) return <div className='loading-container'><Loading type='mini' /></div>;

    if (!activityLogs.length) return <div className='empty-logs-text'>No activities yet</div>;

    return activityLogs.map((log, i) => {
      return (
        <div key={i} className='single-log'>
          <div className='log-content'>{getLogContent(log)}</div>
          <ViewDate customClass={'log-time'} date={log.createdAt} />
        </div>
      );
    });
  };

  return (
    <div className='activity-log-container custom-scrollbar'>
      <div className='heading-container'>
        <h4 className='font-weight-bold'>Activity Log</h4>
      </div>
      <div className='logs-container'>
        {renderLogs()}
      </div>
    </div>
  );
};

export default ActivityLog;
