import ActionButton from 'components/ActionButton/ActionButton';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Error } from 'components/message/Error';
import { createAutomaticMerchantCollection, getAutomaticMerchantCollectionDetail, updateAutomaticMerchantCollection } from 'services/api/automaticMerchantCollection';
import { showError, showSuccess } from 'utils/notifications';
import { Loading } from 'components/loading/Loading';
import JSONInput from 'react-json-editor-ajrm';

const validationSchema = yup.object().shape({
  title: yup.string().required(),
  cloudFunctionName: yup.string().required('Cloud Function is a required field'),
});

type FormData = {
  title: string;
  cloudFunctionName: string;
  cloudFunctionParams: string;
};

const CreateAutomaticMerchantCollection = props => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [cloudFunctionParams, setCloudFunctionParams] = useState({});
  const [cloudFunctionParamError, setCloudFunctionParamsError] = useState('');
  const [collection, setCollection] = useState<Record<string, any>>({});
  const {
    register,
    handleSubmit,
    errors,
  } = useForm<FormData>({ resolver: yupResolver(validationSchema) });
  const { action,collectionId } = props.match.params;
  const isEdit = action === 'edit';

  const fetchCollectionDetails = async () => {
    setFetchLoading(true);
    try {
      const response = (await getAutomaticMerchantCollectionDetail(collectionId)).toJSON();
      setCollection({
        ...response,
        cloudFunctionParams: JSON.stringify(response.cloudFunctionParams),
      });
      setActive(response.active);
      setCloudFunctionParams(response.cloudFunctionParams);
    } catch (e) {
      showError(e);
    } finally {
      setFetchLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      fetchCollectionDetails();
    }
  }, []);

  const getPayload = (formData: FormData) => {
    const {
      cloudFunctionName,
      title,
    } = formData;
    return {
      active,
      title,
      cloudFunctionName,
      cloudFunctionParams,
    };
  };

  const createAutomaticMerchantCollectionFn = handleSubmit(async (values) => {
    try {
      const data = getPayload(values);
      if (!data?.cloudFunctionParams) {
        setCloudFunctionParamsError('Please enter cloud function params');
        return;
      }
      setLoading(true);
      const response = await createAutomaticMerchantCollection(data);
      showSuccess('Collection created successfully');
      props.history.replace(`/automatic-merchant-collection/${response.id}`);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  });

  const editAutomaticMerchantCollectionFn = handleSubmit(async (values) => {
    try {
      const data = { ...getPayload(values), id: collectionId };
      if (!data?.cloudFunctionParams) {
        setCloudFunctionParamsError('Please enter cloud function params');
        return;
      }
      setLoading(true);
      await updateAutomaticMerchantCollection(data);
      showSuccess('Collection details updated successfully');
      props.history.goBack();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  });
  
  const handleCancel = () => {
    if (isEdit) {
      props.history.replace({
        pathname: `/automatic-merchant-collection/${collectionId}`,
        search: props.location.search,
      });
    } else {
      props.history.goBack();
    }
  };
  
  const getFooter = () => (
    <div className={styles['footer']}>
      <ActionButton
        text={'Cancel'}
        active={!loading}
        isLoading={false}
        onClick={handleCancel}
        customClass={'btn btn-outline-secondary mr-3'}
      />
      <ActionButton
        text={`${isEdit ? 'Save' : 'Create'}`}
        active={!loading}
        isLoading={loading}
        onClick={isEdit ? editAutomaticMerchantCollectionFn : createAutomaticMerchantCollectionFn}
        customClass={'btn btn-primary mr-3'}
      />
    </div>
  );
  
  const getInputsSection = () => (
    <div className={styles['collection-inputs-section']}>
      <div className={styles['active']}>
        <input
          type={'checkbox'}
          checked={active}
          disabled={loading}
          onChange={e => setActive(e.target.checked)}
        />
        <div className={styles['label']}>Active</div>
      </div>
      <div className={styles['title']}>
        <div className={styles['label']}>Title</div>
        <input
          name={'title'}
          ref={register}
          className={'form-control'}
          disabled={loading}
          defaultValue={collection.title}
        />
        {errors.title && <Error message={errors.title.message} />}
      </div>
      <div className={styles['cloud-function']}>
        <div className={styles['label']}>Cloud Function</div>
        <input
          name={'cloudFunctionName'}
          ref={register}
          className={'form-control'}
          disabled={loading}
          defaultValue={collection.cloudFunctionName}
        />
        {errors.cloudFunctionName && <Error message={errors.cloudFunctionName.message} />}
      </div>
      <div className={styles['params']}>
        <div className={styles['label']}>Params</div>
        <JSONInput
          placeholder={cloudFunctionParams}
          height='500px'
          width='100%'
          viewOnly={loading}
          waitAfterKeyPress={3000}
          onChange={e => setCloudFunctionParams(e?.jsObject)}
        />
        {cloudFunctionParamError && <Error message={cloudFunctionParamError} />}
      </div>
    </div>
  );
  
  const getHeader = () => (
    <div className={styles['header']}>
      <div className={styles['heading']}>{`${isEdit ? 'Edit' : 'Create'} Collection`}</div>
    </div>
  );
  
  if (fetchLoading) return <Loading />;
  
  return (
    <div className={styles['create-collection-container']}>
      {getHeader()}
      {getInputsSection()}
      {getFooter()}
    </div>
  );
};

export { CreateAutomaticMerchantCollection };