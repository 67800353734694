import ActionButton from 'components/ActionButton/ActionButton';
import React, { useState } from 'react';
import { createDishVariant, saveDishVariants } from 'services/api/dishes';
import { showError, showSuccess } from 'utils';
import './index.scss';

const AddDishVariantModalFooter = props => {
  const {
    setVariantLevels,
    variantLevels,
    setIsAddFormVisible,
    isAddFormVisible,
    newVariantDetails: { name = '', price = 0, minCount = 0, maxCount = 0, maxSelectedVariants = 0, minSelectedVariants = 0, objectId: variantObjectId = '' } = {},
    setVariantsArray,
    dishDetails,
    setNewVariantDetails,
    detailsFormInitialValues,
  } = props;

  const [saveVariantLoading, setSaveVariantLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const onClickBack = () => {
    const newVariantLevels = variantLevels;
    newVariantLevels?.pop();
    setVariantLevels([...newVariantLevels]);
  };

  const onClickCancel = () => {
    setIsAddFormVisible(false);
    setNewVariantDetails(detailsFormInitialValues);
  };

  const onClickSecondaryButton = () => {
    if (isAddFormVisible) {
      onClickCancel();
    } else {
      onClickBack();
    }
  };
  
  const onClickAddNewVariant = () => {
    setIsAddFormVisible(true);
  };

  const validateVariantDetails =  () => {
    const errors = {} as any;
    if (maxCount < 1) {
      errors.maxCount = 'Max Count should be greater than 0';
    }
    setErrors(errors);
    return errors;
  };

  const onClickSave = async() => {
    const dataToSend = {
      dishId: dishDetails?.objectId,
      name: name,
      price: price,
      minCount: parseInt(minCount),
      maxCount: parseInt(maxCount),
      minSelectedVariants: parseInt(minSelectedVariants),
      maxSelectedVariants: parseInt(maxSelectedVariants),
      restaurantId: dishDetails?.restaurant?.objectId,
      parentId: variantLevels?.length === 1 ? '' : variantLevels[variantLevels?.length - 1],
      responseAsHierarchy: true,
      available: true,
    };
    
    try {
      const errors = validateVariantDetails();
      if (Object.keys(errors)?.length) {
        return;
      }
      setSaveVariantLoading(true);
      const response = await createDishVariant(dataToSend);
      showSuccess('Variant added successfully');
      setVariantsArray(response);
      setIsAddFormVisible(false);
      setNewVariantDetails(detailsFormInitialValues);
    } catch (e) {
      showError(e);
    } finally {
      setSaveVariantLoading(false);
    }
  };

  const onClickEdit = async() => {
    const dataToSend = {
      dishId: dishDetails?.objectId,
      variants: [{ 'name': name,
        'price': price,
        'minCount': parseInt(minCount),
        'maxCount': parseInt(maxCount),
        'minSelectedVariants': parseInt(minSelectedVariants),
        'maxSelectedVariants': parseInt(maxSelectedVariants),
        'objectId': variantObjectId }],
      responseAsHierarchy: true,
    };
    try {
      const errors = validateVariantDetails();
      if (Object.keys(errors)?.length) {
        return;
      }
      setSaveVariantLoading(true);
      const response = await saveDishVariants(dataToSend);
      showSuccess('Variant updated successfully');
      console.log(response, 'response');
      
      setVariantsArray(response);
      setIsAddFormVisible(false);
      setNewVariantDetails(detailsFormInitialValues);
    } catch (e) {
      showError(e);
    } finally {
      setSaveVariantLoading(false);
    }
  };

  const getAddNewVariantButton = () => {
    return (
      <ActionButton
        text={`Add New Variant`}
        active={true}
        isLoading={false}
        onClick={onClickAddNewVariant}
        customClass={'btn-primary add'}
      />
    );
  };

  const getSaveButton = () => {
    return (
      <ActionButton
        text={'Save'}
        active={name && !saveVariantLoading}
        isLoading={saveVariantLoading}
        onClick={variantObjectId ? onClickEdit : onClickSave}
        customClass={'btn-primary save'}
      />
    );
  };

  const getErrors = () => {
    return (
      <div className='error'>{Object.keys(errors)?.map((error) => {
        return errors[error];
      })}</div>
    );
  };

  return (
    <div className='footer-container'>
      {getErrors()}
      <div className={'add-variant-modal-footer'}>
        {(variantLevels?.length > 1 || isAddFormVisible) ? <div className='cancel' onClick={onClickSecondaryButton}>{isAddFormVisible ? 'Cancel' : 'Back'}</div> : null}
        {isAddFormVisible ? getSaveButton() : getAddNewVariantButton()}
      </div>
    </div>
  );
};

export default AddDishVariantModalFooter;