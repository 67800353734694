import ActionButton from 'components/ActionButton/ActionButton';
import SampleCSVInfo from 'components/SampleCSVInfo';
import ScrapperPlatformDropdown from 'components/ScrapperPlatformDropdown';
import React, { useState } from 'react';
import { createScrapper } from 'services';
import { SCRAPPER_PLATFORM } from 'utils';
import { showError } from 'utils/notifications';
import './index.scss';
import RestaurantAutoSuggest from 'components/RestaurantAutoSuggest';


const CreateScrapper = props => {
  const [name, setName] = useState<string>('');
  const [vendorId, setVendorId] = useState<string>('');
  const [url, setURL] = useState<string>('');
  const [platform, setPlatform] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  
  const createScrapperFn = async () => {
    setLoading(true);
    try {
      const response = await createScrapper({ name, vendorId, url, platform });
      props.history.push(`/scrappers/${response.id}`);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className={'main-container'}>
      <div className={'header'}>
        <h4>Create Scrapper</h4>
      </div>
      <div className={'inputs-container'}>
        <div className={'input-group'}>
          <div className={'label'}>Name</div>
          <input
            className={'form-control'}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div className="d-flex mb-2">
          <div className={'mr-2'}>
            <div className={'label'}>Restaurant Id</div>
            <input
              value={vendorId}
              onChange={e =>setVendorId(e.target.value)}
              className={'form-control'}
            />
          </div>
          <div>
            <div className={'label'}>Restaurant</div>
            <RestaurantAutoSuggest
              placeholder={'Search Restaurant'}
              onSuggestionSelect={suggestion=>setVendorId(suggestion.id)}
            />
          </div>
        </div>
        <div className={'input-group'}>
          <div className={'label'}>Scrapping Site URL</div>
          <input
            className={'form-control'}
            onChange={e => setURL(e.target.value)}
          />
        </div>
        <div className={'input-group'}>
          <div className={'label'}>Platform</div>
          <ScrapperPlatformDropdown value={platform} onChange={setPlatform} />
          {platform === SCRAPPER_PLATFORM.CSV && (
            <div className={'mt-3 mb-n5'}>
              <SampleCSVInfo />
            </div>
          )}
        </div>
      </div>
      <div className={'create-section'}>
        <ActionButton
          active={true}
          isLoading={false}
          onClick={() => props.history.goBack()}
          text={'Cancel'}
          customClass={'btn-outline-secondary mr-3'}
        />
        <ActionButton
          active={!loading}
          isLoading={loading}
          onClick={createScrapperFn}
          text={'Create'}
          customClass={'btn-primary'}
        />
      </div>
    </div>
  );
};

export { CreateScrapper };