import React from 'react';
import { Loading } from '../loading/Loading';
import { IActionButton } from 'interfaces/actionButton';
import './action-button.scss';

const ActionButton = (props: IActionButton) => {
  const { text, onClick, isLoading, active, customClass, type } = props;
  return (
    <>
      <button
        type={ type || 'submit'}
        disabled={!active || isLoading}
        className={`action-btn btn ${active ? 'btn-active' : 'btn-disabled'} ${customClass || ''}`}
        onClick={e => {
          e.preventDefault();
          onClick();
        }}
      >
        {isLoading ? <Loading type='mini' /> : text}
      </button>
    </>
  );
};

export default ActionButton;
