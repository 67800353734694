import RenderInfo from 'components/RenderInfo';
import React from 'react';
import { DATE_FORMATS, formatDate } from 'utils';
import './index.scss';

const PreScheduleConfig = (props) => {
  const { restaurantDetails: { preScheduleConfig: { allowPreSchedule = false, maxDaysBefore = 0, excludedDays = [] } = {}} = {}} = props;

  const excludedDates = excludedDays?.map((date, i) => {
    return (
      <div style={{ width: '25%' }}><strong>{i+1}.</strong> {formatDate(date, DATE_FORMATS.MM_DD_YYYY)}</div>
    );
  });

  return (
    <div className={'pre-schedule-container'}>
      <div className={'heading'}>PreSchedule Config</div>
      <div className={'pre-schedule-sub-container'}>
        <RenderInfo heading="Allow pre schedule" value={allowPreSchedule} type='boolean' />
        <RenderInfo heading="Max days before" value={maxDaysBefore} />
        <div><strong>Excluded dates</strong></div>
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}>
          {excludedDates}
        </div>
      </div>
    </div>
  );
};

export default PreScheduleConfig;
