import React, { useEffect, useState } from 'react';
import './AsyncJobDetail.scss';
import { fetchJobDetails } from 'services/api/asyncJobs';
import { IJobDetails } from 'interfaces/job';
import { Loading } from 'components/loading/Loading';
import JSONViewer from 'components/JSONViewer';

const AsyncJobDetail = props => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({} as IJobDetails);
  const { jobId } = props.match.params;

  useEffect(() => {
    fetchJobDetail();
  }, []);

  const fetchJobDetail = async () => {
    setLoading(true);
    const response = await fetchJobDetails({ id: jobId });
    setLoading(false);
    setData(response);
  };

  const renderInputDetails = () => {
    if(data.input) {
      return Object.entries(data.input).map((item, i) => (
        <div key={i}>
          <p className='label text-capitalize'>{item[0]}:</p>
          <p className='detail'>{item[1].toString()}</p>
        </div>
      ));
    }
    return <div />;
  };

  if(loading) return <Loading />;

  return (
    <>
      <button
        className='btn btn-primary float-right'
        onClick={() => fetchJobDetail()}
      >
        Refresh
      </button>
      <h4 className='heading'>Job Details</h4>
      <div className='details-container'>
        <p className='sub-heading'>Basic Details</p>
        <div className='single'>
          <p className='label'>Type:</p>
          <p  className='detail'>{data.type}</p>
        </div>
        <div className='single'>
          <p className='label'>Status:</p>
          <p  className='detail'>{data.status}</p>
        </div>
        <div className='single'>
          <p className='label'>Process started by:</p>
          <p  className='detail'>{data.startedBy}</p>
        </div>
        <div className='single'>
          <p className='label'>Created at:</p>
          <p  className='detail'>{data.createdAt}</p>
        </div>
        <div className='break' />
        <div className='input-details'>
          <p className='sub-heading'>Input Details:</p>
          {renderInputDetails()}
        </div>
        <div className='break' />
        <div>
          <p className='sub-heading'>Result:</p>
          {
            data.result ? <JSONViewer data={data.result} />
              : <p>No result available</p>
          }
        </div>
        <div>
          <p className='sub-heading'>Error:</p>
          {
            data.error ? <JSONViewer data={data.error} />
              : <p>No error available</p>
          }
        </div>
      </div>
    </>
  );
};

export default AsyncJobDetail;
