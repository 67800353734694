import React, { useEffect, useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import qs from 'query-string';
import './index.scss';
import { ROUTE_STATUS } from 'utils';
import { DateRangePickerWrapper } from 'components/Date/DateRangePicker';
import moment from 'moment';
import NameFilterAutosuggest from 'components/NameFilterAutoSuggest';
import { searchUserByName } from 'services/api/order';

const RoutesFilter = props => {
  const [status, setStatus] = useState<string>('');
  const [routeId, setRouteId] = useState<string>('');
  const [startDate, setStartDate] = useState<string | null>(moment().subtract(1, 'month').toString());
  const [endDate, setEndDate] = useState<string | null>(moment().toString());
  const [driverId, setDriverId] = useState<string>('');
  const [orderId, setOrderId] = useState<string>('');

  useEffect(() => {
    const {
      status,
      routeId,
      startDate,
      endDate,
      driverId,
      orderId,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
    if (status) setStatus(status.toString());
    if (routeId) setRouteId(routeId.toString());
    if (startDate) setStartDate(startDate.toString());
    if (endDate) setEndDate(endDate.toString());
    if (driverId) setDriverId(driverId.toString());
    if (orderId) setOrderId(orderId.toString());
  }, []);

  const resetFilter = () => {
    setStatus('');
    setRouteId('');
    setStartDate('');
    setEndDate('');
    setDriverId('');
    setOrderId('');
    const filterData = {
    };
    props.onFilterSubmit(filterData);
  };

  const onFilterSubmit = () => {
    const filterData: Record<string, any> = {
      status,
      id: routeId,
      startDate,
      endDate,
      driverId,
      orderId,
    };
    props.onFilterSubmit(filterData);
  };

  const onDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    setStartDate(moment(startDate).toISOString());
    setEndDate(moment(endDate).toISOString());
  };

  const getStatusOptions = () => Object.keys(ROUTE_STATUS).map((status, i) => <option key={i} value={status}>{status}</option>);

  return (
    <div className='filter-container'>
      <div className='input-container mb-2 mr-3'>
        <label>Date Range</label>
        <DateRangePickerWrapper
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onDateSelectionChange}
        />
      </div>
      <div className='input-container mb-4 mr-3'>
        <label>Status</label>
        <select
          value={status}
          className={'form-control'}
          onChange={e => setStatus(e.target.value)}
        >
          <option value="" hidden={!!status}>Select a status</option>
          {getStatusOptions()}
        </select>
      </div>
      <div className='input-container mr-3'>
        <label>Route ID</label>
        <input
          className={'form-control'}
          value={routeId}
          onChange={e => setRouteId(e.target.value)}
        />
      </div>
      <div className='input-container mr-3'>
        <label>Order ID</label>
        <input
          className={'form-control'}
          value={orderId}
          onChange={e => setOrderId(e.target.value)}
        />
      </div>
      <div className='input-container mb-2 mr-3'>
        <label>Driver Name</label>
        <NameFilterAutosuggest
          onlyDriver={true}
          entityId={driverId}
          placeholder={'Driver Name'}
          onSuggestionSelected={setDriverId}
          fetchSuggestions={searchUserByName}
        />
      </div>
      <div className='input-container mr-5'>
        <label>Driver ID</label>
        <input
          className={'form-control'}
          value={driverId}
          onChange={e => setDriverId(e.target.value)}
        />
      </div>
      <ActionButton
        text={'Submit'}
        isLoading={false}
        active={true}
        customClass={'btn-primary mb-2'}
        onClick={onFilterSubmit}
      />
      <ActionButton
        text={'Reset'}
        isLoading={false}
        active={true}
        customClass={'btn-primary ml-4 mb-2'}
        onClick={resetFilter}
      />
    </div>
  );
};

export default RoutesFilter;
