export const DRIVER_REPORT_CSV_COLUMNS = [
  {
    label: 'Driver Name',
    value: 'driverFullname',
  },
  {
    label: 'Driver ID',
    value: 'driverId',
  },
  {
    label: 'Note Created At',
    value: 'noteCreatedAt',
  },
  {
    label: 'Note Created By',
    value: 'noteCreatedByUserFullname',
  },
  {
    label: 'Creator User',
    value: 'noteCreatedByUserId',
  },
  {
    label: 'Note Id',
    value: 'noteId',
  },
  {
    label: 'Note',
    value: 'noteValue',
  },
];