import { Loading } from 'components/loading/Loading';
import { SingleOrderWithRouter as SingleOrder } from 'pages/orders';
import React, { useEffect, useState } from 'react';
import { fetchDeferredOrderDetail } from 'services/api/order';
import { showError } from 'utils/notifications';

const DeferredOrderDetails = props => {
  const { match: { params: { orderId }}} = props;
  const [orderDetails, setOrderDetails] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);

  const fetchDeferredOrderDetailFn = async () => {
    try {
      const response = await fetchDeferredOrderDetail(orderId);
      setOrderDetails(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeferredOrderDetailFn();
  }, []);

  if (loading) return <Loading />;

  return (
    <SingleOrder orderDetails={orderDetails} isDeferredOrder={true} />
  );
};

export { DeferredOrderDetails };
