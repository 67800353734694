import ActionButton from 'components/ActionButton/ActionButton';
import React, { useState } from 'react';
import { addNote } from 'services/api/disputes';
import { DATE_FORMATS, formatDate, showError, showSuccess, showWarning } from 'utils';
import './index.scss';
import { Link } from 'react-router-dom';

const Notes = (props) => {
  const { notes = [], fetchDisputeDetailsFn, disputeId } = props;

  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);

  const addNoteFn = async () => {
    if (!note) {
      showWarning('Note cannot be empty.');
      return;
    }
    setLoading(true);
    try {
      await addNote({ id: disputeId, note });
      showSuccess('Note added successfully.');
      setNote('');
      fetchDisputeDetailsFn();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const getNote = ({ note }) => {
    const { by } = note;
    return (
      <li className='note'>
        <div>
          {note?.note}
        </div>
        <div className='note-footer'>
          {by ? <Link to={`/users/${note?.by?.objectId}`} className='note-creator'>View User</Link> : <div />}
          <div className='date'>{formatDate(note.timestamp, DATE_FORMATS.MM_DD_YYYY_HH_MM_SS_A)}</div>
        </div>
      </li>
    );
  };
    
  const getAddNote = () => {
    return (
      <>
        <textarea
          value={note}
          disabled={loading}
          onChange={e => setNote(e.target.value)}
          className='form-control'
          placeholder='Enter new note here...'
        />
        <ActionButton
          text='Add Note'
          active={true}
          isLoading={loading}
          onClick={addNoteFn}
          customClass="btn-primary float-right"
        />
      </>
    );
  };

      
  return (
    <div className={'notes'}>
      <div className='notes-heading'>Notes</div>
      <ol>
        {notes?.map((note) => {
          return getNote({ note });
        })}
      </ol>
      <div className='add-note-section'>
        {getAddNote()}
      </div>
    </div>
  );
};

export default Notes;