import React from 'react';
import StarRatings from 'react-star-ratings';
import './index.scss';

const RatingComponent = props => {
  const { rating } = props;
  
  return (
    <div>
      <StarRatings
        rating={rating}
        starRatedColor={'rgb(244, 81, 29)'}
        starEmptyColor={'rgb(175, 175, 175)'}
        starDimension={'28px'}
        starSpacing={'4px'}
      />
    </div>
  );
};

export default RatingComponent;