import { tableStyles } from 'css/tableStyles';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { formatDate, getPageLimit, showError, stringifyAndParseObj, updatePageLimit } from 'utils';
import qs from 'query-string';
import Filter from './Filter';
import styles from './index.module.scss';
import CustomLink from 'TableLink';
import { fetchUserSubscriptions } from 'services';
import { Loading } from 'components/loading/Loading';

let filters: { [p: string]: any; page: number } = {
  page: 0,
};

const columns = [
  {
    name: 'ID',
    selector: 'objectId',
    sortable: false,
    width: '140px',
    // eslint-disable-next-line react/display-name
    cell: row => (
      <CustomLink
        text={row.objectId}
        linkTo={`/user-subscriptions/${row.objectId}`}
      />
    ),
  },
  {
    name: 'User',
    selector: 'user.full_name',
    sortable: false,
  },
  {
    name: 'Subscription Name',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => {
      return (
        <div>{row?.subscription?.name}</div>
      );
    },
  },
  {
    name: 'Type',
    sortable: false,
    width: '110px',
    // eslint-disable-next-line react/display-name
    cell: row => {
      return (
        <div>{row?.subscription?.type}</div>
      );
    },
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
    width: '110px',
  },
  {
    name: 'Created At',
    selector: 'createdAt',
    sortable: true,
    format: (row: any) => formatDate(row.createdAt),
  },
];

const UsersSubscriptions = props => {
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<Record<string, any>>({});

  const fetchUsersSubscriptionsFn = async () => {
    setLoading(true);
    try {
      let response = await fetchUserSubscriptions({
        ...filters,
        limit: getPageLimit(),
      });
      response = stringifyAndParseObj(response);
      setSubscriptions(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };
  
  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  useEffect(() => {
    if (!props.data) {
      filters = {
        ...filters,
        ...queryStringToFilter(),
      };
      fetchUsersSubscriptionsFn();
    }
  }, []);
  
  const filterToQueryString = () => {
    const { filter } = filters;
    return qs.stringify({ page: filters.page, ...filter });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchUsersSubscriptionsFn();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    filters.limit = rowsPerPage;
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const onFilterSubmit = (updatedFilter: Record<string, any>) => {
    filters = { filter: updatedFilter, page: 0 };
    updateQueryString();
    fetchUsersSubscriptionsFn();
  };

  return (
    <div>
      {loading && <Loading />}
      <div className={styles['header']}>
        <div className={styles['heading']}>Users Subscriptions</div>
      </div>
      <Filter {...props} onSubmit={onFilterSubmit} />
      <DataTable
        columns={columns}
        noHeader={true}
        data={subscriptions.items}
        highlightOnHover={true}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationPerPage={getPageLimit()}
        paginationDefaultPage={(filters.page || 0) + 1}
        paginationTotalRows={subscriptions.total}
        paginationRowsPerPageOptions={[50, 100, 200]}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </div>
  );
};

export { UsersSubscriptions };