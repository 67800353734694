import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ActionButton from 'components/ActionButton/ActionButton';
import { showError, showSuccess } from 'utils/notifications';
import { sendOrderCommunication } from 'services/api/order';
import './index.scss';

const AddUserCommunicaitonModal = props => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<string>('');
  const { show, orderId, onClose } = props;
  
  const handleSubmit = async () => {
    setLoading(true);
    const data = {
      orderId,
      type,
      message,
    };
    try {
      await sendOrderCommunication(data);
      showSuccess('Sent successfully');
      onClose();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title>Add User Communication</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          cols={30}
          rows={6}
          placeholder={'Message'}
          className="w-100"
          value={message}
          onChange={event => setMessage(event.target.value)}
        />
        <div className={'mt-3'}>
          <div className={'mb-2 label'}>Type</div>
          <div className={'d-flex'}>
            <div className={'d-flex mr-5'}>
              <input
                type={'radio'}
                name={'type'}
                value={'sms'}
                onChange={e => setType(e.target.value)}
              />
              <div className={'radio-label'}>SMS</div>
            </div>
            <div className={'d-flex'}>
              <input
                type={'radio'}
                name={'type'}
                value={'push'}
                onChange={e => setType(e.target.value)}
              />
              <div className={'radio-label'}>Push Notification</div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ActionButton
          text={'Close'}
          active={true}
          isLoading={false}
          onClick={onClose}
        />
        <ActionButton
          text={'Submit'}
          active={message.length > 0}
          onClick={handleSubmit}
          isLoading={loading}
          customClass="btn-primary"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AddUserCommunicaitonModal;
