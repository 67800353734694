import React from 'react';
import { mapDispatchToProps as menuActions } from 'actions/menu';
import { mapDispatchToProps as authActions } from 'actions/auth';
import { connect } from 'react-redux';
import './sidenav.scss';
import SideNavItem from './SideNavItem';
import { isUserAuthorized } from 'utils';
import DataTableFilter from 'components/DataTableFIlter';

const mapStateToProps = function({ menu, auth }) {
  return {
    menuItems: menu.menuItems,
    isOpen: menu.isOpen,
    loggedInUser: auth.loggedInUser && JSON.parse(JSON.stringify(auth.loggedInUser)),
  };
};

class SideNav extends React.Component<any, any> {
  constructor(props){
    super(props);
    this.state={
      filterText: '',
    };
  }
    logout =  async () => {
      await this.props.logout();
    };

    filterMenuItems = (list: Record<string,any>[]) =>{
      const { filterText } = this.state;
      return (
        list.filter(item => {
          /** Check and Operation for nested filtering */
          if(item.hasChildren){
            item.children = this.filterMenuItems(item.children);
          }

          if ((item.hasChildren && item.children.length !== 0) || item.title.toLowerCase().includes(filterText.toLowerCase())){
            return true;
          }
          return false;
        })
      );
    }

    getMenuItems = () => {
      const { loggedInUser: user, menuItems } = this.props;

      /** Taking a deep copy of actual menu items so that actual data wont be having any impact*/
      const menuItemsList = JSON.parse(JSON.stringify(menuItems));
      return (
        this.filterMenuItems(menuItemsList)
          .map(menuItem => {
            if (!isUserAuthorized(user.roles, menuItem.roles) || menuItem.hideForEmptyRoles) return null;
            return <SideNavItem menuItem={menuItem} key={menuItem.title}/>;
          })
      );
    };

    getLogoutMenuItem = () => {
      const menuItem = {
        iconClass: 'fa-sign-out',
        title: 'Logout',
        customClass: 'red-background',
        url: '/',
        onClick: this.logout,
      };
      return (
        <SideNavItem menuItem={menuItem}/>
      );
    };

    toggleMenu = () => {
      if (this.props.isOpen) {
        this.props.closeMenu();
      } else {
        this.props.openMenu();
      }
    };

    getSearchBox = () => (
      <div className='pl-2'>
        <DataTableFilter 
          filterText={this.state.filterText} 
          setFilterText={(val)=> this.setState({ filterText: val })} placeholder={'Search Module'} 
        />
      </div>
    );

    renderMenu = () => {
      if (this.props.loggedInUser) {
        return (
          <>
            <div className={`toggle-button d-flex justify-content-center align-items-center position-absolute ${this.props.isOpen ? 'open': ''}`} onClick={() => this.toggleMenu()}>
              <i className="fa fa-bars"></i>
            </div>
            <div className={`sidenav position-fixed text-center ${this.props.isOpen ? 'open': ''}`}>
              <p className="logo">
                <img src={require('assets/logonew.png')} alt="Uva Logo" className="pt-3"/>
              </p>
              {this.getSearchBox()}
              <div className="menu">
                {this.getMenuItems()}
              </div>
              <div className="profile position-absolute">
                <div className="media text-left m-auto d-flex align-items-center">
                  <div className="media-body">
                    <div className="font-weight-bold">{this.props.loggedInUser.full_name}</div>
                    <div>{this.props.loggedInUser.email}</div>
                  </div>
                </div>
                {this.getLogoutMenuItem()}
              </div>
            </div>
          </>
        );
      } else {
        return <></>;
      }
    };

    render (): any {
      return (
        <>{this.renderMenu()}</>
      );
    }
}

const SideNavWrapper = connect(mapStateToProps, { ...menuActions, ...authActions })(SideNav);
export { SideNavWrapper as SideNav };
