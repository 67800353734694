import React, { useEffect, useState } from 'react';
import OrderReviewsFilters from './filter';
import moment from 'moment';
import qs from 'query-string';
import { showError } from 'utils/notifications';
import { fetchReviewsByFilter } from 'services/api/orderReviews';
import { Loading } from 'components/loading/Loading';
import DataTable from 'react-data-table-component';
import { tableStyles } from 'css/tableStyles';
import { getPageLimit, updatePageLimit } from 'utils';
import ReviewCard from './ReviewCard';

let filters: { [p: string]: any; page: number } = {
  page: 0,
  endDate: moment().toISOString(),
  startDate: moment().subtract(1, 'week').toISOString(),
};

const columns = [
  {
    name: '',
    selector: 'objectId',
    // eslint-disable-next-line react/display-name
    cell: row => (
      <ReviewCard details={row} />
    ),
    sortable: false,
  },
];

const OrderReviews = props => {
  const [reviews, setReviews] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);

  const fetchReviewsFn = async () => {
    setLoading(true);
    const { page, ...rest } = filters;
    try {
      const response = await fetchReviewsByFilter({
        page,
        limit: getPageLimit(),
        filter: { ...rest },
      });
      setReviews(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  useEffect(() => {
    filters = {
      ...filters,
      ...queryStringToFilter(),
    };
    fetchReviewsFn();
  }, []);

  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onFilterChanged = (udpatedfilters: Record<string, any>) => {
    filters = { ...udpatedfilters, page: 0 };
    updateQueryString();
    fetchReviewsFn();
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchReviewsFn();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  return (
    <div>
      <OrderReviewsFilters
        {...props}
        onFilterSubmit={onFilterChanged}
      />
      <div className={'mt-4'}>
        {loading ? <Loading /> : (
          <DataTable
            noHeader
            data={reviews.items}
            columns={columns}
            customStyles={tableStyles}
            pagination={true}
            paginationServer={true}
            paginationDefaultPage={(filters.page || 0) + 1}
            paginationPerPage={getPageLimit()}
            paginationTotalRows={reviews.total}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        )}
      </div>
    </div>
  );
};

export { OrderReviews };