import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Error } from 'components/message/Error';

const validationSchemaForRequired = yup.object().shape({
  value: yup.number().integer('Decimals will be ignored').typeError('Field cannot be empty'),
});

const validationSchema = yup.object().shape({
  value: yup.number()
    .integer('Decimals will be ignored')
    .typeError('Field cannot be empty')
    .min(0,'Min value is 0.')
    .nullable()
    .transform((value: string, originalValue: string) => originalValue.trim() === '' ? null: value),
});

type FormData = {
 value: number;
};

const WholeNumberInput = props => {
  const { isTableCell, value, onChange, disabled, required = true } = props;
  const { register, errors, trigger } = useForm<FormData>({ resolver: yupResolver(required ? validationSchemaForRequired : validationSchema) });

  const handleChange = async (value: number) => {
    await trigger('value');
    onChange(value);
  };
  
  return (
    <div>
      {isTableCell ? (
        <input
          name={'value'}
          type={'number'}
          className={'form-control'}
          defaultValue={value}
          ref={register}
          disabled={disabled}
          onChange={e => handleChange(parseInt(e.target.value))}
          step={1}
        />
      ): (
        <input
          name={'value'}
          type={'number'}
          className={'form-control'}
          value={value}
          ref={register}
          disabled={disabled}
          onChange={e => handleChange(parseInt(e.target.value))}
          step={1}
        />
      )}
      {errors.value && <Error message={errors.value.message} />}
    </div>
  );
};

export default WholeNumberInput;