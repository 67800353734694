import ActionButton from 'components/ActionButton/ActionButton';
import AddDishVariantModal from 'components/AddDishVariantModal';
import DishVarientsList from 'components/DishVariants/List';
import React, { useEffect, useState } from 'react';
import { toggleDishVariantsAvailability } from 'services/api/dishes';
import { convertArrayToObject, convertFlatArrayToFlatObject, showError, showSuccess } from 'utils';

const DishVariants = ({ dishDetails }) =>{
  const { variants: variantsFromProps, objectId: dishId } = dishDetails;
  const [addVariantModalOpen, setAddVariantModalOpen] = useState<boolean>(false);
  const [variants, setVariants] = useState([]);
  const [variantsHashMap, setVariantsHashMap] = useState({});
  const [variantsToggleLoading, setVariantsToggleLoading] = useState<boolean>(false);
  const [variantsObject, setVariantsObject] = useState({});

  useEffect(()=>{
    setVariants(variantsFromProps);
  }, [variantsFromProps]);

  useEffect(()=>{
    const convertedHashmap = convertArrayToObject(variants, 'parent', dishId);
    const convertedVariantsObject = convertFlatArrayToFlatObject([...variants, dishDetails], 'objectId');
    setVariantsObject(convertedVariantsObject);
    setVariantsHashMap(convertedHashmap);
  }, [variants]);

  const toggleVariantsAvailability = async () => {
    setVariantsToggleLoading(true);
    try {
      const response = await toggleDishVariantsAvailability({ id: dishId, responseAsTree: true });
      showSuccess('All variants toggled successfully');
      setVariants(response);
    } catch (e) {
      showError(e);
    } finally {
      setVariantsToggleLoading(false);
    }
  };

  return(
    <div className={'dish-variants-section'}>
      <div className={'variants-section-header'}>
        <div className={'section-heading'}>Variants</div>
        <ActionButton
          text={'Toggle All Variants'}
          active={!!variants.length}
          isLoading={variantsToggleLoading}
          onClick={toggleVariantsAvailability}
          customClass={'btn btn-primary'}
        />
        <ActionButton
          text={'Manage Variants'}
          active={true}
          isLoading={false}
          onClick={() => setAddVariantModalOpen(true)}
          customClass={'btn btn-primary ml-3'}
        />
      </div>
      <DishVarientsList
        dishDetails={dishDetails} 
        dishVariants={variantsHashMap[dishId]} 
        setVariantsArray={setVariants}
        variantsArray={variants}
        variantsHashMap={variantsHashMap}
      />

      {addVariantModalOpen && (
        <AddDishVariantModal
          toggleModal={() => setAddVariantModalOpen(!addVariantModalOpen)}
          dishDetails={dishDetails}
          variantsArray={variants}
          setVariantsArray={setVariants}
          variantsHashMap={variantsHashMap}
          setVariantsHashMap={setVariantsHashMap}
          variantsHashMapWithDetails={variantsObject}
        />
      )}
    </div>
  );
};

export default DishVariants;