import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { DATE_FORMATS, formatDate, getPageLimit, updatePageLimit } from 'utils';
import { showError } from 'utils/notifications';
import moment from 'moment';
import { DateRangePickerWrapper } from 'components/Date/DateRangePicker';
import ActionButton from 'components/ActionButton/ActionButton';
import './index.scss';
import { fetchOrdersFromWhichDriverRemoved } from '../../../../services/api/drivers';
import TableLink from 'TableLink';

let filters: { [p: string]: any; page: number } = {
  page: 0,
  endDate: moment().toString(),
  startDate: moment().subtract(1, 'month').toString(),
};

const columns = [
  {
    name: 'Order ID',
    selector: 'orderId',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/orders/${row.orderId}`}
        text={row.orderId}
        openInNewTab={true}
      />
    ),
  },
  {
    name: 'Action',
    selector: 'action',
    sortable: false,
  },
  {
    name: 'Reason',
    selector: 'reason',
    sortable: false,
  },
  {
    name: 'Removed By',
    selector: 'by',
    sortable: false,
  },
  {
    name: 'Order Made On',
    selector: 'status',
    sortable: false,
    format: row => formatDate(row.orderCreationDate, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
  },
  {
    name: 'Order Updated At',
    selector: 'updatedAt',
    sortable: false,
    format: row => formatDate(row.updatedAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
  },
];

const DriverRemovedFromOrders = props => {
  const [orders, setOrders] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string | null>(filters.startDate);
  const [endDate, setEndDate] = useState<string | null>(filters.endDate);
  const { driverId } = props;

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await fetchOrdersFromWhichDriverRemoved({
        filter: { ...filters, driverId },
        page: filters.page,
        limit: getPageLimit(),
      });
      setOrders(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const onDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    filters = {
      ...filters,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
    };
    setStartDate(moment(startDate).toISOString());
    setEndDate(moment(endDate).toISOString());
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    fetchOrders();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const getDataTable = () => {
    if (loading) return <Loading type={'mini'} />;

    return (
      <div className={'col-12 data-table'}>
        <DataTable
          keyField="objectId"
          columns={columns}
          data={orders.items}
          highlightOnHover={true}
          defaultSortField="createdAt"
          customStyles={tableStyles}
          pagination={true}
          paginationServer={true}
          paginationDefaultPage={(filters.page || 0) + 1}
          paginationTotalRows={orders.total}
          progressPending={loading}
          progressComponent={<Loading type="mini" />}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </div>
    );
  };

  return (
    <div className="driver-removed-from-orders">
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="text-muted font-weight-bold">Driver Removed From Orders</div>
            </div>
            <div className="col-6 text-right">
              <div className="d-inline-block">
                <DateRangePickerWrapper
                  startDate={filters.startDate}
                  endDate={filters.endDate}
                  onDatesChange={onDateSelectionChange}
                />
              </div>
              <ActionButton
                text="Submit"
                isLoading={loading}
                active={!!(startDate && endDate)}
                onClick={fetchOrders}
                customClass="btn-primary ml-2"
              />
            </div>
          </div>
        </div>
        {getDataTable()}
      </div>
    </div>
  );
};

export default DriverRemovedFromOrders;