import React, { useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import styles from './index.module.scss';
import _ from 'lodash';
import Tag from 'components/Tag';

const GroupEligibility = props => {
  const { detailsEditable, discount, setDiscount, loading } = props;
  const groups = discount?.userEligibility?.groupContainedIn || [];
  const [group, setGroup] = useState<string>('');

  const handleRemove = (index: number) => {
    groups.splice(index, 1);
    setDiscount({ ...discount, userEligibility: { ...discount?.userEligibility, groupContainedIn: groups }});
  };

  const handleAddGroup = () => {
    if (!_.isEmpty(group)) {
      groups.push(group);
      setDiscount({ ...discount, userEligibility: { ...discount?.userEligibility, groupContainedIn: _.uniq(groups) }});
    }
  };

  const getGroupEligibilitySection = () => {
    if (detailsEditable) {
      return (
        <div className={styles['input-container']}>
          <div >
            <div className={`${styles['label']}`}>Add Eligible Group</div>
            <input
              value={group}
              className='form-control'
              onChange={e => setGroup(e.target.value)}
            />
          </div>
          <div className={styles['action-button']}>
            <ActionButton
              active={true}
              isLoading={loading}
              onClick={handleAddGroup}
              text={'Add Group'}
              customClass={'form-control'}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const getGroupsTagSection = () => {
    if (groups?.length > 0) {
      return (
        <div className={styles['tag-section']}>
          <div className={styles['roles-card']}>
            {groups.map((item, i) => <Tag key={i} tagName={item} onRemove={() => handleRemove(i)} />)}
          </div>
        </div>
      );
    } else {
      return <div className={`${styles['label']} pl-2`}>No Eligible Groups</div>;
    }
  };

  return (
    <div className={styles['groupe-section']}>
      <h6 >Group Eligibility</h6>
      {getGroupEligibilitySection}
      {getGroupsTagSection()}
    </div>
  );
};

export default GroupEligibility;