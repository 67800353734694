import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { IUvaCategoryDropdown } from 'interfaces/uvaCategoryDropdown';
import { Loading } from '../loading/Loading';
import { fetchUvaCategoryTree } from 'services/api/uvaCategories';
import './styles.scss';
import { flattenJson } from 'utils';
import { showError } from '../../utils/notifications';

const UvaCategoriesFlattenTree = (props: IUvaCategoryDropdown) => {
  const [uvaCategories, setUvaCategories] = useState<Record<string, string>[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState({ name: '', objectId: '' });
  const {
    uvaCategoryId,
    onChange,
  } = props;

  const fetchUvaCategoryList = async () => {
    try {
      setLoading(true);
      const response = await fetchUvaCategoryTree();
      const results = flattenJson('', response);
      setUvaCategories(results);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUvaCategoryList();
  }, []);

  useEffect(() => {
    if (!uvaCategoryId) {
      setSelectedItem({ name: '', objectId: '' });
    }
  }, [uvaCategoryId]);

  const onCategorySelection = (item) => {
    setSelectedItem(item);
    onChange(item.objectId, item.name);
  };

  const filterData = () => {
    return uvaCategories.filter(obj => _.get(obj, 'name', '').toLowerCase().includes(searchText.toLowerCase()));
  };

  const renderOptionsDataSet = () => {
    return (
      <div className={'dropdown-layout mt-1'}>
        {
          filterData().map(obj => (
            <div
              onClick={() => onCategorySelection(obj)}
              className={selectedItem.objectId === obj.objectId ? 'active' : ''}
              key={obj.objectId}
              tabIndex={0}
              role="button"
            >
              {obj.name}
            </div>
          ))
        }
      </div>
    );
  };


  return (
    <div className={'node-tree'}>
      <div className="d-flex flex-column">
        <input
          id="uva-category-list"
          placeholder={'Search for Uva Category'}
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
        />
        <div className='m-auto pt-3'>{loading && <Loading type='mini' />}</div>
        {renderOptionsDataSet()}
      </div>
    </div>
  );
};

export default UvaCategoriesFlattenTree;