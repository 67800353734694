import ActionButton from 'components/ActionButton/ActionButton';
import BackButton from 'components/BackButton';
import ImageUpload from 'components/ImageUpload/index';
import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from 'react';
import { fetchRestaurantDetailsByAdmin, updateRestaurant } from 'services/api/restaurant';
import { showError, showSuccess } from 'utils/notifications';
import './index.scss';
import RestaurantTimings from './restaurant-timings';
import _ from 'lodash';
import CitiesDropdown from 'components/CitiesDropdown';
import VerticalTypePicker from 'components/VerticalTypePicker';
import ToggleSwitch from 'components/ToggleSwitch';
import AddAddressModal from 'components/Address/AddAddressModal';
import PreScheduleConfig from './preScheduleConfig';
import ColorTheme from './colorTheme';

const SocialMediaLink = props => (
  <div className={'restaurant-social-media-link'}>
    <img src={props.platformImage} />
    <input
      placeholder={'Add Media Handle'}
      value={props.platformHandle || ''}
      onChange={e => props.onChange(e.target.value)}
    />
  </div>
);

const EditRestaurantInfo = props => {
  const [restaurantDetails, setRestaurantDetails] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [logo, setLogo] = useState<string>('');
  const [cover, setCover] = useState<string>('');
  const [deliveryConfig, setDeliveryConfig] = useState<any>({});
  const [revSharePercentConfig, setRevSharePercentConfig] = useState<any>({});
  const [showSelectLocation, setShowSelectLocation] = useState(false);
  const { restaurantId } = props.match.params;

  const fetchRestaurantDetails = async () => {
    try {
      const response = await fetchRestaurantDetailsByAdmin(restaurantId);
      setRestaurantDetails(response);
      setLogo(response.restaurant_logo?.url || '');
      setCover(response.image?.url || '');
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRestaurantDetails();
  }, []);

  const updateRestaurantInfo = async () => {
    setUpdateLoading(true);

    const getSanitizedSchedule = () => _.pickBy(
      _.mapValues(restaurantDetails.schedule, daySlots => {
        return daySlots.filter(slot => slot.start !== '' || slot.end !== '');
      }), day => !!_.values(day).length,
    );

    try {
      const data = {
        ...restaurantDetails,
      };
      if (restaurantDetails.schedule) {
        data.schedule = JSON.stringify(getSanitizedSchedule());
      }
      const response = await updateRestaurant({ ...data, deliveryConfig, revSharePercentConfig });
      setRestaurantDetails(response);
      showSuccess('Restaurant details updated successfully.');
      props.history.push(`/restaurants/${restaurantId}`);
    } catch (e) {
      showError(e);
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleChange = (key: string, value: any) => {
    setRestaurantDetails({ ...restaurantDetails, [key]: value });
  };

  if (loading) return <Loading />;

  const getActionSection = () => (
    <div className={'restaurant-edit-action-section'}>
      <ActionButton
        text={'Cancel'}
        active={true}
        isLoading={false}
        onClick={() => props.history.goBack()}
      />
      <ActionButton
        text={'Save Changes'}
        active={true}
        isLoading={false}
        onClick={updateRestaurantInfo}
        customClass={'btn-primary'}
      />
    </div>
  );

  const getSocialMediaSection = () => (
    <div className={'restaurant-edit-social-media-section'}>
      <div className={'restaurant-edit-label'}>Follow Us</div>
      <div className={''}>
        <SocialMediaLink
          platformHandle={restaurantDetails.contact_facebook}
          platformImage={require('assets/facebook.svg')}
          onChange={val => handleChange('contact_facebook', val)}
        />
        <SocialMediaLink
          platformHandle={restaurantDetails.contact_instagram}
          platformImage={require('assets/instagram.svg')}
          onChange={val => handleChange('contact_instagram', val)}
        />
        <SocialMediaLink
          platformHandle={restaurantDetails.contact_twitter}
          platformImage={require('assets/twitter.svg')}
          onChange={val => handleChange('contact_twitter', val)}
        />
      </div>
    </div>
  );

  const getBankingSection = () => (
    <div className={'restaurant-edit-bank-details-section'}>
      <div className={'restaurant-edit-group'}>
        <div className={'restaurant-edit-group-label'}>Bank Routing Number</div>
        <input
          className={'restaurant-edit-group-input'}
          value={restaurantDetails.routingNo}
          onChange={e => handleChange('routingNo', e.target.value)}
        />
      </div>
      <div className={'restaurant-edit-group'}>
        <div className={'restaurant-edit-group-label'}>Bank Account Number</div>
        <input
          className={'restaurant-edit-group-input'}
          value={restaurantDetails.bankAccountNo}
          onChange={e => handleChange('bankAccountNo', e.target.value)}
        />
      </div>
    </div>
  );

  const getContactSection = () => (
    <div className={'restaurant-edit-contact-section'}>
      <div className={'restaurant-edit-group'}>
        <div className={'restaurant-edit-group-label'}>Contact Email</div>
        <input
          className={'restaurant-edit-group-input'}
          value={restaurantDetails.contact_email}
          onChange={e => handleChange('contact_email', e.target.value)}
        />
      </div>
      <div className={'restaurant-edit-group'}>
        <div className={'restaurant-edit-group-label'}>Contact Phone</div>
        <input
          className={'restaurant-edit-group-input'}
          value={restaurantDetails.contact_phone}
          onChange={e => handleChange('contact_phone', e.target.value)}
        />
      </div>
      <div className={'restaurant-edit-group'}>
        <div className={'restaurant-checkbox-container'}>
          <input
            type={'checkbox'}
            className={'restaurant-edit-group-checkbox'}
            checked={restaurantDetails.notifyOnlineStatus}
            onChange={e => handleChange('notifyOnlineStatus', e.target.checked)}
          />
          <div className={'restaurant-edit-group-label'}>Notify Online Status</div>
        </div>
        <input
          disabled={!restaurantDetails.notifyOnlineStatus}
          className={'restaurant-edit-group-input'}
          value={restaurantDetails.online_event_sms_phone}
          onChange={e => handleChange('online_event_sms_phone', e.target.value)}
        />
      </div>
    </div>
  );

  const getRestaurantTimingsSection = () => (
    <div className={'restaurant-edit-timings-container'}>
      <div className={'restaurant-label'}>Opening Hours</div>
      <RestaurantTimings
        schedule={restaurantDetails.schedule}
        onChange={schedule => handleChange('schedule', schedule)}
      />
    </div>
  );

  const getRestaurantName = () => (
    <div className={'restaurant-edit-group'}>
      <div className={'restaurant-edit-group-label'}>Restaurant’s Name</div>
      <input
        className={'restaurant-edit-group-input'}
        value={restaurantDetails.restaurant_name}
        onChange={e => handleChange('restaurant_name', e.target.value)}
      />
    </div>
  );

  const getAboutRestaurant = () => (
    <div className={'restaurant-edit-group'}>
      <div className={'restaurant-edit-group-label'}>About Restaurant</div>
      <textarea
        className={'restaurant-edit-group-textarea'}
        value={restaurantDetails.restaurant_about_us}
        onChange={e => handleChange('restaurant_about_us', e.target.value)}
      />
    </div>
  );

  const getType = () => (
    <div className={'restaurant-edit-group'}>
      <div className={'restaurant-edit-group-label'}>Type</div>
      <VerticalTypePicker
        className={'restaurant-edit-group-textarea'}
        verticalType={restaurantDetails.type}
        onChange={e => handleChange('type', e.target.value)}
      />
    </div>
  );

  const getCategory = () => (
    <div className={'restaurant-edit-group'}>
      <div className={'restaurant-edit-group-label'}>Category</div>
      <VerticalTypePicker
        className={'restaurant-edit-group-textarea'}
        verticalType={restaurantDetails.category}
        onChange={e => handleChange('category', e.target.value)}
      />
    </div>
  );

  const getRestaurantAddress = () => (
    <div className={'restaurant-edit-group'}>
      <div className={'restaurant-edit-group-label'}>Restaurant’s Address</div>
      <input
        className={'restaurant-edit-group-input'}
        value={restaurantDetails.contact_address}
        onChange={e => handleChange('contact_address', e.target.value)}
      />
    </div>
  );

  const getRestaurantTown = () => (
    <div className={'restaurant-edit-group'}>
      <div className={'restaurant-edit-group-label'}>Restaurant’s Town</div>
      <input
        className={'restaurant-edit-group-input'}
        value={restaurantDetails.town}
        onChange={e => handleChange('town', e.target.value)}
      />
    </div>
  );

  const getPercentAndItemsInput = () => (
    <div className={'filter-container mb-4 pl-0'}>
      <div className='input-container'>
        <div className={'edit-restaurant-label'}>Limited Number of Items</div>
        <input
          type='number'
          className={'form-control'}
          value={restaurantDetails?.limitedNoOfItems}
          onChange={e => handleChange('limitedNoOfItems', parseInt(e.target.value))}
        />
      </div>
    </div>
  );

  const getRecieveOrderSwitch = () => (
    <div className={'filter-container mb-4 pl-0'}>
      <div className='input-container' style={{ marginRight: '86px' }}>
        <div className={'edit-restaurant-label'}>Recieve Order Email</div>
        <ToggleSwitch
          switchLabel={''}
          checked={restaurantDetails?.recieve_order_email}
          onChange={e => handleChange('recieve_order_email', e)}
        />
      </div>
    </div>
  );

  const getRadiusInput = () => (
    <div className={'filter-container mb-4 pl-0'}>
      <div className='input-container'>
        <div className={'edit-restaurant-label'}>Restaurant Radius</div>
        <input
          type='number'
          className={'form-control'}
          value={restaurantDetails?.restaurantRadius}
          onChange={e => handleChange('restaurantRadius', parseInt(e.target.value))}
        />
      </div>
    </div>
  );

  const getTaxPercent = () => (
    <div className={'filter-container mb-4 pl-0'}>
      <div className='input-container'>
        <div className={'edit-restaurant-label'}>Tax Percent</div>
        <input
          type='number'
          className={'form-control'}
          value={restaurantDetails?.taxPercent}
          onChange={e => handleChange('taxPercent', parseInt(e.target.value))}
        />
      </div>
    </div>
  );

  const getHubspotCompanyId = () => {
    if (!restaurantDetails?.hubspotCompanyId) {
      return (
        <div className={'restaurant-edit-group'}>
          <div className={'restaurant-edit-group-label'}>Hubspot Company Id</div>
          <input
            className={'restaurant-edit-group-input'}
            value={restaurantDetails?.hubspotCompanyId}
            onChange={e => handleChange('hubspotCompanyId', e.target.value)}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const getPrimeSubscriptionDetails = () => (
    <div className={'filter-container mb-4 pl-0'}>
      <div className=''>
        <div className={'edit-restaurant-label'}>Participate In Prime?</div>
        <ToggleSwitch
          switchLabel={''}
          checked={restaurantDetails?.participateInPrimeSubscription}
          onChange={e => handleChange('participateInPrimeSubscription', e)}
        />
      </div>
      {
        restaurantDetails?.participateInPrimeSubscription && (
          <div className="filter-container">
            <div className='input-container'>
              <div className={'edit-restaurant-label'}>Prime Subscription Discount(%)</div>
              <input
                type='number'
                className={'form-control'}
                value={restaurantDetails?.discountPercentForPrimeSubscription}
                onChange={e => handleChange('discountPercentForPrimeSubscription', parseInt(e.target.value))}
              />
            </div>
            <div className='ml-3'>
              <div className={'edit-restaurant-label'}>Free Prime Delivery?</div>
              <ToggleSwitch
                switchLabel={''}
                checked={restaurantDetails?.optInForFreePrimeDelivery}
                onChange={e => handleChange('optInForFreePrimeDelivery', e)}
              />
            </div>
          </div>
        )
      }
      
    </div>
  );

  const onAddressSelect = (address) => {
    setRestaurantDetails({
      ...restaurantDetails, 
      // eslint-disable-next-line @typescript-eslint/camelcase
      restaurant_geo: {
        latitude: address?.coords?.lat,
        longitude: address?.coords?.lng,
      },
      // eslint-disable-next-line @typescript-eslint/camelcase
      contact_address: address?.secondAddress + ', ' + address?.address,
      town: address?.town,
    });
    setShowSelectLocation(false);
  };

  const getSelectedAddress = () => {
    return (
      <div className={'restaurant-edit-group'}>
        <AddAddressModal
          modalOpen={showSelectLocation}
          onSuccess={onAddressSelect}
          onModalClose={() => setShowSelectLocation(false)}
          address={{
            coords: restaurantDetails?.restaurant_geo,
            town: restaurantDetails?.town,
            address: restaurantDetails?.contact_address,
          }}
        />
        <p className={'edit-restaurant-label'}>Location</p>
        {restaurantDetails?.contact_address ? (
          <div className="text-left d-flex">
            {restaurantDetails?.contact_address}
            <div
              className={'change-address-text'}
              onClick={() => {
                setShowSelectLocation(true);
              }}
            >
              &nbsp;Change?
            </div>
          </div>
        ) : (
          <ActionButton
            customClass="orange"
            text="Select location"
            onClick={() => {
              setShowSelectLocation(true);
            }}
            isLoading={false}
            active={true}
          />
        )}
      </div>
    );
  };

  const getBasicDetailsSection = () => (
    <div className={'restaurant-edit-basic-details-section'}>
      {getRestaurantName()}
      {getAboutRestaurant()}
      {getType()}
      {getCategory()}
      {getSelectedAddress()}
      {getRestaurantAddress()}
      {getRestaurantTown()}
      {getPercentAndItemsInput()}
      {getRecieveOrderSwitch()}
      {getRadiusInput()}
      {getTaxPercent()}
      {getHubspotCompanyId()}
      {getPrimeSubscriptionDetails()}
    </div>
  );

  const getImageSection = () => (
    <div className={'restaurant-edit-image-section'}>
      <div className={'restaurant-logo-image-section'}>
        <div className={'text-section'}>
          <div className={'image-label'}>Restaurant Logo</div>
          <ImageUpload
            onImageSelect={setLogo}
            pickerText={restaurantDetails.restaurant_logo ? 'Change' : 'Upload'}
            onImageUpload={img => handleChange('restaurant_logo', img)}
          />
        </div>
        <div>
          {logo ? (
            <img src={logo} />
          ) : (
            <div className={'upload-message'}>Upload Photo</div>
          )}
        </div>
      </div>
      <div className={'restaurant-cover-image-section'}>
        <div className={'text-section'}>
          <div className={'image-label'}>Display Photo</div>
          <div className={'image-helper-text'}>This is the photo that the customers will see</div>
          <ImageUpload
            onImageSelect={setCover}
            pickerText={restaurantDetails.image ? 'Change' : 'Upload'}
            onImageUpload={img => handleChange('image', img)}
          />
        </div>
        <div>
          {cover ? (
            <img src={cover} />
          ) : (
            <div className={'upload-message'}>Upload Photo</div>
          )}
        </div>
      </div>
    </div>
  );

  const getBaseChargesAndBaseMiles = () => (
    <div className={'filter-container mb-4 pl-0'}>
      <div className='input-container'>
        <div className={'edit-restaurant-label'}>Base Charges</div>
        <input
          type='number'
          className={'form-control'}
          value={restaurantDetails?.deliveryConfig?.baseCharges}
          onChange={e => setDeliveryConfig({ ...deliveryConfig, baseCharges: parseInt(e.target.value) })}
        />
      </div>
      <div className='input-container ml-5'>
        <div className={'edit-restaurant-label'}>Base Miles</div>
        <input
          type='number'
          className={'form-control'}
          value={restaurantDetails?.deliveryConfig?.baseMiles}
          onChange={e => setDeliveryConfig({ ...deliveryConfig, baseMiles: parseInt(e.target.value) })}
        />
      </div>
    </div>
  );

  const getDollarExtraMileAndMinuteForNormalOrder = () => (
    <div className={'filter-container mb-4 pl-0'}>
      <div className='input-container'>
        <div className={'edit-restaurant-label'}>$ Extra Mile (Normal Order)</div>
        <input
          type='number'
          className={'form-control'}
          value={restaurantDetails?.deliveryConfig?.dollarExtraMileNormalOrder}
          onChange={e => setDeliveryConfig({ ...deliveryConfig, dollarExtraMileNormalOrder: parseInt(e.target.value) })}
        />
      </div>
      <div className='input-container ml-5'>
        <div className={'edit-restaurant-label'}>$/Extra Minute (Normal Order)</div>
        <input
          type='number'
          className={'form-control'}
          value={restaurantDetails?.deliveryConfig?.dollarPerExtraMinuteNormalOrder}
          onChange={e => setDeliveryConfig({ ...deliveryConfig, dollarPerExtraMinuteNormalOrder: parseInt(e.target.value) })}
        />
      </div>
    </div>
  );

  const getBaseMinutesAndMaxDeliveryCharges = () => (
    <div className={'filter-container mb-4 pl-0'}>
      <div className='input-container'>
        <div className={'edit-restaurant-label'}>Base Minutes</div>
        <input
          type='number'
          className={'form-control'}
          value={restaurantDetails?.deliveryConfig?.baseMinutes}
          onChange={e => setDeliveryConfig({ ...deliveryConfig, baseMinutes: parseInt(e.target.value) })}
        />
      </div>
      <div className='input-container ml-5'>
        <div className={'edit-restaurant-label'}>Max Delivery Charges</div>
        <input
          type='number'
          className={'form-control'}
          value={restaurantDetails?.deliveryConfig?.maxDeliveryCharges}
          onChange={e => setDeliveryConfig({ ...deliveryConfig, maxDeliveryCharges: parseInt(e.target.value) })}
        />
      </div>
    </div>
  );

  const getDollarExtraMileAndPerExtraMinuteForFleetOrder = () => (
    <div className={'filter-container mb-4 pl-0'}>
      <div className='input-container'>
        <div className={'edit-restaurant-label'}>$/Extra Minute (Fleet Order)</div>
        <input
          type='number'
          className={'form-control'}
          value={restaurantDetails?.deliveryConfig?.dollarPerExtraMinuteForFleetOrder}
          onChange={e => setDeliveryConfig({ ...deliveryConfig, dollarPerExtraMinuteForFleetOrder: parseInt(e.target.value) })}
        />
      </div>
      <div className='input-container ml-5'>
        <div className={'edit-restaurant-label'}>$ Extra Mile (Fleet Order)</div>
        <input
          type='number'
          className={'form-control'}
          value={restaurantDetails?.deliveryConfig?.dollarExtraMileFleetOrder}
          onChange={e => setDeliveryConfig({ ...deliveryConfig, dollarExtraMileFleetOrder: parseInt(e.target.value) })}
        />
      </div>
    </div>
  );

  const getGasChargesPerMileAndBaseChargesForRoutedOrder = () => (
    <div className={'filter-container mb-4 pl-0'}>
      <div className='input-container'>
        <div className={'edit-restaurant-label'}>Gas Charges (Per Mile)</div>
        <input
          type='number'
          className={'form-control'}
          value={restaurantDetails?.deliveryConfig?.gasChargesPerMile}
          onChange={e => setDeliveryConfig({ ...deliveryConfig, gasChargesPerMile: parseInt(e.target.value) })}
        />
      </div>
      <div className='input-container ml-5'>
        <div className={'edit-restaurant-label'}>Base Charges (Routed Order)</div>
        <input
          type='number'
          className={'form-control'}
          value={restaurantDetails?.deliveryConfig?.baseChargesForRoutedOrder}
          onChange={e => setDeliveryConfig({ ...deliveryConfig, baseChargesForRoutedOrder: parseInt(e.target.value) })}
        />
      </div>
    </div>
  );

  const getDollarExtraMileAndPerExtraMinuteRoutedOrder = () => (
    <div className={'filter-container mb-4 pl-0'}>
      <div className='input-container'>
        <div className={'edit-restaurant-label'}>$/Extra Minute (Routed Order)</div>
        <input
          type='number'
          className={'form-control'}
          value={restaurantDetails?.deliveryConfig?.dollarPerExtraMinuteRoutedOrder}
          onChange={e => setDeliveryConfig({ ...deliveryConfig, dollarPerExtraMinuteRoutedOrder: parseInt(e.target.value) })}
        />
      </div>
      <div className='input-container ml-5'>
        <div className={'edit-restaurant-label'}>$ Extra Mile (Routed Order)</div>
        <input
          type='number'
          className={'form-control'}
          value={restaurantDetails?.deliveryConfig?.dollarExtraMileRoutedOrder}
          onChange={e => setDeliveryConfig({ ...deliveryConfig, dollarExtraMileRoutedOrder: parseInt(e.target.value) })}
        />
      </div>
    </div>
  );

  const getBaseChargesForFleetOrder = () => (
    <div className={'filter-container mb-4 pl-0'}>
      <div className='input-container'>
        <div className={'edit-restaurant-label'}>Base Charges (Fleet Order)</div>
        <input
          type='number'
          className={'form-control'}
          value={restaurantDetails?.deliveryConfig?.baseChargesForFleetOrder}
          onChange={e => setDeliveryConfig({ ...deliveryConfig, baseChargesForFleetOrder: parseInt(e.target.value) })}
        />
      </div>
    </div>
  );


  const getDeliveryConfigSection = () => (
    <div className={'restaurant-edit-basic-details-section'}>
      <div className={'restaurant-label'}>Delivery Config</div>
      {getBaseChargesAndBaseMiles()}
      {getDollarExtraMileAndMinuteForNormalOrder()}
      {getBaseMinutesAndMaxDeliveryCharges()}
      {getGasChargesPerMileAndBaseChargesForRoutedOrder()}
      {getDollarExtraMileAndPerExtraMinuteRoutedOrder()}
    </div>
  );

  const getDeliveryConfigFleetSection = () => (
    <div className={'restaurant-edit-basic-details-section'}>
      <div className={'restaurant-label'}>Delivery Config(Fleet Order)</div>
      {getDollarExtraMileAndPerExtraMinuteForFleetOrder()}
      {getBaseChargesForFleetOrder()}
    </div>
  );

  const getRevSharePercentConfig = () => (
    <div className={'restaurant-edit-basic-details-section'}>
      <div className={'restaurant-label'}>Rev Share Percent Config</div>
      <div className={'filter-container mb-4 pl-0'}>
        <div className='input-container'>
          <div className={'edit-restaurant-label'}>Rev Share Normal</div>
          <input
            type='number'
            className={'form-control'}
            value={restaurantDetails?.revSharePercentConfig?.normal}
            onChange={e => setRevSharePercentConfig({ ...revSharePercentConfig, normal: parseFloat(e.target.value) })}
          />
        </div>
        <div className='input-container ml-5'>
          <div className={'edit-restaurant-label'}>Rev Share Prime</div>
          <input
            type='number'
            className={'form-control'}
            value={restaurantDetails?.revSharePercentConfig?.prime}
            onChange={e => setRevSharePercentConfig({ ...revSharePercentConfig, prime: parseFloat(e.target.value) })}
          />
        </div>
        <div className='input-container ml-5'>
          <div className={'edit-restaurant-label'}>Rev Share Clip</div>
          <input
            type='number'
            className={'form-control'}
            value={restaurantDetails?.revSharePercentConfig?.clip}
            onChange={e => setRevSharePercentConfig({ ...revSharePercentConfig, clip: parseFloat(e.target.value) })}
          />
        </div>
        <div className='input-container ml-5'>
          <div className={'edit-restaurant-label'}>Rev Share Takeout</div>
          <input
            type='number'
            className={'form-control'}
            value={restaurantDetails?.revSharePercentConfig?.take_out}
            onChange={e => setRevSharePercentConfig({ ...revSharePercentConfig, 'take_out': parseFloat(e.target.value) })}
          />
        </div>
      </div>
    </div>
    
  );


  const getHeader = () => (
    <div className={'header'}>
      <div>
        <BackButton />
        <div>{restaurantDetails.restaurant_name}</div>
        <div>{restaurantId}</div>
      </div>
      <div>
        <ActionButton
          text={'Save Changes'}
          active={true}
          isLoading={false}
          onClick={updateRestaurantInfo}
          customClass={'btn-primary'}
        />
      </div>
    </div>
  );

  return (
    <div className={'edit-restaurant-container'}>
      {updateLoading && <Loading />}
      {getHeader()}
      <div className={'edit-restaurant-details-container'}>
        {getImageSection()}
        {getBasicDetailsSection()}
        {getDeliveryConfigSection()}
        {getDeliveryConfigFleetSection()}
        {getRevSharePercentConfig()}
        {getRestaurantTimingsSection()}
        <PreScheduleConfig handleChange={handleChange} restaurantDetails={restaurantDetails} />
        <ColorTheme setRestaurantDetails={setRestaurantDetails} restaurantDetails={restaurantDetails} />
        {getContactSection()}
        {getBankingSection()}
        {getSocialMediaSection()}
        {getActionSection()}
      </div>
    </div>
  );
};

export { EditRestaurantInfo };