import { Types } from '../actions/types';
import { ILead } from '../../interfaces';
import { AnyAction } from 'redux';

const initialState: ILead.StateToProps = {
  error: false,
  loading: false,
  leads: [],
  leadsCount: 0,
};

export function lead(
  state: ILead.StateToProps = initialState,
  action: AnyAction,
) {
  switch (action.type) {
  case Types.FETCH_LEADS:
    return {
      ...state,
      loading: true,
    };

  case Types.FETCH_LEADS_SUCCESS:
    state.leads.splice(0, state.leads.length);
    state.leads = state.leads.concat(action.payload.leads);
    return {
      ...state,
      loading: false,
      leadsCount: action.payload.leadsCount,
    };

  case Types.FETCH_LEADS_FAILED:
    return {
      ...state,
      leads: [],
      loading: false,
      error: true,
      leadsCount: 0,
    };

  case Types.FETCH_LEAD_DETAIL:
    return {
      ...state,
      loading: true,
    };

  case Types.FETCH_LEAD_DETAIL_SUCCESS:
    updateLeadWithDetail(state.leads, action.payload.lead);
    return {
      ...state,
      loading: false,
    };

  case Types.FETCH_LEAD_DETAIL_FAILED:
    return {
      ...state,
      loading: false,
      error: true,
    };

  default:
    return state;
  }
}

function updateLeadWithDetail(leads: any, leadDetail: any) {
  if (leads) {
    leadDetail.lead = leadDetail.lead.toJSON();
    leads.forEach((lead: any) => {
      if (lead.objectId === leadDetail.lead.objectId) {
        lead.user = leadDetail.lead.user;
        lead.documents = leadDetail.documents.map((doc: any) => doc.toJSON());
      }
    });
  }

}
