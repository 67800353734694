import { cloudFunction } from 'services/parse';

export const getRestaurantCollections = (data: any ) => {
  return cloudFunction('fetchAllRestaurantCollections',{ ...data });
};

export const addRestaurantCollection = (name: string) => {
  return cloudFunction('createRestaurantCollection', { name });
};

export const deleteRestaurantCollection = (id: string) => {
  return cloudFunction('deleteRestaurantCollection', { id });
};

export const addItemsToRestaurantCollection = (collectionId: string, restaurantIds: string[]) => {
  return cloudFunction('addRestaurantToRestaurantCollection', { collectionId, restaurantIds });
};

export const getRestaurantCollectionDetail = (id: string) => {
  return cloudFunction('fetchRestaurantCollectionDetailForAdmin', { id });
};

export const removeItemFromRestaurantCollection = (collectionId: string, restaurantId: string) => {
  return cloudFunction('removeRestaurantFromRestaurantCollection', { collectionId, restaurantId });
};

export const updateRestaurantCollection = (data: Record<string,any>) => {
  return cloudFunction('updateRestaurantCollection', { ...data });
};
