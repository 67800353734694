import React from 'react';
import styles from './index.module.scss';
import CopyToClipBoard from 'components/CopyToClipBoard';
import TableLink from 'TableLink';



const DynamicLink = ({ link = '' }) => {
  const getDynamicLink = () => {
    if (link && link.length > 0) {
      return (
        <div className={`d-flex ${styles['link']}`}>
          <TableLink
            text={link}
            linkTo={link}
          />
          {link && <CopyToClipBoard copyText={link} />}
        </div>
      );
    } else return <div className={styles['detail']}>{'N/A'}</div>;
  };

  return (
    <div className={styles['container']}>
      <div className={styles['label']}>{'Dynamic Link URL'}</div>
      {getDynamicLink()}
    </div>
  );
};

export default DynamicLink;