import React from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import DecimalInput from 'components/DecimalInput';
import WholeNumberInput from 'components/WholeNumberInput';
import styles from './index.module.scss';
import DatePicker from 'react-datepicker';
import { DATE_FORMATS, DISCOUNT_TYPES, formatDate } from 'utils';
import moment from 'moment';
import Currency from 'components/Currency';
import ActionCheckbox from 'components/ToggleSwitch';
import DropDown from 'components/Dropdown';

const BasicDetails = props => {
  const { detailsEditable,updateDiscountDetailsFn,setDetailsEditable,discount,
    setDiscount,updateLoading,loading,startDate,setStartDate,endDate,setEndDate }=props;

  const handleEdit = () => {
    if (detailsEditable) {
      updateDiscountDetailsFn();
    } else {
      setDetailsEditable(true);
    }
  };

  const getActionButtons = () => (
    <div className={styles['action-buttons']}>
      <ActionButton
        text={detailsEditable ? 'Save' : 'Edit'}
        active={!updateLoading}
        isLoading={updateLoading}
        onClick={handleEdit}
        customClass={'mb-3'}
      />
      {detailsEditable ?
        <ActionButton
          text={'Cancel'}
          active={true}
          isLoading={false}
          onClick={() => setDetailsEditable(false)}
          customClass={'mb-3 ml-2'}
        />
        : null}
    </div>
  );

  const getPercentOrAmount = () => {
    if ((discount?.type).includes('FIXED')) {
      return (
        <div className={styles['details-group']}>
          <div className={styles['label']}>Discount Amount*</div>
          {detailsEditable ? (
            <WholeNumberInput
              value={discount.discountAmount}
              disabled={updateLoading}
              onChange={e => setDiscount({ ...discount, discountAmount: e })}
            />
          ) : (
            <div className={styles['detail']}><Currency amount={discount.discountAmount} /></div>
          )}
        </div>
      );
    } else {
      return (
        <div className={styles['details-group']}>
          <div className={styles['label']}>Discount Percent*</div>
          {detailsEditable ? (
            <DecimalInput
              value={discount.discountPercent}
              disabled={updateLoading}
              onChange={e => setDiscount({ ...discount, discountPercent: e })}
            />
          ) : (
            <div className={styles['detail']}>{discount.discountPercent}%</div>
          )}
        </div>
      );
    }
  };

  const getGlobalLimit = () => (
    <div className={styles['details-group']}>
      <div className={styles['label']}>Global Limit</div>
      {detailsEditable ? (
        <WholeNumberInput
          value={discount.globalLimit}
          disabled={updateLoading}
          onChange={e => setDiscount({ ...discount, globalLimit: e })}
          required={false}
        />
      ) : (
        <div className={styles['detail']}>{discount.globalLimit ?? '-'}</div>
      )}
    </div>
  );

  const getUserLimit = () => (
    <div className={styles['details-group']}>
      <div className={styles['label']}>User Limit</div>
      {detailsEditable ? (
        <WholeNumberInput
          disabled={updateLoading}
          value={discount.userLimit}
          onChange={e => setDiscount({ ...discount, userLimit: e })}
          required={false}
        />
      ) : (
        <div className={styles['detail']}>{discount.userLimit ?? '-'}</div>
      )}
    </div>
  );

  const getDiscountLimits = () => (
    <>
      {getGlobalLimit()}
      {getUserLimit()}
    </>
  );

  const getStartDate = () => (
    <div className={styles['details-group']}>
      <div className={styles['label']}>Start Date*</div>
      {detailsEditable ? (
        <div className={styles['date-picker']}>
          <DatePicker
            wrapperClassName="w-100"
            className={'form-control'}
            showTimeInput
            dateFormat={DATE_FORMATS.MM_DD_YYYY__EE_HH_MM_A}
            selected={startDate ? moment(startDate).toDate() : null}
            onChange={(e: any) => setStartDate(moment(e).toISOString())}
          />
        </div>
      ) : (
        <div className={styles['detail']}>{formatDate(startDate, DATE_FORMATS.MM_DD_YYYY_HH_MM_A)}</div>
      )}
    </div>
  );

  const getEndDate = () => (
    <div className={styles['details-group']}>
      <div className={styles['label']}>End Date</div>
      {detailsEditable ? (
        <div className={styles['date-picker']}>
          <DatePicker
            wrapperClassName="w-100"
            className={'form-control'}
            dateFormat={DATE_FORMATS.MM_DD_YYYY__EE_HH_MM_A}
            showTimeInput
            selected={endDate ? moment(endDate).toDate() : null}
            onChange={(e: any) => setEndDate(moment(e).toISOString())}
          />
        </div>
      ) : (
        <div className={styles['detail']}>{formatDate(endDate, DATE_FORMATS.MM_DD_YYYY_HH_MM_A)}</div>
      )}
    </div>
  );

  const getDates = () => (
    <>
      {getStartDate()}
      {getEndDate()}
    </>
  );

  const getDiscountDetail = () => (
    <>
      <div className={styles['details-group']}>
        <div className={styles['label']}>Discount ID</div>
        <div className={`${styles['detail']} ${styles['id']}`}>{discount.objectId}</div>
      </div>
      <div className={styles['details-group']}>
        <div className={styles['label']}>Created At</div>
        <div className={styles['detail']}>{formatDate(discount.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A)}</div>
      </div>
    </>
  );

  const getDiscountCode = () => (
    <div className={styles['details-group']}>
      <div className={styles['label']}>Discount Code*</div>
      {detailsEditable ? (
        <input
          className={'form-control'}
          value={discount.code}
          onChange={e => setDiscount({ ...discount, code: e.target.value })}
        />
      ) : (
        <div className={styles['detail']}>{discount.code ?? '-'}</div>
      )}
    </div>
  );

  const getDiscountType = () => (
    <div className={styles['details-group']}>
      <div className={styles['label']}>Discount Type*</div>
      {detailsEditable ? (
        <DropDown name="Discount Type" value={discount.type} onChange={(e) => setDiscount({ ...discount, type: e })} options={DISCOUNT_TYPES} />
      ) : (
        <div className={styles['detail']}>{discount.type ?? '-'}</div>
      )}
    </div>
  );

  const getActiveCheckBox=()=>{
    if(detailsEditable){
      return (
        <ActionCheckbox
          switchLabel=''
          checked={discount?.active ?? false}
          disabled={loading}
          onChange={(e) => setDiscount({ ...discount, active: e })}
        />
      );
    }else{
      return discount?.active ? 'True' : 'False';
    }
  };

  const getOnlyOnFirstOrder=()=>{
    if(detailsEditable){
      return (
        <ActionCheckbox
          switchLabel=''
          checked={discount?.onlyOnFirstOrder}
          disabled={loading}
          onChange={(e) => setDiscount({ ...discount, onlyOnFirstOrder: e })}
        />
      );
    }else{
      return discount?.onlyOnFirstOrder ? 'True' : 'False';
    }
  };

  const getOnlyForPickup=()=>{
    if(detailsEditable){
      return (
        <ActionCheckbox
          switchLabel=''
          checked={discount?.onlyForPickup}
          disabled={loading}
          onChange={(e) => setDiscount({ ...discount, onlyForPickup: e })}
        />
      );
    }else{
      return discount?.onlyForPickup ? 'True' : 'False';
    }
  };

  const getIsAutomatic=()=>{
    if(detailsEditable){
      return (
        <ActionCheckbox
          switchLabel=''
          checked={discount?.isAutomatic}
          disabled={loading}
          onChange={(e) => setDiscount({ ...discount, isAutomatic: e })}
        />
      );
    }else{
      return discount?.isAutomatic ? 'True' : 'False';
    }
  };

  const getCheckBoxContainer = () => (
    <div className={styles['checkbox-container']}>
      <div className={styles['info']}>
        <div className={styles['label']}>Active</div>
        {getActiveCheckBox()}
      </div>
      <div className={styles['info']}>
        <div className={styles['label']}>Only on First Order</div>
        {getOnlyOnFirstOrder()}
      </div>
      <div className={styles['info']}>
        <div className={styles['label']}>Only For Pickup</div>
        {getOnlyForPickup()}
      </div>
      <div className={styles['info']}>
        <div className={styles['label']}>Is Automatic</div>
        {getIsAutomatic()}
      </div>
    </div>
  );

  const getLeftSection = () => (
    <div>
      {getDiscountDetail()}
      {getDiscountLimits()}
      {getDates()}
    </div>
  );

  const getRightSection = () => (
    <div >
      {getActionButtons()}
      {getDiscountCode()}
      {getDiscountType()}
      {getPercentOrAmount()}
    </div>
  );
  
  return (
    <>
      <div className={styles['basic-details-section']}>
        <div className={styles['details-section']}>
          {getLeftSection()}
          {getRightSection()}
        </div>
      </div >
      {getCheckBoxContainer()}
    </>
  );
};

export default BasicDetails;