import React, { useCallback, useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import _ from 'lodash';
import theme from './index.module.scss';

const NameFilterAutosuggest = props => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (props.entityId === '') setValue(props.entityId);
  }, [props.entityId]);
  
  const onChange = (e, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    if (value.length >= 2) {
      const result = await props.fetchSuggestions(value, props.onlyDriver);
      setSuggestions(result);
    }
  };

  const onSuggestionsFetchRequestedDebounced = useCallback(_.debounce(onSuggestionsFetchRequested, 500), []);

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = suggestion => suggestion.full_name;

  const renderSuggestion = suggestion => {
    return (
      <div>
        <span>{suggestion.full_name}</span>
      </div>
    );
  };

  const onSuggestionSelected = (e, { suggestion }) => {
    props.onSuggestionSelected(suggestion.id);
  };

  const inputProps = {
    placeholder: props.placeholder,
    value,
    onChange,
  };

  return (
    <>
      <Autosuggest
        theme={theme}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequestedDebounced}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    </>
  );
};

export default NameFilterAutosuggest;
