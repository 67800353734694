import { cloudFunction } from 'services/parse';

export const createRestaurantTag = (name: string) => cloudFunction('createRestaurantTag', { name });

export const createDishTag = (name: string) => cloudFunction('createDishTag', { name });

export const deleteRestaurantTag = (id: string) => cloudFunction('deleteRestaurantTag', { id });

export const deleteDishTag = (id: string) => cloudFunction('deleteDishTag', { id });

export const fetchRestaurantTags = async () => {
  let response = await cloudFunction('fetchRestaurantTags', {});
  response = response.map(tag => {
    return {
      id: tag.objectId,
      name: tag.name,
    };
  });
  return response;
};

export const fetchDishTags = async () => {
  let response = await cloudFunction('fetchDishTags', {});
  response = response.map(tag => {
    return {
      id: tag.objectId,
      name: tag.name,
    };
  });
  return response;
};
