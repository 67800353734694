import RenderInfo from 'components/RenderInfo';
import React from 'react';
import styles from './index.module.scss';

const PrimeDiscountData = ({ details }) => {
  if (!details?.primeSubscriptionDiscountData) return null;

  const { 
    primeSubscriptionDiscountData: { 
      delivery: { total: deliveryTotal = 0 } = {},
      subtotals: { total: subtotalsTotal = 0, merchants = [] } = {},
    } = {},
  } = details;

  const getMerchantData = (merchant, index) => {
    const {
      id: merchantId,
      discountAmount = 0,
    } = merchant;
    return (
      <div key={`${index}`} className={styles['basic-info']}>
        <div className='d-flex'>
          <span className='mr-2'>{index + 1}. &nbsp;</span>
          <div className={styles['info']}>
            <RenderInfo heading="Merchant Id" value={merchantId} link={`/restaurants/${merchantId}`} />
          </div>
        </div>
        <div className={styles['info']}>
          <RenderInfo heading="Discoun tAmount" value={discountAmount} type='currency' />
        </div>
        <div className={styles['info']}></div>
        <div className={styles['info']}></div>
      </div>
    );
  };

  return (
    <div className={styles['section-container']}>
      <div className={styles['section-header']}>
        <div className={styles['heading']}>Prime discount data</div>
      </div>
      <div className={styles['basic-info']}>
        <div className={styles['info']}>
          <RenderInfo heading="Delivery total" value={deliveryTotal} type="currency" />
          <RenderInfo heading="Subtotals" value={subtotalsTotal} type="currency" />
        </div>
      </div>
      {merchants?.map(getMerchantData)}
    </div>
  );
};

export default PrimeDiscountData;