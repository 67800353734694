import ActionButton from 'components/ActionButton/ActionButton';
import BackButton from 'components/BackButton';
import ImageUpload from 'components/ImageUpload';
import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from 'react';
import { createDish, getDishDetail, saveDish, updateDishImage } from 'services/api/dishes';
import { fetchRestaurantDetailsByAdmin } from 'services/api/restaurant';
import { showError } from 'utils/notifications';
import './index.scss';
import _ from 'lodash';

const AddDish = props => {
  const [loading, setLoading] = useState<boolean>(true);
  const [dishDetails, setDishDetails] = useState<Record<string, any>>({});
  const [dishImage, setDishImage] = useState<string>('');
  const [dishVariants, setDishVariants] = useState<Record<string, any>>({});
  const [categories, setCategories] = useState<string[]>([]);
  const [addLoading, setAddLoading] = useState<boolean>(false);
  const { dishId, restaurantId } = props.match.params;
  const isEdit = dishId !== 'dish';

  const fetchDishDetails = async () => {
    try {
      const dish = await getDishDetail(dishId);
      setDishDetails(dish);
      setDishVariants(dish?.variants);
      setDishImage(dish.image?._url || '');
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchRestaurantDetails = async () => {
    try {
      const response = await fetchRestaurantDetailsByAdmin(restaurantId);
      setCategories(response.categories || []);
    } catch (e) {
      showError(e);
    } finally {
      if (isEdit) {
        fetchDishDetails();
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchRestaurantDetails();
  }, []);

  const updateDish = async () => {
    setAddLoading(true);
    const data = {
      dishId,
      restaurantId,
      name: dishDetails.name,
      description: dishDetails.description,
      price: dishDetails.price,
      taxPercent: dishDetails.taxPercent,
      category: dishDetails.category,
      minSelectedVariants: dishDetails?.minSelectedVariants,
      maxSelectedVariants: dishDetails?.maxSelectedVariants,
      quantity: dishDetails.quantity,
    };
    try {
      await saveDish(data);
      await updateDishImage({
        id: dishId,
        file: dishDetails.image,
      });
      props.history.replace(`/dishes/${dishId}`);
    } catch (e) {
      showError(e);
      setAddLoading(false);
    }
  };

  const createDishFn = async () => {
    setAddLoading(true);
    const data = {
      restaurantId,
      name: dishDetails.name,
      description: dishDetails.description,
      category: dishDetails.category,
      price: dishDetails.price,
      taxPercent: dishDetails.taxPercent,
      minSelectedVariants: dishDetails?.minSelectedVariants,
      maxSelectedVariants: dishDetails?.maxSelectedVariants,
      quantity: dishDetails.quantity,
    };
    try {
      const response = await createDish(data);
      await updateDishImage({
        id: response.id,
        file: dishDetails.image,
      });
      props.history.replace(`/dishes/${response.id}`);
    } catch (e) {
      showError(e);
      setAddLoading(false);
    }
  };

  const handleClick = () => {
    if (isEdit) {
      updateDish();
    } else {
      createDishFn();
    }
  };

  const handleChange = (key: string, value: any) => {
    setDishDetails({ ...dishDetails, [key]: value });
  };

  const getActionSection = () => (
    <div className={'add-item-action-section'}>
      <ActionButton
        text={'Cancel'}
        active={true}
        isLoading={false}
        onClick={() => props.history.goBack()}
      />
      <ActionButton
        text={'Save Changes'}
        active={true}
        isLoading={false}
        onClick={handleClick}
        customClass={'btn-primary'}
      />
    </div>
  );

  const getQuantitySection = () => (
    <div className={'detail-group'}>
      <div className={'field-name'}>Quantity</div>
      <div className={'field-input'}>
        <input
          className={'form-control'}
          type={'number'}
          step={1}
          value={dishDetails?.quantity}
          onChange={e => handleChange('quantity', parseInt(e.target.value))}
        />
      </div>
    </div>
  );

  const getTaxSection = () => (
    <div className={'detail-group'}>
      <div className={'field-name'}>Tax Percent</div>
      <div className={'field-input'}>
        <input
          className={'form-control'}
          type={'number'}
          step={0.01}
          value={dishDetails.taxPercent}
          onChange={e => handleChange('taxPercent', parseFloat(e.target.value))}
        />
      </div>
    </div>
  );

  const getMinSelectedVariantsSection = () => (
    <div className={'detail-group'}>
      <div className={'field-name'}>Min Selected Variants</div>
      <div className={'field-input'}>
        <input
          className={'form-control'}
          type={'number'}
          step={1}
          value={dishDetails.minSelectedVariants}
          onChange={e => handleChange('minSelectedVariants', parseFloat(e.target.value))}
        />
      </div>
    </div>
  );

  const getMaxSelectedVariantsSection = () => (
    <div className={'detail-group'}>
      <div className={'field-name'}>Max Selection Variants</div>
      <div className={'field-input'}>
        <input
          className={'form-control'}
          type={'number'}
          step={1}
          value={dishDetails.maxSelectedVariants}
          onChange={e => handleChange('maxSelectedVariants', parseFloat(e.target.value))}
        />
      </div>
    </div>
  );

  const getCategorySection = () => (
    <div className={'detail-group'}>
      <div className={'field-name'}>Category</div>
      <div className={'field-input'}>
        {categories.length ? (
          <select
            className={'form-control'}
            value={dishDetails.category}
            onChange={e => handleChange('category', e.target.value)}
          >
            <option value={''} hidden={!!dishDetails.category}>Please select a category</option>
            {categories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        ) : (
          <input
            className={'form-control'}
            value={dishDetails.category || ''}
            onChange={e => handleChange('category', e.target.value)}
          />
        )}
      </div>
    </div>
  );

  const getPriceSection = () => (
    <div className={'detail-group'}>
      <div className={'field-name'}>Price</div>
      <div className={'field-input'}>
        <input
          className={'form-control'}
          type={'number'}
          step={0.01}
          value={dishDetails.price}
          onChange={e => handleChange('price', parseFloat(e.target.value))}
        />
      </div>
    </div>
  );

  const getImageSection = () => (
    <div className={'detail-group'}>
      <div className={'field-name'}>
        <div>Image</div>
        <div className={'image-upload-container'}>
          <ImageUpload
            pickerText={dishDetails.image ? 'Change' : 'Select'}
            onImageSelect={setDishImage}
            onImageUpload={img => {
              handleChange('image', img);
              setDishImage(img.url());
            }}
          />
        </div>
      </div>
      <div className={'field-input'}>
        <img src={dishImage || require('assets/placeholder.svg')} />
      </div>
    </div>
  );

  const getDescriptionSection = () => (
    <div className={'detail-group'}>
      <div className={'description-field-name'}>
        <div className={'field-name'}>Description</div>
        <div className={'description-label-helper'}>
          Tell us something about the item, the ingredients and what makes it special
        </div>
      </div>
      <div className={'field-input'}>
        <textarea
          className={'form-control'}
          value={dishDetails.description || ''}
          onChange={e => handleChange('description', e.target.value)}
        />
      </div>
    </div>
  );
  const getNameSection = () => (
    <div className={'detail-group'}>
      <div className={'field-name'}>Name</div>
      <div className={'field-input'}>
        <input
          className={'form-control'}
          value={dishDetails.name || ''}
          onChange={e => handleChange('name', e.target.value)}
        />
      </div>
    </div>
  );

  if (loading) return <Loading />;

  return (
    <div className={'add-menu-item-container'}>
      {addLoading && <Loading />}
      <div className={'header'}>
        <div>{isEdit ? 'Edit' : 'Add'} Menu Item</div>
        <BackButton />
      </div>
      <div className={'menu-item-fields-container'}>
        {getNameSection()}
        {getDescriptionSection()}
        {getImageSection()}
        {getPriceSection()}
        {getQuantitySection()}
        {getCategorySection()}
        {getTaxSection()}
        {getMinSelectedVariantsSection()}
        {getMaxSelectedVariantsSection()}
        {getActionSection()}
      </div>
    </div>
  );
};

export { AddDish };