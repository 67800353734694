import React, { useState } from 'react';
import ViewDate from 'components/Date/ViewDate';

const SingleNote = props => {
  const [collapsed, setCollapsed] = useState(true);
  const { createdAt, note, by = {}} = props.note;
  // eslint-disable-next-line @typescript-eslint/camelcase
  const { full_name } = by;

  return (
    <div className={`single-note ${collapsed ? 'collapsed' : ''}`}>
      <div className='note-content'>{note}</div>
      <div className='note-footer'>
        <div className='note-creator'>{full_name}</div>     {/* eslint-disable-line */}
        <ViewDate customClass={'note-created-at'} date={createdAt && createdAt.iso} />
        <i className='fa fa-chevron-up' onClick={() => setCollapsed(!collapsed)} />
      </div>
    </div>
  );
};

export default SingleNote;
