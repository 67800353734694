import ActionButton from 'components/ActionButton/ActionButton';
import { DateRangePickerWrapper } from 'components/Date/DateRangePicker';
import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import moment from 'moment';
import DisputeStatuses from 'components/DisputeStatuses';

const OrderReviewsFilters = props => {
  const [startDate, setStartDate] = useState<string | null>(moment().subtract(1, 'week').toISOString());
  const [endDate, setEndDate] = useState<string | null>(moment().toISOString());
  const [startDueDate, setStartDueDate] = useState<string | null>(moment().subtract(1, 'week').toISOString());
  const [endDueDate, setEndDueDate] = useState<string | null>(moment().toISOString());
  const [status, setStatus] = useState('');

  useEffect(() => {
    const {
      startDate,
      endDate,
      endDueDate,
      startDueDate,
      status,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });

    if (startDate) setStartDate(startDate.toString());
    if (endDate) setEndDate(endDate.toString());
    if (startDueDate) setStartDate(startDueDate.toString());
    if (endDueDate) setEndDate(endDueDate.toString());
    if (status) setStatus(status.toString());
  }, []);

  const resetFilter = () => {
    setStartDate('');
    setEndDate('');
    setStartDueDate('');
    setEndDueDate('');

    const filterData = {
      startDate: '',
      endDate: '',
      startDueDate: '',
      endDueDate: '',
      status: '',
    };
    props.onFilterSubmit(filterData);
  };

  const onFilterSubmit = () => {
    const filterData: Record<string, any> = {
      startDate,
      endDate,
      startDueDate,
      endDueDate,
      status,
    };
    props.onFilterSubmit(filterData);
  };

  const onDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    setStartDate(moment(startDate).toISOString());
    setEndDate(moment(endDate).toISOString());
  };

  const onDueDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    setStartDueDate(moment(startDate).toISOString());
    setEndDueDate(moment(endDate).toISOString());
  };

  return (
    <form className='filter-container'>
      <div className='input-container mb-2 ml-2'>
        <label>Status</label>
        <DisputeStatuses
          verticalType={status}
          disabled={false}
          onChange={(e) => setStatus(e.target.value)}
          isFilter
        />
      </div>
      <div className='input-container mb-2 ml-2'>
        <label>Date</label>
        <DateRangePickerWrapper
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onDateSelectionChange}
        />
      </div>
      <div className='input-container mb-2 ml-2'>
        <label>Due Date</label>
        <DateRangePickerWrapper
          startDate={startDueDate}
          endDate={endDueDate}
          onDatesChange={onDueDateSelectionChange}
        />
      </div>
      <div className={'buttons-container'}>
        <ActionButton
          text={'Submit'}
          isLoading={false}
          active={true}
          customClass={'btn-primary mb-2'}
          onClick={onFilterSubmit}
        />
        <ActionButton
          text={'Reset'}
          isLoading={false}
          active={true}
          customClass={'btn-primary ml-4 mb-2'}
          onClick={resetFilter}
        />
      </div>
    </form>
  );
};

export default OrderReviewsFilters;