import ActionButton from 'components/ActionButton/ActionButton';
import BackButton from 'components/BackButton';
import { Loading } from 'components/loading/Loading';
import ToggleSwitch from 'components/ToggleSwitch';
import React, { useEffect, useState } from 'react';
import { formatDate } from 'utils';
import { showError, showSuccess } from 'utils/notifications';
import '../../dishes/dish-details/index.scss';
import DataTable from 'react-data-table-component';
import { tableStyles } from 'css/tableStyles';
import { getDishCollectionDetail, removeItemFromDishCollection, updateDishCollection } from 'services/api/dishCollection';
import AddDishCollectionItemModal from '../../../components/AddDishCollectionItemModal/index';
import ActionIcon from 'components/ActionIcon';
import { ACTION_ICON_TYPES } from '../../../utils/constant';
import TableLink from 'TableLink';
import { useMemo } from 'react';
import ImageUpload from 'components/ImageUpload';
import VerticalTypePicker from 'components/VerticalTypePicker';
import DynamicLink from 'components/DynamicLink';

const DishCollectionDetails = props => {
  const [collectionDetails, setCollectionDetails] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [dishDeleteLoading, setDishDeleteLoading] = useState<boolean>(false);
  const [dishImage, setDishImage] = useState<string>('');
  const [detailsEditable, setDetailsEditable] = useState<boolean>(false);
  const { collectionId } = props.match.params;

  const fetchDishCollectionDetails = async () => {
    setLoading(true);
    try {
      const response = await getDishCollectionDetail(collectionId);
      setCollectionDetails(response);
      setDishImage(response?.icon?.url);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDishCollectionDetails();
  }, []);

  const itemColumns = useMemo(() => [
    {
      name: 'Dish ID',
      selector: 'objectId',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/dishes/${row.objectId}`}
          text={row.objectId}
          openInNewTab={true}
        />
      ),
    },
    {
      name: 'Dish Name',
      selector: 'name',
      width: '600px',
      /* eslint-disable react/display-name */
      cell: row => (
        <div className={'dish-list-dish-detail'}>
          {row.image && (
            <div className={'dish-image'}>
              <img src={row?.image?.url} />
            </div>
          )}
          <div className={'dish-name-and-desc'}>
            <div className={'dish-name-and-id'}>
              <div className={'dish-name'}>{row.name}</div>
            </div>
            <div className={'dish-description'}>{row.description}</div>
          </div>
        </div>
      ),
    },
    {
      name: '',
      selector: '',
      cell: row => (
        <ActionIcon
          actionType={ACTION_ICON_TYPES.DELETE}
          onClick={() => deleteDishFn(row.id)}
          loading={dishDeleteLoading}
        />
      ),
    },
  ], [dishDeleteLoading]);

  const updateDishCollectionFn = async (type: string) => {
    setLoading(true);
    try {
      const data = {};
      if (type === 'featured') {
        data['featured'] = !collectionDetails.featured;
      }
      if (type === 'active') {
        data['active'] = !collectionDetails.active;
      }
      if (type === 'basicDetails') {
        data['verticalType'] = collectionDetails.verticalType;
        data['name'] = collectionDetails.name;
      }
      await updateDishCollection({ id: collectionId, data });
      setDetailsEditable(false);
      await fetchDishCollectionDetails();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateImage = async (url: string) => {
    try {
      const response = await updateDishCollection({ id: collectionId, data: { icon: url }});
      setCollectionDetails(response);
    } catch (e) {
      showError(e);
    }
  };

  const deleteDishFn = async (itemId: string) => {
    setDishDeleteLoading(true);
    try {
      await removeItemFromDishCollection(collectionId, itemId);
      showSuccess('Dish Removed successfully.');
      fetchDishCollectionDetails();
    } catch (e) {
      showError(e);
    } finally {
      setDishDeleteLoading(false);
    }
  };

  const getDishCollectionName = () => {
    return (
      <div className={'dish-details-group'}>
        <div className={'label'}>Collection Name</div>
        {detailsEditable ? (
          <input
            className={'form-control'}
            value={collectionDetails.name || ''}
            onChange={e => setCollectionDetails({ ...collectionDetails, name: e.target.value })}
          />
        ) : (
          <div className={'detail'}>{collectionDetails.name}</div>
        )}
      </div>
    );
  };

  const getDishDetails = () => (
    <div className={'dish-basic-details-section'}>
      <div className={'d-flex justify-content-between'}>
        <div className={'dish-details-group'}>
          <div className={'label'}>Collection ID</div>
          <div className={'detail'}>{collectionDetails.objectId}</div>
        </div>
        {getDishCollectionName()}
        <div className={'dish-details-group'}>
          <div className={'label'}>Created At</div>
          <div className={'detail'}>{formatDate(collectionDetails.createdAt)}</div>
        </div>
        <div className={'dish-details-group'}>
          <div className={'label'}>Vertical Type</div>
          {detailsEditable ? (
            <VerticalTypePicker
              verticalType={collectionDetails?.verticalType}
              disabled={false}
              onChange={(e) => setCollectionDetails({ ...collectionDetails, verticalType: e.target.value })}
            />
          ) : (
            <div className={'detail'}>{collectionDetails?.verticalType ?? '-'}</div>
          )}
        </div>
        <div className={'dish-details-group'}>
          <div className={'label'}>Featured</div>
          <ToggleSwitch
            switchLabel={''}
            checked={collectionDetails.featured}
            onChange={() => updateDishCollectionFn('featured')}
            disabled={false}
          />
        </div>
        <div className={'dish-details-group'}>
          <div className={'label'}>Active</div>
          <ToggleSwitch
            switchLabel={''}
            checked={collectionDetails.active}
            onChange={() => updateDishCollectionFn('active')}
            disabled={false}
          />
        </div>
      </div>
      <div className='mt-3'>
        <DynamicLink link={collectionDetails?.dynamicLink} />
      </div>
    </div>
  );

  const getItemSection = () => {
    return (
      <DataTable
        title={'Dishes List'}
        actions={(
          <ActionButton
            text={`Add Dish +`}
            active={true}
            isLoading={false}
            customClass={'btn-primary mb-2'}
            onClick={() => setAddModalOpen(true)}
          />
        )}
        keyField="objectId"
        columns={itemColumns}
        data={collectionDetails.items}
        highlightOnHover={true}
        customStyles={tableStyles}
      />
    );
  };

  const getHeader = () => (
    <div className={'header'}>
      <div>Dish Collection Details</div>
      <BackButton />
    </div>
  );

  const getImageSection = () => (
    <div className={'detail-group'}>
      <div className={'field-name'}>
        <div>Image</div>
        <ImageUpload
          pickerText={collectionDetails.icon ? 'Change' : 'Select'}
          onImageSelect={setDishImage}
          onImageUpload={img => {
            handleUpdateImage(img);
            setDishImage(img.url());
          }}
          containerClass='image-upload-container'
        />
      </div>
      <div className={'field-input'}>
        <img src={dishImage || require('assets/placeholder.svg')} />
      </div>
    </div>
  );

  const getActionButtons = () => {
    return (
      <div className={'d-flex justify-content-end'}>
        {detailsEditable ? (
          <ActionButton
            text={'Save'}
            active={true}
            isLoading={false}
            onClick={() => updateDishCollectionFn('basicDetails')}
            customClass="btn-primary mb-3"
          />
        ) : null
        }
        <ActionButton
          text={detailsEditable ? 'Cancel' : 'Edit'}
          active={true}
          isLoading={false}
          onClick={() => setDetailsEditable(!detailsEditable)}
          customClass="btn-primary mb-3 ml-2"
        />
      </div>
    );
  };

  return (
    <div>
      {loading && <Loading />}
      {addModalOpen && (
        <AddDishCollectionItemModal
          collectionId={collectionId}
          onSuccess={fetchDishCollectionDetails}
          toggleModal={() => setAddModalOpen(!addModalOpen)}
        />
      )}
      <div className={'dish-details-main-container'}>
        {getHeader()}
        <div className={'dish-details-section'}>
          {getImageSection()}
          {getActionButtons()}
          {getDishDetails()}
          {getItemSection()}
        </div>
      </div>
    </div>
  );
};

export { DishCollectionDetails };