import { shopifyAPI } from '.';

export async function fetchShopifyMerchants(filter: any) {
  const { data } = await shopifyAPI.get(`/admin/merchants?${filter}`);
  return data;
}

export async function fetchShopifyMerchantDetails(id: any) {
  const { data } = await shopifyAPI.get(`/admin/merchants/${id}`);
  return data;
}

export async function fetchShopifyMerchantsAutoSuggest(query: string) {
  const { data } = await shopifyAPI.get(`/admin/merchants/autosuggest?query=${query}`);
  return data;
}
