import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { fetchCloudRequests } from 'services/api/cloudRequests';
import { DATE_FORMATS, ENTITY_CLASS_MAPPINGS, formatDate, getPageLimit, updatePageLimit } from 'utils';
import { showError } from 'utils/notifications';
import moment from 'moment';
import qs from 'query-string';
import TableLink from 'TableLink';
import CloudRequestsFilter from './cloud-requests-filter';

let filters: { [p: string]: any; page: number } = {
  page: 0,
  endDate: moment().toString(),
  startDate: moment().subtract(1, 'month').toString(),
};

const CloudRequests = props => {
  const [cloudRequests, setCloudRequests] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(false);

  const queryParams= qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });

  const fetchCloudRequestsFn = async () => {
    setLoading(true);
    const { page, ...rest } = filters;
    try {
      const response = await fetchCloudRequests({
        page: page,
        limit: getPageLimit(),
        filter: { ...rest },
      });
      setCloudRequests(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    filters = { ...queryParams, page: 0 };
    fetchCloudRequestsFn();
  }, [queryParams.entityId, queryParams.userId, queryParams.startDate, queryParams.endDate]);

  const getEntityRouteInfo = (entityClass: string, entityId: string, getEntityLink: boolean) => {
    const [entity] = ENTITY_CLASS_MAPPINGS.filter(entity => entity.key === entityClass);
    if (!getEntityLink) {
      return !!entity && !!entity?.routeValue;
    } else {
      return `/${entity.routeValue}/${entityId}`;
    }
  };

  const columns = useMemo(() => [
    {
      name: 'Cloud Request ID',
      selector: 'objectId',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/cloud-requests/${row.objectId}`}
          text={row.objectId}
        />
      ),
    },
    {
      name: 'Created At',
      selector: 'createdAt',
      format: row => formatDate(row.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_SS_A),
    },
    {
      name: 'Time Taken',
      selector: 'timeTaken',
    },
    {
      name: 'Entity ID',
      selector: 'entityId',
      cell: row => row.entityId ? (
        getEntityRouteInfo(row.entityClass, row.entityId, false) ? (
          <TableLink
            linkTo={getEntityRouteInfo(row.entityClass, row.entityId, true)}
            text={row.entityId}
            openInNewTab={true}
          />
        ) : row.entityId
      ) : '-',
    },
    {
      name: 'Entity Class',
      selector: 'entityClass',
    },
    {
      name: 'User',
      selector: 'user.full_name',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/users/${row.user.objectId}`}
          text={row.user.full_name}
          openInNewTab={true}
        />
      ),
    },
  ], []);

  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchCloudRequestsFn();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const onFilterChanged = (udpatedfilters: Record<string, any>) => {
    filters = { ...udpatedfilters, page: 0 };
    updateQueryString();
  };

  const getDataTable = () => (
    <DataTable
      title={'Cloud Requests'}
      highlightOnHover={true}
      data={cloudRequests.items}
      columns={columns}
      customStyles={tableStyles}
      pagination={true}
      paginationServer={true}
      paginationDefaultPage={(filters.page || 0) + 1}
      paginationTotalRows={cloudRequests.total}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  );

  const getFilter = () => (
    <CloudRequestsFilter
      {...props}
      onFilterSubmit={onFilterChanged}
    />
  );

  return (
    <div>
      {loading && <Loading />}
      {getFilter()}
      {getDataTable()}
    </div>
  );
};

export default CloudRequests;