import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { DATE_FORMATS, formatDate, updatePageLimit } from 'utils';
import qs from 'query-string';
import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import { showError } from 'utils/notifications';
import TableLink from 'TableLink';
import { fetchFleetMerchantInvoices } from 'services/api/invoices';
import InvoiceFilters from './invoice-filter';
import Currency from 'components/Currency';

let filters: { [p: string]: any; page: number } = {
  page: 0,
};

const ShopifyInvoiceList = props => {
  const [invoices, setInvoices] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      name: 'Invoice ID',
      selector: 'objectId',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/shopify/invoices/${row.objectId}`}
          text={row.objectId}
          openInNewTab={false}
        />
      ),
    },
    {
      name: 'User Name',
      selector: '',
      sortable: false,
      width: '250px',
      // eslint-disable-next-line react/display-name
      cell: row => (
        row?.user?.full_name ? <TableLink
          linkTo={`/users/${row?.user?.objectId}`}
          text={row?.user?.full_name}
          openInNewTab={false}
        /> : '-'
      ),
    },
    {
      name: 'Total Orders',
      selector: 'orders',
      format: row => row?.orders?.length,
    },
    {
      name: 'Total Amount',
      selector: 'totalAmount',
      // eslint-disable-next-line react/display-name
      cell: row => <Currency amount={row?.totalDeliveryCharges + row?.totalDeliveryTaxes} />,
    },
    {
      name: 'Scheduled At',
      selector: 'scheduledAt',
      format: row => formatDate(row.scheduledAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
    },
    {
      name: 'Status',
      selector: 'status',
    },
    {
      name: 'Issue Date',
      selector: 'createdAt',
      format: row => formatDate(row.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
    },
    {
      name: 'Billing Period',
      selector: '',
      cell: row => (formatDate(row.startDate, 'MMM D')+ '-' + formatDate(row.endDate, 'MMM D')),
    },
  ];

  const fetchFleetMerchantInvoicesFn = async () => {
    setLoading(true);
    try {
      const response = await fetchFleetMerchantInvoices(filters);
      setInvoices(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  useEffect(() => {
    filters = {
      ...filters,
      ...queryStringToFilter(),
    };
    fetchFleetMerchantInvoicesFn();
  }, []);

  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onFilterChanged = (udpatedfilters: Record<string, any>) => {
    // if (udpatedfilters._id) {
    //   props.history.push(`/shopify/merchants/${udpatedfilters._id}`);
    //   return;
    // }
    filters = { ...udpatedfilters, page: 0 };
    updateQueryString();
    fetchFleetMerchantInvoicesFn();
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchFleetMerchantInvoicesFn();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  return (
    <div>
      {loading && <Loading />}
      <InvoiceFilters
        location={props.location}
        onFilterSubmit={onFilterChanged}
      />
      <DataTable
        title="Invoices"
        keyField="_id"
        columns={columns}
        data={invoices.items}
        highlightOnHover={true}
        defaultSortField="createdAt"
        defaultSortAsc={false}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationDefaultPage={(filters.page || 0) + 1}
        paginationTotalRows={invoices.total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </div>
  );
};

export default ShopifyInvoiceList ;
