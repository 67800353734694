import React, { useEffect, useState } from 'react';
import { fetchAdminOrders } from 'services/api/order';
import DataTable from 'react-data-table-component';
import { fromStripeAmount, getPageLimit, updatePageLimit } from 'utils';
import qs from 'query-string';
import { Loading } from 'components/loading/Loading';
import { OrdersFilter } from './orders-filter';
import { tableStyles } from 'css/tableStyles';
import { showError } from 'utils/notifications';
import moment from 'moment';
import TableLink from 'TableLink';

let filters: { [p: string]: any; page: number } = {
  page: 0,
  endDate: moment().toString(),
  startDate: moment().subtract(1, 'month').toString(),
};

let sort = {};

const Orders = props => {
  const [orders, setOrders] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const { hideFilter } = props;
  const columns = [
    {
      name: 'Order ID',
      selector: 'objectId',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/orders/${row.objectId}`}
          text={row.objectId}
          openInNewTab={false}
        />
      ),
    },
    {
      name: 'Order Number',
      selector: 'orderNumber',
      sortable: false,
      width: '100px',
    },
    {
      name: 'Restaurant ID',
      selector: 'restaurant.objectId',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/restaurants/${row.restaurant?.objectId}`}
          text={row.restaurant?.objectId}
          openInNewTab={true}
        />
      ),
    },
    {
      name: 'Date Time',
      selector: 'createdAt',
      sortable: true,
      width: '180px',
    },
    {
      name: 'Client',
      selector: 'clientName',
      cell: (row) => (row.clientName || row.contactName),
      sortable: false,
    },
    {
      name: 'Driver',
      selector: 'driverName',
      sortable: false,
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: false,
      format: row => `$${row.total}`,
    },
    {
      name: 'Refunded',
      selector: 'refunded',
      sortable: false,
      format: row => `$${fromStripeAmount(row.refundAmount)}`,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: false,
    },
    {
      name: 'Cloud Request',
      selector: 'cloudRequest',
      // eslint-disable-next-line react/display-name
      cell: (row) =>
        <TableLink
          linkTo={`/cloud-requests?entityId=${row.objectId}`}
          text={<i className="fa fa-link" />}
          openInNewTab={true}
        />,
    },
  ];


  const fetchAdminOrdersFn = async () => {
    setLoading(true);
    try {
      const response = await fetchAdminOrders({
        page: filters.page,
        limit: getPageLimit(),
        filter: filters,
        sort,
      });
      setOrders(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  useEffect(() => {
    filters = {
      ...filters,
      ...queryStringToFilter(),
    };
    fetchAdminOrdersFn();
  }, []);

  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onFilterChanged = (udpatedfilters: Record<string, any>) => {
    filters = { ...udpatedfilters, page: 0 };
    updateQueryString();
    fetchAdminOrdersFn();
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchAdminOrdersFn();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const onSort = (column: Record<string, any>, sortDirection: string) => {
    sort = {
      ...sort,
      [column.selector]: sortDirection,
    };
    fetchAdminOrdersFn();
  };

  const getOrdersFilter = () => {
    if (!hideFilter) {
      return (
        <OrdersFilter
          showOrderNumberFilter
          showUserNameFilter
          showUserEmailFilter
          showUserPhoneFilter
          showDriverNameFilter
          showRestaurantFilter
          showDiscountCodeFilter
          showRouteFilter
          location={props.location}
          onFilterSubmit={onFilterChanged}
        />);
    } else {
      return null;
    }
  };

  return (
    <div>
      {loading && <Loading />}
      {getOrdersFilter()}
      <DataTable
        title="Orders"
        keyField="objectId"
        columns={columns}
        data={orders.items}
        highlightOnHover={true}
        defaultSortField="createdAt"
        defaultSortAsc={false}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationDefaultPage={(filters.page || 0) + 1}
        paginationTotalRows={orders.total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        sortServer={true}
        onSort={onSort}
      />
    </div>
  );
};


export { Orders };
