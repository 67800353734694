import moment from 'moment';
import { cloudFunction } from 'services/parse';
import { DATE_FORMATS, formatDate } from 'utils';

export const fetchAsyncJobs = async (data: Record<string, any>) => {
  const response = await cloudFunction('fetchAsyncJobs', data);
  const { items, total } = response;
  const itemsArr = items.map(t => {
    return {
      id: t.id,
      status: t.get('status'),
      createdAt: t.get('createdAt'),
      startedBy: t.get('startedBy').get('full_name'),
    };
  });
  const responseObj = { items: itemsArr, total };
  return responseObj;
};

export const fetchJobDetails = async (data: Record<string, string>) => {
  const response = await cloudFunction('fetchAsyncJobDetail', data);
  const responseObj = {
    input: response.get('input'),
    type: response.get('type'),
    status: response.get('status'),
    createdAt: moment(response.get('createdAt')).format(DATE_FORMATS.MM_DD_YYYY_HH_MM_SS_A),
    startedBy: response.get('startedBy').get('full_name'),
    updatedAt: response.get('updatedAt'),
    result: response.get('result') && JSON.parse(response.get('result')),
    error: response.get('error') &&  JSON.parse(response.get('error')),
  };
  return responseObj;
};
