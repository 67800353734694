import React from 'react';
import './index.scss';
import { DATE_FORMATS, formatDate } from 'utils';
import DatePicker from 'react-datepicker';
import CityDropDown from '../components/city-drop-down';
import DriverVehicleDetail from '../driver-vehicle-detail';

const DriverPersonalDetail = (props) => {
  const { savePersonalDetailsLoading } = props;
  return (
    <div className={`driver-personal ${props.isEditing ? 'editing': ''}`}>
      <div className="row">
        <div className="col-12">
          <span className="text-muted font-weight-bold">Driver Personal Detail</span>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-3 border-right">
              {props.profilePicture && <img src={props.profilePicture.url} alt={props.full_name} className="avatar mr-3"/> }
              {!props.isEditing ? <span className="driver-name font-weight-bold">{props.full_name || 'N/A'}</span> : <>
                <input disabled={savePersonalDetailsLoading} type="text" className="form-control" value={props.full_name}
                  onChange={event => props.onPersonalDetailChange({ 'full_name': event.target.value })}/>
              </>}
              {!props.isEditing ? <div className="font-weight-bold">{props.email || 'N/A'}</div>: <>
                <input disabled={savePersonalDetailsLoading} type="email" className="form-control" value={props.email}
                  onChange={event => props.onPersonalDetailChange({ 'email': event.target.value })} />
              </>}
            </div>
            <div className="col-12 col-sm-6 col-md-3 border-right">
              <div className="text-muted font-weight-bold">DriverID</div>
              <div className="font-weight-bold">{props.objectId}</div>
            </div>
            <div className="col-12 col-sm-6 col-md-3 border-right">
              <div className="text-muted font-weight-bold">SSN Number</div>
              {!props.isEditing ? <div className="font-weight-bold">{props.ssn || 'N/A'}</div> : <>
                <input disabled={savePersonalDetailsLoading} type="text" className="form-control" value={props.ssn}
                  onChange={event => props.onPersonalDetailChange({ 'ssn': event.target.value })} />
              </>}
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="text-muted font-weight-bold">Phone Number</div>
              {!props.isEditing ? <div className="font-weight-bold">{props.phone || 'N/A'}</div> : <>
                <input disabled={savePersonalDetailsLoading} type="text" className="form-control" value={props.phone}
                  onChange={event => props.onPersonalDetailChange({ 'phone': event.target.value })} />
              </>}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-sm-6 col-md-3 border-right">
              <div className="text-muted font-weight-bold">Birthdate</div>
              {!props.isEditing ? <div className="font-weight-bold">{(props.dateOfBirth && formatDate(props.dateOfBirth, DATE_FORMATS.MMM_DD_YYYY)) || 'N/A'}</div> : <>
                <DatePicker disabled={savePersonalDetailsLoading} id="dateOfBirthDriver" className="form-control w-100" selected={props.dateOfBirth && new Date(props.dateOfBirth)}
                  onChange={event => props.onPersonalDetailChange({ 'dateOfBirth': (event || {}).toString() })} />
              </>}
            </div>
            <div className="col-12 col-sm-6 col-md-3 border-right">
              <div className="text-muted font-weight-bold">Account Number</div>
              {!props.isEditing ? <div className="font-weight-bold">{props.accountNumber || 'N/A'}</div> : <>
                <input disabled={savePersonalDetailsLoading} type="text" className="form-control" value={props.accountNumber}
                  onChange={event => props.onPersonalDetailChange({ 'accountNumber': event.target.value })} />
              </>}
            </div>
            <div className="col-12 col-sm-6 col-md-3 border-right">
              <div className="text-muted font-weight-bold">Routing Number</div>
              {!props.isEditing ? <div className="font-weight-bold">{props.routingNumber || 'N/A'}</div> : <>
                <input disabled={savePersonalDetailsLoading} type="text" className="form-control" value={props.routingNumber}
                  onChange={event => props.onPersonalDetailChange({ 'routingNumber': event.target.value })} />
              </>}
            </div>
            <div className="col-12 col-sm-6 col-md-3 border-right">
              <div className="text-muted font-weight-bold">City</div>
              {!props.isEditing ? <div className="font-weight-bold">{props.city || 'N/A'}</div> : <>
                <CityDropDown disabled={savePersonalDetailsLoading} city={props.city} cities={props.cities} loading={props.cityLoading}
                  onChange={event => props.onPersonalDetailChange({ 'city': event.target.value })}/>
              </>}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-sm-6 col-md-3 border-right">
              <div className="text-muted font-weight-bold">Gender</div>
              {!props.isEditing ? <div className="font-weight-bold">{props.gender || 'N/A'}</div> : <>
                <select disabled={savePersonalDetailsLoading} className="form-control" value={props.gender} onChange={event => props.onPersonalDetailChange({ 'gender': event.target.value })}>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Rather not say">Rather not say</option>
                </select>
              </>}
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="text-muted font-weight-bold">App Version</div>
              <div className="font-weight-bold">{props.driverAppVersion}</div>
            </div>
          </div>
        </div>
      </div>
      <DriverVehicleDetail { ...props } />
    </div>
  );
};

export default DriverPersonalDetail;
