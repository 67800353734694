import React, { useEffect, useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import qs from 'query-string';
import '../../dishes/dishes-filter/index.scss';
import ToggleSwitch from 'components/ToggleSwitch';
import VerticalTypePicker from 'components/VerticalTypePicker';

const DishesFilter = props => {
  const [featured, setFeatured] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [verticalType, setVerticalType] = useState<string>('');

  const resetFilter = () => {
    setFeatured(false);
    setActive(false);
    const filterData = {};
    props.onFilterSubmit(filterData);
  };

  const onFilterSubmit = () => {
    const filterData: Record<string, any> = {};
    if (active) {
      filterData['active'] = active;
    }
    if (featured) {
      filterData['featured'] = featured;
    }
    if (verticalType) {
      filterData['verticalType'] = verticalType;
    }
    props.onFilterSubmit(filterData);
  };

  useEffect(() => {
    const {
      featured,
      active,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
    if (featured) setFeatured(!!featured);
    if (active) setActive(!!active);
    if (verticalType) setVerticalType(verticalType);
  }, []);

  return (
    <form className='filter-container'>
      <div className='input-container mb-2 mr-4' style={{ width: '150px' }}>
        <label className='mr-2'>Vertical Type</label>
        <VerticalTypePicker
          verticalType={verticalType}
          disabled={false}
          onChange={(e) => setVerticalType(e.target.value)}
        />
      </div>
      <div className='input-container mb-2 mr-4'>
        <ToggleSwitch
          switchLabel='Featured'
          onChange={() => setFeatured(!featured)}
          checked={featured}
        />
      </div>
      <div className='input-container mb-2 mr-4'>
        <ToggleSwitch
          switchLabel='Active'
          onChange={() => setActive(!active)}
          checked={active}
        />
      </div>
      <div>
        <ActionButton
          text={'Submit'}
          isLoading={false}
          active={true}
          customClass={'btn-primary mr-2'}
          onClick={onFilterSubmit}
        />
        <ActionButton
          text={'Reset'}
          isLoading={false}
          active={true}
          customClass={'btn-primary'}
          onClick={resetFilter}
        />
      </div>
    </form>
  );
};

export default DishesFilter;
