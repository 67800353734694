import ActionButton from 'components/ActionButton/ActionButton';
import { tableStyles } from 'css/tableStyles';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { fetchAllSubscriptionsForAdmin } from 'services/api/subscriptions';
import { DATE_FORMATS, formatDate, showError } from 'utils';
import styles from './index.module.scss';
import TableLink from 'TableLink';
import { Loading } from 'components/loading/Loading';

const Subscriptions = (props) => {
  const history = useHistory();
  const { stripeProductId = '' } = props;
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAllSubscriptionsFn = async () => {
    setLoading(true);
    try {
      const response = await fetchAllSubscriptionsForAdmin({
        filters: { productId: stripeProductId },
      });
      setSubscriptions(response);
      setLoading(false);
    } catch (e) {
      showError(e);
    }
  };

  useEffect(() => {
    fetchAllSubscriptionsFn();
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'Id',
        selector: 'objectId',
        // eslint-disable-next-line react/display-name
        cell: row => (
          <TableLink
            text={row.objectId}
            linkTo={`/subscription/${row.objectId}`}
          />
        ),
      },
      {
        name: 'Name',
        selector: 'name',
      },
      {
        name: 'Price($)',
        selector: 'price',
        format: (row) => row?.price / 100,
      },
      {
        name: 'Stripe price id',
        selector: 'priceId',
      },
      {
        name: 'Type',
        selector: 'type',
      },
      {
        name: 'Trial Period Days',
        selector: 'trialPeriodDays',
      },
      {
        name: 'Created At',
        selector: 'createdAt',
        format: (row) => formatDate(row.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
      },
    ], [],
  );

  const getHeader = () => {
    return (
      <div className={styles['section-header']}>
        <div className={styles['section-heading']}>Subscriptions</div>
        <ActionButton
          active={true}
          isLoading={false}
          onClick={() => { history.push(`/subscription/create?stripeProductId=${stripeProductId}`) }}
          text={
            <>
              Subscriptions <span className={'ml-3'}>+</span>
            </>
          }
        />
      </div>
    );
  };

  if (loading) return <Loading />;

  return (
    <div className={styles['subscriptions-container']}>
      {getHeader()}
      <DataTable
        noHeader
        data={subscriptions}
        columns={columns}
        customStyles={tableStyles}
      />
    </div>
  );
};

export default Subscriptions;
