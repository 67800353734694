import ActionButton from 'components/ActionButton/ActionButton';
import BackButton from 'components/BackButton';
import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from 'react';
import { STRIPE_SUPPORTED_CARD_BRANDS, showError } from 'utils';
import styles from './index.module.scss';
import { fetchDiscount, updateDiscount } from 'services/api/discounts';
import moment from 'moment';
import { fetchAdminOrders } from 'services';
import { Orders } from 'pages/orders';
import BasicDetails from './BasicDetails';
import DishEligibility from './DishEligibility';
import UserEligibility from './UserEligibility';
import OrderEligibility from './OrderEligibility';
import _ from 'lodash';
import CardEligibility from './CardEligibility';

const DiscountDetails = props => {
  const { discountId } = props.match.params;
  const [discount, setDiscount] = useState<Record<string, any>>({ type: '' });
  const [loading, setLoading] = useState<boolean>(false);
  const [ordersLoading, setOrdersLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<Record<string, any>>({ data: true });
  const [startDate, setStartDate] = useState<string>(moment().toISOString());
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [detailsEditable, setDetailsEditable] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [cardBrands, setCardBrands] = useState([]) as any;

  const fetchDiscountDetailsFn = async () => {
    setLoading(true);
    try {
      const response = await fetchDiscount(discountId);
      setDiscount(response);
      setStartDate(moment(response.startDate).toISOString());
      setEndDate(moment(response.endDate).toISOString());
      const eligibleCardBrands = _.get(response, 'cardEligibility.cardBrands', []);
      const cardBrandValues = STRIPE_SUPPORTED_CARD_BRANDS.filter((card)=>{
        return eligibleCardBrands?.includes(card?.value);
      });
      setCardBrands(cardBrandValues);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDiscountDetailsFn();
  }, []);

  const updateDiscountDetailsFn = async () => {
    try {
      if (!discount?.code) {
        showError('Please enter discount code');
        return;
      }
      if (!discount?.type) {
        showError('Please select discount type');
        return;
      }
      setUpdateLoading(true);
      const discountDetails = {
        ...discount,
        id: discountId,
        startDate: startDate.toString(),
        endDate: endDate.toString(),
      } as any;
      if (cardBrands?.length > 0) {
        discountDetails.cardEligibility = {};
        discountDetails.cardEligibility.cardBrands = cardBrands?.map((card: any)=>card?.value);
      } else if (discountDetails?.cardEligibility?.cardBrands) {
        delete discountDetails.cardEligibility.cardBrands;
      }
      await updateDiscount(discountDetails);
      setDetailsEditable(false);
    } catch (e) {
      showError(e);
    } finally {
      setUpdateLoading(false);
    }
  };

  const getHeader = () => (
    <div className={styles['header']}>
      <div className={styles['heading']}>Discount Details</div>
      <BackButton />
    </div>
  );

  const handleOrdersClick = async () => {
    setOrdersLoading(true);
    const filter = { discountCode: discount?.code };
    try {
      const response = await fetchAdminOrders(filter);
      setOrders(response);
    } catch (e) {
      showError(e);
    } finally {
      setOrdersLoading(false);
    }
  };

  const getOrderItems = () => {
    if(orders?.items){
      return (
        <Orders
          {...props}
          data={orders.items}
          total={orders.total}
          selectableRows={false}
          hideFilter={true}
        />
      );
    }else{
      return null;
    }
  };

  const getOrders = () => {
    if (!detailsEditable) {
      return (
        <div className={styles['orders-section']}>
          <div>
            <ActionButton
              active={!ordersLoading}
              isLoading={ordersLoading}
              customClass={'m-2'}
              onClick={handleOrdersClick}
              text={'Show Orders'}
            />
          </div>
          {getOrderItems()}
        </div>
      );
    } else {
      return null;
    }
  };

  const getEligibilitySection = () => {
    return (
      <>
        <CardEligibility
          detailsEditable = {detailsEditable}
          cardBrands = {cardBrands}
          setCardBrands = {setCardBrands}
          updateLoading = {updateLoading}
        />
        <DishEligibility
          discount = {discount}
          setDiscount = {setDiscount}  
          loading = {loading}
          detailsEditable={detailsEditable}
        />
        <UserEligibility
          discount = {discount}
          setDiscount = {setDiscount}  
          loading = {loading}
          detailsEditable={detailsEditable}
        />
        <OrderEligibility
          discount = {discount}
          setDiscount = {setDiscount}  
          loading = {loading}
          detailsEditable={detailsEditable}
        />
      </>
    );
  };

  return (
    <div className={styles['main-container']}>
      {loading && <Loading />}
      {getHeader()}
      <BasicDetails 
        loading = {loading}
        detailsEditable={detailsEditable}
        setDetailsEditable ={setDetailsEditable}
        discount = {discount}
        setDiscount = {setDiscount}
        updateLoading = {updateLoading}
        startDate= {startDate}
        setStartDate={setStartDate}
        endDate = {endDate}
        setEndDate = {setEndDate}
        updateDiscountDetailsFn = {updateDiscountDetailsFn}
      />
      {getEligibilitySection()}
      {getOrders()}
    </div>
  );
};

export { DiscountDetails };