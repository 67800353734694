import React from 'react';
import DataTable from 'react-data-table-component';
import { tableStyles as customStyles } from 'css/tableStyles';

const tableStyles = {
  ...customStyles,
  headRow: {
    style: {
      ...customStyles.headRow.style,
      borderRadius: '6px',
    },
  },
};

const RestaurantStatsOrdersTable = props => {
  const {
    data,
    columns,
    header,
  } = props;
  
  return (
    <div className={'subsection-table-container mb-4'}>
      <div className={'restaurant-stats-detail-tag mb-1'}>{header}</div>
      <DataTable
        noHeader
        columns={columns}
        data={data}
        customStyles={tableStyles}
      />
    </div>
  );
};

export default RestaurantStatsOrdersTable;