import React, { useEffect, useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import { DAYS_NUMBER_TO_NAME, RESTAURANT_TIMINGS_TYPE } from 'utils';
import SingleDay from './single-day';

const RestaurantTimings = props => {
  const [selectedMode, setSelectedMode] = useState<string>(RESTAURANT_TIMINGS_TYPE.DAILY);
  const [restaurantHours, setRestaurantHours] = useState<Record<string, any>>({});
  const { schedule, onChange } = props;

  const getTimings = () => {
    const existingDays: string[] = Object.keys(schedule || {});
    let days: Record<string, any> = {};
    for (const day in DAYS_NUMBER_TO_NAME) {
      let singleDay: Record<string, any> = {};
      if (existingDays.includes(day)) {
        const dayData = schedule[day];
        singleDay = dayData?.map(slot => {
          if (!slot) {
            return { start: '', end: '' };
          } else {
            return { start: slot.start, end: slot.end };
          }
        });
      } else {
        singleDay = [{ start: '', end: '' }];
      }
      days = {
        ...days,
        [day]: singleDay,
      };
    }
    return days;
  };

  useEffect(() => {
    setRestaurantHours(getTimings());
  }, []);
  
  const getDays = (type: string) => {
    const timings = getTimings();
    if (type === RESTAURANT_TIMINGS_TYPE.WEEKLY) {
      return [{ 'everyday': timings['everyday'] }];
    }

    const days = Object.keys(DAYS_NUMBER_TO_NAME);
    const filteredDays: Record<string, any>[] = [];
    for (const day of days) {
      if (day !== 'everyday') {
        filteredDays.push({
          [day]: timings[day.toString()],
        });
      }
    }
    return filteredDays;
  };

  const handleChange = (timings: Record<string, any>[]) => {
    onChange(timings);
    setRestaurantHours(timings);
  };
  
  const WeeklySection = () => (
    <div className={'restaurant-weekly-timings-section'}>
      <SingleDay
        day={getDays(RESTAURANT_TIMINGS_TYPE.WEEKLY)[0]}
        restaurantHours={restaurantHours}
        setRestaurantHours={handleChange}
      />
    </div>
  );

  const DailySection = () => (
    <div className={'restaurant-daily-timings-section'}>
      {getDays(RESTAURANT_TIMINGS_TYPE.DAILY).map((day, i) => (
        <SingleDay
          key={i}
          day={day}
          restaurantHours={restaurantHours}
          setRestaurantHours={handleChange}
        />
      ))}
    </div>
  );

  return (
    <div className={'restaurant-edit-timings-section'}>
      <div className={'timings-selector-button'}>
        <ActionButton
          text={'Daily'}
          active={true}
          isLoading={false}
          onClick={() => setSelectedMode(RESTAURANT_TIMINGS_TYPE.DAILY)}
          customClass={selectedMode === RESTAURANT_TIMINGS_TYPE.DAILY ? 'active' : ''}
        />
        <ActionButton
          text={'Weekly'}
          active={true}
          isLoading={false}
          onClick={() => setSelectedMode(RESTAURANT_TIMINGS_TYPE.WEEKLY)}
          customClass={selectedMode === RESTAURANT_TIMINGS_TYPE.WEEKLY ? 'active' : ''}
        />
      </div>
      {selectedMode === RESTAURANT_TIMINGS_TYPE.DAILY ? <DailySection /> : <WeeklySection />}
    </div>
  );
};

export default RestaurantTimings;