import { IMenu } from '../../interfaces';
import { AnyAction } from 'redux';
import { Types } from '../actions/types';
import { MENU_ITEMS } from '../../utils';

/** Sorting Menu Items based on its title */
const menuList = MENU_ITEMS.sort((a, b) => (a.title < b.title ? -1 : 1));

const initialState: IMenu.StateToProps = {
  isOpen: true,
  menuItems: menuList as IMenu.IMenuItem[],
};

export function menu(
  state: IMenu.StateToProps = initialState,
  action: AnyAction,
) {
  switch (action.type) {
  case Types.SIDE_MENU_OPEN:
    return {
      ...state,
      isOpen: true,
    };
  case Types.SIDE_MENU_COLLAPSE:
    return {
      ...state,
      isOpen: false,
    };
  default:
    return state;
  }
}
