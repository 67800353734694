import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import './sidenavitem.scss';

const SideNavItemChild = ({ menuItem }) => {
  const location = useLocation();
  const isActive = () => {
    return location.pathname === menuItem.url;
  };
  return (
    <Link to={menuItem.url} onClick={event => (menuItem.onClick && menuItem.onClick(event))} className="text-decoration-none">
      <div className={`side-nav-item d-flex align-items-center px-4 ${isActive() ? 'active' : ''} ${menuItem.customClass || ''}`}>
        <i className={`fa ${menuItem.iconClass}`}></i>
        <span className="text-nowrap">{menuItem.title}</span>
      </div>
    </Link>
  );
};

export default SideNavItemChild;
