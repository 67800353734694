import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { showError, showSuccess } from 'utils/notifications';
import qs from 'query-string';
import { ACTION_ICON_TYPES, formatDate, getPageLimit, getTextValue, updatePageLimit } from 'utils';
import TableLink from 'TableLink';
import CollectionFilter from '../dish-collection/collection-filter';
import { deleteRestaurantCollection, getRestaurantCollections } from 'services/api/restaurantCollection';
import ActionButton from 'components/ActionButton/ActionButton';
import AddRestaurantCollectionModal from '../../components/AddDishCollectionModal/index';
import ActionIcon from 'components/ActionIcon';

let filters: { [p: string]: any; page: number } = {
  page: 0,
};

const RestaurantCollection = props => {
  const [colletions, setColletions] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const [addModal, showAddModal] = useState(false);

  const fetchRestaurantColletion = async (filters: Record<string, any>) => {
    setLoading(true);
    try {
      const { page, ...rest } = filters;
      const response = await getRestaurantCollections({
        page,
        limit: getPageLimit(),
        filter: {
          ...rest,
        },
      });
      setColletions(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteRestaurantCollectionFn = async (id: string) => {
    setLoading(true);
    try {
      await deleteRestaurantCollection(id);
      showSuccess('Restaurant COllection deleted succefully.');
      fetchRestaurantColletion(filters);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };
  
  useEffect(() => {
    const filter = {
      ...filters,
      ...queryStringToFilter(),
    };
    fetchRestaurantColletion(filter);
  }, []);

  const columns = useMemo(() => [
    {
      name: 'Collection ID',
      selector: 'objectId',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/restaurant-collection/${row.objectId}`}
          text={row.objectId}
        />
      ),
    },
    {
      name: 'Collection Name',
      selector: 'name',
    },
    {
      name: 'No of restaurants',
      selector: '',
      cell: row => (row?.restaurants?.length || 0),
      width: '180px',
    },
    {
      name: 'Featured',
      selector: 'featured',
      cell: (row)=>getTextValue(row.featured),
      width: '80px',
    },
    {
      name: 'Active',
      selector: 'active',
      cell: (row) => getTextValue(row.active),
      width: '80px',
    },
    {
      name: 'Created by',
      selector: 'user.full_name',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/users/${row.user.objectId}`}
          text={row.user.full_name}
          openInNewTab={true}
        />
      ),
    },
    {
      name: 'Created at',
      selector: 'createdAt',
      sortable: true,
      format: (row: any) => formatDate(row.createdAt, 'MM/DD/YYYY HH:mm'),
    },
    {
      name: '',
      selector: '',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <ActionIcon
          actionType={ACTION_ICON_TYPES.DELETE}
          onClick={() => deleteRestaurantCollectionFn(row.objectId)}
        />
      ),
    },
  ], []);

  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchRestaurantColletion(filters);
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const onFilterChanged = (updatedFilters: Record<string, any>) => {
    filters = { ...updatedFilters, page: 0 };
    updateQueryString();
    fetchRestaurantColletion(filters);
  };

  const onSort = (column: Record<string, any>, sortDirection: string) => {
    const sort = filters.sort || {};
    filters = {
      ...filters,
      sort: {
        ...sort,
        [column.selector]: sortDirection,
      },
    };
    fetchRestaurantColletion(filters);
  };

  return (
    <div>
      {loading && <Loading />}
      {addModal && (
        <AddRestaurantCollectionModal 
          toggleModal={()=>{showAddModal(!addModal)}}
          onSuccess={()=>fetchRestaurantColletion(filters)}
          isRestaurantCollection={true}
        />
      )}
      <CollectionFilter
        {...props}
        onFilterSubmit={onFilterChanged}
      />
      <DataTable
        title={'Restaurant Collections'}
        actions={(
          <ActionButton
            text={`Create Collection +`}
            active={true}
            isLoading={false}
            customClass={'btn-primary mb-2'}
            onClick={() => showAddModal(true)}
          />
        )}
        keyField="objectId"
        columns={columns}
        data={colletions.items}
        highlightOnHover={true}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationDefaultPage={(filters.page || 0) + 1}
        paginationTotalRows={colletions.total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onSort={onSort}
      />
    </div>
  );
};

export { RestaurantCollection };