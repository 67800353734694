import ActionButton from 'components/ActionButton/ActionButton';
import ActionIcon from 'components/ActionIcon';
import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { deleteToll, fetchTolls } from 'services/api/tolls';
import { ACTION_ICON_TYPES, getPageLimit, updatePageLimit } from 'utils';
import { showError, showSuccess } from 'utils/notifications';
import EditTollModal from './EditTollModal';
import './index.scss';
import qs from 'query-string';

let filters: { page: number } = {
  page: 0,
};


const TollCrud = props => {
  const [tolls, setTolls] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [tollToEdit, setTollToEdit] = useState<Record<string, any>>({});
  const [editTollModalOpen, setEditTollModalOpen] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<string>('');

  const fetchTollsFn = async () => {
    try {
      const response = await fetchTolls({
        page: filters.page,
        limit: getPageLimit(),
      });
      setTolls(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTollsFn();
  }, []);

  const deleteTollFn = async (id: string) => {
    setDeleteLoading(id);
    try {
      await deleteToll(id);
      await fetchTollsFn();
      showSuccess('Toll deleted successfully');
    } catch (e) {
      showError(e);
    } finally {
      setDeleteLoading('');
    }
  };

  const columns = useMemo(() => [
    {
      name: 'Name',
      selector: 'name',
    },
    {
      name: 'Amount',
      selector: 'amount',
    },
    {
      name: 'Latitude',
      selector: 'coords.latitude',
    },
    {
      name: 'Longitude',
      selector: 'coords.longitude',
    },
    {
      name: '',
      selector: '',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <div className={'d-flex'}>
          <ActionIcon
            actionType={ACTION_ICON_TYPES.EDIT}
            loading={false}
            onClick={() => {
              setTollToEdit(row);
              setEditTollModalOpen(true);
            }}
            customClass={'mr-4'}
          />
          <ActionIcon
            actionType={ACTION_ICON_TYPES.DELETE}
            loading={deleteLoading === row.objectId}
            onClick={() => deleteTollFn(row.objectId)}
          />
        </div>
      ),
    },
  ], [loading, deleteLoading]);

  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onChangePage = (page: number) => {
    filters.page = page -1;
    updateQueryString();
    fetchTollsFn();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const getDataTable = () => loading ?
    (
      <Loading type={'mini'} />
    ) : (
      <DataTable
        noHeader
        data={tolls.items}
        columns={columns}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationDefaultPage={(filters.page || 0) + 1}
        paginationTotalRows={tolls.total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    );

  const handleAddTollClick = () => {
    setTollToEdit({});
    setEditTollModalOpen(true);
  };
    
  return (
    <div className={'toll-crud-container'}>
      {editTollModalOpen && (
        <EditTollModal
          closeModal={() => setEditTollModalOpen(false)}
          onSuccess={fetchTollsFn}
          tollToEdit={tollToEdit}
        />
      )}
      <div className={'header'}>
        <div className={'heading'}>Tolls</div>
        <ActionButton
          active={true}
          isLoading={false}
          onClick={handleAddTollClick}
          text={'Add Toll'}
          type={'button'}
          customClass={'btn btn-primary'}
        />
      </div>
      {getDataTable()}
    </div>
  );
};

export default TollCrud;