import React, { useEffect, useState } from 'react';
import { createDishTag, createRestaurantTag, deleteDishTag, deleteRestaurantTag, fetchDishTags, fetchRestaurantTags } from 'services/api/tags';
import ActionButton from 'components/ActionButton/ActionButton';
import Tag from 'components/Tag';
import './index.scss';
import { showError } from 'utils/notifications';
import { Loading } from 'components/loading/Loading';

const Tags = () => {
  const [restaurantTags, setRestaurantTags] = useState<Record<string, any>[]>([]);
  const [dishTags, setDishTags] = useState<Record<string, any>[]>([]);
  const [restaurantTagsLoading, setRestaurantTagsLoading] = useState<boolean>(true);
  const [dishTagsLoading, setDishTagsLoading] = useState<boolean>(true);

  const fetchRestaurantTagsFn = async () => {
    try {
      const response = await fetchRestaurantTags();
      setRestaurantTags(response);
    } catch (e) {
      showError(e);
    } finally {
      setRestaurantTagsLoading(false);
    }
  };

  const fetchDishTagsFn = async () => {
    try {
      const response = await fetchDishTags();
      setDishTags(response);
    } catch (e) {
      showError(e);
    } finally {
      setDishTagsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchRestaurantTagsFn();
    fetchDishTagsFn();
  }, []);

  const addRestaurantTag = async (name: string) => {
    try {
      await createRestaurantTag(name);
      fetchRestaurantTagsFn();
    } catch (e) {
      showError(e);
    }
  };

  const removeRestaurantTag = async (id: string) => {
    try {
      await deleteRestaurantTag(id);
      fetchRestaurantTagsFn();
    } catch (e) {
      showError(e);
    }
  };

  const addDishTag = async (name: string) => {
    try {
      await createDishTag(name);
      fetchDishTagsFn();
    } catch (e) {
      showError(e);
    }
  };

  const removeDishTag = async (id: string) => {
    try {
      await deleteDishTag(id);
      fetchDishTagsFn();
    } catch (e) {
      showError(e);
    }
  };
  
  const TagsSection = props => {
    const { tags, createTag, removeTag, tagsLoading } = props;
    const [newTagName, setNewTagName] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async () => {
      setLoading(true);
      await createTag(newTagName);
      setLoading(false);
    };

    if (tagsLoading) return <Loading type={'mini'} />;

    return (
      <div className='tags-section'>
        <form className='add-tag-section'>
          <input
            className='form-control'
            disabled={loading}
            value={newTagName}
            placeholder={'Tag Name'}
            onChange={e => setNewTagName(e.target.value)}
          />
          <ActionButton
            text='Add'
            active={!loading}
            isLoading={false}
            onClick={handleSubmit}
            customClass={'btn-primary ml-4'}
          />
        </form>
        <div className='tags-view'>
          {tags.length
            ? tags.map((tag, i) => {
              return <Tag key={i} tagName={tag.name} onRemove={() => removeTag(tag.id)} />;
            })
            : <div className='no-tags'>No tags added</div>
          }
        </div>
      </div>
    );
  };
  
  return (
    <>
      <div className='restaurant-tags-section'>
        <h4 className='header'>Restaurant Tags</h4>
        <TagsSection
          tags={restaurantTags}
          createTag={addRestaurantTag}
          removeTag={removeRestaurantTag}
          tagsLoading={restaurantTagsLoading}
        />
      </div>
      <div className='dish-tags-section'>
        <h4 className='header'>Dish Tags</h4>
        <TagsSection
          tags={dishTags}
          createTag={addDishTag}
          removeTag={removeDishTag}
          tagsLoading={dishTagsLoading}
        />
      </div>
    </>
  );
};

export { Tags };