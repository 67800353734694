import { tableStyles } from 'css/tableStyles';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { fetchRestaurantsByAdmin } from 'services/api/restaurant';
import { DATE_FORMATS, formatDate, getPageLimit, secondsToMinute, updatePageLimit } from 'utils';
import { showError } from 'utils/notifications';
import qs from 'query-string';
import TableLink from 'TableLink';
import { Loading } from 'components/loading/Loading';
import RestaurantsFilter from './restaurants-filter';
import { connect } from 'react-redux';
import { isUserShopper } from 'utils';
import ActionButton from 'components/ActionButton/ActionButton';

let filters: { [p: string]: any; page: number } = {
  page: 0,
};

let sort = {};

const columns = [
  {
    name: 'Restaurant ID',
    selector: 'objectId',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/restaurants/${row.objectId}`}
        text={row.objectId}
        openInNewTab={false}
      />
    ),
  },
  {
    name: 'Name',
    selector: 'restaurant_name',
    sortable: false,
  },
  {
    name: 'Created At',
    selector: 'createdAt',
    sortable: true,
    format: row => formatDate(row.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
  },
  {
    name: 'Average Cooking Time',
    selector: 'avgCookingTime',
    sortable: false,
    format: row => row.avgCookingTime ? secondsToMinute(row.avgCookingTime) : '-',
  },
  {
    name: 'Active',
    selector: 'active',
    sortable: false,
    format: row => row.active?.toString() || '-',
  },
  {
    name: 'Online',
    selector: 'online',
    sortable: false,
    format: row => row.online?.toString() || '-',
  },
];

const mapStateToProps = (state: any) => ({
  user: state.auth.loggedInUser,
});

const Restaurants = props => {
  const [loading, setLoading] = useState<boolean>(true);
  const [restaurants, setRestaurants] = useState<Record<string, any>>({});
  
  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  const fetchRestaurants = async () => {
    setLoading(true);
    try {
      const response = await fetchRestaurantsByAdmin({
        filter: filters,
        page: filters.page,
        limit: getPageLimit(),
        sort,
      });
      setRestaurants(response);
    } catch(e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    filters = {
      page:filters.page,
      ...queryStringToFilter(),
    };
    fetchRestaurants();
  }, []);
  
  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };
  
  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchRestaurants();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const onFilterChanged = (updatedFilters: Record<string, any>) => {
    filters = { ...updatedFilters, page: 0 };
    updateQueryString();
    fetchRestaurants();
  };

  const onSort = (column: Record<string, any>, sortDirection: string) => {
    sort = {
      ...sort,
      [column.selector]: sortDirection,
    };
    fetchRestaurants();
  };
  
  const _isUserShopper = isUserShopper(props.user?.get('roles')) && props.user?.get('roles')?.length === 1;

  return (
    <div>
      {loading && <Loading />}
      {!_isUserShopper ? (
        <RestaurantsFilter
          {...props}
          onFilterSubmit={onFilterChanged}
        />
      ): null}
      <DataTable
        title="Restaurants"
        actions={(
          <ActionButton
            text={`Create Restaurant +`}
            active={true}
            isLoading={false}
            customClass={'btn-primary mb-2'}
            onClick={() => props.history.push('/restaurant/create')}
          />
        )}
        keyField="objectId"
        columns={columns}
        data={restaurants.items}
        highlightOnHover={true}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationDefaultPage={(filters.page || 0) + 1}
        paginationTotalRows={restaurants.total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onSort={onSort}
      />
    </div>
  );
};

const RestaurantsWrapper = connect(mapStateToProps)(Restaurants);
export { RestaurantsWrapper as Restaurants };