import ActionButton from 'components/ActionButton/ActionButton';
import React, { useState } from 'react';
import { addDishCollection } from 'services/api/dishCollection';
import { showError } from 'utils/notifications';
import '../AddCategoryModal/index.scss';
import { addRestaurantCollection } from '../../services/api/restaurantCollection';

const AddCollectionModal = props => {
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const {
    toggleModal,
    onSuccess,
    isRestaurantCollection,
  } = props;

  const createCollection = async () => {
    setLoading(true);
    try {
      if (isRestaurantCollection){
        const response = await addRestaurantCollection(name);
      }else{
        const response = await addDishCollection(name);
      }
      onSuccess();
      toggleModal();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={'add-category-modal-main-container'}>
      <div className={'add-category-modal-container'}>
        <div className={'add-category-modal-header'}>
          <div className={'heading'}>
            {`Create new ${isRestaurantCollection ? 'restaurant' : 'dish'} collection`}
          </div>
          <div className={'close-modal'}>
            <i className={'fa fa-times'} onClick={toggleModal} />
          </div>
        </div>
        <div className={'add-category-modal-body'}>
          <div className={'add-category-info-group'}>
            <div className={'label'}>Name</div>
            <div className={'field'}>
              <input
                className={'form-control'}
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={'add-category-modal-footer'}>
          <ActionButton
            type={'submit'}
            text={'Add'}
            active={!!name}
            isLoading={loading}
            onClick={createCollection}
            customClass={'btn-primary'}
          />
        </div>
      </div>
    </div>
  );
};

export default AddCollectionModal;