import React, { useEffect, useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import qs from 'query-string';
import './index.scss';
import ShopifyMerchantAutoSuggest from 'components/ShopifyMerchantAutoSuggest';

const MerchantFilters = props => {
  const [merchantId, setMerchantId] = useState<string>('');
  const [merchantName, setMerchantName] = useState<string>('');

  useEffect(() => {
    const {
      merchantId,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });

    if (merchantId) setMerchantId(merchantId.toString());
  }, []);

  const resetFilter = () => {
    setMerchantId('');
    setMerchantName("");

    props.onFilterSubmit({});
  };

  const onFilterSubmit = () => {
    const filterData: Record<string, any> = {
      _id: merchantId,
    };

    const filteredData = {};

    for (const key in filterData) {
      if (filterData[key] !== "") {
        filteredData[key] = filterData[key];
      }
    }

    props.onFilterSubmit(filteredData);
  };

  return (
    <form className='filter-container'>
      <div className='input-container mb-2 mr-4'>
        <label>Merchant ID</label>
        <input
          className='form-control'
          placeholder={'Merchant ID'}
          value={merchantId}
          onChange={e => setMerchantId(e.target.value)}
        />
      </div>
      <div className='input-container mr-4 mb-2'>
        <label>Shopify Merchant</label>
        <ShopifyMerchantAutoSuggest
          value={merchantName}
          handleChange={setMerchantName}
          placeholder={'Search Merchant'}
          onSuggestionSelect={setMerchantId}
        />
      </div>
      <ActionButton
        text={'Submit'}
        isLoading={false}
        active={true}
        customClass={'btn-primary mb-2'}
        onClick={onFilterSubmit}
      />
      <ActionButton
        text={'Reset'}
        isLoading={false}
        active={true}
        customClass={'btn-primary ml-4 mb-2'}
        onClick={resetFilter}
      />
    </form>
  );
};


export { MerchantFilters };
