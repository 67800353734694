import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { ACTION_ICON_TYPES, getPageLimit, updatePageLimit } from 'utils';
import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import { showError, showSuccess } from 'utils/notifications';
import { addBlacklistedMerchantForDriver, fetchBlacklistedMerchantsForDriver, removeBlacklistedMerchantForDriver } from 'services/api/blacklists';
import AddRestaurant from 'components/AddRestaurant';
import ActionButton from 'components/ActionButton/ActionButton';
import ActionIcon from 'components/ActionIcon';
import TableLink from 'TableLink';

const BlackListedMerchants = (props)=>{
  const [restaurants, setRestaurants] = useState<Record<string, any>>({});
  const [page, setPage] =useState<number>(0);
  const [addRestaurantModalOpen, setAddRestaurantModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: 'ID',
      selector: 'objectId',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/restaurants/${row.objectId}`}
          text={row.objectId}
          openInNewTab={false}
        />
      ),
    },
    {
      name: 'Restaurant Name',
      selector: 'restaurant_name',
      sortable: false,
    },
    {
      selector: '',
      sortable: false,
      name: 'Remove',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <div className={'ml-5'}>
          <ActionIcon
            actionType={ACTION_ICON_TYPES.DELETE}
            loading={false}
            onClick={(e)=>removeFromBlacklist(row?.objectId)}
          />
        </div>
      ),
    },
  ];

  const fetchBlacklistedRestaurant = async () => {
    setLoading(true);
    try {
      const response = await fetchBlacklistedMerchantsForDriver({
        limit: getPageLimit(),
        page: page,
        driverId: props?.driverId,
      });
      setRestaurants(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const addRestaurantFn = async (restaurantId) => {
    setLoading(true);
    try {
      setAddRestaurantModalOpen(!addRestaurantModalOpen);
      await addBlacklistedMerchantForDriver({ driverId:props?.driverId, restaurantId });
      await fetchBlacklistedRestaurant();
      showSuccess('Restaurant added to User successfully');
    } catch(e) {
      showError(e);
      setLoading(false);
    }
  };

  const removeFromBlacklist = async (restaurantId)=>{
    try{
      setLoading(true);
      await removeBlacklistedMerchantForDriver({ restaurantId });
      await fetchBlacklistedRestaurant();
      showSuccess('Restaurant removed from blacklist');
    }catch(e){
      showError(e);
    }finally{
      setLoading(false);
    }
  };

  const onChangePage = (page: number) => {
    setPage(page - 1);
    fetchBlacklistedRestaurant();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  useEffect(() => {
    fetchBlacklistedRestaurant();
  }, []);

  return (
    <div>
      {loading && <Loading />}
      {addRestaurantModalOpen && (
        <AddRestaurant
          toggleModal={() => setAddRestaurantModalOpen(!addRestaurantModalOpen)}
          onSubmit={addRestaurantFn}
        />
      )}
      <div className={'d-flex justify-content-between mb-2 pr-3'}>
        <div className='pl-2 font-weight-bolder text-muted'>Blacklisted Restaurants</div>
        <ActionButton
          text='Add Restaurant'
          active={true}
          isLoading={false}
          customClass={'btn btn-primary'}
          onClick={() => setAddRestaurantModalOpen(true)}
        />
      </div>
      
      <DataTable
        noHeader={true}
        keyField="objectId"
        columns={columns}
        data={restaurants.items}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationDefaultPage={page + 1}
        paginationTotalRows={restaurants.total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </div>
  );
};
export default BlackListedMerchants;