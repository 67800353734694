import { Types } from 'actions/types';
import { IAuth } from 'interfaces/auth';
import { isLoggedIn, login, logout } from 'services';

const IAuthAction: IAuth.DispatchFromProps = {
  isLoggedIn: () => {
    return isLoggedIn();
  },
  login: (username: string, password: string) => {
    return async function (dispatch: any) {
      dispatch({
        type: Types.LOGIN,
      });
      try {
        const currentUser = await login(username, password);
        dispatch({
          type: Types.LOGIN_SUCCESS,
          payload: { loggedInUser: currentUser },
        });
        return currentUser;
      } catch (e) {
        dispatch({
          type: Types.LOGIN_FAILED,
          payload: { message: e.message },
        });
        return null;
      }
    };
  },
  logout: () => {
    return async function (dispatch: any) {
      dispatch({
        type: Types.LOGOUT,
      });
      try {
        await logout();
        dispatch({
          type: Types.LOGOUT_SUCCESS,
        });
        return true;
      } catch (e) {
        dispatch({
          type: Types.LOGOUT_FAILED,
        });
        return false;
      }
    };
  },
};

export { IAuthAction as mapDispatchToProps };
