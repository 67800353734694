import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import './sidenavitem.scss';
import SideNavItemChild from './SideNavItemChild';

const SideNavItem = ({ menuItem }) => {
  const [renderChildren, setRenderChildren] = useState(false);
  const location = useLocation();
  const isActive = () => {
    return location.pathname === menuItem.url;
  };

  if (!menuItem.hasChildren) {
    return (
      <div>
        <Link to={menuItem.url} onClick={event => (menuItem.onClick && menuItem.onClick(event))} className="text-decoration-none">
          <div className={`side-nav-item d-flex align-items-center px-3 ${isActive() ? 'active' : ''} ${menuItem.customClass || ''}`}>
            <i className={`fa ${menuItem.iconClass}`}></i>
            <span className="text-nowrap">{menuItem.title}</span>
          </div>
        </Link>
      </div>
    );
  }

  return (
    <>
      <div
        onClick={() => setRenderChildren(!renderChildren)}
        className={`side-nav-item d-flex flex-col cursor-pointer align-items-center px-3`}
      >
        <i className={`fa ${menuItem.iconClass}`}></i>
        <span className="text-nowrap">{menuItem.title}</span>
        <i className={`fa fa-chevron-down ml-auto ${renderChildren ? `invert` : ``}`} />
      </div>
      {renderChildren && (
        menuItem.children.map((child, i) => (
          <SideNavItemChild key={i} menuItem={child} />
        ))
      )}
    </>
  );
};

export default SideNavItem;
