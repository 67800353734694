import RenderInfo from 'components/RenderInfo';
import React from 'react';
import styles from './index.module.scss';

const PerMerchantData = ({ details }) => {
  const { perMerchantRouteData = {}} = details;

  if (!Object.keys(perMerchantRouteData)?.length) return null;

  const getMerchantData = (merchantId, index) => {
    const {
      routeData: {
        distance = 0,
        duration = 0,
        hasToll = false,
      } = {},
      merchantDiscountPercentForPrimeSubscription = 0,
      merchantOptInForFreePrimeDelivery = false,
    } = perMerchantRouteData[merchantId];

    return (
      <div key={`${index}`} className={styles['basic-info']}>
        <div className='d-flex'>
          <span className='mr-2'>{index + 1}. &nbsp;</span>
          <div className={styles['info']}>
            <RenderInfo heading="Merchant Id" value={merchantId} link={`/restaurants/${merchantId}`} />
          </div>
        </div>
        <div className={styles['info']}>
          <RenderInfo heading="Merchant Opt In For Free Prime Delivery" value={merchantOptInForFreePrimeDelivery} type='boolean' />
          <RenderInfo heading="Merchant Discount Percent For Prime Subscription" value={merchantDiscountPercentForPrimeSubscription} />
        </div>
        <div className={styles['info']}>
          <RenderInfo heading="Duration" value={duration} />
          <RenderInfo heading="Distance" value={distance} />
        </div>
        <div className={styles['info']}>
          <RenderInfo heading="Has Toll" value={hasToll} type='boolean' />
        </div>
      </div>
    );
  };

  return (
    <div className={styles['section-container']}>
      <div className={styles['section-header']}>
        <div className={styles['heading']}>Per merchant route data</div>
      </div>
      {Object.keys(perMerchantRouteData)?.map(getMerchantData)}
    </div>
  );
};

export default PerMerchantData;