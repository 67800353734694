import ReportsFilter from 'components/ReportsFilter';
import React, { useState } from 'react';
import { getLeadReport } from 'services';
import { showError } from 'utils/notifications';
import moment from 'moment';
import { Loading } from 'components/loading/Loading';
import ExportCSVButton from 'components/ExportCSVButton';
import DataTable from 'react-data-table-component';
import EmptySectionMessage from 'components/message/EmptySectionMessage';
import { tableStyles } from 'css/tableStyles';
import TableLink from 'TableLink';

const columns = [
  {
    name: 'Lead ID',
    value: 'leadId',
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/leads/${row.leadId}`}
        text={row.leadId}
      />
    ),
  },
  {
    name: 'Lead Created At',
    selector: 'leadCreatedAt',
  },
  {
    name: 'Full Name',
    selector: 'leadName',
  },
  {
    name: 'Phone',
    selector: 'leadPhone',
  },
  {
    name: 'Email',
    selector: 'leadEmail',
  },
  {
    name: 'City',
    selector: 'leadCity',
  },
  {
    name: 'User ID',
    selector: 'userId',
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/drivers/${row.userId}`}
        text={row.userId}
      />
    ),
  },
  {
    name: 'Signed Up At',
    selector: 'signedUpAt',
  },
  {
    name: 'Driver Approved At',
    selector: 'driverApprovedAt',
  },
  {
    name: 'Promoted By User ID',
    selector: 'promotedByUserId',
  },
  {
    name: 'Promoted By User Name',
    selector: 'promotedByUserFullName',
  },
  {
    name: 'First Order Served At',
    selector: 'firstOrderServedAt',
  },
];

const columnsForCSV = [
  {
    label: 'Lead ID',
    value: 'leadId',
  },
  {
    label: 'Lead Created At',
    value: 'leadCreatedAt',
  },
  {
    label: 'Full Name',
    value: 'leadName',
  },
  {
    label: 'Phone',
    value: 'leadPhone',
  },
  {
    label: 'Email',
    value: 'leadEmail',
  },
  {
    label: 'City',
    value: 'leadCity',
  },
  {
    label: 'User ID',
    value: 'userId',
  },
  {
    label: 'Signed Up At',
    value: 'signedUpAt',
  },
  {
    label: 'Driver Approved At',
    value: 'driverApprovedAt',
  },
  {
    label: 'Promoted By User ID',
    value: 'promotedByUserId',
  },
  {
    label: 'Promoted By User Name',
    value: 'promotedByUserFullName',
  },
  {
    label: 'First Order Served At',
    value: 'firstOrderServedAt',
  },
];

const LeadReports = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [report, setReport] = useState<Record<string, any>[]>([]);
  const [startDate, setStartDate] = useState<Date | null | string>(moment().subtract(1, 'month').toISOString());
  const [endDate, setEndDate] = useState<Date | null | string>(moment().toISOString());
  const [apiCalled, setAPICalled] = useState<boolean>(false);

  const fetchLeadsReport = async () => {
    if (!apiCalled) setAPICalled(true);
    setLoading(true);
    try {
      const response = await getLeadReport({
        startDate,
        endDate,
      });
      setReport(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };
  
  const getDataTable = () => {
    if (loading) return <Loading />;

    if (report.length) {
      const exportCSVButton = <ExportCSVButton key={''} data={report} columnsForCSV={columnsForCSV} fileName={'Leads Report'} />;

      return (
        <DataTable
          title={'Reports'}
          columns={columns}
          data={report}
          customStyles={tableStyles}
          striped={true}
          actions={[exportCSVButton]}
        />
      );
    } else {
      return apiCalled ? <EmptySectionMessage message={'No records found'} /> : null;
    }
  };

  return (
    <div>
      <ReportsFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        showEmailInput={false}
        handleSubmit={fetchLeadsReport}
      />
      {getDataTable()}
    </div>
  );
};

export default LeadReports;