import { cloudFunction } from 'services/parse';
import { DATE_FORMATS, formatDate } from 'utils';

export const fetchUsersForAdminDashboard = async (filters: Record<string, any>) => {
  let response = await cloudFunction('fetchUsersForAdminDashboard', filters);

  response = {
    items: response.items.map(record => {
      return {
        objectId: record.id,
        name: record.get('full_name'),
        createdAt: formatDate(record.get('createdAt'), DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
        email: record.get('email'),
        phone: record.get('phone'),
      };
    }),
    total: response.total,
  };

  return response;
};

export const fetchUserDetailForAdminDashboard = async (id: string) => {
  const response = await cloudFunction('fetchUserDetailForAdminDashboard', { id });
  return response.toJSON();
};

export const fetchRoles = () => {
  return cloudFunction('fetchRoles', {});
};

export const assignRole = (userId: string, role: string) => {
  return cloudFunction('assignRole', { userId, role });
};

export const removeRoleForUser = (userId: string, role: string) => {
  return cloudFunction('removeUserFromRole', { userId, role });
};

export const addRestaurantToUser = (userId: string, restaurantId: string) => {
  return cloudFunction('addRestaurantToUser', { userId, restaurantId });
};

export const removeRestaurantFromUser = (userId: string, restaurantId: string) => {
  return cloudFunction('removeRestaurantFromUser', { userId, restaurantId });
};

export const fetchUserRestaurants = (userId: string) => {
  return cloudFunction('fetchUserRestaurants', { userId });
};

export const fetchStoresForUser = (id: string) => {
  return cloudFunction('fetchStoresForUser', { userId: id });
};

export const lockUser = (userId: string) => {
  return cloudFunction('lockUser', { userId });
};

export const unlockUser = (userId: string) => {
  return cloudFunction('unlockUser', { userId });
};

export const setAsFleetUser = (userId: string) => {
  return cloudFunction('setAsFleetUser', { id: userId });
};

export const removeAsFleetUser = (userId: string) => {
  return cloudFunction('removeAsFleetUser', { id: userId });
};

export const toggleOverrideValidPaymentMethodNeededFlag = (userId: string) => {
  return cloudFunction('toggleOverrideValidPaymentMethodNeededFlag', { id: userId });
};

export const reactivateUser = (userId: string) => {
  return cloudFunction('reactivateUser', { userId });
};

export const fetchFleetUserByEmail = (email: string) => {
  return cloudFunction('fetchFleetUserByEmail', { email }); 
};