import React, { useMemo, useState } from 'react';
import { getOrderRejectionReport } from 'services/api/reports';
import { showError } from 'utils/notifications';
import '../index.scss';
import { Loading } from 'components/loading/Loading';
import DataTable from 'react-data-table-component';
import _ from 'lodash';
import ExportCSVButton from 'components/ExportCSVButton';
import { tableStyles } from 'css/tableStyles';
import TableLink from 'TableLink';
import OrderRejectionFilters from './filters/index';
import RatingComponent from '../../../components/RatingComponent/index';
import DataTableFilter from 'components/DataTableFIlter';

const driverColumns = [
  {
    name: 'Driver ID',
    selector: 'driverId',
    sortable: false,
    width: '150px',
    // eslint-disable-next-line react/display-name
    cell: (row) => <TableLink linkTo={`/drivers/${row.driverId}`} text={row.driverId} />,
  },
  {
    name: 'Driver Name',
    selector: 'driverName',
    sortable: false,
    width: '180px',
  },
  {
    name: 'Driver Email',
    selector: 'driverEmail',
    sortable: false,
    width: '250px',
  },
];

const restaurantColumns = [
  {
    name: 'Merchant ID',
    selector: 'merchantId',
    sortable: false,
    width: '150px',
    // eslint-disable-next-line react/display-name
    cell: (row) => <TableLink linkTo={`/restaurants/${row.merchantId}`} text={row.merchantId} />,
  },
  {
    name: 'Merchant Name',
    selector: 'merchantName',
    sortable: false,
    width: '180px',
  },
  {
    name: 'Merchant Email',
    selector: 'merchantEmail',
    sortable: false,
    width: '250px',
  },
];

const commonColumns = [
  {
    name: 'Total Orders',
    selector: 'totalOrders',
    sortable: false,
  },
  {
    name: 'Accepted Orders',
    selector: 'acceptanceCount',
    sortable: false,
  },
  {
    name: 'Rejected Orders',
    selector: 'rejectionCount',
    sortable: false,
  },
  {
    name: 'Not Accepted Orders',
    selector: 'notAcceptanceCount',
    sortable: false,
  },
];

const ratingColumn= {
  name: 'Ratings',
  selector: 'averageRating',
  sortable: false,
  width: '200px',
  // eslint-disable-next-line react/display-name
  cell: (row) => <RatingComponent rating={row.averageRating || 0}/>,
};

const OrderRejectionSummary = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [groupBy, setGroupBy] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const CSVFileName = 'Order Rejection Summary';

  const fetchPaymentSummary = async (filters: Record<string,any>) => {
    setLoading(true);
    try {
      const response = await getOrderRejectionReport(filters);
      setGroupBy(filters.groupBy);
      if (response.length === 0) {
        throw 'No Data Found !';
      }
      setData(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const getTableColumns = useMemo(() => (
    groupBy === 'driver' ? (
      [
        ...driverColumns, 
        ...commonColumns,
        {
          name: 'Daily Rejection Ratio',
          selector: 'dailyRejectionRatio',
          sortable: true,
          width: '100px',
        },
        {
          name: 'Weekly Rejection Ratio',
          selector: 'weeklyRejectionRatio',
          sortable: true,
          width: '100px',
        },
        ratingColumn,
      ]) : (
      [
        ...restaurantColumns, 
        ...commonColumns,
        ratingColumn,
      ])
  ), 
  [groupBy]);

  const columnsForCSV = useMemo(() => (
    getTableColumns.map(col => ({ label: col.name, value: col.selector }))
  ), [getTableColumns],
  );

  

  const getDataTable = () => {
    if (loading) return <Loading />;
    if (!_.isEmpty(data)) {
      const exportCSVButton = <ExportCSVButton data={data} columnsForCSV={columnsForCSV} fileName={CSVFileName} />;
      const getSearchBox = () => (
        <div className='pl-2'>
          <DataTableFilter
            filterText={searchText}
            setFilterText={setSearchText}
            placeholder={'Search by Id or Name'}
          />
        </div>
      );
      const filterData = data.filter(obj => {
        if (
          (obj?.objectId?.toLowerCase().includes(searchText.toLowerCase())) 
          || (obj?.driverName?.toLowerCase().includes(searchText.toLowerCase()))
          || (obj?.merchantName?.toLowerCase().includes(searchText.toLowerCase()))
        ) {
          return true;
        }
        return false;
      });
      return (
        <DataTable
          title="Reports"
          keyField="objectId"
          columns={getTableColumns}
          data={filterData}
          customStyles={tableStyles}
          pagination={false}
          striped={true}
          actions={[getSearchBox(),exportCSVButton]}
        />
      );
    }
    return <div />;
  };

  return (
    <div className='report-container'>
      <OrderRejectionFilters loading={loading} onSubmit={fetchPaymentSummary}/>
      {getDataTable()}
    </div>
  );
};

export default OrderRejectionSummary;