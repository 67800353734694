import React, { useEffect, useState } from 'react';
import { fetchDuplicateOrders } from 'services/api/order';
import DataTable from 'react-data-table-component';
import { DateRangePickerWrapper } from 'components/Date/DateRangePicker';
import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import { showError } from 'utils/notifications';
import moment from 'moment';
import TableLink from 'TableLink';
import ActionButton from 'components/ActionButton/ActionButton';
import { DATE_FORMATS, convertCentsToDollors, formatDate } from 'utils';
import Currency from 'components/Currency';

const DuplicateOrders = props => {
  const [orders, setOrders] = useState<Record<string, any>[]>([]);
  const [startDate, setStartDate] = useState<string | null>(moment().toString());
  const [endDate, setEndDate] = useState<string | null>(moment().toString());
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: 'Order ID',
      selector: 'objectId',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/orders/${row.objectId}`}
          text={row.objectId}
          openInNewTab={true}
        />
      ),
    },
    {
      name: 'Restaurant ID',
      selector: 'restaurant.objectId',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/restaurants/${row.restaurant?.objectId}`}
          text={row.restaurant?.objectId}
          openInNewTab={true}
        />
      ),
    },
    {
      name: 'User Id',
      selector: 'user.objectId',
      // eslint-disable-next-line react/display-name
      cell: (row) =>
        <TableLink
          linkTo={`/users/${row.user.objectId}`}
          text={row.user.objectId}
          openInNewTab={true}
        />,
    },
    {
      name: 'Created At',
      selector: 'createdAt',
      cell: (row) => formatDate(row.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_SS_A),
      width:'250px',
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: false,
      // eslint-disable-next-line react/display-name
      format: row => <Currency amount={convertCentsToDollors(row.total)}/>,
    },
    {
      name: 'Status',
      selector: 'status',
    },
    {
      name: 'Cloud Request',
      selector: 'cloudRequest',
      // eslint-disable-next-line react/display-name
      cell: (row) =>
        <TableLink
          linkTo={`/cloud-requests?entityId=${row.objectId}`}
          text={<i className="fa fa-link" />}
          openInNewTab={true}
        />,
    },
  ];


  const fetchOrdersFn = async () => {
    setLoading(true);
    try {
      const response = await fetchDuplicateOrders({
        startDate,
        endDate,
      });
      setOrders(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const onDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    setStartDate(moment(startDate).toISOString());
    setEndDate(moment(endDate).toISOString());
  };

  useEffect(() => {
    fetchOrdersFn();
  }, []);

  const resetFilter = () => {
    setStartDate(moment().subtract(1, 'month').toString());
    setEndDate(moment().toString());
  };

  return (
    <div>
      {loading && <Loading />}
      <div className="d-flex">
        <div className='input-container mb-2 mr-4'>
          <label>Date Range</label>
          <DateRangePickerWrapper
            startDate={startDate}
            endDate={endDate}
            onDatesChange={onDateSelectionChange}
          />
        </div>
        <div className="align-self-end">
          <ActionButton
            text={'Submit'}
            isLoading={false}
            active={true}
            customClass={'btn-primary mb-2'}
            onClick={() => { fetchOrdersFn()}}
          />
          <ActionButton
            text={'Reset'}
            isLoading={false}
            active={true}
            customClass={'btn-primary ml-4 mb-2'}
            onClick={resetFilter}
          />
        </div>
      </div>
      {orders.map(duplicate=>(
        <div className="m-2" key={`div-${duplicate.duplicateId}`}>
          <DataTable
            title={`Duplicate Order# ${duplicate.duplicateId}`}
            key={duplicate.duplicateId}
            columns={columns}
            data={duplicate.duplicateOrders}
            customStyles={tableStyles}
          />
        </div>
      ),
      )
      }
    </div>
  );
};


export { DuplicateOrders };
