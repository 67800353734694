import React from 'react';
import ReactJson from 'react-json-view';
import { IJSONViewerProps } from 'interfaces/JSONViewer';

const JSONViewer: React.FC<IJSONViewerProps> = props => {
  return (
    <ReactJson
      src={props.data}
      name={props.rootVisible}
      theme={'monokai'}
      displayDataTypes={false}
      displayObjectSize={false}
      collapseStringsAfterLength={80}
    />
  );
};

export default JSONViewer;