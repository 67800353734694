import React from 'react';
import styles from './index.module.scss';
import ActionCheckbox from 'components/ToggleSwitch';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const PreScheduleConfig = (props) => {
  const { handleChange, restaurantDetails: { preScheduleConfig, preScheduleConfig: { allowPreSchedule = false, maxDaysBefore = 0, excludedDays = [] } = {}} = {}} = props;

  const getAllowPreSchedule = () => {
    return (
      <div className='d-flex align-items-center'>
        <ActionCheckbox
          switchLabel={'Allow pre schedule'} 
          disabled={false} 
          checked={allowPreSchedule} 
          onChange={() => {
            handleChange('preScheduleConfig', {
              ...preScheduleConfig, allowPreSchedule: !allowPreSchedule,
            });
          }}
          customClass='my-2'
        />
      </div>
    );
  };

  const getMaxDaysBefore = () => {
    return (
      <div className={styles['name']}>
        <div className={styles['label']}>Max Days Before</div>
        <input
          className={'form-control'}
          disabled={false}
          type='number'
          min={1}
          step={1}
          value={maxDaysBefore}
          onChange={(e) => {
            handleChange('preScheduleConfig', {
              ...preScheduleConfig, maxDaysBefore: e?.target?.value,
            });
          }}
        />
      </div>
    );
  };
  return (
    <div className={styles['container']}>
      <div className={styles['heading']}>PreSchedule Config</div>
      <div className={styles['sub-container']}>
        <div className={styles['sub-container-1']}>
          {getAllowPreSchedule()}
          {getMaxDaysBefore()}
        </div>
        <div className='ml-4'>
          <DayPicker
            mode="multiple"
            min={1}
            selected={excludedDays?.map((date) => new Date(date))}
            onSelect={(e) => {
              console.log(e, 'ddcndksc');
              handleChange('preScheduleConfig', {
                ...preScheduleConfig, excludedDays: e,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PreScheduleConfig;
