import ActionButton from 'components/ActionButton/ActionButton';
import React, { useState } from 'react';
import { addRestaurantNote, deleteRestaurantNote, editRestaurantNote, optInAutoLogIn, optInAutoLogOff, optOutAutoLogIn, optOutAutoLogOff, setRestaurantOffline, setRestaurantOnline, toggleAcceptDeferred, toggleRestaurantActiveStatus, updateRestaurant } from 'services/api/restaurant';
import { DATE_FORMATS, DAYS_NUMBER_TO_NAME, formatDate, secondsToMinute, timeToString } from 'utils';
import { showError, showSuccess } from 'utils/notifications';
import './index.scss';
import ToggleSwitch from 'components/ToggleSwitch';
import moment from 'moment';
import _ from 'lodash';
import JSONViewer from 'components/JSONViewer';
import TableLink from 'TableLink';
import RestaurantOnlineTimeGraph from './RestaurantOnlineTimeGraph';
import DynamicLink from 'components/DynamicLink';
import Currency from 'components/Currency';
import PreScheduleConfig from './preScheduledConfig';
import ColorTheme from './colorTheme';
import RestoDocAndAggrement from './RestoDocAndAggrement';
import DriverNotes from 'pages/drivers/driver-details/driver-notes';
const webAppUrl = process.env.REACT_APP_WEB_APP_URL;
const hubspotUrl = process.env.REACT_APP_HUBSPOT_COMPANY_LINK;

const getBasicDetails = (heading: string, value: any) => (
  <div className={'basic-details-group'}>
    <div className={'label'}>{heading}</div>
    <div className={'detail'}>{value ?? '-'}</div>
  </div>
);

const RestaurantInfo = props => {
  const [loading, setLoading] = useState<string>('');
  const [featuredLoading, setFeaturedLoading] = useState<boolean>(false);
  const [toggleLoading, setToggleLoading] = useState<boolean>(false);
  const { restaurantDetails, refreshDetails } = props;

  const markRestaurantOnlineOffline = async (markOnline: boolean) => {
    try {
      if (markOnline) {
        setLoading('online');
        await setRestaurantOnline(restaurantDetails.restaurantId);
      } else {
        setLoading('offline');
        await setRestaurantOffline(restaurantDetails.restaurantId);
      }
      await refreshDetails();
      showSuccess(`Restaurant marked ${markOnline ? 'Online' : 'Offline'} successfully`);
    } catch (e) {
      showError(e);
    } finally {
      setLoading('');
    }
  };

  const toggleRestaurantActiveStatusFn = async () => {
    setToggleLoading(true);
    try {
      await toggleRestaurantActiveStatus(restaurantDetails.restaurantId);
      await refreshDetails();
      showSuccess(`Restaurant Active toggled successfully`);
    } catch (e) {
      showError(e);
    } finally {
      setToggleLoading(false);
    }
  };

  const updateRestaurantInfo = async () => {
    setFeaturedLoading(true);

    try {
      await updateRestaurant({ featured: !restaurantDetails.featured, objectId: restaurantDetails.objectId });
      await refreshDetails();
    } catch (e) {
      showError(e);
    } finally {
      setFeaturedLoading(false);
    }
  };

  const toggleAcceptDefer = async () => {
    setToggleLoading(true);
    try {
      await toggleAcceptDeferred(restaurantDetails.restaurantId);
      await refreshDetails();
      showSuccess(`Restaurant Accepts defer toggled successfully.`);
    } catch (e) {
      showError(e);
    } finally {
      setToggleLoading(false);
    }
  };

  const toggleAutoLogoff = async () => {
    try {
      if (restaurantDetails.autoLogOff) {
        setLoading('autologoff_off');
        await optOutAutoLogOff(restaurantDetails.restaurantId);
      } else {
        setLoading('autologoff_on');
        await optInAutoLogOff(restaurantDetails.restaurantId);
      }
      showSuccess(`AutoLogoff turned ${restaurantDetails.autoLogOff ? 'OFF' : 'ON'} successfully`);
      await refreshDetails();
    } catch (e) {
      showError(e);
    } finally {
      setLoading('');
    }
  };

  const toggleAutoLogIn = async () => {
    try {
      if (restaurantDetails.autoLogIn) {
        setLoading('autologin_off');
        await optOutAutoLogIn(restaurantDetails.restaurantId);
      } else {
        setLoading('autologin_on');
        await optInAutoLogIn(restaurantDetails.restaurantId);
      }
      showSuccess(`AutoLogin turned ${restaurantDetails.autoLogIn ? 'OFF' : 'ON'} successfully`);
      await refreshDetails();
    } catch (e) {
      showError(e);
    } finally {
      setLoading('');
    }
  };

  const getMediaHandleURL = (url: string) => {
    const index = url.indexOf('https');
    if (index > -1) {
      return url;
    } else {
      return `https://${url}`;
    }
  };

  const getBankingDetails = () => (
    <div className={'restaurant-banking-section'}>
      <div className={'details-group'}>
        <div className={'label'}>Bank Account Number</div>
        <div className={'detail'}>{restaurantDetails.bankAccountNo}</div>
      </div>
      <div className={'details-group'}>
        <div className={'label'}>Bank Routing Number</div>
        <div className={'detail'}>{restaurantDetails.routingNo}</div>
      </div>
    </div>
  );

  const getLinksSection = () => {
    const hubspotLink = `${hubspotUrl}/${restaurantDetails?.hubspotCompanyId}`;
    const webAppLink = `${webAppUrl}/${restaurantDetails?.type}s/${restaurantDetails?.slug}`;
    return (
      <div className={'restaurant-banking-section'}>
        <div className={'details-group'}>
          <div className={'label'}>Hubspot Link</div>
          <div className={'detail'}>
            <TableLink
              linkTo={hubspotLink}
              openInNewTab={true}
              text={hubspotLink}
            />
          </div>
        </div>
        <div className={'details-group'}>
          <div className={'label'}>Link to Web-App</div>
          <div className={'detail'}>
            <TableLink
              linkTo={webAppLink}
              openInNewTab={true}
              text={webAppLink}
            />
          </div>
        </div>
        <DynamicLink link={restaurantDetails?.dynamicLink} />
      </div>
    );
  };

  const getRestaurantHours = () => {
    if (!restaurantDetails.schedule) return null;

    const getTime = (hour: string) => {
      if (!hour) return;
      const time = hour.slice(0, 2) + ':' + hour.slice(2, 4);
      const date = moment().format(DATE_FORMATS.YYYY_MM_DD) + `T${time}`;
      return moment(date).format(DATE_FORMATS.HH_MM_P);
    };

    const getNoScheduleMessage = () => (
      <div className={'no-shcedule-message'}>
        Schedule Not Set
      </div>
    );

    const getTimings = () => _.keys(restaurantDetails.schedule).map((key, i) => {
      const dayName = DAYS_NUMBER_TO_NAME[key];
      const dayHours = restaurantDetails.schedule[key];

      if (!dayHours.length) return null;

      const getDayHours = () => dayHours.map((timing, i) => {
        if (!timing) {
          return '-';
        } else {
          return (
            <div key={i} className={'day-single-slot'}>
              <div className={'font-weight-bold'}>From</div>
              <div className={'hours-start'}>{getTime(_.get(timing, 'start'))}</div>
              <div className={'font-weight-bold'}>To</div>
              <div className={'hours-end'}>{getTime(_.get(timing, 'end'))}</div>
            </div>
          );
        }
      });

      return (
        <div key={key} className={'single-day'}>
          <div className={'day-name'}>{dayName}</div>
          <div className={'day-slots'}>
            {getDayHours()}
          </div>
        </div>
      );
    });

    return (
      <div className={'restaurant-hours-section'}>
        <div className={'d-flex justify-content-between'}>
          <div className={'label'}>Opening Hours</div>
          <div className={'d-flex flex-column align-items-center'}>
            <div className={'label'}>Auto Logoff</div>
            <div className={'d-flex mt-2'}>
              <ActionButton
                text={'ON'}
                isLoading={loading === 'autologoff_on'}
                active={!restaurantDetails.autoLogOff}
                onClick={() => toggleAutoLogoff()}
                customClass={'btn-primary mr-3'}
              />
              <ActionButton
                text={'OFF'}
                isLoading={loading === 'autologoff_off'}
                active={restaurantDetails.autoLogOff}
                onClick={() => toggleAutoLogoff()}
                customClass={'btn-primary'}
              />
            </div>
          </div>
          <div className={'d-flex flex-column align-items-center'}>
            <div className={'label'}>Auto Login</div>
            <div className={'d-flex mt-2'}>
              <ActionButton
                text={'ON'}
                isLoading={loading === 'autologoin_on'}
                active={!restaurantDetails.autoLogIn}
                onClick={() => toggleAutoLogIn()}
                customClass={'btn-primary mr-3'}
              />
              <ActionButton
                text={'OFF'}
                isLoading={loading === 'autologin_off'}
                active={restaurantDetails.autoLogIn}
                onClick={() => toggleAutoLogIn()}
                customClass={'btn-primary'}
              />
            </div>
          </div>
        </div>
        <div className={'timings-section'}>
          {
            _.keys(restaurantDetails.schedule).length
              ? getTimings()
              : getNoScheduleMessage()
          }
        </div>
      </div>
    );
  };

  const getBasicDetailsSection = () => (
    <div className={'restaurant-basic-details-section'}>
      {getBasicDetails('Restaurant Id', restaurantDetails?.restaurantId)}
      {getBasicDetails('Average Cooking Time', `${secondsToMinute(restaurantDetails.avgCookingTime)} mins.`)}
      {getBasicDetails('Type', restaurantDetails?.type)}
      {getBasicDetails('Category', restaurantDetails?.category)}
      {getBasicDetails('Last 30 days Average Online Time ', timeToString(restaurantDetails?.avgOnlineTimeForLast30Days))}
      {getBasicDetails('Limited Number of Items', restaurantDetails?.limitedNoOfItems)}
      {getBasicDetails('Recieve Order Email', restaurantDetails?.recieve_order_email ? 'True' : 'False')}
      {getBasicDetails('Notify Online Status', restaurantDetails?.notifyOnlineStatus)}
      {getBasicDetails('Restaurant Radius', restaurantDetails?.restaurantRadius)}
      {getBasicDetails('Average Rating', restaurantDetails?.averageRating)}
      {getBasicDetails('Tax Percent', restaurantDetails?.taxPercent)}
      {getBasicDetails('Participate In Prime Program', restaurantDetails?.participateInPrimeSubscription ? 'True' : 'False')}
      {getBasicDetails('Free Prime Delivery', restaurantDetails?.optInForFreePrimeDelivery ? 'True' : 'False')}
      {getBasicDetails('Prime Subscription Discount', `${restaurantDetails?.discountPercentForPrimeSubscription || 0} %`)}
      {getBasicDetails('Online Event SMS Phone Number', restaurantDetails?.online_event_sms_phone)}
      {getBasicDetails('Created At', formatDate(restaurantDetails.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A))}
      <div className={'basic-details-group'}>
        <div className={'label'}>
          {restaurantDetails.active ? 'Active' : 'Inactive'}
        </div>
        <ToggleSwitch
          switchLabel={''}
          checked={restaurantDetails.active}
          disabled={toggleLoading}
          onChange={toggleRestaurantActiveStatusFn}
        />
      </div>
      <div className={'basic-details-group'}>
        <div className={'label'}>
          {'Accepts Defer'}
        </div>
        <ToggleSwitch
          switchLabel={''}
          checked={restaurantDetails.acceptsDeferred}
          disabled={toggleLoading}
          onChange={toggleAcceptDefer}
        />
      </div>
      <div className={'basic-details-group'}>
        <div className={'label'}>
          {'Featured'}
        </div>
        <ToggleSwitch
          switchLabel={''}
          onChange={updateRestaurantInfo}
          disabled={featuredLoading}
          checked={restaurantDetails.featured || false}
        />
      </div>
    </div>
  );

  const getDetailsHeader = () => (
    <div className={'restaurant-info-section'}>
      <div className={'left-panel'}>
        <div className={'restaurant-name'}>{restaurantDetails.restaurant_name}</div>
        <div className={'restaurant-address'}>{restaurantDetails.contact_address}</div>
        <div className={'restaurant-about-us'}>{restaurantDetails.restaurant_about_us}</div>
      </div>
      <div className={'right-panel'}>
        <div className={`online-status ${restaurantDetails.online ? 'online' : 'offline'}`}>
          {restaurantDetails.online ? 'Online' : 'Offline'}
        </div>
        <div className={'restaurant-contact-info'}>
          <div className={'label'}>Contact Information</div>
          <div className={'contact-info'}>{restaurantDetails.contact_phone}</div>
          <div className={'contact-info'}>{restaurantDetails.contact_email}</div>
        </div>
        {!!restaurantDetails.online_event_sms_phone && (
          <div className={'restaurant-contact-info'}>
            <div className={'label'}>Contact Online/Offline Number</div>
            <div className={'contact-info'}>{restaurantDetails.online_event_sms_phone}</div>
          </div>
        )}
        {!!restaurantDetails.limitedNoOfItems && (
          <div className={'restaurant-item-limit'}>
            <div className={'label'}>Order Items Limit</div>
            <div className={'info'}>{restaurantDetails.limitedNoOfItems}</div>
          </div>
        )}
      </div>
    </div>
  );

  const MediaLink = props => {
    const { link, iconURL } = props;
    return (
      <img
        src={iconURL}
        onClick={() => window.open(link, '_blank')}
      />
    );
  };

  const getImageSection = () => (
    <div className={'cover-image-section'}>
      <img
        className={'cover-image'}
        src={restaurantDetails.image?.url}
      />
      {!restaurantDetails.online ? (
        <div className={'online-dot'} />
      ) : null}
      <img
        className={'restaurant-avatar'}
        src={restaurantDetails.restaurant_logo?.url}
      />
      <div className={'social-media-links'}>
        {restaurantDetails.contact_facebook && (
          <MediaLink
            link={getMediaHandleURL(restaurantDetails.contact_facebook)}
            iconURL={require('assets/facebook.svg')}
          />
        )}
        {restaurantDetails.contact_instagram && (
          <MediaLink
            link={getMediaHandleURL(restaurantDetails.contact_instagram)}
            iconURL={require('assets/instagram.svg')}
          />
        )}
        {restaurantDetails.contact_twitter && (
          <MediaLink
            link={getMediaHandleURL(restaurantDetails.contact_twitter)}
            iconURL={require('assets/twitter.svg')}
          />
        )}
      </div>
      <ActionButton
        active={true}
        isLoading={false}
        onClick={() => props.history.push(`/restaurants/${restaurantDetails.objectId}/edit`)}
        text={'Edit'}
        customClass={'btn-primary'}
      />

      <div className={'buttons-section pb-3'}>
        {restaurantDetails.isDeleted && <label className="deleted-flag">{'DELETED'}</label>}
        <ActionButton
          text={'Mark Offline'}
          isLoading={loading === 'offline'}
          active={restaurantDetails.online}
          onClick={() => markRestaurantOnlineOffline(false)}
          customClass={'btn-primary mr-3'}
        />
        <ActionButton
          text={'Mark Online'}
          isLoading={loading === 'online'}
          active={!restaurantDetails.online}
          onClick={() => markRestaurantOnlineOffline(true)}
          customClass={'btn-primary'}
        />
      </div>
    </div>
  );

  const getServiceableTracts = () => {
    if (!_.isEmpty(restaurantDetails?.serviceableTracts)) {
      return (
        <div className={'serviceable-tracts'}>
          <div className='restaurant-details-label-2 mb-3'>Serviceable Tracts</div>
          <JSONViewer data={restaurantDetails?.serviceableTracts} rootVisible={false} />
        </div>
      );
    } else {
      return null;
    }
  };

  const getDeliveryConfigSection = () => (
    <>
      <div className={'label'}>Delivery Config</div>
      <div className={'delivery-config-section'}>
        {getBasicDetails('Base Charges', <Currency amount={restaurantDetails?.deliveryConfig?.baseCharges} />)}
        {getBasicDetails('Base Miles', restaurantDetails?.deliveryConfig?.baseMiles)}
        {getBasicDetails('$ Extra Mile (Normal Order)', <Currency amount={restaurantDetails?.deliveryConfig?.dollarExtraMileNormalOrder} />)}
        {getBasicDetails('$/Extra Minute (Normal Order)', <Currency amount={restaurantDetails?.deliveryConfig?.dollarPerExtraMinuteNormalOrder} />)}
        {getBasicDetails('Base Minutes', timeToString(restaurantDetails?.deliveryConfig?.baseMinutes))}
        {getBasicDetails('Gas Charges (Per Mile)', <Currency amount={restaurantDetails?.deliveryConfig?.gasChargesPerMile} />)}
        {getBasicDetails('Base Charges (Routed Order)', <Currency amount={restaurantDetails?.deliveryConfig?.baseChargesForRoutedOrder} />)}
        {getBasicDetails('$/Extra Minute (Routed Order)', <Currency amount={restaurantDetails?.deliveryConfig?.dollarPerExtraMinuteRoutedOrder} />)}
        {getBasicDetails('$ Extra Mile (Routed Order)', <Currency amount={restaurantDetails?.deliveryConfig?.dollarExtraMileRoutedOrder} />)}
        {getBasicDetails('Max Delivery Charges', <Currency amount={restaurantDetails?.deliveryConfig?.maxDeliveryCharges} />)}
      </div>
    </>
  );

  const getDeliveryConfigFleetSection = () => (
    <>
      <div className={'label'}>Delivery Config(Fleet Order)</div>
      <div className={'delivery-config-section'}>
        {getBasicDetails('Base Charges For FleetOrder', <Currency amount={restaurantDetails?.deliveryConfig?.baseChargesForFleetOrder} />)}
        {getBasicDetails('$/Extra Minute (Fleet Order)', <Currency amount={restaurantDetails?.deliveryConfig?.dollarPerExtraMinuteForFleetOrder} />)}
        {getBasicDetails('$ Extra Mile (Fleet Order)', <Currency amount={restaurantDetails?.deliveryConfig?.dollarExtraMileFleetOrder} />)}
      </div>
    </>
  );

  const getRevSharePercentConfig = () => (
    <>
      <div className={'label'}>Rev Share Percent Config</div>
      <div className={'delivery-config-section'}>
        {getBasicDetails('Normal', <Currency amount={restaurantDetails?.revSharePercentConfig?.normal} />)}
        {getBasicDetails('Prime', <Currency amount={restaurantDetails?.revSharePercentConfig?.prime} />)}
        {getBasicDetails('Clip', <Currency amount={restaurantDetails?.revSharePercentConfig?.clip} />)}
        {getBasicDetails('Take out', <Currency amount={restaurantDetails?.revSharePercentConfig?.take_out} />)}
      </div>
    </>
  );

  const getRestoDocsAndAggrement =() => (
    <>
      <RestoDocAndAggrement restaurantDetails={restaurantDetails}/>
    </>
  );

  const getRestaurantNotes = () => {
    return (
      <div className="mt-3 p-3">
        <DriverNotes notes={restaurantDetails?.notes} id={restaurantDetails?.objectId} headerName='Restaurant' 
          addNotesAPI={addRestaurantNote} editNotesAPI={editRestaurantNote} deleteNoteAPI={deleteRestaurantNote}/>
      </div>
    );
  };

  return (
    <div className={'restaurant-info-container'}>
      {getImageSection()}
      {getDetailsHeader()}
      {getBasicDetailsSection()}
      {getRestaurantHours()}
      <PreScheduleConfig restaurantDetails={restaurantDetails} />
      <ColorTheme restaurantDetails={restaurantDetails} />
      <RestaurantOnlineTimeGraph restaurantId={restaurantDetails.restaurantId} />
      {getBankingDetails()}
      {getLinksSection()}
      {getRestoDocsAndAggrement()}
      {getRestaurantNotes()}
      {getDeliveryConfigSection()}
      {getDeliveryConfigFleetSection()}
      {getRevSharePercentConfig()}
      {getServiceableTracts()}
    </div>
  );
};

export default RestaurantInfo;