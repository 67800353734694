import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { showError } from 'utils';
import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import styles from './index.module.scss';
import ActionButton from 'components/ActionButton/ActionButton';
import CustomLink from 'TableLink';
import { fetchAllStripeProductsForAdmin } from 'services/api/stripeProducts';

const StripeProductsList = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [stripeProducts, setStripeProducts] = useState([]);

  const fetchAllStripeProductsFn = async () => {
    setLoading(true);
    try {
      const response = await fetchAllStripeProductsForAdmin();
      setStripeProducts(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllStripeProductsFn();
  }, []);

  const columns = useMemo(() => [
    {
      name: 'Id',
      selector: 'objectId',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <CustomLink
          text={row.objectId}
          linkTo={`/stripe-products/${row.slug}`}
        />
      ),
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Stripe Product Id',
      selector: 'stripeProductId',
    },
    {
      name: 'Product Type',
      selector: 'productType',
    },
    {
      name: 'Slug',
      selector: 'slug',
    },
    {
      name: 'Active',
      selector: 'active',
      format: (row) => row.active?.toString() || 'false',
    },
  ], []);

  const getHeader = () => {
    return (
      <div className={styles['header']}>
        <div className={styles['heading']}>Stripe Products</div>
        <div className={styles['buttons-container']}>
          <ActionButton
            text={<>Stripe Product <span>+</span></>}
            active={true}
            isLoading={false}
            onClick={() => { props.history.push('/stripe-products/create') }}
            customClass={styles['add-new-pick-pack-button']}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      {loading && <Loading />}
      <div className={styles['main-container']}>
        {getHeader()}
        <DataTable
          keyField="objectId"
          columns={columns}
          noHeader={true}
          data={stripeProducts}
          highlightOnHover={true}
          customStyles={tableStyles}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default StripeProductsList;
