import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { LocationPickerWrapper } from '../LocationPickerWrapper';
import './index.module.scss';
import LocationDetails from './locationDetails';
import SearchLocation from './searchLocation';
import { DEFAULT_ADDRESS } from '../../../utils/constant';
import AddressDetails from './addressDetails';
import { IAddress } from '../../../interfaces/address';
import scriptLoader from 'react-async-script-loader';

export const addressComponents = {
  locationDetails: 'locationDetails',
  addressDetails: 'addressDetails',
  searchLocation: 'searchLocation',
};
 
const AddAddressModal: React.FC<IAddress.IAddAddressModal> = (props) => {
  const {
    modalOpen,
    onModalClose,
    address,
    onSuccess,
    showDoNotSaveOption = true,
  } = props;
  const [componentToShow, setComponentToShow] = useState(
    addressComponents.locationDetails,
  );
  const [localAddress, setLocalAddress] = useState({}) as any;

  useEffect(() => {
    if (address) {
      setLocalAddress({ ...address, coords: { lat: address?.coords?.latitude, lng: address?.coords?.longitude }});
    } else {
      setLocalAddress({
        address: 'Puerto Rico',
        secondAddress: '',
        town: '',
        zip: '',
        number: '',
        coords: DEFAULT_ADDRESS.coords,
      });
    }
  }, [address]);

  const setAddressKey = (key, value) => {
    setLocalAddress({ ...localAddress, [key]: value });
  };

  const handleClose = () => {
    onModalClose();
    setComponentToShow(addressComponents.locationDetails);
  };

  const renderLocationSearch = () => {
    if (componentToShow === addressComponents.searchLocation) {
      return <SearchLocation
        setComponentToShow={setComponentToShow}
        onSelect={(e) => {
          setComponentToShow(addressComponents.locationDetails);
          setLocalAddress({
            ...localAddress,
            coords: e.coords,
            address: e.address,
          });
        }}
      />;
    } else {
      return null;
    }
  };

  const renderAddressDetails = () => {
    if (componentToShow === addressComponents.addressDetails) {
      return (
        <AddressDetails
          setComponentToShow={setComponentToShow}
          localAddress={localAddress}
          setAddressKey={setAddressKey}
          onSuccess={onSuccess}
          handleClose={handleClose}
          showDoNotSaveOption={showDoNotSaveOption}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <Modal show={modalOpen} animation={false} size='lg'>
      <LocationPickerWrapper
        containerStyle={
          componentToShow === addressComponents.addressDetails
            ? containerStyleAddressDetails
            : containerStyle
        }
        onMapLocationChanged={(coords) => { setAddressKey('coords', coords) }}
        locationCoords={localAddress.coords}
        isVisible={componentToShow !== addressComponents.searchLocation}
        draggable={componentToShow === addressComponents.locationDetails}
      />
      <Modal.Body>
        <div className="edit-address row">
          <LocationDetails
            setComponentToShow={setComponentToShow}
            localAddress={localAddress}
            handleClose={handleClose}
            setLocalAddress={setLocalAddress}
            isVisible={componentToShow === addressComponents.locationDetails}
          />
          {renderLocationSearch()}
          {renderAddressDetails()}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '300px',
  borderRadius: '10px',
};

const containerStyleAddressDetails = {
  position: 'relative',
  width: '100%',
  height: '200px',
  borderRadius: '2px',
};

export default scriptLoader(
  [`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places`])(AddAddressModal);
