import ActionButton from 'components/ActionButton/ActionButton';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import qs from 'query-string';
import { DISCOUNT_TYPES } from 'utils';
import DropDown from 'components/Dropdown';

const Filter = props => {
  const [code, setCode] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [discountId, setDiscountId] = useState<string>('');

  useEffect(() => {
    const {
      code,
      type,
      discountId,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });

    if (code) setCode(code.toString());
    if (type) setType(type.toString());
    if (discountId) setDiscountId(discountId.toString());
  }, []);

  const resetFilter = () => {
    setCode('');
    setType('');
    setDiscountId('');
    props.onFilterSubmit({});
  };

  const onFilterSubmit = () => {
    const filterData: Record<string, any> = {
      code,
      type,
      discountId,
    };
    props.onFilterSubmit(filterData);
  };

  return (
    <form className={styles['filter-container']}>
      <div className={styles['input-group']}>
        <div className={styles['label']}>Discount Type</div>
        <DropDown value={type} onChange={(e) => setType(e)} name={'Discount Type'} options={DISCOUNT_TYPES} />
      </div>
      <div className={styles['input-group']}>
        <div className={styles['label']}>Discount ID</div>
        <input
          className={'form-control'}
          value={discountId}
          placeholder={'Discount Id'}
          onChange={e => setDiscountId(e.target.value)}
        />
      </div>
      <div className={styles['input-group']}>
        <div className={styles['label']}>Discount Code</div>
        <input
          className={'form-control'}
          value={code}
          placeholder={'Discount Code'}
          onChange={e => setCode(e.target.value)}
        />
      </div>
      <div className={styles['buttons-container']}>
        <ActionButton
          text={'Submit'}
          active={true}
          isLoading={false}
          onClick={onFilterSubmit}
        />
        <ActionButton
          text={'Reset'}
          active={true}
          isLoading={false}
          onClick={resetFilter}
        />
      </div>
    </form>
  );
};

export default Filter;