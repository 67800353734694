import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from 'react';
import PaymentIcon from 'react-payment-icons';
import { fetchOrderTransaction } from 'services/api/order';
import { ORDER_STATUS } from 'utils';
import { showError } from 'utils/notifications';

const TransactionInfo = (props: {
  orderId: string; isDeferredOrder?: boolean;
  parentOrderId: string | undefined; orderStatus?: string | undefined;
  isParentOrder: boolean | undefined;
}) => {
  const [transactionInfo, setTransactionInfo] = useState<Record<string, any>>({});
  const [transactionInfoLoading, setTransactionInfoLoading] = useState<boolean>(true);
  const [transactionType, setTransactionType] = useState<string>('');
  const { orderId, parentOrderId, orderStatus, isParentOrder } = props;

  const fetchTransactionInfo = async () => {
    try {
      const response = await fetchOrderTransaction(orderId, props.isDeferredOrder);
      setTransactionInfo(response);
      if (response.chargeId) setTransactionType('stripe');
      else if (response.athMovilReferenceNumber) setTransactionType('athMovil');
      else setTransactionType('none');
    } catch (e) {
      showError(e);
    } finally {
      setTransactionInfoLoading(false);
    }
  };

  useEffect(() => {
    if (orderStatus === ORDER_STATUS.UNPAID) {
      setTransactionInfoLoading(false);
    } else if (!parentOrderId) {
      fetchTransactionInfo();
    } else {
      setTransactionInfoLoading(false);
    }

  }, [orderId]);

  const getTransactionDetails = () => {
    const getCardInfo = () => {
      // eslint-disable-next-line @typescript-eslint/camelcase
      const { brand, last4, exp_month, exp_year } = transactionInfo.card;
      return (
        <div className={'card-details'}>
          <div className={'card-number'}>
            <span>Card Number</span>
            <div className={'d-flex'}>
              <PaymentIcon
                id={brand.toLowerCase()}
                className={'payment-method-icon'}
              />
              <span>{`**** **** **** ${last4}`}</span>
            </div>
          </div>
          <div className={'card-expiry'}>
            <span>Card Expiry</span>
            {/* eslint-disable-next-line @typescript-eslint/camelcase */}
            <span>{`${exp_month}/${exp_year}`}</span>
          </div>
        </div>
      );
    };

    const isTransactionOnStripe = () => transactionType === 'stripe';

    return (
      <div className={'transaction-section'}>
        <div className={'transaction-type'}>
          <span>Payment made by</span>
          <span>{isTransactionOnStripe() ? 'Stripe' :
            <span>
              {`Please see transaction of parent order `}
              <a href={`/orders/${parentOrderId}`}>{parentOrderId}</a>
            </span>
          }
          </span>
        </div>
        {transactionInfo.card && getCardInfo()}
        <div className={'transaction-id'}>
          <span>{isTransactionOnStripe() ? 'Charge ID' : 'Transaction Reference No.'}</span>
          <span>{isTransactionOnStripe() ? transactionInfo.chargeId : transactionInfo.athMovilReferenceNumber}</span>
        </div>
      </div>
    );
  };

  const getMessage = () => transactionInfo.success === false ? (
    <div className={'no-transaction'}>{transactionInfo.error || 'Transaction was not successful'}</div>
  ) : (
    <div className={'no-transaction'}>No transaction found</div>
  );

  const getTransaction = () => {
    return (
      <div>
        {transactionType === 'none'
          ? getMessage()
          : getTransactionDetails()
        }
      </div>
    );
  };

  const getTransactionInfo = () => {
    if (isParentOrder) {
      return <>{getTransaction()}</>;
    } else if (orderStatus === ORDER_STATUS.UNPAID) {
      return <div className={'no-transaction'}>Order is Unpaid.</div>;
    } else if (parentOrderId) {
      return <div className={'no-transaction'}>Please see the transaction of parent order.</div>;
    } else if (!isParentOrder || !parentOrderId && orderStatus !== ORDER_STATUS.UNPAID) {
      return <div className={'no-transaction'}>{getTransaction()}</div>;
    } else {
      return <div className={'no-transaction'}>No transaction Details</div>;
    }
  };

  if (transactionInfoLoading) return <Loading type='mini' />;

  return (
    <div className={'transaction-info-section'}>
      <div className={'label'}>Transaction Info</div>
      {getTransactionInfo()}
    </div>
  );
};

export default TransactionInfo;