import { ILead } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { Message } from 'components/message/Message';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { updateUserDetail } from 'services';
import moment from 'moment';
import { phoneRegEx } from 'utils/constant';
import { Error } from 'components/message/Error';
import { showError } from 'utils/notifications';
import TableLink from 'TableLink';

const validationSchema = yup.object().shape({
  birthDate: yup.string(),
  gender: yup.string(),
  phone: yup.string().matches(phoneRegEx, 'Phone Number must be valid'),
  socialSecurityNumber: yup.string(),
  bankAccountNumber: yup.string(),
  bankRoutingNumber: yup.string(),
});

type FormData = {
  birthDate: string;
  gender: string;
  phone: string;
  socialSecurityNumber: string;
  bankAccountNumber: string;
  bankRoutingNumber: string;
};

const UserDetail = ({ user } ) => {
  const { register, handleSubmit, errors } = useForm<FormData>({ resolver: yupResolver(validationSchema) });
  const [birthDate, setBirthDate] = useState<Date | null>();
  const [gender, setGender] = useState('');
  const [phone, setPhone] = useState('');
  const [socialSecurityNumber, setSocialSecurityNumber] = useState('');
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [bankRoutingNumber, setBankRoutingNumber] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setGender(user.gender || '');
    setPhone(user.phone || '');
    setSocialSecurityNumber(user.ssn || '');
    setBankAccountNumber(user.accountNumber || '');
    setBankRoutingNumber(user.routingNumber || '');
    if (user.dateOfBirth) {
      setBirthDate(moment(user.dateOfBirth).toDate());
    } else {
      setBirthDate(null);
    }
  }, []);

  const updateUserDetails = handleSubmit(async () => {
    setLoading(true);
    const data = {
      accountNumber: bankAccountNumber,
      routingNumber: bankRoutingNumber,
      dateOfBirth: birthDate ? moment(birthDate).toISOString() : null,
      phone,
      ssn: socialSecurityNumber,
      gender: gender,
    };

    try {
      await updateUserDetail(user.objectId, data);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  });

  return (
    <div className='row'>
      <div className='col-8 user-detail-section'>
        <div className="col-6 col-sm-12 col-md-6 pb-2">
          <label className="label text-muted mb-0">Driver Id</label>
          <div className='info'>
            <TableLink 
              linkTo={`/drivers/${user.objectId}`}
              text={user.objectId}
              openInNewTab={false}
              customClass = {'font-weight-bolder'}
            />
          </div>
        </div>
        <div className="col-6 col-sm-12 col-md-6 pb-2">
          <label className="label text-muted mb-0">Name</label>
          <div className="info font-weight-bolder">{user.full_name}</div>
        </div>
        <div className="col-6 col-sm-12 col-md-6 pb-2">
          <label className="label text-muted mb-0">Email</label>
          <div className="info font-weight-bolder">{user.username}</div>
        </div>
        <div className="col-6 col-sm-12 col-md-6 pb-2">
          <label className="label text-muted mb-0">StripeId</label>
          <div className="info font-weight-bolder">{user.stripeId || 'Not available'}</div>
        </div>
        <div className="col-8 col-sm-12 col-md-6 pb-2 date-picker">
          <label className="label text-muted mb-0">Birth Date</label>
          <DatePicker
            className='form-control'
            selected={birthDate}
            onChange={()=>{}}
          />
          {errors.birthDate && <Error message={errors.birthDate.message} />}
        </div>
        <div className="col-6 col-sm-12 col-md-6 pb-2">
          <label className="label text-muted mb-0">Gender</label>
          <select name='gender-picker' className='form-control' value={gender} ref={register} onChange={e => setGender(e.target.value)}>
            <option value='Female'>Female</option>
            <option value='Male'>Male</option>
            <option value='Rather not say'>Rather not say</option>
            <option value='Other'>Other</option>
          </select>
          {errors.gender && <Error message={errors.gender.message} />}
        </div>
        <div className="col-6 col-sm-12 col-md-6 pb-2">
          <label className="label text-muted mb-0">Phone</label>
          <input
            type={'number'}
            name={'phone'}
            ref={register}
            className='form-control'
            value={phone || ''}
            onChange={e => setPhone(e.target.value)}
          />
          {errors.phone && <Error message={errors.phone.message} />}
        </div>
        <div className="col-6 col-sm-12 col-md-6 pb-2">
          <label className="label text-muted mb-0">Social Security Number</label>
          <input
            name={'socialSecurityNumber'}
            ref={register}
            className='form-control'
            value={socialSecurityNumber}
            onChange={e => setSocialSecurityNumber(e.target.value)}
          />
          {errors.socialSecurityNumber && <Error message={errors.socialSecurityNumber.message} />}
        </div>
        <div className="col-6 col-sm-12 col-md-6 pb-2">
          <label className="label text-muted mb-0">Bank Account Number</label>
          <input
            name={'bankAccountNumber'}
            ref={register}
            className='form-control'
            value={bankAccountNumber || ''}
            onChange={e => setBankAccountNumber(e.target.value)}
          />
          {errors.bankAccountNumber && <Error message={errors.bankAccountNumber.message} />}
        </div>
        <div className="col-6 col-sm-12 col-md-6 pb-2">
          <label className="label text-muted mb-0">Bank Routing Number</label>
          <input
            name={'bankRoutingNumber'}
            ref={register}
            className='form-control'
            value={bankRoutingNumber || ''}
            onChange={e => setBankRoutingNumber(e.target.value)}
          />
          {errors.bankRoutingNumber && <Error message={errors.bankRoutingNumber.message} />}
        </div>
      </div>
      <div className="col text-center buttons-section">
        <ActionButton isLoading={loading} active={true} text={'Resend Welcome Email'} onClick={() => { }} customClass="btn-primary float-right" />
        <div className="resend-email-helper text-muted">
          if the user hasn’t received the welcome email, you can resend the email here
        </div>
        <ActionButton
          text={'Update User Details'}
          active={true}
          isLoading={loading}
          onClick={updateUserDetails}
          customClass={'btn-primary update-user-button'}
        />
      </div>
    </div>
  );
};

const NoUser = () => {
  return (
    <div className="text-center">
      <Message message="No User Detail" />
    </div>
  );
};

const UserDetailWrapper = ({ user, loading }: { user: ILead.IUserDetail; loading: boolean }) => {
  return (
    <div className="user-detail mt-4">
      <h4 className="pl-2 font-weight-bolder">Driver details</h4>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              { user ? <UserDetail user={user} /> : <NoUser/>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { UserDetailWrapper as UserDetail };
