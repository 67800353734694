import React from 'react';
import Switch from 'react-switch';
import { IToggleSwitchProps } from 'interfaces/toggleSwitch';
import './index.scss';

const ToggleSwitch: React.FC<IToggleSwitchProps> = props => {
  const { switchLabel, disabled = false, checked = false, onChange, customClass } = props;

  return (
    <>
      <span className={`${customClass || ''} pr-1`}>{switchLabel}</span>
      <Switch
        className={`react-switch-bg ${checked ? 'checked' : 'unchecked'}`}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        onColor={'#F4511D'}
        handleDiameter={14}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        height={22}
        width={45}
      />
    </>
  );
};

export default ToggleSwitch;
