import React, { useState } from 'react';
import { Loading } from 'components/loading/Loading';

const CityDropDown = ({ city, onChange, cities, loading, disabled = false }: { city: string; onChange: any; cities: any[]; loading: boolean, disabled?: boolean }) => {
  const [cityList, setCityList] = useState(cities);

  const getOptions = () => {
    return cityList.map(({ city }) => (<option value={city} key={city}>{city}</option>));
  };

  if (loading) {
    return (
      <div className="form-control">
        <Loading type="mini" />
      </div>
    );
  }
  return (
    <>
      <select value={city} className="form-control" onChange={onChange} disabled={disabled}>
        <option>Select City</option>
        {getOptions()}
      </select>
    </>
  );
};

export default CityDropDown;
