import { tableStyles } from 'css/tableStyles';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { DATE_FORMATS, formatDate, getPageLimit, updatePageLimit } from 'utils';
import { showError } from 'utils/notifications';
import qs from 'query-string';
import TableLink from 'TableLink';
import { Loading } from 'components/loading/Loading';
import { fetchAllUserCarts } from 'services/api/cart';
import Currency from 'components/Currency';
import Filter from './filters';

let filters: { [p: string]: any; page: number } = {
  page: 0,
};

const UserCarts = props => {
  const [loading, setLoading] = useState<boolean>(true);
  const [userCarts, setUserCarts] = useState<Record<string, any>>({});
  
  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  const fetchAllUserCartsFn = async () => {
    setLoading(true);
    try {
      const response = await fetchAllUserCarts({
        filter: filters,
        page: filters.page,
        limit: getPageLimit(),
      });
      setUserCarts(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    filters = {
      page:filters.page,
      ...queryStringToFilter(),
    };
    fetchAllUserCartsFn();
  }, []);
  
  const columns = useMemo(() => [
    {
      name: 'Id',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          text={row?.objectId}
          linkTo={`/user-carts/${row?.objectId}`} 
        />
      ),
    },
    {
      name: 'Status',
      selector: 'status',
    },
    {
      name: 'UserId',
      selector: 'user',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          text={row?.user?.id}
          linkTo={`/users/${row?.user?.id}`}
        />
      ),
    },
    {
      name: 'OrderId',
      selector: 'user',
      // eslint-disable-next-line react/display-name
      cell: row => row?.status === 'processed' ? (
        <TableLink
          text={row?.order?.id}
          linkTo={`/orders/${row?.order?.id}`}
        />
      ) : '-',
    },
    {
      name: 'Created At',
      selector: 'createdAt',
      // eslint-disable-next-line react/display-name
      format: row => formatDate(row.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
    },
    {
      name: 'Last Updated At',
      selector: 'updatedAt',
      // eslint-disable-next-line react/display-name
      format: row => formatDate(row.updatedAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
    },
    {
      name: 'Total',
      selector: 'total',
      // eslint-disable-next-line react/display-name
      format: (row) => <Currency amount={row.total/100} />,
    },
  ], [userCarts]);

  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };
  
  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchAllUserCartsFn();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const onFilterChanged = (updatedFilters: Record<string, any>) => {
    filters = { ...updatedFilters, page: 0 };
    updateQueryString();
    fetchAllUserCartsFn();
  };

  return (
    <div>
      {loading && <Loading />}
      <Filter 
        {...props}
        onSubmit={onFilterChanged} 
      />
      <DataTable
        title="User Carts"
        keyField="objectId"
        columns={columns}
        data={userCarts.items}
        highlightOnHover={true}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationDefaultPage={(filters.page || 0) + 1}
        paginationTotalRows={userCarts.total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </div>
  );
};

export default UserCarts;