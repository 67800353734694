import { cloudFunction } from 'services/parse';
import { DATE_FORMATS, formatDate } from 'utils';

export const fetchAdminOrders = async (params: Record<string, any>) => {
  const response = await cloudFunction('fetchAdminOrders', params);
  const data = {
    items: response.items.map(order => {
      const res = JSON.parse(JSON.stringify(order));
      return {
        ...res,
        objectId: res.objectId,
        clientName: res['user'] && res['user']['full_name'],
        driverName: res['driver'] && res['driver']['full_name'],
        subtotal: res['subtotals'],
        orderNumber: res['orderNumber'],
        taxes: res['taxes'],
        total: res['total'] / 100,
        createdAt: formatDate(res['createdAt'], DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
        deliveredAt: res['deliveredAt'] ? formatDate(res['deliveredAt'], DATE_FORMATS.MM_DD_YYYY_HH_MM_A):'-',
        refundAmount: res['refundAmount'] || 0,
        status: res['status'],
        cloudRequest: '',
      };
    }),
    total: response.total,
  };

  return data;
};

export const fetchAdminOrderDetail = async (id: string) => {
  const response = await cloudFunction('fetchAdminOrderDetail', { id });
  return JSON.parse(JSON.stringify(response));
};

export const getOrderUpdates = async (orderId: string) => {
  let response = await cloudFunction('getOrderUpdates', { orderId });

  response = response.map(record => {
    return {
      previous: record.get('previous'),
      updated: record.get('updated'),
      updatedAt: record.get('updatedAt'),
    };
  });

  return response;
};

export const fetchRefundsForOrder = (orderId: string) => {
  return cloudFunction('fetchRefundsForOrder', { orderId });
};

export const getRefundReasons = () => {
  return cloudFunction('getRefundReasons', {});
};

export const getFleetRefundReasons = async () => {
  const result = await cloudFunction('fetchCancelFleetOrderReasons ', {});
  const reasons = result.map(obj => {
    obj['key'] = obj['code'];
    obj['value'] = obj['displayValue'];
    return obj;
  });
  return reasons;
};

export const createRefund = (data: Record<string, any>) => {
  return cloudFunction('createRefund', data);
};

export const assignOrderToDriver = (data: Record<string, any>) => {
  return cloudFunction('assignOrderByAdmin', data);
};

export const unAssignOrder = (orderId: string, driverId: string) => {
  return cloudFunction('unAssignOrder', { orderId, driverId });
};

export const updateBonus = (orderId: string, bonusData: Record<string, any>) => {
  return cloudFunction('updateBonus', { orderId, bonusData });
};

export const searchUserByName = async (term: string, onlyDriver?: boolean) => {
  let response = await cloudFunction('searchUserByName', { term, onlyDriver });
  response = response.map(res => {
    return {
      full_name: `${res.full_name} (${res.email})`,        // eslint-disable-line
      id: res.objectId,
    };
  });
  return response;
};

export const fetchAvailableTolls = () => {
  return cloudFunction('fetchAvailableTolls', {});
};

export const addTollByAdmin = (orderId: string, name: string, amount: number) => {
  if (!name) throw 'Please select a toll.';

  return cloudFunction('addTollByAdmin', { orderId, name, amount });
};

export const fetchOrderTransaction = (id: string, isDeferred?: boolean) => {
  return cloudFunction('fetchOrderTransaction', { id, isDeferred });
};

export const fetchDeferredOrders =  () => {
  return cloudFunction('fetchDeferredOrders', {});
};

export const fetchDeferredOrderDetail = fetchAdminOrderDetail;

export const moveDeferredOrderToOrder = (id: string) => {
  return cloudFunction('moveDeferredOrderToOrder', { id });
};

export const addOrderNote = (orderId: string, note: string) => {
  return cloudFunction('addOrderNote', { orderId, note });
};

export const editOrderNote = (orderId: string, noteId: string, note: string) => {
  return cloudFunction('editOrderNote', { orderId, noteId, note });
};

export const deleteOrderNote = (orderId: string, noteId: string) => {
  return cloudFunction('deleteOrderNote', { orderId, noteId });
};

export const resendReceiptToNewEmail = (orderId: string, email: string) => {
  return cloudFunction('resendReceiptToNewEmail', { orderId, email });
};

export const sendOrderCommunication = (data: Record<string, any>) => {
  return cloudFunction('sendOrderCommunication', data);
};

export const markOrderFinished = (data: Record<string, any>) => {
  return cloudFunction('orderFinished', data);
};

export const markOrderAsDelivered = (data: Record<string, any>) => {
  return cloudFunction('markOrderAsDelivered', data);
};

export const fetchChildOrders = (id: string) => {
  return cloudFunction('fetchChildOrders', { id });
};

export const fetchDuplicateOrders = async (params: Record<string, any>) => {
  let response = await cloudFunction('fetchPossibleDuplicates', params);
  response= JSON.parse(JSON.stringify(response));
  return Object.keys(response).map((duplicateKey) => ({
    duplicateId: duplicateKey,
    duplicateOrders: response[duplicateKey],
  }));
};

export const removeRejectionFromOrder = (params: any) => {
  return cloudFunction('removeRejectionFromOrder', params);
};
