const tableStyles = {
  table: {
    style: {
      borderRadius: '10px',
    },
  },
  headRow: {
    style: {
      backgroundColor: '#F4511D',
      border: 'none',
    },
  },
  headCells: {
    style: {
      color: '#ffffff',
      fontSize: '16px',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      borderBottomColor: '#FFFFFF',
      borderRadius: '25px',
      outline: '1px solid #FFFFFF',
    },
  },
  pagination: {
    style: {
      border: 'none',
    },
  },
};

export { tableStyles };