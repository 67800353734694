import ActionButton from 'components/ActionButton/ActionButton';
import React, { useState } from 'react';
import { showError, showSuccess } from 'utils/notifications';
import './index.scss';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Error } from 'components/message/Error';

const validationSchema = yup.object().shape({
  email: yup.string().matches(/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/ , 'Is not in correct format').required('Email is required'),
});

type FormData = {
  email: string;
};

const ExportReportModal = props => {
  const [loading, setLoading] = useState<boolean>(false);
  const { register, errors, handleSubmit } = useForm<FormData>({ resolver: yupResolver(validationSchema) });
  const {
    heading,
    toggleModal,
    apiParams = {},
    apiFunction,
  } = props;

  const sanitizeEmails = (emailString) => { 
    return emailString.split(',').map(email => email.trim());
  };
  
  const onClickSubmit = handleSubmit(async(values) => {
    try {
      setLoading(true);
      await apiFunction({
        emails: sanitizeEmails(values?.email),
        ...apiParams,
      });
      showSuccess('Report sent successfully on mail');
      toggleModal();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  });

  const getEmailInput = () => {
    return(
      <div className={'export-report-info-group'}>
        <div className={'label'}>Email</div>
        <div className={'field'}>
          <input
            name={'email'}
            className={'form-control'}
            type={'email'}
            ref={register}
            disabled={loading}
            placeholder='Enter comma seperated emails'
          />
        </div>
      </div>
    );
  };
  return (
    <div className={'export-report-modal-main-container'}>
      <div className={'export-report-modal-container'}>
        <div className={'export-report-modal-header'}>
          <div className={'heading'}>{heading}</div>
          <div className={'close-modal'}>
            <i className={'fa fa-times'} onClick={toggleModal} />
          </div>
        </div>
        <div className={'export-report-modal-body'}>
          {getEmailInput()}
          {errors?.email?.message ? <Error message={errors?.email?.message} /> : null}
        </div>
        <div className={'export-report-modal-footer'}>
          <ActionButton
            type={'button'}
            text={'Cancel'}
            active={!loading}
            isLoading={false}
            onClick={toggleModal}
          />
          <ActionButton
            type={'submit'}
            text={'Submit'}
            active={true}
            isLoading={loading}
            onClick={onClickSubmit}
            customClass={'btn-primary'}
          />
        </div>
      </div>
    </div>
  );
};

export default ExportReportModal;