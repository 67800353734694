import { shopifyAPI } from '.';

export async function fetchShopifyOrders(filter: any) {
  const { data } = await shopifyAPI.get(`/admin/orders?${filter}`);
  return data;
}

export async function fetchShopifyOrderDetails(id: any) {
  const { data } = await shopifyAPI.get(`/admin/orders/${id}`);
  return data;
}

export async function syncShopifyOrder(id: any) {
  const { data } = await shopifyAPI.put(`/admin/sync-order/${id}`);
  return data;
}