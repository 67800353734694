import RenderInfo from 'components/RenderInfo';
import React from 'react';
import styles from './index.module.scss';

const BasicDetails = ({ details }) => {
  const {
    _id,
    shopName,
    accessToken,
    lastSyncStartTime,
    lastSyncEndTime,
    createdAt,
    updatedAt,
    email,
    phone,
    uvaAccessToken,
    isStoreUpdated,
    isPaymentMethodUpdated,
    deliveryChargeMarkupPercent,
    __v,
  } = details;

  return (
    <div className={styles['details-section']}>
      <div className={styles['info-container']}>
        <div className={styles['info']}>
          <RenderInfo heading="ID" value={_id} />
          <RenderInfo heading="Shop Name" value={shopName} />
          <RenderInfo heading="Access Token" value={accessToken} />
          <RenderInfo heading="Last Sync Start Time" value={lastSyncStartTime} type="date"/>
          <RenderInfo heading="Last Sync End Time" value={lastSyncEndTime} type="date" />
          <RenderInfo heading="Created At" value={createdAt} type="date" />
        </div>
        <div className={styles['info']}>
          <RenderInfo heading="Updated At" value={updatedAt} />
          <RenderInfo heading="Email" value={email} />
          <RenderInfo heading="Phone" value={phone} />
          <RenderInfo heading="UVA Access Token" value={uvaAccessToken} />
          <RenderInfo heading="Is Store Updated" value={isStoreUpdated} type="boolean" />
          <RenderInfo heading="Is Payment Method Updated" value={isPaymentMethodUpdated} type="boolean" />
          <RenderInfo heading="Delivery Charge Markup Percent" value={deliveryChargeMarkupPercent} />
        </div>
      </div>
    </div>
  );
};


export default BasicDetails;