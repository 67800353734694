import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

const ClientDetails = (props) => {
  const { user, deliveryAddress, itemCount, contactPhone, contactName } = props;

  const getUserDetailLink = (user) => {
    const text = _.get(user, 'full_name', _.get(user, 'email'));
    return (
      <>
        {`${text || ''} ${(text && contactName) ? `/`: ''} ${contactName || ''}`} 
        <Link to={`/users/${user?.objectId}`}>{`(${user?.objectId})`}</Link>
      </>
    );
  };

  return (
    <div className={'client-details-section'}>
      <div className={'single-section'}>
        <div className={'label'}>Client Details</div>
        <div className={'client-name'}>{getUserDetailLink(user)}</div>
        <div className={'client-address'}>{deliveryAddress}</div>
      </div>
      <div className={'single-section'}>
        <div className={'label'}>Client Phone Number</div>
        <div className={'client-phone'}>{contactPhone || user?.phone}</div>
        <div className={'label'}>Items Ordered</div>
        <div className={'item-count'}>{ itemCount }</div>
      </div>
    </div>
  );
};

export default ClientDetails;