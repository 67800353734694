import * as React from 'react';
import './message.scss';

class Success extends React.Component<any, any> {

  render () {
    return (
      <div className="alert alert-success">{this.props.message || ''}</div>
    );
  }
}
export { Success };
