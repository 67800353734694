import React, { useEffect, useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import qs from 'query-string';
import { DateRangePickerWrapper } from 'components/Date/DateRangePicker';
import moment from 'moment';
import './index.scss';

const RefundsFilter = props => {
  const [startTime, setStartTime] = useState<string | null>(moment().subtract(5, 'days').toString());
  const [endTime, setEndTime] = useState<string | null>(moment().toString());

  useEffect(() => {
    const {
      startTime,
      endTime,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });

    if (startTime) setStartTime(startTime.toString());
    if (endTime) setEndTime(endTime.toString());
  }, []);

  const resetFilter = () => {
    setStartTime(moment().subtract(1, 'week').toISOString());
    setEndTime(moment().toISOString());

    const filterData = {
      startTime: moment().subtract(1, 'week').toString(),
      endTime: moment().toString(),
    };

    props.onFilterSubmit(filterData);
  };

  const onFilterSubmit = () => {
    const filterData: Record<string, any> = {
      startTime,
      endTime,
    };

    props.onFilterSubmit(filterData);
  };

  const onDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    setStartTime(moment(startDate).toISOString());
    setEndTime(moment(endDate).toISOString());
  };

  return (
    <form className={'refunds-filter-container'}>
      <div className='input-container mb-2 mr-4'>
        <label>Date Range</label>
        <DateRangePickerWrapper
          startDate={startTime}
          endDate={endTime}
          onDatesChange={onDateSelectionChange}
        />
      </div>
      <div>
        <ActionButton
          text={'Submit'}
          isLoading={false}
          active={true}
          customClass={'btn-primary mb-2'}
          onClick={onFilterSubmit}
        />
        <ActionButton
          text={'Reset'}
          isLoading={false}
          active={true}
          customClass={'btn-primary ml-4 mb-2'}
          onClick={resetFilter}
        />
      </div>
    </form>
  );
};

export default RefundsFilter;
