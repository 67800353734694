import ActionButton from 'components/ActionButton/ActionButton';
import DishesAutoSuggest from 'components/DishesAutoSuggest';
import React, { useState } from 'react';
import { addItemsToDishCollection } from 'services/api/dishCollection';
import { searchDishes } from 'services/api/dishes';
import { showError } from 'utils/notifications';
import './index.scss';

const AddDishCollectionItemModal = props => {
  const[restaurantId, setRestaurantId] = useState<string>('');
  const [dishName, setDishName] = useState<string>('');
  const [dishId, setDishId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const {
    collectionId,
    toggleModal,
    onSuccess,
  } = props;

  const AddItem = async () => {
    setLoading(true);
    try {
      const response = await addItemsToDishCollection(collectionId,[dishId]);
      onSuccess();
      toggleModal();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={'add-dish-collection-modal-main-container'}>
      <div className={'add-dish-collection-modal-container'}>
        <div className={'add-dish-collection-modal-header'}>
          <div className={'heading'}>Add new dish to the collection</div>
          <div className={'close-modal'}>
            <i className={'fa fa-times'} onClick={toggleModal} />
          </div>
        </div>
        <form className='filter-container'>
          <div className='input-container mb-2 mr-4' style={{ width: '150px' }}>
            <label>Dish ID</label>
            <input
              className='form-control'
              placeholder={'Dish ID'}
              value={dishId}
              onChange={e => setDishId(e.target.value)}
            />
          </div>
          <div className='input-container mr-4 mb-2'>
            <label>Dish</label>
            <DishesAutoSuggest
              value={dishName}
              handleChange={setDishName}
              placeholder={'Search Dish'}
              onSuggestionSelect={setDishId}
              fetchDishes={term => searchDishes(term, { restaurantId })}
            />
          </div>
          <ActionButton
            text={'Add'}
            isLoading={loading}
            active={!!dishId}
            customClass={'btn-primary mb-2'}
            onClick={AddItem}
          />
        </form>
      </div>
    </div>
  );
};

export default AddDishCollectionItemModal;