import BackButton from 'components/BackButton';
import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from 'react';
import { showError } from 'utils';
import BasicDetails from './basicDetails';
import styles from './index.module.scss';
import { fetchShopifyMerchantDetails } from 'services/shopifyApi/merchants';
import DriverNotes from 'pages/drivers/driver-details/driver-notes';
import { fetchFleetUserByEmail } from 'services/api/users';
import '../../leads/components/lead-detail/document.scss';
import { addDriverNotes, deleteDriverNotes, editDriverNotes } from 'services/api/drivers';

const ShopifyMerchantDetails = (props) => {
  const { merchantId } = props.match.params;
  const [loading, setLoading] = useState('init');
  const [data, setData] = useState({}) as any;
  const [uvaFleetUserDetails, setUvaFleetUserDetails] = useState({}) as any;

  const getMerchantDetailFn = async () => {
    try {
      const shopifyMerchantData = await fetchShopifyMerchantDetails(merchantId);
      setData(shopifyMerchantData?.data);
      await getUVAFleetMerchantDetailFn(shopifyMerchantData?.data?.email);
    } catch (e) {
      showError(e);
    } finally {
      setLoading('');
    }
  };

  const getUVAFleetMerchantDetailFn = async (email: string) => {
    try {
      const response = await fetchFleetUserByEmail(email);
      setUvaFleetUserDetails(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading('');
    }
  };

  useEffect(() => {
    getMerchantDetailFn();
  }, []);

  const getHeader = () => (
    <div className={styles['header']}>
      <div className={styles['heading']}>Merchant Details</div>
      <div className={styles['header-buttons']}>
        <BackButton />
      </div>
    </div>
  );

  return (
    <div id={'main-container'} className={`${styles['main-container']}`}>
      {loading==='init' && <Loading />}
      {getHeader()}
      <BasicDetails details={data} />
      <div className="mt-3 p-3">
        {loading==='uva' ? <Loading type="mini"/>
          :<DriverNotes notes={uvaFleetUserDetails?.notes} id={uvaFleetUserDetails?.objectId} headerName='Merchant' addNotesAPI={addDriverNotes} 
            editNotesAPI={editDriverNotes} deleteNoteAPI={deleteDriverNotes}/>}
      </div>
    </div>
  );
};

export default ShopifyMerchantDetails;