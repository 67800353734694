import * as React from 'react';
import { Redirect, Route } from 'react-router';
import { connect } from 'react-redux';
import { isUserAuthorized } from 'utils';

const mapStateToProps = (state: any) => ({
  isAuthenticated: !!state.auth.loggedInUser,
  userRoles: state.auth.loggedInUser ? state.auth.loggedInUser.get('roles') : [],
});

const PrivateRoute: React.ComponentType<any> = ({
  component: Component,
  isAuthenticated,
  userRoles,
  ...rest
}) => {

  if (isAuthenticated && !isUserAuthorized(userRoles, rest.roles)) {
    return <h5>You do not have access to this page</h5>;
  }

  return (
    <Route
      {...rest}
      render={(props: any) => {
        return isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
const PrivateRouteWrapper = connect(mapStateToProps)(PrivateRoute);

export { PrivateRouteWrapper as PrivateRoute };
