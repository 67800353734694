import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { DATE_FORMATS, formatDate, getPageLimit, updatePageLimit } from 'utils';
import qs from 'query-string';
import { Loading } from 'components/loading/Loading';
import { showError } from 'utils/notifications';
import { tableStyles } from 'css/tableStyles';
import { fetchRefunds } from 'services/api/common';
import moment from 'moment';
import RefundsFilter from './filter';
import './index.scss';
import ExportCSVButton from 'components/ExportCSVButton';

let filters: Record<string, any> = {
  page: 0,
  startTime: moment().subtract(5, 'days').toString(),
  endTime: moment().toString(),
};

const columns = [
  {
    name: 'Refund ID',
    selector: 'objectId',
    sortable: false,
    width: '120px',
  },
  {
    name: 'Refunded At',
    selector: 'refundedAt',
    sortable: false,
    width: '160px',
  },
  {
    name: 'Refunded By',
    selector: 'refundedBy.id',
    sortable: false,
    width: '120px',
  },
  {
    name: 'Order ID',
    selector: 'order.objectId',
    sortable: false,
    width: '120px',
  },
  {
    name: 'Food',
    selector: 'food',
    sortable: false,
    format: row => `$${row.food}`,
  },
  {
    name: 'Delivery',
    selector: 'delivery',
    sortable: false,
    format: row => `$${row.delivery}`,
  },
  {
    name: 'Food Taxes',
    selector: 'foodTaxes',
    sortable: false,
    format: row => `$${row.foodTaxes}`,
  },
  {
    name: 'Delivery Taxes',
    selector: 'deliveryTaxes',
    sortable: false,
    format: row => `$${row.deliveryTaxes}`,
  },
  {
    name: 'Discount Amount',
    selector: 'discountAmount',
    sortable: false,
    format: row => `$${row.discountAmount}`,
  },
  {
    name: 'Total Amount',
    selector: 'totalAmount',
    sortable: false,
    format: row => `$${row.totalAmount}`,
  },
  {
    name: 'Reason',
    selector: 'reason',
    sortable: false,
    width: '180px',
  },
  {
    name: 'Type',
    selector: 'type',
    sortable: false,
  },
  {
    name: 'Description',
    selector: 'description',
    sortable: false,
    width: '250px',
    // eslint-disable-next-line react/display-name
    cell: row =>
      row.description ? <div className={'refund-description'}>{row.description}</div> : <div />,
  },
];

const columnsForCSV = [
  {
    value: 'objectId',
    label: 'Refund ID',
  },
  {
    value: 'refundedAt',
    label: 'Refunded At',
  },
  {
    value: 'refundedBy.id',
    label: 'Refunded By',
  },
  {
    value: 'order.objectId',
    label: 'Order ID',
  },
  {
    value: 'food',
    label: 'Food',
  },
  {
    value: 'delivery',
    label: 'Delivery',
  },
  {
    value: 'foodTaxes',
    label: 'Food Taxes',
  },
  {
    value: 'deliveryTaxes',
    label: 'Delivery Taxes',
  },
  {
    value: 'discountAmount',
    label: 'Discount Amount',
  },
  {
    value: 'totalAmount',
    label: 'Total Amount',
  },
  {
    value: 'reason',
    label: 'Reason',
  },
  {
    value: 'type',
    label: 'Type',
  },
  {
    value: 'description',
    label: 'Description',
  },
];

const Refunds = props => {
  const [refunds, setRefunds] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);

  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  const fetchRefundsFn = async () => {
    setLoading(true);
    try {
      const response = await fetchRefunds({
        limit: getPageLimit(),
        ...filters,
      });
      setRefunds(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    filters = {
      ...filters,
      ...queryStringToFilter(),
    };
    fetchRefundsFn();
  }, []);

  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onFilterChanged = (udpatedfilters: Record<string, any>) => {
    filters = { ...udpatedfilters, page: 0 };
    updateQueryString();
    fetchRefundsFn();
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchRefundsFn();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const exportCSVButton =
    <ExportCSVButton
      data={refunds.items}
      columnsForCSV={columnsForCSV}
      fileName={'Refunds Report'}
    />;

  return (
    <div>
      {loading && <Loading />}
      <RefundsFilter {...props} onFilterSubmit={onFilterChanged} />
      <DataTable
        title="Refunds"
        columns={columns}
        data={refunds.items}
        highlightOnHover={true}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationDefaultPage={(filters.page || 0) + 1}
        paginationTotalRows={refunds.total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        actions={[exportCSVButton]}
      />
    </div>
  );
};

export { Refunds };