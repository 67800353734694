import React, { useEffect, useState } from 'react';
import { showError } from 'utils/notifications';
import { Loading } from 'components/loading/Loading';
import { fetchDisputeDetail } from 'services/api/disputes';
import BackButton from 'components/BackButton';
import './index.scss';
import { DATE_FORMATS, formatDate, stringifyAndParseObj } from 'utils';
import TableLink from 'TableLink';
import Notes from './notes';
import CopyToClipBoard from 'components/CopyToClipBoard';

const DisputeDetails = props => {
  const { id } = props.match.params;

  const [details, setDetails] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);

  const fetchDisputeDetailsFn = async () => {
    setLoading(true);
    try {
      let response = await fetchDisputeDetail({
        id,
      });
      response = stringifyAndParseObj(response);
      setDetails(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDisputeDetailsFn();
  }, []);
  
  const getHeader = () => (
    <div className={'header'}>
      <div>Dispute Details</div>
      <BackButton />
    </div>
  );

  const getDetail = ({ heading, value }) => {
    return (
      <div className={'dish-details-group mt-2'}>
        <div className={'label'}>{heading}</div>
        <div className={'detail'}>{value}</div>
      </div>
    );
  };
  
  const getDisputeDetails = () => (
    <div className={'dispute-basic-details-section'}>
      <div>
        {getDetail({ heading: 'Object Id', value: details.objectId })}
        {getDetail({ heading: 'Status', value: details.status })}
        {getDetail({ heading: 'Stripe Dispute Id', value: details?.stripeDisputeId })}
        {getDetail({ heading: 'Amount', value: details.amount })}
        {getDetail({ heading: 'Currency', value: details.currency })}
        <div className={'dish-details-group mt-2'}>
          <div className={'label'}>Stripe Dashboard</div>
          <div className='d-flex align-items-center'>
            <TableLink
              linkTo={details?.stripeDashboardLink}
              text={details?.stripeDashboardLink}
              customClass={'detail'}
              openInNewTab
            />
            <CopyToClipBoard
              copyText={details?.stripeDashboardLink}
            />
          </div>
        </div>
      </div>
      <div>
        <div className={'dish-details-group mt-2'}>
          <div className={'label'}>Order</div>
          <TableLink
            linkTo={`/orders/${details?.order?.objectId}`}
            text={details?.order?.objectId}
            customClass={'detail'}
            openInNewTab
          />
        </div>
        {getDetail({ heading: 'Created At', value: formatDate(details.disputeCreatedAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_SS_A) })}
        {getDetail({ heading: 'Due By', value: formatDate(details.responseDueBy, DATE_FORMATS.MM_DD_YYYY_HH_MM_SS_A) })}
      </div>
    </div>
  );

  

  return (
    <div className='dispute-details-main-container'>
      {getHeader()}
      {loading && <Loading />}
      <div className={'dispute-details-section'}>
        {getDisputeDetails()}
        <Notes notes={details?.notes} fetchDisputeDetailsFn={fetchDisputeDetailsFn} disputeId={id} />
      </div>
    </div>
  );
};

export default DisputeDetails;