import React, { useEffect, useState } from 'react';
import { assignAgent, fetchAgentList } from 'services';
import _ from 'lodash';
import { IAgentPickerProps } from 'interfaces/agentPicker';
import { showSuccess } from 'utils/notifications';

const AgentPicker: React.FC<IAgentPickerProps> = props => {
  const [agents, setAgents] = useState([{ objectId: '', full_name: '' }]);      // eslint-disable-line
  const [selectedAgent, setSelectedAgent] = useState('');
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let agentsArr = await fetchAgentList();
        agentsArr = agentsArr.map(agent => _.pick(agent, 'objectId', 'full_name'));
        setAgents(agentsArr);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const getAgentList = () => agents.map((agent, i) => {
    return <option key={i} value={agent.objectId}>{agent.full_name}</option>;
  });

  const assignAgentFn = async (agentId: string) => {
    setSelectedAgent(agentId);
    setLoading(true);
    try {
      await assignAgent(props.leadId, agentId);
      showSuccess('Agent assigned successfully');
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='ml-auto mr-4 row picker-container'>
      <span>Assignee</span>
      <select
        className='form-control'
        value={selectedAgent || props.currentAgentId}
        disabled={loading}
        onChange={e => assignAgentFn(e.target.value)}
      >
        <option key='defaultValue' value='undefined'>Select an agent</option>
        {getAgentList()}
      </select>  
    </div>
  );
};

export default AgentPicker;