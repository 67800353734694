import React, { useEffect, useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import qs from 'query-string';
import './index.scss';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Error } from 'components/message/Error';
import NameFilterAutosuggest from 'components/NameFilterAutoSuggest';
import { searchUserByName } from 'services/api/order';
import { phoneRegEx } from 'utils';

const validationSchema = yup.object().shape({
  driverEmail: yup.string().email(),
  driverPhone: yup.string().matches(phoneRegEx, 'Phone Number must be valid'),
});

type FormData = {
  driverEmail: string;
  driverPhone: string;
}

const DriverFilter = props => {
  const { errors, handleSubmit, register, reset } = useForm<FormData>({ resolver: yupResolver(validationSchema) });
  const [driverId, setDriverId] = useState<string>('');
  const [driverEmail, setDriverEmail] = useState<string>('');
  const [driverPhone, setDriverPhone] = useState<string>('');

  useEffect(() => {
    const {
      driverId,
      email,
      phone,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
    if (driverId) setDriverId(driverId.toString());
    if (email) setDriverEmail(email.toString());
    if (phone) setDriverPhone(phone.toString());
  }, []);

  const resetFilter = () => {
    reset();
    setDriverId('');
    setDriverEmail('');
    setDriverPhone('');
    const filterData = {
      driverEmail: '',
      driverPhone: '',
      driverId: '',
    };
    props.onFilterSubmit(filterData);
  };

  const onFilterSubmit = handleSubmit((values) => {
    const { driverEmail, driverPhone } = values;
    const filterData: Record<string, any> = {
      phone: driverPhone,
      email: driverEmail,
      driverId: driverId,
    };
    props.onFilterSubmit(filterData);
  });

  return (
    <form className='filter-container'>
      <div className='input-container mb-2 mr-4'>
        <label>Driver ID</label>
        <input
          className='form-control'
          placeholder={'Driver ID'}
          value={driverId}
          onChange={e => setDriverId(e.target.value)}
        />
      </div>
      <div className='input-container mb-2 mr-4'>
        <label>Driver Name</label>
        <NameFilterAutosuggest
          entityId={driverId}
          placeholder={'Driver Name'}
          onSuggestionSelected={setDriverId}
          fetchSuggestions={event => searchUserByName(event, true)}
        />
      </div>
      <div className='input-container mb-2 mr-4'>
        <label>Driver Email</label>
        <input
          name={'driverEmail'}
          ref={register}
          className='form-control'
          placeholder={'Driver Email'}
          defaultValue={driverEmail}
        />
        {errors.driverEmail && <Error message={errors.driverEmail.message} />}
      </div>
      <div className='input-container mb-2 mr-4'>
        <label>Driver Phone</label>
        <input
          name={'driverPhone'}
          ref={register}
          className='form-control'
          placeholder={'Driver Phone'}
          defaultValue={driverPhone}
        />
        {errors.driverPhone && <Error message={errors.driverPhone.message} />}
      </div>
      <ActionButton
        text={'Submit'}
        isLoading={false}
        active={true}
        customClass={'btn-primary mb-2'}
        onClick={onFilterSubmit}
      />
      <ActionButton
        text={'Reset'}
        isLoading={false}
        active={true}
        customClass={'btn-primary ml-4 mb-2'}
        onClick={resetFilter}
      />
    </form>
  );
};

export default DriverFilter;
