import React, { useState } from 'react';
import { ILead } from 'interfaces';
import { unApproveDocument } from 'services/api';
import './reject-document.scss';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import { showError } from 'utils/notifications';

const RejectDocument = ({ doc, show, onHide }: { doc: ILead.ILeadDocument; show: boolean; onHide: Function }) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');
  const [isApproved, setIsApproved] = useState<boolean>(doc.isApproved);

  const unApproveDoc = async () => {
    try {
      setLoading(true);
      const approved: boolean = await unApproveDocument(doc.objectId, reason);
      setIsApproved(approved);
      closeModal({ approved, reason });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  const closeModal = (updated?) => {
    setReason('');
    onHide({ data: updated });
  };
  return <>
    <div className={`reject-modal-container justify-content-center align-items-center ${show && 'show'}`}>
      <div className="modal-box p-4">
        <div className="close-button" onClick={() => closeModal()}>
          <div className="fa fa-times"></div>
        </div>
        <div className="row">
          <div className="col-12">
            <label className="font-weight-bolder">Rejection Reason</label>
          </div>
          <div className="col-12">
            <textarea className="form-control" value={reason} onChange={event => setReason(event.target.value)}></textarea>
          </div>
          <div className="col-12 mt-2">
            <ActionButton customClass={'btn-primary'} text={'Submit'} active={true} onClick={unApproveDoc} isLoading={loading}></ActionButton>
          </div>
        </div>
      </div>
    </div>
  </>;
};

export { RejectDocument };
