import { cloudFunction } from 'services/parse';
import { DATE_FORMATS, formatDate } from 'utils';

export const fetchScrappers = async () => {
  let response = await cloudFunction('fetchScrappers', {});
  response = response.map(row => ({
    ...row,
    active: row.active.toString(),
    hasError: row.hasError?.toString(),
    lastScrappingTime: row.scrappingStats?.lastScrappingTime ? (
      formatDate(row.scrappingStats.lastScrappingTime, DATE_FORMATS.MM_DD_YYYY_HH_MM_A)) : '-',
  }));
  return response;
};

export const fetchScrapperDetails = async (id: string) => {
  const response = await cloudFunction('fetchScrapperDetails', { id });
  return response;
};

export const toggleScrapperActiveStatus = async (id: string) => {
  return cloudFunction('toggleScrapperActiveStatus', { id });
};

export const toggleScrapperFilterByTags = async (id: string) => {
  return cloudFunction('toggleScrapperFilterByTags', { id });
};

export const createScrapper = (data: Record<string, any>) => {
  return cloudFunction('createScrapper', data);
};

export const updateLastScrappingTime = (id: string, lastScrappingTime: string) => {
  return cloudFunction('updateLastScrappingTime', { id, lastScrappingTime });
};

export const updateScrapper = (data: Record<string, any>) => {
  return cloudFunction('updateScrapper', data);
};

export const getScrapperServerInstances = () => {
  return cloudFunction('getScrapperServerInstances', null);
};

export const setScrapperServerInstance = (data: Record<string, any>) => {
  return cloudFunction('setScrapperServerInstance', data);
};

export const removeScrapperServerInstance = (data: Record<string, any>) => {
  return cloudFunction('removeScrapperServerInstance', data);
};
