import React, { useEffect, useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import qs from 'query-string';
import RestaurantAutoSuggest from 'components/RestaurantAutoSuggest';
import VerticalTypePicker from 'components/VerticalTypePicker';
import Dropdown from 'components/Dropdown';
import './restaurant-details/index.scss';

const ACTIVE_DROPDOWN_OPTIONS = [{ value: 'true', label: 'Active' }, { value: 'false', label: 'Inactive' }];
const ONLINE_DROPDOWN_OPTIONS = [{ value: 'true', label: 'Online' }, { value: 'false', label: 'Offline' }];

const RestaurantsFilter = props => {
  const [restaurantId, setRestaurantId] = useState<string>('');
  const [active, setActive] = useState<string>('');
  const [online, setOnline] = useState<string>('');
  const [type, setType] = useState<string>('');

  useEffect(() => {
    const {
      restaurantId,
      isOnline,
      active,
      type,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });

    if (restaurantId) setRestaurantId(restaurantId.toString());
    if (type) setType(type.toString());
    if (isOnline !== undefined) setOnline(`${isOnline}`);
    if (active !== undefined) setActive(`${active}`);

  }, []);

  const resetFilter = () => {
    setRestaurantId('');
    setOnline('');
    setActive('');
    setType('');

    const filterData = {};
    props.onFilterSubmit(filterData);
  };

  const onFilterSubmit = () => {
    const filterData: Record<string, any> = {
      restaurantId,
      isOnline: online ? JSON.parse(online) : '',
      active: active ? JSON.parse(active) : '',
      type,
    };
    props.onFilterSubmit(filterData);
  };

  return (
    <form className='filter-container'>
      <div className='input-container mr-4 mb-2'>
        <label>Restaurant ID</label>
        <input
          value={restaurantId}
          onChange={e => setRestaurantId(e.target.value)}
          className={'form-control'}
        />
      </div>
      <div className='input-container mr-4 mb-2'>
        <label>Restaurant</label>
        <RestaurantAutoSuggest
          placeholder={'Search Restaurant'}
          onSuggestionSelect={suggestion => setRestaurantId(suggestion.id)}
        />
      </div>
      <div className='input-container mr-4 mb-2'>
        <label>Vertical Type</label>
        <VerticalTypePicker
          verticalType={type}
          disabled={false}
          isFilter={true}
          onChange={(e) => setType(e.target.value)}
        />
      </div>
      <div className='input-container mr-4 mb-2'>
        <label>Active</label>
        <Dropdown name="Active" value={active} onChange={setActive} options={ACTIVE_DROPDOWN_OPTIONS} isFilter={true} />
      </div>
      <div className='input-container mr-4 mb-2'>
        <label>Online</label>
        <Dropdown name="Online" value={online} onChange={setOnline} options={ONLINE_DROPDOWN_OPTIONS} isFilter={true} />
      </div>
      <div className='button-container'>
        <ActionButton
          text={'Submit'}
          isLoading={false}
          active={true}
          customClass={'btn-primary mb-2'}
          onClick={onFilterSubmit}
        />
        <ActionButton
          text={'Reset'}
          isLoading={false}
          active={true}
          customClass={'btn-primary ml-4 mb-2'}
          onClick={resetFilter}
        />
      </div>
    </form>
  );
};

export default RestaurantsFilter;
