import React from 'react';
import './single-order.scss';

interface IGetDriverDetailsTemplate {
  id: string; 
  details: any;
  showRemoveRejection?: boolean; 
  orderDetails?: any; 
  removeRejectionFromOrderFn?: Function;
}
const GetDriverDetailsTemplate = ({ id, details, showRemoveRejection = false, removeRejectionFromOrderFn }: IGetDriverDetailsTemplate) => {

  return (
    <div className='driver'>
      <div>
        {showRemoveRejection && 
        <div 
          onClick={() => {
            removeRejectionFromOrderFn && removeRejectionFromOrderFn(id);
          }} 
          className='remove-icon'
        >
          <i className={'fa fa-times'} />
        </div>}
        <div className='car-icon'><i className={'fa fa-car'} /></div>
      </div>
      <div className='detail'>
        <div><strong>Id:</strong> {' '} 
          <a
            href={`/drivers/${id}`}
            className={'_id'}
          >
            {id}
          </a>
        </div>
        {details?.map((detail) => {
          return <div><strong>{detail?.key}</strong>: {detail?.value}</div>;
        })}
      </div>
    </div>
  );
};

export default GetDriverDetailsTemplate;