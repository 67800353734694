import ActionButton from 'components/ActionButton/ActionButton';
import RenderInfo from 'components/RenderInfo';
import React from 'react';
import { Modal } from 'react-bootstrap';
import './styles.scss';

const ImportDishSuccessModal = (props: {show: boolean; onClose: Function; restaurantId: string; data: any; history: any}) => {
  const { data, onClose, restaurantId, show } = props;
  const showRestaurantMenu = () => {
    props.history.push(`/restaurants/${restaurantId}?selectedTab=1`);
  };
  return (
    <Modal show={show} onHide={onClose} animation centered>
      <Modal.Header>
        <Modal.Title>Dishes Imported Successfully</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Dishes were imported for restaurant with Id: <strong>{restaurantId}</strong></p>
        <RenderInfo heading='Total Records Created' value={data.totalRecordsCreated}/>
        <RenderInfo heading='Total Records Updated' value={data.totalRecordsUpdated}/>
      </Modal.Body>

      <Modal.Footer>
        <ActionButton text={'Close'} active={true} customClass='cancel' onClick={props.onClose} isLoading={false}></ActionButton>
        <ActionButton text={'See Restaurant Menu'} active={true} onClick={showRestaurantMenu} isLoading={false} customClass="btn-primary"></ActionButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportDishSuccessModal;