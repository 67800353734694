import React,{ useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import styles from './index.module.scss';
import DishesAutoSuggest from 'components/DishesAutoSuggest';
import _ from 'lodash';
import Tag from 'components/Tag';
import { searchDishes } from 'services/api/dishes';

const DishEligibility = props => {
  const { detailsEditable,discount,setDiscount,loading }=props;
  const [dishName, setDishName] = useState<string>('');
  const [dishId, setDishId] = useState<string>('');
  const dishes = discount?.dishEligibility || [];
  
  const handleRemove = (index: string) => {
    dishes.splice(index, 1);
    setDiscount({ ...discount, dishEligibility: dishes });
  };

  const handleAddDish = () => {
    if (!_.isEmpty(dishId)) {
      dishes.push(dishId);
      setDiscount({ ...discount, dishEligibility: dishes });
    }
  };

  const getDishEligibilitySection=()=>{
    if(detailsEditable){
      return (
        <div className={styles['input-container']}>
          <div>
            <div className={styles['label']}>Dish Id</div>
            <input
              className='form-control'
              placeholder={'Dish ID'}
              value={dishId}
              onChange={e => setDishId(e.target.value)}
            />
          </div>
          <div className=''>
            <div className={styles['label']}>Dish</div>
            <DishesAutoSuggest
              value={dishName}
              handleChange={setDishName}
              placeholder={'Search Dish'}
              onSuggestionSelect={setDishId}
              fetchDishes={term => searchDishes(term, {})}
            />
          </div>
          <div className={styles['action-button']}>
            <ActionButton
              active={true}
              isLoading={loading}
              onClick={handleAddDish}
              text={'Add Dish'}
              customClass={'form-control'}
            />
          </div>
        </div>
      ); 
    }else{
      return null;
    }
  };

  const getDishesTagSection=()=>{
    if(dishes?.length > 0){
      return (
        <div className={styles['tag-section']}>
          <div className={styles['roles-card']}>
            {dishes.map((item, i) => <Tag key={i} tagName={item} onRemove={() => handleRemove(i)} />)}
          </div>
        </div>
      );
    }else{
      return <div className={`${styles['label']} pl-2`}>No Eligible Dishes</div>;
    } 
  };

  return (
    <div className={styles['group-section']}>
      <h4 className=' font-weight-bolder'>Dish Eligibility</h4>
      {getDishEligibilitySection()}
      {getDishesTagSection()}
    </div >
  );
};

export default DishEligibility;