import React from 'react';

const TableLink = props => {
  const {
    linkTo,
    text,
    openInNewTab,
    customClass,
  } = props;

  return (
    <a
      className={`id ${customClass}`}
      href={linkTo}
      target={openInNewTab ? '_blank' : '_self'}
    >
      {text}
    </a>
  );
};

export default TableLink;