import ActionButton from 'components/ActionButton/ActionButton';
import ExportCSVButton from 'components/ExportCSVButton';
import { Loading } from 'components/loading/Loading';
import EmptySectionMessage from 'components/message/EmptySectionMessage';
import { tableStyles } from 'css/tableStyles';
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { getDriversReport } from 'services';
import { getEmailArr } from 'utils';
import { showError } from 'utils/notifications';
import './index.scss';

const columns = [
  {
    name: 'First Name',
    selector: 'firstName',
    sortable: false,
  },
  {
    name: 'Last Name',
    selector: 'lastName',
    sortable: false,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: false,
  },
  {
    name: 'Phone',
    selector: 'phone',
    sortable: false,
  },
  {
    name: 'SSN',
    selector: 'ssn',
    sortable: false,
  },
];

const columnsForCSV = [
  {
    value: 'firstName',
    label: 'First Name',
  },
  {
    value: 'lastName',
    label: 'Last Name',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'phone',
    label: 'Phone',
  },
  {
    value: 'ssn',
    label: 'SSN',
  },
];

const DriversData = () => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [emails, setEmails] = useState<string>('');
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [apiCalled, setAPICalled] = useState<boolean>(false);
  
  const fetchDriversData = async () => {
    if (!apiCalled) setAPICalled(true);
    setLoading(true);
    try {
      const response = await getDriversReport({
        emails: getEmailArr(emails),
        sendEmail,
      });
      setData(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const getDataTable = () => {
    if (loading) return <Loading />;

    if (data.length) {
      const exportCSVButton = <ExportCSVButton data={data} columnsForCSV={columnsForCSV} fileName={'Drivers Data'} />;
  
      return (
        <DataTable
          title="Drivers"
          keyField="objectId"
          columns={columns}
          data={data}
          customStyles={tableStyles}
          pagination={false}
          striped={true}
          actions={[exportCSVButton]}
        />
      );
    } else {
      return apiCalled ? <EmptySectionMessage message={'No records found'} /> : null;
    }
  };
  
  const getEmailInputSection = () => (
    <div>
      <div className={'d-flex align-items-center'}>
        <div className='input-container mr-4'>
          <label>User Email</label>
          <input
            name={'userEmail'}
            type={'email'}
            className={'form-control'}
            placeholder={'Emails'}
            disabled={!sendEmail}
            value={emails}
            onChange={e => setEmails && setEmails(e.target.value)}
          />
          <div className='helper'>Hint: separate multiple emails using commas</div>
        </div>
        <div className='input-container mr-4'>
          <div className={'send-email'}>
            <div className='label'>Send Email</div>
            <input
              type='checkbox'
              checked={sendEmail}
              onChange={e => setSendEmail(e.target.checked)}
            />
          </div>
        </div>
      </div>
      <div>
        <ActionButton
          type={'button'}
          text={'Submit'}
          isLoading={false}
          active={!loading}
          customClass={'btn-primary my-2'}
          onClick={fetchDriversData}
        />
      </div>
    </div>
  );
  
  return (
    <div>
      {getEmailInputSection()}
      {getDataTable()}
    </div>
  );
};

export default DriversData;