import React, { useState } from 'react';
import ReportsFilter from 'components/ReportsFilter';
import { Loading } from 'components/loading/Loading';
import DataTable from 'react-data-table-component';
import { getOrderReviewReport } from 'services/api/reports';
import moment from 'moment';
import { showError } from 'utils/notifications';
import ExportCSVButton from 'components/ExportCSVButton';
import DataTableFilter from 'components/DataTableFIlter';
import { tableStyles } from 'css/tableStyles';
import EmptySectionMessage from 'components/message/EmptySectionMessage';

const columns = [
  {
    name: 'Order ID',
    selector: 'orderId',
    sortable: false,
    width: '150px',
  },
  {
    name: 'User',
    selector: 'user',
    sortable: false,
  },
  {
    name: 'Date Time',
    selector: 'createdAt',
    sortable: false,
  },
  {
    name: 'UVA Review',
    selector: 'uvaReview',
    sortable: false,
    wrap: true,
  },
  {
    name: 'Service Review',
    selector: 'serviceReview',
    sortable: false,
    wrap: true,
  },
  {
    name: 'Restaurant Review',
    selector: 'restaurantReview',
    sortable: false,
    wrap: true,
  },
  {
    name: 'Driver Review',
    selector: 'driverReview',
    sortable: false,
    wrap: true,
  },
];

const columnsForCSV = [
  {
    value: 'orderId',
    label: 'Order ID',
  },
  {
    value: 'user',
    label: 'User',
  },
  {
    value: 'createdAt',
    label: 'Date Time',
  },
  {
    value: 'uvaReview',
    label: 'Uva Review',
  },
  {
    value: 'serviceReview',
    label: 'Service Review',
  },
  {
    value: 'restaurantReview',
    label: 'Restaurant Review',
  },
  {
    value: 'driverReview',
    label: 'Driver Review',
  },
];

const ReviewReport = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null | string>(moment().subtract(1, 'days').toDate());
  const [endDate, setEndDate] = useState<Date | null | string>(moment().toDate());
  const [emails, setEmails] = useState<string>('');
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [data, setData] = useState<Array<Record<string, any>>>([]);
  const [filterText, setFilterText] = React.useState('');
  const [apiCalled, setAPICalled] = useState<boolean>(false);

  const getEmailArr = () => {
    if (sendEmail && emails) {
      const emailArr = emails.split(',').map(email => email.trim());
      return emailArr;
    }
    return [];
  };

  const fetchOrderReviewReport = async () => {
    if (!apiCalled) setAPICalled(true);
    const emails: string[] = getEmailArr();

    setLoading(true);
    try {
      const response = await getOrderReviewReport(startDate, endDate, emails, sendEmail);
      setData(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const getDataTable = () => {
    if (loading) return <Loading />;

    if (data.length) {
      const exportCSVButton = <ExportCSVButton data={data} columnsForCSV={columnsForCSV} fileName={'Review Report'} />;
      const dataFilterInput = <DataTableFilter filterText={filterText} setFilterText={setFilterText} placeholder={'Filter by User'} />;
      const filteredData = data.filter(item => item.user && item.user.toLowerCase().includes(filterText.toLowerCase()));

      return (
        <DataTable
          title="Reports"
          keyField="objectId"
          columns={columns}
          data={filteredData}
          customStyles={tableStyles}
          pagination={false}
          striped={true}
          actions={[dataFilterInput, exportCSVButton]}
        />
      );
    } else {
      return apiCalled ? <EmptySectionMessage message={'No records found'} /> : null;
    }
  };

  return (
    <div className='report-container'>
      <ReportsFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        emails={emails}
        setEmails={setEmails}
        sendEmail={sendEmail}
        setSendEmail={setSendEmail}
        handleSubmit={fetchOrderReviewReport}
        showEmailInput={true}
      />
      {getDataTable()}
    </div>
  );
};


export default ReviewReport;
