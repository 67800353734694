import React, { useEffect, useState } from 'react';
import { fetchUsersForAdminDashboard } from 'services/api/users';
import DataTable from 'react-data-table-component';
import { getPageLimit, updatePageLimit } from 'utils';
import qs from 'query-string';
import { Loading } from 'components/loading/Loading';
import UsersFilter from './users-filter';
import { showError } from 'utils/notifications';
import { tableStyles } from 'css/tableStyles';
import TableLink from 'TableLink';

let filters: { page: number; sort?: Record<string, any> } = {
  page: 0,
};

let sort = {};

const Users = props => {
  const [users, setUsers] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: 'User ID',
      selector: 'objectId',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/users/${row.objectId}`}
          text={row.objectId}
          openInNewTab={false}
        />
      ),
    },
    {
      name: 'User Name',
      selector: 'name',
      sortable: false,
    },
    {
      name: 'Created At',
      selector: 'createdAt',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: false,
    },
    {
      name: 'Phone',
      selector: 'phone',
      sortable: false,
    },
  ];

  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  const fetchUsersForAdminDashboardFn = async () => {
    setLoading(true);
    try {
      const response = await fetchUsersForAdminDashboard({
        limit: getPageLimit(),
        filter: filters,
        page: filters.page,
        sort,
      });
      setUsers(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    filters = {
      ...filters,
      ...queryStringToFilter(),
    };
    fetchUsersForAdminDashboardFn();
  }, []);

  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onFilterChanged = (udpatedfilters: Record<string, any>) => {
    filters = { ...udpatedfilters, page: 0 };
    updateQueryString();
    fetchUsersForAdminDashboardFn();
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchUsersForAdminDashboardFn();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const onSort = (column: Record<string, any>, sortDirection: string) => {
    sort = {
      ...sort,
      [column.selector]: sortDirection,
    };
    fetchUsersForAdminDashboardFn();
  };

  return (
    <div>
      {loading && <Loading />}
      <UsersFilter location={props.location} onFilterSubmit={onFilterChanged} />
      <DataTable
        title="Users"
        keyField="objectId"
        columns={columns}
        data={users.items}
        highlightOnHover={true}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationDefaultPage={(filters.page || 0) + 1}
        paginationTotalRows={users.total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onSort={onSort}
      />
    </div>
  );
};

export { Users };