import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import ActionButton from 'components/ActionButton/ActionButton';
import { createRestaurant } from 'services/api/restaurant';
import { showError, showSuccess } from 'utils';
import AddAddressModal from 'components/Address/AddAddressModal/index';

const validationSchema = yup.object().shape({
  name: yup.string().required('Please enter name'),
  email: yup.string().email('Please enter a valid email').required('Please enter email'),
  phone: yup.string().required('Please enter phone'),
  aboutUs: yup.string().required('Please enter about us'),
  addressObj: yup.mixed(),
});

type FormData = {
  name: string;
  email: string;
  phone: string;
  addressObj: any;
  aboutUs: string;
};

const CreateRestaurant = () => {
  const [addressObj, setAddressObj] = useState({}) as any;
  const [loading, setLoading] = useState(false);
  const [showSelectLocation, setShowSelectLocation] = useState(false);

  const { register, handleSubmit, errors, setValue } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    register('addressObg');
  }, []);

  const onAddressSelect = (address) => {
    const newAddressObj = {
      address: address?.secondAddress + ', ' + address?.address,
      coords: {
        latitude: address?.coords?.lat,
        longitude: address?.coords?.lng,
      },
      town: address?.town,
    };
    setValue('addressObj', newAddressObj);
    setAddressObj(newAddressObj);
    setShowSelectLocation(false);
  };

  const saveStripeProductFn = handleSubmit(async (values) => {
    setLoading(true);
    try {
      await createRestaurant({
        name: values?.name,
        email: values?.email,
        phone: values?.phone,
        aboutUs: values?.aboutUs,
        addressObj,
      });
      setValue('name', '');
      setValue('email', '');
      setValue('phone', '');
      setValue('aboutUs', '');
      setValue('addressObj', {});
      setAddressObj({});
      showSuccess('Request submitted successfully.');
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  });

  const getError = (err) => {
    if (!err) return null;
    return <div className={styles['error']}>{err}</div>;
  };

  const getName = () => {
    return (
      <div className={styles['name']}>
        <div className={styles['label']}>Name*</div>
        <input
          name={'name'}
          ref={register}
          className={'form-control'}
          disabled={loading}
        />
        {getError(errors?.name?.message)}
      </div>
    );
  };

  const getEmail = () => {
    return (
      <div className={`${styles['name']}`}>
        <div className={styles['label']}>Email*</div>
        <input
          name={'email'}
          ref={register}
          className={'form-control'}
          disabled={loading}
        />
        {getError(errors?.email?.message)}
      </div>
    );
  };

  const getPhone = () => {
    return (
      <div className={styles['name']}>
        <div className={styles['label']}>Phone*</div>
        <input
          name={'phone'}
          ref={register}
          className={'form-control'}
          disabled={loading}
        />
        {getError(errors?.phone?.message)}
      </div>
    );
  };

  const getAboutUs = () => {
    return (
      <div className={`${styles['comment']} mt-3`}>
        <div className={styles['label']}>About Us</div>
        <textarea
          name={'aboutUs'}
          ref={register}
          className={'form-control'}
          disabled={loading}
        />
        {getError(errors?.aboutUs?.message)}
      </div>
    );
  };

  const getSelectedAddress = () => {
    return (
      <div className="mt-3">
        <p className="text-left mt-4">Location</p>
        {addressObj?.address ? (
          <div className="text-left d-flex">
            {addressObj?.address}
            <div
              className={styles['change-text']}
              onClick={() => {
                setShowSelectLocation(true);
              }}
            >
              &nbsp;Change?
            </div>
          </div>
        ) : (
          <ActionButton
            customClass="orange"
            text="Select location"
            onClick={() => {
              setShowSelectLocation(true);
            }}
            isLoading={false}
            active={true}
          />
        )}
        {getError(errors?.addressObj?.message)}
      </div>
    );
  };

  return (
    <div>
      <AddAddressModal
        modalOpen={showSelectLocation}
        onSuccess={onAddressSelect}
        onModalClose={() => setShowSelectLocation(false)}
      />
      <div className={styles['main-container']}>
        <h4>Create Restaurant</h4>
        <div className={styles['form-container']}>
          {getName()}
          {getEmail()}
          {getPhone()}
          {getSelectedAddress()}
          {getAboutUs()}
          <ActionButton
            text={'Save'}
            active={!loading}
            isLoading={loading}
            onClick={saveStripeProductFn}
            customClass="mt-3"
          />
        </div>
      </div>
    </div>
  );
};

export { CreateRestaurant };
