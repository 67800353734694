import BackButton from 'components/BackButton';
import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from 'react';
import { fetchUserCartDetails } from 'services/api/cart';
import { showError } from 'utils';
import BasicDetails from './basicDetails';
import styles from './index.module.scss';
import ListItems from './listItems';

const UserCartDetails = (props) => {
  const { id } = props.match.params;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({}) as any;

  const fetchUserCartDetailsFn = async () => {
    setLoading(true);
    try {
      const response = await fetchUserCartDetails({ id });
      setData(JSON.parse(JSON.stringify(response)));
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserCartDetailsFn();
  }, []);

  const getHeader = () => (
    <div className={styles['header']}>
      <div className={styles['heading']}>User Cart Details</div>
      <div className={styles['header-buttons']}>
        <BackButton />
      </div>
    </div>
  );
  
  return (
    <div id={'main-container'}>
      {getHeader()}
      {loading ? <Loading /> : 
        <div className={styles['sub-container']}>
          <BasicDetails details={data} />
          <ListItems items={data?.dishes} />
        </div>
      }
    </div>
  );
};

export default UserCartDetails;