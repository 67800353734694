import TimePicker from 'components/Date/TimePicker';
import React from 'react';
import { removeWhiteSpacesAndNonNumberCharacters } from 'utils';

const SingleSlot = props => {
  const {
    slot,
    slotIndex,
    deleteSlot,
    handleChange,
  } = props;
  
  const convertForTimePicker = (timeString: string) => {
    let time = removeWhiteSpacesAndNonNumberCharacters(timeString);
    if (time.length !== 4) time = '';

    if (!!time.length) {
      return time.slice(0, 2) + ':' + time.slice(2);
    } else return time;
  };
  
  return (
    <div className={'single-slot'}>
      <div className={'from-time-input-group'}>
        <div className={'time-group-label'}>From</div>
        <TimePicker
          value={convertForTimePicker(slot.start)}
          onChange={val => handleChange(val, 'start')}
        />
      </div>
      <div className={'to-time-input-group'}>
        <div className={'time-group-label'}>To</div>
        <TimePicker
          value={convertForTimePicker(slot.end)}
          onChange={val => handleChange(val, 'end')}
        />
      </div>
      <div className={'delete-slot'} onClick={() => deleteSlot(slotIndex)}>
        <i className={'fa fa-times'} />
      </div>
    </div>
  );
};

export default SingleSlot;