import Parse from 'parse';
import history from 'helpers/history';

// @ts-ignore
Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, process.env.REACT_APP_PARSE_JS_KEY);
// @ts-ignore
Parse.serverURL = process.env.REACT_APP_PARSE_SERVER_URL;

export async function cloudFunction (funName: string, params: any): Promise<any> {
  try {
    const response = await Parse.Cloud.run(funName, params);
    return response;
  } catch (e) {
    if (e.code === 209) {
      Parse.User.logOut();
      window.location.reload();
    } else {
      throw (e.message || e.toString() || e);
    }
  }
}

export default Parse;
