import React, { useState } from 'react';
import moment from 'moment';
import ReportsFilter from 'components/ReportsFilter';
import { Loading } from 'components/loading/Loading';
import ExportCSVButton from 'components/ExportCSVButton';
import DataTable from 'react-data-table-component';
import { tableStyles } from 'css/tableStyles';
import EmptySectionMessage from 'components/message/EmptySectionMessage';
import TableLink from 'TableLink';
import { fetchDriverActiveStatusChangedEvents } from 'services';
import { showError } from 'utils/notifications';

const columns = [
  {
    name: 'Event ID',
    selector: 'objectId',
  },
  {
    name: 'Created At',
    selector: 'createdAt',
  },
  {
    name: 'Driver ID',
    selector: 'entityId',
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/drivers/${row.entityId}`}
        text={row.entityId}
      />
    ),
  },
  {
    name: 'Performed By',
    selector: 'performedBy',
  },
  {
    name: 'Event Name',
    selector: 'eventName',
  },
  {
    name: 'New Value',
    selector: 'newValue',
    format: row => row.newValue.toString(),
  },
];

const columnsForCSV = [
  {
    label: 'Event ID',
    value: 'objectId',
  },
  {
    label: 'Created At',
    value: 'createdAt',
  },
  {
    label: 'Driver ID',
    value: 'entityId',
  },
  {
    label: 'Performed By',
    value: 'performedBy',
  },
  {
    label: 'Event Name',
    value: 'eventName',
  },
  {
    label: 'New Value',
    value: 'newValue',
  },
];

const DriverEvents = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null | string>(moment().subtract(1, 'week').toISOString());
  const [endDate, setEndDate] = useState<Date | null | string>(moment().toISOString());
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [apiCalled, setAPICalled] = useState<boolean>(false);
  // const [newValue, setNewValue] = useState<boolean>(false);

  const fetchTaxSummary = async () => {
    if (!apiCalled) setAPICalled(true);
    setLoading(true);
    const filter = {
      startDate,
      endDate,
      // newValue,
    };
    try {
      const response = await fetchDriverActiveStatusChangedEvents({ filter });
      setData(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const getDataTable = () => {
    if (loading) return <Loading />;

    if (data.length) {
      const exportCSVButton = (
        <ExportCSVButton
          key={''}
          data={data}
          columnsForCSV={columnsForCSV}
          fileName={'Driver Events'}
        />
      );

      return (
        <DataTable
          title={'Reports'}
          columns={columns}
          data={data}
          customStyles={tableStyles}
          striped={true}
          actions={[exportCSVButton]}
        />
      );
    } else {
      return apiCalled ? <EmptySectionMessage message={'No records found'} /> : null;
    }
  };

  return (
    <div>
      <div className={'d-flex align-items-center'}>
        {/* <div className={'d-flex mr-4 align-items-center'}> */}
        {/* <input
            className={'mr-2'}
            type={'checkbox'}
            checked={newValue}
            onChange={e => setNewValue(e.target.checked)}
          />
          <span className={'checkbox-label'}>New Value</span> */}
      </div>
      <ReportsFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        showEmailInput={false}
        handleSubmit={fetchTaxSummary}
      />
      {/* </div> */}
      {getDataTable()}
    </div>
  );
};

export default DriverEvents;