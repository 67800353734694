import React from 'react';
import PaymentIcon from 'react-payment-icons';
import _ from 'lodash';
import styles from './index.module.scss';

const RenderPaymentMethod = (props) => {
  const { paymentMethod } = props;
    
  const
    id = _.get(paymentMethod, 'id', ''),
    livemode = _.get(paymentMethod, 'livemode', ''),
    stripePaymentMethodId = _.get(paymentMethod, 'stripePaymentMethodId', ''),
    brand = _.get(paymentMethod, 'card.brand', ''),
    expMonth = _.get(paymentMethod, 'card.exp_month', ''),
    expYear = _.get(paymentMethod, 'card.exp_year', ''),
    last4 = _.get(paymentMethod, 'card.last4', '');

  return (
    <div key={id} className={styles['single-payment-method']}>
      <div className={styles['payment-method-details']}>
        <div className={'d-flex'}>
          <div className={styles['line1']}>{`**** **** **** ${last4}`}</div>
          <PaymentIcon
            id={brand.toLowerCase()}
            className="payment-icon"
          />
        </div>
        <div className={styles['line2']}>
          <div>{`Expires on:`}</div>
          <div>{`${expMonth}/${expYear}`}</div>
        </div>
        {livemode && (
          <div className={styles['livemode']}>
            <div>Live Mode:</div>
            <div>{livemode.toString()}</div>
          </div>
        )}
        {stripePaymentMethodId ? (
          <div className={styles['stripe-id']}>
            <div>Stripe Payment ID:</div>
            <div>{stripePaymentMethodId}</div>
          </div>
        ): null}
      </div>
    </div>
  );
};

export default RenderPaymentMethod;