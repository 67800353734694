import React, { useCallback, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import _ from 'lodash';
import theme from './index.module.scss';
import { searchRestaurants } from 'services/api/restaurant';

const RestaurantAutoSuggest = props => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const {
    placeholder,
    onSuggestionSelect,
    disabled,
  } = props;

  const onChange = (e, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    if (value.length >= 2) {
      const result = await searchRestaurants(value,{});
      if (result) {
        setSuggestions(result);
      }
    }
  };

  const onSuggestionsFetchRequestedDebounced = useCallback(_.debounce(onSuggestionsFetchRequested, 500), [value]);

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = suggestion => suggestion.name;

  const renderSuggestion = suggestion => {
    return (
      <div >
        <span>{suggestion.name}</span>
      </div>
    );
  };

  const onSuggestionSelected = (e,{ suggestion }) => {
    onSuggestionSelect(suggestion);
  };

  const inputProps = {
    placeholder,
    value,
    onChange,
    disabled,
  };

  return (
    <>
      <Autosuggest
        theme={theme}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequestedDebounced}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    </>
  );
};

export default RestaurantAutoSuggest;
