import React, {  } from 'react';
import './index.scss';

const AddDishVariantDetailsForm = props => {
  const {
    newVariantDetails, 
    setNewVariantDetails,
  } = props;

  const setDetail = ({ key, value }) => {
    setNewVariantDetails({ ...newVariantDetails, [key]: value });
  };

  return (
    <div className={'add-variant-modal-body'}>
      <div className={'add-variant-info-group mb-4'}>
        <div className={'label'}>Name</div>
        <div className={'field'}>
          <input
            className={'form-control'}
            value={newVariantDetails?.name}
            onChange={e => setDetail({ key: 'name', value: e.target.value })}
          />
        </div>
      </div>
      <div className={'add-variant-info-group mb-4'}>
        <div className={'label'}>Price</div>
        <div className={'field'}>
          <input
            className={'form-control'}
            step={0.01}
            value={newVariantDetails?.price}
            type={'number'}
            onChange={e => setDetail({ key: 'price', value: parseFloat(e.target.value) })}
          />
        </div>
      </div>
      <div className='d-flex mb-4'>
        <div className={'add-variant-info-group mr-2'}>
          <div className={'label'}>Min Count</div>
          <div className={'field'}>
            <input
              className={'form-control'}
              step={1}
              value={newVariantDetails?.minCount}
              type={'number'}
              onChange={e => setDetail({ key: 'minCount', value: e.target.value })}
            />
          </div>
        </div>
        <div className={'add-variant-info-group mr-2'}>
          <div className={'label'}>Max Count</div>
          <div className={'field'}>
            <input
              className={'form-control'}
              step={1}
              value={newVariantDetails?.maxCount}
              type={'number'}
              onChange={e => setDetail({ key: 'maxCount', value: e.target.value })}
            />
          </div>
        </div>
        <div className={'add-variant-info-group mr-2'}>
          <div className={'label'}>Min selected variants</div>
          <div className={'field'}>
            <input
              className={'form-control'}
              step={1}
              value={newVariantDetails?.minSelectedVariants}
              type={'number'}
              onChange={e => setDetail({ key: 'minSelectedVariants', value: e.target.value })}
            />
          </div>
        </div>
        <div className={'add-variant-info-group mr-2'}>
          <div className={'label'}>Max selected variants</div>
          <div className={'field'}>
            <input
              className={'form-control'}
              step={1}
              value={newVariantDetails?.maxSelectedVariants}
              type={'number'}
              onChange={e => setDetail({ key: 'maxSelectedVariants', value: e.target.value })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDishVariantDetailsForm;