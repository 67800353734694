import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ActionButton from 'components/ActionButton/ActionButton';
import { assignOrderToDriver } from 'services/api/order';
import { showError, showSuccess } from 'utils/notifications';

const AssignDriverModal = props => {
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    show,
    orderId,
    driverId,
    onClose,
    onSuccess,
  } = props;
  
  const handleSubmit = async () => {
    setLoading(true);
    try {
      await assignOrderToDriver({
        orderId,
        driverId,
        note,
      });
      await onSuccess();
      showSuccess('Driver assigned successfully');
      onClose();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title>Notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          name="note"
          id="note"
          cols={30}
          rows={10}
          className="w-100"
          defaultValue={note}
          onChange={event => setNote(event.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <ActionButton
          text={'Close'}
          active={true}
          isLoading={false}
          onClick={onClose}
          type={'button'}
        />
        <ActionButton
          text={'Submit'}
          active={!!note.length}
          isLoading={loading}
          onClick={handleSubmit}
          customClass={'btn-primary'}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AssignDriverModal;
