import { IRadioInput } from 'interfaces/radioInput';
import React from 'react';

const RadioInput = (props: IRadioInput)=> {
  const { name, description, value, label, setSelectedOption } = props;

  return (
    <div className='refund-type-container'>
      <input
        value={value}
        type='radio'
        name={name}
        onChange={e => setSelectedOption(e.target.value)}
      />
      <div className='type-label'>
        <label className='option-name'>{label}</label>
        <label className='option-description'>{description}</label>
      </div>
    </div>
  );
};

export default RadioInput;