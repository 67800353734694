import { cloudFunction } from 'services/parse';

export const fetchJobs = async () => {
  const response = await cloudFunction('fetchJobs', {});
  return response;
};

export const fetchCronJobDetails = async (id: string) => {
  const response = await cloudFunction('fetchJobDetails', { id });
  return response.toJSON();
};

export const getJobServerInstances = () => {
  return cloudFunction('getJobServerInstances', {});
};

export const toggleJobActiveStatus = (id: string) => {
  return cloudFunction('toggleJobActiveStatus', { id });
};

export const setJobServerInstance = (id: string, jobServerInstance: string) => {
  return cloudFunction('setJobServerInstance', { id, jobServerInstance });
};

export const setJobCronExpression = async (id: string, cronExpression: string) => {
  const response = await cloudFunction('setJobCronExpression', { id, cronExpression });
  return response.toJSON();
};

export const setJobParams = async (id: string, params: {}) => {
  const response = await cloudFunction('setJobParams', { id, params });
  return response.toJSON();
};