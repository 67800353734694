import BackButton from 'components/BackButton';
import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from 'react';
import {
  fetchUserSubscriptionDetails,
} from 'services';
import { STRIPE_DASHBOARD_LINK, showError, stringifyAndParseObj } from 'utils';
import styles from './index.module.scss';
import RenderInfo from 'components/RenderInfo';
import CopyToClipBoard from 'components/CopyToClipBoard';

const UserSubscriptionDetails = props => {
  const { subscriptionId } = props.match.params;

  const [loading, setLoading] = useState<boolean>(true);
  const [subscriptionInfo, setSubscriptionInfo] = useState<Record<string, any>>({});
  const { user = {}, subscription: { stripeProduct = {}} = {}} = subscriptionInfo;

  const fetchSubscriptionDetailsFn = async () => {
    try {
      let response = await fetchUserSubscriptionDetails({ userSubscriptionId: subscriptionId });
      response = stringifyAndParseObj(response);
      setSubscriptionInfo(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionDetailsFn();
  }, []);

  if (loading) return <Loading />;

  const getBasicDetailsSection = () => (
    <div className={styles['basic-details-section']}>
      <div className={styles['sub-container']}>
        <div className={styles['details-section']}>
          <RenderInfo heading='ObjectId' value={subscriptionInfo?.objectId} />
          <RenderInfo heading='quantity' value={subscriptionInfo?.quantity} />
          <RenderInfo heading='status' value={subscriptionInfo?.status} />
          <div className='d-flex align-items-center'>
            <RenderInfo 
              heading='Stripe Subscription Id' 
              value={subscriptionInfo?.stripeSubscriptionId} 
              link={`${STRIPE_DASHBOARD_LINK}/subscriptions/${subscriptionInfo?.stripeSubscriptionId}`}
            />
            <CopyToClipBoard
              copyText={`${STRIPE_DASHBOARD_LINK}/subscriptions/${subscriptionInfo?.stripeSubscriptionId}`}
            />
          </div>
        </div>
        <div className={styles['details-section']}>
          <RenderInfo heading='Created At' value={subscriptionInfo?.createdAt || '-'} type={subscriptionInfo?.createdAt ? 'date' : ''} />
          <RenderInfo heading='Started At' value={subscriptionInfo?.startDate || '-'} type={subscriptionInfo?.startDate ? 'date' : ''} />
          <RenderInfo heading='Current Period Start' value={subscriptionInfo?.currentPeriodStart || '-'} type={subscriptionInfo?.currentPeriodStart ? 'date' : ''} />
          <RenderInfo heading='Current Period End' value={subscriptionInfo?.currentPeriodEnd || '-'} type={subscriptionInfo?.currentPeriodEnd ? 'date' : ''} />
        </div>
        
      </div>
    </div>
  );
  
  const getUserDetailsSection = () => (
    <div className={styles['user-details-section']}>
      <div className={styles['subheading']}>User Details</div>
      <div className={styles['details-section']}>
        <RenderInfo heading='User ID' value={user.objectId} link={`/users/${user.objectId}`} />
        <RenderInfo heading='Name' value={user.full_name} />
        {user.phone ? (<RenderInfo heading='phone' value={user.phone} />) : null}
      </div>
    </div>
  );

  const getStripeProductDetailsSection = () => (
    <div className={styles['user-details-section']}>
      <div className={styles['subheading']}>Stripe Product Details</div>
      <div className={styles['details-section']}>
        <RenderInfo heading='Object Id' value={stripeProduct.objectId} link={`/stripe-products/${stripeProduct.slug}`} />
        <RenderInfo heading='Name' value={stripeProduct.name} />
      </div>
    </div>
  );

  return (
    <>
      <div className={styles['main-container']}>
        <div className={styles['header']}>
          <div className={styles['heading']}>User Subscription Details</div>
          <BackButton />
        </div>
        {getBasicDetailsSection()}
        {getStripeProductDetailsSection()}
        {getUserDetailsSection()}
      </div>
    </>
  );
};

export { UserSubscriptionDetails };
