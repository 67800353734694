import { cloudFunction } from 'services/parse';

export const fetchDisputes = (data: Record<string, any>) => {
  return cloudFunction('fetchDisputes', data);
};

export const fetchDisputeStatuses = () => {
  return cloudFunction('fetchDisputeStatuses', {});
};

export const fetchDisputeDetail = (data: Record<string, any>) => {
  return cloudFunction('fetchDisputeDetail', data);
};

export const addNote = (data: Record<string, any>) => {
  return cloudFunction('addNote', data);
};