import React, { useState } from 'react';
import './styles.scss';
import { importDishes } from 'services/api/dishes';
import { Loading } from 'components/loading/Loading';
import { Error } from 'components/message/Error';
import SampleCSVInfo from 'components/SampleCSVInfo';
import RestaurantAutoSuggest from 'components/RestaurantAutoSuggest';
import ImportDishSuccessModal from './importDishSuccessModal';

const ImportDish = (props) => {
  const [sheetUrl, setSheetUrl] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [restaurantId, setRestaurantId] = useState('');
  const [overrideExistingImage, setOverrideExistingImage] = useState(false);
  const [importResponse, setImportResponse] = useState({});
  const [modalShow, setModalShow] = useState(false);
  
  const startImport = async (e: any): Promise<void> => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!restaurantId || !sheetUrl) {
        throw 'Please select a restaurant and provide sheet URL both';
      }
      setError('');
      const response = await importDishes(sheetUrl, restaurantId, overrideExistingImage);
      setImportResponse(response);
      setLoading(false);
      setModalShow(true);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  const importStatus = () => {
    if (loading) return <Loading />;
    if (error) return <Error message={error} />;
    return <div />;
  };

  const onModalClose = () => {
    setModalShow(!modalShow);
  };

  return (
    <>
      <ImportDishSuccessModal show={modalShow} onClose={onModalClose} data={importResponse} restaurantId={restaurantId} {...props}/>
      <div className="import-container">
        <h4>Import Dishes</h4>
        <form action="">
          <div className="form-group">
            <label htmlFor="fileUrl">Google Sheet URL</label>
            <input
              className="form-control"
              id="fileUrl"
              type="text"
              value={sheetUrl}
              placeholder="Enter Google Sheet URL/ID"
              onChange={e => setSheetUrl(e.target.value)}
            />
          </div>
          <div className="form-check import-checkbox">
            <label htmlFor="overrideExistingImage" className="">
              <input
                className="import-checkbox"
                id="overrideExistingImage"
                type="checkbox"
                checked={overrideExistingImage}
                onChange={e => setOverrideExistingImage(e.target.checked)}
              />
              Allow overriding the  existing image?
            </label>
          </div>
          <SampleCSVInfo />
          <div className='filter-container'>
            <div className={'input-container mr-2'}>
              <label>Restaurant Id</label>
              <input
                value={restaurantId}
                onChange={e => setRestaurantId(e.target.value)}
                className={'form-control'}
              />
            </div>
            <div className='input-container mr-2'>
              <label>Restaurant</label>
              <RestaurantAutoSuggest
                placeholder={'Search Restaurant'}
                onSuggestionSelect={suggestion => setRestaurantId(suggestion.id)}
              />
            </div>
          </div>
          {importStatus()}
          <button
            type="submit"
            className="btn btn-primary float-right"
            onClick={startImport}
          >
            Start Import
          </button>
        </form>
      </div>
    </>
  );
};

export { ImportDish };
