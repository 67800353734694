import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import * as React from 'react';
import { useState } from 'react';
import moment from 'moment';

interface IDatePickerFilter {
  startDate?: Date | string | null;
  endDate?: Date | string | null;
  onDatesChange: Function;
}

const DateRangePickerWrapper: React.FC<IDatePickerFilter> = (props) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const startDate = props.startDate ? moment(props.startDate) : null;
  const endDate = props.endDate ? moment(props.endDate) : null;

  return <DateRangePicker
    startDateId="startDate" // PropTypes.string.isRequired,
    endDateId="endDate" // PropTypes.string.isRequired,
    startDate={startDate}
    minimumNights={0}
    endDate={endDate}
    onDatesChange={props.onDatesChange} // PropTypes.func.isRequired,
    onFocusChange={setFocusedInput}
    focusedInput={focusedInput}
    isOutsideRange={() => false}
  />;
};

// DateRangePickerWrapper.propTypes = {
//   onDatesChange: PropTypes.func.isRequired,
//   startDate: PropTypes.instanceOf(Date),
//   endDate: PropTypes.instanceOf(Date),
// };

export { DateRangePickerWrapper };
