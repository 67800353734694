import BackButton from 'components/BackButton';
import JSONViewer from 'components/JSONViewer';
import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from 'react';
import {
  fetchScrapperDetails, removeScrapperServerInstance, setScrapperServerInstance,
  toggleScrapperActiveStatus, toggleScrapperFilterByTags, updateLastScrappingTime, updateScrapper,
} from 'services';
import { showError, showSuccess } from 'utils/notifications';
import './index.scss';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ActionButton from 'components/ActionButton/ActionButton';
import ScrapperPlatformDropdown from 'components/ScrapperPlatformDropdown';
import ScrapperServerInstanceDropDown, { NONE } from 'components/ScrapperInstanceDropDown';
import RestaurantAutoSuggest from 'components/RestaurantAutoSuggest';
import TableLink from 'TableLink';

const ScrapperDetails = props => {
  const { match: { params: { scrapperId } } } = props;
  const [scrapper, setScrapper] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [updateActiveloading, setUpdateActiveLoading] = useState<boolean>(false);
  const [filterByTagsLoading, setFilterByTagsLoading] = useState<boolean>(false);
  const [lastScrappingTime, setLastScrappingTime] = useState<Date | null>();
  const [updateScrappingTimeloading, setUpdateScrappingTimeLoading] = useState<boolean>(false);
  const [editScrapperInfo, setEditScrapperInfo] = useState<boolean>(false);
  const [editScrapperLoading, setEditScrapperLoading] = useState<boolean>(false);
  const [scrapperServerInstance, setScrapperServerInstanceState] = useState<any>(undefined);

  const fetchScrapperDetailsFn = async () => {
    try {
      const response = await fetchScrapperDetails(scrapperId);
      setScrapper(response);
      if (response.scrappingStats?.lastScrappingTime) {
        setLastScrappingTime(moment(response.scrappingStats?.lastScrappingTime.iso).toDate());
      }
      setScrapperServerInstanceState(response.scrapperServerInstance);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchScrapperDetailsFn();
  }, []);

  const updateLastScrappingTimeFn = async (date: Date) => {
    setUpdateScrappingTimeLoading(true);
    setLastScrappingTime(date);
    try {
      await updateLastScrappingTime(scrapperId, date.toISOString());
      showSuccess('Last Scrapping Time Updated successfully');
    } catch (e) {
      showError(e);
    } finally {
      setUpdateScrappingTimeLoading(false);
    }
  };

  const toggleScrapperStatus = async (active: boolean) => {
    setUpdateActiveLoading(true);
    try {
      await toggleScrapperActiveStatus(scrapperId);
      showSuccess('Scrapper status changed.');
      setScrapper({ ...scrapper, active });
    } catch (e) {
      showError(e);
    } finally {
      setUpdateActiveLoading(false);
    }
  };

  const toggleFilterByTags = async (filterByTags: boolean) => {
    setFilterByTagsLoading(true);
    try {
      await toggleScrapperFilterByTags(scrapperId);
      showSuccess('Filter By Tags Toggled.');
      setScrapper({ ...scrapper, filterByTags });
    } catch (e) {
      showError(e);
    } finally {
      setFilterByTagsLoading(false);
    }
  };

  const updateScrapperFn = async () => {
    setEditScrapperLoading(true);
    const data = {
      id: scrapperId,
      name: scrapper.name,
      vendorId: scrapper.vendorId,
      platform: scrapper.platform,
      scrappingURL: scrapper.scrappingURL,
    };

    try {
      if (scrapperServerInstance === NONE) {
        await removeScrapperServerInstance({ id: scrapperId });
      } else if (scrapperServerInstance) {
        await setScrapperServerInstance({ id: scrapperId, scrapperServerInstance });
      }
      await updateScrapper(data);
      await fetchScrapperDetailsFn();
      setEditScrapperInfo(false);
      showSuccess('Scrapper Updated.');
    } catch (e) {
      showError(e);
    } finally {
      setEditScrapperLoading(false);
    }
  };

  const getScrapperStats = () => (
    <div className={'stats-container'}>
      <div className={'subheading'}>Scrapping Stats</div>
      <div className={'stats-group'}>
        <div className={'label'}>Running</div>
        <div className={'info'}>{scrapper.scrappingStats.running ? 'Yes' : 'No'}</div>
      </div>
      <div className={'stats-group mb-4'}>
        <div className={'label'}>Last Scrapping time</div>
        <div className={'info'}>
          <DatePicker
            className={'form-control'}
            disabled={updateScrappingTimeloading}
            selected={lastScrappingTime}
            onChange={e => updateLastScrappingTimeFn(moment((e || {}).toString()).toDate())}
          />
        </div>
      </div>
      <div className={'pl-2'}>
        <JSONViewer data={_.omit(scrapper.scrappingStats, 'running')} />
      </div>
    </div>
  );

  const getScrapperBasicDetails = () => {
    const getDetailsCheckboxSection = () => (
      <div className={'d-flex flex-column w-50 align-items-end pr-4'}>
        <div className={'checkbox-container'}>
          {updateActiveloading ? (
            <Loading type={'mini'} />
          ) : (
            <input type={'checkbox'}
              checked={scrapper.active}
              disabled={updateActiveloading}
              onChange={e => toggleScrapperStatus(e.target.checked)}
            />
          )}
          <div className={'info'}>Active</div>
        </div>
        <div className={'checkbox-container'}>
          {filterByTagsLoading ? (
            <Loading type={'mini'} />
          ) : (
            <input type={'checkbox'}
              checked={scrapper.filterByTags}
              disabled={filterByTagsLoading}
              onChange={e => toggleFilterByTags(e.target.checked)}
            />
          )}
          <div className={'info'}>Filter By Tags</div>
        </div>
      </div>
    );

    const getScrapperDetails = () => (
      <div className={'d-flex flex-column w-50'}>
        <div className={'info-group'}>
          <div className={'label'}>Scrapper Name</div>
          {editScrapperInfo ? (
            <input
              disabled={editScrapperLoading}
              className={'form-control'}
              defaultValue={scrapper.name}
              onChange={e => setScrapper({ ...scrapper, name: e.target.value })}
            />
          ) : (
            <>
              <div className={'info'}>{scrapper.name || '-'}</div>
            </>
          )}
        </div>
        <div className={'info-group'}>
          <div className={'label'}>Platform</div>
          {editScrapperInfo ? (
            <ScrapperPlatformDropdown
              value={scrapper.platform}
              onChange={e => setScrapper({ ...scrapper, platform: e })}
            />
          ) : (
            <>
              <div className={'info'}>{scrapper.platform || '-'}</div>
            </>
          )}
        </div>
        <div className={'info-group'}>
          <div className={'label'}>Restaurant ID</div>
          {editScrapperInfo ? (
            <div className="d-flex">
              <div className={'mr-2'}>
                <input
                  value={scrapper.vendorId}
                  onChange={e => setScrapper({ ...scrapper, vendorId: e.target.value })}
                  className={'form-control'}
                />
              </div>
              <div >
                <RestaurantAutoSuggest
                  placeholder={'Search Restaurant'}
                  onSuggestionSelect={suggestion => setScrapper({ ...scrapper, vendorId: suggestion.id })}
                />
              </div>
            </div>
          ) : (
            <>
              <div className={'info'}>
                {scrapper.vendorId ?
                  <TableLink
                    linkTo={`/restaurants/${scrapper.vendorId}`}
                    text={scrapper.vendorId}
                    openInNewTab={false}
                  />
                  : '-'}
              </div>
            </>
          )}
        </div>
        <div className={'info-group'}>
          <div className={'label'}>Scrapping URL</div>
          {editScrapperInfo ? (
            <input
              className={'form-control'}
              disabled={editScrapperLoading}
              defaultValue={scrapper.scrappingURL}
              onChange={e => setScrapper({ ...scrapper, scrappingURL: e.target.value })}
            />
          ) : (
            <>
              <div className={'info'}>{scrapper.scrappingURL || '-'}</div>
            </>
          )}
        </div>
        <div className={'info-group'}>
          <div className={'label'}>Scrapper Server Instance</div>
          {editScrapperInfo ? (
            <>
              <ScrapperServerInstanceDropDown
                value={scrapperServerInstance}
                onChange={instanceName => setScrapperServerInstanceState(instanceName)}
              />
            </>
          ) : (
            <>
              <div className={'info'}>{scrapperServerInstance || 'N/A'}</div>
            </>
          )}
        </div>
      </div>
    );

    const getScrapperDetailsHeader = () => (
      <div className={'d-flex w-100 justify-content-end mb-2 mt-n2'}>
        {editScrapperInfo ? (
          <>
            <ActionButton
              text={'Cancel'}
              active={true}
              isLoading={false}
              onClick={() => setEditScrapperInfo(false)}
              customClass={'btn btn-outline-secondary mr-3'}
            />
            <ActionButton
              text={'Save'}
              active={!editScrapperLoading}
              isLoading={editScrapperLoading}
              onClick={updateScrapperFn}
              customClass={'btn btn-primary'}
            />
          </>
        ) : (
          <div
            className={'edit-button'}
            onClick={() => setEditScrapperInfo(true)}
          >
            <i className={'fa fa-pencil-square-o'} />
            <span>Edit</span>
          </div>
        )}
      </div>
    );

    return (
      <div className={'basic-details'}>
        <div className={'d-flex flex-column w-100'}>
          {getScrapperDetailsHeader()}
          <div className={'d-flex'}>
            {getScrapperDetails()}
            {getDetailsCheckboxSection()}
          </div>
        </div>
      </div>
    );
  };

  const getHeader = () => (
    <div className={'header'}>
      <BackButton />
      <h4>Scrapper Details</h4>
    </div>
  );

  if (loading) return <Loading />;

  return (
    <div className={'scrapper-details-container'}>
      {getHeader()}
      {getScrapperBasicDetails()}
      {getScrapperStats()}
    </div>
  );
};

export { ScrapperDetails };
