import React from 'react';
import { DocumentSlot } from './DocumentSlot';
import { OTHER_DOCUMENT_TYPE } from '../../../../utils';
import { DocumentUpload } from './DocumentUpload';
import { Document } from './Document';

const Documents = (props: any) => {
  const { isOtherDocumentsRequired=true } = props;
  const getStandardDocuments = () => {
    return props.AVAILABLE_DOCUMENT_LIST.map(docType => {
      return (
        <div className="col-6 col-md-3 col-lg-2 text-center document-container" key={docType.code}>
          <div className="source">{docType.displayName}</div>
          <DocumentSlot documentType={docType} {...props}/>
        </div>
      );
    });
  };

  const getOtherDocuments = () => {
    const { documents } = props;
    
    const otherDocuments = documents.filter(d => d.documentCode=== OTHER_DOCUMENT_TYPE.code);
    return <>
      {otherDocuments.map(doc => {
        return <>
          <div className="col-6 col-md-3 col-lg-2 text-center document-container" key={doc.code}>
            <div className="source">{OTHER_DOCUMENT_TYPE.displayName}</div>
            <Document doc={doc} {...props}/>
          </div>
        </>;
      })}
    </>;
  };

  const getUploadOtherDocumentSlot = () => {
    return <>
      <div className="col-6 col-md-3 col-lg-2 text-center document-container" key={OTHER_DOCUMENT_TYPE.code}>
        <div className="source">{OTHER_DOCUMENT_TYPE.displayName}</div>
        <DocumentUpload documentType={OTHER_DOCUMENT_TYPE} userId={props.userId} {...props}/>
      </div>
    </>;
  };

  return (
    <div className="documents mt-4">
      {<h4 className="pl-2 font-weight-bolder">{!props.headerName ? 'Documents': props.headerName}</h4>}
      <div className={`${props.headerName ?  '' : 'card'}`}>
        <div className={`card-body ${props.customClass || ''}`}>
          <div className="row">
            { getStandardDocuments() }
            { isOtherDocumentsRequired && getOtherDocuments() }
            { isOtherDocumentsRequired && getUploadOtherDocumentSlot() }
          </div>
        </div>
      </div>
    </div>
  );
};

export { Documents };
