import React from 'react';
import styles from './index.module.scss';
import { DATE_FORMATS, formatDate } from 'utils';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ActionCheckbox from 'components/ToggleSwitch';
import EmailEligibility from './EmailEligibility';
import DomainEligibility from './DomainEligibility';
import GroupEligibility from './GroupEligibility';

const UserEligibility = props => {
  const { detailsEditable,discount,setDiscount,loading }=props;
  const createBeforeDate = discount?.userEligibility?.createBeforeDate;
  const isDriver = discount?.userEligibility?.isDriver;
  const isDispatcher = discount?.userEligibility?.isDispatcher;

  const getCreateBeforeDate=()=>{
    if(detailsEditable){
      return (
        <DatePicker
          wrapperClassName='w-100 pb-2'
          className={'form-control'}
          dateFormat={DATE_FORMATS.MM_DD_YYYY__EE_HH_MM_A}
          selected={createBeforeDate ? moment(createBeforeDate).toDate() : null}
          onChange={(e: any) => setDiscount({ ...discount, userEligibility: { ...discount?.userEligibility, createBeforeDate: moment(e).toISOString() }})}
        />      
      );
    }else{
      return <div className={styles['detail']}>{formatDate(discount?.userEligibility?.createBeforeDate, DATE_FORMATS.MM_DD_YYYY_HH_MM_A)}</div>;
    }
  };

  const getIsDriver =()=>{
    if(detailsEditable){
      return (
        <ActionCheckbox
          switchLabel=''
          checked={isDriver}
          disabled={loading}
          onChange={(e: any) => setDiscount({ ...discount, userEligibility: { ...discount?.userEligibility, isDriver: e }})}
        />
      );
    }else{
      return <div>{isDriver ? 'True' : 'False'}</div>;
    }
  };

  const getIsDispatcher =()=>{
    if(detailsEditable){
      return (
        <ActionCheckbox
          switchLabel=''
          checked={isDispatcher}
          disabled={loading}
          onChange={(e: any) => setDiscount({ ...discount, userEligibility: { ...discount?.userEligibility, isDispatcher: e }})}
        />
      );
    }else{
      return <div>{isDispatcher ? 'True' : 'False'}</div>;
    }
  };


  const getUserEligibilitySection=()=>(
    <div className={styles['input-container']}>
      <div className='pb-2'>
        <div className={styles['label']}>Create Before Date</div>
        {getCreateBeforeDate()}
      </div>
      <div>
        <div className={styles['label']}>Is Driver</div>
        {getIsDriver()}
      </div>
      <div>
        <div className={styles['label']}>Is Dispatcher</div>
        {getIsDispatcher()}
      </div>
    </div>
  );
  
  return (
    <>
      <div className={styles['user-section']}>
        <h4 className='pb-2 font-weight-bolder'>User Eligibility</h4>
        {getUserEligibilitySection()}
        <EmailEligibility {...props}/>
        <DomainEligibility {...props}/>
        <GroupEligibility {...props}/>
      </div>
    </>
  );
};

export default UserEligibility;