import { ILead } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { approveDocument, deleteDocumentForUser, setExpirationDateForDocument } from 'services/api';
import { Loading } from 'components/loading/Loading';
import { RejectDocument } from './RejectDocument';
import ReactTooltip from 'react-tooltip';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { showError } from 'utils/notifications';
import FilePreviewer from 'react-file-previewer-v2';

const Document = (props: { doc: ILead.ILeadDocument; onDocumentChange: Function; isApprovedRequired?: boolean; isExpiredDateRequired?: boolean }) => {
  const { doc, onDocumentChange, isApprovedRequired=true, isExpiredDateRequired=true } = props;

  const showFullScreen = (doc: any) => {
    window.open(doc.image, '_blank');
  };

  const [loading, setLoading] = useState(false);
  const [isApproved, setIsApproved] = useState<boolean>(doc.isApproved);
  const [unApproveReason, setUnApproveReason] = useState<string>(doc.unApproveReason);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [expirationDate, setExpirationDate] = useState<Date | null>();
  const [pickerFocused, setPickerFocused] = useState(false);

  useEffect(() => {
    if (doc.expiresAt) {
      setExpirationDate(moment(doc.expiresAt.iso).toDate());
    }
  }, []);

  const approveDoc = async () => {
    setLoading(true);
    const approved: boolean = await approveDocument(doc.objectId);
    setIsApproved(approved);
    setUnApproveReason('');
    setLoading(false);
    onDocumentChange && onDocumentChange();
  };

  const unApproveDoc = async () => {
    setShowRejectModal(true);
  };

  const documentRejected = event => {
    setShowRejectModal(false);
    if (event && event.data) {
      setIsApproved(event.data.approved);
      setUnApproveReason(event.data.reason);
    }
  };

  const setDocumentExpiryDate = async (date: Date) => {
    setLoading(true);
    try {
      await setExpirationDateForDocument(doc.objectId, moment(date).toISOString());
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const expirationDateChanged = async (date) => {
    setExpirationDate(date);
    await setDocumentExpiryDate(date);
    onDocumentChange && onDocumentChange();
  };

  const deleteDocument = async ({ objectId }) => {
    setLoading(true);
    try {
      await deleteDocumentForUser(objectId);
      onDocumentChange && onDocumentChange();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  function getApproveProcessButtons () {
    if (isApprovedRequired) {
      return (
        <div className="d-flex justify-content-around mt-2">
          <button onClick={() => approveDoc()} className="btn btn-link approve-btn">
            <i className="fa fa-check"/>
          </button>
          <button onClick={() => unApproveDoc()} className="btn btn-link reject-btn">
            <i className="fa fa-times"/>
          </button>
        </div>
      );
    } else {
      return null;
    }
  }

  function getOverLayButtons () {
    return (
      <>
        <div className="fullscreen-icon d-inline" style={{ zIndex: 1 , cursor: 'pointer' }}>
          <i className="fa fa-arrows-alt pointer" onClick={() => showFullScreen(doc)} />
        </div>
        <div className="delete-icon fullscreen-icon d-inline" style={{ zIndex: 1, cursor: 'pointer' }}>
          <i className="fa fa-trash pointer" onClick={() => deleteDocument(doc)} />
        </div>
      </>
    );
  }

  function getIsRejectedTooltip() {
    if (isApprovedRequired && isApproved === false) {
      return (
        <>
          <div className="badge badge-danger badge-pill" style={{ zIndex: 1 }} data-for="main" data-tip={unApproveReason} data-event='click focus'>Rejected!</div>
          <ReactTooltip
            id="main"
            place={'top'}
            type={'dark'}
            effect={'solid'}
            globalEventOff='click'
            multiline={true}
          />
        </>);
    } else {
      return null;
    }
  }

  function getExpirationDateInput() {
    if (isExpiredDateRequired) {
      return (
        <div
          className={`date-picker-container ${pickerFocused ? 'picker-focused' : ''}`}
          onClick={() => setPickerFocused(true)}
        >
          <i className='fa fa-calendar-o' />
          <DatePicker
            className='form-control'
            selected={expirationDate}
            onFocus={() => setPickerFocused(true)}
            onClickOutside={() => setPickerFocused(false)}
            onBlur={() => setPickerFocused(false)}
            onChange={expirationDateChanged}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  return <>
    <div key={doc.objectId}>
      <div className="uploaded-image-container">
        {getOverLayButtons()}
        {isApprovedRequired && isApproved && (<div className="badge badge-success badge-pill" style={{ zIndex: 1 }}>Approved!</div>)}
        {getIsRejectedTooltip()}
        <div className="uploaded-image">
          <FilePreviewer 
            file={{ url: doc.image }}
          />
        </div>
        {loading && <Loading type="mini" />}
        {
          !loading
          && <>
            {getApproveProcessButtons()}
            {getExpirationDateInput()}
          </>
        }
      </div>
      <RejectDocument doc={doc} show={showRejectModal} onHide={documentRejected}/>
    </div>
  </>;
};
export { Document };
