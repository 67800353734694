import React, { useEffect, useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import qs from 'query-string';
import './index.scss';
import { DateRangePickerWrapper } from 'components/Date/DateRangePicker';
import moment from 'moment';
import ShopifyMerchantAutoSuggest from 'components/ShopifyMerchantAutoSuggest';

const OrdersFilter = props => {
  const [orderId, setOrderId] = useState<string>('');
  const [uvaOrderId, setUvaOrderId] = useState<string>('');
  const [shopifyOrderId, setShopifyOrderId] = useState<string>('');
  const [merchantId, setMerchantId] = useState<string>('');
  const [merchantName, setMerchantName] = useState<string>('');
  const [startDate, setStartDate] = useState<string | null>('');
  const [endDate, setEndDate] = useState<string | null>('');

  useEffect(() => {
    const {
      orderId,
      uvaOrderId,
      shopifyOrderId,
      merchantId,
      startDate,
      endDate,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });

    if (orderId) setOrderId(orderId.toString());
    if (uvaOrderId) setUvaOrderId(uvaOrderId.toString());
    if (shopifyOrderId) setShopifyOrderId(shopifyOrderId.toString());
    if (merchantId) setMerchantId(merchantId.toString());
    if (startDate) setStartDate(startDate.toString());
    if (endDate) setEndDate(endDate.toString());
  }, []);

  const resetFilter = () => {
    setOrderId('');
    setUvaOrderId('');
    setShopifyOrderId('');
    setMerchantId('');
    setStartDate('');
    setEndDate('');
    setMerchantName('');

    props.onFilterSubmit({});
  };

  const onFilterSubmit = () => {
    const filterData: Record<string, any> = {
      _id: orderId,
      uvaOrderId,
      shopifyOrderId,
      merchantId,
      startDate,
      endDate
    };

    const filteredData = {};

    for (const key in filterData) {
      if (filterData[key] !== "") {
        filteredData[key] = filterData[key];
      }
    }

    props.onFilterSubmit(filteredData);
  };

  const onDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    setStartDate(moment(startDate).toISOString());
    setEndDate(moment(endDate).toISOString());
  };

  return (
    <form className='filter-container'>
      <div className='input-container mb-2 mr-4'>
        <label>Order ID</label>
        <input
          className='form-control'
          placeholder={'Order ID'}
          value={orderId}
          onChange={e => setOrderId(e.target.value)}
        />
      </div>
      <div className='input-container mb-2 mr-4'>
        <label>UVA Order Id</label>
        <input
          className='form-control'
          placeholder={'UVA Order ID'}
          value={uvaOrderId}
          onChange={e => setUvaOrderId(e.target.value)}
        />
      </div>
      <div className='input-container mb-2 mr-4'>
        <label>Shopify Order Id</label>
        <input
          className='form-control'
          placeholder={'Shopify Order ID'}
          value={shopifyOrderId}
          onChange={e => setShopifyOrderId(e.target.value)}
        />
      </div>
      <div className='input-container mb-2 mr-4'>
        <label>Shopify Merchant Id</label>
        <input
          className='form-control'
          placeholder={'Shopify Merchant ID'}
          value={merchantId}
          onChange={e => setMerchantId(e.target.value)}
        />
      </div>
      <div className='input-container mr-4 mb-2'>
        <label>Shopify Merchant</label>
        <ShopifyMerchantAutoSuggest
          value={merchantName}
          handleChange={setMerchantName}
          placeholder={'Search Merchant'}
          onSuggestionSelect={setMerchantId}
        />
      </div>
      <div className='input-container mb-2 mr-4'>
        <label>Date Range</label>
        <DateRangePickerWrapper
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onDateSelectionChange}
        />
      </div>
      <ActionButton
        text={'Submit'}
        isLoading={false}
        active={true}
        customClass={'btn-primary mb-2'}
        onClick={onFilterSubmit}
      />
      <ActionButton
        text={'Reset'}
        isLoading={false}
        active={true}
        customClass={'btn-primary ml-4 mb-2'}
        onClick={resetFilter}
      />
    </form>
  );
};


export { OrdersFilter };
