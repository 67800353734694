import React from 'react';
import styles from './index.module.scss';

const ColorTheme = (props) => {
  const { 
    setRestaurantDetails, 
    restaurantDetails: { 
      colorTheme = {},
      colorTheme: {
        primary = '#fd7e14',
        secondary = '#fd7e14',
      } = {},
    } = {}, 
    restaurantDetails = {},
  } = props;

  const getPrimaryColor = () => {
    return (
      <div className={styles['input']}>
        <div className={styles['label']}>Primary Color</div>
        <input 
          type="color" 
          value={primary} 
          onChange={(e) => {
            setRestaurantDetails({
              ...restaurantDetails, colorTheme: {
                ...colorTheme, primary: e?.target?.value,
              },
            });
          }}  
        />
      </div>
    );
  };

  const getSecondaryColor = () => {
    return (
      <div className={styles['input']}>
        <div className={styles['label']}>Secondary Color</div>
        <input 
          type="color" 
          value={secondary} 
          onChange={(e) => {
            setRestaurantDetails({
              ...restaurantDetails, colorTheme: {
                ...colorTheme, secondary: e?.target?.value,
              },
            });
          }} 
        />
      </div>
    );
  };

  return (
    <div className={styles['container']}>
      <div className={styles['heading']}>Color Theme</div>
      <div className={styles['sub-container']}>
        {getPrimaryColor()}
        {getSecondaryColor()}
      </div>
    </div>
  );
};

export default ColorTheme;
