import ActionButton from 'components/ActionButton/ActionButton';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Error } from 'components/message/Error';
import { STRIPE_PRODUCT_TYPE, showError, showSuccess } from 'utils';
import { createStripeProduct, fetchStripeProductBySlug, updateStripeProduct } from 'services/api/stripeProducts';
import DropDown from 'components/Dropdown';
import ImageUpload from 'components/ImageUpload';

const validationSchema = yup.object().shape({
  name: yup.string().required('Please enter name'),
  enName: yup.string(),
  description: yup.string().required('Please enter description'),
  enDescription: yup.string(),
  image: yup.object(),
});

type FormData = {
  name: string;
  enName: string;
  description: string;
  enDescription: string;
  image: object;
};

const CreateStripeProduct = props => {
  const { slug } = props.match.params;
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState() as any;
  const [imageForPreview, setImageForPreview] = useState<string>('');

  const {
    register,
    handleSubmit,
    errors,
    setValue,
  } = useForm<FormData>({ resolver: yupResolver(validationSchema) });

  const fetchStripeProductBySlugFn = async () => {
    setLoading(true);
    try {
      const response = await fetchStripeProductBySlug(slug);
      const { name, enName, description, enDescription, image } = response;
      setData(response);
      setValue('name', name);
      setValue('enName', enName);
      setValue('description', description);
      setValue('enDescription', enDescription);
      setImageForPreview(image?.url ?? '');
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    register('image');
    if (slug) {
      fetchStripeProductBySlugFn();
    }
  }, []);

  const saveStripeProductFn = handleSubmit(async (values) => {
    setLoading(true);
    try {
      if (slug) {
        const res = await updateStripeProduct({ ...values, id: data?.objectId, productType: data.productType });
        showSuccess('Stripe Product updated successfully.');
        props.history.replace(`/stripe-products/${res?.slug}`);
      } else {
        await createStripeProduct({ ...values, productType: data.productType });
        showSuccess('Stripe Product created successfully.');
        props.history.goBack();
      }
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  });

  const getHeader = () => {
    const heading = slug ? 'Update Stripe Product' : 'Create a New Stripe Product';
    return (
      <div className={styles['header']}>
        <div className={styles['heading']}>{heading}</div>
      </div>
    );
  };

  const getFooter = () => {
    return (
      <div className={styles['footer']}>
        <ActionButton
          text={'Cancel'}
          active={!loading}
          isLoading={false}
          onClick={() => props.history.goBack()}
          customClass={styles['cancel']}
        />
        <ActionButton
          text={'Save'}
          active={!loading}
          isLoading={loading}
          onClick={saveStripeProductFn}
        />
      </div>
    );
  };

  const getFieldError = (error) => {
    if (!error) return null;
    return (
      <Error message={error} />
    );
  };

  const getName = () => {
    return (
      <div className={styles['name']}>
        <div className={styles['label']}>Name*</div>
        <input
          name={'name'}
          ref={register}
          className={'form-control'}
          disabled={loading}
        />
        {getFieldError(errors?.name?.message)}
      </div>
    );
  };

  const getDescription = () => {
    return (
      <div className={styles['description']}>
        <div className={styles['label']}>Description*</div>
        <textarea
          name={'description'}
          ref={register}
          className={'form-control'}
          disabled={loading}
        />
        {getFieldError(errors?.description?.message)}
      </div>
    );
  };

  const getEnName = () => {
    return (
      <div className={styles['name']}>
        <div className={styles['label']}>English Name</div>
        <input
          name={'enName'}
          ref={register}
          className={'form-control'}
          disabled={loading}
        />
        {getFieldError(errors?.enName?.message)}
      </div>
    );
  };

  const getEnDescription = () => {
    return (
      <div className={styles['description']}>
        <div className={styles['label']}>English Description</div>
        <textarea
          name={'enDescription'}
          ref={register}
          className={'form-control'}
          disabled={loading}
        />
        {getFieldError(errors?.enDescription?.message)}
      </div>
    );
  };

  const getType = () => {
    return (
      <div className={styles['description']}>
        <DropDown
          name="Product Type"
          onChange={productType =>
            setData({ ...data, productType })}
          options={STRIPE_PRODUCT_TYPE}
          value={data?.productType} />
      </div>
    );
  };

  const onImageUpload = (image) => {
    setValue('image', image);
    setImageForPreview(image?._url);
  };

  const getImageInput = () => {
    return (
      <div className={styles['image-container']}>
        <ImageUpload
          src={imageForPreview.toString()}
          onImageSelect={setImageForPreview}
          onImageUpload={onImageUpload}
          showImageIcon
          forceShowUploadButton
        />
      </div>
    );
  };

  return (
    <div className={styles['main-container']}>
      {getHeader()}
      <div className={styles['sub-container']}>
        {getImageInput()}
        <div className={styles['item-details']}>
          {getName()}
          {getDescription()}
          {getEnName()}
          {getEnDescription()}
          {getType()}
        </div>
      </div>
      {getFooter()}
    </div>
  );
};

export { CreateStripeProduct };
