import React,{ useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import styles from './index.module.scss';
import _ from 'lodash';
import Tag from 'components/Tag';

const EmailEligibility = props => {
  const { detailsEditable,discount,setDiscount,loading }=props;
  const [email, setEmail] = useState<string>('');
  const emails = discount?.userEligibility?.emailContainedIn || [];

  const handleRemove = (index: number) => {
    emails.splice(index, 1);
    setDiscount({ ...discount, userEligibility: { ...discount?.userEligibility, emailContainedIn: emails }});
  };

  const handleAddEmail = () => {
    if (!_.isEmpty(email)) {
      emails.push(email);
      setDiscount({ ...discount, userEligibility: { ...discount?.userEligibility, emailContainedIn: _.uniq(emails) }});
    }
  };

  const getEmailEligibilitySection=()=>{
    if(detailsEditable){
      return (
        <div className={styles['input-container']}>
          <div>
            <div className={`${styles['label']}`}>Add Eligible Email</div>
            <input
              className={'form-control'}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className={styles['action-button']}>
            <ActionButton
              active={true}
              isLoading={loading}
              onClick={handleAddEmail}
              text={'Add Email'}
              customClass={'form-control'}
            />
          </div>
        </div>
      );
    }else{
      return null;
    }
  };

  const getEmailsTagSection=()=>{
    if(emails?.length > 0){
      return (
        <div className={styles['tag-section']}>
          <div className={styles['roles-card']}>
            {emails.map((item, i) => <Tag key={i} tagName={item} onRemove={() => handleRemove(i)} />)}
          </div>
        </div>
      );
    }else{
      return <div className={`${styles['label']} pl-2`}>No Eligible Email</div>;
    }
  };

  return (
    <div className={styles['email-section']}>
      <h6 >Email Eligibility</h6>
      {getEmailEligibilitySection()}
      {getEmailsTagSection()}
    </div>
  );

};

export default EmailEligibility;