import React, { useEffect, useState } from 'react';
import './index.scss';
import { tableStyles } from '../../../../css/tableStyles';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { getWeeklyDriverPaymentSummary } from 'services/api/reports';
import { showError } from 'utils/notifications';
import moment from 'moment';
import { Loading } from 'components/loading/Loading';
import ActionButton from 'components/ActionButton/ActionButton';
import ExportCSVButton from 'components/ExportCSVButton';
import { DateRangePickerWrapper } from 'components/Date/DateRangePicker';
import { DRIVER_EARNING_CSV_COLUMNS, DRIVER_EARNING_DATA_TABLE_COLUMNS } from 'utils/driverEarningConstants';

const DriverWeeklyEarnings = props => {
  const [startDate, setStartDate] = useState<Date | null | string>('');
  const [endDate, setEndDate] = useState<Date | null | string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [earnings, setEarnings] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  
  const columns: IDataTableColumn<any>[] = DRIVER_EARNING_DATA_TABLE_COLUMNS.concat({
    name: 'Week Range',
    selector: 'weekRangeString',
    sortable: false,
    width: '150px',
    format: (row) => row.weekRangeString,
  });

  const columnsForCSV = DRIVER_EARNING_CSV_COLUMNS.concat({
    label: 'Week Range',
    value: 'weekRangeString',
  });

  const exportCSVButton = <ExportCSVButton data={earnings} columnsForCSV={columnsForCSV} fileName={'Driver Weekly Payment Report'} />;

  const fetchDriverWeeklyEarnings = async () => {
    setLoading(true);
    try {
      const response = await getWeeklyDriverPaymentSummary(startDate, endDate, props.objectId);
      setEarnings(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const onChangeRowPerPage = (rowsPerPage: number, currentPage: number) => {
    setPageNumber(currentPage);
    setPageSize(rowsPerPage);
  };

  const onChangePage = (page: number) => {
    setPageNumber(page);
  };

  const onDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    startDate && setStartDate(moment(startDate).toISOString());
    endDate && setEndDate(moment(endDate).toISOString());
  };

  const getEarnings = () => {
    if (loading) {
      return <Loading type="mini" />;
    } else {
      return <div className="col-12 data-table">
        <DataTable
          keyField="objectId"
          columns={columns}
          data={earnings.slice((pageNumber - 1) * pageSize, ((pageNumber - 1) * pageSize + pageSize))}
          highlightOnHover={true}
          defaultSortField="createdAt"
          customStyles={tableStyles}
          pagination={true}
          paginationServer={true}
          paginationDefaultPage={pageNumber}
          paginationPerPage={pageSize}
          paginationTotalRows={earnings.length}
          onChangeRowsPerPage={onChangeRowPerPage}
          onChangePage={onChangePage}
          actions={[exportCSVButton]}
        />
      </div>;
    }
  };
  return (
    <div className="driver-weekly-earnings">
      <div className="row">
        <div className="col-12 mb-2">
          <div className="row">
            <div className="col-6">
              <div className="text-muted font-weight-bold">Driver Weekly Earnings</div>
            </div>
            <div className="col-6 text-right">
              <div className="d-inline-block">
                <DateRangePickerWrapper
                  startDate={startDate}
                  endDate={endDate}
                  onDatesChange={onDateSelectionChange}
                />
              </div>
              <ActionButton text="Submit" active={!!(startDate && endDate)} onClick={fetchDriverWeeklyEarnings} isLoading={loading} customClass="btn-primary ml-2" />
            </div>
          </div>
        </div>
        {getEarnings()}
      </div>
    </div>
  );
};

export default DriverWeeklyEarnings;
