import React, { useEffect, useState } from 'react';
import { assignUvaCategoryToDish, fetchDishes } from 'services/api/categoriseDishes';
import ActionButton from 'components/ActionButton/ActionButton';
import { showError, showSuccess } from '../../utils/notifications';
import { Loading } from 'components/loading/Loading';
import '../dishes/dish-details/index.scss';
import UvaCategoriesFlattenTree from 'components/UvaCategoryTree/UvaCategoryFlattenTree';
import { Link } from 'react-router-dom';
import RestaurantAutoSuggest from 'components/RestaurantAutoSuggest';

const CategoriseDishes = props => {
  const [uvaCategoryId, setUvaCategoryId] = useState<string>('');
  const [uvaCategoryName, setUvaCategoryName] = useState<string>('');
  const [restaurantId, setRestaurantId] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [dishes, setDishes] = useState<Record<string, any>[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [assignLoader, setAssignLoader] = useState<boolean>(false);

  const fetchDishesToCategorise = async (restaurantId) => {
    try {
      setLoader(true);
      const response = await fetchDishes(restaurantId);
      setDishes(response.items);
    } catch (e) {
      showError(e);
    } finally {
      setLoader(false);
    }
  };

  const handleCategoryChange = (key, label) => {
    setUvaCategoryId(key);
    setUvaCategoryName(label);
  };

  const assignUvaCategory = async () => {
    try {
      setAssignLoader(true);
      await assignUvaCategoryToDish(dishes[currentPage]?.objectId, uvaCategoryId);
      showSuccess('Uva Category is Assigned Successfully');
      const dishList = [...dishes];
      dishList[currentPage].uvaCategory = uvaCategoryName;
      setDishes(dishList);
      setUvaCategoryId('');
      setCurrentPage(currentPage + 1);
    } catch (e) {
      showError(e);
    } finally {
      setAssignLoader(false);
    }
  };

  const onRestaurantChange = (e) => {
    setRestaurantId(e.target.value);
    fetchDishesToCategorise(e.target.value);
  };

  useEffect(() => {
    fetchDishesToCategorise(restaurantId);
  }, []);

  const getDishDetails = () => {
    return (
      <div className="ml-2 col-6">
        <div><b>Dish Details</b></div>
        <div style={{ border: '1px solid lightgrey', borderRadius: '6px', padding: '20px' }}>
          <div className={'dish-name-section justify-content-start'}>
            <div className="d-flex flex-column">
              <div className={'mr-3 align-self-center'}>
                <img
                  src={dishes[currentPage]?.image?.url ? dishes[currentPage].image.url : require('assets/placeholder.svg')}
                  style={{
                    width: '200px',
                    height: '200px',
                  }}
                />
              </div>
              <div>
                <div className={'dish-details-group mb-3 mt-3'}>
                  <div className={'label'}>Dish ID</div>
                  <Link
                    to={`/dishes/${dishes[currentPage]?.objectId}`}
                    className={'detail'}
                  >
                    <u>{dishes[currentPage]?.objectId}</u>
                  </Link>
                </div>
                <div className={'dish-details-group mb-3'}>
                  <div className={'label'}>Dish Name</div>
                  <div className={'detail'}>{dishes[currentPage]?.name}</div>
                </div>
                <div className={'dish-details-group mb-3'}>
                  <div className={'label'}>Category</div>
                  <div className={'detail'}>{dishes[currentPage]?.category}</div>
                </div>
                <div className={'dish-details-group mb-3'}>
                  <div className={'label'}>Merchant ID</div>
                  <div className={'detail'}>{dishes[currentPage]?.restaurant?.objectId}</div>
                </div>
                {
                  <div className={'dish-details-group mb-3'}>
                    <div className={'label'}>Assigned Uva Category</div>
                    <div className={'detail'}>{dishes[currentPage]?.uvaCategory || '-'}</div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4 mb-2">
          <ActionButton
            text={'<< Prev'}
            active={currentPage !== 0}
            onClick={() => setCurrentPage(currentPage - 1)}
            isLoading={false}
            customClass={'btn-primary cursor-pointer'}
          />
          <ActionButton
            text={'Next >>'}
            active={true}
            onClick={() => setCurrentPage(currentPage + 1)}
            isLoading={false}
            customClass={'btn-primary cursor-pointer'}
          />
        </div>
      </div>
    );
  };

  const getActions = () => (
    <div className="d-flex flex-column">
      <ActionButton
        text={'+ Assign'}
        active={!!uvaCategoryId}
        onClick={() => assignUvaCategory()}
        isLoading={assignLoader}
        customClass={'btn-primary mt-4 mb-2 cursor-pointer'}
      />
    </div>
  );

  return (
    <div>
      {loader && <Loading />}
      <h3>{'Categorise Dishes'}</h3>
      <div className="m-4">
        <div className={''}>
          <label>Restaurant Id</label>
          <input
            value={restaurantId}
            onChange={e => setRestaurantId(e.target.value)}
            className={'form-control'}
          />
        </div>
        <div className=''>
          <label>Restaurant</label>
          <RestaurantAutoSuggest
            placeholder={'Search Restaurant'}
            onSuggestionSelect={suggestion=>setRestaurantId(suggestion.id)}
          />
        </div>
      </div>
      <div className='d-flex mr-4 mb-2 mt-4'>
        {getDishDetails()}
        <div className="col-6">
          <div><b>Choose Uva Category</b></div>
          <UvaCategoriesFlattenTree
            uvaCategoryId={uvaCategoryId}
            onChange={handleCategoryChange}
          />
          {getActions()}
        </div>

      </div>
    </div>
  );
};

export { CategoriseDishes };