import { Types } from 'actions/types';
import { IAuth } from 'interfaces/auth';
import { AnyAction } from 'redux';
import { getLoggedInUser } from 'services';

const initialState: IAuth.StateToProps = {
  error: false,
  loading: false,
  loggedInUser: getLoggedInUser(),
};

export function auth(
  state: IAuth.StateToProps = initialState,
  action: AnyAction,
) {
  switch (action.type) {
  // LOGIN
  case Types.LOGIN:
    return {
      ...state,
      error: false,
      loading: true,
    };
  case Types.LOGIN_SUCCESS:
    return {
      ...state,
      error: false,
      loading: false,
      loggedInUser: action.payload.loggedInUser,
    };
  case Types.LOGIN_FAILED:
    return {
      ...state,
      error: action.payload.message || true,
      loading: false,
      token: undefined,
    };

    // LOGOUT
  case Types.LOGOUT:
    return {
      ...state,
      error: false,
      loading: true,
    };
  case Types.LOGOUT_SUCCESS:
    return {
      ...state,
      error: false,
      loading: false,
      token: undefined,
      loggedInUser: undefined,
    };
  case Types.LOGOUT_FAILED:
    return {
      ...state,
      error: false,
      loading: false,
    };

  default:
    return state;
  }
}
