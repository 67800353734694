import React, { useEffect, useState } from 'react';
import './index.scss';
import { tableStyles } from '../../../../css/tableStyles';
import DataTable from 'react-data-table-component';
import { getPageLimit, updatePageLimit } from 'utils';
import { fetchAdminOrders } from 'services/api/order';
import { showError } from 'utils/notifications';
import { Loading } from 'components/loading/Loading';
import DriverOrderSummary from './driver-order-summary';
import TableLink from 'TableLink';
import { OrdersFilter } from 'pages/orders/orders-filter';

let filters: { [p: string]: any } = {
  page: 0,
};

let sort = {};

const DriverOrderHistory = props => {
  const [orders, setOrders] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const columns = [
    {
      name: 'Order#',
      selector: 'orderNumber',
      sortable: false,
    },
    {
      name: 'Order ID',
      selector: 'objectId',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/orders/${row.objectId}`}
          text={row.objectId}
          openInNewTab={true}
        />
      ),
    },
    {
      name: 'Delivery Date Time',
      selector: 'deliveredAt',
      sortable: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: false,
    },
    {
      name: 'Client Name',
      selector: 'clientName',
      sortable: false,
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: false,
      format: row => `$${row.total}`,
    },
  ];
  useEffect(() => {
    fetchDriverOrders();
  }, []);

  const fetchDriverOrders = async () => {
    setLoading(true);
    try {
      const response = await fetchAdminOrders({
        filter: {
          ...filters,
          isDriverDetailPage: true,
          driverId: props.objectId,
        },
        limit: getPageLimit(),
        page: filters.page,
        sort,
      });
      setOrders(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    fetchDriverOrders();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const handleFilterSubmit = (updatedFilter: Record<string, any>) => {
    filters = { ...filters, ...updatedFilter, page: 0 };
    fetchDriverOrders();
  };

  const onSort = (column: Record<string, any>, sortDirection: string) => {
    sort = {
      ...sort,
      [column.selector]: sortDirection,
    };
    fetchDriverOrders();
  };

  return (
    <div className="driver-orders">
      <div className="row">
        <div className="col-12 order-table">
          <span className="text-muted font-weight-bold">Driver Order History</span>
          <DriverOrderSummary {...props} />
          <div className={'mt-4 mb-2'}>
            <OrdersFilter
              showOrderNumberFilter
              showUserNameFilter
              showUserEmailFilter
              showUserPhoneFilter
              showRestaurantFilter
              location={props.location}
              onFilterSubmit={handleFilterSubmit}
            />
          </div>
          <DataTable
            keyField="objectId"
            columns={columns}
            data={orders.items}
            highlightOnHover={true}
            customStyles={tableStyles}
            pagination={true}
            paginationServer={true}
            paginationDefaultPage={(filters.page || 0) + 1}
            paginationTotalRows={orders.total}
            progressPending={loading}
            progressComponent={<Loading type="mini" />}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onSort={onSort}
          />
        </div>
      </div>
    </div>
  );
};

export default DriverOrderHistory;
