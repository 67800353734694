import React from 'react';
import { IPseudoLinkProps } from 'interfaces/pseudoLink';
import styles from './index.module.scss';

const PseudoLink = (props: IPseudoLinkProps) => {
  const { onClick, text } = props;

  return (
    <span className={styles['pseudo__link']} onClick={onClick}>{text}</span>
  );
};

export default PseudoLink;