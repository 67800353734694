import Parse, { cloudFunction } from 'services/parse';
import { DATE_FORMATS, formatDate } from 'utils';

export async function uploadFile (base64, type): Promise<string> {
  const file = new Parse.File('document', { base64 }, type);
  await file.save();
  return file.url();
}

export async function uploadParseFile(base64): Promise<Parse.File> {
  const file = new Parse.File('document', { base64 });
  await file.save();
  return file;
}

export const getFleetOrdersReport = (data: Record<string, any>) => {
  return cloudFunction('getFleetOrdersReport', data);
};

export const fetchRefunds = async (data: Record<string, any>) => {
  const response = await cloudFunction('fetchRefunds', data);
  return {
    ...response,
    items: response.items.map(item => ({
      ...item,
      refundedAt: formatDate(item.refundedAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
    })),
  };
};
