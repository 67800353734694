import React, { useState } from 'react';
import _ from 'lodash';
import ReportsFilter from 'components/ReportsFilter';
import { Loading } from 'components/loading/Loading';
import DataTable from 'react-data-table-component';
import { getDriverPaymentSummary } from 'services/api/reports';
import moment from 'moment';
import { showError } from 'utils/notifications';
import ExportCSVButton from 'components/ExportCSVButton';
import DataTableFilter from 'components/DataTableFIlter';
import { getEmailArr } from '../../utils';
import { tableStyles } from 'css/tableStyles';
import EmptySectionMessage from 'components/message/EmptySectionMessage';

const columns = [
  {
    name: 'Driver ID',
    selector: 'objectId',
    sortable: false,
    width: '150px',
  },
  {
    name: 'Driver Name',
    selector: 'full_name',
    sortable: false,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: false,
  },
  {
    name: 'Account number',
    selector: 'accountNumber',
    sortable: false,
    wrap: true,
  },
  {
    name: 'Routing Number',
    selector: 'routingNumber',
    sortable: false,
    wrap: true,
  },
  {
    name: 'SSN',
    selector: 'ssn',
    sortable: false,
    wrap: true,
  },
  {
    name: 'Address',
    selector: 'city',
    sortable: false,
    wrap: true,
  },
  {
    name: 'Total Orders',
    selector: 'totalOrders',
    sortable: false,
  },
  {
    name: 'Earnings',
    selector: 'earnings',
    sortable: false,
    format: row => `$ ${row.earnings}`,
  },
  {
    name: 'UVA Bonus',
    selector: 'uvaBonus',
    sortable: false,
    format: row => `$ ${row.uvaBonus}`,
  },
  {
    name: 'Distance Bonus',
    selector: 'distanceBonus',
    sortable: false,
    format: row => `$ ${row.distanceBonus}`,
  },
  {
    name: 'Rejection Bonus',
    selector: 'rejectionBonus',
    sortable: false,
    format: row => `$ ${row.rejectionBonus}`,
  },
  {
    name: 'Merchant Distance Bonus',
    selector: 'merchantDistanceBonus',
    sortable: false,
    format: row => `$ ${_.round(row.merchantDistanceBonus, 2)}`,
  },
  {
    name: 'Tip',
    selector: 'tip',
    sortable: false,
    format: row => `$ ${row.tip}`,
  },
  {
    name: 'Toll',
    selector: 'toll',
    sortable: false,
    format: row => `$ ${row.toll}`,
  },
  {
    name: 'Total Routes',
    selector: 'totalRoutes',
    sortable: false,
    format: row => `${row.totalRoutes}`,
  },
  {
    name: 'Route Earnings',
    selector: 'totalRouteEarnings',
    sortable: false,
    format: row => `$ ${row.totalRouteEarnings}`,
  },
  {
    name: 'Route Bonus',
    selector: 'routeBonus',
    sortable: false,
    format: row => `$ ${row.routeBonus}`,
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: false,
    format: row => `$ ${row.total}`,
  },
];

const columnsForCSV = [
  {
    value: 'objectId',
    label: 'Driver ID',
  },
  {
    value: 'full_name',
    label: 'Driver Name',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'accountNumber',
    label: 'Account number',
  },
  {
    value: 'routingNumber',
    label: 'Routing Number',
  },
  {
    value: 'ssn',
    label: 'SSN',
  },
  {
    value: 'city',
    label: 'Address',
  },
  {
    value: 'totalOrders',
    label: 'Total Orders',
  },
  {
    value: 'uvaBonus',
    label: 'UVA Bonus',
  },
  {
    value: 'distanceBonus',
    label: 'Distance Bonus',
  },
  {
    value: 'rejectionBonus',
    label: 'Rejection Bonus',
  },
  {
    value: 'merchantDistanceBonus',
    label: 'Merchant Distance Bonus',
  },
  {
    value: 'tip',
    label: 'Tip',
  },
  {
    value: 'toll',
    label: 'Toll',
  },
  {
    value: 'earnings',
    label: 'Earnings',
  },
  {
    value: 'total',
    label: 'Total',
  },
];

const DriverPaymentReport= () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null | string>(moment().subtract(1, 'days').toDate());
  const [endDate, setEndDate] = useState<Date | null | string>(moment().toDate());
  const [emails, setEmails] = useState<string>('');
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [data, setData] = useState<Array<Record<string, any>>>([]);
  const [filterText, setFilterText] = React.useState('');
  const [apiCalled, setAPICalled] = useState<boolean>(false);

  const fetchDriverPaymentReport = async () => {
    if (!apiCalled) setAPICalled(true);
    setLoading(true);
    try {
      const response = await getDriverPaymentSummary(startDate, endDate, getEmailArr(emails), sendEmail);
      setData(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const getDataTable = () => {
    if (loading) return <Loading />;

    if (data.length) {
      const exportCSVButton = <ExportCSVButton data={data} columnsForCSV={columnsForCSV} fileName={'Driver Payment Report'} />;
      const dataFilterInput = <DataTableFilter filterText={filterText} setFilterText={setFilterText} placeholder={'Filter by Driver Name'} />;
      const filteredData = data.filter(item => item.full_name && item.full_name.toLowerCase().includes(filterText.toLowerCase()));

      return (
        <DataTable
          title="Reports"
          keyField="objectId"
          columns={columns}
          data={filteredData}
          customStyles={tableStyles}
          pagination={false}
          striped={true}
          actions={[dataFilterInput, exportCSVButton]}
        />
      );
    } else {
      return apiCalled ? <EmptySectionMessage message={'No records found'} /> : null;
    }
  };

  return (
    <div className='report-container'>
      <ReportsFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        emails={emails}
        setEmails={setEmails}
        sendEmail={sendEmail}
        setSendEmail={setSendEmail}
        handleSubmit={fetchDriverPaymentReport}
        showEmailInput={true}
      />
      {getDataTable()}
    </div>
  );
};

export default DriverPaymentReport;
