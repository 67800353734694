export const Types = {
  OFFLINE: 'OFFLINE',
  ONLINE: 'ONLINE',

  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',

  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILED: 'LOGOUT_FAILED',

  FETCH_LEADS: 'FETCH_LEADS',
  FETCH_LEADS_SUCCESS: 'FETCH_LEADS_SUCCESS',
  FETCH_LEADS_FAILED: 'FETCH_LEADS_FAILED',

  FETCH_LEAD_DETAIL: 'FETCH_LEAD_DETAIL',
  FETCH_LEAD_DETAIL_SUCCESS: 'FETCH_LEAD_DETAIL_SUCCESS',
  FETCH_LEAD_DETAIL_FAILED: 'FETCH_LEAD_DETAIL_FAILED',

  SIDE_MENU_OPEN: 'SIDE_MENU_OPEN',
  SIDE_MENU_COLLAPSE: 'SIDE_MENU_COLLAPSE',
};
