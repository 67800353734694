import ActionButton from 'components/ActionButton/ActionButton';
import React, { useState } from 'react';
import { addCategory, editCategory } from 'services/api/restaurant';
import { showError, showSuccess } from 'utils/notifications';
import './index.scss';

const AddCategoryModal = props => {
  const {
    restaurantId,
    toggleModal,
    onSuccess,
    onEditSuccess,
    categoryCurrentName,
  } = props;

  const [category, setCategory] = useState<string>(categoryCurrentName || '');
  const [loading, setLoading] = useState<boolean>(false);

  const addCategoryFn = async () => {
    setLoading(true);
    try {
      const response = await addCategory({ category, restaurantId });
      onSuccess(response.get('categories'));
      toggleModal();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const editCategoryFn = async () => {
    setLoading(true);
    try {
      await editCategory({
        currentCategory: categoryCurrentName,
        newCategory: category,
        restaurantId,
      });
      showSuccess('Category Edited successfully');
      onEditSuccess();
      toggleModal();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={'add-category-modal-main-container'}>
      <div className={'add-category-modal-container'}>
        <div className={'add-category-modal-header'}>
          <div className={'heading'}>{`${categoryCurrentName ? 'Edit' : 'Add'} Category`}</div>
          <div className={'close-modal'}>
            <i className={'fa fa-times'} onClick={toggleModal} />
          </div>
        </div>
        <div className={'add-category-modal-body'}>
          <div className={'add-category-info-group'}>
            <div className={'label'}>Name</div>
            <div className={'field'}>
              <input
                className={'form-control'}
                value={category}
                onChange={e => setCategory(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={'add-category-modal-footer'}>
          <ActionButton
            type={'button'}
            text={'Cancel'}
            active={!loading}
            isLoading={false}
            onClick={toggleModal}
          />
          <ActionButton
            type={'submit'}
            text={`${categoryCurrentName ? 'Edit' : 'Add'} Category`}
            active={!!category}
            isLoading={loading}
            onClick={categoryCurrentName ? editCategoryFn : addCategoryFn}
            customClass={'btn-primary'}
          />
        </div>
      </div>
    </div>
  );
};

export default AddCategoryModal;