import ToggleSwitch from 'components/ToggleSwitch';
import React, { useEffect, useState } from 'react';
import { updateUserDetail } from 'services';
import { showError, showSuccess } from 'utils';

const InternalUser = (props) => {
  const { userDetails: { objectId: userId = '', isInternalUser: isInternalUserFromProps = false } = {}} = props;
  const [loading, setLoading] = useState(false);
  const [isInternalUser, setIsInternalUser] = useState(isInternalUserFromProps);

  useEffect(() => {
    setIsInternalUser(isInternalUserFromProps);
  }, [isInternalUserFromProps]);

  const toggleInternalUser = async(e) => {
    try {
      setLoading(true);
      setIsInternalUser(!isInternalUser);
      await updateUserDetail(userId, {
        isInternalUser: !isInternalUser,
      });
      showSuccess('User updated successfully.');
    } catch (e) {
      setIsInternalUser(!isInternalUser);
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className='label'>Internal user</div>
      <ToggleSwitch
        switchLabel="" 
        disabled={loading} 
        checked={isInternalUser} 
        onChange={toggleInternalUser} 
      />
    </>
  );
};

export default InternalUser;