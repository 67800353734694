import { combineReducers } from 'redux';
import { online } from './online';
import { auth } from './auth';
import { lead } from './lead';
import { menu } from './menu';

const rootReducer = combineReducers({
  online,
  auth,
  lead,
  menu,
});

export { rootReducer };
