import RenderInfo from 'components/RenderInfo';
import React from 'react';
import styles from './index.module.scss';

const DeliveryChargesData = ({ details }) => {
  if (!details?.deliveryChargeData) return null;

  const { deliveryChargeData: { 
    baseCharges = 0, 
    actualBaseDeliveryCharges = 0, 
    actualDeliverycharges = 0,
    waivedDeliveryCharges = 0,
    distanceCharges = 0,
    durationCharges = 0,
    gasCharges = 0,
    waivedBaseChargesForPrimeSubscription = 0,
    merchantOptInForFreePrimeDelivery = 0,
    total = 0,
  } = {}} = details;

  return (
    <div className={styles['section-container']}>
      <div className={styles['section-header']}>
        <div className={styles['heading']}>Delivery charges</div>
      </div>
      <div className={styles['basic-info']}>
        <div className={styles['info']}>
          <RenderInfo heading="Base charges" value={baseCharges} type="currency" />
          <RenderInfo heading="Actual base delivery charges" value={actualBaseDeliveryCharges} type="currency" />
          <RenderInfo heading="Actual delivery charges" value={actualDeliverycharges} type="currency" />
        </div>
        <div className={styles['info']}>
          <RenderInfo heading="Wavied delivery charges" value={waivedDeliveryCharges} type="currency" />
          <RenderInfo heading="Distance charges" value={distanceCharges} type="currency" />
          <RenderInfo heading="Duration charges" value={durationCharges} type="currency" />
        </div>
        <div className={styles['info']}>
          <RenderInfo heading="Gas charges" value={gasCharges} type="currency" />
          <RenderInfo heading="Wavied base charges for prime subscription" value={waivedBaseChargesForPrimeSubscription} type="currency" />
        </div>
        <div className={styles['info']}>
          <RenderInfo heading="Merchant opt in for free prime delivery" value={merchantOptInForFreePrimeDelivery} type="currency" />
          <RenderInfo heading="Total" value={total} type="currency" />
        </div>
      </div>
    </div>
  );
};

export default DeliveryChargesData;