import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import qs from 'query-string';
import ActionButton from 'components/ActionButton/ActionButton';
import { searchUserByName } from 'services';
import SubscriptionTypeDropdown from 'components/SubscriptionTypeDropdown';
import NameFilterAutosuggest from 'components/NameFilterAutoSuggest';
import { STRIPE_USER_SUBSCRIPTION_STATUSES } from 'utils';

const Filter = props => {
  const [subscriptionId, setSubscriptionId] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');

  useEffect(() => {
    const {
      subscriptionId,
      userId,
      status,
      userEmail,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
    setSubscriptionId(subscriptionId?.toString() || '');
    setUserId(userId?.toString() || '');
    setStatus(status?.toString() || '');
    setUserEmail(userEmail?.toString() || '');
  }, []);


  const resetFilter = () => {
    setSubscriptionId('');
    setUserId('');
    setStatus('');
    setUserEmail('');
    const filterData = {
      subscriptionId: '',
      userId: '',
      status: '',
      email: '',
    };
    props.onSubmit(filterData);
  };

  const handleSubmit = () => {
    const filterData: Record<string, any> = {
      subscriptionId,
      userId,
      userEmail,
      status,
    };
    props.onSubmit(filterData);
  };

  const getStatuses = () => {
    return (
      <div className={styles['input-group']}>
        <div className={styles['label']}>Status</div>
        <select value={status} onChange={e => setStatus(e.target.value)} className={'form-control'}>
          <option hidden={!!status} value={'default'}>Select a status</option>
          {Object.keys(STRIPE_USER_SUBSCRIPTION_STATUSES)?.map((status) => {
            return <option value={STRIPE_USER_SUBSCRIPTION_STATUSES[status]}>{status}</option>;
          })}
        </select>
      </div>
    );
  };

  return (
    <form className={styles['filter-container']}>
      <div className={styles['input-group']}>
        <div className={styles['label']}>Subscription Type</div>
        <SubscriptionTypeDropdown
          value={subscriptionId}
          onChange={setSubscriptionId}
        />
      </div>
      <div className={styles['autosuggest-group']}>
        <div className={styles['label']}>User</div>
        <div className={styles['autosuggest-input']}>
          <NameFilterAutosuggest
            entityId={userId}
            placeholder={'User Name'}
            onSuggestionSelected={setUserId}
            fetchSuggestions={searchUserByName}
          />
        </div>
      </div>
      <div className={styles['input-group']}>
        <div className={styles['label']}>User ID</div>
        <input
          className={'form-control'}
          value={userId}
          onChange={e => setUserId(e.target.value)}
        />
      </div>
      <div className={styles['input-group']}>
        <div className={styles['label']}>User Email</div>
        <input
          className={'form-control'}
          value={userEmail}
          onChange={e => setUserEmail(e.target.value)}
        />
      </div>
      {getStatuses()}
      <div className={styles['buttons-container']}>
        <ActionButton
          text={'Submit'}
          active={true}
          isLoading={false}
          onClick={handleSubmit}
        />
        <ActionButton
          text={'Reset'}
          active={true}
          isLoading={false}
          onClick={resetFilter}
        />
      </div>
    </form>
  );
};

export default Filter;