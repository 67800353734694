import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { fetchDisputeStatuses } from 'services/api/disputes';

const DisputeStatuses = props => {
  const [statuses, setStatuses] = useState([{ id: '', name: '' }]);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    status,
    onChange,
    disabled,
    onKeyDown = true,
    isFilter,
  } = props;

  const formatStatuses = (statuses) => {
    return Object.keys(statuses)?.map((status) => {
      return {
        key: statuses[status],
        value: statuses[status],
      };
    });
  };

  useEffect(() => {
    (async function fetchDisputeStatusesFn() {
      setLoading(true);
      const statuses = await fetchDisputeStatuses();
      const formattedStatuses = formatStatuses(statuses) as any;
      setStatuses(formattedStatuses);
      setLoading(false);
    })();
  }, []);

  const getStatuses = () => {
    return statuses.map((status: any) => {
      return <option key={status.key} value={status.value}>{status.value}</option>;
    });
  };

  return (
    <Form.Control onKeyDown={onKeyDown && onKeyDown} as="select" value={status} onChange={onChange} disabled={disabled || loading}>
      <option key={'defaultValue'}  value={isFilter ? '' : undefined} >{isFilter ? 'ALL' : 'Select Dispute Status'}</option>
      {getStatuses()}
    </Form.Control >
  );
};

export default DisputeStatuses;