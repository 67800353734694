export const CONSTANTS = {
  APP_NAME: 'Uva-Admin',
  AUTH_TOKEN: 'UVA-ADMIN-TOKEN',
};

let PAGE_LIMIT = 10;
export const getPageLimit = () => {
  return PAGE_LIMIT;
};

export const updatePageLimit = (pageLimit: number) => {
  PAGE_LIMIT = pageLimit;
};

export const USER_ROLES = {
  DISPATCHER: 'dispatcher',
  ADMIN: 'admin',
  RESTAURANT: 'restaurant',
  AGENT: 'agent',
  DRIVER: 'driver',
  FINANCE: 'finance',
  SALES: 'sales',
  SHOPPER: 'shopper',
};

export const MENU_ITEMS = [
  {
    url: '/leads',
    iconClass: 'fa-archive',
    title: 'Leads',
    roles: [USER_ROLES.AGENT],
  },
  {
    url: '/dish-imports',
    iconClass: 'fa-upload',
    title: 'Import Dishes',
    roles: [USER_ROLES.SALES],
  },
  {
    url: '/notifications-list',
    iconClass: 'fa-bell',
    title: 'Send Notifications',
    roles: [USER_ROLES.DISPATCHER],
  },
  {
    url: '/copy-restaurant-menu',
    iconClass: 'fa-files-o',
    title: 'Copy Restaurant Menu',
    roles: [USER_ROLES.SALES],
  },
  {
    url: '/orders',
    iconClass: 'fa-files-o',
    title: 'Orders',
    roles: [USER_ROLES.DISPATCHER, USER_ROLES.SALES, USER_ROLES.FINANCE],
  },
  {
    url: '/duplicate-orders',
    iconClass: 'fa-files-o',
    title: 'Duplicate Orders',
    roles: [USER_ROLES.DISPATCHER, USER_ROLES.SALES, USER_ROLES.FINANCE],
  },
  {
    url: '/order-reviews',
    iconClass: 'fa-list-ul',
    title: 'Order Reviews',
    roles: [USER_ROLES.DISPATCHER, USER_ROLES.SALES],
  },
  {
    url: '/deferred-orders',
    iconClass: 'fa-files-o',
    title: 'Deferred Orders',
    roles: [USER_ROLES.DISPATCHER, USER_ROLES.SALES, USER_ROLES.FINANCE],
  },
  {
    url: '/user-carts',
    iconClass: 'fa-files-o',
    title: 'User Carts',
    roles: [USER_ROLES.DISPATCHER, USER_ROLES.SALES, USER_ROLES.FINANCE],
  },
  {
    url: '/drivers',
    iconClass: 'fa-truck',
    title: 'Drivers',
    roles: [USER_ROLES.DISPATCHER, USER_ROLES.SALES, USER_ROLES.FINANCE],
  },
  {
    iconClass: 'fa-list-alt',
    title: 'Reports',
    roles: [USER_ROLES.SALES, USER_ROLES.FINANCE],
    hasChildren: true,
    children: [
      {
        url: '/reports/bonus',
        title: 'Bonus Report',
        roles: [],
      },
      {
        url: '/reports/discount-redemption',
        title: 'Discount Redemption',
        roles: [],
      },
      {
        url: '/reports/drivers-data',
        title: 'Drivers Data',
        roles: [],
      },
      {
        url: '/reports/driver-events',
        title: 'Driver Events',
        roles: [],
      },
      {
        url: '/reports/driver-note-report',
        title: 'Driver Notes Report',
        roles: [],
      },
      {
        url: '/reports/driver-payment',
        title: 'Driver Payment Summary',
        roles: [],
      },
      {
        url: '/reports/fleet-orders',
        title: 'Fleet Orders Report',
        roles: [],
      },
      {
        url: '/reports/fleet-payment-summary',
        title: 'Fleet Payment Summary',
        roles: [],
      },
      {
        url: '/reports/leads',
        title: 'Leads Report',
        roles: [],
      },
      {
        url: '/reports/order-rejection-summary',
        title: 'Order Rejection Summary',
        roles: [],
      },
      {
        url: '/reports/review',
        title: 'Order Review Report',
        roles: [],
      },
      {
        url: '/reports/payment',
        title: 'Restaurant Payment Summary',
        roles: [],
      },
      {
        url: '/reports/revenue-overview',
        title: 'Revenue Overview',
        roles: [],
      },
      {
        url: '/reports/tax-summary',
        title: 'Tax Summary',
        roles: [],
      },
      {
        url: '/reports/prime-subscription-discount-report',
        title: 'Prime Subscription Discount Report',
        roles: [],
      },
    ],
  },
  {
    url: '/users',
    iconClass: 'fa-users',
    title: 'Users',
    roles: [USER_ROLES.DISPATCHER, USER_ROLES.SALES, USER_ROLES.FINANCE],
  },
  {
    url: '/tags',
    iconClass: 'fa-tags',
    title: 'Tags',
    roles: [USER_ROLES.SALES, USER_ROLES.ADMIN],
  },
  {
    url: '/scrappers',
    iconClass: 'fa-list-ul',
    title: 'Scrappers',
    roles: [USER_ROLES.SALES, USER_ROLES.FINANCE],
  },
  {
    url: '/stripe-products',
    iconClass: 'fa-list-ul',
    title: 'Stripe Products',
    roles: [],
  },
  {
    url: '/shopify',
    iconClass: 'fa-list-ul',
    title: 'Shopify',
    roles: [],
    hasChildren: true,
    children: [
      {
        url: '/shopify/orders',
        title: 'Orders',
        roles: [],
      },
      {
        url: '/shopify/merchants',
        title: 'Merchants',
        roles: [],
      },
      {
        url: '/shopify/invoices',
        title: 'Invoices',
        roles: [],
      },
    ],
  },
  {
    url: '/disputes',
    iconClass: 'fa-list-ul',
    title: 'Disputes',
    roles: [],
  },
  {
    url: '/routes',
    iconClass: 'fa-map-marker',
    title: 'Routes',
    roles: [USER_ROLES.DISPATCHER],
  },
  {
    url: '/refunds',
    iconClass: 'fa-list-ul',
    title: 'Refunds',
    roles: [],
    hideForEmptyRoles: true,
  },
  {
    url: '/',
    iconClass: 'fa-list-ul',
    title: 'Restaurants',
    roles: [],
    hideForEmptyRoles: false,
  },
  {
    url: '/uva-categories',
    iconClass: 'fa-list-ul',
    title: 'Uva Categories',
    roles: [],
    hideForEmptyRoles: false,
  },
  {
    url: '/dishes',
    iconClass: 'fa-list-ul',
    title: 'Dishes',
    roles: [USER_ROLES.FINANCE, USER_ROLES.SALES, USER_ROLES.DISPATCHER],
    hideForEmptyRoles: false,
  },
  {
    url: '/dish-collection',
    iconClass: 'fa-list-ul',
    title: 'Dish Collections',
    roles: [USER_ROLES.FINANCE, USER_ROLES.SALES, USER_ROLES.DISPATCHER],
    hideForEmptyRoles: false,
  },
  {
    url: '/automatic-merchant-collection',
    iconClass: 'fa-list-ul',
    title: 'Automatic Merchant Collections',
    roles: [USER_ROLES.FINANCE, USER_ROLES.SALES, USER_ROLES.DISPATCHER],
    hideForEmptyRoles: false,
  },
  {
    url: '/discounts',
    iconClass: 'fa-list-ul',
    title: 'Discounts',
    roles: [USER_ROLES.FINANCE, USER_ROLES.SALES, USER_ROLES.DISPATCHER],
    hideForEmptyRoles: false,
  },
  {
    url: '/categorise-dishes',
    iconClass: 'fa-list-ul',
    title: 'Categorise Dishes',
    roles: [],
    hideForEmptyRoles: false,
  },
  {
    url: '/restaurant-stats',
    iconClass: 'fa-list-ul',
    title: 'Restaurant Stats',
    roles: [USER_ROLES.DISPATCHER],
    hideForEmptyRoles: false,
  },
  {
    url: '/config',
    iconClass: 'fa-cog',
    title: 'Config',
    roles: [],
    hideForEmptyRoles: false,
  },
  {
    url: '/cron-jobs',
    iconClass: 'fa-list',
    title: 'Cron Jobs',
    roles: [],
    hideForEmptyRoles: false,
  },
  {
    url: '/toll-crud',
    iconClass: 'fa-list',
    title: 'Tolls',
    roles: [],
    hideForEmptyRoles: false,
  },
  {
    url: '/cloud-requests',
    iconClass: 'fa-mixcloud',
    title: 'Cloud Requests',
    roles: [],
    hideForEmptyRoles: false,
  },
  {
    url: '/tax-summary',
    iconClass: 'fa-list-alt',
    title: 'Tax Summary',
    roles: [],
    hideForEmptyRoles: false,
  },
  {
    url: '/restaurant-collection',
    iconClass: 'fa-list-alt',
    title: 'Restaurant Collection',
    roles: [],
  },
  {
    url: '/user-subscriptions',
    title: 'User Subscriptions',
    roles: [],
    hasChildren: false,
  },
];

export const AVAILABLE_DOCUMENT_LIST = [
  {
    code: 'car_insurance',
    displayName: 'Car Insurance',
  },
  {
    code: 'driver_license',
    displayName: 'Driving License',
  },
  {
    code: 'car_license',
    displayName: 'Car License',
  },
  {
    code: 'good_standing',
    displayName: 'Good Standing',
  },
  {
    code: 'profile_picture',
    displayName: 'Profile Picture',
  },
  {
    code: 'agreement',
    displayName: 'Agreement',
  },
  {
    code: 'general_authorization_certificate',
    displayName: 'Certificado de Autorización General',
  },
  {
    code: 'vehicle_authorization_certificate',
    displayName: 'Certificado de Autorización de Vehículo',
  },
];

export const UVA_FLEET_AVAILABLE_DOCUMENT_LIST = [
  {
    code: 'merchantAgreement',
    displayName: 'Merchant Agreement',
  },
];

export const OTHER_DOCUMENT_TYPE = {
  code: 'other',
  displayName: 'Other',
};

export const LEAD_ACTIVITIES_LIST = {
  CREATED: 'created',
  WELCOME_EMAIL_SENT: 'interestedEmailSent',
  ASSIGNED_TO_AGENT: 'assigned',
  CONTACTED: 'contacted',
  SIGNED_UP_AS_USER: 'signedUp',
  DOCUMENT_UPLOADED: 'documentUploaded',
  DOCUMENT_APPROVED: 'documentApproved',
  DOCUMENT_UN_APPROVED: 'documentUnApproved',
  PROMOTED_TO_DRIVER: 'promotedToDriver',
  MARKED_AS_INTERESTED: 'markedAsInterested',
  MARKED_AS_NOT_INTERESTED: 'markedAsNotInterested',
  MARKED_AS_DECLINED: 'markedAsDeclined',
  MARKED_AS_NOT_DECLINED: 'markedAsNotDeclined',
};

export const DATE_FORMATS = {
  MM_DD_YYYY_HH_MM_A: 'MM/DD/YYYY hh:mm a',
  MM_DD_YYYY_HH_MM_SS_A: 'MM/DD/YYYY hh:mm:ss a',
  MMM_DD_YYYY: 'll',
  H_MM_P: 'h:mm a',
  HH_MM_P: 'hh:mm a',
  YYYY: 'YYYY',
  MMM_YYYY: 'MMM, YYYY',
  MM_DD_YYYY: 'MM/DD/YYYY',
  YYYY_MM_DD: 'YYYY-MM-DD',
  MM_DD_YYYY__EE_HH_MM_A: 'MM/dd/yyyy  EE hh:mm a',
};

export const phoneRegEx = /^$|^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const REFUND_TYPES = {
  byItem: 'byItem',
  BY_ITEM: 'BY_ITEM',
  full: 'full',
  FULL: 'FULL',
  partial: 'partial',
  PARTIAL: 'PARTIAL',
  DELIVERY: 'DELIVERY',
};

export const RESTAURANT_PAYMENT_REPORT_TYPE = {
  DEPOSIT_SUMMARY: 'depositSummary',
  RESTAURANT_BANK_STATEMENT: 'restaurantBankStatement',
};

export const MESSAGES = {
  DRIVER_UPDATE_SUCCESS: 'Driver updated successfully',
};

export const STRIPE_PRODUCT_TYPE = [
  { value: 'prime', label: 'Prime Subscription' },
];

export const SCRAPPER_PLATFORM = {
  SHOPIFY: 'shopify',
  CSV: 'csv',
  JSON: 'json',
  WEB: 'web',
  SQUARE_SPACE: 'square_space',
  CUSTOM_SHOPIFY: 'custom_shopify',
};

export const ORDER_STATUS = {
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  DELIVERED: 'DELIVERED',
  COOKING: 'COOKING',
  REFUNDPARTIAL: 'REFUNDPARTIAL',
  PICKEDUP: 'PICKEDUP',
  FINISHED: 'FINISHED',
  REFUND: 'REFUND',
  RECEIVED: 'RECEIVED',
  REVIEW: 'REVIEW',
  CANCELLED: 'CANCELLED',
  DEFERRED: 'DEFERRED',
};

export const ROUTE_ACTIVITY_TYPE = {
  START: 'start',
  PICKUP_SHIPMENT: 'pickupShipment',
  DELIVER_SHIPMENT: 'deliverShipment',
};

export const ROUTE_STATUS = {
  PENDING: 'PENDING',
  ASSIGNED: 'ASSIGNED',
  DELIVERED: 'DELIVERED',
  PARTIALLY_DELIVERED: 'PARTIALLY_DELIVERED',
};

export const RESTAURANT_DETAILS_TYPE = {
  OVERVIEW: {
    key: 'restaurant-info',
    label: 'Restaurant Info',
  },
  MENU: {
    key: 'menu',
    label: 'Menu',
  },
  CATEGORIES: {
    key: 'categories',
    label: 'Categories',
  },
  ORDERS: {
    key: 'orders',
    label: 'Orders',
  },
  DRIVERS: {
    key: 'drivers',
    label: 'Blacklisted Drivers',
  },
};

export const DAYS_NUMBER_TO_NAME = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
  'everyday': 'Everyday',
};

export const RESTAURANT_TIMINGS_TYPE = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
};

export const ACTION_ICON_TYPES = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
};

export const ENTITY_CLASS_MAPPINGS = [
  {
    key: 'Discount',
    dropdownValue: 'Discount',
    routeValue: '',
  },
  {
    key: 'Redemption',
    dropdownValue: 'Redemption',
    routeValue: '',
  },
  {
    key: 'Order',
    dropdownValue: 'Order',
    routeValue: 'orders',
  },
  {
    key: 'Restaurant',
    dropdownValue: 'Restaurant',
    routeValue: 'restaurants',
  },
  {
    key: '_User',
    dropdownValue: 'User',
    routeValue: 'users',
  },
  {
    key: 'Dish',
    dropdownValue: 'Dish',
    routeValue: 'dishes',
  },
  {
    key: 'DishVariant',
    dropdownValue: 'Dish Variant',
    routeValue: '',
  },
  {
    key: '_Installation',
    dropdownValue: 'Installation',
    routeValue: '',
  },
  {
    key: 'OrderUpdate',
    dropdownValue: 'Order Update',
    routeValue: '',
  },
  {
    key: 'Like',
    dropdownValue: 'Like',
    routeValue: '',
  },
  {
    key: 'Scrapper',
    dropdownValue: 'Scrapper',
    routeValue: 'scrappers',
  },
  {
    key: 'Clothing_Product',
    dropdownValue: 'Clothing Product',
    routeValue: '',
  },
  {
    key: 'ConvenienceStore_Product',
    dropdownValue: 'Convenience Store Product',
    routeValue: '',
  },
  {
    key: 'Cart',
    dropdownValue: 'Cart',
    routeValue: 'Cat',
  },
  {
    key: 'Transaction',
    dropdownValue: 'Transaction',
    routeValue: '',
  },
  {
    key: 'Refund',
    dropdownValue: 'Refund',
    routeValue: '',
  },
  {
    key: 'StripeEvent',
    dropdownValue: 'Stripe Event',
    routeValue: '',
  },
  {
    key: 'Lead',
    dropdownValue: 'Lead',
    routeValue: 'leads',
  },
  {
    key: 'Document',
    dropdownValue: 'Document',
    routeValue: '',
  },
  {
    key: 'OrderLog',
    dropdownValue: 'Order Log',
    routeValue: '',
  },
  {
    key: 'AsyncJob',
    dropdownValue: 'Async Job',
    routeValue: '',
  },
  {
    key: 'CloudRequest',
    dropdownValue: 'Cloud Request',
    routeValue: '',
  },
  {
    key: 'BannerPromo',
    dropdownValue: 'Banner Promo',
    routeValue: '',
  },
  {
    key: 'BannerPromoDriver',
    dropdownValue: 'Banner Promo Driver',
    routeValue: '',
  },
  {
    key: 'Event',
    dropdownValue: 'Event',
    routeValue: '',
  },
  {
    key: 'Agent',
    dropdownValue: 'Agent',
    routeValue: '',
  },
  {
    key: 'Driver',
    dropdownValue: 'Driver',
    routeValue: 'drivers',
  },
  {
    key: '_Session',
    dropdownValue: 'Session',
    routeValue: '',
  },
  {
    key: 'RestaurantTag',
    dropdownValue: 'Restaurant Tag',
    routeValue: '',
  },
  {
    key: 'DishTag',
    dropdownValue: 'Dish Tag',
    routeValue: '',
  },
  {
    key: 'Verticals',
    dropdownValue: 'Verticals',
    routeValue: '',
  },
  {
    key: 'MerchantWebhooks',
    dropdownValue: 'Merchant Webhooks',
    routeValue: '',
  },
  {
    key: 'VehicleType',
    dropdownValue: 'Vehicle Type',
    routeValue: '',
  },
  {
    key: 'DeferredOrder',
    dropdownValue: 'Deferred Order',
    routeValue: '',
  },
  {
    key: 'Review',
    dropdownValue: 'Review',
    routeValue: '',
  },
  {
    key: 'RouteOptimizationProblem',
    dropdownValue: 'Route Optimization Problem',
    routeValue: '',
  },
  {
    key: 'FleetRoute',
    dropdownValue: 'Fleet Route',
    routeValue: '',
  },
  {
    key: 'FleetRouteUpdate',
    dropdownValue: 'Fleet Route Update',
    routeValue: '',
  },
  {
    key: 'VehicleMake',
    dropdownValue: 'Vehicle Make',
    routeValue: '',
  },
  {
    key: 'Invitation',
    dropdownValue: 'Invitation',
    routeValue: '',
  },
  {
    key: 'ImportOrderJob',
    dropdownValue: 'Import Order Job',
    routeValue: '',
  },
  {
    key: 'Municipalities',
    dropdownValue: 'Municipalities',
    routeValue: '',
  },
  {
    key: 'ConfigDescription',
    dropdownValue: 'Config Description',
    routeValue: '',
  },
  {
    key: 'Job',
    dropdownValue: 'Job',
    routeValue: '',
  },
  {
    key: 'Toll',
    dropdownValue: 'Toll',
    routeValue: '',
  },
  {
    key: 'UserLocation',
    dropdownValue: 'UserLocation',
    routeValue: '',
  },
];

export const ORDER_DELIVERY_TYPE = {
  FLEET_NEXT_DAY: 'fleet-next-day',
  FLEET_SAME_DAY: 'fleet-same-day',
};

export const GROUP_BY_OPTIONS = [
  {
    label: 'Restaurants',
    value: 'restaurant',
  },
  {
    label: 'Drivers',
    value: 'driver',
  },
];

export const MODAL_TYPES = {
  SUCCESS: 'Success',
  CONFIRM_CHANGES: 'Confirm Changes',
};

export const DISCOUNT_TYPES = [
  { value: 'FIXED_AMOUNT_OF_TOTAL', label: 'FIXED_AMOUNT_OF_TOTAL' },
  { value: 'PERCENTAGE_OF_DELIVERY', label: 'PERCENTAGE_OF_DELIVERY' },
  { value: 'PERCENTAGE_OF_TOTAL', label: 'PERCENTAGE_OF_TOTAL' },
  { value: 'FIXED_AMOUNT_OF_DELIVERY', label: 'FIXED_AMOUNT_OF_DELIVERY' },
  { value: 'FIXED_AMOUNT_OF_SUB_TOTAL', label: 'FIXED_AMOUNT_OF_SUB_TOTAL' },
  { value: 'PERCENTAGE_OF_SUB_TOTAL', label: 'PERCENTAGE_OF_SUB_TOTAL' },
];

export const CANCEL_FLEET_ORDER_REASON_CODES = {
  notNeededAnymore: 'notNeededAnymore',
  foundBetterPrice: 'foundBetterPrice',
  foundBetterDeliverySpeed: 'foundBetterDeliverySpeed',
  internalTesting: 'internalTesting',
  other: 'other',
};

export const SUBSCRIPTION_TYPES = {
  WEEKLY: 'WEEKLY',
  BI_MONTHLY: 'BI_MONTHLY',
  MONTHLY: 'MONTHLY',
  TRI_WEEKLY: 'TRI_WEEKLY',
  DAILY: 'DAILY',
};

export const DEFAULT_ADDRESS = {
  coords:{
    lat: 18.456285,
    lng: -66.070518,
  },
  address: 'San Juan, Puerto Rico',
};

export const STRIPE_SUPPORTED_CARD_BRANDS = [
  { value: 'American Express', label: 'American Express' },
  { value: 'Discover & Diners', label: 'Discover & Diners' },
  { value: 'Visa', label: 'Visa' },
  { value: 'Mastercard', label: 'Mastercard' },
  { value: 'Japan Credit Bureau (JCB)', label: 'Japan Credit Bureau (JCB)' },
  { value: 'China UnionPay (CUP)', label: 'China UnionPay (CUP)' },
];

export const CART_STATUSES = [{ label: 'Active', value: 'active' }, { label: 'Processed', value: 'processed' }, { label: 'Pending', value: 'pending' }, { label: 'Deleted', value: 'deleted' }];

export const STRIPE_DASHBOARD_LINK = 'https://dashboard.stripe.com';

export const STRIPE_USER_SUBSCRIPTION_STATUSES = {
  incomplete: 'incomplete', 
  incompleteExpired: 'incomplete_expired', 
  trialing: 'trialing', 
  active: 'active', 
  pastDue: 'past_due', 
  canceled: 'canceled', 
  unpaid: 'unpaid',
};

export const FLEET_MERCHANT_INVOICE_STATUS = {
  PENDING: 'PENDING',
  PROCESSED: 'PROCESSED',
  FAILED: 'FAILED',
};

export const FLEET_MERCHANT_INVOICE_STATUS_OPTIONS = [
  { value: 'PENDING', label: 'PENDING' },
  { value: 'PROCESSED', label: 'PROCESSED' },
  { value: 'FAILED', label: 'FAILED' },
];