import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { exportRestaurantMenu, getDishesForRestaurantMenuV2 } from 'services/api/restaurant';
import { showError, showSuccess } from 'utils/notifications';
import './index.scss';
import qs from 'query-string';
import { getPageLimit, updatePageLimit } from 'utils';
import ToggleSwitch from 'components/ToggleSwitch';
import TableLink from 'TableLink';
import ActionButton from 'components/ActionButton/ActionButton';
import { deleteDish, searchDishes, toggleDishAvailability } from 'services/api/dishes';
import DishesAutoSuggest from 'components/DishesAutoSuggest';
import ExportReportModal from 'components/ExportReportModal';

const RestaurantMenu = props => {
  const [dishes, setDishes] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const [togglingDish, setTogglingDish] = useState<string>('');
  const [deleteLoading, setDeleteLoading] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<number>();
  const [dishName, setDishName] = useState<string>('');
  const [dishId, setDishId] = useState<string>('');
  const [isExportMenuModalOpen, setIsExportMenuModalOpen] = useState(false);
  const toggleExportMenuModal = () => {setIsExportMenuModalOpen(!isExportMenuModalOpen)};
  const { restaurantId } = props;
  
  const filters: { [p: string]: any; page: number } = {
    page: 0,
  };

  const fetchDishesForRestaurant = async (filters: Record<string, any>) => {
    setLoading(true);
    try {
      const { page, dishId } = filters;
      const data: Record<string, any> = {
        page,
        limit: getPageLimit(),
        filter: {
          restaurantId,
        },
      };
      if (dishId) data.filter.dishId = dishId;
      const response = await getDishesForRestaurantMenuV2(data);
      setDishes(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  useEffect(() => {
    const { selectedTab, ...rest } = queryStringToFilter();
    if (selectedTab && typeof selectedTab === 'number') setSelectedTab(selectedTab);
    props.history.push({
      pathname: props.location.pathname,
      search: qs.stringify({ ...rest, selectedTab }),
    });
    fetchDishesForRestaurant(rest);
  }, []);

  const toggleDishAvailabilityFn = async (dishId: string) => {
    setTogglingDish(dishId);
    try {
      await toggleDishAvailability({ dishId, restaurantId });
      await fetchDishesForRestaurant(filters);
      showSuccess('Dish Availability toggled successfully');
    } catch (e) {
      showError(e);
    } finally {
      setTogglingDish('');
    }
  };
  
  const deleteDishFn = async (dishId: string) => {
    setDeleteLoading(dishId);
    const data = {
      dishId,
      restaurantId,
    };
    try {
      await deleteDish(data);
      await fetchDishesForRestaurant(filters);
      showSuccess('Dish deleted successfully');
    } catch (e) {
      showError(e);
    } finally {
      setDeleteLoading('');
    }
  };
  
  const columns = useMemo(() => [
    {
      name: 'Name',
      selector: 'name',
      width: '660px',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <div className={'dish-details'}>
          <div className={'dish-image'}>
            <img src={row.image ? row.image.url : require('assets/placeholder.svg')} />
          </div>
          <div className={'dish-name-and-desc'}>
            <div className={'dish-name-and-id'}>
              <div className={'dish-name'}>{row.name}</div>              
              <TableLink
                linkTo={`/dishes/${row.objectId}`}
                text={row.objectId}
              />
            </div>
            <div className={'dish-description'}>{row.description}</div>
          </div>
        </div>
      ),
    },
    {
      name: 'Pricing',
      selector: 'price',
      width: '180px',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <div className={'dish-price-and-variant'}>
          <div className={'dish-pricing'}>$ {row.price}</div>
          {row.variantsCount > 0 ? (
            <div className={'dish-variant-count'}>{`${row.variantsCount} Variant${row.variantsCount > 1 ? 's' : ''}`}</div>
          ): null}
        </div>
      ),
    },
    {
      name: 'Toggle Availabilty',
      selector: 'available',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <ToggleSwitch
          checked={row.available || false}
          disabled={togglingDish === row.objectId}
          onChange={() => toggleDishAvailabilityFn(row.objectId)}
          switchLabel={''}
        />
      ),
    },
    {
      name: 'Action',
      selector: '',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <div className={'dishes-row-action'}>
          <i
            className={'fa fa-pencil'}
            onClick={() => props.history.push(`/dishes/${row.objectId}/edit/${restaurantId}`)}
          />
          {getDeleteDishButton(row)}
        </div>
      ),
    },
  ], [togglingDish, loading, deleteLoading]);

  const getDeleteDishButton = (row) => {
    if (row?.deleted) return <div className='deleted-text'>Deleted</div>;
    if (deleteLoading === row.objectId) {
      return <Loading type={'mini'} />;
    } else {
      return <i className={'fa fa-trash'}
        onClick={() => deleteDishFn(row.objectId)}
      />;
    }
  };

  const getQueryString = () => {
    return qs.stringify({ ...filters, selectedTab });
  };

  const updateQueryString = () => {
    const qs = getQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchDishesForRestaurant(filters);
  };
  
  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const handleReset = () => {
    setDishId('');
    setDishName('');
    fetchDishesForRestaurant({ ...filters, dishId: '' });
  };

  return (
    <div>
      {loading && <Loading />}
      <div className={'menu-header'}>
        {isExportMenuModalOpen && <ExportReportModal 
          heading="Export Restaurant Menu" 
          toggleModal={toggleExportMenuModal} 
          apiParams={{ restaurantId: restaurantId }}
          apiFunction={exportRestaurantMenu}
        />}
        <div className={'menu-heading'}>Restaurant Menu</div>
        <div className='d-flex'>
          <ActionButton
            text={'Export Menu'}
            active={true}
            onClick={toggleExportMenuModal}
            customClass={'btn btn-primary ml-3 mx-2'}
            isLoading={false}
          />
          <ActionButton
            text={'Dish +'}
            active={true}
            isLoading={false}
            onClick={() => props.history.push(`/dishes/dish/add/${restaurantId}`)}
            customClass={'btn btn-primary'}
          />
        </div>
        
      </div>
      <div className={'menu-filter-container'}>
        <div className={'dish-autosuggest'}>
          <DishesAutoSuggest
            value={dishName}
            fetchDishes={term => searchDishes(term, { restaurantId })}
            placeholder={'Search Dish'}
            handleChange={setDishName}
            onSuggestionSelect={setDishId}
          />
        </div>
        <div>
          <ActionButton
            text={'Submit'}
            active={true}
            isLoading={false}
            onClick={() => fetchDishesForRestaurant({ ...filters, dishId })}
            customClass={'btn btn-primary'}
          />
          <ActionButton
            text={'Reset'}
            active={true}
            isLoading={false}
            onClick={handleReset}
            customClass={'btn btn-primary ml-3'}
          />
        </div>
      </div>
      <DataTable
        noHeader
        noTableHead
        keyField="objectId"
        columns={columns}
        data={dishes.items}
        highlightOnHover={true}
        customStyles={tableStyles}
        pagination={true}
        paginationServer={true}
        paginationDefaultPage={(filters.page || 0) + 1}
        paginationTotalRows={dishes.total}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </div>
  );
};

export default RestaurantMenu;