import React, { useEffect } from 'react';
import 'react-dates/initialize';
import AppRouter from '../router';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
// require('../services');
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/app.scss';
import 'react-dates/lib/css/_datepicker.css';
import 'react-notifications-component/dist/theme.css';
import { refreshLoggedInUser } from '../services/api';

function App() {
  useEffect(() => {
    refreshLoggedInUser();
  }, []);
  return (
    <Router>
      <div className="app">
        <div className="main">
          <ReactNotification />
          <AppRouter/>
        </div>
      </div>
    </Router>
  );
}

export { App };
