import React, { useEffect, useMemo, useState } from 'react';
import { fetchChildOrders } from 'services/api/order';
import './single-order.scss';
import { Loading } from 'components/loading/Loading';
import ActionButton from 'components/ActionButton/ActionButton';
import { DATE_FORMATS, formatDate, fromStripeAmount, getItemsCount } from 'utils';
import Currency from 'components/Currency';
import BackButton from 'components/BackButton';
import { showError } from 'utils/notifications';
import DataTable from 'react-data-table-component';
import { tableStyles } from 'css/tableStyles';
import OrderNoteModal from './AddNote';
import AddUserCommunicaitonModal from './Add User Communication';
import TableLink from 'TableLink';
import NoteSection from './NoteSection';
import TransactionInfo from './TransactionInfo';
import ClientDetails from './ClientDetails';

const MultiMerchantOrder = props => {
  const [loading, setLoading] = useState(true);
  const [childOrders, setChildOrders] = useState<Record<string, string>[]>([]);
  const [orderDetails, setOrderDetails] = useState<Record<string, any>>({});
  const [orderNoteModalOpen, setOrderNoteModalOpen] = useState<boolean>(false);
  const [note, setNote] = useState<Record<string, any> | null>(null);
  const [addUserCommunicationModalOpen, setAddUserCommunicaitonModalOpen] = useState<boolean>(false);
  const { orderId } = props;

  const fetchChildOrdersFn = async () => {
    try {
      const response = await fetchChildOrders(orderId);
      setChildOrders(response);
    } catch (e) {
      showError(e);

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.orderDetails) {
      setOrderDetails(props.orderDetails);
      setLoading(false);
      fetchChildOrdersFn();
    }
  }, []);

  const orderColumns = useMemo(() => [
    {
      name: 'Order Id',
      selector: 'objectId',
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/orders/${row.objectId}`}
          text={row.objectId}
          openInNewTab={true}
        />
      ),
    },
    {
      name: 'Order number',
      selector: 'orderNumber',
    },
    {
      name: 'Restaurant',
      selector: 'restaurant.restaurant_name',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/restaurants/${row.restaurant?.objectId}`}
          text={row.restaurant?.restaurant_name}
          openInNewTab={true}
        />
      ),
    },
    {
      name: 'Status',
      selector: 'status',
    },
  ], []);

  if (loading) return <Loading />;

  const getOrderPlateDetailsSection = () => {
    const getVariantsDescription = (variants: Record<string, any>[]) => {
      return (
        <div className={'variants'}>
          <div className={'variants-label'}>Variants</div>
          {variants.map(variant => (
            <div key={variant.id} className={'variant'}>
              <div className={`name`}>
                {variant.name}
              </div>
              <div className={'amount'}>
                <Currency amount={variant.price} />
              </div>
            </div>
          ))}
        </div>
      );
    };

    const getDishNote = (note: string) => {
      return (
        <div className={'notes'}>
          <div className={'notes-label'}>Notes</div>
          <div className={'note'}>{note}</div>
        </div>
      );
    };

    const getDishDetails = (dishDetails: Record<string, any>, dishCount: number) => {
      if (!dishDetails.dishesDescription) {
        return [];
      }
      const mappedDetails = dishDetails.dishesDescription.map(plateDescription => (
        <div key={plateDescription.id} className={'single-plate'}>
          <div className={'basic-plate-details'}>
            <div className={'dish-count'}>1x</div>
            <div className={`name`}>
              {dishDetails.name}
            </div>
            <div className={'dish-price'}><Currency amount={dishDetails.price} /></div>
          </div>
          {plateDescription ? (
            <div className={'dish-desc'}>
              {!!plateDescription.specs?.length && getVariantsDescription(plateDescription.specs)}
              {!!plateDescription.notes && getDishNote(plateDescription.notes)}
            </div>
          ) : null}
        </div>
      ));
      return mappedDetails;
    };

    const getPlateDetails = () => {
      return orderDetails.dishes.map(dish => getDishDetails(dish, dish.count));
    };

    const getTotalBreakdownSection = () => {
      const getTotalBreakdowns = () => {
        const data = [
          {
            label: 'Subtotal',
            value: orderDetails.subtotals,
          },
          {
            label: 'Subtotals Markup',
            value: orderDetails.subtotalsMarkup,
          },
          {
            label: 'Delivery Fee',
            value: orderDetails.deliveryCharges,
          },
          {
            label: 'Delivery Tax',
            value: orderDetails.taxData?.deliveryTaxes?.total,
          },
          {
            label: 'Discount',
            value: orderDetails.discountAmount,
          },
          {
            label: 'Taxes',
            value: orderDetails.taxes,
          },
        ];

        return data.map(charge => (
          <div key={charge.label} className={'delivery-fee-section'}>
            <div>{charge.label}</div>
            <div className={'amount'}>
              {(charge.label === 'Discount' && charge.value > 0) && (<span>{`- `}</span>)}
              <Currency amount={charge.value || 0} />
            </div>
          </div>
        ));
      };

      return (
        <div className={'total-breakdown-section'}>
          {getTotalBreakdowns()}
          <div className={'total-section'}>
            <div>Total</div>
            <div className={'amount'}><Currency amount={fromStripeAmount(orderDetails.total)} /></div>
          </div>
        </div>
      );
    };

    return (
      <div className={'order-details-section'}>
        <div className={'label'}>Dish Details</div>
        {orderDetails.dishes && getPlateDetails()}
        {getTotalBreakdownSection()}
      </div>
    );
  };

  const getBasicOrderDetailsSection = () => {
    return (
      <div className={'basic-order-details'}>
        <div className={'detail'}>
          <div className={'label'}>Order ID</div>
          <div className={'info'}>{orderDetails.objectId}</div>
          {orderDetails?.parentOrder && (
            <div>
              <div className={'label'}>Parent Order ID</div>
              <a href={`/orders/${orderDetails.parentOrder.objectId}`}>{orderDetails.parentOrder.objectId}</a>
            </div>
          )}
        </div>
        <div className={'detail'}>
          <div className={'label'}>Order Type</div>
          <div className={'info'}>{'Multi-Merchant Order'}</div>
        </div>
        <div className={'detail'}>
          <div className={'label'}>Order Date</div>
          <div className={'info'}>{formatDate(orderDetails.createdAt, DATE_FORMATS.MMM_DD_YYYY)}</div>
        </div>
        <div className={'detail'}>
          <div className={'label'}>Time</div>
          <div className={'info'}>{formatDate(orderDetails.createdAt, DATE_FORMATS.H_MM_P)}</div>
        </div>
      </div>
    );
  };

  const getHeader = () => (
    <div>
      <BackButton />
      <div className={'header'}>
        <h4>Order Detail</h4>
        <div className={'buttons-container'}>
          <ActionButton
            text="Send Communication"
            active={true}
            isLoading={false}
            onClick={() => setAddUserCommunicaitonModalOpen(true)}
            customClass="btn-primary user-comm-button"
          />
        </div>
      </div>
    </div>
  );

  const getChildOrdersTable = () => (
    <>
      <div className={'label pt-4 pb-4'}>Child Orders</div>
      <DataTable
        noHeader
        columns={orderColumns}
        data={childOrders}
        customStyles={tableStyles}
      />
    </>
  );

  return (
    <>
      {
        orderNoteModalOpen && (
          <OrderNoteModal
            show={orderNoteModalOpen}
            onClose={() => {
              setNote(null);
              setOrderNoteModalOpen(false);
            }}
            orderId={orderDetails.objectId}
            note={note}
            onSuccess={notes => setOrderDetails({ ...orderDetails, notes })}
          />
        )
      }
      {
        addUserCommunicationModalOpen && (
          <AddUserCommunicaitonModal
            orderId={orderDetails.objectId}
            show={addUserCommunicationModalOpen}
            onClose={() => setAddUserCommunicaitonModalOpen(false)}
          />
        )
      }
      <div className={`single-order-container`}>
        {getHeader()}
        <div className={'order-details-container'}>
          {getBasicOrderDetailsSection()}
          <ClientDetails
            user={orderDetails?.user}
            deliveryAddress={orderDetails?.deliveryAddress}
            itemCount={(orderDetails.dishes || orderDetails.dishes.length) ? getItemsCount(orderDetails.dishes) + ' item' : 'No details available'}
            contactPhone={orderDetails?.contactPhone}
          />
          {getOrderPlateDetailsSection()}
          <TransactionInfo orderId={orderDetails?.objectId} parentOrderId={orderDetails?.parentOrder?.objectId} orderStatus={orderDetails?.status} isParentOrder={orderDetails?.isMultiMerchantParentOrder} />
          <NoteSection
            orderDetails={orderDetails}
            setOrderDetails={setOrderDetails}
            note={note}
            setNote={setNote}
            setOrderNoteModalOpen={setOrderNoteModalOpen}
            orderNoteModalOpen={orderNoteModalOpen}
          />
          {getChildOrdersTable()}
        </div>
      </div>
    </>
  );
};
export default MultiMerchantOrder;
