import React from 'react';

const Dropdown = props => {
  const { value, onChange, disabled, name, options, areOptionsStringArray, isFilter } = props;
  let getOptions;
  if (areOptionsStringArray) {
    getOptions = () => options.map((record, i) => (
      <option key={i} value={record}>{record}</option>
    ));
  } else {
    getOptions = () => options.map((record, i) => (
      <option key={i} value={record.value}>{record.label}</option>
    ));
  }

  return (
    <select
      name={name}
      className={'form-control'}
      value={value}
      onChange={e => onChange(e.target.value)}
      disabled={disabled}
    >
      <option key={'default'} value={''} >{isFilter ? 'ALL' : `Select ${name}`}</option>
      {!!options.length ? getOptions() : null}
    </select>
  );
};

export default Dropdown;