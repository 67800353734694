import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ActionButton from 'components/ActionButton/ActionButton';
import { showError } from 'utils';

const UpdateDriverNotes = props => {
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.note && props.note.note) {
      setNote(props.note.note);
    } else {
      setNote('');
    }
  }, [props.note]);

  const submitDriverNotes = async () => {
    setLoading(true);
    try {
      let driver;
      if (props.isAdd) {
        driver = await props.addNotesAPI({ note, id: props.driverId });
      } else {
        driver = await props.editNotesAPI({ note, id: props.driverId, noteId: props.note.id });
      }
      props.onAdd(driver.toJSON().notes);
      props.onClose();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={props.show} onHide={props.onClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title>{props.isAdd ? 'Add' : 'Edit'} Notes</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <textarea name="notes" id="notes" cols={30} rows={10} className="w-100" value={note} onChange={event => setNote(event.target.value)}></textarea>
      </Modal.Body>

      <Modal.Footer>
        <ActionButton text={'Close'} active={true} onClick={props.onClose} isLoading={false}></ActionButton>
        <ActionButton text={'Submit'} active={note.length > 0} onClick={submitDriverNotes} isLoading={loading} customClass="btn-primary"></ActionButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateDriverNotes;
