import React,{ useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import styles from './index.module.scss';
import _ from 'lodash';
import Tag from 'components/Tag';

const DomainEligibility = props => {
  const { detailsEditable,discount,setDiscount,loading }=props;
  const [domain, setDomain] = useState<string>('');
  const domains = discount?.userEligibility?.domainContainedIn || [];

  const handleRemove = (index: number) => {
    domains.splice(index, 1);
    setDiscount({ ...discount, userEligibility: { ...discount?.userEligibility, domainContainedIn: domains }});
  };

  const handleAddDomain = () => {
    if (!_.isEmpty(domain)) {
      domains.push(domain);
      setDiscount({ ...discount, userEligibility: { ...discount?.userEligibility, domainContainedIn: _.uniq(domains) }});
    }
  };

  const getDomainEligibilitySection=()=>{
    if(detailsEditable){
      return (
        <div className={styles['input-container']}>
          <div >
            <div className={`${styles['label']}`}>Add Eligible Domain</div>
            <input
              className='form-control'
              value={domain}
              onChange={e => setDomain(e.target.value)}
            />
          </div>
          <div className={styles['action-button']}>
            <ActionButton
              active={true}
              isLoading={loading}
              onClick={handleAddDomain}
              text={'Add Domain'}
              customClass={'form-control'}
            />
          </div>
        </div>
      );
    }else{
      return null;
    }
  };

  const getDomainsTagSection=()=>{
    if(domains?.length > 0){
      return (
        <div className={styles['tag-section']}>
          <div className={styles['roles-card']}>
            {domains.map((item, i) => <Tag key={i} tagName={item} onRemove={() => handleRemove(i)} />)}
          </div>
        </div>
      );
    }else{
      return  <div className={`${styles['label']} pl-2`}>No Eligible Domains</div>;
    }
  };
  
  return (
    <div className={styles['domain-section']}>
      <h6 >Domain Eligibility</h6>
      {getDomainEligibilitySection()}
      {getDomainsTagSection()}
    </div>
  );
};
export default DomainEligibility;
