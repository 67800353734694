import React from 'react';
import styles from './index.module.scss';

const SingleAddressTile = props => {
  const {
    address,
  } = props;

  return (
    <div className={`${styles['single-address']}`}>
      <div className={styles['address-details']}>
        <div className={styles['line1']}>{`${address?.name}`}</div>
        <div className={styles['line1']}>{address.address}</div>
        <div className={styles['line2']}>{`${address.town}`}</div>
      </div>
    </div>
  );
};

export default SingleAddressTile;