import ActionButton from 'components/ActionButton/ActionButton';
import BackButton from 'components/BackButton';
import ImageUpload from 'components/ImageUpload/index';
import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from 'react';
import { showError, showSuccess } from 'utils/notifications';
// import './index.scss';
import _ from 'lodash';
import { getUvaCategoryDetail, updateUvaCategoryDetail, uploadUvaCategoryIcon } from 'services/api/uvaCategories';
import UvaCategoriesFlattenTree from 'components/UvaCategoryTree/UvaCategoryFlattenTree';

const EditUvaCategoryInfo = props => {
  const [details, setDetails] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [parentCategory, setParentCategory] = useState<string>('');
  const [parentCategoryName, setParentCategoryName] = useState<string>('');

  const [logo, setLogo] = useState<string>('');
  const { uvaCategoryId } = props.match.params;
  
  const fetchDetails = async () => {
    setLoading(true);
    try {
      const res = await getUvaCategoryDetail(uvaCategoryId);
      if (res) {
        setDetails(JSON.parse(JSON.stringify(res)));
        setLogo(res.icon?.url);
      }
    } catch (e) {
      //   showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);


  const updateCategoryInfo = async () => {
    setUpdateLoading(true);
    try {
      const data = {
        id: uvaCategoryId,
        name: details.name,
        esName: details.esName,
        parentCategoryId: parentCategory,
      };
      const response = await updateUvaCategoryDetail(data);
      setDetails(response);
      showSuccess('Uva Category details updated successfully.');
      props.history.push(`/uva-categories/${uvaCategoryId}`);
    } catch (e) {
      // showError(e);
    } finally {
      setUpdateLoading(false);
    }
  };
  
  const handleChange = (key: string, value: any) => {
    setDetails({ ...details, [key]: value });
  };

  const handleCategoryChange = (key, label) => {
    setParentCategory(key);
    setParentCategoryName(label);
  };

  const uploadIconImage = (img) =>{
    try{
      uploadUvaCategoryIcon({
        icon: img,
        id: uvaCategoryId,
      });
      handleChange('icon', img);
    }catch(e){
      showError(e);
    }
  };

  if (loading) return <Loading />;
  
  const getActionSection = () => (
    <div className={'restaurant-edit-action-section'}>
      <ActionButton
        text={'Cancel'}
        active={true}
        isLoading={false}
        onClick={() => props.history.goBack()}
      />
      <ActionButton
        text={'Save Changes'}
        active={true}
        isLoading={false}
        onClick={updateCategoryInfo}
        customClass={'btn-primary'}
      />
    </div>
  );
  
  const getBasicDetailsSection = () => (
    <div className={'restaurant-edit-basic-details-section'}>
      <div className={'restaurant-edit-group'}>
        <div className={'restaurant-edit-group-label'}>Category Name</div>
        <input
          className={'restaurant-edit-group-input'}
          value={details.name}
          onChange={e => handleChange('name', e.target.value)}
        />
      </div>
      <div className={'restaurant-edit-group'}>
        <div className={'restaurant-edit-group-label'}>Category Name (Spanish)</div>
        <input
          className={'restaurant-edit-group-input'}
          value={details.esName}
          onChange={e => handleChange('esName', e.target.value)}
        />
      </div>
      <div>
        <div className={'dish-details-group'}>
          <b>Change Parent Category</b> :&nbsp;
          <label className={'detail'}>{parentCategoryName ? parentCategoryName : details?.parentCategory?.name}</label>
        </div>
        <UvaCategoriesFlattenTree
          uvaCategoryId={parentCategory}
          onChange={handleCategoryChange}
        />
      </div>
    </div>
  );
  
  const getImageSection = () => (
    <div className={'restaurant-edit-image-section'}>
      <div className={'restaurant-logo-image-section'}>
        <div className={'text-section'}>
          <div className={'image-label'}>Uva Category Logo</div>
          <ImageUpload
            onImageSelect={setLogo}
            pickerText={details.icon ? 'Change' : 'Upload'}
            onImageUpload={uploadIconImage}
          />
        </div>
        <div>
          <img src={logo || details.icon?.url} />
        </div>
      </div>
    </div>
  );
  
  const getHeader = () => (
    <div className={'header'}>
      <div>
        <BackButton />
        <div>{'Edit Uva Category Details'}</div>
        <div>{uvaCategoryId}</div>
      </div>
      <div>
        <ActionButton
          text={'Save Changes'}
          active={true}
          isLoading={false}
          onClick={updateCategoryInfo}
          customClass={'btn-primary'}
        />
      </div>
    </div>
  );
  
  return (
    <div className={'edit-restaurant-container'}>
      {updateLoading && <Loading />}
      {getHeader()}
      <div className={'edit-restaurant-details-container'}>
        {getImageSection()}
        {getBasicDetailsSection()}
        {getActionSection()}
      </div>
    </div>
  );
};

export { EditUvaCategoryInfo };