import React, { useState } from 'react';
import './index.scss';
import { DATE_FORMATS, formatDate } from 'utils';
import DataTable from 'react-data-table-component';
import { tableStyles } from '../../../../css/tableStyles';
import ActionButton from 'components/ActionButton/ActionButton';
import UpdateDriverNotes from './update-driver-notes';
import { Loading } from 'components/loading/Loading';

const DriverNotes = (props) => {
  const { notes = [], id, headerName='Driver' } = props;
  const [driverNotes, setDriverNotes] = useState(notes);
  const [loading, setLoading] = useState('');
  const [showDriverNotesModal, setShowDriverNotesModal] = useState(false);
  const [isAddNotes, setIsAddNotes] = useState(false);
  const [selectedNote, setSelectedNote] = useState({});
  const columns = [
    {
      name: 'Notes',
      selector: 'note',
      sortable: false,
    },
    {
      name: 'Date Created',
      selector: 'createdAt',
      sortable: false,
      format: row => formatDate(row.createdAt.iso || row.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
    },
    {
      name: 'Creator',
      selector: 'by',
      sortable: false,
      format: row => row.by.objectId || row.by.id,
    },
    {
      name: 'Action',
      sortable: false,
      cell: row => getActionButtons(row),
    },
  ];

  const getActionButtons = row => {
    return (
      <div>
        <button className="btn btn-danger" onClick={() => deleteNotes(row)}>
          { loading === row.id ? <Loading type='mini' /> : <i className="fa fa-trash delete-btn"></i> }
        </button>
        <button className="btn btn-primary ml-3" onClick={() => editDriverNotes(row)}>
          <i className="fa fa-pencil edit-btn"></i>
        </button>
      </div>
    );
  };

  const addNotes = () => {
    setSelectedNote({});
    setShowDriverNotesModal(true);
    setIsAddNotes(true);
  };

  const deleteNotes = async note => {
    try {
      setLoading(note.id);
      const driver = await props.deleteNoteAPI({ noteId: note.id, id });
      setDriverNotes(driver.toJSON().notes);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading('');
    }
  };

  const editDriverNotes = note => {
    setSelectedNote(note);
    setShowDriverNotesModal(true);
    setIsAddNotes(false);
  };

  const closeNotesModal = () => {
    setIsAddNotes(false);
    setShowDriverNotesModal(false);
  };

  return (
    <div className="driver-notes">
      <div className="row">
        <div className="col-12 notes-table">
          <div className="row mb-2">
            <div className="col-6 d-flex align-items-center">
              <span className="text-muted font-weight-bold">{headerName} Notes</span>
            </div>
            <div className="col-6 text-right">
              <ActionButton text="Add" active={true} isLoading={false} onClick={addNotes} customClass="btn-primary"></ActionButton>
            </div>
          </div>

          <DataTable
            keyField="objectId"
            columns={columns}
            data={driverNotes}
            highlightOnHover={true}
            defaultSortField="createdAt"
            customStyles={tableStyles}
            pagination={true}
            paginationServer={true}
            paginationTotalRows={notes.length}
          />
        </div>
      </div>
      <UpdateDriverNotes show={showDriverNotesModal} isAdd={isAddNotes} onClose={closeNotesModal} driverId={id}
        note={selectedNote} onAdd={setDriverNotes} {...props}/>
    </div>
  );
};

export default DriverNotes;
