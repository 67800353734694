import React, { useEffect, useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import qs from 'query-string';
import './index.scss';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Error } from 'components/message/Error';
import NameFilterAutosuggest from 'components/NameFilterAutoSuggest';
import { searchUserByName } from 'services/api/order';
import { phoneRegEx } from 'utils';

const validationSchema = yup.object().shape({
  userEmail: yup.string(),
  userPhone: yup.string().matches(phoneRegEx, 'Phone Number must be valid'),
});

type FormData = {
  userEmail: string;
  userPhone: string;
}

const UsersFilter = props => {
  const { errors, handleSubmit, register, reset } = useForm<FormData>({ resolver: yupResolver(validationSchema) });
  const [userId, setUserId] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [userPhone, setUserPhone] = useState<string>('');

  useEffect(() => {
    const {
      userId,
      email,
      phone,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
    if (userId) setUserId(userId.toString());
    if (email) setUserEmail(email.toString());
    if (phone) setUserPhone(phone.toString());
  }, []);

  const resetFilter = () => {
    reset();
    setUserId('');
    setUserEmail('');
    setUserPhone('');
    const filterData = {
      userEmail: '',
      userPhone: '',
      userId: '',
    };
    props.onFilterSubmit(filterData);
  };

  const onFilterSubmit = handleSubmit((values) => {
    const { userEmail, userPhone } = values;
    const filterData: Record<string, any> = {
      phone: userPhone,
      email: userEmail,
      userId: userId,
    };
    props.onFilterSubmit(filterData);
  });

  return (
    <form className='filter-container'>
      <div className='input-container mb-2 mr-4'>
        <label>User ID</label>
        <input
          className='form-control'
          placeholder={'User ID'}
          value={userId}
          onChange={e => setUserId(e.target.value)}
        />
      </div>
      <div className='input-container mb-2 mr-4'>
        <label>User Name</label>
        <NameFilterAutosuggest
          entityId={userId}
          placeholder={'User Name'}
          onSuggestionSelected={setUserId}
          fetchSuggestions={searchUserByName}
        />
      </div>
      <div className='input-container mb-2 mr-4'>
        <label>User Email</label>
        <input
          name={'userEmail'}
          ref={register}
          className='form-control'
          placeholder={'User Email or username'}
          defaultValue={userEmail}
        />
        {errors.userEmail && <Error message={errors.userEmail.message} />}
      </div>
      <div className='input-container mb-2 mr-4'>
        <label>User Phone</label>
        <input
          name={'userPhone'}
          ref={register}
          className='form-control'
          placeholder={'User Phone'}
          defaultValue={userPhone}
        />
        {errors.userPhone && <Error message={errors.userPhone.message} />}
      </div>
      <ActionButton
        text={'Submit'}
        isLoading={false}
        active={true}
        customClass={'btn-primary mb-2'}
        onClick={onFilterSubmit}
      />
      <ActionButton
        text={'Reset'}
        isLoading={false}
        active={true}
        customClass={'btn-primary ml-4 mb-2'}
        onClick={resetFilter}
      />
    </form>
  );
};

export default UsersFilter;
