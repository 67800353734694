import { Types } from 'actions/types';
import { ILead } from 'interfaces/lead';
import { fetchLeadDetail, fetchLeads } from 'services';

const ILeadAction: ILead.DispatchFromProps = {
  fetchLeads: (filters: ILead.ILeadFilter, sort = {}) => {
    return async function (dispatch: any) {
      dispatch({
        type: Types.FETCH_LEADS,
      });
      try {
        const response = await fetchLeads(filters, sort);
        dispatch({
          type: Types.FETCH_LEADS_SUCCESS,
          payload: {
            leads: response.items.map((lead: any) => lead.toJSON()),
            leadsCount: response.total,
          },
        });
        return response;
      } catch (e) {
        dispatch({
          type: Types.FETCH_LEADS_FAILED,
          payload: { message: e.message },
        });
        return null;
      }
    };
  },
  fetchLeadDetail: (id: string) => {
    return async function (dispatch: any) {
      dispatch({
        type: Types.FETCH_LEAD_DETAIL,
      });
      try {
        const response = await fetchLeadDetail(id);
        dispatch({
          type: Types.FETCH_LEAD_DETAIL_SUCCESS,
          payload: { lead: response },
        });
        return response;
      } catch (e) {
        dispatch({
          type: Types.FETCH_LEAD_DETAIL_FAILED,
          payload: { message: e.message },
        });
        return null;
      }
    };
  },
};

export { ILeadAction as mapDispatchToProps };
