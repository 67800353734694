import React, { useEffect, useState } from 'react';
import { getJobServerInstances } from 'services/api/cronJobs';
import { showError } from 'utils/notifications';

const JobServerInstanceDropdown = props => {
  const [serverInstances, setServerInstances] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const{
    value,
    disabled,
    onChange,
  } = props;
  
  const fetchServerInstances = async () => {
    try {
      const response = await getJobServerInstances();
      setServerInstances(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServerInstances();
  }, []);
  
  return (
    <select
      className={'form-control'}
      disabled={loading || disabled}
      onChange={e => onChange(e.target.value)}
      value={value}
    >
      <option hidden={!!value}>Select an Instance</option>
      {serverInstances?.map(instance => <option key={instance} value={instance}>{instance}</option>)}
    </select>
  );
};

export default JobServerInstanceDropdown;