import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import omit from 'lodash/omit';
import { DateRangePicker, DateRangePickerShape, END_DATE, START_DATE, isSameDay } from 'react-dates';
import { css, withStyles, withStylesPropTypes } from 'react-with-styles';

const presets = [
  { start: moment(), end: moment(), text: 'Today' },
  { start: moment().subtract(6, 'days'), end: moment(), text: 'Last 7 days' },
  { start: moment().subtract(1, 'month').add(1, 'day'), end: moment(), text: 'Last 30 days' },
  { start: moment().startOf( 'quarter'), end: moment(), text: 'QTD' },
  { start: moment().startOf( 'quarter').subtract(1, 'day').startOf('quarter'), end: moment().startOf( 'quarter').subtract(1, 'day'), text: 'Last Quarter' },
  { start: moment().startOf( 'year'), end: moment(), text: 'YTD' },
];

const propTypes = {
  ...withStylesPropTypes,

  // example props for the demo
  autoFocus: PropTypes.bool,
  autoFocusEndDate: PropTypes.bool,
  initialStartDate: momentPropTypes.momentObj,
  initialEndDate: momentPropTypes.momentObj,
  presets: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    start: momentPropTypes.momentObj,
    end: momentPropTypes.momentObj,
  })),

  ...omit(DateRangePickerShape, [
    'startDate',
    'endDate',
    'onDatesChange',
    'focusedInput',
    'onFocusChange',
  ]),
};

const defaultProps = {
  // example props for the demo
  autoFocus: false,
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  presets: [],

  // input related props
  startDateId: 'START_DATE',
  startDatePlaceholderText: 'Start Date',
  endDateId: 'END_DATE',
  endDatePlaceholderText: 'End Date',
  disabled: false,
  required: false,
  screenReaderInputMessage: '',
  showClearDates: false,
  showDefaultInputIcon: false,
  customInputIcon: null,
  customArrowIcon: null,
  customCloseIcon: null,

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: 'horizontal',
  anchorDirection: 'left',
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  // onPrevMonthClick() { },
  // onNextMonthClick() { },
  // onClose() { },

  // day presentation and interaction related props
  renderDayContents: null,
  minimumNights: 0,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isOutsideRange: day => false,
  isDayHighlighted: () => false,

  // internationalization
  displayFormat: () => moment.localeData().longDateFormat('L'),
  monthFormat: 'MMMM YYYY',
  // phrases: DateRangePickerPhrases,
};

class DateRangePresetsPicker extends React.Component<any, any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props) {
    super(props);

    let focusedInput = null;
    if (props.autoFocus) {
      focusedInput = START_DATE;
    } else if (props.autoFocusEndDate) {
      focusedInput = END_DATE;
    }

    this.state = {
      focusedInput,
      startDate: props.initialStartDate,
      endDate: props.initialEndDate,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.renderDatePresets = this.renderDatePresets.bind(this);
  }

  componentDidMount() {
    // let updatedPresets: Record<string, any> = [];
    if (this.props.presets) {
      // updatedPresets = [...this.props.presets]
      this.setState({ presets: this.props.presets });
    } else {
      this.setState({ presets });
    }
  }

  onDatesChange({ startDate, endDate }) {
    this.props.onDatesChange({ startDate, endDate });
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput });
  }

  renderDatePresets() {
    const { styles } = this.props;
    const { startDate, endDate, presets } = this.state;

    return (
      <div {...css(styles.PresetDateRangePicker_panel)}>
        {presets.map(({ text, start, end }) => {
          const isSelected = isSameDay(start, startDate) && isSameDay(end, endDate);
          return (
            <button
              key={text}
              {...css(
                styles.PresetDateRangePicker_button,
                isSelected && styles.PresetDateRangePicker_button__selected,
              )}
              type="button"
              onClick={() => this.onDatesChange({ startDate: start, endDate: end })}
            >
              {text}
            </button>
          );
        })}
      </div>
    );
  }

  render() {
    const { focusedInput } = this.state;
    const initialVisibleMonth = () => moment().subtract(1, 'month');

    // autoFocus, autoFocusEndDate, initialStartDate and initialEndDate are helper props for the
    // example wrapper but are not props on the SingleDatePicker itself and
    // thus, have to be omitted.
    const props = omit(this.props, [
      'autoFocus',
      'autoFocusEndDate',
      'initialStartDate',
      'initialEndDate',
      'presets',
    ]);

    return (
      <div>
        <DateRangePicker
          {...props}
          renderCalendarInfo={this.renderDatePresets}
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          startDate={moment(this.props.startDate)}
          endDate={moment(this.props.endDate)}
          initialVisibleMonth={initialVisibleMonth}
          keepOpenOnDateSelect={true}
        />
      </div>
    );
  }
}

DateRangePresetsPicker.propTypes = propTypes;
DateRangePresetsPicker.defaultProps = defaultProps;

export default withStyles(({ reactDates: { color }}) => ({
  // eslint-disable-next-line @typescript-eslint/camelcase
  PresetDateRangePicker_panel: {
    padding: '0 22px 11px 22px',
  },
  // eslint-disable-next-line @typescript-eslint/camelcase
  PresetDateRangePicker_button: {
    position: 'relative',
    height: '100%',
    textAlign: 'center',
    background: 'none',
    border: `2px solid ${color.core.primary}`,
    color: color.core.primary,
    padding: '4px 12px',
    marginRight: 8,
    font: 'inherit',
    fontWeight: 700,
    lineHeight: 'normal',
    overflow: 'visible',
    boxSizing: 'border-box',
    cursor: 'pointer',

    ':active': {
      outline: 0,
    },
  },
  // eslint-disable-next-line @typescript-eslint/camelcase
  PresetDateRangePicker_button__selected: {
    color: color.core.white,
    background: color.core.primary,
  },
}))(DateRangePresetsPicker);
