import moment from 'moment';
import _ from 'lodash';
import { DATE_FORMATS, ORDER_DELIVERY_TYPE, USER_ROLES } from './constant';

export const capitalize = (text: string) => {
  const textArr = text.split(' ');
  for (let i = 0; i < textArr.length; i++) {
    textArr[i] = textArr[i].substr(0, 1).toUpperCase() + textArr[i].substr(1).toLowerCase();
  }
  return textArr.join(' ');
};

export const isValidDomainName = (url: string) => {
  const res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return (res !== null);
};

export const formatObject = (obj: any) => {
  for (const key in obj) {
    if (!obj[key]) {
      delete obj[key];
    }
  }
  return obj;
};

export const readFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader: any = new FileReader();
    reader.onload = () => resolve(reader?.result?.split(',')[1]); // Extract base64 content
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export const formatDate = (date: any, format?: string) => {
  return date ? moment(date).format(format || 'l') : '-';
};

export const convertToLocaleString = (value) => {
  return (value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

export const isBase64 = (arg) => {
  if (arg === '' || arg?.trim() === '') { return false }
  try {
    const str = arg?.split(',').splice(-1);
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
};

export const metersToMile = (meters: number) => {
  return parseFloat((meters / 1609.344).toFixed(2));
};

export const secondsToMinute = (seonds: number) => {
  return Math.round((seonds / 60) / 5) * 5;
};

export const isUserAuthorized = (userRoles = [], authorizedRoles = []) => {
  // Empty authorizedRoles means we just need a logged in user.
  // Admin has access to all the routes
  if (_.isEmpty(authorizedRoles) || _.indexOf(userRoles, USER_ROLES.ADMIN) !== -1) {
    return true;
  }
  for (const userRole of userRoles) {
    for (const role of authorizedRoles) {
      if (role === userRole) return true;
    }
  }
  return false;
};

export const isUserShopper = (roles: string[]) => {
  for (const role of roles) {
    if (role === USER_ROLES.SHOPPER) return true;
  }
  return false;
};

export const getEmailArr = (emails) => {
  if (emails) {
    return emails.split(',').map(email => email.trim());
  }
  return [];
};

export const convertSecondsToMinutesHours = (seconds: number) => {
  return moment(moment().format(DATE_FORMATS.YYYY_MM_DD)).add(seconds, 'seconds').format('HH:mm:ss');
};

export const metersToKm = (meters: number) => {
  if (meters > 999) {
    return (meters / 1000).toFixed(2);
  } else {
    return meters;
  }
};

export const getTypeFilteredRestaurants =
  (restaurants: Record<string, any>, types: string[]) => {
    if (!types) return restaurants;
    return restaurants.filter(restaurant => {
      for (const type of types) {
        if (restaurant.type === type) return true;
      }
      return false;
    });
  };

export const convertFrom24To12HrFormat = (timeString: string, includeTimePeriod: boolean) => {
  return moment(timeString, ['HH:mm']).format(`hh:mm ${includeTimePeriod ? 'A' : ''}`);
};

export const removeWhiteSpacesAndNonNumberCharacters = (string: string) => {
  return string.replace(/[^0-9]+/ig, '');
};

export const sortArray = (array: any[], sortBy = '') => {
  if (sortBy) return _.sortBy(array, sortBy);
  else return array.sort();
};

export const isFleetOrder = (deliveryType: string) => {
  return deliveryType === ORDER_DELIVERY_TYPE.FLEET_SAME_DAY || deliveryType === ORDER_DELIVERY_TYPE.FLEET_NEXT_DAY;
};

export const flattenJson = (parentName: string, data: Record<string, any>[]) => {
  let results: Record<string, string>[] = [];
  data.forEach(obj => {
    let name = '';
    if (parentName) {
      name = `${parentName} > ${obj['name']}`;
    } else {
      name = `${obj['name']}`;
    }
    if (obj['children']) {
      results.push({
        name,
        objectId: obj['objectId'],
      });
      results = [...results, ...flattenJson(name, obj['children'])];
    } else {
      results.push({
        name,
        objectId: obj['objectId'],
      });
    }
  });
  return results;
};

export const getItemsCount = (items: any[]) => {
  if (items.length && items[0]['count']) {
    return _.sumBy(items, 'count');
  } else {
    return _.sumBy(items, 'quantity');
  }
};

export const convertCentsToDollors = (amount: number) => {
  return amount / 100;
};

export const dollarToCents = amt => {
  return amt.toFixed(2) * 100;
};

export const getTimeDiff = (startDate: number, endDate: number) => {
  const timeDiff = moment(endDate).diff(startDate, 'seconds');
  return `${timeDiff} seconds`;
};

export const getTextValue = (value: boolean) => {
  return value ? 'Yes' : 'No';
};

export const sanitizeFilterObject = (filterData: any) => {
  return _.pickBy(filterData, _.identity);
};

export const stringifyAndParseObj = (Obj: any) => {
  return JSON.parse(JSON.stringify(Obj));
};

export const timeToString = (time: number) => {
  const days = Math.floor(time / 1440);
  const hours = Math.floor(time / 60);
  const minutes = Math.floor(time % 60);
  return (!days && !minutes && !hours) ? '<1min' : `${days ? days + ' d' : ''} ${hours ? hours + ' hr' : ''} ${minutes ? minutes + ' min' : ''}`;
};

export const convertArrayToObject = (array, key, defaultKey) => {
  const teamObject = {};
  array.forEach((item) => {
    const objectKey = item[key] || defaultKey;
    if (teamObject[objectKey]) {
      teamObject[objectKey].push(item);
    } else {
      teamObject[objectKey] = [item];
    }
  });
  return teamObject;
};

export const convertFlatArrayToFlatObject = (array, key) => {
  const teamObject = {};
  array.forEach((item) => {
    const objectKey = item[key];
    teamObject[objectKey] = item;
  });
  return teamObject;
};

export const convertNestedObjectsToFlatArrayForVariantSpecs = (obj: any) => {
  const result = [] as any;
  const convertArrToObj = (arr) => {
    const parent = result[result?.length - 1]?.id;
    arr.forEach((e: any) => {
      if (e.specs) {
        result.push({
          title: e.name,
          parent,
          ...e,
        });
        convertArrToObj(e.specs);
      } else result.push(e);
    });
  };
  convertArrToObj(obj?.specs);
  return result;
};

export const getItemsFromCartVariantWise = (cartItems: any): any => {
  let updatedCartItems = [] as any;
  cartItems?.map((cartItem) => {
    cartItem?.dishesDescription?.map((dishDescription, i) => {
      if (dishDescription?.specs?.length < 1) {
        updatedCartItems = [...updatedCartItems, { ...cartItem, dishDescriptionTree: dishDescription }];
      } else {
        const specsFlatArray = convertNestedObjectsToFlatArrayForVariantSpecs(dishDescription);
        const sortedSpecs = specsFlatArray.sort((a,b) => a.name - b.name);
        let price = cartItem?.price;
        const specNames = sortedSpecs?.map((spec) => {price += spec?.price; return spec?.name});
        const specNamesJoined = specNames?.join(' | ');
        updatedCartItems = 
            [...updatedCartItems, { ...cartItem, price, dishDescriptionTree: dishDescription, specs: 
              cartItem.dishesDescription[i]?.specs, consolidatedVariantName: specNamesJoined, 
            dishDescriptionId: dishDescription?.id }];
      }
    });
    return { ...cartItem };
  });
  const result = Object.values(updatedCartItems.reduce((c, item) => {
    const { id, consolidatedVariantName = '' } = item;
    const type = id + ' ' + consolidatedVariantName;
    c[type] = c[type] || { ...item, count: 0 };
    c[type].count++;
    return c;
  }, {}));
  return result;
};