import React from 'react';
import Alert from 'react-bootstrap/Alert';

const SampleCSVInfo = () => {
  const sampleGoogleSheetURL = (): string | undefined => process.env.REACT_APP_SAMPLE_DISH_IMPORT_GOOGLE_SHEET_URL;

  return (
    <div className="form-group">
      <Alert variant={'warning'}>
        Sample Google Sheet URL
        <p>
          <a
            href={sampleGoogleSheetURL()}
            target="_blank"
            rel="noopener noreferrer"
          >
            {sampleGoogleSheetURL()}
          </a>
        </p>
        <p className='font-italic'>
          <span className='font-weight-bold'>Note: </span>
          The sheet must be available to the public.
        </p>
      </Alert>
    </div>
  )
};

export default SampleCSVInfo;