import Parse from 'services/parse';

export const isLoggedIn = () => {
  const currentUser = Parse.User.current();
  return !!currentUser;
};

export const getLoggedInUser = () => {
  return Parse.User.current();
};

export const refreshLoggedInUser = () => {
  const currentUser = getLoggedInUser();
  if (currentUser) {
    currentUser.fetch();
  }
};

export const logout = async() => {
  return Parse.User.logOut();
};

export const login = async(email: string, password: string) => {
  return Parse.User.logIn(email, password);
};
