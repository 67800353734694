import React from 'react';
import './index.scss';

const DriverEarnings = props => {
  return (
    <div className="driver-earnings">
      <div className="row">
        <div className="col-12">
          <div className="text-muted font-weight-bold">Driver Earnings</div>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-block">
                <div className="row">
                  <div className="col-7">
                    <div>Total Earning</div>
                    <div className="earning-amount font-weight-bold">$ { props.totalEarnings || 0 }</div>
                  </div>
                  <div className="col d-flex justify-content-center align-items-center pl-0">
                    <img src={require('assets/bar-chart.png')} alt="bar-chart" className="bar-chart"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm mt-2 mt-sm-0">
              <div className="info-block">
                <div>Uva Bonus</div>
                <div className="earning-amount font-weight-bold">$ { props.totalUvaBonus || 0 }</div>
              </div>
            </div>
            <div className="col-12 col-sm mt-2 mt-sm-0">
              <div className="info-block">
                <div>Tips</div>
                <div className="earning-amount font-weight-bold">$ { props.totalTips || 0 }</div>
              </div>
            </div>
            <div className="col-12 col-sm mt-2 mt-sm-0">
              <div className="info-block">
                <div>Tolls</div>
                <div className="earning-amount font-weight-bold">$ { props.totalTolls || 0 }</div>
              </div>
            </div>
            <div className="col-12 col-sm mt-2 mt-sm-0">
              <div className="info-block">
                <div>Distance</div>
                <div className="earning-amount font-weight-bolder">$ { props.totalDistanceBonus || 0 }</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverEarnings;
