import { Parser } from 'json2csv';

export const convertJSONtoCSV = (fields: string[], jsonFile: Record<string, any>[]) => {
  const opts = { fields };

  try {
    const parser = new Parser(opts);
    const csv = parser.parse(jsonFile);
    return csv;
  } catch (err) {
    console.error(err);
  }
};

export const downloadFile = (file: any, filename: string) => {
  const link = document.createElement('a');
  if (file == null) return;

  if (!file.match(/^data:text\/csv/i)) {
    file = `data:text/csv;charset=utf-8,${file}`;
  }

  link.setAttribute('href', encodeURI(file));
  link.setAttribute('download', `${filename}.csv`);
  link.click();
};