import ActionButton from 'components/ActionButton/ActionButton';
import DataTableFilter from 'components/DataTableFIlter';
import { Loading } from 'components/loading/Loading';
import ToggleSwitch from 'components/ToggleSwitch';
import { tableStyles } from 'css/tableStyles';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { fetchJobs, getJobServerInstances, toggleJobActiveStatus } from 'services/api/cronJobs';
import TableLink from 'TableLink';
import { DATE_FORMATS, formatDate } from 'utils';
import { showError, showSuccess } from 'utils/notifications';

const CronJobs = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [activeLoading, setActiveLoading] = useState<string>('');
  const [serverInstances, setServerInstances] = useState<string[]>([]);
  const [cronJobs, setCronJobs] = useState<Record<string, any>>({});
  const [switching,setSwitching] = useState<boolean>(false);
  const [filterText, setFilterText] = React.useState('');

  const fetchCronJobs = async () => {
    try {
      const response = await fetchJobs();
      setCronJobs(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchServerInstances = async () => {
    try {
      const response = await getJobServerInstances();
      setServerInstances(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServerInstances();
    fetchCronJobs();
  }, []);

  const toggleCronJob = async (jobDetails: Record<string, any>) => {
    const {
      objectId: id,
      name,
      active,
    } = jobDetails;
    setActiveLoading(id);
    try {
      await toggleJobActiveStatus(id);
      await fetchCronJobs();
      showSuccess(`${name} marked ${active ? 'INACTIVE' : 'ACTIVE'} successfully`);
    } catch (e) {
      showError(e);
    } finally {
      setActiveLoading('');
    }
  };

  const columns = useMemo(() => [
    {
      name: 'Job ID',
      selector: 'objectId',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <TableLink
          linkTo={`/cron-jobs/${row.objectId}`}
          text={row.objectId}
          openInNewTab={false}
        />
      ),
    },
    {
      name: 'Created At',
      selector: 'createdAt',
      sortable: true,
      format: row => formatDate(row.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: false,
      width: '350px',
    },
    {
      name: 'Job Server Instance',
      selector: 'jobServerInstance',
      sortable: false,
    },
    {
      name: 'CRON Expression',
      selector: 'cronExpressionStr',
      sortable: false,
      format: row => row.cronExpressionStr ? row.cronExpressionStr : '-',
    },
    {
      name: 'Last Run Time',
      selector: 'lastRunTime',
      sortable: false,
      format: row => formatDate(row.lastRunTime?.iso, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
    },
    {
      name: 'Last Error Time',
      selector: 'lastErrorTime',
      sortable: false,
      format: row => row.lastErrorTime ? formatDate(row.lastErrorTime?.iso, DATE_FORMATS.MM_DD_YYYY_HH_MM_A) : '-',
    },
    {
      name: 'Active',
      selector: 'active',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <ToggleSwitch
          switchLabel={''}
          checked={row.active}
          disabled={activeLoading === row.objectId}
          onChange={() => toggleCronJob(row)}
        />
      ),
    },
  ], [activeLoading, loading]);

  const getGroupedDataTable = () => {
    if(cronJobs.length>0 && !switching){
      const dataFilterInput = <DataTableFilter filterText={filterText} setFilterText={setFilterText} placeholder={'Filter'} />;
      const filteredData = cronJobs.filter(job => { 
        return (job.name && job.name.toLowerCase().includes(filterText.toLowerCase()) || 
        job.jobServerInstance && job.jobServerInstance.toLowerCase().includes(filterText.toLowerCase()));
      });

      return (
        <DataTable
          title={'CRON Jobs'}
          keyField={'objectId'}
          columns={columns}
          data={filteredData}
          highlightOnHover={true}
          customStyles={tableStyles}
          actions={[dataFilterInput]}
        />
      );
    }else{
      return <div/>;
    }
  };

  const getSeperateDataTables=()=>{
    if(cronJobs.length>0 && switching){
      return(
        <div>
          {serverInstances.map(instance=>{
            const jobServerInstanceData= cronJobs.filter(job=>job.jobServerInstance===instance);
            if(jobServerInstanceData.length==0) return;
            return (
              <div key={instance}>
                <DataTable
                  title={`${instance.toUpperCase()} (${jobServerInstanceData.length})`}
                  keyField={'objectId'}
                  columns={columns}
                  data={jobServerInstanceData}
                  highlightOnHover={true}
                  customStyles={tableStyles}
                />
              </div>
            );
          })}
        </div>

      );
    }else return <></>;
  };

  const getActionButton=()=>{
    return (
      <ActionButton
        active={true}
        isLoading={loading}
        onClick={()=>setSwitching(!switching)}
        text={switching ? 'Group All' : 'Group By Job Instance'}
      />
    );
  };

  return (
    <div>
      {loading && <Loading />}
      {getActionButton()}
      {getGroupedDataTable()}
      {getSeperateDataTables()}
    </div>
  );
};

export default CronJobs;