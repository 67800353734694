import ActionButton from 'components/ActionButton/ActionButton';
import React, { useEffect, useState } from 'react';
import { uploadParseFile } from 'services/api/common';
import { showError, showSuccess } from 'utils/notifications';
import { ImagePicker } from 'react-file-picker';
import './index.scss';
import { isBase64 } from 'utils';

const ImageUpload = props => {
  const [file, setFile] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const {
    src,
    pickerText,
    onImageSelect,
    onImageUpload,
    showImageIcon = false,
    containerClass = '',
    forceShowUploadButton,
  } = props;

  useEffect(() => {
    if (src?.length) {
      setFile(src);
    }
  }, [src]);

  const isPreviewMode = () => {
    return isBase64(src) && forceShowUploadButton;
  };

  useEffect(() => {
    if (isPreviewMode()) {
      setShowUploadButton(true);
    }
  }, [src]);

  const onSelectFile = (base64) => {
    setFile(base64);
    setShowUploadButton(true);
    if (onImageSelect) {
      onImageSelect(base64);
    }
  };

  const onError = (error) => {
    setError(error.toString());
  };

  const uploadDocument = async () => {
    try {
      setLoading(true);
      const image = await uploadParseFile(file);
      onImageUpload(image);
      setShowUploadButton(false);
      setLoading(false);
      showSuccess('File uploaded successfully');
    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  const getImageContainer = () => {
    if (showImageIcon) {
      if (file) {
        return <img className="uploaded-image" src={file?.toString()} alt={'Item image'} />;
      } else {
        return <div className={'no-image-message'}>No Image</div>;
      }
    } else {
      return null;
    }
  };

  return (
    <>
      {getImageContainer()}
      <div className={`image-upload-container ${containerClass}`}>
        <ImagePicker
          extensions={['jpg', 'jpeg', 'png']}
          dims={{ minWidth: 100, maxWidth: 16000, minHeight: 100, maxHeight: 16000 }}
          maxSize={15}
          onChange={onSelectFile}
          onError={onError}
        >
          {
            showImageIcon ? <i className={'fa fa-pencil'} /> :
              <div className={'image-upload-picker-text pt-2 pb-2'}>{pickerText}</div>
          }
        </ImagePicker>
        {showUploadButton ? (
          <ActionButton
            text={'Upload'}
            active={true}
            onClick={uploadDocument}
            isLoading={loading}
            customClass={'btn btn-primary image-upload-button'}
          />
        ) : null}
      </div>
    </>
  );
};

export default ImageUpload;