import ActionButton from 'components/ActionButton/ActionButton';
import BackButton from 'components/BackButton';
import JobServerInstanceDropdown from 'components/JobServerInstanceDropdown';
import { Loading } from 'components/loading/Loading';
import React, { useEffect, useState } from 'react';
import { fetchCronJobDetails, setJobCronExpression, setJobParams, setJobServerInstance,toggleJobActiveStatus } from 'services/api/cronJobs';
import { DATE_FORMATS, formatDate } from 'utils';
import { showError, showSuccess } from 'utils/notifications';
import './index.scss';
import JSONInput from 'react-json-editor-ajrm';
import { isObject } from 'lodash';

const CronJobDetails = props => {
  const [jobDetails, setJobDetails] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [activeLoading, setActiveLoading] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [editJob, setEditJob] = useState<boolean>(false);
  const { jobId } = props.match.params;
  
  const fetchCronJobDetailsFn = async () => {
    try {
      const response = await fetchCronJobDetails(jobId);
      setJobDetails(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCronJobDetailsFn();
  }, []);

  const toggleCronJob = async (active: boolean) => {
    setActiveLoading(true);
    try {
      await toggleJobActiveStatus(jobId);
      setJobDetails({ ...jobDetails, active });
      showSuccess(`${jobDetails.name} marked ${!active ? 'INACTIVE' : 'ACTIVE'} successfully`);
    } catch (e) {
      showError(e);
    } finally {
      setActiveLoading(false);
    }
  };

  const updateServerInstance = async () => {
    setEditLoading(true);
    try { 
      if (jobDetails.jobServerInstance) {
        await setJobServerInstance(jobId, jobDetails.jobServerInstance);
      }
      if (jobDetails.cronExpression) {
        await setJobCronExpression(jobId, jobDetails.cronExpression);
      }
      if (jobDetails.params) {
        debugger;
        await setJobParams(jobId, isObject(jobDetails.params) ? jobDetails.params : JSON.parse(jobDetails.params));
      }
      setEditJob(false);
      await fetchCronJobDetailsFn();
      showSuccess('Job updated successfully');

    } catch (e) {
      showError(e);
    } finally {
      setEditLoading(false);
    }
  };
  
  const getCronJobHealthStatus=() => {
    return jobDetails?.error ?'UNHEALTHY':'HEALTHY';
  };

  const getJobDetails = () => {
    const getRightPanel = () => (
      <div className={''}>
        <div className={'checkbox-container'}>
          {activeLoading ? (
            <Loading type={'mini'} />
          ) : (
            <input type={'checkbox'}
              checked={jobDetails.active}
              disabled={activeLoading}
              onChange={e => toggleCronJob(e.target.checked)}
            />
          )}
          <div className={'info'}>Active</div>
        </div>
        <div className={'info-group'}>
          <div className={'label'}>Created At</div>
          <div className={'info'}>
            {jobDetails.createdAt ? formatDate(jobDetails.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A) : '-'}
          </div>
        </div>
        <div className={'info-group'}>
          <div className={'label'}>Last Run Time</div>
          <div className={'info'}>
            {jobDetails.lastRunTime ? formatDate(jobDetails.lastRunTime?.iso, DATE_FORMATS.MM_DD_YYYY_HH_MM_A) : '-'}
          </div>
        </div>
        <div className={'info-group'}>
          <div className={'label'}>Last Error Time</div>
          <div className={'info'}>
            {jobDetails.lastErrorTime ? formatDate(jobDetails.lastErrorTime?.iso, DATE_FORMATS.MM_DD_YYYY_HH_MM_A) : '-'}
          </div>
        </div>
        <div className={'info-group'}>
          <div className={`${'status'} ${getCronJobHealthStatus()}`}>
            {getCronJobHealthStatus()}
          </div>
        </div>
      </div>
    );

    const handleCronExpChange = (value: string) => {
      setJobDetails({ ...jobDetails, cronExpression: value });
    };

    const getJobParams =() => {
      return (
        <div className={'info-group'}>
          <div className={'label'}>CRON Job Params</div>
          <JSONInput
            placeholder={typeof jobDetails.params === 'object' ? jobDetails.params : JSON.parse(jobDetails.params || '{}')}
            height='500px'
            width='110%'
            viewOnly={!editJob}
            waitAfterKeyPress={3000}
            onChange={e => setJobDetails({ ...jobDetails, params:JSON.stringify(e?.jsObject) })}
          />
        </div>
      );
    };
    const getLeftPanel = () => (
      <div className={'d-flex flex-column w-50'}>
        <div className={'info-group'}>
          <div className={'label'}>Job ID</div>
          <div className={'info'}>{jobId}</div>
        </div>
        <div className={'info-group'}>
          <div className={'label'}>Job Name</div>
          <div className={'info'}>{jobDetails.name || '-'}</div>
        </div>
        <div className={'info-group'}>
          <div className={'label'}>Job Server Instance</div>
          {editJob ? (
            <JobServerInstanceDropdown
              value={jobDetails.jobServerInstance}
              disabled={editLoading}
              onChange={jobServerInstance => setJobDetails({ ...jobDetails, jobServerInstance })}
            />
          ): (
            <div className={'info'}>{jobDetails.jobServerInstance || '-'}</div>
          )}
        </div>
        <div className={'info-group'}>
          <div className={'label'}>CRON Expression</div>
          {editJob ? (
            <input
              className={'form-control'}
              value={jobDetails.cronExpression}
              disabled={!editJob}
              onChange={e => handleCronExpChange(e.target.value)}
            />
          ) :
            (
              <div className={'info'}>{jobDetails.cronExpression || '-'}</div>
            )}
        </div>
        <div className={'info-group'}>
          <div className={'label'}>CRON Expression String</div>
          <div className={'info'}>{jobDetails.cronExpressionStr || '-'}</div>
        </div>
        {getJobParams()}
        <div className={'info-group'}>
          <div className={'label'}>Error</div>
          <div className={'info'}>{jobDetails.error || '-'}</div>
        </div>
      </div>
    );

    const getEditButton = () => (
      <div className={'d-flex w-100 justify-content-end mb-2 mt-n2'}>
        {editJob ? (
          <div>
            <ActionButton
              text={'Save'}
              active={!editLoading}
              isLoading={editLoading}
              onClick={updateServerInstance}
              customClass={'btn btn-primary mr-3'}
            />
            <ActionButton
              text={'Cancel'}
              active={!editLoading}
              isLoading={editLoading}
              onClick={e => { setEditJob(false) }}
              customClass={'btn btn-outline-secondary mr-3'}
            />
          </div>
        ) : (
          <div
            className={'edit-button'}
            onClick={() => setEditJob(true)}
          >
            <i className={'fa fa-pencil-square-o'} />
            <span>Edit</span>
          </div>
        )}
      </div>
    );
    
    return (
      <div className={'cronjob-basic-details-section'}>
        {getEditButton()}
        <div className={'d-flex'}>
          {getLeftPanel()}
          {getRightPanel()}
        </div>
      </div>
    );
  };
  
  const getHeader = () => (
    <div className={'header'}>
      <BackButton />
      <h4>CRON Job Details</h4>
    </div>
  );

  if (loading) return <Loading />;
  
  return (
    <div className={'cronjob-details-main-container'}>
      {getHeader()}
      {getJobDetails()}
    </div>
  );
};

export default CronJobDetails;