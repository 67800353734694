import { cloudFunction } from 'services/parse';
import { getPageLimit } from 'utils';
import { ILead } from 'interfaces/lead';

export const fetchLeads = (filters: ILead.ILeadFilter, sort = {}) => {
  return cloudFunction('fetchLeads', {
    limit: getPageLimit(),
    ...filters,
    sort,
  });
};

export const fetchLeadDetail = async (id: string) => {
  const leadDetail = await cloudFunction('fetchLeadDetail', { id });
  // Parse into the json. Lets not use any Parse specific syntax in the main code
  return {
    lead: leadDetail.lead.toJSON(),
    documents: leadDetail.documents.map((d: any) => d.toJSON()),
  } as ILead.ILeadDetail;
};

export const updateLead = (id: string, data) => {
  return cloudFunction('updateLead', { id, data });
};


export const approveDocument = async (id: string): Promise<boolean> => {
  const document: Parse.Object = await cloudFunction('approveDocument', { id });
  return document.get('isApproved');
};

export const unApproveDocument = async (id: string, reason: string): Promise<boolean> => {
  const document: Parse.Object = await cloudFunction('unApproveDocument', { id, reason });
  return document.get('isApproved');
};

export const uploadDocumentForUser = async (userId: string, documentCode: string, image: string): Promise<void> => {
  await cloudFunction('uploadDocumentForUser', { userId, documentCode, image });
};

export const convertToDriver = async (id: string): Promise<ILead.IUserDetail> => {
  const user: Parse.Object = await cloudFunction('convertToDriver', { id });
  const userJSON = user.toJSON();
  return userJSON as unknown as ILead.IUserDetail;
};

export const addLeadNote = async (leadId: string, note: string) => {
  const response = await cloudFunction('addLeadNote', { leadId, note });
  return response;
};

export const fetchAgentList = async () => {
  let agentList = await cloudFunction('fetchAgentList', { selectedFields: ['full_name'] });
  agentList = agentList.map(agent => agent.toJSON());
  return agentList;
};

export const assignAgent = (leadId: string, agentId: string) => {
  return cloudFunction('assignAgent', { leadId, agentId });
};

export const fetchActivityLogs = async (leadId: string) => {
  let activityLogs = await cloudFunction('fetchLeadActivities', { leadId });
  activityLogs = activityLogs.map(log => log.toJSON());
  return activityLogs;
};

export const updateUserDetail = (userId: string, data: Record<string, any>) => {
  return cloudFunction('updateUserDetail', { userId, data });
};

export const contactLead = (leadId: string) => {
  return cloudFunction('contactLead', { leadId });
};

export const toggleInterestedFlag = (leadId: string) => {
  return cloudFunction('toggleInterestedFlag', { leadId });
};

export const toggleDeclinedFlag = (leadId: string) => {
  return cloudFunction('toggleDeclinedFlag', { leadId });
};

export const setExpirationDateForDocument = (id: string, expirationDate: string) => {
  return cloudFunction('setExpirationDateForDocument', { id, expirationDate });
};

export const searchLeadByName = async (term: string) => {
  let response = await cloudFunction('searchLeadByName', { term });
  response = response.map(res => {
    return {
      full_name: res.get('full_name'),        // eslint-disable-line
      id: res.id,
    };
  });
  return response;
};

export const deleteDocumentForUser = (id: string) => {
  return cloudFunction('deleteDocumentForUser', { id });
};

export const fetchLeadEventNames = () => {
  return cloudFunction('fetchLeadEventNames', {});
};