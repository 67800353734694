import { ILead } from 'interfaces';
import React, { useEffect, useState } from 'react';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import { contactLead, toggleDeclinedFlag, toggleInterestedFlag,updateLead } from 'services';
import Form from 'react-bootstrap/Form';
import { showError, showSuccess } from 'utils/notifications';

// eslint-disable-next-line max-len
const LeadInformation = ({ lead, user, convertToDriver, loading }: { lead: any; user: ILead.IUserDetail; convertToDriver: Function; loading: boolean }) => {
  const [isInterested, setIsInterested] = useState<string>('');
  const [isDeclined, setIsDeclined] = useState<string>('');
  const [email, setEmail] = useState<string>();
  const [updateLoading,setUpdateLoading] = useState<boolean>(false);

  const [isInterestedLoading, setIsInterestedLoading] = useState(false);
  const [isDeclinedLoading, setIsDeclinedLoading] = useState(false);
  const [contactedBtnLoading, setContactedBtnLoading] = useState(false);

  useEffect(() => {
    switch (lead.isInterested) {
    case undefined:
      setIsInterested('not set');
      break;
    case true:
      setIsInterested('interested');
      break;
    case false:
      setIsInterested('not interested');
      break;
    }

    switch (lead.isDeclined) {
    case undefined:
      setIsDeclined('not set');
      break;
    case true:
      setIsDeclined('declined');
      break;
    case false:
      setIsDeclined('not declined');
      break;
    }
  }, []);

  const contactLeadFn = async () => {
    setContactedBtnLoading(true);
    try {
      await contactLead(lead.objectId);
      showSuccess('Lead contacted');
    } catch (e) {
      showError(e);
    } finally {
      setContactedBtnLoading(false);
    }
  };

  const toggleIsInterestedFn = async () => {
    setIsInterestedLoading(true);
    try {
      await toggleInterestedFlag(lead.objectId);
      showSuccess('Interested flag changed successfully');
    } catch (e) {
      showError(e);
    } finally {
      setIsInterestedLoading(false);
    }
  };

  const toggleIsDeclinedFn = async () => {
    setIsDeclinedLoading(true);
    try {
      await toggleDeclinedFlag(lead.objectId);
      showSuccess('Declined flag changed successfully');
    } catch (e) {
      showError(e);
    } finally {
      setIsDeclinedLoading(false);
    }
  };

  const isInterestedToggled = (value: string) => {
    setIsInterested(value);
    toggleIsInterestedFn();
  };

  const isDeclinedToggled = (value: string) => {
    setIsDeclined(value);
    toggleIsDeclinedFn();
  };

  const updateLeadDetailsFn =async ()=>{
    setUpdateLoading(true);
    try {
      await updateLead(lead.objectId,{ email });
      showSuccess('Lead Details updated successfully');
    } catch (e) {
      showError(e);
    } finally {
      setUpdateLoading(false);
    }
  };

  return (
    <div className="lead-information">
      <h4 className="pl-2 font-weight-bolder">Lead Information</h4>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-8">
              <div className='label'>Name</div>
              <div className="info font-weight-bolder">{lead.full_name}</div>
              <div className='label'>Email</div>
              <div className="info d-flex">
                <input type="email" className="form-control mt-1 email-input" defaultValue={lead.email}
                  onChange={event => setEmail(event.target.value)}/>
                <ActionButton 
                  active={!updateLoading}
                  isLoading={updateLoading}
                  text={'Update Email'}
                  onClick={updateLeadDetailsFn}
                  customClass={'btn-primary ml-1 mt-1'}
                />
              </div>
              {lead.facebookLeadId && <>
                <div className="label">Source</div>
                <div className='info font-weight-bolder'>Facebook</div>
                <div className='info font-weight-bolder'>
                  {lead.facebookLeadId}
                </div>
              </>
              }
              <div className='label'>City</div>
              <div className="info font-weight-bolder">{lead.city}</div>

              {lead.mobileOS && <>
                <div className='label'>Mobile OS</div>
                <div className="info font-weight-bolder">{lead.mobileOS}</div>
              </>
              }

              <div className="label">Phone Number</div>
              <div className="info font-weight-bolder">{lead.phone}</div>

              <div className="label">Served for other?</div>
              <div className="info font-weight-bolder">{lead.hasServedForOther}</div>

              {lead.transportMethod && <>
                <div className="mt-1 font-weight-bolder title">Transport Method</div>
                <div className="info font-weight-bolder">{lead.transportMethod}</div>
              </>
              }
              
            </div>
            <div className="col text-center">
              <div className="d-inline-block">
                <div className='interested-section'>
                  <span>Interested</span>
                  <Form.Control
                    as={'select'}
                    value={isInterested}
                    disabled={isInterestedLoading}
                    onChange={e => isInterestedToggled(e.target.value)}
                  >
                    <option hidden={isInterested !== 'not set'} value={'not set'}>Not set</option>
                    <option value={'not interested'}>Not Interested</option>
                    <option value={'interested'}>Interested</option>
                  </Form.Control>
                </div>
                <div className='declined-section'>
                  <span>Declined</span>
                  <Form.Control
                    as={'select'}
                    value={isDeclined}
                    disabled={isDeclinedLoading}
                    onChange={e => isDeclinedToggled(e.target.value)}
                  >
                    <option hidden={isInterested !== 'not set'} value={'not set'}>Not set</option>
                    <option value={'not declined'}>Not Declined</option>
                    <option value={'declined'}>Declined</option>
                  </Form.Control>
                </div>
                <ActionButton
                  text={'Contacted'}
                  active={true}
                  isLoading={contactedBtnLoading}
                  customClass={'btn-primary lead-contacted-btn'}
                  onClick={contactLeadFn}
                />
                <ActionButton isLoading={loading} active={true} text={'Resend Interested Email'} onClick={() => { }} customClass="btn-primary resend-mail-btn" />
                <div className="resend-email-helper text-muted">
                  if the lead hasn’t received the interested email, you can resend the email here
                </div>
              </div>
              <div className="convert-to-driver">
                { user && !user.isDriver && <button className="btn btn-link" onClick={() => convertToDriver()}>Convert to Driver</button> }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { LeadInformation };
