import React, { useEffect, useState } from 'react';
import TimeKeeper from 'react-timekeeper';
import { convertFrom24To12HrFormat } from 'utils';
import './index.scss';

const TimePicker = props => {
  const [time, setTime] = useState<string>('00:00');
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const { value, onChange } = props;
  
  useEffect(() => {
    if (value) setTime(value);
  }, []);
  
  const getDisplayValue = (value: any) => convertFrom24To12HrFormat(value, true);
  
  return (
    <div className={'time-picker-container'}>
      {showPicker ? (
        <TimeKeeper
          time={time}
          onChange={time => setTime(time.formatted24)}
          onDoneClick={() => {
            onChange(time);
            setShowPicker(false);
          }}
          switchToMinuteOnHourSelect={true}
        />
      ): (
        <input
          readOnly
          className={'time-picker-input'}
          value={value ? getDisplayValue(value) : '-'}
          onClick={() => setShowPicker(true)}
        />
      )}
    </div>
  );
};

export default TimePicker;