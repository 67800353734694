import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Loading } from '../../../components/loading/Loading';
import { tableStyles } from '../../../css/tableStyles';
import styles from './index.module.scss';
import ActionButton from 'components/ActionButton/ActionButton';
import { DATE_FORMATS, formatDate, getPageLimit, updatePageLimit } from 'utils';
import TableLink from 'TableLink';
import { showError } from 'utils/notifications';
import { fetchPaginatedDiscounts, updateDiscount } from 'services/api/discounts';
import ToggleSwitch from 'components/ToggleSwitch';
import qs from 'query-string';
import Filter from './Filter';

const Discounts = props => {
  let filters: { [p: string]: any; page: number } = {
    page: 0,
  };
  const [loading, setLoading] = useState<boolean>(true);
  const [discounts, setDiscounts] = useState<Record<string, any>>({});
  const [activeLoading, setActiveLoading] = useState<boolean>(false);
  const toggleDiscountStatus = async (row: any) => {
    setActiveLoading(true);
    try {
      await updateDiscount({ id: row.objectId, active: !row?.active });
      fetchDiscountsFn();
    } catch (e) {
      showError(e);
    } finally {
      setActiveLoading(false);
    }
  };
  const fetchDiscountsFn = async () => {
    setLoading(true);
    const { page, ...rest } = filters;
    try {
      const response = await fetchPaginatedDiscounts({
        page,
        limit: getPageLimit(),
        filter: { ...rest },
      });
      setDiscounts(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  useEffect(() => {
    filters = {
      ...filters,
      ...queryStringToFilter(),
    };
    fetchDiscountsFn();
  }, []);

  const columns = useMemo(() => [
    {
      name: 'Discount Id',
      selector: 'objectId',
      sortable: false,
      width: '150px',
      // eslint-disable-next-line react/display-name
      cell: row =>
        <TableLink
          text={row.objectId}
          linkTo={`/discounts/${row.objectId}`}
        />,
    },
    {
      name: 'Created At',
      selector: 'createdAt',
      sortable: false,
      width: '170px',
      format: (row) => formatDate(row.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
    },
    {
      name: 'Discount Code',
      selector: 'code',
      width: '200px',
    },
    {
      name: 'Discount Type',
      selector: 'type',
      width: '240px',
    },
    {
      name: 'Discount Percent(%)',
      selector: 'discountPercent',
      width: '100px',
      cell: row => row?.discountPercent ?? '-',
    },
    {
      name: 'Discount Amount',
      selector: 'discountAmount',
      width: '100px',
      cell: row => row?.discountAmount ?? '-',
    },
    {
      name: 'User Limit',
      selector: 'userLimit',
      width: '100px',
      cell: row => row?.userLimit ?? '-',
    },
    {
      name: 'Global Limit',
      selector: 'globalLimit',
      width: '100px',
      cell: row => row?.globalLimit ?? '-',
    },
    {
      name: 'Start Date',
      selector: 'startDate',
      width: '120px',
      cell: row => formatDate(row?.startDate, DATE_FORMATS.MM_DD_YYYY_HH_MM_A) ?? '-',
    },
    {
      name: 'End Date',
      selector: 'endDate',
      width: '120px',
      cell: row => formatDate(row?.endDate, DATE_FORMATS.MM_DD_YYYY_HH_MM_A) ?? '-',
    },
    {
      name: 'Active',
      selector: 'active',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <ToggleSwitch
          switchLabel={''}
          checked={row.active}
          disabled={activeLoading}
          onChange={() => toggleDiscountStatus(row)}
        />
      ),
    },
  ], []);

  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    fetchDiscountsFn();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    filters.limit = rowsPerPage;
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  const onFilterSubmit = (updatedFilter: Record<string, any>) => {
    if (updatedFilter.discountId) {
      props.history.push(`/discounts/${updatedFilter.discountId}`);
    } else {
      filters = { ...updatedFilter, page: 0 };
      updateQueryString();
      fetchDiscountsFn();
    }
  };

  return (
    <div className={`${styles['main-container']}`}>
      {loading ? <Loading /> : null}
      <div className={styles['header']}>
        <div className={styles['heading']}>Discounts</div>
        <ActionButton
          text={<>Discount <span>+</span></>}
          active={true}
          isLoading={false}
          onClick={() => props.history.push('/discounts/create')}
        />
      </div>
      <Filter {...props} onFilterSubmit={onFilterSubmit} />
      <div className={styles['data-table']}>
        <DataTable
          keyField="objectId"
          columns={columns}
          noHeader={true}
          data={discounts.items}
          highlightOnHover={true}
          customStyles={tableStyles}
          pagination={true}
          paginationServer={true}
          paginationPerPage={getPageLimit()}
          paginationDefaultPage={(filters.page || 0) + 1}
          paginationTotalRows={discounts.total}
          paginationRowsPerPageOptions={[50, 100, 200]}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default Discounts;
