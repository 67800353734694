import ActionButton from 'components/ActionButton/ActionButton';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { showError } from 'utils/notifications';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Error } from 'components/message/Error';
import { resendReceiptToNewEmail } from 'services/api/order';

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
});

type FormData = {
  email: string;
}

const ResendReceiptModal = props => {
  const { register, handleSubmit, errors } = useForm<FormData>({ resolver: yupResolver(validationSchema) });
  const [loading, setLoading] = useState(false);
  const { modalOpen, orderId, onCloseModal } = props;

  const sendReceipt = handleSubmit(async ({ email }) => {
    setLoading(true);
    try {
      await resendReceiptToNewEmail(orderId, email);
      onCloseModal();
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal  show={modalOpen} onHide={onCloseModal} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title>Resend Receipt</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          className="w-100 form-control"
          placeholder={'Email'}
          ref={register}
          name={'email'}
        />
        {errors.email && <Error message={errors.email.message} />}
      </Modal.Body>
      <Modal.Footer>
        <ActionButton
          text={'Close'}
          active={true}
          isLoading={false}
          onClick={onCloseModal}
        />
        <ActionButton
          text={'Submit'}
          active={true}
          onClick={sendReceipt}
          isLoading={loading}
          customClass="btn-primary"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ResendReceiptModal;
