import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { fetchDeferredOrders } from 'services/api/order';
import TableLink from 'TableLink';
import { DATE_FORMATS, formatDate, fromStripeAmount } from 'utils';
import { showError } from 'utils/notifications';

const columns = [
  {
    name: 'Order ID',
    selector: 'objectId',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/deferred-orders/${row.objectId}`}
        text={row.objectId}
        openInNewTab={false}
      />
    ),
  },
  {
    name: 'Order Number',
    selector: 'orderNumber',
    sortable: false,
  },
  {
    name: 'Date Time',
    selector: 'createdAt',
    sortable: false,
    format: row => formatDate(row.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
  },
  {
    name: 'Client',
    selector: 'user.full_name',
    sortable: false,
  },
  {
    name: 'Restaurant',
    selector: 'restaurant.restaurant_name',
    sortable: false,
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: false,
    format: row => `$${fromStripeAmount(row.total)}`,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
  },
];

const DeferredOrders = props => {
  const [orders, setOrders] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);

  const fetchDeferredOrdersFn = async () => {
    setLoading(true);
    try {
      const response = await fetchDeferredOrders();
      setOrders(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeferredOrdersFn();
  }, []);

  return (
    <div>
      {loading && <Loading />}
      <DataTable
        title="Deferred Orders"
        keyField="objectId"
        columns={columns}
        data={orders.items}
        highlightOnHover={true}
        defaultSortField="createdAt"
        customStyles={tableStyles}
        pagination={false}
      />
    </div>
  );
};


export { DeferredOrders };
