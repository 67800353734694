import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Currency from 'components/Currency';
import { convertNestedObjectsToFlatArrayForVariantSpecs } from 'utils';

const DishVariant = (props) => {
  const {
    variant,
    dishSelected,
    handleVariantChange,
    disableReasonSelect,
    variantDisabled,
    depth,
    variantsForRefund,
  } = props;
  const [selected, setSelected] = useState<boolean>(false);
  useEffect(() => {
    setSelected(variantsForRefund?.includes(variant?.id));
  }, [variantsForRefund]);
  const handleVariantSelectedChange = (
    variantSelected: boolean,
    value: string,
  ) => {
    const newVariantsForRefund = [...variantsForRefund];
    setSelected(variantSelected);
    disableReasonSelect(!variantSelected);
    const flattenedVaraintSpecs = convertNestedObjectsToFlatArrayForVariantSpecs(variant);
    if (variantSelected) {
      const index = newVariantsForRefund.indexOf(variant.id);
      // if variant is not selected before, add it in selected array
      if (index === -1) newVariantsForRefund.push(value);
      // adding all child variants to selected variants
      flattenedVaraintSpecs?.forEach((item) => {
        const index = newVariantsForRefund.indexOf(item.id);
        if (index === -1) newVariantsForRefund.push(item.id);
      });
      handleVariantChange(newVariantsForRefund);
    } else {
      // remove all childs if parent is deselected
      flattenedVaraintSpecs?.forEach((item) => {
        const index = newVariantsForRefund.indexOf(item.id);
        if (index !== -1) newVariantsForRefund.splice(index, 1);
      });
      const index = newVariantsForRefund.indexOf(variant.id);
      if (index > -1) newVariantsForRefund.splice(index, 1);
      handleVariantChange(newVariantsForRefund);
    }
  };

  return (
    <div className={'variant-choice'}>
      <div
        style={{ paddingLeft: depth * 10 }}
        className={`variant-name ${
          variantDisabled && 'refunded-strikethrough'
        }`}
      >
        {variant.name}
      </div>
      <div className={'variant-price'}>${variant.price}</div>
      <input
        type={'checkbox'}
        checked={selected || dishSelected}
        disabled={dishSelected || variantDisabled}
        value={variant.id}
        onChange={(e) =>
          handleVariantSelectedChange(e.target.checked, e.target.value)
        }
      />
    </div>
  );
};

const SinglePlateItem = (props) => {
  const {
    index,
    dish,
    refundPlateList,
    setRefundPlateList,
    getRefundReasonOptions,
    description,
  } = props;
  const [selected, setSelected] = useState<boolean>(false);
  const [selectedRefundReason, setSelectedRefundReason] = useState<string>('');
  const [variantsForRefund, setVariantsForRefund] = useState<string[]>([]);
  const [reasonSelectDisabled, setReasonSelectDisabled] =
    useState<boolean>(true);
  const plateRefundDisabled = description.refundStatus === 'REFUNDED';
  
  const addPropertyToRefundInfo = (propertyKey: string, propertyValue: any) => {
    const copyOfRefundPlateList = JSON.parse(JSON.stringify(refundPlateList));
    const indexOfItem = _.findIndex(
      copyOfRefundPlateList,
      (dishItem: Record<string, any>) => {
        return dishItem.dishDescription.id === dish.dishesDescription[index].id;
      },
    );

    if (indexOfItem > -1) {
      if (propertyKey !== 'id') {
        copyOfRefundPlateList.splice(indexOfItem, 1, {
          ...copyOfRefundPlateList[indexOfItem],
          dishDescription: {
            ...copyOfRefundPlateList[indexOfItem].dishDescription,
            [propertyKey]: propertyValue,
          },
        });
      } else {
        copyOfRefundPlateList.splice(indexOfItem, 1, {
          id: propertyValue,
          dishDescription: {
            id: dish.dishesDescription[index].id,
            reason: selectedRefundReason,
          },
        });
      }
    } else {
      if (propertyKey !== 'id') {
        if (propertyKey === 'reason') return;
        copyOfRefundPlateList.push({
          id: dish.id,
          dishDescription: {
            id: dish.dishesDescription[index].id,
            [propertyKey]: propertyValue,
            reason: selectedRefundReason,
          },
        });
      } else {
        copyOfRefundPlateList.push({
          id: propertyValue,
          dishDescription: {
            id: dish.dishesDescription[index].id,
            reason: selectedRefundReason,
          },
        });
      }
    }
    setRefundPlateList(copyOfRefundPlateList);
  };

  const removePropertyFromRefundInfo = () => {
    const copyOfRefundPlateList = JSON.parse(JSON.stringify(refundPlateList));
    const indexOfItem = _.findIndex(
      copyOfRefundPlateList,
      (dishItem: Record<string, any>) => {
        return dishItem.dishDescription.id === dish.dishesDescription[index].id;
      },
    );
    copyOfRefundPlateList.splice(indexOfItem, 1);
    setRefundPlateList(copyOfRefundPlateList);
  };

  const handleRefundReasonSelect = (value: string) => {
    setSelectedRefundReason(value);
    addPropertyToRefundInfo('reason', value);
  };

  const handleVariantChange = (value: string[]) => {
    if (value.length > 0) {
      addPropertyToRefundInfo('specIds', value);
      setVariantsForRefund(value);
    } else {
      removePropertyFromRefundInfo();
    }
  };

  const handleDishSelectedChange = (addDish: boolean, value: string) => {
    setSelected(addDish);
    setReasonSelectDisabled(!addDish);
    if (addDish) {
      addPropertyToRefundInfo('id', value);
    } else {
      if (variantsForRefund.length > 0) {
        addPropertyToRefundInfo('specIds', variantsForRefund);
      } else {
        removePropertyFromRefundInfo();
      }
    }
  };

  const getDishVariant = (dishesDescription, depth = 0) => {
    return (
      <div className={'variant-choice-container'}>
        {dishesDescription.specs.map((variant, i) => {
          return (
            <>
              <DishVariant
                key={i}
                depth={depth}
                dishSelected={selected}
                variant={variant}
                handleVariantChange={handleVariantChange}
                setVariantsForRefund={setVariantsForRefund}
                variantsForRefund={variantsForRefund}
                disableReasonSelect={setReasonSelectDisabled}
                variantDisabled={
                  plateRefundDisabled || variant.refundStatus === 'REFUNDED'
                }
              />
              {variant?.specs?.length
                ? getDishVariant(variant, depth + 1)
                : null}
            </>
          );
        })}
      </div>
    );
  };

  return (
    <div className={'single-plate-item'}>
      <div className={'item-choice'}>
        <div
          className={`item-name ${
            plateRefundDisabled && 'refunded-strikethrough'
          }`}
        >
          {dish.name}
        </div>
        <div className={'item-price'}>
          <Currency amount={parseFloat(dish.price.toFixed(2))} />
        </div>
        <input
          type={'checkbox'}
          checked={selected}
          value={dish.id}
          disabled={plateRefundDisabled}
          onChange={(e) =>
            handleDishSelectedChange(e.target.checked, e.target.value)
          }
        />
      </div>
      {dish.dishesDescription?.length &&
      dish.dishesDescription[index].specs.length ? (
          getDishVariant(dish.dishesDescription[index])
        ) : (
          <div />
        )}
      <div className={'refund-reason-section'}>
        <label>Reason</label>
        <select
          className={'form-control'}
          value={selectedRefundReason}
          disabled={reasonSelectDisabled || plateRefundDisabled}
          onChange={(e) => handleRefundReasonSelect(e.target.value)}
        >
          <option hidden={!!selectedRefundReason} value={'default'}>
            Select an option
          </option>
          {getRefundReasonOptions()}
        </select>
      </div>
    </div>
  );
};

export default React.memo(SinglePlateItem);