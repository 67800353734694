import React, { useEffect, useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import qs from 'query-string';
import './index.scss';
import { DateRangePickerWrapper } from 'components/Date/DateRangePicker';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Error } from 'components/message/Error';
import NameFilterAutosuggest from 'components/NameFilterAutoSuggest';
import { searchUserByName } from 'services/api/order';
import { connect } from 'react-redux';
import { ORDER_STATUS, isUserShopper } from 'utils';
import RestaurantAutoSuggest from 'components/RestaurantAutoSuggest';
import Dropdown from 'components/Dropdown';

const ORDER_STATUS_OPTIONS = Object.keys(ORDER_STATUS);

const validationSchema = yup.object().shape({
  userEmail: yup.string().email(),
  driverEmail: yup.string().email(),
});

type FormData = {
  orderId: string;
  orderNumber: string;
  startDate: string;
  endDate: string;
  userEmail: string;
  userPhone: string;
  driverEmail: string;
  restaurantId: string;
  discountCode: string;
  routeId: string;
  orderStatus: string;
}

const mapStateToProps = (state: any) => ({
  user: state.auth.loggedInUser,
});

const OrdersFilter = props => {
  const { register, handleSubmit, errors } = useForm<FormData>({ resolver: yupResolver(validationSchema) });
  const [orderId, setOrderId] = useState<string>('');
  const [orderNumber, setOrderNumber] = useState<string>('');
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string>('');
  const [userPhone, setUserPhone] = useState<string>('');
  const [driverId, setDriverId] = useState<string>('');
  const [restaurantId, setRestaurantId] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [discountCode, setDiscountCode] = useState<string>('');
  const [routeId, setRouteId] = useState<string>('');
  const [orderStatus, setOrderStatus] = useState<string>('');
  const {
    showOrderNumberFilter,
    showUserNameFilter,
    showUserEmailFilter,
    showUserPhoneFilter,
    showDriverNameFilter,
    showRestaurantFilter,
    showDiscountCodeFilter,
    showRouteFilter,
  } = props;

  useEffect(() => {
    const {
      orderId,
      orderNumber,
      startDate,
      endDate,
      userEmail,
      userPhone,
      driverEmail: driverId,
      restaurantId,
      userId,
      discountCode,
      routeId,
      orderStatus,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });

    if (orderId) setOrderId(orderId.toString());
    if (orderNumber) setOrderNumber(orderNumber.toString());
    if (startDate) setStartDate(startDate.toString());
    if (endDate) setEndDate(endDate.toString());
    if (userEmail) setUserEmail(userEmail.toString());
    if (userPhone) setUserPhone(userPhone.toString());
    if (driverId) setDriverId(driverId.toString());
    if (restaurantId) setRestaurantId(restaurantId.toString());
    if (userId) setUserId(userId.toString());
    if (discountCode) setDiscountCode(discountCode.toString());
    if (routeId) setRouteId(routeId.toString());
    if (orderStatus) setOrderStatus(orderStatus.toString());
  }, []);

  const resetFilter = () => {
    setOrderId('');
    setOrderNumber('');
    setStartDate('');
    setEndDate('');
    setUserEmail('');
    setUserPhone('');
    setDriverId('');
    setRestaurantId('');
    setUserId('');
    setDiscountCode('');
    setRouteId('');
    setOrderStatus('');

    const filterData = {
      orderId: '',
      orderNumber: '',
      startDate: '',
      endDate: '',
      userEmail: '',
      userPhone: '',
      driverId: '',
      restaurantId: '',
      userId: '',
      discountCode: '',
      routeId: '',
      status: '',
    };

    props.onFilterSubmit(filterData);
  };

  const onFilterSubmit = handleSubmit(() => {
    const filterData: Record<string, any> = {
      orderId,
      orderNumber,
      startDate,
      endDate,
      userEmail,
      userPhone,
      driverId,
      restaurantId,
      userId,
      discountCode,
      routeId,
      status: orderStatus,
    };

    props.onFilterSubmit(filterData);
  });

  const onDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    setStartDate(moment(startDate).toISOString());
    setEndDate(moment(endDate).toISOString());
  };

  const _isUserShopper = isUserShopper(props.user?.get('roles'));

  return (
    <form className='filter-container'>
      <div className='input-container mb-2 mr-4'>
        <label>Order ID</label>
        <input
          className='form-control'
          placeholder={'Order ID'}
          value={orderId}
          onChange={e => setOrderId(e.target.value)}
        />
      </div>
      {!_isUserShopper && (
        <>
          {showUserNameFilter && (
            <div className='input-container mb-2 mr-4'>
              <label>User Name</label>
              <NameFilterAutosuggest
                entityId={userId}
                placeholder={'User Name'}
                onSuggestionSelected={setUserId}
                fetchSuggestions={searchUserByName}
              />
            </div>
          )}
          {showDriverNameFilter && (
            <div className='input-container mb-2 mr-4'>
              <label>Driver Name</label>
              <NameFilterAutosuggest
                entityId={driverId}
                placeholder={'Driver Name'}
                onSuggestionSelected={setDriverId}
                onlyDriver={true}
                fetchSuggestions={searchUserByName}
              />
            </div>
          )}
          {showOrderNumberFilter && (
            <div className='input-container mb-2 mr-4'>
              <label>Order Number</label>
              <input
                className='form-control'
                placeholder={'Order Number'}
                value={orderNumber}
                onChange={e => setOrderNumber(e.target.value)}
              />
            </div>
          )}
          {showUserEmailFilter && (
            <div className='input-container mb-2 mr-4'>
              <label>User Email</label>
              <input
                name={'userEmail'}
                ref={register}
                className='form-control'
                placeholder={'User Email'}
                value={userEmail}
                onChange={e => setUserEmail(e.target.value)}
              />
              {errors.userEmail && <Error message={errors.userEmail.message} />}
            </div>
          )}
          {showUserPhoneFilter && (
            <div className='input-container mb-2 mr-4'>
              <label>User Phone</label>
              <input
                className='form-control'
                placeholder={'User Phone'}
                value={userPhone}
                onChange={e => setUserPhone(e.target.value)}
              />
            </div>
          )}
          {showDiscountCodeFilter && (
            <div className='input-container mb-2 mr-4'>
              <label>Discount Code</label>
              <input
                className='form-control'
                placeholder={'Discount Code'}
                value={discountCode}
                onChange={e => setDiscountCode(e.target.value)}
              />
            </div>
          )}
          {showRouteFilter && (
            <div className='input-container mb-2 mr-4'>
              <label>Route Id</label>
              <input
                className='form-control'
                placeholder={'Route Id'}
                value={routeId}
                onChange={e => setRouteId(e.target.value)}
              />
            </div>
          )}
          <div className='input-container mb-2 mr-4'>
            <label>Date Range</label>
            <DateRangePickerWrapper
              startDate={startDate}
              endDate={endDate}
              onDatesChange={onDateSelectionChange}
            /> 
          </div>
        </>
      )}
      {showRestaurantFilter && (
        <>
          <div className='input-container mr-4 mb-2'>
            <label>Restaurant ID</label>
            <input
              value={_isUserShopper ? '' : restaurantId}
              onChange={e => setRestaurantId(e.target.value)}
              disabled={_isUserShopper}
              className={'form-control'}
            />
          </div>
          <div className='input-container mr-4 mb-2'>
            <label>Restaurant</label>
            <RestaurantAutoSuggest
              placeholder={'Search Restaurant'}
              onSuggestionSelect={suggestion => setRestaurantId(suggestion.id)}
              disabled={_isUserShopper}
            />
          </div>
        </>
      )}
      <div className='input-container mb-2 mr-4'>
        <label>Order Status</label>
        <Dropdown value={orderStatus} onChange={setOrderStatus} options={ORDER_STATUS_OPTIONS} areOptionsStringArray={true} isFilter={true} />
      </div>
      <ActionButton
        text={'Submit'}
        isLoading={false}
        active={true}
        customClass={'btn-primary mb-2'}
        onClick={onFilterSubmit}
      />
      {!_isUserShopper && <ActionButton
        text={'Reset'}
        isLoading={false}
        active={true}
        customClass={'btn-primary ml-4 mb-2'}
        onClick={resetFilter}
      />}
    </form>
  );
};

const OrderFilterWrapper = connect(mapStateToProps)(OrdersFilter);

export { OrderFilterWrapper as OrdersFilter };
