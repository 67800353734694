import React, { useState } from 'react';
import styles from './index.module.scss';
import Header, { CancelButton, RenderAddress } from './components';
import { addressComponents } from './index';
import ActionButton from '../../ActionButton/ActionButton';
import { IAddress } from '../../../interfaces/address';

const AddressDetails: React.FC<IAddress.IAddressDetails> = (props) => {
  const {
    setComponentToShow,
    localAddress,
    setAddressKey,
    onSuccess = () => {
      return;
    },
    handleClose = () => { return },
    localAddress: { town = '', country = '', zip = '' } = {},
  } = props;

  const [error, setError] = useState({}) as any;

  const validateForm = () => {
    let errorsTemp = {};
    if (!localAddress?.secondAddress) {
      errorsTemp = { secondAddress: 'Please enter a valid second address' };
    }
    setError(errorsTemp);
    return errorsTemp;
  };

  const addAddressFn = async () => {
    const errors = validateForm();
    if (Object.keys(errors)?.length > 0) {
      return;
    }
    onSuccess(localAddress);
    onClose();
  };

  const onClose = () => {
    setComponentToShow(addressComponents.locationDetails);
  };

  const renderError = (err) => {
    if(!err) return null;
    return <div>{err}</div>;
  };

  const firstAddressInput = () => {
    return (
      <div className={`${styles['form-group']} mb-1`}>
        <label>First Address</label>
        <input
          name="address"
          defaultValue={localAddress.address}
          disabled={false}
          onChange={(e) => setAddressKey('address', e.target.value)}
          placeholder="Enter first address"
        />
        {renderError(error?.address)}
      </div>
    );
  };

  const secondAddressInput = () => {
    return (
      <div className={`${styles['form-group']} mb-1`}>
        <label>Second Address</label>
        <input
          name="secondAddress"
          defaultValue={localAddress.secondAddress}
          disabled={false}
          onChange={(e) => setAddressKey('secondAddress', e.target.value)}
          placeholder="Unit number, society name"
        />
        {renderError(error?.secondAddress)}
      </div>
    );
  };

  const getAddressString = () => {
    return `${town}${country ? ', ' + country : ''}${zip ? ', ' + zip : ''}`;
  };

  const addressInput = () => {
    return (
      <div className={`${styles['form-group']} mb-1`}>
        <label>Address</label>
        <RenderAddress
          address={getAddressString()}
          onClick={() => {
            setComponentToShow(addressComponents.searchLocation);
          }}
        />
      </div>
    );
  };

  const renderSaveButton = () => {
    return (
      <div className="d-flex justify-content-between mt-2">
        <div className='d-flex align-items-center'>
          <CancelButton onCancel={handleClose} />
          <ActionButton
            text="Save"
            onClick={addAddressFn}
            isLoading={false}
            active={true}
            customClass={'orange'}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="col-12 field-container">
      <Header title="Details" onClose={onClose} />
      {addressInput()}
      {firstAddressInput()}
      {secondAddressInput()}
      {renderError(error?.error)}
      {renderSaveButton()}
    </div>
  );
};
export default AddressDetails;
