import { cloudFunction } from 'services/parse';
import { DATE_FORMATS, formatDate } from 'utils';
import moment from 'moment';
import _ from 'lodash';

export const getOrderReviewReport = async (startDate: Date | string | null, endDate: Date | string | null, emails: string[], sendEmail: boolean) => {
  if (sendEmail && !emails.length) throw 'Please enter an email or uncheck "Send Email"';

  let response = await cloudFunction('getOrderReviewReport', { endDate, startDate, emails, sendEmail });
  response = response.map(record => {
    return {
      orderId: record.id,
      user: record.user.full_name,
      createdAt: formatDate(record.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
      uvaReview: record.review.uva.text,
      serviceReview: record.review.service.text,
      restaurantReview: record.review.restaurant.text,
      driverReview: record.review.driver.text,
    };
  });
  return response;
};

export const getBonusReport = async (startDate: Date | string | null, endDate: Date | string | null, emails: string[], sendEmail: boolean) => {
  if (sendEmail && !emails.length) throw 'Please enter an email or uncheck "Send Email"';

  let response = await cloudFunction('getBonusReport', { endDate, startDate, emails, sendEmail });
  response = response.map(record => {
    return {
      orderId: record.id,
      createdAt: formatDate(record.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
      distance: typeof record.distance === 'string' ? record.distance : (record.distance).toFixed(2),
      distanceBonus: record.distanceBonus,
    };
  });
  return response;
};

export const getDriverPaymentSummary = (
  startDate: Date | string | null,
  endDate: Date | string | null,
  emails: string[],
  sendEmail: boolean,
  driverId?: string,
) => {
  if (sendEmail && !emails.length) throw 'Please enter an email or uncheck "Send Email"';

  return cloudFunction('getDriverPaymentSummary', { startDate, endDate, emails, sendEmail, driverId });
};

export async function sendRestaurantPaymentSummary(
  startTime: Date | null | string,
  endTime: Date | null | string,
  emails: string[],
  restaurantId: string,
  sendEmail: boolean) {
  if (!startTime || !endTime) {
    throw 'Please select start and end date';
  }
  const data = {
    startTime: moment(startTime).toDate(),
    endTime: moment(endTime).toDate(),
    emails,
    sendEmail,
    restaurantId,
  };
  return cloudFunction('restaurantPaymentSummary', data);
}

export async function getFleetOrderPaymentSummaryReport(
  startTime: Date | null | string,
  endTime: Date | null | string,
  emails: string[],
  restaurantId: string,
  sendEmail: boolean) {
  if (!startTime || !endTime) {
    throw 'Please select start and end date';
  }
  const data = {
    startDate: moment(startTime).toDate(),
    endDate: moment(endTime).toDate(),
    emails,
    sendEmail,
    selectedRestaurantId__: restaurantId,
  };
  return cloudFunction('getFleetOrderPaymentSummaryReport', data);
}

export async function getOrderRejectionReport(filters: Record<string, any>) {
  if (!filters?.startDate || !filters?.endDate) {
    throw 'Please select start and end date';
  }
  const data = {
    ...filters,
    selectedDriverId: filters?.driverId,
    selectedRestaurantId__: filters?.restaurantId,
  };
  delete data['restaurantId'];
  delete data['driverId'];
  return cloudFunction('getOrderRejectionReport', data);
}


export const getDailyDriverPaymentSummary = (
  startDate: Date | string | null,
  endDate: Date | string | null,
  driverId?: string,
) => {
  return cloudFunction('getDailyDriverPaymentSummary', { startDate, endDate, driverId });
};

export const getWeeklyDriverPaymentSummary = (
  startDate: Date | string | null,
  endDate: Date | string | null,
  driverId?: string,
) => {
  return cloudFunction('getWeeklyDriverPaymentSummary', { startDate, endDate, driverId });
};

export const revenueOverview = async (data: Record<string, any>) => {
  let response = await cloudFunction('revenueOverview', data);

  const getRefundedByReasonData = (refundByReasonAndType: Record<string, any>) => {
    return _.mapValues(refundByReasonAndType, reason => (
      _.mapValues(reason, type => {
        let refundAmount = 0;
        _.forIn(type, (value) => {
          refundAmount += value;
        });
        return { refundAmount };
      })
    ));
  };

  response = {
    ...response,
    refundByReasonAndType: getRefundedByReasonData(response.refundByReasonAndType),
  };
  return response;
};

export const discountRedemptionReport = async (data: Record<string, any>) => {
  let response = await cloudFunction('discountRedemptionReport', data);
  response = response.map(record => ({
    discountCode: record.discountCode ? record.discountCode : '-',
    discountAmount: record.discountAmount ? record.discountAmount : '-',
    redemptionDate: record.redemptionDate ? formatDate(record.redemptionDate, DATE_FORMATS.MM_DD_YYYY_HH_MM_A) : '-',
    orderNumber: record.orderNumber ? record.orderNumber : '-',
    orderId: record.orderId ? record.orderId : '-',
    clientId: record.clientId,
    clientEmail: record.clientEmail ? record.clientEmail : '-',
    clientFullname: record.clientFullname ? record.clientFullname : '-',
    clientPhoneNumber: record.clientPhone ? record.clientPhone : '-',
  }));
  return response;
};

export const getDriversReport = (data: Record<string, any>) => {
  return cloudFunction('getDriversReport', data);
};

export const fetchConsolidatedTaxSummaryForRestaurants = async (data: Record<string, any>) => {
  const response = await cloudFunction('fetchConsolidatedTaxSummaryForRestaurants', data);
  return response.map(record =>
    _.mapValues(record, (val) => {
      if (typeof val === 'number') {
        return parseFloat(val.toFixed(2));
      } else return val;
    }),
  );
};

export const fetchDriverActiveStatusChangedEvents = async (data: Record<string, any>) => {
  const response = await cloudFunction('fetchDriverActiveStatusChangedEvents', data);
  return response.map(record => ({
    ...record,
    createdAt: formatDate(record.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A),
    performedBy: record.performedBy ? record.performedBy.full_name : '-',
  }));
};

export const getLeadReport = async (data: Record<string, any>) => {
  let response = await cloudFunction('getLeadReport', data);
  response = response.map(record => ({
    ...record,
    leadName: record.leadName || '-',
    leadPhone: record.leadPhone || '-',
    leadEmail: record.leadEmail || '-',
    leadCity: record.leadCity || '-',
    userId: record.userId || '-',
    promotedByUserId: record.promotedByUserId || '-',
    promotedByUserFullName: record.promotedByUserFullName || '-',
    leadCreatedAt: record.leadCreatedAt ? formatDate(record.leadCreatedAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A) : '-',
    signedUpAt: record.signedUpAt ? formatDate(record.signedUpAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A) : '-',
    driverApprovedAt: record.driverApprovedAt ? formatDate(record.driverApprovedAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A) : '-',
    firstOrderServedAt: record.firstOrderServedAt ? formatDate(record.firstOrderServedAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A) : '-',
  }));
  return response;
};

export const getDriverNotesReport = async (data: {
  startDate: Date | string | null;
  endDate: Date | string | null;
}) => {
  const response = await cloudFunction('getDriverNotesReport', data);
  return response;
};

export const primeSubscriptionDiscountReport = async (data: {
  startDate: Date | string | null;
  endDate: Date | string | null;
}) => {
  const response = await cloudFunction('primeSubscriptionDiscountReport', data);
  return response;
};