import { Loading } from 'components/loading/Loading';
import moment from 'moment';
import { Documents } from 'pages/leads/components/lead-detail/Documents';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { fetchDocumentsForDriver } from 'services/api/drivers';
import { updateRestaurant } from 'services/api/restaurant';
import { UVA_FLEET_AVAILABLE_DOCUMENT_LIST, showError, showSuccess } from 'utils';
import '../../leads/components/lead-detail/document.scss';

const RestoDocAndAggrement = ({ restaurantDetails }) => {
  const [loading, setLoading] = useState('docs');
  const [documents, setDocuments] = useState<Document[]>([]);
  const [contractDate, setContractDate] = useState<Date | null>(restaurantDetails?.contractDate ? 
    moment(restaurantDetails?.contractDate?.iso).toDate() : null);
  const [pickerFocused, setPickerFocused] = useState(false);

  useEffect(() => {
    fetchUserDocuments();
  },[restaurantDetails?.objectId]);

  const setContractDateFn = async (date: any) => {
    setLoading('contract');
    try {
      await updateRestaurant({ objectId: restaurantDetails?.objectId, contractDate: moment(date).toISOString() });
      showSuccess('Contract signing date updated successfully.');
    } catch (e) {
      showError(e);
    } finally {
      setLoading('');
    }
  };

  const contractDateChanged = async (date) => {
    setContractDate(date);
    await setContractDateFn(date);
  };

  const fetchUserDocuments = async () => {
    setLoading('docs');
    try {
      const docs = await fetchDocumentsForDriver(restaurantDetails?.objectId);
      setDocuments(docs);
    } catch (e) {
      showError(e);
    } finally {
      setLoading('');
    }
  };

  return (
    <div className=' documents'>
      <div className="mt-3 border-top p-3 card">
        {loading==='docs' ? <Loading type="mini"/> :
          <Documents 
            documents={documents} 
            userId={restaurantDetails?.objectId} 
            onDocumentChange={fetchUserDocuments} 
            customClass="pb-5" 
            AVAILABLE_DOCUMENT_LIST={UVA_FLEET_AVAILABLE_DOCUMENT_LIST}
            isOtherDocumentsRequired={false}
            isApprovedRequired={false}
            headerName='Merchant Agreement'
            isExpiredDateRequired={false}
          />
        }
        <div className='pl-2 upload-button' style={{ width: '215px' }}>
          <span className="text-muted mr-3 text-small">Contract Signing Date</span>
          {loading==='contract' ?  <Loading type='mini'/>:
            <div
              className={`date-picker-container ${pickerFocused ? 'picker-focused' : ''}`}
              onClick={() => setPickerFocused(true)}
            >
              <i className='fa fa-calendar-o mr-2' />
              <ReactDatePicker
                className='form-control w-50'
                selected={contractDate}
                onFocus={() => setPickerFocused(true)}
                onClickOutside={() => setPickerFocused(false)}
                onBlur={() => setPickerFocused(false)}
                onChange={contractDateChanged}
              />
              <span className='ml-auto'>
                {contractDate && <a onClick={() => contractDateChanged(null)} className="text-small" style={{ color:'#007bff', fontSize: '13px' }}>Clear</a>}
              </span>
            </div>}
        </div>
      </div>
      <div className="mt-3 border-top mb-3 p-3 card">
        {loading==='docs' ? <Loading type="mini"/> :
          <Documents 
            documents={documents} 
            userId={restaurantDetails?.objectId} 
            onDocumentChange={fetchUserDocuments} 
            customClass="pb-5" 
            AVAILABLE_DOCUMENT_LIST={[]}
            isApprovedRequired={false}
            headerName='Documents'
          />
        }
      </div>
    </div>
  );
};

export default RestoDocAndAggrement;