import { cloudFunction } from 'services/parse';
import { DATE_FORMATS, formatDate } from 'utils';

export const fetchDriversForAdminDashboard = async (filters: Record<string, any>) => {
  let response = await cloudFunction('fetchDriversForAdminDashboard', filters);
  response = {
    items: response.items.map(record => {
      return {
        objectId: record.objectId,
        name: record.full_name,
        driverApprovedAt: formatDate(
          record.driverApprovedAt || record.createdAt,
          DATE_FORMATS.MM_DD_YYYY_HH_MM_A,
        ),
        email: record.email,
        phone: record.phone,
        isDriver: record.isDriver,
      };
    }),
    total: response.total,
  };
  return response;
};

export const fetchDriverDetailForAdminDashboard = async (id: string) => {
  const response = await cloudFunction('fetchDriverDetailForAdminDashboard', { id });
  response.createdAt = formatDate(response.createdAt, DATE_FORMATS.MMM_DD_YYYY);
  return response;
};

export const fetchDriverActivities = async (params: Record<string, any>) => {
  const response = await cloudFunction('fetchDriverActivities', params);
  return JSON.parse(JSON.stringify(response));
};

export const deleteDriverNotes = async ({ noteId, id }: { noteId: string; id: string }) => {
  return await cloudFunction('deleteUserNote', { noteId, userId:id });
};

export const addDriverNotes = async ({ id, note }) => {
  return await cloudFunction('addUserNote', { userId: id, note });
};

export const editDriverNotes = async ({ id, note, noteId }: { id: string; note: string; noteId: string }) => {
  return await cloudFunction('editUserNote', { userId: id, note, noteId });
};

export const toggleIsDriver = async (id: string) => {
  return await cloudFunction('toggleIsDriver', { id });
};

export const toggleOnlineStatusByAdmin = async (id: string) => {
  return await cloudFunction('toggleOnlineStatusByAdmin', { id });
};

export const fetchDocumentsForDriver = async (userId: string) => {
  const documents = await cloudFunction('fetchUserDocuments', { userId });
  return JSON.parse(JSON.stringify(documents));
};

export const fetchAllCities = () => {
  return cloudFunction('fetchAllCities', {});
};

export const fetchVehicleTypes = () => {
  return cloudFunction('fetchVehicleTypes', {});
};

export const fetchDriverDeactivationReasons = () => {
  return cloudFunction('fetchDriverDeactivationReasons', {});
};

export const fetchOrdersFromWhichDriverRemoved = (data: Record<string, any>) => {
  return cloudFunction('fetchOrdersFromWhichDriverRemoved', data);
};