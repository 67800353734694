import ActionButton from 'components/ActionButton/ActionButton';
import { Loading } from 'components/loading/Loading';
import { tableStyles } from 'css/tableStyles';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { fetchScrappers, getScrapperServerInstances } from 'services';
import TableLink from 'TableLink';
import { showError } from 'utils/notifications';
import './Scrappers.scss';
import DataTableFilter from 'components/DataTableFIlter';

const columns = [
  {
    name: 'ID',
    selector: 'objectId',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/scrappers/${row.objectId}`}
        text={row.objectId}
        openInNewTab={false}
      />
    ),
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: false,
  },
  {
    name: 'Restaurant ID',
    selector: 'vendorId',
    sortable: false,
  },
  {
    name: 'Active',
    selector: 'active',
    sortable: false,
  },
  {
    name: 'Last Scrapping',
    selector: 'lastScrappingTime',
    sortable: false,
  },
  {
    name: 'Has Error',
    selector: 'hasError',
    sortable: false,
  },
  {
    name: 'Instance',
    selector: 'scrapperServerInstance',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => (<div>{ row.scrapperServerInstance || 'N/A'}</div>),
  },
];

const Scrappers = props => {
  const [scrappers, setScrappers] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState(false);
  const [serverInstances, setServerInstances] = useState<string[]>([]);
  const [switching,setSwitching] = useState<boolean>(false);
  const [filterText, setFilterText] = React.useState('');

  const fetchScrappersFn = async () => {
    setLoading(true);
    try {
      const [scrappersResponse,instances] = await Promise.all([fetchScrappers(),getScrapperServerInstances()]);
      setScrappers(scrappersResponse);
      setServerInstances(instances);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchScrappersFn();
  }, []);

  const getGroupedDataTable = () => {    
    if (scrappers.length>0 && !switching) {
      const dataFilterInput = <DataTableFilter filterText={filterText} setFilterText={setFilterText} placeholder={'Filter'} />;
      const filteredData = scrappers.filter(scrapper => {
        return ( scrapper?.name && scrapper.name.toLowerCase().includes(filterText.toLowerCase()) ||
                 scrapper?.scrapperServerInstance && scrapper.scrapperServerInstance.toLowerCase().includes(filterText.toLowerCase()) ||
                 scrapper?.vendorId && scrapper.vendorId.toLowerCase().includes(filterText.toLowerCase())
        );
      });

      return (
        <DataTable
          keyField="objectId"
          columns={columns}
          data={filteredData}
          highlightOnHover={true}
          defaultSortField="createdAt"
          customStyles={tableStyles}
          actions={[dataFilterInput]}
        />
      );
    }

    return <div />;
  };

  const getSeperateDataTables=()=>{
    if(scrappers.length>0 && switching){
      return(
        <div>
          {serverInstances.map(instance=>{
            const scrapperServerInstanceData= scrappers.filter(scrapper=>scrapper.scrapperServerInstance===instance);
            if(scrapperServerInstanceData.length==0) return;
            return (
              <div key={instance}>
                <DataTable
                  title={`${instance.toUpperCase()} (${scrapperServerInstanceData.length})`}
                  keyField={'objectId'}
                  columns={columns}
                  data={scrapperServerInstanceData}
                  highlightOnHover={true}
                  customStyles={tableStyles}
                />
              </div>
            );
          })}
        </div>

      );
    }else return <></>;
  };

  const getActionButton=()=>{
    return (
      <ActionButton
        active={true}
        isLoading={loading}
        customClass={'ml-2'}
        onClick={()=>setSwitching(!switching)}
        text={switching ? 'Group All' : 'Group By Server Instance'}
      />
    );
  };

  const getHeading=()=>{
    return (
      <div className={'list-header'}>
        <h4 className={'list-heading'}>Scrappers</h4>
        <ActionButton
          text={'Create Scrapper'}
          active={true}
          isLoading={false}
          onClick={() => props.history.push('/scrappers/create')}
          customClass={'btn-primary'}
        />
      </div>
    );
  };
  return (
    <>
      {loading && <Loading />}
      {getHeading()}
      {getActionButton()}
      {getGroupedDataTable()}
      {getSeperateDataTables()}
    </>
  );
};

export { Scrappers };
