import React from 'react';
import { Router } from './Router';
import { SideNav } from '../components/sidenav/SideNav';

class AppRouter extends React.Component {
  render() {
    return (
      <>
        <SideNav />
        <Router />
      </>
    );
  }
}

export default AppRouter;
