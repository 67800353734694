import React, { useEffect, useState } from 'react';
import { fetchVerticals } from 'services/api';
import _ from 'lodash';
import Form from 'react-bootstrap/Form';

const VerticalTypePicker = props => {
  const [verticalTypes, setVerticalTypes] = useState([{ id: '', name: '' }]);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    verticalType,
    onChange,
    disabled,
    onKeyDown = true,
    isFilter,
  } = props;

  useEffect(() => {
    const formatVerticals = (verticals) => {
      setVerticalTypes(_.sortBy(verticals, 'name'));
      setLoading(false);
    };
    (async function fetchVerticalTypes() {
      setLoading(true);
      const verticals = await fetchVerticals();
      formatVerticals(verticals);
    })();
  }, []);

  const getVerticals = () => {
    return verticalTypes.map((vertical) => {
      return <option key={vertical.id} value={vertical.name}>{vertical.name}</option>;
    });
  };

  return (
    <Form.Control onKeyDown={onKeyDown && onKeyDown} as="select" value={verticalType} onChange={onChange} disabled={disabled || loading}>
      <option key={'defaultValue'} hidden={!verticalType} value={isFilter ? '' : undefined} >{isFilter ? 'ALL' : 'Select Vertical Type'}</option>
      {getVerticals()}
    </Form.Control >
  );
};

export default VerticalTypePicker;