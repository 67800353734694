import React, { useEffect, useState } from 'react';
import './index.scss';
import ActionButton from 'components/ActionButton/ActionButton';
import { showError } from 'utils/notifications';
import { createToll, updateToll } from 'services/api/tolls';

const EditTollModal = props => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tollName, setName] = useState<string>('');
  const [tollAmount, setAmount] = useState<number>();
  const [tollLatitude, setLatitude] = useState<number>();
  const [tollLongitude, setLongitude] = useState<number>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { closeModal, onSuccess, tollToEdit } = props;
  const { objectId, name, amount, coords } = tollToEdit;
  
  useEffect(() => {
    if (!!name) {
      setIsEdit(true);
      setName(name);
      setAmount(amount);
      setLatitude(coords.latitude);
      setLongitude(coords.longitude);
    }
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    let payload: Record<string, any> = {
      name: tollName,
      amount: tollAmount,
      latitude: tollLatitude,
      longitude: tollLongitude,
    };
    
    if (isEdit) {
      payload = {
        ...payload,
        id: objectId,
      };
    }
    
    try {
      isEdit ? await updateToll(payload) : await createToll(payload);
      await onSuccess();
      closeModal();
    } catch(e) {
      showError(e);
      setLoading(false);
    }
  };
  
  return (
    <div className='edit-toll-container'>
      <div className='edit-toll-modal'>
        <div className='modal-header'>
          <div>{`${isEdit ? 'Edit': 'Add'} Toll`}</div>
          <i className='fa fa-close' onClick={closeModal} />
        </div>
        <div className='modal-body'>
          <div className='toll-name'>
            <label>Name</label>
            <input
              className={'form-control'}
              value={tollName}
              disabled={loading}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className='toll-name'>
            <label>Amount</label>
            <input
              className={'form-control'}
              type={'number'}
              value={tollAmount}
              disabled={loading}
              onChange={e => setAmount(parseFloat(e.target.value))}
            />
          </div>
          <div className='toll-name'>
            <label>Latitude</label>
            <input
              className={'form-control'}
              type={'number'}
              value={tollLatitude}
              disabled={loading}
              onChange={e => setLatitude(parseFloat(e.target.value))}
            />
          </div>
          <div className='toll-name'>
            <label>Longitude</label>
            <input
              className={'form-control'}
              type={'number'}
              value={tollLongitude}
              disabled={loading}
              onChange={e => setLongitude(parseFloat(e.target.value))}
            />
          </div>
        </div>
        <div className='modal-footer'>
          <ActionButton
            text={'Cancel'}
            active={true}
            type={'button'}
            isLoading={false}
            onClick={closeModal}
          />
          <ActionButton
            text={`${isEdit ? 'Edit' : 'Add'} Toll`}
            active={!loading}
            isLoading={false}
            customClass={'btn-primary'}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default EditTollModal;