import React, { useEffect, useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import qs from 'query-string';
import './index.scss';
import NameFilterAutosuggest from 'components/NameFilterAutoSuggest';
import { searchUserByName } from 'services';
import Dropdown from 'components/Dropdown';
import { FLEET_MERCHANT_INVOICE_STATUS, showError, showSuccess } from 'utils';
import { DateRangePickerWrapper } from 'components/Date/DateRangePicker';
import moment from 'moment';
import { fetchFleetMerchantInvoices } from 'services/api/invoices';
const FLEET_MERCHANT_INVOICE_STATUS_OPTIONS = Object.keys(FLEET_MERCHANT_INVOICE_STATUS).map(key => ({
  value: FLEET_MERCHANT_INVOICE_STATUS[key],
  label: FLEET_MERCHANT_INVOICE_STATUS[key],
}));
const InvoiceFilters = props => {
  const [userId, setUserId] = useState<string>('');
  const [status, setStatus] = useState<any>('');
  const [issuedAtStartDate,setIssuedAtStartDate] = useState<any>('');
  const [issuedAtEndDate,setIssuedAtEndDate] = useState<any>('');
  const [scheduledAtStartDate,setScheduledAtStartDate] = useState<any>('');
  const [scheduledAtEndDate,setScheduledAtEndDate] = useState<any>('');
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showZeroInvoices, setShowZeroInvoices] = useState<boolean>(false);
  const [emails, setEmails] = useState<string>('');

  useEffect(() => {
    const {
      userId,
      status,
      issuedAtStartDate,
      issuedAtEndDate,
      scheduledAtStartDate,
      scheduledAtEndDate,
      showZeroInvoices,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });

    if (userId) setUserId(userId.toString());
    if (status) setStatus(status.toString());
    if (issuedAtStartDate) setIssuedAtStartDate(issuedAtStartDate.toString());
    if (issuedAtEndDate) setIssuedAtEndDate(issuedAtEndDate.toString());
    if (scheduledAtStartDate) setScheduledAtStartDate(scheduledAtStartDate.toString());
    if (scheduledAtEndDate) setScheduledAtEndDate(scheduledAtEndDate.toString());
    if (showZeroInvoices) setShowZeroInvoices(!!showZeroInvoices);
  }, []);

  const resetFilter = () => {
    setUserId('');
    setStatus('');
    setShowZeroInvoices(false);
    setIssuedAtStartDate('');
    setIssuedAtEndDate('');
    setScheduledAtStartDate('');
    setScheduledAtEndDate('');
    props.onFilterSubmit({});
  };

  const onFilterSubmit = () => {
    const filterData: Record<string, any> = {
      userId,
      status,
      showZeroInvoices,
      scheduledAtStartDate,
      scheduledAtEndDate,
      issuedAtStartDate,
      issuedAtEndDate,
    };

    props.onFilterSubmit(filterData);
  };

  const onIssuedAtDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    setIssuedAtStartDate(moment(startDate).toISOString());
    setIssuedAtEndDate(moment(endDate).toISOString());
  };

  const onScheduledAtDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    setScheduledAtStartDate(moment(startDate).toISOString());
    setScheduledAtEndDate(moment(endDate).toISOString());
  };

  const getEmailArr = () => {
    if (sendEmail && emails) {
      const emailArr = emails.split(',').map(email => email.trim());
      return emailArr;
    }
    return [];
  };

  const exportInvoiceListFn = async () => {
    const emails: string[] = getEmailArr();

    setLoading(true);
    const filterData: Record<string, any> = {
      userId,
      status,
      showZeroInvoices,
      scheduledAtStartDate,
      scheduledAtEndDate,
      issuedAtStartDate,
      issuedAtEndDate,
    };
    try {
      if (emails.length === 0) {
        showError('Please enter email to export.');
        return;
      }
      const response = await fetchFleetMerchantInvoices({ ...filterData, emails, exportAsCSV: true });
      showSuccess(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form className='filter-container'>
        <div className='input-container mb-2 mr-4'>
          <label>User ID</label>
          <input
            className='form-control'
            placeholder={'User ID'}
            value={userId}
            onChange={e => setUserId(e.target.value)}
          />
        </div>
        <div className='input-container mb-2 mr-4'>
          <label>User Name</label>
          <NameFilterAutosuggest
            entityId={userId}
            placeholder={'User Name'}
            onSuggestionSelected={setUserId}
            fetchSuggestions={searchUserByName}
          />
        </div>
        <div className='input-container mr-4 mb-2' style={{ width: '200px' }}>
          <label>Status</label>
          <Dropdown name="Status" value={status} onChange={setStatus} options={FLEET_MERCHANT_INVOICE_STATUS_OPTIONS} isFilter={false} />
        </div>
        <div className={'d-flex mt-4 mr-3'}>
          <input
            type='checkbox'
            disabled={loading}
            checked={showZeroInvoices}
            onChange={e => setShowZeroInvoices(e.target.checked)}
            className={'mr-2'}
          />
          <div className='label mr-2'>Zero Invoices</div>
        </div>
        <div className='input-container mb-2 mr-4'>
          <label>Issued At Date Range</label>
          <DateRangePickerWrapper
            startDate={issuedAtStartDate}
            endDate={issuedAtEndDate}
            onDatesChange={onIssuedAtDateSelectionChange}
          />
        </div>
        <div className='input-container mb-2 mr-4'>
          <label>Scheduled At Date Range</label>
          <DateRangePickerWrapper
            startDate={scheduledAtStartDate}
            endDate={scheduledAtEndDate}
            onDatesChange={onScheduledAtDateSelectionChange}
          />
        </div>
        <ActionButton
          text={'Submit'}
          isLoading={false}
          active={true}
          customClass={'btn-primary mb-2'}
          onClick={onFilterSubmit}
        />
        <ActionButton
          text={'Reset'}
          isLoading={false}
          active={true}
          customClass={'btn-primary ml-4 mb-2'}
          onClick={resetFilter}
        />
      </form>
      <hr/>
      <form className='filter-container mt-2'>
        <div className='input-container mr-2 mb-2'>
          <input
            type='email'
            className='form-control'
            placeholder='Emails'
            disabled={loading || !sendEmail}
            value={emails}
            onChange={e => setEmails(e.target.value)}
          />
          <div className='hint mb-2 text-muted'>Hint: separate multiple emails using commas</div>
        </div>
        <div className='filter-container m-0'>
          <div className={'d-flex'}>
            <div className='label mr-2'>Send Email</div>
            <input
              type='checkbox'
              disabled={loading}
              checked={sendEmail}
              onChange={e => setSendEmail(e.target.checked)}
              className={'mr-2'}
            />
          </div>
          <div className='button-container'>
            <ActionButton
              text={loading ? 'Loading...' : 'Export'}
              active={sendEmail && !loading}
              isLoading={false}
              customClass={'btn-primary m-0'}
              onClick={exportInvoiceListFn}
            />
          </div>
        </div>
      </form>
    </>
  );
};


export default InvoiceFilters;
