import CustomLink from 'TableLink';
import React from 'react';
import { DATE_FORMATS, convertToLocaleString, formatDate } from 'utils';
import styles from './index.module.scss';
import _ from 'lodash';

const RenderInfo = (props) => {
  const { heading, value, defaultValue = '-', link = '', type = '', customComponent, containerClass = '' } = props;

  const getValue = () => {
    if (type === 'boolean') {
      return value?.toString() || defaultValue;
    } else if (type === 'date') {
      return value ? formatDate(value, DATE_FORMATS.MM_DD_YYYY_HH_MM_A) : defaultValue;
    } else if (type === 'currency') {
      return !_.isNil(value) ? `$${convertToLocaleString(Number(props.value).toFixed(2))}` : defaultValue;
    } else {
      return value || defaultValue;
    }
  };
  const renderValue = () => {
    if (link) {
      return <CustomLink
        text={getValue()}
        linkTo={link}
      />;
    } else {
      return <div className={styles['detail']}>{getValue()}</div>;
    }
  };
  return (
    <div className={`${styles['info-group']} ${containerClass}`}>
      <div className={styles['label']}>{heading}</div>
      {customComponent ? customComponent() : renderValue()}
    </div>
  );
};

export default RenderInfo;