import { cloudFunction } from 'services/parse';

export const fetchBlacklistedDriversForMerchant = async (params: Record<string, any>): Promise<any> => {
  return await cloudFunction('fetchBlacklistedDriversForMerchant', params);
};

export const fetchBlacklistedMerchantsForDriver = async (params: Record<string, any>): Promise<any> => {
  return await cloudFunction('fetchBlacklistedMerchantsForDriver', params);
};

export const addBlacklistedMerchantForDriver = async (params: Record<string, any>): Promise<any> => {
  return await cloudFunction('addBlacklistedMerchantForDriver', params);
};

export const addBlacklistedDriverForMerchant = async (params: Record<string, any>): Promise<any> => {
  return await cloudFunction('addBlacklistedDriverForMerchant', params);
};

export const removeBlacklistedMerchantForDriver = async (params: Record<string, any>): Promise<any> => {
  return await cloudFunction('removeBlacklistedMerchantForDriver', params);
};

export const removeBlacklistedDriverForMerchant = async (params: Record<string, any>): Promise<any> => {
  return await cloudFunction('removeBlacklistedDriverForMerchant', params);
};