import React, { useEffect, useState } from 'react';
import Filters from './filter';
import moment from 'moment';
import qs from 'query-string';
import { showError } from 'utils/notifications';
import { Loading } from 'components/loading/Loading';
import DataTable from 'react-data-table-component';
import { tableStyles } from 'css/tableStyles';
import { DATE_FORMATS, formatDate, getPageLimit, stringifyAndParseObj, updatePageLimit } from 'utils';
import { fetchDisputes } from 'services/api/disputes';
import TableLink from 'TableLink';

let filters: { [p: string]: any; page: number } = {
  page: 0,
  endDate: moment().toISOString(),
  startDate: moment().subtract(1, 'week').toISOString(),
  endDueDate: moment().add(1, 'week').toISOString(),
  startDueDate: moment().subtract(1, 'week').toISOString(),
};

const columns = [
  {
    name: 'Id',
    selector: 'objectId',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/disputes/${row.objectId}`}
        text={row.objectId}
      />
    ),
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
  },
  {
    name: 'Stripe Dispute Id',
    selector: 'stripeDisputeId',
    sortable: false,
  },
  {
    name: 'Charge Id',
    selector: 'chargeId',
    sortable: false,
  },
  {
    name: 'Order',
    selector: 'order.objectId',
    sortable: false,
    // eslint-disable-next-line react/display-name
    cell: row => (
      <TableLink
        linkTo={`/orders/${row?.order?.objectId}`}
        text={row?.order?.objectId}
      />
    ),
  },
  {
    name: 'Created At',
    selector: 'disputeCreatedAt',
    format: row => formatDate(row.disputeCreatedAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_SS_A),
  },
  {
    name: 'Due By',
    selector: 'responseDueBy',
    format: row => formatDate(row.responseDueBy, DATE_FORMATS.MM_DD_YYYY_HH_MM_SS_A),
  },
];

const Disputes = props => {
  const [disputes, setDisputes] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);

  const fetchDisputesFn = async () => {
    setLoading(true);
    const { page, startDate, endDate, startDueDate, endDueDate, status } = filters;
    try {
      const filter = {
        page,
        limit: getPageLimit(),
        startDate,
        endDate,
        responseDueByStartDate: startDueDate,
        responseDueByEndDate: endDueDate,
      } as any;
      if ( status) {
        filter.status = status;
      }
      let response = await fetchDisputes({
        filter,
      });
      response = stringifyAndParseObj(response);
      setDisputes(response);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  const queryStringToFilter = () => {
    return qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
  };

  useEffect(() => {
    filters = {
      ...filters,
      ...queryStringToFilter(),
    };
    fetchDisputesFn();
  }, []);

  const filterToQueryString = () => {
    return qs.stringify({ ...filters });
  };

  const updateQueryString = () => {
    const qs = filterToQueryString();
    props.history.push({
      pathname: props.location.pathname,
      search: qs,
    });
  };

  const onFilterChanged = (udpatedfilters: Record<string, any>) => {
    filters = { ...udpatedfilters, page: 0 };
    updateQueryString();
    fetchDisputesFn();
  };

  const onChangePage = (page: number) => {
    filters.page = page - 1;
    updateQueryString();
    fetchDisputesFn();
  };

  const onChangeRowsPerPage = (rowsPerPage: number, currentPage: number) => {
    updatePageLimit(rowsPerPage);
    onChangePage(currentPage);
  };

  return (
    <div>
      <Filters
        {...props}
        onFilterSubmit={onFilterChanged}
      />
      <div className={'mt-4'}>
        {loading ? <Loading /> : (
          <DataTable
            noHeader
            data={disputes.items}
            columns={columns}
            customStyles={tableStyles}
            pagination={true}
            paginationServer={true}
            paginationDefaultPage={(filters.page || 0) + 1}
            paginationPerPage={getPageLimit()}
            paginationTotalRows={disputes.total}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        )}
      </div>
    </div>
  );
};

export default Disputes;