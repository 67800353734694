import React, { useEffect, useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import qs from 'query-string';
import './index.scss';
import { DateRangePickerWrapper } from 'components/Date/DateRangePicker';
import moment from 'moment';
import NameFilterAutosuggest from 'components/NameFilterAutoSuggest';
import { searchUserByName } from 'services/api/order';
import EntityClassDropdown from 'components/EntityClassDropdown';

const CloudRequestsFilter = props => {
  const [userId, setUserId] = useState<string>('');
  const [entityId, setEntityId] = useState<string>('');
  const [entityClass, setEntityClass] = useState<string>('');
  const [startDate, setStartDate] = useState<string | null>(moment().subtract(1, 'month').toString());
  const [endDate, setEndDate] = useState<string | null>(moment().toString());
  const {
    onFilterSubmit,
  } = props;

  useEffect(() => {
    const {
      userId,
      entityId,
      entityClass,
      startDate,
      endDate,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });

    if (userId) setUserId(userId.toString());
    if (entityId) setEntityId(entityId.toString());
    if (entityClass) setEntityClass(entityClass.toString());
    if (startDate) setStartDate(startDate.toString());
    if (endDate) setEndDate(endDate.toString());

    if (entityId || userId || startDate || endDate){
      onFilterSubmitFn({
        userId,
        entityId,
        entityClass,
        startDate,
        endDate,
      });
    }
  }, []);

  const resetFilter = () => {
    setUserId('');
    setEntityId('');
    setEntityClass('');
    setStartDate('');
    setEndDate('');

    const filterData = {
      userId: '',
      entityId: '',
      entityClass: '',
      startDate: '',
      endDate: '',
    };

    onFilterSubmit(filterData);
  };

  const onFilterSubmitFn = (queryParams) => {
    if (queryParams){
      onFilterSubmit(queryParams);
    }else{
      const filterData: Record<string, any> = {
        userId,
        entityId,
        entityClass,
        startDate,
        endDate,
      };
      onFilterSubmit(filterData);
    }
  };

  const onDateSelectionChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    setStartDate(moment(startDate).toISOString());
    setEndDate(moment(endDate).toISOString());
  };

  return (
    <form className='filter-container'>
      <div className='input-container mb-2 mr-4'>
        <label>User Name</label>
        <NameFilterAutosuggest
          entityId={userId}
          placeholder={'User Name'}
          onSuggestionSelected={setUserId}
          fetchSuggestions={searchUserByName}
        />
      </div>
      <div className='input-container mb-2 mr-4'>
        <label>Entity ID</label>
        <input
          className='form-control'
          placeholder={'Entity ID'}
          value={entityId}
          onChange={e => setEntityId(e.target.value)}
        />
      </div>
      <div className='input-container mb-2 mr-4'>
        <label>Entity Class</label>
        <EntityClassDropdown
          value={entityClass}
          onChange={setEntityClass}
        />
      </div>
      <div className='input-container mb-2 mr-4'>
        <label>Date Range</label>
        <DateRangePickerWrapper
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onDateSelectionChange}
        />
      </div>
      <ActionButton
        text={'Submit'}
        isLoading={false}
        active={true}
        customClass={'btn-primary mb-2'}
        onClick={onFilterSubmitFn}
        type={'submit'}
      />
      <ActionButton
        text={'Reset'}
        isLoading={false}
        active={true}
        customClass={'btn-primary ml-4 mb-2'}
        onClick={resetFilter}
        type={'button'}
      />
    </form>
  );
};

export default CloudRequestsFilter;