import React, { useEffect, useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import qs from 'query-string';
import './index.scss';
import DishesAutoSuggest from 'components/DishesAutoSuggest';
import { searchDishes } from 'services/api/dishes';
import RestaurantAutoSuggest from 'components/RestaurantAutoSuggest';

const DishesFilter = props => {
  const [restaurantId, setRestaurantId] = useState<string>('');
  const [dishName, setDishName] = useState<string>('');
  const [dishId, setDishId] = useState<string>('');

  useEffect(() => {
    const {
      dishId,
      restaurantId,
    } = qs.parse(props.location.search, { parseBooleans: true, parseNumbers: true });
    if (dishId) setDishId(dishId.toString());
    if (restaurantId) setRestaurantId(restaurantId.toString());
  }, []);

  const resetFilter = () => {
    setRestaurantId('');
    setDishId('');
    setDishName('');

    const filterData = {
      dishId: '',
      restaurantId: '',
    };

    props.onFilterSubmit(filterData);
  };

  const onFilterSubmit = () => {
    const filterData: Record<string, any> = {
      dishId,
      restaurantId,
    };

    props.onFilterSubmit(filterData);
  };

  return (
    <form className='filter-container'>
      <div className='input-container mb-2 mr-4' style={{ width: '150px' }}>
        <label>Dish ID</label>
        <input
          className='form-control'
          placeholder={'Dish ID'}
          value={dishId}
          onChange={e => setDishId(e.target.value)}
        />
      </div>
      <div className='input-container mr-4 mb-2'>
        <label>Dish</label>
        <DishesAutoSuggest
          value={dishName}
          handleChange={setDishName}
          placeholder={'Search Dish'}
          onSuggestionSelect={setDishId}
          fetchDishes={term => searchDishes(term, { restaurantId })}
        />
      </div>
      <div className='input-container mr-4 mb-2'>
        <label>Restaurant Id</label>
        <input
          value={restaurantId}
          onChange={e => setRestaurantId(e.target.value)}
          className='form-control'
        />
      </div>
      <div className='input-container mr-4 mb-2'>
        <label>Restaurant</label>
        <RestaurantAutoSuggest
          placeholder={'Search Restaurant'}
          onSuggestionSelect={suggestion=>setRestaurantId(suggestion.id)}
        />
      </div>
      <ActionButton
        text={'Submit'}
        isLoading={false}
        active={true}
        customClass={'btn-primary mb-2'}
        onClick={onFilterSubmit}
      />
      <ActionButton
        text={'Reset'}
        isLoading={false}
        active={true}
        customClass={'btn-primary ml-4 mb-2'}
        onClick={resetFilter}
      />
    </form>
  );
};

export default DishesFilter;
