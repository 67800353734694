import React,{ useMemo,useState } from 'react';
import ActionButton from 'components/ActionButton/ActionButton';
import DataTable from 'react-data-table-component';
import { DATE_FORMATS, formatDate } from 'utils';
import { Loading } from 'components/loading/Loading';
import { deleteOrderNote } from '../../../services/api/order';
import { showError } from '../../../utils/notifications';
import { tableStyles } from 'css/tableStyles';

const NoteSection = (props) =>{
  const [noteDeleteId, setNoteDeleteId] = useState<string>('');
  const { orderDetails, setOrderDetails, note, setNote, setOrderNoteModalOpen, orderNoteModalOpen } = props;
  
  const deleteNote = async (id: string) => {
    setNoteDeleteId(id);
    try {
      const res = await deleteOrderNote(orderDetails.objectId, id);
      setOrderDetails({ ...orderDetails, notes: res.toJSON().notes });
    } catch (e) {
      showError(e);
    } finally {
      setNoteDeleteId('');
    }
  };

  const editNote = (note: Record<string, any>) => {
    setNote(note);
    setOrderNoteModalOpen(true);
  };

  const columns = useMemo(() => [
    {
      name: 'Notes',
      selector: 'note',
      sortable: false,
      width: '500px',
      // eslint-disable-next-line react/display-name
      cell: (row) => <div style={{ wordBreak: 'break-word', paddingTop: '10px' }}>{row['note']}</div>,
    },
    {
      name: 'Date Created',
      selector: 'createdAt',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: (row) => <div style={{ wordBreak: 'break-word' }}>{formatDate(row.createdAt, DATE_FORMATS.MM_DD_YYYY_HH_MM_A)}</div>,
    },
    {
      name: 'Creator',
      selector: 'by',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: (row) => <div style={{ wordBreak: 'break-word' }}>{row.by.objectId}</div>,
    },
    {
      name: 'Action',
      sortable: false,
      // eslint-disable-next-line react/display-name
      cell: row => (
        <div>
          <button
            className={'btn btn-danger delete-icon'}
            onClick={() => deleteNote(row.id)}
          >
            {noteDeleteId === row.id ? <Loading type='mini' /> : <i className={'fa fa-trash delete-btn'}></i>}
          </button>
          <button
            className={'btn btn-primary ml-3'}
            onClick={() => editNote(row)}
          >
            <i className={'fa fa-pencil edit-btn'}></i>
          </button>
        </div>
      ),
    },
  ], [orderDetails.notes, noteDeleteId, note, orderNoteModalOpen]);

  const getOrderNotesTable = () => (
    <DataTable
      noHeader
      columns={columns}
      data={orderDetails.notes}
      customStyles={tableStyles}
    />
  );

  return (
    <div className={'notes-section'}>
      <div className={'notes-header'}>
        <div className={'label'}>Order Notes</div>
        <ActionButton
          text="Add Note"
          active={true}
          isLoading={false}
          onClick={() => {
            setNote(null);
            setOrderNoteModalOpen(true);
          }}
          customClass="btn-primary"
        />
      </div>
      {(orderDetails.notes || []).length > 0 ? getOrderNotesTable() : (
        <div className={'empty-notes-message'}>No Notes Found</div>
      )}
    </div>
  );
};

export default NoteSection;
